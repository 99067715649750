import React, { Component } from "react";
import { connect } from "react-redux";
import { GatewayOutlined } from "@ant-design/icons";

import { Table, Input, Tag, Space } from "antd";

class Pg extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-lg-12 mx-auto">
            <div className="card">
              <div
                className="card-header"
                // style={{ backgroundColor: "aliceblue" }}
              >
                <div className="row">
                  <div className="col-lg-8">
                    <div className="col">
                      <div className="media">
                        <div className="media-body align-self-center ms-2">
                          <p className="font-14 font-weight-semibold mb-0">
                            <b>
                              {
                                this.props.selectedPgData.serviceRequest
                                  .requestCode
                              }
                            </b>
                          </p>
                        
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                  <div className="col">
                      <div className="media">
                        <div className="media-body align-self-center ms-2">
                          <p className="font-14 font-weight-semibold mb-0">
                          <Tag color="orange">
                          {
                            this.props.selectedPgData.pgStatus.name.toUpperCase()
                              
                          }
                        </Tag>
                          </p>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="">
                      <h6 className="mb-0">
                        Depot :{" "}
                        <Tag color="blue">
                          {
                            this.props.selectedPgData.serviceRequest.site.depot
                              .name
                          }
                        </Tag>
                        &rarr; Site :{" "}
                        <Tag color="blue">
                          {
                            this.props.selectedPgData.serviceRequest.site
                              .siteName
                          }
                        </Tag>
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="task-box">
                  <p className="text-muted float-end">
                  Date and Time :{" "}
                            {
                              this.props.selectedPgData.serviceRequest
                                .requestDate
                            }{" "}
                            {
                              this.props.selectedPgData.serviceRequest
                                .requestTime
                            }
                  </p>
                  <hr />
                  <br />
                  <br />
                  <div className="holder">
                    <ul className="steppedprogress pt-1">
                      <li className="complete">
                        <span>Initiated</span>
                      </li>
                      <li className="complete continuous">
                        <span>Approved</span>
                      </li>
                      <li className="">
                        <span>Installed</span>
                      </li>
                      <li className="">
                        <span>Completed</span>
                      </li>
                    </ul>
                  </div>
                  <br />
                  <br />
                  {/* <h5 style={{ marginTop: 20 }}>Organic Farming</h5>
                  <p className="text-muted mb-1">
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered alteration in some
                    form.
                  </p> */}
                  {/* <p className="text-muted text-end mb-1">15% Complete</p> */}
                  <div className="progress mb-4" style={{ height: "4px" }}>
                    {/* <div
                      className="progress-bar bg-secondary"
                      role="progressbar"
                      style={{ width: "15%" }}
                      aria-valuenow="15"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div> */}
                  </div>
                  <div className="d-flex justify-content-between">
                    {/* <div className="img-group">
                      <a className="user-avatar user-avatar-group" href="#">
                        <img
                          src="assets/images/users/user-1.jpg"
                          alt="user"
                          className="rounded-circle thumb-xs"
                        />
                      </a>
                      <a className="user-avatar user-avatar-group" href="#">
                        <img
                          src="assets/images/users/user-5.jpg"
                          alt="user"
                          className="rounded-circle thumb-xs"
                        />
                      </a>
                      <a className="user-avatar user-avatar-group" href="#">
                        <img
                          src="assets/images/users/user-7.jpg"
                          alt="user"
                          className="rounded-circle thumb-xs"
                        />
                      </a>
                    </div> */}
                    {/* <ul className="list-inline mb-0 align-self-center">
                      <li className="list-item d-inline-block me-2">
                        <a className="" href="#">
                          <i className="mdi mdi-format-list-bulleted text-success font-15"></i>
                          <span className="text-muted fw-bold">15/100</span>
                        </a>
                      </li>
                      <li className="list-item d-inline-block">
                        <a className="" href="#">
                          <i className="mdi mdi-comment-outline text-primary font-15"></i>
                          <span className="text-muted fw-bold">3</span>
                        </a>
                      </li>
                      <li className="list-item d-inline-block">
                        <a className="ms-2" href="#">
                          <i className="mdi mdi-pencil-outline text-muted font-18"></i>
                        </a>
                      </li>
                      <li className="list-item d-inline-block">
                        <a className="" href="#">
                          <i className="mdi mdi-trash-can-outline text-muted font-18"></i>
                        </a>
                      </li>
                    </ul> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Pg;

import React, { Component } from 'react'
import ReactApexChart from 'react-apexcharts'


class TwoLineChart extends Component {
  constructor(props) {
    super(props);
    this.state = {

      series: [
        {
          name: "High - 2013",
          data: [28, 29, 33, 36, 32, 32, 33, 50]
        },
        {
          name: "Low - 2013",
          data: [12, 11, 14, 18, 17, 13, 13]
        }
      ],
      options: {
        chart: {
          height: 350,
          type: 'line',
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
          toolbar: {
            show: false
          }
        },
        colors: ['#E43000', '#30E759'],
        dataLabels: {
          enabled: true,

          style: {
            fontSize: '12px',


            colors: ['#FFFFFF']
          },
        },
        stroke: {
          curve: 'smooth'
        },

        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        markers: {
          size: 1
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Agu'],

        },
        yaxis: {
          title: {
            text: 'Temperature'
          },

        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,

        }
      },


    }
  }
  componentDidMount() {
    console.log('erer');
    console.log(this.props);
    this.setState({
      series: this.props.y1,
      options: {
        chart: {
          height: 450,
          type: 'line',
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
          toolbar: {
            show: false,
            position: 'bottom'
          },
          tickAmount: 5,
        },
        colors: ['#FFB200', '#42B02A'],
        dataLabels: {
          enabled: true,
          position: 'bottom',
          style: {
            fontSize: '12px',
            colors: ['#FFFFFF']
          },
          offsetY: -6,
          background: {
            enabled: false, 
          },
        },    
        stroke: {
          curve: 'smooth'
        },

        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        markers: {
          size: 1
        },
        xaxis: {
          categories: this.props.x1,
          tickAmount: 10,
          labels: {
            rotate: -0,
            position: 'bottom',
          },
        },
        yaxis: {
          title: {
            text: this.props.yLabale,
          },

        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
          offsetX: 5,
          offsetY: -5,
        }
      },
    })
  }

  render() {
    return (
      <ReactApexChart options={this.state.options} series={this.state.series} type="line" height={350} />
    )
  }
}
export default TwoLineChart;

import React, { Component } from "react";
import { connect } from "react-redux";
import { getDonationListData } from "../actions/index";
import { getDonationCategories, getData } from "../actions/index";
import {
  getPgData,
  getPgCountData,
  getPgStatusData,
  getPgCostData,
  getPgSLAData,
} from "../api/index";
import { Select } from "antd";
import Pg from "../components/pg/pg";

import LineChart from "./chart/base-line";
import LineChartSla from "./chart/base-line-sla";

import StackedBar from "./chart/StackedBar";
import GroupedBar from "./chart/GroupedBar";
import DoughnutChart from "./chart/Doughnut";

import { Line } from "react-chartjs-2";

var noData = (
  <svg
    class="ant-empty-img-simple"
    width="64"
    height="41"
    viewBox="0 0 64 41"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(0 1)" fill="none" fill-rule="evenodd">
      <ellipse
        class="ant-empty-img-simple-ellipse"
        cx="32"
        cy="33"
        rx="32"
        ry="7"
      ></ellipse>
      <g class="ant-empty-img-simple-g" fill-rule="nonzero">
        <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
        <path
          d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
          class="ant-empty-img-simple-path"
        ></path>
      </g>
    </g>
  </svg>
);
class DashboardExceptional extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    setInterval(() => this.getPgCountData(), 20000);
  }
  getPgCountData = async () => {
    //console.log("sdsd");
    this.setState({
      isLoading: true,
    });
    var data = {
      // "id":2,
      type: "all",
    };
    var res = await getPgCountData(data);
    this.setState({
      isLoading: false,
    });
  };

  render() {
    return (
      <div>
      
          <div class="col-lg-12">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Exceptional Dashboard</h4>
                <p class="text-muted mb-0">Live data on the floor.</p>
              </div>
              {this.state.isLoading ? (
              <div class="card-body">
                <div class="d-flex justify-content-center">
                  <div class="spinner-border text-primary" role="status"></div>
                </div>
              </div>
               ) : (
                "Data Loaded"
              )}
            </div>
          </div>
       
      </div>
    );
  }
}

export default DashboardExceptional;

import React from "react";
import { withContext } from "../../../context";
import SmartContainer from "./smart-container";
import moment from "moment";
import "./style.css";

// function (){
//   return (

//   )
// }

// class Container extends React.Component{
//     render(){
//         return(
//             <React.Fragment>
//             {/* <code className="box">{JSON.stringify(this.props, null, "\nꜜ")}</code> */}
//             {
//               this.props.data.map((e)=>(
//                 <div className="box" key={e.name} onClick={this.props.fetch}>
//                   <div className="color-indicator" style={{background: e.color}}></div>
//                   <table>
//                     <tr>
//                       <td><b>Vehicle No</b></td>
//                       <td><b>Start Time</b></td>
//                       <td><b>End Time</b></td>
//                       <td><b>Departure Time</b></td>
//                       <td><b>Status</b></td>
//                     </tr>
//                     {e.data.map((d, i) => (
//                       <tr key={d.vehicle_no}>
//                         <td>{d.vehicle_no}</td>
//                         <td>{d.StartTime}</td>
//                         <td>{d.EndTime}</td>
//                         {/* <td><code>{d.DepartureTime}</code></td> */}
//                         <td>{moment.duration(moment().diff(d.DepartureTime)).humanize()}</td>
//                         {d.delay_status && <td className="warning">DELAYED</td>}
//                       </tr>
//                       ))
//                     }
//                   </table>
//                 </div>
//               ))
//             }
//             </React.Fragment>
//         )
//     }
// }

class Container extends React.Component{
  render(){
    return (
      <SmartContainer>
        <div>eee</div>
        <div>eee</div>
        <div>eee</div>
      </SmartContainer>
    )
  }
}

export default withContext.bind(this, Container) 
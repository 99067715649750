import React, { Component } from 'react';
import { getTrackPg } from "../api/index";
import ReactExport from "react-data-export";
import { Table, Input, Tag, Space, DatePicker } from "antd";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
class TrackPg extends Component {
    constructor(props) {
        super(props);
        this.state = {

            data_list: [],
            startDate: "",
            endDate: "",
            loading:false,
      
          };
        
    }

    componentDidMount(){
        //console.log(this)
       // this.getData();
    }
    getData = async (data) => {
      var form = new FormData();
      form.append("RequestDateFrom",data.startDate);
      form.append("RequestDateTo",data.endDate);
      form.append("pageNo", "0");
      form.append("recodesPerPage", "100");
        this.setState({
            loading: true,
          });
        var res = await getTrackPg(form);
        //console.log(res.data);
        if (res.success) {
           
          this.setState({
            loading: false,
            data_list: res.data.data,
          });
        }
      };
      onChangeDate = (date, dateString) => {
        //console.log(date);
        //console.log(dateString);
        this.setState({
          startDate: dateString,
        });
      };
      onChangeDate2 = (date, dateString) => {
        //console.log(date);
        //console.log(dateString);
        this.setState({
          endDate: dateString,
        });
      };
      searchClick = () => {
        //console.log("search");
        if(this.state.startDate!="" && this.state.endDate!=""){
          var data = {
            "startDate": this.state.startDate,
            "endDate": this.state.endDate,
          };
          this.setState({
            loading: true,
          });
          this.getData(data);
        }
        
      };
    
    
    render() {

     const   columns = [
            {
              title: "Region Name",
              dataIndex: "regionName",
              key: "regionName",
              render: (item) => <span>{item}</span>,
            },
            {
              title: "Depot Name",
              dataIndex: "depotName",
              key: "depotName",
              render: (item) => <span>{item}</span>,
            },
            {
                title: "Request Code",
                dataIndex: "requestCode",
                key: "requestCode",
                render: (item) => <span>{item}</span>,
              },
            {
              title: "Pending OnDO",
              dataIndex: "pendingOnDO",
              key: "pendingOnDO",
              render: (item) => <span>{item==true?("true"):("false") }</span>,
            },
            {
              title: "Pending OnOM",
              dataIndex: "pendingOnOM",
              key: "pendingOnOM",
              render: (item) => <span>{item==true?("true"):("false") }</span>,
            },
            {
             title: "Pending OnRM",
             dataIndex: "pendingOnRM",
             key: "pendingOnRM",
             render: (item) => <span>{item==true?("true"):("false") }</span>,
            },
            {
              title: "Pending OnTM",
              dataIndex: "pendingOnTM",
              key: "pendingOnTM",
              render: (item) => <span>{item==true?("true"):("false") }</span>,
             },
              {
                title: "Pending OnVC",
                dataIndex: "pendingOnVC",
                key: "pendingOnVC",
                render: (item) => <span>{item==true?("true"):("false") }</span>,
              },
              {
                title: "Request Date",
                dataIndex: "requestDate",
                key: "requestDate",
                render: (item) => <span>{item}</span>,
              },
              {
                title: "Status",
                dataIndex: "status",
                key: "status",
                render: (item) => <span>{item}</span>,
              },
          ];
          const timestamp = Date.now();
          const dataSet1 = this.state.data_list;
          const fontValue =  {sz: "12", bold: true,fill: {patternType: "solid", fgColor: {rgb: "FF0B0713"}}};

          const StatisticDataSet = [
            {
                columns: [
                  { title: "Region Name	" , style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue}  ,width: {wpx:140}},
                  { title: "Depot Name",style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} , width: {wpx: 155}},
                  { title: "Request Code",style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} , width: {wpx: 245}},
                  { title: "Pending OnDO",style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} , width: {wpx: 155}},
                  { title: "Pending OnOM",style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} , width: {wpx: 155}},
                  { title: "Pending OnRM",style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} , width: {wpx: 155}},
                  
                  { title: "Pending OnTM",style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} , width: {wpx: 155}},
                  { title: "Pending OnVC",style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} , width: {wpx: 155}},
                
                  // { title: "Pending OnVC",style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} , width: {wpx: 155}},
                  { title: "Request Date",style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} , width: {wpx: 155}},
                  { title: "Status",style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} , width: {wpx: 220}},
                 
               
    
                ],
                data:dataSet1?.map((data)=>[
                  {value:data.regionName ,style: { font: { sz: "12" } }},
                  {value:data.depotName ,style: { font: { sz: "12" } }},
                  {value:data.requestCode ,style: { font: { sz: "12" } }},
                  {value:data.pendingOnDO ,style: { font: { sz: "12" } }},
                  {value:data.pendingOnOM ,style: { font: { sz: "12" } }},
                  {value:data.pendingOnRM ,style: { font: { sz: "12" } }},
                  {value:data.pendingOnTM ,style: { font: { sz: "12" } }},
                
                  {value:data.pendingOnVC ,style: { font: { sz: "12" } }},
                  {value:data.requestDate ,style: { font: { sz: "12" } }},
                  {value:data.status ,style: { font: { sz: "12" } }},
              
    
                ]),
            },
           
        
            
          ];
        return (


          
          <div className="row">
          <div className="col-md-3 col-lg-3 col-xl-3">
            <DatePicker
              placeholder="Start Date"
              style={{ width: "100%" }}
              onChange={this.onChangeDate}
            />
          </div>
          <div className="col-md-3 col-lg-3 col-xl-3">
            <DatePicker
              placeholder="End Date"
              style={{ width: "100%" }}
              onChange={this.onChangeDate2}
            />
          </div>
          <div className="col-md-6 col-lg-6 col-xl-6">
            <button
              onClick={this.searchClick}
              class="btn btn-primary"
              //style={{ float: "right" }}
            >
              <i class="fas fa-search"> </i> Search
            </button>
          </div>
          <br/><br/>
          <div className="col-lg-12 col-xl-12">
            <div className="card">
              <div className="card-header">
                <div className="row ">
                  <div className="col">
                    <h4 className="card-title">
                      Report - Track Panding PG{" "}
                      <ExcelFile
                        filename={"Track Panding PG -" + timestamp}
                        element={
                          <button
                            class="btn btn-primary"
                            style={{ float: "right" }}
                          >
                            <i class="fas fa-file-export"> </i> Export
                          </button>
                        }
                      >
                       <ExcelSheet dataSet={StatisticDataSet} name="Track PG"/>
                      
                      </ExcelFile>
                    </h4>
                    <div></div>
                  </div>
                </div>
              </div>
              <div className="card-body">
             
                <Table
                  size="small"
                  columns={columns}
                  // rowKey="id"
                  loading={this.state.loading}
                  dataSource={this.state.data_list}
                />
      
              </div>
            </div>
          </div>
        </div>
           
        );
    }
}

export default TrackPg;
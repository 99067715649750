import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import { GoogleMap, LoadScript, Marker, InfoWindow } from "@react-google-maps/api";
import redIcon from "../../../icon/location_flag.png";
import { connect } from "react-redux";
import { Select, Spin } from "antd";
import { getLocationList } from "../../../api/index";
import MapLocation from './location'
import { Card, Col, Row, Descriptions, Tag } from 'antd';
import { Route, withRouter } from 'react-router-dom';

const { Option } = Select;

const defaultProps = {
    center: {
        lat: 7.469853140531547,
        lng: 80.0435207784175
    },
    zoom: 7.5
};




class MapView extends Component {

    constructor(props) {
        super(props)
        this.state = {
            depotList: [],
            regionList: [],
            subRegionList: [],
            selectedRegions: [],
            selectedSubRegions: [],
            selectedDepot: [],
            locationList: [],
            isLoading: false,
            hoverLocationId: 0,
            clickedItemId: 0,
            showSiteName: false
        }
    }

    componentDidMount() {

      //  this.getData();
        
        this.setState({
            depotList: this.props.depots,
            regionList: this.props.regions.filter((item) => { return item.isSubRegion == 0 }),
            subRegionList: this.props.regions.filter((item) => { return item.isSubRegion == 1 }),
        })
    }

    // getData = async () => {
    //     this.setState({
    //         isLoading: true
    //     })
    //     var formdata = new FormData();
    //     formdata.append("pageNo", "");
    //     formdata.append("recodesPerPage", "2000");
    //     formdata.append("regions", "");
    //     formdata.append("subregions", "");
    //     formdata.append("depots", "1");
    //     var res = await getLocationList(formdata);
    //     console.log(res);
    //     this.setState({
    //         locationList: res.data.data,
    //         isLoading: false
    //     })
    // }


    selectedSite = (id) => {
        this.setState({
            clickedItemId: id,
        })
        // this.props.history.push('/site-summary/' + id);
    }

    searchDepotChange = (depot) => {
        this.setState({
            selectedDepot: depot
        })
    }

    searchRegionChange = (region) => {

        this.setState({
            selectedRegions: region
        })
    }

    searchSubRegionChange = (subRegion) => {
        this.setState({
            selectedSubRegions: subRegion
        })
    }

    _canclePopUp = () => {
        this.setState({
            clickedItemId: 0
        })

    }




    filterBtnClick = async () => {
        this.setState({
            isLoading: true
        })
        var formdata = new FormData();
        formdata.append("pageNo", "");
        formdata.append("recodesPerPage", "2000");
        formdata.append("regions", this.state.selectedRegions.toString());
        formdata.append("subregions", this.state.selectedSubRegions.toString());
        formdata.append("depots", this.state.selectedDepot.toString());
        var res = await getLocationList(formdata);

        this.setState({
            locationList: res.data.data,
            isLoading: false
        })
    }

    _onChildMouseEnter = (key) => {
        // this.props.onHoverKeyChange(key);
        this.setState({
            hoverLocationId: this.state.locationList[key].id
        })
    }

    _onChildMouseLeave = (key) => {
        this.setState({
            hoverLocationId: 0
        })
    }

    onZoomChanged = () => {
        alert("1111111")
    }

    handleChange(e) {
        if (e.zoom > 12) {
            this.setState({
                showSiteName: true
            })
        }
        else {
            this.setState({
                showSiteName: false
            })
        }
        this.setState({
            clickedItemId: 0,
        })

    }

    viewSiteDetails = (id) => {
        this.props.history.push('/site-summary/' + id);
    }

    render() {

        return (
            <Spin spinning={this.state.isLoading} delay={200}>
                <div style={{ height: '95vh', width: '100%' }}>

                    {/* <img style={{ width: 25, marginTop: -1 }} src={redIcon} />
                <div style={{ background: `url(${require("../../icon/location_flag.png")})`, width: 100, height: 100 }}></div> */}

                    <div className="row">
                        <div class="card">

                            <div class="card-body bootstrap-select-1">
                                <div class="row">

                                    <div class="col-md-3">
                                        <label class="mb-3">Region</label>
                                        {/* <Select options={this.state.regionList} name="depotId" onChange={(e) => this.searchRegionChange(e)} /> */}
                                        <Select
                                            mode="multiple"
                                            showSearch
                                            name="regionId"
                                            onChange={(e) => this.searchRegionChange(e)}
                                            placeholder="Region"
                                            style={{ width: "100%" }}
                                            value={this.state.selectedRegions.map((item) => item)}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                        >
                                            {this.state.regionList.map((item, index) => {
                                                return <Option key={index} value={item.id}>{item.name}</Option>;
                                            })}
                                        </Select>
                                    </div>
                                    <div class="col-md-3">
                                        <label class="mb-3">Sub Region</label>
                                        {/* <Select options={this.state.subRegionList} name="depotId" onChange={(e) => this.searchSubRegionChange(e)} /> */}
                                        <Select
                                            mode="multiple"
                                            showSearch
                                            name="regionId"
                                            onChange={(e) => this.searchSubRegionChange(e)}
                                            placeholder="Sub Region"
                                            style={{ width: "100%" }}
                                            value={this.state.selectedSubRegions.map((item) => item)}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                        >
                                            {this.state.subRegionList.map((item, index) => {
                                                return <Option key={index} value={item.id}>{item.name}</Option>;
                                            })}
                                        </Select>
                                    </div>
                                    <div class="col-md-3">
                                        <label class="mb-3">Depot</label>
                                        {/* <Select options={this.state.depotList} name="depotId" onChange={(e) => this.searchDepotChange(e)} /> */}
                                        <Select
                                            mode="multiple"
                                            showSearch
                                            onChange={(e) => this.searchDepotChange(e)}
                                            placeholder="Depot"
                                            style={{ width: "100%" }}
                                            value={this.state.selectedDepot.map((item) => item)}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                        >
                                            {this.state.depotList.map((item, index) => {
                                                return <Option key={index} value={item.id}>{item.name}</Option>;
                                            })}
                                        </Select>
                                    </div>

                                </div>
                                <div class="col-md-3" style={{ marginTop: 10 }}>
                                    <button type="button" class="btn btn-primary" onClick={() => this.filterBtnClick()}> Filter</button>
                                    <button style={{ marginLeft: 10 }} type="button" class="btn btn-danger" onClick={() => this._canclePopUp()}> Clear Selected Site</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: "AIzaSyC7QjauaBLV9W__koQgy65PIuxYbcSPpfg" }}
                        defaultCenter={defaultProps.center}
                        defaultZoom={defaultProps.zoom}
                        onChildMouseEnter={this._onChildMouseEnter}
                        onChildMouseLeave={this._onChildMouseLeave}
                        onZoomChanged={this.onZoomChanged}
                        onChange={(e) => this.handleChange(e)}
                    >

                        {this.state.locationList.map(marker => {
                            if (marker.siteAddressList.length > 0) {
                                return <div
                                    lat={marker.siteAddressList[0].latitude}
                                    lng={marker.siteAddressList[0].longitude}
                                    style={{ margin: "auto", cursor: "pointer" }} onClick={() => this.selectedSite(marker.id)}>
                                    <MapLocation isHovered={this.state.hoverLocationId == marker.id} id={marker.id} clicked={this.state.clickedItemId == marker.id} name={marker.siteName} showSiteName={this.state.showSiteName} />

                                    {
                                        (this.state.clickedItemId == marker.id) &&
                                        <div onClick={() => this._canclePopUp()} style={{
                                            display: "flex",
                                            width: 460,
                                            backgroundColor: "white",
                                            borderRadius: "3%",
                                            boxShadow: "1px 3px 1px #9E9E9E",
                                            zIndex: '2',
                                            position: 'relative', zIndex: '2',
                                            padding: 10
                                        }}>
                                            <div>
                                                <Descriptions title="Site Information">
                                                    <Descriptions.Item span={3} label="UserName">{marker.siteName}</Descriptions.Item>
                                                    <Descriptions.Item span={3} label="Latitude">{marker.siteAddressList[0].latitude}</Descriptions.Item>
                                                    <Descriptions.Item span={3} label="Longitude">{marker.siteAddressList[0].longitude}</Descriptions.Item>
                                                    <Descriptions.Item span={3} label="Address">{marker.siteAddressList[0].address}</Descriptions.Item>
                                                </Descriptions>
                                                <div style={{ marginTop: 20 }}>
                                                    <Tag color="error" onClick={() => this.viewSiteDetails(marker.id)}>View Site Details</Tag>
                                                </div>

                                            </div>
                                        </div>
                                    }

                                </div>
                                // <Location
                                //     lat={marker.siteAddressList[0].latitude}
                                //     lng={marker.siteAddressList[0].longitude}
                                //     name={marker.siteName}
                                //     id={marker.id}
                                //     clickEvent={this.selectedSite}
                                //     hoverLocationId={this.state.hoverLocationId}
                                //     clickedItemId={this.state.clickedItemId}
                                // />
                            }

                        })}

                    </GoogleMapReact>
                </div>
            </Spin>
        )
    }
}

function mapStateToProps(state) {
    return {
        depots: state.depots,
        regions: state.regions
    };
}



export default withRouter(connect(
    mapStateToProps,
    {}
)(MapView));

import React, { Component } from 'react'
import { Table, Input, InputNumber, message, Modal, Select } from "antd";
import {updateDcPowerSharing, getAllDropdowns} from "../../api/index";
const { Option } = Select;

class DCPowerSharing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            switchStatus: true,
            objectSet: {
                "dcpsSharedFrom": "",
                "dcpsSharedCapacity": 0,
                "dcpsAverageLoad": 0
            },
            dropDownData : {
                "dcpsSharedFrom": [],
                "dcpsSharedCapacity": [],
            }
        }
        this.handledcpsSharedFrom = this.handledcpsSharedFrom.bind(this);
        this.handldcpsSharedCapacity = this.handldcpsSharedCapacity.bind(this);
        this.handldcpsAverageLoad = this.handldcpsAverageLoad.bind(this);
    }

    getSharedBackupPowerDropDownData = async () => {
        var res = await getAllDropdowns();
        if (res.success) {
            this.setState({
                dropDownData: {
                    "dcpsSharedFrom": res.data["DC power Sharing"]["Shared from"],
                    "dcpsSharedCapacity": res.data["DC power Sharing"]["Shared capacity(A)"],
                }
            })
        } else {
            message.warning('Issue With Dropdown Data Fetching! : ' + res.data.message);
        }
    }
    
    handledcpsSharedFrom(e) {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                dcpsSharedFrom: e.target.value
            }

        }, () => {
            console.log(this.state.objectSet)
        })
    }
    
    handldcpsSharedCapacity(e) {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                dcpsSharedCapacity: e
            }

        }, () => {
            console.log(this.state.objectSet)
        })
    }
    handldcpsAverageLoad(e) {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                dcpsAverageLoad: e
            }

        }, () => {
            console.log(this.state.objectSet)
        })
    }
    componentDidMount() {
        console.log(this.props.details)
        this.setState({
            objectSet: {
                "dcpsSharedFrom": this.props.details.access_technologies_data?.dcpsSharedFrom,
                "dcpsSharedCapacity": this.props.details.access_technologies_data?.dcpsSharedCapacity,
                "dcpsAverageLoad": this.props.details.access_technologies_data?.dcpsAverageLoad,
            }
        })
        this.getSharedBackupPowerDropDownData();
    }

        
	showUpdateConfirm = () => {
		const { confirm } = Modal;
		confirm({
			title: 'Are You Want To Update These Data, Related To DC Power Sharing?',
			onOk: async () => {
                this.dataSetToUpdate();
			},
			onCancel() { },
		});
	};
    
    dataSetToUpdate = async () => {
           var res = await updateDcPowerSharing(this.props.details2.site_details.id, this.state.objectSet);
           if(res.success){
              message.success('Successfully Update!')
              this.props.parentMethod();
              this.setState({
                switchStatus: true
            })
           }else{
            message.warning(' Something went wrong!')
           }
    }

    dataUpdate() {
        console.log("ssss")
        this.setState({
            switchStatus: false
        })
    }
    close = async () =>{
        this.props.parentMethod();
        this.setState({
            objectSet: {
                "dcpsSharedFrom": this.props.details.access_technologies_data?.dcpsSharedFrom,
                "dcpsSharedCapacity": this.props.details.access_technologies_data?.dcpsSharedCapacity,
                "dcpsAverageLoad": this.props.details.access_technologies_data?.dcpsAverageLoad,
            }
        });
        this.setState({
            switchStatus: true
        });
    }
    render() {
        const { details } = this.props.details
        return (
            <>
                {this.props.details.access_technologies_data !=null ? (
                    <div className='row'>
                        <div className='col-md-6'>
                            <div class="card">
                                <div class="card-header">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <h4 class="card-title">Information</h4>
                                        {this.state.switchStatus
                                            ?   <button type="button" class="btn btn-soft-primary btn-icon-square-sm" onClick={() => this.dataUpdate()}> <i class="mdi mdi-pencil-outline"></i></button>
                                            :   <div class="d-flex justify-content-end align-items-center">
                                                    <button type="button" class="btn btn-soft-secondary " onClick={() => this.close()} style={{marginRight:"10px"}}> Cancel </button>    
                                                    {/* <button type="button" class="btn btn-soft-primary " onClick={() => this.showUpdateConfirm()}> Submit to Update </button> */}
                                                    {
                                                        this.state.objectSet?.dcpsAverageLoad == null || this.state.objectSet?.dcpsAverageLoad == "" ?
                                                        <button type="button" class="btn btn-soft-primary " disabled> Submit to Update </button> : 
                                                        <button type="button" class="btn btn-soft-primary " onClick={() => this.showUpdateConfirm()}> Submit to Update </button>
                                                    }
                                                </div>
                                        }
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        
                                            <ul class="list-group">
                                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                                    <div>
                                                    <i class="la la-hand-o-right text-primary me-2"></i>  DC Power Shared From
                                                    </div>
                                                    {/* {this.state.switchStatus ? this.props.details.access_technologies_data?.dcpsSharedFrom !=null ? (<span class="badge badge-outline-success">{this.props.details.access_technologies_data.dcpsSharedFrom}</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>) : <Input style={{ width: '20%' }} value={this.state.objectSet?.dcpsSharedFrom} id="mpcNoOfPhases" onChange={this.handledcpsSharedFrom} />} */}
                                                    <div>
                                                        {this.state.switchStatus ? this.props.details.access_technologies_data?.dcpsSharedFrom != null ||
                                                        this.props.details.access_technologies_data?.dcpsSharedFrom != undefined ? (
                                                            <span className="badge badge-outline-success">{this.props.details.access_technologies_data.dcpsSharedFrom}</span>) : 
                                                        (<span className="badge badge-outline-danger">Not Defined Yet</span>) : (
                                                            <Select
                                                                defaultValue={this.props.details.access_technologies_data?.dcpsSharedFrom}
                                                                style={{ width: 120 }} 
                                                                onChange={(e) => {
                                                                    this.state.objectSet.dcpsSharedFrom = e;
                                                                }} >
                                                                {this.state.dropDownData.dcpsSharedFrom.map((x, y) => {
                                                                    return <Option value={x}>{x}</Option>
                                                                })}
                                                            </Select>
                                                        )}
                                                    </div>
                                                </li>
                                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                                    <div>
                                                    <i class="la la-hand-o-right text-primary me-2"></i>  Shared Capacity(A)
                                                    </div>
                                                    {/* {this.state.switchStatus ? this.props.details.access_technologies_data?.dcpsSharedCapacity ? ( <span class="badge badge-outline-success">{this.props.details.access_technologies_data.dcpsSharedCapacity}</span>):( <span class="badge badge-outline-warning">{this.props.details.access_technologies_data.dcpsSharedCapacity}</span>) : <InputNumber style={{ width: '20%' }} value={this.state.objectSet?.dcpsSharedCapacity} id="mpcNoOfPhases" onChange={this.handldcpsSharedCapacity} />} */}
                                                    {/* <div>
                                                    {this.state.switchStatus.toString() == "true" ? (                                                      
                                                            this.props.details.access_technologies_data?.sbpNoOfPhases != null || 
                                                            this.props.details.access_technologies_data?.sbpNoOfPhases != undefined ? ( 
                                                                <span className="badge badge-outline-success">{this.props.details.access_technologies_data.sbpNoOfPhases}</span>
                                                            ) : (
                                                                <span className="badge badge-outline-danger">Not Defined Yet</span>
                                                            )    
                                                    ) : (
                                                        <Select
                                                            defaultValue={this.props.details.access_technologies_data?.sbpNoOfPhases}
                                                            style={{ width: 120 }} 
                                                            onChange={(e) => {
                                                                this.state.objectSet.sbpNoOfPhases = e;
                                                            }}>
                                                            {this.state.dropDownObjectSet.sbpNoOfPhases.map((x, y) => {
                                                                return <Option value={x}>{x}</Option>
                                                            })}
                                                        </Select>
                                                    )}
                                                    </div> */}
                                                    <div>
                                                        {this.state.switchStatus ? this.props.details.access_technologies_data?.dcpsSharedCapacity != null || 
                                                        this.props.details.access_technologies_data?.dcpsSharedCapacity != undefined ? ( 
                                                        <span className="badge badge-outline-success">{this.props.details.access_technologies_data.dcpsSharedCapacity}</span>) : 
                                                        (<span className="badge badge-outline-danger">Not Defined Yet</span>) : (
                                                            <Select
                                                                defaultValue={this.props.details.access_technologies_data?.dcpsSharedCapacity}
                                                                style={{ width: 120 }} 
                                                                onChange={(e) => {
                                                                    this.state.objectSet.dcpsSharedCapacity = e;
                                                                }}>
                                                                {this.state.dropDownData.dcpsSharedCapacity.map((x, y) => {
                                                                    return <Option value={x}>{x}</Option>
                                                                })}
                                                            </Select>
                                                        )}
                                                    </div>
                                                </li>
                                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                                    <div>
                                                    <i class="la la-hand-o-right text-primary me-2"></i>  Average Load(A){this.state.switchStatus == false ?<pre style={{color: 'red'}}> *Required</pre> : ""}
                                                    </div>
                                                    {this.state.switchStatus ? this.props.details.access_technologies_data?.dcpsAverageLoad ? ( 
                                                    <span class="badge badge-outline-success">{
                                                    this.props.details.access_technologies_data.dcpsAverageLoad}</span>):( <span class="badge badge-outline-warning">{
                                                    this.props.details.access_technologies_data.dcpsAverageLoad}</span>) : 
                                                    <InputNumber style={{ width: '20%' }} 
                                                    value={this.state.objectSet?.dcpsAverageLoad} id="mpcNoOfPhases" onChange={this.handldcpsAverageLoad} />}
                                                </li>
                                            </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : ( <div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <h4 style={{ color: "#c5c5c5" }}>No Dat Found DC Power Sharing</h4></div>)}

            </>
        )
    }
}
export default DCPowerSharing;

import React, { Component } from 'react'

import { withRouter } from "react-router-dom";
import { getDetailsByRequestId,getImageCaptureAllComponents } from "../../api/index";
import $ from 'jquery';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import moment from 'moment';
import { Anchor } from 'antd';
const { Link } = Anchor;
class RequestsView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Data_list: [],
            loading: false,
            comptId: null,
            selectImageSet: null,
            xx1: 'dd',
            imageComid: null,
            popupImg:null,

            compList: []

        }
    }
    componentDidMount() {

        console.log(this.props.match.params.id)
        this.getAllDetailsByRequestId();
        this.getDataImageCaptureAllComponents();

    }

    getDataSet(data) {
        console.log(data.imageRequestComponent[0]);
        this.setState({
            comptId: data?.imageRequestComponent[0]?.component?.id,
            selectImageSet: data?.imageRequestComponent[0]?.requestFieldsMap
        })

    }
    getDataImageCaptureAllComponents = async () => {
        var res = await getImageCaptureAllComponents();
        console.log('getImageCaptureAllComponents')
        if (res.success) {
            this.setState({
                compList:res?.data
            })
            
        }
        console.log(res)

    }
    getAllDetailsByRequestId = async () => {
        this.setState({
            loading: true
        })
        var data = new FormData();
        data.append('requestId', this.props.match.params.id);
        var res = await getDetailsByRequestId(data);
        console.log(res);
        if (res.success) {
            this.setState({
                Data_list: res.data,
                loading: false
            }, () => {

                this.getDataSet(this.state.Data_list)


            })

        }
    };
    onChangeSetImages(e){
        console.log('aaaaa')
        console.log(e)
        console.log(document.getElementById(e).src)
        this.setState({
            popupImg:document.getElementById(e).src
        })

    }
    onChangeImages(id, key, imageName, imgId,imgarray) {
       
        document.getElementById("imge" + id).src = "https://storage.googleapis.com/dns_storage_v2/requestimages/" + imageName;
      //  document.getElementById("imge" + id).value = "https://storage.googleapis.com/dns_storage_v2/requestimages/" + imageName;
      //  document.getElementById("imgePopup" + id).src = "https://storage.googleapis.com/dns_storage_v2/requestimages/" + imageName;
        var index = imgarray.filter(item => item.id != imgId)
       
        index?.map((a,t)=>{
            
          
                $("#imagLi"+a.id).attr('class', 'list-group-item');
            

        })


        $("#imagLi"+imgId).toggleClass("active");
       
        console.log(index)
        

     
        // $('#imagLi425').on('click', function (event) {
        //     event.preventDefault();
           
        // });
       // document.getElementById("imagLi" + imgId).className = "list-group-item active"

    }
    onChangeCopmt(id, name) {
        //console.log(this.state.Data_list.imageRequestComponent[id - 1].requestFieldsMap);
        this.setState({
            comptId: id,
            selectImageSet: this.state.Data_list?.imageRequestComponent[id - 1]?.requestFieldsMap
        })

        // if (this.state.selectSiteId != null) {
        //     this.setState({
        //         comptId: id,
        //         selectComptName: name

        //     }, () => {
        //         this.getDataLastThreeRequestDetails();
        //     })
        //     window.scrollTo(0, 0)

        // } else {

        //     if (!alert('Please select a Site !')) { window.location.reload(); }
        // }


    }
    render() {

        return (
            <div class="container-fluid"  >


                <div class="row">
                    <div class="col-sm-12">
                        <div class="page-title-box">
                            <div class="row">
                                <div class="col">
                                    <h4 class="page-title">Image Request View</h4>
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="javascript:void(0);">Image Capture </a></li>

                                        <li class="breadcrumb-item"><a href="javascript:void(0);">{this.state.Data_list?.requestCode}</a></li>

                                    </ol>
                                </div>
                                <div class="col-auto align-self-center">
                                    {this.state.Data_list?.serviceStatus?.name !== '' ? (<span class="ant-tag ant-tag-has-color" style={{ backgroundColor: 'green', fontSize: 14 }}>{this.state.Data_list?.serviceStatus?.name}</span>) : ('')}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" id='home' >
                    <div class="col-12">
                        <div class="card">

                            <div class="card-body">
                                <div class="dastone-profile">
                                    <div class="row">
                                        <div class="col-lg-4 align-self-center mb-3 mb-lg-0">
                                            <div class="dastone-profile-main">
                                                <div class="dastone-profile-main-pic">
                                                    <img style={{ width: '86%' }} src={"https://upload.wikimedia.org/wikipedia/commons/thumb/1/12/Redu135cassefrp.jpg/220px-Redu135cassefrp.jpg"} alt="" height="110" class="rounded-circle"></img>
                                                    <span class="dastone-profile_main-pic-change">
                                                        <i class="fas fa-camera"></i>
                                                    </span>
                                                </div>
                                                <div class="dastone-profile_user-detail">
                                                    <h5 class="dastone-user-name">{this.state.Data_list?.site?.siteName}</h5>
                                                    <span class="ant-tag ant-tag-has-color" style={{ backgroundColor: 'gray', fontSize: 12 }}>{this.state.Data_list?.site?.siteId}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-4 ms-auto align-self-center">
                                            <ul class="list-unstyled personal-detail mb-0">
                                                <li class=""><i class="ti ti-timer me-2 text-secondary font-16 align-middle"></i> <b> Created Time </b> : {moment(this.state.Data_list?.createdTime).format('YYYY-MM-DD HH:mm:ss')}</li>
                                                <li class="mt-2"><i class="ti ti-user text-secondary font-16 align-middle me-2"></i> <b>Created User </b> :{this.state.Data_list?.createdUser?.name}</li>
                                                <li class="mt-2"><i class="ti ti-timer text-secondary font-16 align-middle me-2"></i> <b> Request Date </b> :
                                                    {this.state.Data_list?.requestDate}
                                                </li>
                                            </ul>

                                        </div>
                                        <div class="col-lg-4 align-self-center">
                                            <div class="row">
                                                <div class="col-auto text-end border-end">

                                                    <p class="mb-0 fw-semibold">Reson</p>
                                                    <h4 class="m-0 fw-bold"> <span class="text-muted font-12 fw-normal">{this.state?.Data_list?.reson?.name}</span></h4>
                                                </div>
                                                <div class="col-auto">

                                                    <p class="mb-0 fw-semibold">Sub Reason</p>
                                                    <h4 class="m-0 fw-bold"> <span class="text-muted font-12 fw-normal">{this.state?.Data_list?.subReason?.name}</span></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row' >
                    <div className='col-lg-3' >


                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">Component</h4>
                                <p class="text-muted mb-0">Images on Components</p>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <ul class="list-group">
                                            {this.state.compList.map((item, index) => {
                                                return (
                                                    <li style={{ fontSize:12,backgroundColor: this.state.comptId == item.id ? ('#00cf49') : (''), borderColor: this.state.comptId == item.id ? ('#00cf49') : ('') }} class={this.state.comptId == item.id ? ("list-group-item active") : ("list-group-item")}><a href='#home' style={{ color: this.state.comptId == item.id ? ("white") : ("#1d2c48") }} role='tab' onClick={() => this.onChangeCopmt(item.id, item.name)}><i class="la la-arrow-right text-secondary me-2"></i>{item.name}</a></li>
                                                )

                                            })}


                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-9'>
                        <div className='row'>





                            {this.state.selectImageSet?.map((item, idx) => {
                                {var te=item?.images[0].imageName; }


                                return (
                                    <div class="col-lg-6" style={{ display: 'grid' }}>
                                        <div class="card">
                                            <Popup

                                                trigger={<a style={{ textAlign: 'center', marginTop: 3 }}><img  onClick={() => this.onChangeSetImages('imge' + item?.subCompornent?.id)} value={'https://storage.googleapis.com/dns_storage_v2/requestimages/' + item?.images[0].imageName}   id={'imge' + item?.subCompornent?.id} style={{ width: '50%' }} class="card-img-top img-fluid bg-light-alt" src={'https://storage.googleapis.com/dns_storage_v2/requestimages/' + item?.images[0].imageName} ></img></a>}
                                                modal
                                                nested

                                            >
                                                {close => (
                                                    <div className="row" style={{ justifyContent: 'center', }}>

                                                        <a style={{ textAlign: 'end', fontSize: 18, marginTop: -9 }} className="close" onClick={close}>
                                                            &times;
                                                        </a>

                                                        <img id={'imgePopup'+item?.subCompornent?.id} style={{ width: '65%', marginTop: -15 }} class="card-img-top img-fluid bg-light-alt" src={this.state?.popupImg} ></img>



                                                    </div>
                                                )}
                                            </Popup>

                                            <div class="card-header" >
                                                <div class="row align-items-center">
                                                    <div class="col">
                                                        <h4 class="card-title" style={{ fontSize: 12 }}>{item?.subCompornent?.name}</h4>
                                                    </div>
                                                    <div class="col-auto">

                                                        <span class="badge badge-outline-light">{this.state.Data_list.requestDate}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card-body">
                                                <p class="card-text text-muted ">Image description goes here..</p>

                                                <div class="row">
                                                    <div class="col-lg-12">

                                                    <ul class="list-group">
                                                        {console.log(item?.images.length)}
                                                        

                                                            {item?.images.map((x, y) => {

                                                                {console.log('sss')}


                                                                return (
                                                 

                                                                    <li id={'imagLi' + x.id} style={{ padding: 7 }} class={this.state.imageComid == null && y == 0 ? ("list-group-item active") : ("list-group-item")} ><a role='tab' onClick={() => this.onChangeImages(item?.subCompornent?.id, y, x.imageName, x.id,item?.images)}><i class="la la-arrow-right text-secondary me-2"></i>Image {y+1}</a>

                                                                    </li>
                                                                )

                                                            })}
                                                            </ul>

                                                 


                                                    </div>

                                                </div>
                                                <br></br>

                                                <a href="#" class="btn btn-primary btn-sm">More Info..</a>

                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                            {this.state.selectImageSet == null || this.state.selectImageSet[0] == null ? (<div className='row' style={{ textAlign: 'center' }}>
                                <h3 class="font-25 text-muted mb-5">Images NotFound</h3>

                            </div>) : ('')}




                        </div>

                       



                    </div>

                </div>

            </div>

        )
    }
}
export default withRouter(RequestsView);

import React, { Component } from "react";
import { Table, Input, Tag, Space, DatePicker, Select } from "antd";
import ReactExport from "react-data-export";
import { getsiteAccessExport, getUserSites } from "../../api/index";
import { getDonationCategories, getData } from "../../actions/index";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'

import ReactLoading from 'react-loading';
const moment = require('moment');




const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const { RangePicker } = DatePicker;
const { Option } = Select;
class TeamMemberFullExport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "",
            filterTable: null,
            data_list: [],
            startDate: "",
            endDate: "",
            loading: false,
            fullArray: [],
            depotList: [],
            regionsList: [],
            regionIds: '',
            depotIds: '',
            allSite_loading: false,
            allSite: [],
            siteIds: '',

        };
    }

    componentDidMount() {
        if (JSON.parse(localStorage.getItem('serviceTypeSet')) == null) {
            const { history } = this.props;

            history.push("/");

        } else {
            this.getSetPageData(JSON.parse(localStorage.getItem('serviceTypeSet')),21)
        }
        this.props.getData();
        this.getDataSitesByuser();
        var _depotList = [];
        var _regionsList = [];
        this.props.depots.map((depot) => {
            _depotList.push({ value: depot.id, label: depot.name })
        })
        this.props.regions.filter(allret => "0".includes(allret.isSubRegion)).map((regions) => {
            _regionsList.push({ value: regions.id, label: regions.name })
        })
        this.setState({
            depotList: _depotList,
            regionsList: _regionsList
        })

        //console.log(this.props.details);
    }
    getSetPageData = (serviceTypeIs, Pageid) => {
        //console.log("this.props");
        //console.log(serviceTypeIs.serviceType);
        if (serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == Pageid)[0] == null) {
            const { history } = this.props;

            history.push("/not-allowed");
        }

    };
    getDataSitesByuser = async () => {
        var res = await getUserSites();
        var _allSite = [];
        //console.log("getUserSites");
        //console.log(res.data);
        if (res.success) {
            res.data.map((sites) => {
                _allSite.push({ value: sites.id, label: sites.siteName })
            })
            this.setState({
                allSite_loading: false,
                allSite: _allSite,
            });
        }
    };
    searchRegionmChange = (event) => {
        var dd = event
        //console.log(dd)
        var subRegion = [];
        var _depotList1 = [];
        var subRegionIdArray = [];

        this.props.regions.filter(allret => dd.map((n) => n).includes(allret.parentRegion?.id)).map((subR) => {
            subRegionIdArray.push(subR.id)
        })

        this.setState({
            regionIds: subRegionIdArray
        });

        //console.log(this.props.regions.filter(allret => dd.map((n) => n).includes(allret.parentRegion?.id)));
        subRegion = this.props.regions.filter(allret => dd.map((n) => n).includes(allret.parentRegion?.id));
        //console.log(this.props.depots.filter(alldate => subRegion.map((n) => n.id).includes(alldate.region?.id)));
        this.props.depots.filter(alldate => subRegion.map((n) => n.id).includes(alldate.region?.id)).map((depot) => {
            _depotList1.push({ value: depot.id, label: depot.name })
        })

        this.setState({
            depotList: _depotList1

        })

    }
    getDataexpot = async (data) => {

        var pageCount = 0;
        //console.log(data);
        var res = await getsiteAccessExport(data);
        //console.log("getsiteAccessExport");
        pageCount = res.data.pageCount;
        //console.log(pageCount);
        if (pageCount == 0) {

            this.setState({
                loading: false,
                data_list: []
            })
        }
        if (pageCount == 1) {
            this.setState({

                data_list: res.data.data,
                loading: false,

            });
        }
        if (pageCount > 1) {
            for (let i = 0; i < pageCount; i++) {
                this.getDataexpot2(i, pageCount)
                if (i == pageCount - 1) {
                    this.setState({
                        loading: false,

                    })
                }
            }
        }

        //console.log(res);






        // if (res.success) {
        //     this.setState({
        //         loading: false,
        //         data_list: res.data,
        //     });
        // }
    };
    getDataexpot2 = async (data, pagecout) => {
        //console.log(data);
        var form = new FormData();
        form.append("RequestDateFrom", this.state.startDate);
        form.append("RequestDateTo", this.state.endDate);
        form.append("teamMember", 1);
        form.append("siteIds", this.state.siteIds);
        form.append("depotIds", this.state.depotIds);
        form.append("regionIds", this.state.regionIds);
        form.append("pageNo", data);
        form.append("recodesPerPage",50);
        var res = await getsiteAccessExport(form);
        //console.log("getsiteAccessExport");
        //console.log(res);



        if (res.success) {
            this.setState({
                fullArray: [...this.state.fullArray, ...res.data.data]
            }, () => {
                //console.log("343434")
                //console.log(this.state.fullArray)
                this.setState({

                    data_list: this.state.fullArray,
                });

            })

        }
    };
    searchDepottemChange = (event) => {

        this.setState({
            depotIds: event
        });
    }
    searchSiteemChange = (event) => {

        this.setState({
            siteIds: event
        });
    }
    onChangeDate = (date, dateString) => {
        //console.log(date);
        //console.log(dateString);
        this.setState({
            startDate: dateString,
        });
    };
    onChangeDate2 = (date, dateString) => {
        //console.log(date);
        //console.log(dateString);
        this.setState({
            endDate: dateString,
        });
    };
    searchClick = () => {
        //console.log("search");
        if (this.state.startDate != "" && this.state.endDate != "") {
            this.setState({
                fullArray: [],
                data_list: []
            })

            var form = new FormData();
            form.append("RequestDateFrom", this.state.startDate);
            form.append("RequestDateTo", this.state.endDate);
            form.append("teamMember", 1);
            form.append("pageNo", 0);
            form.append("siteIds", this.state.siteIds);
            form.append("depotIds", this.state.depotIds);
            form.append("regionIds", this.state.regionIds);
            form.append("recodesPerPage",50);

            this.setState({
                loading: true,
            });
            this.getDataexpot(form);
        }

    };
    render() {
        const fontValue = { sz: "12", bold: true, fill: { patternType: "solid", fgColor: { rgb: "FF0B0713" } } };
        const { filterTable } = this.state;
        const dataSet1 = this.state.data_list;



        const StatisticDataSet = [
            {
                columns: [
                    // Sec 01
                    { title: "Access Permission Code", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 210 } },//char width 
                    { title: "Site Id", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Site Name", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width 
                    { title: "Depot", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width 
                    
                    { title: "Region", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width 
                    { title: "Sub Region", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width 
                  
                    { title: "Requester", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 100 } },//char width 
                    { title: "Requester Company", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width cManual_transport_amount
                    { title: "Name", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width cManual_transport_amount
                    { title: "Mobile No", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width cManual_transport_amount
                    { title: "Nic", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 100 } },//char width cManual_transport_amount
                    { title: "Company", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width cManual_transport_amount


                ],
                data: dataSet1?.map((data) => [

                    // Sec 01
                    { value: data.accessPermissionCode, style: { font: { sz: "12" } } },
                    { value: data.siteId ? (data.siteId) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteName ? (data.siteName) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.depot ? (data.depot) : ("Null"), style: { font: { sz: "12" } } },
                   
                    { value: data.region ? (data.region) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.subRegion ? (data.subRegion) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.userName ? (data.userName) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.company ? (data.company) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.visitorName ? (data.visitorName) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.visitorMobileNo ? (data.visitorMobileNo) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.visitorNic ? (data.visitorNic) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.visitorCompany ? (data.visitorCompany) : ("Null"), style: { font: { sz: "12" } } },



                ]),
            }
        ];

        const columns = [
            {
                title: "Access PermissionCode",
                dataIndex: "accessPermissionCode",
                key: "accessPermissionCode",
                render: (item) => <span>{item}</span>,
            },
            {
                title: "Site Id",
                dataIndex: "siteId",
                key: "siteId",
                render: (item) => <span>{item}</span>,
            }, {
                title: "Site Name",
                dataIndex: "siteName",
                key: "siteName",
                render: (item) => <span>{item}</span>,
            },
            {
                title: "Depot",
                dataIndex: "depot",
                key: "depot",
                render: (item) => <span>{item}</span>,
            },
            {
                title: "Region",
                dataIndex: "region",
                key: "region",
                render: (item) => <span>{item}</span>,
            },

            {
                title: "Requester",
                dataIndex: "userName",
                key: "userName",
                render: (item) => <span>{item}</span>,
            },
            {
                title: "Requester company",
                dataIndex: "company",
                key: "company",
                render: (item) => <span>{item}</span>,
            },
            {
                title: "Name",
                dataIndex: "visitorName",
                key: "visitorName",
                render: (item) => <span>{item}</span>,
            },
            {
                title: "Mobile No",
                dataIndex: "visitorMobileNo",
                key: "visitorMobileNo",
                render: (item) => <span>{item}</span>,
            },
            {
                title: "Nic",
                dataIndex: "visitorNic",
                key: "visitorNic",
                render: (item) => <span>{item}</span>,
            },
            {
                title: "Company",
                dataIndex: "visitorCompany",
                key: "visitorCompany",
                render: (item) => <span>{item}</span>,
            },

        ];
        const timestamp = Date.now();
        return (
            <div class="container-fluid">
                 <div class="row">
              <div class="col-sm-12">
                <div class="page-title-box">
                  <div class="row">
                    <div class="col">
                      <h4 class="page-title">Site Access Team Export</h4>
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Data Export </a></li>
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Site Access Team Export</a></li>

                      </ol>
                    </div>
                    <div class="col-auto align-self-center">
                      <a href="#" class="btn btn-sm btn-outline-primary" id="Dash_Date">
                        <span class="day-name" id="Day_Name">Today:</span>&nbsp;
                        <span class="" id="Select_date">{new Date().toUTCString().slice(5, 16)}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar align-self-center icon-xs ms-1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                      </a>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
                
                <div className="col-md-2 col-lg-2 col-xl-2">
                    <DatePicker
                        placeholder="Start Date"
                        style={{ width: "100%" }}
                        onChange={this.onChangeDate}
                    />
                </div>
                <div className="col-md-2 col-lg-2 col-xl-2">
                    <DatePicker
                        placeholder="End Date"
                        style={{ width: "100%" }}
                        onChange={this.onChangeDate2}
                    />
                </div>
                <div className="col-md-2 col-lg-2 col-xl-2">
                    <Select
                        mode="multiple"
                        showSearch
                        name="regionId"
                        onChange={(e) => this.searchRegionmChange(e)}
                        placeholder="Region"
                        style={{ width: "100%" }}

                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        }
                    // options={this.state.regionsList}

                    >
                        {this.state.regionsList.map((item, index) => {
                            return <Option value={item.value}>{item.label}</Option>;
                        })}
                    </Select>
                </div>
                <div className="col-md-3 col-lg-3 col-xl-3"> <Select
                    mode="multiple"
                    showSearch
                    name="depotId"
                    onChange={(e) => this.searchDepottemChange(e)}
                    placeholder="Depot"
                    style={{ width: "100%" }}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }
                // options={this.state.depotList}

                >
                    {this.state.depotList.map((item, index) => {
                        return <Option value={item.value}>{item.label}</Option>;
                    })}
                </Select>
                </div>
                <div className="col-md-3 col-lg-3 col-xl-3">
                    <Select
                        mode="multiple"
                        placeholder="Site"
                        style={{ width: "100%" }}
                        // options={this.state.allSite} 
                        loading={this.state.allSite_loading}
                        name="siteId"
                        onChange={(e) => this.searchSiteemChange(e)}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        }
                    >
                        {this.state.allSite.map((item, index) => {
                            return <Option value={item.value}>{item.label}</Option>;
                        })}
                    </Select>
                </div>
                <div className="col-lg-12 col-xl-12" style={{padding:10,textAlign: 'end'}}>
                        <button
                            onClick={this.searchClick}
                            class="btn btn-primary"
                        //style={{ float: "right" }}
                        >
                            <i class="fas fa-search"> </i> Search
                        </button>
                    
                </div>


                <div className="col-lg-12 col-xl-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="row ">
                                <div className="col">
                                    <h4 className="card-title">
                                        Report -Site Access Team Export{" "}
                                        <ExcelFile
                                            filename={"Site Access Team Export -" + timestamp}
                                            element={
                                                <button
                                                    class="btn btn-primary"
                                                    style={{ float: "right" }}
                                                >
                                                    <i class="fas fa-file-export"> </i>Site Access Team  Export
                                                </button>
                                            }
                                        >

                                            <ExcelSheet dataSet={StatisticDataSet} name="Site Access Team Export" />
                                        </ExcelFile>
                                    </h4>
                                    <div></div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">

                            <Table
                                size="small"
                                columns={columns}
                                // rowKey="id"
                                loading={this.state.loading}
                                dataSource={this.state.data_list}
                            />

                        </div>
                    </div>
                </div>
            </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    //console.log("state", state);
    return {
        organization_count: state.organization.length,
        regions: state.regions,
        depots: state.depots,
        sites_count: state.sites.length,
        vendor_count: state.vendor.length,
        roles_count: state.roles.length,
    };
}
export default withRouter(connect(mapStateToProps, { getDonationCategories, getData })(
    TeamMemberFullExport
)); 
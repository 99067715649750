import { ADD_ARTICLE , DATA_REQUESTED ,DONATION_CATEGORIES , ADD_VENDOR ,ADD_ORGANIZATION , ADD_REGION , ADD_DEPOT } from "../constants/action-types";
import {get} from '../api/api.client'  


export function addArticle(payload) {
  return { type: ADD_ARTICLE, payload };
}
export function addOrganization(payload) {
	 return { type: ADD_ORGANIZATION, payload };
  }

export function addVendor(payload) {
	return { type: ADD_VENDOR, payload };
 }

 export function addRegion(payload) {
	return { type: ADD_REGION, payload };
 }

 export function addDepot(payload) {
	return { type: ADD_DEPOT, payload };
 }

export function getData() {
  return { type:  DATA_REQUESTED  };
}

export function getData1() {
	return { type:  "DATA_REQUESTED1"  };
}

  
export async function getDonationListData() {  
	var payload = await get("/common/depot/getAll");
	return payload 
}

   export  function getDonationCategories(payload) {  
 
	return { type:  DONATION_CATEGORIES  , payload};
}



import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  message,
  notification,
  Row,
  Select,
  Table,
  Empty,
  Modal,
} from "antd";
import { data_set, gen_models } from "./data";
import { CSVLink, CSVDownload } from "react-csv";
import {
  CloseCircleOutlined,
  CopyOutlined,
  DatabaseOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EyeOutlined,
  FilterOutlined,
  HistoryOutlined,
  HomeOutlined,
  SendOutlined,
  SaveOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import "./App.css";
import {
  getPowerDbFilterData,
  getAllQueryTemplates,
  getQueryTemplatesCreate,
  getQueryTemplatesDelete,
  getAllDropdowns,
} from "../../api/index";
import { compose } from "redux";
import { headers } from "../../api/api.config";
import { get } from "../../api/api.client";

//import { ToastContainer, toast } from 'material-react-toastify';
//import 'material-react-toastify/dist/ReactToastify.css';

const { Option } = Select;

const QueryBuilder = () => {
  let firstTable = "";
  let relation = "";
  let firstTablePrimaryStatus = false;
  var tableList = [];
  const [formItems, setFormItems] = useState([
    {
      field_name: "",
      label: "",
      operator: "",
      selectedOperator: "",
      value: "",
      query_name: "",
      extra_fields: [],
      detail_view: false,
    },
  ]);
  const [sql, setSql] = useState("");
  const [operatorOptions, setOperatorOptions] = useState({});
  const [filterList, setFilterList] = useState([]);
  const [apiData, setApiData] = useState([]);
  const [generatedModels, setGeneratedModels] = useState([]);
  const [boolState, setBoolState] = useState(false);
  const [openDrawerDetails, setOpenDrawerDetails] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [filteredModels, setFilteredModels] = useState(generatedModels);
  const [messageApi, contextHolder] = message.useMessage();
  const [savedData, setSavedData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [fieldValidator, setFieldValidator] = useState("");
  const [templateItems, setTemplateItems] = useState([
    {
      templateName: "",
      templateDescription: "",
      headers: "",
      queryBody: "",
      createdAt: "",
      createdBy: "",
      id: "",
    },
  ]);
  const [showRapidFilterSection, setShowRapidFilterSection] = useState(false);
  const [showFilterSection, setShowFilterSection] = useState(true);
  const [showNormalFilterSection, setShowNormalFilterSection] = useState(true);
  const [fieldNames, setFieldNames] = useState([]); 
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(null);
  const handleFilterSection = (query, headers) => {
    resetTableSection();
    setShowNormalFilterSection(!showNormalFilterSection);
    setShowFilterSection(!showFilterSection);
    setShowRapidFilterSection(true);
    setFieldNames(headers);
    setSql(query);
  };
  const [assetsTypes, setAssetsTypes] = useState({
    dcduTypes: [],
    cabinetTypes: [],
  });

  const date = new Date();

  const handleSearch = (value) => {
    console.log(savedData);
    const lowercasedValue = value.toLowerCase();
    const filteredItems = savedData.filter((item) =>
      item.templateName.toLowerCase().includes(lowercasedValue)
    );
    setFilteredData(filteredItems);
    setSearchText(value);
  };

  // Load All Data When The Page Loads
  useEffect(() => {
    getAssetsTypes();
  }, []);

  useEffect(() => {
    console.log(assetsTypes);
  }, [assetsTypes]);

  const getAssetsTypes = async () => {
    var res = await getAllDropdowns();
    if (res.success) {
      setAssetsTypes({
        dcduTypes: res.data["Access Technologies"]["GSM"],
        cabinetTypes: res.data["Access Technologies"]["GSM"],
      });
    } else {
      message.warning("Some Data Can't Be Loaded");
    }
    console.log("===============================+++++++++++++++++++++++++++==============================");
    console.log(assetsTypes);
  };

  // Function to create a new template in the database
  const createTemplate = async () => {
    const fieldNames = filterList.map((item) => item.display_name);
    const requestBody = {
      createdBy: localStorage.getItem("userId"),
      templateName: templateName,
      templateDescription: "SQL Query Template Description",
      headers: fieldNames.join(","),
      queryBody: sql,
    };
    try {
      var res = await getQueryTemplatesCreate(requestBody);
      console.log(res);
      if (res.success) {
        if (res.data.status === "Error") {
          notification.error({
            message: "Template Creation Failed",
            description: res.data.message,
          });
        } else {
          console.log("Template created successfully:");
          notification.success({
            message: "Template Created Successfully",
            description:
              "Template Created Successfully, You can view the template in the template list, And you can use it in the future.",
          });
        }
      } else {
        notification.error({
          message: "Template Creation Failed",
          description: res?.data?.message,
        });
      }
    } catch (error) {
      notification.error({
        message: "Template Creation Failed",
        description: error.message,
      });
    }
  };
  
  // Function to retrieve all templates from the database
  const retriveAllTemplates = async () => {
    var res = await getAllQueryTemplates(localStorage.getItem("userId"));

    if (res.success) {
      console.log(res.data);
      if (res.data.status === "Error") {
        notification.error({
          message: "Template Retrieval Failed",
          description: res.data.message,
        });
      } else {
        console.log("Template retrieved successfully:", res.data.value);
        setSavedData(res.data.value);
      }
    } else {
      notification.error({
        message: "Template Retrieval Failed",
      });
    }
  };

  // Remove Template From The List and From The Database
  const deleteTemplate = async (templateId) => {
    try {
      var res = await getQueryTemplatesDelete(templateId);

      console.log(res);
      if (res.success) {
        if (res.data.status === "Error") {
          notification.error({
            message: "Template Deletion Failed",
            description: res.data.message,
          });
        } else {
          console.log("Template deleted successfully:");
          prepareData();
          notification.success({
            message: "Template Deleted Successfully",
            description:
              "Template Deleted Successfully, Template is no longer available in the template list.",
          });
        }
      } else {
        notification.error({
          message: "Template Deletion Failed",
          description: res?.data?.message,
        });
      }
    } catch (error) {
      notification.error({
        message: "Template Deletion Failed",
        description: error.message,
      });
    }
  };

  // Confirmation Modal to Delete the Template
  const showDeleteConfirmationModal = (templateId) => {
    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure you want to delete this Template?",
      okText: "Yes",
      cancelText: "No",
      onOk: () => deleteTemplate(templateId),
    });
  };

  // This function is used to show the modal and Handle Logic Behind it
  const showModal = () => {
    setIsModalVisible(true);
  };
  
  const handleOk = () => {
    console.log("Template Name:", templateName);
    setIsModalVisible(false);
    createTemplate();
  };
  
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleReload = () => {
    window.location.pathname = window.location.pathname;
  };

  const resetRapidFilterSection = () => {
    setShowRapidFilterSection(false);
    setShowFilterSection(true);
    setShowNormalFilterSection(true);

    setSql("");
    setFilterList([]);
    setApiData([]);
    setGeneratedModels([]);
    setBoolState(false);
  };

  const resetTableSection = () => {
    setApiData([]);
  };

  // That Function is used to get the operator options
  const filteredOptions = (value) => {
    var data = [];
    for (let i = 0; i < value.length; i++) data.push(data_set.fields[value[i]]);
    setFilterList(data);
  };

  // This function is used to add new form item
  const handleAddFormItem = () => {
    const newItem = {
      field_name: "",
      label: "",
      operator: "",
      value: "",
      query_name: "",
      extra_fields: [],
      detail_view: false,
      response_type: "",
      display_name: "",
    };
    setFormItems([...formItems, newItem]);
  };

  // This function is used to get the model data
  const fetchModelData = async (sql, fieldNames, targetData) => {
    console.log("IN THIS FUNCTION 01 ================================================================");
    console.log(targetData);
    if (targetData.toLowerCase() === "DCDU- Type") {
      console.log("IN THIS FUNCTION 02 ================================================================");
      const models = getAssetsTypes.dcduTypes.map((name, index) => ({
        id: (index + 1).toString(),
        model: name,
      }));
      setGeneratedModels(models);
    } else {
      const formData = new FormData();
      formData.append("query", sql);
      formData.append("headers", fieldNames);
      var generatedModels = [];
      var res = await getPowerDbFilterData(formData);
      if (res.success) {
        if (Array.isArray(res.data)) {
          const uniqueNames = [...new Set(res.data.map((item) => item.name))];
          console.log("Unique Names: ", uniqueNames);
          const models = uniqueNames.map((name, index) => ({
            id: (index + 1).toString(),
            model: name,
          }));
          setGeneratedModels(models);
        } else {
          console.error("API Response Data is not an array");
        }
      }
    }

    //label
  };

  // This function is used to remove the form item
  const handleRemoveFormItem = (index) => {
    const updatedFormItems = formItems.filter((item, i) => i !== index);
    setFormItems(updatedFormItems);
  };

  // This function is used to get the details
  const getDetails = (index) => {
    const updatedFormItems = formItems.filter((item, i) => i === index);
    console.log("------------------------------------------------------------------------------");
    console.log(updatedFormItems);
    if (updatedFormItems.length === 1) {
      if (updatedFormItems[0].detail_view === true) {
        const execute_query = updatedFormItems[0].query_name;
        const extra_fields = updatedFormItems[0].extra_fields;
        const label = updatedFormItems[0].label;
        fetchModelData(execute_query, extra_fields, label);
      }
    } else {
      console.log("No matching item found or multiple items found.");
    }
  };

  // This function is used to handle the label change
  const handleLabelChange = (index, value) => {
    var selectedItem = data_set.fields.filter(function (el) {
      return el.display_name === value;
    });

    if (selectedItem.length == 0) {
      return;
    }

    const updatedFormItems = [...formItems];
    updatedFormItems[index].label = selectedItem[0].field_name;
    updatedFormItems[index].field_name = selectedItem[0].field_name;
    updatedFormItems[index].table_prefix = selectedItem[0].table_prefix;
    updatedFormItems[index].table_name = selectedItem[0].table_name;
    updatedFormItems[index].response_type = selectedItem[0].response_type;
    updatedFormItems[index].operator = selectedItem[0].operotors;
    updatedFormItems[index].selectedOperator = "";
    updatedFormItems[index].value = "";
    updatedFormItems[index].query_name = selectedItem[0].query_name;
    updatedFormItems[index].extra_fields = selectedItem[0].extra_fields;
    updatedFormItems[index].detail_view = selectedItem[0].detail_view;
    updatedFormItems[index].response_type = selectedItem[0].response_type;
    updatedFormItems[index].display_name = selectedItem[0].display_name;

    console.log(selectedItem[0].detail_view);

    if (selectedItem[0].detail_view === true) {
      setBoolState(true);
    }
    setFormItems(updatedFormItems);
    var data = {};
    data[value] = selectedItem[0].operotors;
    setOperatorOptions(data);
    console.log(formItems);
  };

  const handleFormItemChange = (index, key, value) => {
    const updatedFormItems = [...formItems];
    updatedFormItems[index][key] = value;
    console.log(updatedFormItems);
    setFormItems(updatedFormItems);
  };

  function isValidResponseType(inputType, inputValue) {
    // Define an array of valid response types
    const validResponseTypes = ["text", "number"];

    // Check if the inputType is a valid response type
    if (validResponseTypes.includes(inputType)) {
      // Depending on the response type, you can perform additional checks on the inputValue
      if (inputType === "text") {
        // Check if the inputValue is a string
        setFieldValidator(true);
        return typeof inputValue === "string";
      } else if (inputType === "number") {
        // Check if the inputValue is a number
        setFieldValidator(true);
        return typeof inputValue === "number";
      }
    }
    setFieldValidator(false);
    return false; // Input type is not valid or doesn't match the input value's type
  }

  const generateSQLQuery = () => {
    setApiData([]);

    let selectedItems = "";
    for (let i = 0; i < filterList.length; i++) {
      selectedItems +=
        filterList[i].table_prefix +
        "." +
        filterList[i].field_name +
        " " +
        filterList[i].display_name +
        (filterList.length > 1 && filterList.length !== i + 1 ? "," : "");
    }
    let sqlQuery = "SELECT " + selectedItems + " FROM ";

    if (filterList.length > 0 && filterList[0].field_name !== "") {
      // var selectedItem = data_set.fields.filter(function (el) {
      //     return el.field_name === filterList[0].field_name;
      // });
      firstTable = filterList.length > 1 ? "site" : filterList[0].table_name;
      tableList.push(filterList.length > 1 ? "site" : filterList[0].table_name);
      firstTablePrimaryStatus =
        filterList.length > 1 ? true : filterList[0].is_primary_table;
      let table_prefix =
        filterList.length > 1 ? "tbl_site" : filterList[0].table_prefix;
      sqlQuery += `${firstTable} ${table_prefix}`;

      for (let i = filterList.length > 1 ? 0 : 1; i < filterList.length; i++) {
        var selectedItem = data_set.fields.filter(function (el) {
          return (
            el.field_name === filterList[i].field_name &&
            el.table_name === filterList[i].table_name
          );
        });

        const tableName = selectedItem[0].table_name;
        const table_prefix = selectedItem[0].table_prefix;
        console.log(tableList);
        console.log(tableName);
        console.log(checkTableAlreadyAvailable(tableName));
        console.log("------------------------------------------");
        if (!checkTableAlreadyAvailable(tableName)) {
          if (selectedItem[0].is_primary_table && firstTablePrimaryStatus) {
            const joinCondition = getJoinCondition(firstTable, tableName);
            sqlQuery += ` INNER JOIN ${tableName} ${table_prefix} ON ${joinCondition}`;
          } else {
            if (
              !checkTableAlreadyAvailable(selectedItem[0].primary_table_name)
            ) {
              const primaryJoinCondition = getJoinCondition(
                firstTable,
                selectedItem[0].primary_table_name
              );
              sqlQuery += ` INNER JOIN ${selectedItem[0].primary_table_name} ${selectedItem[0].primary_table_prefix} ON ${primaryJoinCondition}`;
            }
            const joinCondition = getJoinCondition(
              selectedItem[0].primary_table_name,
              tableName
            );
            sqlQuery += ` INNER JOIN ${tableName} ${table_prefix} ON ${joinCondition}`;
          }
        }
      }
      if (formItems.length > 0 && formItems[0].field_name !== "") {
        for (let i = 0; i < formItems.length; i++) {
          const item = formItems[i];
          if (i === 0) {
            sqlQuery += ` WHERE ${item.table_prefix}.${item.label} ${item.selectedOperator}`;
            if (item.selectedOperator == "LIKE") {
              sqlQuery +=
                item.response_type == "text"
                  ? ` '%${item.value}%'`
                  : ` ${item.value}`;
            } else {
              sqlQuery +=
                item.response_type == "text"
                  ? `'${item.value}'`
                  : ` ${item.value}`;
            }
          } else {
            if (item.selectedOperator == "LIKE") {
              sqlQuery += ` AND ${item.table_prefix}.${item.label} ${item.selectedOperator} '%${item.value}%'`;
            } else {
              sqlQuery += ` AND ${item.table_prefix}.${item.label} ${item.selectedOperator} ${item.value}`;
            }
          }
        }
      }
    } else {
      if (filterList.length > 0 && filterList[0].field_name !== "") {
        sqlQuery += filterList[0].table_name;
      } else {
        sqlQuery = "";
      }
    }
    setSql(sqlQuery);
  };

  // This function is used to check the table already available
  const checkTableAlreadyAvailable = (tableName) => {
    const list = tableList;
    const check = list.filter(function (el) {
      return el === tableName;
    });

    if (check.length > 0) {
      return true;
    }
    return false;
  };

  // This function is used to copy the text
  const copyText = (text) => {
    navigator.clipboard.writeText("");
    navigator.clipboard.writeText(text);
    setGeneratedModels([]);
    setOpenDrawerDetails(false);
  };

  const getJoinCondition = (tableName, primaryTable) => {
    const count = tableList.filter(function (el) {
      return el === tableName;
    });
    if (count.length == 0) {
      tableList.push(tableName);
    }

    const primaryTableCOunt = tableList.filter(function (el) {
      return el === primaryTable;
    });
    if (primaryTableCOunt.length == 0) {
      tableList.push(primaryTable);
    }

    relation = data_set.relations.filter(function (el) {
      return el.main_table === primaryTable && el.sub_table === tableName;
    })[0];
    if (relation === undefined) {
      relation = data_set.relations.filter(function (el) {
        return el.main_table === tableName && el.sub_table === primaryTable;
      })[0];
    }

    return (
      relation.main_table_prefix +
      "." +
      relation.main_table_id +
      " = " +
      relation.sub_table_prefix +
      "." +
      relation.sub_table_id
    );
  };

  const onClose = () => {
    setOpenDrawerDetails(false);
  };

  const apiResponse = () => {
    const fieldNames = filterList.map((item) => item.display_name);
    fetchApiData1(fieldNames.join(","));
  };
  const rapidAPIResponse = () => {
    fetchApiData(fieldNames);
  };
  // Function to fetch data from the API

  
  
  const fetchApiData1 = async (fieldNames) => {
    const formData = new FormData();
    formData.append("query", sql);
    formData.append("headers", fieldNames);

    //const requestTimestamp = new Date().toISOString(); // Get current date and time as a string
    var res = await getPowerDbFilterData(formData);

    if (res.success) {
      console.log("API Response Data: ", res.data);
      setApiData(res.data); // Set the API data in the state
      
      // const requestData = {
      //   query: sql,
      //   headers: fieldNames,
      //   responseCode: res.status,
      // };
      // localStorage.setItem(requestTimestamp, JSON.stringify(requestData));

      notification.success({
        message: "Success",
        description: "API request was successful.",
      });
    } else {
      notification.error({
        message: "Error",
        description: "Error fetching data: " + res.message,
      });
    }
  };

  const fetchApiData = async (fieldNames) => {
    const formData = new FormData();
    formData.append("query", sql);
    formData.append("headers", fieldNames);

    var res = await getPowerDbFilterData(formData);

    if (res.success) {
      console.log("API Response Data: ", res.data);
      setApiData(res.data); // Set the API data in the state
      notification.success({
        message: "Success",
        description: "API request was successful.",
      });
    } else {
      notification.error({
        message: "Error",
        description: "Error fetching data: " + res.message,
      });
    }
  };

  

  // const fetchApiData = (fieldNames) => {
  //   const formData = new FormData();
  //   formData.append("query", sql);
  //   formData.append("headers", fieldNames);

  //   const requestTimestamp = new Date().toISOString(); // Get current date and time as a string

  //   fetch("https://dns.eimsky.com/common/powerSiteData/getPowerDbFilterData", {
  //     method: "POST",
  //     body: formData,
  //   })
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error("Network response was not ok");
  //       }
  //       console.log("API Response Code : ", response.status);
  //       return response
  //         .json()
  //         .then((data) => ({ data, status: response.status }));
  //     })
  //     .then((response) => {
  //       if (response.status === 200) {
  //         console.log("API Response Data: ", response.data);
  //         setApiData(response.data); // Set the API data in the state

  //         const requestData = {
  //           query: sql,
  //           headers: fieldNames,
  //           responseCode: response.status,
  //         };

  //         if (isRequestDataAlreadyStored(requestData)) {
  //           console.log("Request data already exists in localStorage.");
  //         } else {
  //           const storageKey = requestTimestamp;
  //           localStorage.setItem(storageKey, JSON.stringify(requestData));
  //           console.log("Request data saved successfully.");
  //         }
  //         notification.success({
  //           message: "Success",
  //           description: "API request was successful.",
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //       // Show an error notification
  //       notification.error({
  //         message: "Error",
  //         description: "Error fetching data: " + error.message,
  //       });
  //     });

  //   retrieveSavedData();
  // };
  
  // const isRequestDataAlreadyStored = (requestData) => {
  //   return Object.keys(localStorage).some((key) => {
  //     try {
  //       const storedData = JSON.parse(localStorage.getItem(key));
  //       // Customize the comparison logic based on your data structure
  //       return (
  //         storedData &&
  //         storedData.query === requestData.query &&
  //         storedData.headers === requestData.headers
  //       );
  //     } catch (error) {
  //       // Handle potential JSON parsing errors
  //       console.error("Error parsing stored data:", error);
  //       return false;
  //     }
  //   });
  // };

  // // Function to retrieve saved data from local storage
  const retrieveSavedData = () => {
    const data = [];
    for (let key in localStorage) {
      if (localStorage.hasOwnProperty(key)) {
        try {
          const item = JSON.parse(localStorage.getItem(key));
          if (item) {
            console.log("Item: ", item);
            data.push({ timestamp: key, ...item });
          }
        } catch (error) {
          console.error("Error parsing local storage data:", error);
        }
      }
    }
    setSavedData(data);
  };

  const prepareData = () => {
    //setSavedData([]);
    retriveAllTemplates();
    setVisible(true);
  };

  const columns = apiData[0]
    ? Object.keys(apiData[0]).map((key) => ({
        title: key
          .replace(/_/g, " ")
          .replace("value", "")
          .replace(/\b\w/g, (l) => l.toUpperCase()), // Format the title here
        dataIndex: key,
        key,
      }))
    : [];

  const updateFilterText = (e) => {
    const searchText = e.target.value;
    setFilterText(searchText);

    if (searchText) {
      setFilterText(searchText);

      const filtered = generatedModels.filter((item) =>
        item.model.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredModels(filtered);
    } else {
      setFilterText("");
      setFilteredModels("");
    }
  };

  const spanStyles = {
    fontWeight: "bold",
    fontSize: "14.5px",
  };

  const dataShow = {
    fontSize: "14.5px",
    marginTop: "20px",
  };
  const alignment = {
    padding: "10px",
  };

  return (
    <div style={{ marginTop: "5px" }} className="filter-container">
      <div className="row">
        <div className="col-sm-12">
          <div className="page-title-box">
            <div className="row">
              <div className="col">
                <h4 className="page-title" style={{ fontWeight: "bold" }}>
                  Cross Table Filteration
                </h4>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="javascript:void(0);">Power DB</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="javascript:void(0);">Cross Table Filteration</a>
                  </li>
                </ol>
              </div>
              <div className="col-auto align-self-center">
                <a
                  href="#"
                  className="btn btn-sm btn-outline-primary"
                  id="Dash_Date"
                >
                  <span className="day-name" id="Day_Name">
                    Today:
                  </span>
                  &nbsp;
                  <span className="" id="Select_date">
                    {new Date().toUTCString().slice(5, 16)}
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="feather feather-calendar align-self-center icon-xs ms-1"
                  >
                    <rect
                      x="3"
                      y="4"
                      width="18"
                      height="18"
                      rx="2"
                      ry="2"
                    ></rect>
                    <line x1="16" y1="2" x2="16" y2="6"></line>
                    <line x1="8" y1="2" x2="8" y2="6"></line>
                    <line x1="3" y1="10" x2="21" y2="10"></line>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <Drawer
          title="SAVED API RESPONSES"
          width={500}
          onClose={() => setVisible(false)}
          visible={visible}
          onShow={retrieveSavedData}
        >
          {/*<h3>No Of All Saved Requests : {savedData.length}</h3>*/}
          {/*
                        {
            "id": 1,
            "createdBy": 1,
            "templateName": "SampleTemplate-12",
            "templateDescription": "This is a sample template with an advanced SQL query",
            "headers": "SampleHeaders",
            "queryBody": "SELECT * FROM your_table WHERE column_name = 'example' AND another_column > 107;",
            "createdAt": "2023-12-20T16:24:28.000+05:30"
        },
    */}

          {/*{savedData.map((dataItem, index) => (
                        <div key={index} className="action-container" style={alignment}>
                            <p><span style={spanStyles}>Template Name:</span> {dataItem.templateName}</p>
                            <p><span style={spanStyles}>SQL Query:</span> {dataItem.queryBody}</p>
                            <p><span style={spanStyles}>Target Headers:</span> {dataItem.headers}</p>
                            <p><span style={spanStyles}>Create Date:</span> {dataItem.createdAt}</p>

                            <Button type="primary" onClick={() => {
                                handleFilterSection(dataItem.queryBody, dataItem.headers);
                                setVisible(false);
                            }}>
                                Load Template
                            </Button>
                            <Button type="primary" danger style={{ marginLeft: '10px' }}
                                onClick={() => {
                                    showDeleteConfirmationModal(dataItem.id);
                                    setVisible(false)
                                }}>
                                Delete Template
                            </Button>
                        </div>
                            ))} */}
          <div>
            <Input.Search
              placeholder="Search by template name"
              value={searchText}
              onChange={(e) => handleSearch(e.target.value)}
              style={{ marginBottom: "10px" }}
            />
            {filteredData != null ? (
              <>
                {[...filteredData]
                  .sort((a, b) => b.id - a.id)
                  .map((dataItem, index) => (
                    <div
                      key={index}
                      className="action-container"
                      style={alignment}
                    >
                      <p>
                        <span style={spanStyles}>Template Name:</span>{" "}
                        {dataItem.templateName}
                      </p>
                      <p>
                        <span style={spanStyles}>SQL Query:</span>{" "}
                        {dataItem.queryBody}
                      </p>
                      <p>
                        <span style={spanStyles}>Target Headers:</span>{" "}
                        {dataItem.headers}
                      </p>
                      <p>
                        <span style={spanStyles}>Create Date:</span>{" "}
                        {dataItem.createdAt}
                      </p>

                      <Button
                        type="primary"
                        onClick={() => {
                          handleFilterSection(
                            dataItem.queryBody,
                            dataItem.headers
                          );
                          setVisible(false);
                        }}
                      >
                        Load Template
                      </Button>
                      <Button
                        type="primary"
                        danger
                        style={{ marginLeft: "10px" }}
                        onClick={() => {
                          showDeleteConfirmationModal(dataItem.id);
                          setVisible(false);
                        }}
                      >
                        Delete Template
                      </Button>
                    </div>
                  ))}
              </>
            ) : (
              <>
                {[...savedData]
                  .sort((a, b) => b.id - a.id)
                  .map((dataItem, index) => (
                    <div
                      key={index}
                      className="action-container"
                      style={alignment}
                    >
                      <p>
                        <span style={spanStyles}>Template Name:</span>{" "}
                        {dataItem.templateName}
                      </p>
                      <p>
                        <span style={spanStyles}>SQL Query:</span>{" "}
                        {dataItem.queryBody}
                      </p>
                      <p>
                        <span style={spanStyles}>Target Headers:</span>{" "}
                        {dataItem.headers}
                      </p>
                      <p>
                        <span style={spanStyles}>Create Date:</span>{" "}
                        {dataItem.createdAt}
                      </p>

                      <Button
                        type="primary"
                        onClick={() => {
                          handleFilterSection(
                            dataItem.queryBody,
                            dataItem.headers
                          );
                          setVisible(false);
                        }}
                      >
                        Load Template
                      </Button>
                      <Button
                        type="primary"
                        danger
                        style={{ marginLeft: "10px" }}
                        onClick={() => {
                          showDeleteConfirmationModal(dataItem.id);
                          setVisible(false);
                        }}
                      >
                        Delete Template
                      </Button>
                    </div>
                  ))}
              </>
            )}
          </div>
        </Drawer>
      </div>

      {showFilterSection && (
        <div id="filterSection">
          <Divider orientation="left">
            <span
              style={{ fontWeight: "bold", fontSize: "14.5px", color: "gray" }}
            >
              primary Filter Section
            </span>
          </Divider>
          <Form
            className="action-container"
            style={{ padding: "18px 15px 0px 11px" }}
          >
            <div style={{ display: "flex" }}>
              <Form.Item className="main-dropdown">
                <Select
                  mode="multiple"
                  style={{ width: 450 }}
                  onChange={(value) => filteredOptions(value)}
                  placeholder="Please Select"
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  } // Define the search filter
                >
                  {data_set.fields.map((column, index) => {
                    return (
                      <Option key={index} value={index}>
                        {column.label}
                      </Option>
                    );
                  })}
                </Select>

                <Button
                  type="primary"
                  shape="circle"
                  onClick={() => prepareData()}
                  style={{ marginLeft: "10px" }}
                >
                  <HistoryOutlined />
                </Button>
              </Form.Item>
            </div>
          </Form>

          <Divider orientation="left">
            <span
              style={{ fontWeight: "bold", fontSize: "14.5px", color: "gray" }}
            >
              Advanced Filter Section
            </span>
          </Divider>
          <div>
            <Form className="action-container">
              <Button
                type="dashed"
                className="main-dropdown"
                onClick={handleAddFormItem}
                style={{ width: "14%", marginRinght: "20px" }}
              >
                {<FilterOutlined />}
                New Filter
              </Button>

              <Button
                type="primary"
                className="main-dropdown"
                onClick={generateSQLQuery}
              >
                Prepare Query
                <DatabaseOutlined />
              </Button>

              <Button
                type="primary"
                danger
                className="main-dropdown"
                onClick={handleReload}
              >
                Force Reload
                {<DeleteOutlined />}
              </Button>

              <div className="main-advanded-filter">
                {formItems.map((item, index) => (
                  <div
                    key={index}
                    style={{ display: "flex", marginBottom: "-10px" }}
                  >
                    {console.log(item.response_type)}
                    <Form.Item>
                      <Select
                        style={{ width: 250 }}
                        placeholder="Label"
                        value={item.display_name}
                        onChange={(value) => handleLabelChange(index, value)}
                      >
                        .value
                        {filterList &&
                          filterList.map((column, index) => {
                            console.log(
                              index,
                              column.field_name,
                              column.label,
                              column.value
                            );
                            return (
                              <Option key={index} value={column.display_name}>
                                {column.label}
                              </Option>
                            );
                          })}
                        {/* Add more label options as needed */}
                      </Select>
                    </Form.Item>
                    <Form.Item>
                      <Select
                        style={{ width: 100, marginLeft: 4 }}
                        placeholder="Operator"
                        value={item.selectedOperator}
                        onChange={(value) =>
                          handleFormItemChange(index, "selectedOperator", value)
                        }
                      >
                        .value.value
                        {item.operator &&
                          item.operator.map((op) => (
                            <Option key={op.key} value={op.value}>
                              {op.lable}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                    <Form.Item>
                      <Input
                        type={item.response_type == "text" ? "text" : "number"}
                        style={{ width: 150, marginLeft: 4 }}
                        placeholder="Value"
                        value={item.value}
                        onChange={(e) =>
                          handleFormItemChange(index, "value", e.target.value)
                        }
                      />
                    </Form.Item>
                    <Form.Item>
                      <CloseCircleOutlined
                        style={{ marginLeft: 10, color: "red" }}
                        onClick={() => handleRemoveFormItem(index)}
                      />
                    </Form.Item>
                    {item.detail_view && (
                      <Form.Item>
                        <EyeOutlined
                          style={{ marginLeft: 10, fontSize: "16px" }}
                          onClick={() => {
                            const fieldValus = getDetails(index);
                            setOpenDrawerDetails(true);
                          }}
                        />
                      </Form.Item>
                    )}
                  </div>
                ))}
              </div>
            </Form>
          </div>
        </div>
      )}

      {showRapidFilterSection && (
        <div>
          <Divider orientation="left">
            <span
              style={{ fontWeight: "bold", fontSize: "14.5px", color: "gray" }}
            >
              Rapid Filter Section
            </span>
          </Divider>
          <div className="action-container">
            {sql !== "" && (
              <div className="container-x">
                <l>
                  <li style={{ marginTop: "10px" }}>
                    <span style={spanStyles}>Auto Generated Query :</span>{" "}
                    <fire style={dataShow}>{sql}</fire> <br />
                  </li>

                  <li style={{ marginTop: "10px" }}>
                    <span style={spanStyles}>Target Field List :</span>
                    <span style={dataShow}>
                      {filterList.map((item) => item.display_name).join(", ")}
                    </span>
                    <br />
                  </li>

                  <Button
                    type="primary"
                    onClick={rapidAPIResponse}
                    style={{ marginLeft: "10px" }}
                    className="main-dropdown"
                  >
                    Request Data <SendOutlined />
                  </Button>

                  <Button
                    type="primary"
                    danger
                    onClick={resetRapidFilterSection}
                    style={{ marginLeft: "10px" }}
                    className="main-dropdown"
                  >
                    Filter Home <HomeOutlined />
                  </Button>
                </l>
              </div>
            )}
          </div>
        </div>
      )}

      {showNormalFilterSection && (
        <div className="action-container">
          {sql !== "" && (
            <div className="container-x">
              <l>
                <li>
                  <span style={spanStyles}>Target Field List :</span>{" "}
                  <fire style={dataShow}>
                    {filterList.map((item) => item.label).join(", ")}
                  </fire>
                </li>

                <li style={{ marginTop: "10px" }}>
                  <span style={spanStyles}>Auto Generated Query :</span>{" "}
                  <fire style={dataShow}>{sql}</fire> <br />
                </li>

                <li style={{ marginTop: "10px" }}>
                  <span style={spanStyles}>Generated Time And Date</span>{" "}
                  <fire style={dataShow}>{`${date.toString()}`}</fire> <br />
                </li>

                <Button
                  type="primary"
                  onClick={apiResponse}
                  style={{ marginLeft: "10px" }}
                  className="main-dropdown"
                >
                  Request Data <SendOutlined />
                </Button>
              </l>
            </div>
          )}
        </div>
      )}

      <Drawer
        title="Detail View"
        placement="right"
        onClose={onClose}
        visible={openDrawerDetails}
      >
        <>
          <Form.Item>
            <Input
              placeholder="Filter Models"
              value={filterText}
              onChange={updateFilterText}
            />
          </Form.Item>

          <div className="action-container">
            {filteredModels.length != 0 &&
              filteredModels.map((item) => (
                <Row type="flex" justify="start" style={{ marginTop: "4px" }}>
                  <Col span={24}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span className="main-detailview">
                        {item.id}. {item.model}
                      </span>
                      <CopyOutlined
                        onClick={() =>
                          copyText(
                            filteredModels.map((item) => item.model).join("\n")
                          )
                        }
                        style={{
                          fontSize: "12px",
                          cursor: "pointer",
                          marginRight: "10px",
                          color: "#001eca73",
                          marginLeft: "20px",
                        }}
                        className="main-detailview"
                      />
                    </div>
                  </Col>
                </Row>
              ))}
          </div>

          <div className="action-container">
            <h3 level={3} className="main-detailview">
              Detail View
            </h3>
            {generatedModels.map((item) => (
              <Row type="flex" justify="start" key={item.id}>
                <Col span={24}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span className="main-detailview">
                      {item.id}. {item.model}
                    </span>
                    <CopyOutlined
                      onClick={() => copyText(item.model)}
                      style={{
                        fontSize: "12px",
                        cursor: "pointer",
                        marginRight: "10px",
                        color: "#001eca73",
                        marginLeft: "20px",
                      }}
                      className="main-detailview"
                    />
                  </div>
                </Col>
              </Row>
            ))}
          </div>
        </>
      </Drawer>

      <Modal
        title="Save As Template"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Enter template name:</p>
        <Input
          value={templateName}
          onChange={(e) => setTemplateName(e.target.value)}
        />

        <p>Target Headers List:</p>
        <Input.TextArea
          style={{ color: "black" }}
          value={filterList.map((item) => item.label).join(", ")}
          disabled
        />

        <p>Template Query:</p>
        <Input.TextArea
          style={{ color: "black" }}
          value={sql}
          rows={4}
          disabled
        />
      </Modal>

      {apiData.length != 0 && (
        <div className="action-container">
          <Button
            type="primary"
            style={{ width: "12%" }}
            icon={<DownloadOutlined />}
            className="main-dropdown"
          >
            <CSVLink
              data={apiData}
              filename={{ date }.date + ".csv"}
              icon={<DownloadOutlined />}
              target="_blank"
            >
              <x style={{ color: "white" }}> Export Data</x>
            </CSVLink>
          </Button>

          <Button
            type="primary"
            style={{ width: "19%", marginLeft: "10px" }}
            onClick={showModal}
            className="main-dropdown"
          >
            <SaveOutlined />
            Save As Template
          </Button>

          {console.log(columns)}
          {apiData && (
            <Table
              className="table-container"
              columns={columns} // Define column configuration (e.g., column names)
              dataSource={apiData} // Pass the API data as the data source
              bordered
              size="small"
            />
          )}
        </div>
      )}

      {apiData.length === 0 && (
        <div className="action-container">
          <Empty style={{ padding: "20px" }} description="No Data Found" />
        </div>
      )}
    </div>
  );
};

export default QueryBuilder;

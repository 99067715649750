import React, { Component } from 'react'; 
import SimpleReactValidator from 'simple-react-validator';
import {createNewContract} from '../api/index'
import { connect } from "react-redux"; 
import {addDepot} from '../actions/index';
import { ToastContainer, toast } from 'material-react-toastify';
import {getAllContracts} from '../api/index';

 class Contract extends Component {

	constructor(props) {
		super(props) 
		this.validator = new SimpleReactValidator();
		this.state ={
			vendor : 0, 
			contracts : [] 
		}
	}

	componentDidMount(){  
		this.getAllContracts()
   }

   getAllContracts = async() =>{
	   var res = await getAllContracts();
	   if(res.success){
		   this.setState({
			contracts : res.data
		   })
	   } 
   }

	createContract = async() =>{
		if (this.validator.allValid()) {
			var data = {
				 	"vendor": this.props.vendors[this.state.vendor], 
					 
			} 
			var res = await createNewContract(data);
		 
			if(res.success){
				toast.success("Successfully Created")
				 this.getAllContracts()
				 
			} 
		  } else {
			this.validator.showMessages();
			// rerender to show messages for the first time
			// you can use the autoForceUpdate option to do this automatically`
			this.forceUpdate();
		  }
	}

	formItemChange = (event) =>{
        var obj = {};
		obj[event.target.name] = event.target.value; 
		this.setState(obj);  
	 }
	 
 
	render(){ 
		return (
			<div>  
  					<div className="row">
                        <div className="col-lg-5">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Create Contract</h4>
                                    {/* <p className="text-muted mb-0">Basic example to demonstrate Bootstrap’s form styles.</p>  */}
                                </div> 
                                <div className="card-body">
                                    <form>
                                        
										<div className="mb-3">
                                            <label className="form-label" for="exampleInputPassword1">Vendor</label>
                                            <div className="col-sm-12">  
                                            <select className="select2 form-control mb-3 custom-select" name="vendor" onChange={(e)=> this.formItemChange(e)}  style={{width: '100%' ,height:'36px'}}>
                                               
											   
											   {
												   this.props.vendors.map((vendor , i)=>{
														return <option value={i}>{vendor.name}</option>
												   })
											   }    
                                            </select>
											{/* <p style={{color:"#f5325c"}}>{this.validator.message('description', this.state.description, 'required')}</p> */}
                                        </div> 
											
										</div>
                                        {/* <div className="mb-3">
                                            <div className="form-check">
                                                <input type="checkbox" className="form-check-input" id="InlineCheckbox" />
                                                <label className="form-check-label" for="InlineCheckbox">Check me out</label>
                                            </div>
                                        </div>   */}
                                        
                                    </form>          
									<button type="submit" className="btn btn-primary" onClick={()=> this.createContract()}>Submit</button>                                  
                                </div> 
                            </div> 
                        </div> 
                        <div className="col-lg-7">
                            <div className="card">
							<div className="card-header">
                                    <h4 className="card-title">Contracts</h4>
                                    {/* <p className="text-muted mb-0">
                                        Use <code>.table-striped</code> to add zebra-striping to any table row
                                        within the <code>&lt;tbody&gt;</code>.
                                    </p> */}
                                </div> 
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table table-striped mb-0">
                                            <thead>
                                            <tr>
                                                <th>ID</th> 
												<th>Vendor Name</th> 
												<th>Vendor Code</th> 
                                                <th className="text-end">Action</th>
                                            </tr>
                                            </thead>
                                            <tbody>
											{this.state.contracts.map((contract , i) => {
											  return <tr>
											   <td>{contract.id}</td> 
											   <td>{contract.vendor.name}</td> 
											   <td>{contract.vendor.code}</td> 
											   
											   <td className="text-end">                                                       
												   <a href="#"><i className="las la-pen text-secondary font-16"></i></a>
												   <a href="#"><i className="las la-trash-alt text-secondary font-16"></i></a>
											   </td>
										   </tr>
											})}
                                            
                                             
                                             </tbody>
                                        </table> 
                                    </div> 
                                </div>
                            </div> 
                        </div> 
                    </div> 
					<ToastContainer position="top-right" autoClose={5000}/>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		addDepot: data => dispatch(addDepot(data))
	};
  }
 
function mapStateToProps(state) {  
	return {
		vendors: state.vendor, 
	};
  }
  
  

  export default connect(
	mapStateToProps, mapDispatchToProps
  )(Contract); 


import React, { Component } from "react";
import { Table, Input, Tag, Space, DatePicker } from "antd";
import ReactExport from "react-data-export";
import { getfullExport,getfullExportNew } from "../../api/index";
import ReactLoading from 'react-loading';
import { getDonationCategories, getData } from "../../actions/index";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'

const moment = require('moment');




const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const { RangePicker } = DatePicker;
class FullExport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      filterTable: null,
      data_list: [],
      startDate: "",
      endDate: "",
      loading:false,

    };
  }

  componentDidMount() {

    if (JSON.parse(localStorage.getItem('serviceTypeSet')) == null) {
      const { history } = this.props;
   
      history.push("/");
     
    } else {
      this.getSetPageData(JSON.parse(localStorage.getItem('serviceTypeSet')) , 14)
    }

    this.props.getData();

   
    
    
  }
  getSetPageData =(serviceTypeIs,Pageid) =>  {
    //console.log("this.props");
    //console.log(serviceTypeIs.serviceType);
    if(serviceTypeIs.serviceType.filter(checkuser=>checkuser?.id==Pageid)[0] == null ){
      const { history } = this.props;
   
      history.push("/not-allowed");
    }
    
  };
  getDataexport = async (data) => {
    this.setState({
      loading: true,
      data_list: []
    })
    var res = await getfullExport(data);
    console.log(res);
    if (res.success) {
      this.setState({
        loading: false,
        data_list: res.data,
      });
    }
  };
  getDataexportNew = async (data) => {
    this.setState({
      loading: true,
      data_list: []
    })
    var res = await getfullExportNew(data);
    console.log(res);
    if (res.success) {
      this.setState({
        loading: false,
        data_list: res.data,
      });
    }
  };
  onChangeDate = (date, dateString) => {
    //console.log(date);
    //console.log(dateString);
    this.setState({
      startDate: dateString,
    });
  };
  onChangeDate2 = (date, dateString) => {
    //console.log(date);
    //console.log(dateString);
    this.setState({
      endDate: dateString,
    });
  };
  searchClick = () => {
    //console.log("search");
    if(this.state.startDate!="" && this.state.endDate!=""){
      var data = {
        "startDate": this.state.startDate,
        "endDate": this.state.endDate,
      };
      this.setState({
        loading: true,
      });
      this.getDataexport(data);
    }
    
  };
  searchClickNew = () => {
    //console.log("search");
    if(this.state.startDate!="" && this.state.endDate!=""){
      var data = {
        "startDate": this.state.startDate,
        "endDate": this.state.endDate,
      };
      this.setState({
        loading: true,
      });
      this.getDataexportNew(data);
    }
    
  };
  render() {
    const fontValue =  {sz: "12", bold: true,fill: {patternType: "solid", fgColor: {rgb: "FF0B0713"}}};
    const { filterTable } = this.state;
    const dataSet1 = this.state.data_list;

   

    const StatisticDataSet = [
{
  columns: [
    // Sec 01
    {title: "Request Code",style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:210}},//char width 
    {title: "Site Id", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width 
    {title: "Site Name", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
    {title: "Depot", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:180}},//char width cManual_transport_amount
    {title: "Region", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:180}},//char width cManual_transport_amount
    {title: "Sub Region", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:180}},//char width cManual_transport_amount
    {title: "Vendor", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
    {title: "Status", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:180}},//char width cManual_transport_amount
    {title: "Current Status", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:180}},//char width cManual_transport_amount
    {title: "Created Time",style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}} },fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:180}},//pixels width 
    {title: "Created By",style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width   
    {title: "Gen. Rquested Date & Time", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:180}},//char width 
    {title: "OM Approval", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width 
    {title: "OM Rejected Reason (OM Rejected)", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width 
    {title: "OM Approval Time", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:180}},//char width 
    
      // Sec 02
    {title: "Tam Assign Time", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:150}},//char width cManual_transport_amount
    {title: "Dispatch time", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:150}},//char width cManual_transport_amount
    {title: "Arrival time", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:150}},//char width cManual_transport_amount
    {title: "Installed time", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:150}},//char width cManual_transport_amount
    {title: "Depo. Requested Remove Time", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:150}},//char width cManual_transport_amount
    {title: "Gen. Actual Removed Time", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:150}},//char width cManual_transport_amount
    {title: "Payment Removed Time", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
    

    // Sec 03
    {title: "Cancellation", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
    {title: "Cancellation request notes - Vendor (Cancellation Request By Vendor)", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
    {title: "Cancellation request notes - Depot Officer (DO Request Cancellation)", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
    {title: "Cancellation Request Time", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
    {title: "Cancelled time", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount    
    {title: "Payable Cancellation", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
  

    
    // Sec 04
   // {title: "Gen. Running Hours", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
    {title: "Gen.Running Hours", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
    {title: "Type", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
    {title: "Units", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
    {title: "Unit Price", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
   

    // Sec 05
    {title: "PG Cost", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
    {title: "Manual Transport Distance", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
    {title: "Manual Distance-Team", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
    {title: "Approved Manual Distance", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
 //   {title: "Manual Distance Cost", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
    {title: "Manual Transport Service Code", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
    {title: "Manual Transport Quantity", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
    {title: "Manual Transport Unit Price", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
    {title: "Manual Transport Cost", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
    {title: "Total PG Cost", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
    {title: "Service Code", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
    
      // Sec 06
    {title: "Within SLA", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
    {title: "Vendor Suggested SLA", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
    {title: "Vendor Comment", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
    {title: "SLA Approved", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
   
      // Sec 07
    {title: "Outage Penalty", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
    {title: "Outage Duration", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
    {title: "Trouble Ticket Reference", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
    {title: "DO Remarks (DO Verified)", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
    {title: "Calculated penalty band", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
    {title: "OM Verified Time (OM Verified)", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
    {title: "OM Rejected Reason (Removal Rejected by OM)", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
    {title: "Vendor suggest penalty band", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
    {title: "Approved penalty band", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
    {title: "Approved penalty cost", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
   
     // Sec 08
     {title: "RM Approval Time", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
     {title: "RM Remarks (Completed)", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
    {title: "VC Approval Time", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
    
],
data:dataSet1?.map((data)=>[

  // Sec 01
  {value:data.request_code ,style: { font: { sz: "12" } }},
  {value:data.site_id?(data.site_id):("Null") ,style: { font: { sz: "12" } }},
  {value:data.site?(data.site):("Null") ,style: { font: { sz: "12" } }},
  {value:data.depot?(data.depot):("Null") ,style: { font: { sz: "12" } }},
  {value:data.region?(data.region):("Null") ,style: { font: { sz: "12" } }},
  {value:data.sub_region?(data.sub_region):("Null") ,style: { font: { sz: "12" } }},
  {value:data.vendor?(data.vendor):("Null") ,style: { font: { sz: "12" } }},
  {value:data.status?(data.status):("Null") ,style: { font: { sz: "12" } }},
  {value:data.currentStatus?(data.currentStatus):("Null") ,style: { font: { sz: "12" } }}, 
  {value:data.created_time? moment((data.created_time)).format('YYYY-MM-DD HH:mm'):("Null") ,style: { font: { sz: "12" } }},
  
  {value:data.created_by?(data.created_by):("Null") ,style: { font: { sz: "12" } }},
  {value:data.request_time.length==8?(data.request_date+' '+data.request_time.slice(0, -3)):(moment((data.request_date)).format('YYYY-MM-DD')+' '+moment((data.request_time)).format('HH:mm')) ,style: { font: { sz: "12" } }},

 // {value:data.request_date+' '+data.request_time.slice(0, -3) ,style: { font: { sz: "12" } }},  
  {value:data.om_approval_time!=null?("Yes"):("No") ,style: { font: { sz: "12" } }},  
  {value:data.omrejectedReson?(data.omrejectedReson):("Null") ,style: { font: { sz: "12" } }},  
  {value:data.om_approval_time? moment((data.om_approval_time)).format('YYYY-MM-DD HH:mm:ss'):("Null") ,style: { font: { sz: "12" } }},
 
  // Sec 02
  {value:data.team_assign_time?moment(data.team_assign_time).format('YYYY-MM-DD HH:mm:ss'):("Null") ,style: { font: { sz: "12" } }},
  {value:data.dispatch_time?moment(data.dispatch_time).format('YYYY-MM-DD HH:mm:ss'):("Null") ,style: { font: { sz: "12" } }},
  {value:data.arrival_time?moment(data.arrival_time).format('YYYY-MM-DD HH:mm:ss'):("Null") ,style: { font: { sz: "12" } }},
  {value:data.installed_time?moment(data.installed_time).format('YYYY-MM-DD HH:mm:ss'):("Null") ,style: { font: { sz: "12" } }},
  {value:data.remove_request_time?moment(data.remove_request_time).format('YYYY-MM-DD HH:mm:ss'):("Null") ,style: { font: { sz: "12" } }},
  {value:data.actual_removed_time?moment(data.actual_removed_time).format('YYYY-MM-DD HH:mm:ss'):("Null") ,style: { font: { sz: "12" } }},
  {value:data.removed_time?moment(data.removed_time).format('YYYY-MM-DD HH:mm:ss'):("Null") ,style: { font: { sz: "12" } }},
 
  // Sec 03
  {value:data.cansalation?("Yes"):("No") ,style: { font: { sz: "12" } }},
  {value:data.cancellationRequestNotesVendor?(data.cancellationRequestNotesVendor):("Null") ,style: { font: { sz: "12" } }},
  {value:data.cancellationRequestNotesDo?(data.cancellationRequestNotesDo):("Null") ,style: { font: { sz: "12" } }},
  {value:data.cancellation_request_time?moment(data.cancellation_request_time).format('YYYY-MM-DD HH:mm:ss'):("Null") ,style: { font: { sz: "12" } }},
  {value:data.cancelled_time?moment(data.cancelled_time).format('YYYY-MM-DD HH:mm:ss'):("Null") ,style: { font: { sz: "12" } }},
  {value:data.payable_cancellation?("Yes"):("No") ,style: { font: { sz: "12" } }},
  

  // Sec 04
 // {value:"Null*" ,style: { font: { sz: "12" } }},
  {value:data.gen_runing_hours?(data.gen_runing_hours):("Null") ,style: { font: { sz: "12" } }},
  {value:data.type?(data.type):("Null") ,style: { font: { sz: "12" } }},
  {value:data.quantity?(data.quantity):("Null") ,style: { font: { sz: "12" } }},
  {value:data.unit_price?(data.unit_price):("Null") ,style: { font: { sz: "12" } }},
  
  // Sec 05
  {value:data.total_amount?(data.total_amount):("Null") ,style: { font: { sz: "12" } }},
  {value:data.manual_distance?(data.manual_distance):("Null") ,style: { font: { sz: "12" } }},
  {value:data.vendor_suggested_distance?(data.vendor_suggested_distance):("Null") ,style: { font: { sz: "12" } }},
  {value:data.approved_manual_distance?(data.approved_manual_distance):("Null") ,style: { font: { sz: "12" } }},
  //{value:"Null*" ,style: { font: { sz: "12" } }},
  {value:data.manualTransportServiceCode?(data.manualTransportServiceCode):("Null") ,style: { font: { sz: "12" } }},
  {value:data.manualTransportQuantity?(data.manualTransportQuantity):("Null") ,style: { font: { sz: "12" } }},
  {value:data.manualTransportUnitPrice?(data.manualTransportUnitPrice):("Null") ,style: { font: { sz: "12" } }},
  {value:data.manualTransportAmount?(data.manualTransportAmount):("Null") ,style: { font: { sz: "12" } }},

  {value:data.total_pg_cost?(data.total_pg_cost):("Null"),style: { font: { sz: "12" } }}, 
  {value:data.service_code?(data.service_code):("Null"),style: { font: { sz: "12" } }}, 
  
 // Sec 06
  {value:data.sla?("No"):("Yes") ,style: { font: { sz: "12" } }},
  {value:data.vendor_suggested_sla_breach?("No"):("Yes") ,style: { font: { sz: "12" } }},
  {value:data.vendor_comment_sla_breach?(data.vendor_comment_sla_breach):("Null") ,style: { font: { sz: "12" } }},
  {value:data.approved_sla_breach?("No"):("Yes") ,style: { font: { sz: "12" } }}, 
  
  // Sec 07
  {value:"Null*" ,style: { font: { sz: "12" } }},
  {value:"Null*" ,style: { font: { sz: "12" } }},
  {value:data.troubl_ticket_reference?(data.troubl_ticket_reference):("Null") ,style: { font: { sz: "12" } }},
  {value:data.doremarks?(data.doremarks):("Null") ,style: { font: { sz: "12" } }},
  {value:data.calculated_penalty_band?(data.calculated_penalty_band):("Null") ,style: { font: { sz: "12" } }},
  {value:data.omverifiedTime?(data.omverifiedTime):("Null") ,style: { font: { sz: "12" } }},
  {value:data.omremovalRejectedReason?(data.omremovalRejectedReason):("Null") ,style: { font: { sz: "12" } }},
  {value:data.vendor_suggest_penalty_band?(data.vendor_suggest_penalty_band):("Null") ,style: { font: { sz: "12" } }},
  {value:data.approved_penalty_band?(data.approved_penalty_band):("Null") ,style: { font: { sz: "12" } }},
  {value:data.approved_penalty_cost?(data.approved_penalty_cost):("Null") ,style: { font: { sz: "12" } }},

   // Sec 08
   {value:data.rm_approval_time? moment((data.rm_approval_time)).format('YYYY-MM-DD HH:mm:ss'):("Null") ,style: { font: { sz: "12" } }},
    {value:data.rmremarks?(data.rmremarks):("Null") ,style: { font: { sz: "12" } }},
  {value:data.vc_approval_time? moment((data.vc_approval_time)).format('YYYY-MM-DD HH:mm:ss'):("Null") ,style: { font: { sz: "12" } }},
   
  
   
]),
}
    ];

    const columns = [
      {
        title: "Request Code",
        dataIndex: "request_code",
        key: "request_code",
        render: (item) => <span>{item}</span>,
      },
      {
        title: "Site Name",
        dataIndex: "site",
        key: "site",
        render: (item) => <span>{item}</span>,
      },
      {
        title: "Site Id",
        dataIndex: "site_id",
        key: "site_id",
        render: (item) => <span>{item}</span>,
      },
      {
        title: "Request Date",
        dataIndex: "request_date",
        key: "request_code",
        render: (item) => <span>{item.length==10?(item):(moment((item)).format('YYYY-MM-DD'))}</span>,
      },
      {
        title: "Request Time",
        dataIndex: "request_time",
        key: "request_code",
        render: (item) => <span>{item.length==8?(item ):(moment((item)).format('HH:mm') +":00")}</span>,
      },
    ];
    const timestamp = Date.now();
    return (
      <div class="container-fluid">
        <div class="row">
              <div class="col-sm-12">
                <div class="page-title-box">
                  <div class="row">
                    <div class="col">
                      <h4 class="page-title">PG Full Export</h4>
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Data Export </a></li>
                        <li class="breadcrumb-item"><a href="javascript:void(0);">PG Full Export</a></li>

                      </ol>
                    </div>
                    <div class="col-auto align-self-center">
                      <a href="#" class="btn btn-sm btn-outline-primary" id="Dash_Date">
                        <span class="day-name" id="Day_Name">Today:</span>&nbsp;
                        <span class="" id="Select_date">{new Date().toUTCString().slice(5, 16)}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar align-self-center icon-xs ms-1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                      </a>

                    </div>
                  </div>
                </div>
              </div>
            </div>
      <div className="row">
        <div className="col-md-3 col-lg-3 col-xl-3">
          <DatePicker
            placeholder="Start Date"
            style={{ width: "100%" }}
            onChange={this.onChangeDate}
          />
        </div>
        <div className="col-md-3 col-lg-3 col-xl-3">
          <DatePicker
            placeholder="End Date"
            style={{ width: "100%" }}
            onChange={this.onChangeDate2}
          />
        </div>
        {/* <div className="col-md-2 col-lg-2 col-xl-2">
          <button
            onClick={this.searchClick}
            class="btn btn-primary"
            //style={{ float: "right" }}
          >
            <i class="fas fa-search"> </i> Old Search
          </button>
          
        </div> */}
        <div className="col-md-3 col-lg-3 col-xl-3">
          <button
            onClick={this.searchClickNew}
            class="btn btn-primary"
            //style={{ float: "right" }}
          >
            <i class="fas fa-search"> </i>Search
          </button>
          
        </div>
        <br/><br/>
        <div className="col-lg-12 col-xl-12">
          <div className="card">
            <div className="card-header">
              <div className="row ">
                <div className="col">
                  <h4 className="card-title">
                    Report -PG Full export{" "}
                    <ExcelFile
                      filename={"PG Full export -" + timestamp}
                      element={
                        <button
                          class="btn btn-primary"
                          style={{ float: "right" }}
                        >
                          <i class="fas fa-file-export"> </i>PG Full Export
                        </button>
                      }
                    >
                     
                      <ExcelSheet dataSet={StatisticDataSet} name="PG Full Export"/>
                    </ExcelFile>
                  </h4>
                  <div></div>
                </div>
              </div>
            </div>
            <div className="card-body">
           
              <Table
                size="small"
                columns={columns}
                // rowKey="id"
                loading={this.state.loading}
                dataSource={this.state.data_list}
              />
    
            </div>
          </div>
        </div>
      </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  //console.log("state", state);
  return {
    organization_count: state.organization.length,
    regions: state.regions,
    depots: state.depots,
    sites_count: state.sites.length,
    vendor_count: state.vendor.length,
    roles_count: state.roles.length,
  };
}

export default withRouter(connect(mapStateToProps, { getDonationCategories, getData })( FullExport)); 

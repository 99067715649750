import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';

class StackedBar extends Component {
  constructor(props) {
    super(props);

    // Separate configurations for dataLabels and total dataLabels
    const dataLabelsConfig = {
      background: {
        enabled: false,
        foreColor: '#fff',
        padding: 2,
        borderRadius: 2,
        borderWidth: 0.5,
        borderColor: '#fff',
      },
    };

    const totalDataLabelsConfig = {
      background: {
        enabled: true, // Change this according to your requirement
        foreColor: '#fff',
        padding: 2,
        borderRadius: 2,
        borderWidth: 0.5,
        borderColor: '#fff',
      },
    };

    // this.state = {
    //   series: props.y1 || [],
    //   options: {
    //     chart: {
    //       fontFamily: 'Roboto, sans-serif',
    //       type: 'bar',
    //       height: '300',
    //       parentHeightOffset: 0,
    //       stacked: true,
    //       toolbar: {
    //         show: false,
    //       },
    //     },
    //     colors: props.barColors || ['#FF3A29', '#42B02A', '#2D5FF4', '#C624FF', '#CBC300'],
    //     dataLabels: {
    //       enabled: true,
    //       foreColor: '#fff', // Set data labels color to white
    //     },
    //     plotOptions: {
    //       bar: {
    //         horizontal: props.horizontalStatus || false,
    //         dataLabels: {
    //           total: {
    //             enabled: true,
    //             offsetX: 0,
    //             style: {
    //               fontSize: '13px',
    //               fontWeight: 900,
    //               foreColor: '#fff', // Set total data labels color to white
    //             },
    //           },
    //           background: {
    //             ...dataLabelsConfig.background,
    //           },
    //         },
    //         ...totalDataLabelsConfig,
    //       },
    //     },
    //     stroke: {
    //       width: 1,
    //       colors: ['#fff'],
    //     },
    //     xaxis: {
    //       tickAmount: 10,
    //       grid: {
    //         padding: {
    //           left: 0,
    //           right: 0,
    //         },
    //       },
    //       categories: props.x1 || [],
    //       labels: {
    //         rotate: -0,
    //         tickAmount: 10,
    //         formatter: function (val) {
    //           return val;
    //         },
    //         style: {
    //           fontSize: '12px',
    //           fontWeight: 500,
    //           cssClass: 'apexcharts-xaxis-label',
    //           fontFamily: 'Roboto, sans-serif',
    //           foreColor: '#fff', // Set x-axis labels color to white
    //         },
    //       },
    //     },
    //     yaxis: {
    //       title: {
    //         text: props.yLabale || '',
    //         style: {
    //           color: '#fff', // Set y-axis title color to white
    //         },
    //       },
    //       labels: {
    //         style: {
    //           colors: '#fff', // Set y-axis labels color to white
    //         },
    //       },
    //     },
    //     legend: {
    //       position: 'top',
    //       horizontalAlign: 'center',
    //       fontFamily: 'Roboto, sans-serif',
    //       labels: {
    //         colors: '#fff', // Set legend labels color to white
    //       },
    //     },
    //   },
    // };
    this.state = {
  series: props.y1 || [],
  options: {
    chart: {
      fontFamily: 'Roboto, sans-serif',
      type: 'bar',
      height: '300',
      parentHeightOffset: 0,
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    colors: props.barColors || ['#FF3A29', '#42B02A', '#2D5FF4', '#C624FF', '#CBC300'],
    dataLabels: {
      enabled: true,
      foreColor: '#fff', // Set data labels color to white
    },
    plotOptions: {
      bar: {
        horizontal: props.horizontalStatus || false,
        dataLabels: {
          total: {
            enabled: true,
            offsetX: 0,
            style: {
              fontSize: '13px',
              fontWeight: 900,
              foreColor: '#fff', // Set total data labels color to white
            },
          },
          background: {
            ...dataLabelsConfig.background,
          },
        },
        ...totalDataLabelsConfig,
      },
    },
    stroke: {
      width: 1,
      colors: ['#fff'],
    },
    xaxis: {
      tickAmount: 10,
      grid: {
        padding: {
          left: 0,
          right: 0,
        },
      },
      categories: props.x1 || [],
      labels: {
        rotate: -0,
        tickAmount: 10,
        formatter: function (val) {
          return val;
        },
        style: {
          fontSize: '12px',
          fontWeight: 500,
          cssClass: 'apexcharts-xaxis-label',
          fontFamily: 'Roboto, sans-serif',
          foreColor: '#fff', // Set x-axis labels color to white
        },
      },
    },
    yaxis: {
      title: {
        text: props.yLabale || '',
        style: {
          color: '#fff', // Set y-axis title color to white
        },
      },
      labels: {
        style: {
          colors: '#fff', // Set y-axis labels color to white
        },
        formatter: function (val) {
          return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'center',
      fontFamily: 'Roboto, sans-serif',
      labels: {
        colors: '#fff', // Set legend labels color to white
      },
    },
  },
};
  }

  render() {
    return (
      <div>
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height="300"
        />
      </div>
    );
  }
}

export default StackedBar;

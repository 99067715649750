import React, { Component } from 'react';
import { connect } from "react-redux";
import { getSiteListByPage, filterSiteData, } from '../../api/Repos/SiteRepo';
import { getUsers, viewerAllRequests, getUserSites, getAccessPermitionRequestById, getlogByStatusId, getStatusSummary, getSitesWithFilter } from '../../api/index';
import { MessageOutlined, LikeOutlined, DownloadOutlined } from '@ant-design/icons';

import { Link } from "react-router-dom";
// import Select from 'react-select'
import { Select, DatePicker, Table, List, Skeleton, Avatar, Tag } from 'antd';

import { Collapse } from 'reactstrap';
import Popup from 'reactjs-popup';
import moment from 'moment';

import Modal from 'react-modal';


const options = [
	{ value: 'chocolate', label: 'Chocolate' },
	{ value: 'strawberry', label: 'Strawberry' },
	{ value: 'vanilla', label: 'Vanilla' }
]
const { Option } = Select;


class AllRequests extends Component {

	constructor(props) {
		super(props)
		this.state = {
			all_users: null,
			pageNo: 0,
			pgData: "",
			editVisibilty: false,
			site_list: [],
			site_name: "",
			initLoading2: true,
			site_id: "",
			depotList: [],
			regionsList: [],
			isOpen: false,
			isOpenFilter: true,
			depotId: '',
			all_request: [],
			recodesPerPage: 1,
			recodesPerPage_1: 20,
			duration: '',
			selectUser: '',
			statusIds: '',
			siteIds: '',
			depotIds: '',
			regionIds: '',
			allSite: [],
			_StatusArry: [],
			_StatusArry2: [],
			showModal2: false,
			requestViewData: [],
			requestViewDatavisitor: [],
			requestViewDataEqu: [],
			requestViewDataIns: [],
			requestViewDataVhe: [],
			requestViewDataSites: [],
			accessStarttime: '',
			slectApproveImage: null,
			allSite_loading: true,
			statusSummaryArray: null,
			loading: true




		}
		this.handleCloseFullModal = this.handleCloseFullModal.bind(this);
	}

	handleCloseFullModal() {
		this.setState({ showModal2: false });
	}

	componentDidMount() {
		this.getUsersAll();
		this.getDataSitesByuser();
		if (JSON.parse(localStorage.getItem('serviceTypeSet')) == null) {
			window.location.reload(false);

		} else {
			this.getSetPageData(JSON.parse(localStorage.getItem('serviceTypeSet')), 13)
		}
		//this.getAllSites()


		this.getViewerAllRequests();
		//console.log("rtrt");
		//console.log(this.props);
		var _depotList = [];
		var _regionsList = [];
		this.props.depots.map((depot) => {
			_depotList.push({ value: depot.id, label: depot.name })
		})
		this.props.regions.filter(allret => "0".includes(allret.isSubRegion)).map((regions) => {
			_regionsList.push({ value: regions.id, label: regions.name })
		})

		this.setState({
			depotList: _depotList,
			regionsList: _regionsList
		})
		var StatusArry = [];
		var clubStatusArry = [];
		clubStatusArry = [
			{
				"value": '60,61,62',
				"label": "Initiated",
				"color": "#ECFF00"
			},
			{
				"value": '63,68,69,71',
				"label": "Pending",
				"color": "#effaae"
			},
			{
				"value": '64,65,66,67,70',
				"label": "Completed",
				"color": "#FFAE62"
			},

		]
		StatusArry = [
			{
				"value": 60,
				"label": "Access Request Initiated",
				"color": "#ECFF00"
			},
			{
				"value": 61,
				"label": "Access Request Edited",
				"color": "#effaae"
			},
			{
				"value": 62,
				"label": "Access permissions not required",
				"color": "#FFAE62"
			},
			{
				"value": 63,
				"label": "Access Permissions Requested",
				"color": "#00A8FF"
			},
			{
				"value": 64,
				"label": "Access Permission Received",
				"color": "#ccffb8"
			},
			{
				"value": 65,
				"label": "Completed",
				"color": "#20D137"
			},
			{
				"value": 66,
				"label": "Rejected by Handler",
				"color": "#f79d86"
			},
			{
				"value": 67,
				"label": "Rejected by Site",
				"color": "#ff7070"
			},
			{
				"value": 68,
				"label": "Permissions Requested after Edit By User",
				"color": "#f571ce"
			},
			{
				"value": 69,
				"label": "Permissions Requested after Edit By Handler",
				"color": "#e6c725"
			},
			{
				"value": 70,
				"label": "Request Cancelled",
				"color": "#b8b8b8"
			},
			{
				"value": 71,
				"label": "Transfer To the Another Handler",
				"color": "#FFAE62"
			}
		]
		this.setState({
			_StatusArry: clubStatusArry,
			_StatusArry2: StatusArry,

		})
	}

	getUsersAll = async () => {
		var res = await getUsers();
		console.log('getUsersAll');
		console.log(res);
		if (res.success) {
			this.setState({
				all_users: res.data,
			});
		}
	};
	getDataSitesByuser = async () => {
		var res = await getUserSites();
		var _allSite = [];
		//console.log("getUserSites");
		//console.log(res.data);
		if (res.success) {
			res.data.map((sites) => {
				_allSite.push({ value: sites.id, label: sites.siteName })
			})
			this.setState({
				allSite_loading: false,
				allSite: _allSite,
			});
		}
	};
	getSetPageData = (serviceTypeIs, Pageid) => {
		//console.log("this.props");
		//console.log(serviceTypeIs.serviceType);
		if (serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == Pageid)[0] == null) {

			this.setState({
				editVisibilty: false
			})
		} else {
			this.setState({
				editVisibilty: true
			})
		}

	};
	getDataStatusSummary = async (data) => {
		//console.log(data);
		var res = await getStatusSummary(data);
		//console.log("getStatusSummary");
		//console.log(res.data);
		if (res.success) {
			var selectArry;
			this.setState({
				statusSummaryArray: res.data
			})
			selectArry = res?.data.filter(array => array.statusId == 64)



			//console.log(selectArry[0]);
			//console.log('selectArry[0]');

			if (selectArry[0]?.extraData?.approveImage) {
				this.setState({

					slectApproveImage: selectArry[0]?.extraData?.approveImage,

				}, () => {
					//console.log(this.state.slectApproveImage)
				})
			}



		}
	};
	getDataLogByStatusId = async (data, createOn) => {
		//console.log(data);

		var res = await getlogByStatusId(data);
		//console.log("getlogByStatusId");
		//console.log(createOn);
		//console.log(res.data);
		if (res.success) {






		}
	};


	getViewerAllRequests = async () => {
		this.setState({
			loading: true
		})
		var form = new FormData();
		//console.log('this.state.pageNo');
		//console.log(this.state.pageNo);
		//console.log(this.state.recodesPerPage_1);
		//console.log(this.state.regionIds);
		//console.log(this.state.depotIds);
		//console.log(this.state.siteIds);
		console.log(this.state.statusIds);
		form.append("duration", this.state.duration);
		form.append("statusIds", this.state.statusIds);
		form.append("siteIds", this.state.siteIds);
		form.append("depotIds", this.state.depotIds);
		form.append("regionIds", this.state.regionIds);
		form.append('requestUserId', this.state.selectUser);
		form.append("pageNo", this.state.pageNo);
		form.append("recodesPerPage", this.state.recodesPerPage_1);


		var res = await viewerAllRequests(form);
		//console.log("viewerAllRequests");
		console.log(res.data.data);
		if (res.success) {
			this.setState({
				loading: false,

				all_request: res.data.data,
				recodesPerPage: res.data.pageCount,
				initLoading2: false,
			});
		}
	};

	// getAllSites = async () => {
	// 	var res = await getSiteListByPage(this.state.pageNo);
	// 	if (res.success) {
	// 		this.setState({
	// 			site_list: res.data
	// 		})
	// 	}
	// }
	getDataAccessPermitionRequestById = async (data) => {
		//console.log(data);
		var res = await getAccessPermitionRequestById(data);
		//console.log("getAccessPermitionRequestById");
		//console.log(res.data.accessDate + ' ' + res.data.accessStartOn);
		if (res.success) {
			console.log(res);
			var form = new FormData();
			form.append("accessPermissionRequestId", res.data.id);
			form.append("statusId", '64');
			this.getDataLogByStatusId(form, res.data?.createdOn)
			var form2 = new FormData();
			form2.append("accessPermissionRequestId", res.data.id);


			this.getDataStatusSummary(form2);

			this.setState({
				showModal2: true,
				requestViewData: res.data,
				requestViewDatavisitor: res.data?.visitors,
				requestViewDataEqu: res.data?.equipments,
				requestViewDataIns: res.data?.instruments,
				requestViewDataVhe: res.data?.accessVehicles,
				requestViewDataSites: res.data.sites[0],
				accessStarttime: res.data.accessDate + ' ' + res.data.accessStartOn

			});

		}
	};
	getSiteAccessDetails = (id) => {
		var form = new FormData();
		form.append("accessPermitionRequestId", id);
		this.getDataAccessPermitionRequestById(form);
		//console.log(id);
	}

	previousBtnClick = () => {
		if (this.state.pageNo > 0) {
			var aa = this.state.pageNo - 1
			this.setState({
				pageNo: aa
			}, () => {
				this.getViewerAllRequests();
			})
		}
	}

	nextBtnClick = () => {
		var aa = this.state.pageNo + 1
		this.setState({
			pageNo: aa
		}, () => {
			this.getViewerAllRequests();
		})

	}

	searchFormItemChange = (event) => {
		var obj = {};
		obj[event.target.name] = event.target.value;
		//console.log(obj)
		this.setState(obj);
	}
	searchStatustemChange = (event) => {
		//	console.log(event)
		if (event.length > 1) {
			console.log(event)


		}


		this.setState({
			statusIds: event
		});
	}
	searchSiteemChange = (event) => {

		this.setState({
			siteIds: event
		});
	}

	searchDepottemChange = (event) => {

		this.setState({
			depotIds: event
		}, () => { this.getDataSitesWithFilter() });
	}
	getDataSitesWithFilter = async () => {
		this.setState({
			allSite_loading: true,
		})

		console.log("subR");
		var _regionList = [];
		var _depotList = [];
		var _allSite = [];
		if (this.state.regionIds !== '') {
			this.state.regionIds?.map((x, y) => {
				_regionList.push({ "id": x })
			}, () => {

			})
		}
		if (this.state.depotIds !== '') {
			this.state.depotIds?.map((a, b) => {
				_depotList.push({ "id": a })
			}, () => {

			})
		}


		console.log(_regionList)
		var data = new FormData();
		// data.append('regions','[{"id":6},{"id":7}]');
		data.append('regions', JSON.stringify(_regionList));
		data.append('depots', JSON.stringify(_depotList));
		var res = await getSitesWithFilter(data);
		console.log(res)
		if (res.success) {
			res.data.data.map((sites) => {
				_allSite.push({ value: sites.id, label: sites.siteName })
			})
			this.setState({
				allSite_loading: false,
				allSite: _allSite,

			})

		}

	}
	searchRegionmChange = (event) => {
		var dd = event
		var subRegion = [];
		var _depotList1 = [];
		var subRegionIdArray = [];


		this.props.regions.filter(allret => dd.map((n) => n).includes(allret.parentRegion?.id)).map((subR) => {
			subRegionIdArray.push(subR.id)
		})

		this.setState({
			regionIds: subRegionIdArray
		}, () => { this.getDataSitesWithFilter() });

		////console.log(this.props.regions.filter(allret =>dd.map((n) => n).includes(allret.parentRegion?.id)));
		subRegion = this.props.regions.filter(allret => dd.map((n) => n).includes(allret.parentRegion?.id));
		////console.log(this.props.depots.filter(alldate => subRegion.map((n) => n.id).includes(alldate.region?.id)));
		this.props.depots.filter(alldate => subRegion.map((n) => n.id).includes(alldate.region?.id)).map((depot) => {
			_depotList1.push({ value: depot.id, label: depot.name })
		})
		this.setState({
			depotList: _depotList1

		})

	}

	viewFilterClick = () => {
		this.setState({
			isOpenFilter: !this.state.isOpenFilter
		})
	}

	filterBtnClick = async () => {
		this.setState({
			pageNo: 0,

		}, () => {
			this.getViewerAllRequests();
		})

	}
	handleChangeUser = (event) => {
        console.log(event)
        this.setState({
            selectUser: event
        });
    }

	clearBtnClick = () => {
		window.location.reload()
	}

	render() {

		const columns123 = [
			{
				title: "#",
				dataIndex: "id",
				defaultSortOrder: "descend",

				sorter: {
					compare: (a, b) => a.id - b.id,
					multiple: 2,
				},

				render: (text, record, index) => <span>{++index}</span>,
			},
			{
				title: "Site",
				dataIndex: "siteName",
				key: "siteName",

				render: (item) => <span  >{item}</span>,

			},
			{
				title: "Request Code",
				//   dataIndex: "accessPermissionCode",
				key: "accessPermissionCode",
				render: (item) => <span> <a href='#' style={{ fontSize: 14 }} onClick={() => this.getSiteAccessDetails(item.id)}><u>{item.accessPermissionCode}</u></a></span>,
			},
			{
				title: "Created On",
				//   dataIndex: "accessPermissionCode",
				dataIndex: "createdOn",
				sorter: (a, b) => moment(a.createdOn).unix() - moment(b.createdOn).unix(),


				render: (item) => <span>{moment(item).format('YYYY-MM-DD HH:mm:ss')}</span>,
			},
			{
				title: "Type",
				dataIndex: "operationTypeName",
				key: "operationTypeName",
				render: (item) => <span>{item}</span>,
			},
			{
				title: "Group",
				dataIndex: "groupName",
				key: "groupName",

				//render: (item) => <span>{item}</span>,
				render: (item) => <>
					<Tag color="gray" key={item}>
						{item}
					</Tag>
				</>,
			},
			{
				title: "Requester",
				dataIndex: "userName",
				key: "userName",
				render: (item) => <span>{item}</span>,
			},

			{
				title: "Status",
				key: "statusName",
				width: "20%",


				//render: (item) => <span>{item}</span>,
				render: (item) => <>
					<Tag color={item.statusColor} key={item}>
						<span style={{ color: 'black' }}><b>{item.statusName}</b></span>
					</Tag>
				</>,

			},

			// {
			// 	title: 'Action',

			// 	key: 'x',
			// 	render: (item) => <a href='#' onClick={() => this.getSiteAccessDetails(item.id)}><i data-feather="home" class="fa fa-eye"></i></a>,
			//   },

		];



		const columns = [
			{
				title: 'Name',
				dataIndex: 'name',
			},

			{
				title: 'NIC',
				dataIndex: 'nic',
			},
			{
				title: 'Mobile No',
				dataIndex: 'mobileNo',
			},
			{
				title: 'Company',
				dataIndex: 'company',
			},
		];
		const dataTeamMember = this.state.requestViewDatavisitor.filter(visitors3 => visitors3.active == true)
		const columnsEq = [

			{
				title: 'Name',
				dataIndex: 'name',
			},
			{
				title: 'Serial No',
				dataIndex: 'serialNo',
			},
			{
				title: 'Qty',
				dataIndex: 'qnty',
			},
			{
				title: 'Description',
				dataIndex: 'discription',
			},
		];
		const dataListEq = this.state.requestViewDataEqu.filter(equ3 => equ3.active == true)
		const columnsVehi = [

			{
				title: 'Vehicle No',
				dataIndex: 'vehicleNo',
			},
			{
				title: 'Vehicle Type',
				dataIndex: 'vehicleType',
			},

		];
		const dataListVehi = this.state.requestViewDataVhe.filter(vhe3 => vhe3.active == true)
		const columnsIns = [

			{
				title: 'Name',
				dataIndex: 'name',
			},
			{
				title: 'Serial No',
				dataIndex: 'serialNo',
			},
			{
				title: 'Qty',
				dataIndex: 'qnty',
			},
			{
				title: 'Description',
				dataIndex: 'discription',
			},

		];
		const dataListIns = this.state.requestViewDataIns.filter(vhe3 => vhe3.active == true)


		return (
			<div className="container-fluid">
				<div class="row">
					<div class="col-sm-12">
						<div class="page-title-box">
							<div class="row">
								<div class="col">
									<h4 class="page-title">All Requests</h4>
									<ol class="breadcrumb">
										<li class="breadcrumb-item"><a href="javascript:void(0);">Site Access </a></li>
										<li class="breadcrumb-item"><a href="javascript:void(0);">All Requests</a></li>

									</ol>
								</div>
								<div class="col-auto align-self-center">
									<div class="row">

										<div class="col-md-1">
											<button type="button" style={{ marginLeft: 10 }} class="btn btn-danger" onClick={() => this.viewFilterClick()}><i class="fas fa-filter"></i></button>
										</div>

									</div>

								</div>
							</div>
						</div>
					</div>
				</div>

				<Collapse isOpen={this.state.isOpenFilter}>
					<div className="row">
						<div class="card">

							<div class="card-body bootstrap-select-1">
								<div class="row">

									<div class="col-md-3">


										<Select
											mode="multiple"
											showSearch
											name="regionId"
											onChange={(e) => this.searchRegionmChange(e)}
											placeholder="Region"
											style={{ width: "100%" }}

											optionFilterProp="children"
											filterOption={(input, option) =>
												option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
											}
											filterSort={(optionA, optionB) =>
												optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
											}
										// options={this.state.regionsList}

										>
											{this.state.regionsList.map((item, index) => {
												return <Option value={item.value}>{item.label}</Option>;
											})}
										</Select>
									</div>
									<div class="col-md-3">

										<Select
											mode="multiple"
											showSearch
											name="depotId"
											onChange={(e) => this.searchDepottemChange(e)}
											placeholder="Depot"
											style={{ width: "100%" }}
											optionFilterProp="children"
											filterOption={(input, option) =>
												option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
											}
											filterSort={(optionA, optionB) =>
												optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
											}
										// options={this.state.depotList}

										>
											{this.state.depotList.map((item, index) => {
												return <Option value={item.value}>{item.label}</Option>;
											})}
										</Select>
									</div>
									<div class="col-md-3">

										<Select
											mode="multiple"
											placeholder="Site"
											style={{ width: "100%" }}
											loading={this.state.allSite_loading}

											// options={this.state.allSite} 
											name="siteId"
											onChange={(e) => this.searchSiteemChange(e)}
											optionFilterProp="children"
											filterOption={(input, option) =>
												option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
											}
											filterSort={(optionA, optionB) =>
												optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
											}
										>
											{this.state.allSite.map((item, index) => {
												return <Option value={item.value}>{item.label}</Option>;
											})}
										</Select>
									</div>
									<div class="col-md-3">

										<Select
											mode="multiple"
											placeholder="Status"
											style={{ width: "100%" }}
											//options={this.state._StatusArry}
											name="StatusId"
											onChange={(e) => this.searchStatustemChange(e)}
											optionFilterProp="children"
											filterOption={(input, option) =>
												option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
											}
											filterSort={(optionA, optionB) =>
												optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
											}
										>
											{this.state._StatusArry.map((item, index) => {
												return <Option value={item.value}>{item.label}</Option>;
											})}
										</Select>
									</div>
								</div>
								<br></br>
								<div className='row'>
									<div class="col-md-3">

										<Select
											showSearch
											allowClear
											style={{ width: "100%" }}
											placeholder="Requester"

											onChange={(e) => this.handleChangeUser(e)}

											optionFilterProp="children"
											filterOption={(input, option) =>
												option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
											}
											filterSort={(optionA, optionB) =>
												optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
											}
										>
											{this.state.all_users &&
												this.state.all_users.map((item) => (
													<Option key={item.id} value={item.id}>
														{item.email}
													</Option>
												))}
										</Select>
									</div>
								</div>





								<div className='row'>
									<div class="col-md-3" style={{ marginTop: 10 }}>
										<button type="button" class="btn btn-primary" onClick={() => this.filterBtnClick()}> Filter</button>
										<button type="button" style={{ marginLeft: 5 }} class="btn btn-dark" onClick={() => this.clearBtnClick()}>Clear Filter</button>
									</div>
								</div>
							</div>
						</div>
					</div>


				</Collapse>


				{/* <div className="row">
					<List

						className="demo-loadmore-list"
						loading={this.state.initLoading2}
						itemLayout="horizontal"
						//loadMore={loadMore2}
						dataSource={this.state.all_request}
						renderItem={item => (
							<List.Item
								style={{ marginLeft: "6px" }}
							>

								<Skeleton avatar title={false} loading={item.loading} active>

									<List.Item.Meta
										onClick={() => this.getSiteAccessDetails(item.id)}
										avatar={<Avatar src={"https://cdn.iconscout.com/icon/free/png-256/settings-gear-preferences-wrench-optimization-seo-web-1-7229.png"} />}
										title={item.siteName + " (" + item.operationTypeName + ")"}
										body={"ssssssssss"}
										description={<a>{item.accessPermissionCode}</a>}
									/>
									<div style={{ width: "34%" }}>
										<div className='row' style={{ textAlign: "center", fontSize: 12 }}>
											<div className='col-md-12' style={{ color: "blue" }} >
												<b>{item.groupName}</b>
											</div>
											<div className='col-md-12' style={{ textAlign: "center", fontSize: 9 }}>
												{item.userName}
											</div>
											<div className='col-md-12'>
												<span class="badge rounded-pill noti-icon-badge" style={{ textAlign: "center", fontSize: 10, backgroundColor: item.statusColor, color: "black" }}>{item.statusName}</span>
											</div>

										</div>
									</div>
								</Skeleton>
							</List.Item>

						)}
					/>



				</div> */}


				<Table
					size='small'
					fontSize={9}
					columns={columns123}
					bordered
					pagination={false}
					loading={this.state.loading}
					dataSource={this.state.all_request}
				/>
				<Modal

					isOpen={this.state.showModal2}
					contentLabel="Minimal Modal Example"
					style={{
						overlay: {

							zIndex: 10000
						},
						content: {
							right: '15%',
							left: '15%',
							top: '9px',
							bottom: '9px'
						}
					}}
				//    className="modal"
				>
					<div class="col-lg-12 mx-auto">


						<div className="card">
							<div className="card-header">
								<h4 className="card-title">Request Details:</h4>
							</div>
							<div className="card-body">
								<div class="row">
									<div class="col-md-12">
										<table>
											<tr>
												<th>Site Group Name</th>
												<td>{this.state.requestViewData.accessPerminionGroupName}</td>
											</tr>
											<tr>
												<th>Operation Type</th>
												<td>{this.state.requestViewData?.operationType?.name}</td>
											</tr>
											<tr>
												<th>Create On</th>
												<td>{moment(this.state.requestViewData.createdOn).format('YYYY-MM-DD HH:mm:ss')}</td>
											</tr>
											<tr>
												<th>Status</th>
												<td><span class="badge  rounded-pill noti-icon-badge" style={{ textAlign: "center", fontSize: 10, color: "black", backgroundColor: this.state.requestViewData.accessPermitionStatus?.color }}>{this.state.requestViewData.accessPermitionStatus?.name}</span></td>
											</tr>
											{/* <tr>
                                                    <th>Schedule Type</th>
                                                    <td>{this.state.requestViewData?.scheduleType?.name}</td>
                                                </tr> */}
											{this.state.requestViewData?.scheduleStartOn != null ? (<tr>
												<th>Schedule Start On</th>
												<td>{this.state.requestViewData?.scheduleStartOn}</td>
											</tr>) : ("")}

											{this.state.requestViewData?.scheduleStartOn != null ? (<tr>
												<th>Schedule End On</th>
												<td>{this.state.requestViewData?.scheduleEndOn}</td>
											</tr>) : ("")}

											{this.state.requestViewData?.user != null ? (<tr>
												<th>User Name</th>
												<td>{this.state.requestViewData?.user?.name}</td>
											</tr>) : ("")}
											{this.state.requestViewData?.user != null ? (<tr>
												<th>User Email</th>
												<td>{this.state.requestViewData?.user?.email}</td>
											</tr>) : ("")}
											{this.state.requestViewData?.user != null ? (<tr>
												<th>User Contact</th>
												<td>{this.state.requestViewData?.user?.mobileNumber}</td>
											</tr>) : ("")}
											{this.state.requestViewData?.accessDate != null ? (<tr>
												<th>Start On </th>
												<td>{this.state.requestViewData?.accessDate} {this.state.requestViewData?.accessStartOn}</td>
											</tr>) : ("")}
											{this.state.requestViewData?.accessEndDate != null ? (<tr>
												<th>End On </th>
												<td>{this.state.requestViewData?.accessEndDate} {'23:59:59'}</td>
											</tr>) : ("")}



											<tr>
												<th>Handler Name </th>
												<td>{this.state.requestViewData?.handlerName}</td>
											</tr>
											<tr>
												<th> Handler Contact</th>
												<td><i class="fa fa-phone"> </i> {this.state.requestViewData?.handlerContactNumber}</td>
											</tr>
										</table>

									</div>


								</div>
								<div className='row'>
									<div class="row justify-content-center" style={{ fontSize: 12 }}>
										{this.state.requestViewDatavisitor[0] != null ? (<div class="col-lg-12">
											<h6 class="mt-4">Team Members:</h6>
											<Table columns={columns} dataSource={dataTeamMember} size="small" />



										</div>) : ("")}
										{this.state.requestViewDataEqu[0] != null ? (<div class="col-lg-12">
											<h6>Equipments:</h6>
											<Table columns={columnsEq} dataSource={dataListEq} size="small" />



										</div>) : ("")}
										{this.state.requestViewDataIns[0] != null ? (<div class="col-lg-12">
											<h6>Instruments:</h6>
											<Table style={{ fontSize: 9 }} columns={columnsIns} dataSource={dataListIns} size="small" />


										</div>) : ("")}

										{this.state.requestViewDataVhe[0] != null ? (<div class="col-lg-12">
											<h6>Access Vehicles:</h6>

											<Table columns={columnsVehi} dataSource={dataListVehi} size="small" />

										</div>) : ("")}


									</div>
								</div>

								<div class="row">

								</div>

								<div class="row">
									{this.state.requestViewData.scheduleStartOn != null ? (<div class="col-lg-6">
										<p><h6 class="mt-4">Schedule Start On:</h6> {this.state.requestViewData.scheduleStartOn}</p>

									</div>) : ("")}

									{this.state.requestViewData.scheduleEndOn != null ? (<div class="col-lg-6">
										<p><h6 class="mt-4">Schedule End On:</h6> {this.state.requestViewData.scheduleEndOn}</p>

									</div>) : ("")}
									{this.state.requestViewData.projectName != null && this.state.requestViewData.projectName != '' ? (
										<div class="col-lg-6">
											<h6 class="mt-4">Project Name:</h6>
											<ul class="ps-3">
												<li><small class="font-12">{this.state.requestViewData.projectName} </small></li>

											</ul>
										</div>
									) : ('')}
									{this.state.requestViewData.scopeOfWork != null && this.state.requestViewData.scopeOfWork != '' ? (
										<div class="col-lg-6">
											<h6 class="mt-4">Scope Of Work:</h6>
											<ul class="ps-3">
												<li><small class="font-12">{this.state.requestViewData.scopeOfWork} </small></li>

											</ul>
										</div>
									) : ('')}
									{this.state.requestViewData.downTimeRequiredDate != null && this.state.requestViewData.downTimeRequiredDate != '' ? (
										<div class="col-lg-6">
											<h6 class="mt-4">Down Time Required Date:</h6>
											<ul class="ps-3">
												<li><small class="font-12">{this.state.requestViewData.downTimeRequiredDate} </small></li>

											</ul>
										</div>
									) : ('')}
									{this.state.requestViewData.nameOfTeamLeader != null && this.state.requestViewData.nameOfTeamLeader != '' ? (
										<div class="col-lg-6">
											<h6 class="mt-4">Name Of Team Leader:</h6>
											<ul class="ps-3">
												<li><small class="font-12">{this.state.requestViewData.nameOfTeamLeader} </small></li>

											</ul>
										</div>
									) : ('')}
									{this.state.requestViewData.contactNoOfTeamLeader != null && this.state.requestViewData.contactNoOfTeamLeader != '' ? (
										<div class="col-lg-6">
											<h6 class="mt-4">Contact No Of Team Leader:</h6>
											<ul class="ps-3">
												<li><small class="font-12">{this.state.requestViewData.contactNoOfTeamLeader} </small></li>

											</ul>
										</div>
									) : ('')}
									{this.state.requestViewData.visitingPurpose != null ? (<div class="col-lg-6">
										<h6 class="mt-4">Visiting Purpose:</h6>
										<ul class="ps-3">
											<li><small class="font-12">{this.state.requestViewData.visitingPurpose?.name} </small></li>

										</ul>
									</div>) : ('')}
									{this.state.requestViewData.accessNeed != null ? (
										<div class="col-lg-6">
											<h6 class="mt-4">Access Need:</h6>
											<ul class="ps-3">
												<li><small class="font-12">{this.state.requestViewData.accessNeed?.name} </small></li>

											</ul>
										</div>
									) : ('')}

									{this.state.requestViewData.workDescription != null ? (
										<div class="col-lg-6">
											<h6 class="mt-4">Work Description:</h6>
											<ul class="ps-3">
												<li><small class="font-12">{this.state.requestViewData.workDescription} </small></li>

											</ul>
										</div>
									) : ('')}


									{this.state.requestViewData.referenceNo != null ? (
										<div class="col-lg-6">
											<h6 class="mt-4">ReferenceNo:</h6>
											<ul class="ps-3">
												<li><small class="font-12">{this.state.requestViewData.referenceNo} </small></li>

											</ul>
										</div>
									) : ('')}

									{this.state.requestViewData.remarks != null ? (
										<div class="col-lg-6">
											<h6 class="mt-4">Remarks:</h6>
											<ul class="ps-3">
												<li><small class="font-12">{this.state.requestViewData.remarks} </small></li>

											</ul>
										</div>
									) : ('')}
									{this.state.requestViewData.workArea != null ? (
										<div class="col-lg-6">
											<h6 class="mt-4">Work Area:</h6>
											<ul class="ps-3">
												<li><small class="font-12">{this.state.requestViewData.workArea.name} </small></li>

											</ul>
										</div>
									) : ('')}
									{this.state.requestViewData.workType != null ? (
										<div class="col-lg-6">
											<h6 class="mt-4">Work Type:</h6>
											<ul class="ps-3">
												<li><small class="font-12">{this.state.requestViewData.workType.name} </small></li>

											</ul>
										</div>
									) : ('')}





								</div>
							</div>
						</div>
						{this.state.requestViewData.files != null ? (<div className="card">
							<div className="card-header">
								<h4 className="card-title">Relevant Documents:</h4>
							</div>
							<div className="card-body">

								{this.state.requestViewData.files != null ? (<div class="ant-upload-list ant-upload-list-">

									{this.state.requestViewData.files.map((item) => (
										<div class="ant-upload-list--container">
											<div class="ant-upload-list-item ant-upload-list-item-undefined ant-upload-list-item-list-type-">
												<div class="ant-upload-list-item-info">
													<span class="ant-upload-span">
														<div class="ant-upload-text-icon">
															<span role="img" aria-label="paper-clip" class="anticon anticon-paper-clip">
																<svg viewBox="64 64 896 896" focusable="false" data-icon="paper-clip" width="1em" height="1em" fill="currentColor" aria-hidden="true">
																	<path d="M779.3 196.6c-94.2-94.2-247.6-94.2-341.7 0l-261 260.8c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l261-260.8c32.4-32.4 75.5-50.2 121.3-50.2s88.9 17.8 121.2 50.2c32.4 32.4 50.2 75.5 50.2 121.2 0 45.8-17.8 88.8-50.2 121.2l-266 265.9-43.1 43.1c-40.3 40.3-105.8 40.3-146.1 0-19.5-19.5-30.2-45.4-30.2-73s10.7-53.5 30.2-73l263.9-263.8c6.7-6.6 15.5-10.3 24.9-10.3h.1c9.4 0 18.1 3.7 24.7 10.3 6.7 6.7 10.3 15.5 10.3 24.9 0 9.3-3.7 18.1-10.3 24.7L372.4 653c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l215.6-215.6c19.9-19.9 30.8-46.3 30.8-74.4s-11-54.6-30.8-74.4c-41.1-41.1-107.9-41-149 0L463 364 224.8 602.1A172.22 172.22 0 00174 724.8c0 46.3 18.1 89.8 50.8 122.5 33.9 33.8 78.3 50.7 122.7 50.7 44.4 0 88.8-16.9 122.6-50.7l309.2-309C824.8 492.7 850 432 850 367.5c.1-64.6-25.1-125.3-70.7-170.9z"></path>
																</svg>
															</span>
														</div>
														<a target="_blank" href={'https://storage.googleapis.com/dns_storage_v2/requestimages/' + item} class="ant-upload-list-item-name" title={item}>{item}</a>
														<span class="ant-upload-list-item-card-actions" style={{ bottom: 2 }}>
															<button title="Download file" type="button" class="ant-btn ant-btn-text ant-btn-sm ant-btn-icon-only ant-upload-list-item-card-actions-btn">
																<span role="img" aria-label="delete" class="anticon anticon-delete">
																	<DownloadOutlined />
																</span>
															</button>
														</span>
													</span>
												</div>
											</div>
										</div>
									))}


								</div>) : ('')}




							</div>
						</div>) : ('')}
						{/* {this.state.slectApproveImage != null ? (<div className="card">
							<div className="card-header">
								<h4 className="card-title">Access Permission Received Document</h4>
							</div>
							<div className="card-body">
								<div class="ant-upload-list--container">
									<div class="ant-upload-list-item ant-upload-list-item-undefined ant-upload-list-item-list-type-">
										<div class="ant-upload-list-item-info">
											<span class="ant-upload-span">
												<div class="ant-upload-text-icon">
													<span role="img" aria-label="paper-clip" class="anticon anticon-paper-clip">
														<svg viewBox="64 64 896 896" focusable="false" data-icon="paper-clip" width="1em" height="1em" fill="currentColor" aria-hidden="true">
															<path d="M779.3 196.6c-94.2-94.2-247.6-94.2-341.7 0l-261 260.8c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l261-260.8c32.4-32.4 75.5-50.2 121.3-50.2s88.9 17.8 121.2 50.2c32.4 32.4 50.2 75.5 50.2 121.2 0 45.8-17.8 88.8-50.2 121.2l-266 265.9-43.1 43.1c-40.3 40.3-105.8 40.3-146.1 0-19.5-19.5-30.2-45.4-30.2-73s10.7-53.5 30.2-73l263.9-263.8c6.7-6.6 15.5-10.3 24.9-10.3h.1c9.4 0 18.1 3.7 24.7 10.3 6.7 6.7 10.3 15.5 10.3 24.9 0 9.3-3.7 18.1-10.3 24.7L372.4 653c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l215.6-215.6c19.9-19.9 30.8-46.3 30.8-74.4s-11-54.6-30.8-74.4c-41.1-41.1-107.9-41-149 0L463 364 224.8 602.1A172.22 172.22 0 00174 724.8c0 46.3 18.1 89.8 50.8 122.5 33.9 33.8 78.3 50.7 122.7 50.7 44.4 0 88.8-16.9 122.6-50.7l309.2-309C824.8 492.7 850 432 850 367.5c.1-64.6-25.1-125.3-70.7-170.9z"></path>
														</svg>
													</span>
												</div>
												<a target="_blank" href={'https://storage.googleapis.com/dns_storage_v2/requestimages/' + this.state.slectApproveImage} class="ant-upload-list-item-name" title={this.state.slectApproveImage}>{this.state.slectApproveImage}</a>
												<span class="ant-upload-list-item-card-actions" style={{ bottom: 2 }}>
													<button title="Download file" type="button" class="ant-btn ant-btn-text ant-btn-sm ant-btn-icon-only ant-upload-list-item-card-actions-btn">
														<span role="img" aria-label="delete" class="anticon anticon-delete">
															<DownloadOutlined />
														</span>
													</button>
												</span>
											</span>
										</div>
									</div>
								</div>

							</div>
						</div>) : ("")} */}
						{this.state.statusSummaryArray != null ? (
							<div>
								{this.state.statusSummaryArray.filter(sAarry => sAarry?.isCompleate == true && (sAarry?.statusId == 67 || sAarry?.statusId == 64 || sAarry?.statusId == 66)).map((item, index) => {
									return <div className="card" style={{ border: '1px solid' + this.state._StatusArry2?.filter(colorArr => colorArr.value == item.statusId)[0].color }}>
										<div className="card-header">
											<h4 className="card-title"> <span class="badge testewer  rounded-pill noti-icon-badge" style={{ textAlign: "center", color: "black", backgroundColor: this.state._StatusArry2?.filter(colorArr => colorArr.value == item.statusId)[0].color }}>{item.statusName}</span></h4>
										</div>
										<div className="card-body">

											<table>
												<tbody>
													<tr>
														<td>Create User </td>
														<td>:{item.createdUserName}</td>
													</tr>
													<tr>
														<td>Create time</td>
														<td>:{moment(item.createdOn).format('YYYY-MM-DD HH:mm:ss')}</td>
													</tr>
													<tr>
														<td>Created User Email </td>
														<td>:{item.createdUserEmail}</td>
													</tr>
													<tr>
														<td>Created User Mobile Number </td>
														<td>:{item.createdUserMobileNumber}</td>
													</tr>
													{item?.extraData?.note ? (<tr>
														<td>Note</td>
														<td>:{item?.extraData?.note}</td>
													</tr>) : ('')}
													{item?.extraData?.requestType ? (<tr>
														<td>Request Type</td>
														<td>:{item?.extraData?.requestType}</td>
													</tr>) : ('')}
													{item?.extraData?.approveImage ? (<tr>
														<td>Document</td>
														<td><div class="ant-upload-list--container">
															<div class="ant-upload-list-item ant-upload-list-item-undefined ant-upload-list-item-list-type-">
																<div class="ant-upload-list-item-info">
																	<span class="ant-upload-span">
																		<div class="ant-upload-text-icon">
																			<span role="img" aria-label="paper-clip" class="anticon anticon-paper-clip">
																				<svg viewBox="64 64 896 896" focusable="false" data-icon="paper-clip" width="1em" height="1em" fill="currentColor" aria-hidden="true">
																					<path d="M779.3 196.6c-94.2-94.2-247.6-94.2-341.7 0l-261 260.8c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l261-260.8c32.4-32.4 75.5-50.2 121.3-50.2s88.9 17.8 121.2 50.2c32.4 32.4 50.2 75.5 50.2 121.2 0 45.8-17.8 88.8-50.2 121.2l-266 265.9-43.1 43.1c-40.3 40.3-105.8 40.3-146.1 0-19.5-19.5-30.2-45.4-30.2-73s10.7-53.5 30.2-73l263.9-263.8c6.7-6.6 15.5-10.3 24.9-10.3h.1c9.4 0 18.1 3.7 24.7 10.3 6.7 6.7 10.3 15.5 10.3 24.9 0 9.3-3.7 18.1-10.3 24.7L372.4 653c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l215.6-215.6c19.9-19.9 30.8-46.3 30.8-74.4s-11-54.6-30.8-74.4c-41.1-41.1-107.9-41-149 0L463 364 224.8 602.1A172.22 172.22 0 00174 724.8c0 46.3 18.1 89.8 50.8 122.5 33.9 33.8 78.3 50.7 122.7 50.7 44.4 0 88.8-16.9 122.6-50.7l309.2-309C824.8 492.7 850 432 850 367.5c.1-64.6-25.1-125.3-70.7-170.9z"></path>
																				</svg>
																			</span>
																		</div>
																		<a target="_blank" href={'https://storage.googleapis.com/dns_storage_v2/requestimages/' + this.state.slectApproveImage} class="ant-upload-list-item-name" title={this.state.slectApproveImage}>{this.state.slectApproveImage}</a>
																		<span class="ant-upload-list-item-card-actions" style={{ bottom: 2 }}>
																			<button title="Download file" type="button" class="ant-btn ant-btn-text ant-btn-sm ant-btn-icon-only ant-upload-list-item-card-actions-btn">
																				<span role="img" aria-label="delete" class="anticon anticon-delete">
																					<DownloadOutlined />
																				</span>
																			</button>
																		</span>
																	</span>
																</div>
															</div>
														</div></td>
													</tr>) : ('')}

												</tbody>
											</table>
											<br></br>


										</div>
									</div>
								})}

							</div>
						) : ('')}




						<div className="card">
							<div className="card-header">
								<h4 className="card-title">Site Details:</h4>
							</div>
							<div className="card-body">
								<table>
									<tr>
										<th>Site Name</th>
										<td>{this.state.requestViewDataSites.siteName}</td>
									</tr>
									<tr>
										<th>Site ID</th>
										<td>{this.state.requestViewDataSites?.siteId}</td>
									</tr>
									<tr>
										<th>Site Handler</th>
										<td>{this.state.requestViewData?.handlerName}</td>
									</tr>
									<tr>
										<th>Tower Owner Category</th>
										<td>{this.state.requestViewDataSites?.towerOwnerCategory}</td>
									</tr>
								</table>
							</div>
						</div>

						<div class="row d-flex justify-content-center">

							<div class="col-lg-12">
								<div class="float-end d-print-none">

									<button class="btn btn-primary btn-sm" onClick={this.handleCloseFullModal}>Close</button>
								</div>
							</div>
						</div>


					</div>


				</Modal>

				<br></br>


				<div
					style={{
						textAlign: 'center',
						marginTop: 12,
						height: 32,
						lineHeight: '32px',
					}}
				>
					{/* <Button onClick={this.onLoadMore}>loading more</Button> */}
					<div class="row">

						<div class=" col-md-12">
							<div class="dataTables_paginate paging_simple_numbers" id="row_callback_paginate" style={{ display: "flex", textAlign: "end", justifyContent: "end" }}>
								<ul class="pagination">
									<li class="paginate_button page-item previous" id="row_callback_previous">
										<a onClick={() => this.previousBtnClick()} aria-controls="row_callback" data-dt-idx="0" tabindex="0" class="page-link" style={{ fontSize: 15 }}>Previous</a>
									</li>
									<li class="paginate_button page-item ">
										<a aria-controls="row_callback" data-dt-idx="1" tabindex="0" class="page-link" style={{ fontSize: 15 }}>{this.state.pageNo + 1}/{this.state.recodesPerPage}
										</a>
									</li>

									<li class="paginate_button page-item next" id="row_callback_next">
										<a onClick={() => this.nextBtnClick()} aria-controls="row_callback" data-dt-idx="7" tabindex="0" class="page-link" style={{ fontSize: 15 }}>Next
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>

		);
	}
}


function mapStateToProps(state) {
	//console.log("state")
	//console.log(state)
	return {
		depots: state.depots,
		regions: state.regions
	};
}



export default connect(
	mapStateToProps,
	{}
)(AllRequests);



import React, { Component } from "react";
import { connect } from "react-redux";
import { getSiteListByPage, filterSiteData } from "../../api/Repos/SiteRepo";
import { Link } from "react-router-dom";
import { DatePicker, Table, List, Skeleton, Avatar, Tag ,Input} from 'antd';

import Select from "react-select";
import { Collapse } from "reactstrap";
import {
  RightOutlined,
  DownOutlined,
  BorderOuterOutlined,
  TeamOutlined,
  AppstoreOutlined,
  DeploymentUnitOutlined,
  ClusterOutlined,
} from "@ant-design/icons";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

class DepotList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNo: 1,
      site_list: [],
      site_name: "",
      site_id: "",
      depotList: [],
      isOpen: false,
      depotId: "",
      pgData: "",
      filterTable: null,
    
    };
  }

  componentDidMount() {
    console.log(this.props.depots)
    this.setState({
      pgData:this.props.depots
    })
    //	this.getAllSites()
    // var _depotList = [];
    // this.props.regions.map((depot)=>{
    // 	_depotList.push({ value: depot.id, label: depot.name })
    // })
    // this.setState({
    // 	depotList : _depotList
    // })
  }

  // getAllSites = async() =>{
  // 	var res = await getSiteListByPage(this.state.pageNo);
  // 	if(res.success){
  // 		this.setState({
  // 			site_list : res.data
  // 		})
  // 	}
  // }

  previousBtnClick = () => {
    if (this.state.pageNo > 1) {
      var aa = this.state.pageNo - 1;
      this.setState(
        {
          pageNo: aa,
        },
        () => {
          this.getAllSites();
        }
      );
    }
  };

  nextBtnClick = () => {
    var aa = this.state.pageNo + 1;
    this.setState(
      {
        pageNo: aa,
      },
      () => {
        this.getAllSites();
      }
    );
  };
  search = (value) => {
    const { baseData } = this.state;
    //console.log("PASS", { value });

    const filterTable = this.state.pgData.filter((o) => {
      console.log('0');
      console.log((o));
      
      return (
        Object.keys(o).some((k) => {
          console.log(o[k]);
        return String(o[k]).toLowerCase().includes(value.toLowerCase());
        }) 
      );
    });

    this.setState({ filterTable });
  };

  searchFormItemChange = (event) => {
    var obj = {};
    obj[event.target.name] = event.target.value;
    //console.log(obj);
    this.setState(obj);
  };

  searchDepottemChange = (event) => {
    this.setState({
      depotId: event.value,
    });
  };

  viewFilterClick = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  filterBtnClick = async () => {
    let formData = new FormData(); //formdata object

    formData.append("depotId", this.state.depotId); //append the values with key, value pair
    formData.append("siteId", this.state.site_id);
    formData.append("siteName", this.state.site_name);

    var res = await filterSiteData(formData);
    if (res.success) {
      this.setState({
        site_list: res.data,
      });
    }
  };

  clearBtnClick = () => {
    window.location.reload();
  };

  render() {
    const { filterTable, pgData } = this.state;
    const columns123 = [
			{
				title: "#",
				// dataIndex: "siteId",
				//	defaultSortOrder: "descend",


				render: (text, record, index) => <span> <u>{++ index}</u></span>,
			},
			{
				title: "Name",

				key: "name",

				render: (item) => <span  ><u><Link to={"/depot-summary/" + item.id}>{item.name}</Link></u></span>,

			},
      {
				title: "Organization",
				//   dataIndex: "accessPermissionCode",
				key: "region",
			//	render: (item) => <span>{item.region.organization.name}</span>,
      render: (item) => <>
					<Tag color="#ea1a20" key={item}>
					{item.region.organization.name}
					</Tag>
				</>,
			},
			{
				title: "Region",
				//   dataIndex: "accessPermissionCode",
				key: "region",
				render: (item) => <span>{item.region.parentRegion.name}</span>,
			},
      {
				title: "SubRegion",
				//   dataIndex: "accessPermissionCode",
				key: "region",
				render: (item) => <span>{item.region.name}</span>,
			},
			{
				title: "Description",
				//   dataIndex: "accessPermissionCode",
				key: "description",
				render: (item) => <span>{item.description}</span>,
			},

			

		];
    return (
      <div className="container-fluid">
        <div class="row">
              <div class="col-sm-12">
                <div class="page-title-box">
                  <div class="row">
                    <div class="col">
                      <h4 class="page-title">Depots List</h4>
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Organization</a></li>
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Depots</a></li>

                      </ol>
                    </div>
                    <div class="col-auto align-self-center">
                      <a href="#" class="btn btn-sm btn-outline-primary" id="Dash_Date">
                        <span class="day-name" id="Day_Name">Today:</span>&nbsp;
                        <span class="" id="Select_date">{new Date().toUTCString().slice(5, 16)}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar align-self-center icon-xs ms-1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                      </a>

                    </div>
                  </div>
                </div>
              </div>
            </div>
        
        
        <div className="row">
        

          <div className="col-lg-6 text-end">
            <div className="text-end">
             
            </div>
          </div>
        </div>

        <div className="row">
          {this.props.depots.map((site, index) => {
            // return (
            //   <div className="col-lg-3">
            //     <Link to={"/depot-summary/" + site.id}>
            //       <div className="card">
            //         <div className="card-body">
            //           <div className="media mb-3">
					
            //             <DeploymentUnitOutlined style={{ fontSize: 30 }} />
            //             {/* <img src="assets/images/widgets/project2.jpg" alt="" className="thumb-md rounded-circle"/>                                       */}
            //             <div className="media-body align-self-center text-truncate ms-3">
            //               {/* <h4 className="m-0 fw-semibold  font-15">{site.siteName}</h4>    */}
            //               <p className="text-muted  mb-0 font-13">
            //                 Depot :{" "}
            //                 <span className="fw-semibold m-0 fw-normal">
            //                   {" "}
            //                   {site.name}
            //                 </span>
            //               </p>
            //             </div>
            //           </div>
            //           <hr
            //             className="hr-dashed"
            //             style={{ background: "#adadad" }}
            //           />
            //           <div className="d-flex justify-content-between mb-3">
            //             <h6 className="fw-semibold m-0">
            //               Region :{" "}
            //               <span className="text-muted fw-normal">
            //                 {" "}
            //                 {site.region.name  }
            //               </span>
            //             </h6>
            //             {/* <h6 className="fw-semibold m-0">Status : <span className="text-muted fw-normal"><span class="badge bg-success">ACTIVE</span></span></h6>                           */}
            //           </div>
                     
                     
                      
                     

                   
            //         </div>
            //       </div>
            //     </Link>
            //   </div>
            // );
          })}
          <div className="row">
            <div className="col-md-8">
            <Input
                  enterButton
                  style={{ marginBottom: 5 }}
                  placeholder="Search Depot Name"
                  onChange={(e) => {
                    const currValue = e.target.value;
                    this.search(currValue);
                  }}
                />
            </div>
        
          </div>
                 

<Table
						size='small'
						fontSize={9}
						columns={columns123}
						bordered
					//	pagination={{ pageSize: 25 }}
						loading={this.state.loading}
					  dataSource={filterTable == null ? pgData : filterTable}
					/>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    regions: state.regions,
    depots: state.depots,
  };
}

export default connect(mapStateToProps, {})(DepotList);

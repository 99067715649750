import React, { Component } from 'react'
import ReactApexChart from 'react-apexcharts'

class ColumnChartDataLable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [{
        // name: this.props.labelData ? this.props.labelData : 'n',
        data: ['']

      }],
      options: {
        chart: {
          height: 350,
          type: 'bar',
          parentHeightOffset: 0,

          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val;
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          }
        },

        xaxis: {
          categories: [''],

          position: 'bottom',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              }
            }
          },
          tooltip: {
            enabled: false,
          }
        },
        yaxis: {
          title: {
            text: ''
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: true,


          }

        },
      },
    };
  }
  componentDidMount() {
    console.log('ccccc12');
    console.log(this.props)

    this.setState({
      series: this.props.y1,
      options: {
        chart: {
          height: 350,
          type: 'bar',
          parentHeightOffset: 0,
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val.toLocaleString();
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          }
        },

        xaxis: {
          categories: this.props.x1,
          labels: {
            rotate: -0,
            formatter: function (val) {
              return val;
            }

          },
          position: 'bottom',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              }
            }
          },
          tooltip: {
            enabled: true,
          }
        },
        tooltip: {
          title: {
            text: 'Your New Label Name'
          },
          y: {
            formatter: (val) => {
              return parseFloat(val).toFixed(2) + " " + this.props.prefixData;
            }
          }
        },
        yaxis: {
          title: {
            text: this.props.yLabale
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: true,
            formatter: function (val) {
              return val.toLocaleString();
            }
          }

        },
      },
    })

  }

  render() {
    return (
      <div id="chart">
        <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height={270} />
      </div>
    );
  }
}
export default ColumnChartDataLable;
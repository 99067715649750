import React, { useState, useEffect } from "react";
import { Table, Input, Select, Button, Modal, Space, Tag, Tooltip, Row, Col, Form, Upload, message, Image, Spin, Descriptions } from "antd";
import {
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    CheckOutlined,
    UploadOutlined,
    CarOutlined,
    SearchOutlined
} from "@ant-design/icons";
import axios from "axios";
import { FilePdfOutlined, FileWordOutlined } from "@ant-design/icons";
import './style.css';
import { saveAs } from 'file-saver';
import { Parser } from 'json2csv';
import * as XLSX from 'xlsx';
import xlsx from 'xlsx';
import Papa from 'papaparse';
// import { getAllVehicles } from "../../api/index";
import {
    getAllVehiclesList,
    getAllActiveVehicleMaster,
    getAllVehicleType,
    getAllActiveVehicleSubType,
    getAllActiveVehicleOwner,
    getAllActiveVehiclePaymentCategory,
    getAllVendorPool,
    getAllRegion,
    getAllDrivers,
    registerNewVehicles,
    getSingleVehicleByID,
    updateVehicleByID,
    deleteVehicleByID,
    getAllFilteredVehicleMaster,
    getAllFilteredDrivers
} from "../../api/index";

const { Option } = Select;

const VehicleList = () => {
    const [dataSource, setDataSource] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [selectedVehicleType, setSelectedVehicleType] = useState("");
    const [visible, setVisible] = useState(false);
    const [open, setOpen] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [registeredVehicle, setRegisteredVehicle] = useState([]);
    const [searchData, setSearchData] = useState(null);


    const [vehicleType, setVehicleType] = useState([]);
    const [vehicleSubType, setVehicleSubType] = useState([]);
    const [vehicleOwner, setVehicleOwner] = useState([]);
    const [vehiclePaymentCategory, setVehiclePaymentCategory] = useState([]);
    const [vehicleMaster, setVehicleMaster] = useState([]);
    const [vehicleDriver, setVehicleDriver] = useState([]);
    const [region, setRegion] = useState([]);
    const [vendorPool, setVendorPool] = useState([]);
    const [selectedVehicle, setSelectedVehicle] = useState({});
    const [spinning, setSpinning] = React.useState(false);
    const [vehicleRegisterNumbers, setVehicleRegisterNumbers] = useState([]);
    const [openInfoModel, setOpenInfoModel] = useState(false);

    const baseImgURL = "https://storage.googleapis.com/dns_storage_v2/dnsrevampimages/";


    useEffect(() => {
        fetchAllVehicles();
        fetchVehicleType();
        fetchVehicleSubType();
        fetchVehicleOwner();
        fetchVehiclePaymentCategory();
        // fetchVehicleMaster();
        fetchAllVendorPool();
        fetchAllRegions();
        fetchAllDrivers();
    }, []);

    const fetchAllRegions = async () => {
        const response = await getAllRegion();
        if (response.success) {
            console.log(response.data);
            setRegion(response.data.results);
        } else {
            console.log(response.error);
            message.error("Failed to fetch regions");
        }
    };

    const openDetailsInfoModel = () => {
        setOpenInfoModel(true);
    }
    const openInfoModelOperation = async (recordID) => {
        fetchSelectedVehicle(recordID, true);
    }

    const closeInfoModel = () => {
        setSelectedVehicle({});
        setOpenInfoModel(false);
    }

    const fetchVehicleOwner = async () => {
        const response = await getAllActiveVehicleOwner();
        if (response.success) {
            console.log(response.data);
            setVehicleOwner(response.data.results);
        } else {
            console.log(response.error);
            message.error("Failed to fetch vehicle owners");
        }
    };

    const fetchAllDrivers = async () => {
        const response = await getAllFilteredDrivers();
        if (response.success) {
            console.log(response.data);
            setVehicleDriver(response.data.results);
        } else {
            console.log(response.error);
            message.error("Failed to fetch drivers");
        }
    };

    const fetchAllVendorPool = async () => {
        const response = await getAllVendorPool();
        if (response.success) {
            console.log(response.data);
            setVendorPool(response.data.results);
        } else {
            console.log(response.error);
            message.error("Failed to fetch vendor pool");
        }
    };

    const fetchAllVehicles = async () => {
        const response = await getAllVehiclesList();
        if (response.success) {
            const registerNumberArray = [];
            setRegisteredVehicle(response.data.results);
            for (let i = 0; i < response.data.results.length; i++) {
                registerNumberArray.push(response.data.results[i].registrationNumber);
            }
            setVehicleRegisterNumbers(registerNumberArray);
        } else {
            console.log(response.error);
            message.error("Failed to fetch vehicles");
        }
    };

    const fetchVehicleType = async () => {
        const response = await getAllVehicleType();
        if (response.success) {
            console.log(response.data);
            setVehicleType(response.data.results);
        } else {
            console.log(response.error);
            message.error("Failed to fetch vehicle types");
        }
    };

    const fetchVehicleSubType = async () => {
        const response = await getAllActiveVehicleSubType();
        if (response.success) {
            console.log(response.data);
            setVehicleSubType(response.data.results);
        } else {
            console.log(response.error);
            message.error("Failed to fetch vehicle sub types");
        }
    };

    const fetchVehiclePaymentCategory = async () => {
        const response = await getAllActiveVehiclePaymentCategory();
        if (response.success) {
            console.log(response.data);
            setVehiclePaymentCategory(response.data.results);
        } else {
            console.log(response.error);
            message.error("Failed to fetch vehicle payment categories");
        }
    };

    const fetchVehicleMaster = async () => {
        const response = await getAllFilteredVehicleMaster();
        if (response.success) {
            console.log(response.data);
            setVehicleMaster(response.data.results);
        } else {
            console.log(response.error);
            message.error("Failed to fetch vehicle masters");
        }
    };

    const fetchSelectedVehicle = async (id, isInfoModel) => {
        const response = await getSingleVehicleByID(id);
        if (response.success) {
            setSelectedVehicle({});
            setSelectedVehicle(response.data.results);
            if (isInfoModel == true) {
                setOpenInfoModel(true);
            } else {
                setIsUpdate(true);
                setVisible(true);
            }
        } else {
            console.log(response.error);
            message.error("Failed to fetch vehicle");
        }
    };

    const openModel = (editStatus) => {
        setVisible(true);
        setIsUpdate(editStatus);
    };

    const handleCancel = (editStatus) => {
        setVisible(false);
        // setSelectedVehicle({});
        setSelectedVehicle({});
        setIsUpdate(editStatus);
    };

    const addThousandSeparators = (number) => {
        if (typeof number !== 'number') {
            return number;
        }
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const vehicleListExportDataMap = (apiData) => {
        const exportData = apiData.map(item => ({
            "Registration Number": item.registrationNumber ? item.registrationNumber : "N/A",
            "Vehicle Model": item.model ? item.model : "N/A",
            "Vehicle Brand": item.brand ? item.brand : "N/A",
            "Distance Unit": item.distanceUnit ? item.distanceUnit : "N/A",
            "Seating Capacity": item.seatingCapacity ? item.seatingCapacity : "N/A",
            "Mileage": item.mileage ? addThousandSeparators(item.mileage) : "0",
            "Used Mileage": item.usedMileage ? addThousandSeparators(item.usedMileage) : "0",
            "Driver Name": item.driverName ? item.driverName : "N/A",
            "Driver Contact": item.driverContact ? item.driverContact : "N/A",
            "Region": item.regionId ? region.find(r => r.id === item?.regionId)?.name : "N/A",
            "Vehicle Master Active": item.vehicleMaster.active ? "Active" : "Inactive",
            "Vehicle Master Code": item.vehicleMaster.code ? item.vehicleMaster?.code : "N/A",
            "Vehicle Master Description": item.vehicleMaster.description,
            "Vehicle Master Mileage (Km)": item.vehicleMaster.mileage ? addThousandSeparators(item.vehicleMaster.mileage) : "N/A",
            "Vehicle Master Travelling Officer": item.vehicleMaster.travellingOfficer,
            "Vehicle Master Vehicle Category": item.vehicleMaster.vehicleCategory,
            "Vendor Pool Name": item.vendorPool.name ? item.vendorPool.name : "N/A",
            "Vendor Pool Mileage": item.vendorPool.mileage ? addThousandSeparators(item.vendorPool.mileage) : "0",
            "Vendor Pool Used Mileage": item.vendorPool.usedMileage ? addThousandSeparators(item.vendorPool.usedMileage) : "0",
            "Vehicle Type Name": item.vehicleType.name ? item.vehicleType.name : "N/A",
            "Vehicle Sub Type Name": item.vehicleSubType.name ? item.vehicleSubType.name : "N/A",
            "Vehicle Owner Name": item.vehicleOwner.name ? item.vehicleOwner.name : "N/A",
            "Vehicle Payment Category Name": item.vehiclePaymentCategory.name ? item.vehiclePaymentCategory.name : "N/A",
        }));
        return exportData;
    }

    const exportData = (data, exportName, exportFormat) => {
        if (exportFormat === 1) {
            const worksheet = xlsx.utils.json_to_sheet(data);
            const workbook = xlsx.utils.book_new();
            xlsx.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            xlsx.writeFile(workbook, exportName+ new Date().toISOString().slice(0, 10) + '.xlsx');

        } else {
            const csv = Papa.unparse(data);
            const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
            saveAs(blob, exportName + new Date().toISOString().slice(0, 10) + '.csv');
        }
    }

    const columns = [
        {
            title: <span className="textStyle">Registration Number </span>,
            dataIndex: "registrationNumber",
            key: "registrationNumber",
            render: (text) => {
                return <span className="textStyleChild">
                    {text ? text : "N/A"}
                </span>;
            }
        },
        {
            title: <span className="textStyle">Vehicle Master </span>,
            dataIndex: "vehicleMasterId",
            key: "vehicleMasterId",
            render: (text, record) => {
                return <span className="textStyleChild">
                    {record?.vehicleMaster?.code ? record?.vehicleMaster?.code : "N/A"}
                </span>;
            }
        },
        {
            title: <span className="textStyle">Vehicle Type</span>,
            dataIndex: "vehicleTypeId",
            key: "vehicleTypeId",
            render: (text, record) => {
                return <span className="textStyleChild">
                    <CarOutlined /> {record.vehicleType.name ? record.vehicleType.name : "N/A"}
                </span>;
            }
        },
        {
            title: <span className="textStyle">Vehicle Sub Type</span>,
            dataIndex: "vehicleSubTypeId",
            key: "vehicleSubTypeId",
            render: (text, record) => {
                return <span className="textStyleChild">
                    <CarOutlined /> {record.vehicleSubType.name ? record.vehicleSubType.name : "N/A"}
                </span>;
            }
        },
        {
            title: <span className="textStyle">Brand</span>,
            dataIndex: "brand",
            key: "brand",
            render: (text) => {
                return <span className="textStyleChild">
                    {text ? text : "N/A"}
                </span>;
            }
        },
        {
            title: <span className="textStyle">Model</span>,
            dataIndex: "model",
            key: "model",
            render: (text) => {
                return <span className="textStyleChild">
                    {text ? text : "N/A"}
                </span>;
            }
        },
        {
            title: <span className="textStyle">Seating Capacity</span>,
            dataIndex: "seatingCapacity",
            key: "seatingCapacity",
            render: (text) => {
                return <span className="textStyleChild">
                    {text}
                </span>;
            }
        },
        {
            title: <span className="textStyle">Actions</span>,
            dataIndex: "id",
            key: "id",
            render: (text, record) => (
                <>
                    <Button
                        type="circle"
                        style={{ backgroundColor: "#EBEFFF", width: "25px", height: "25px" }}
                        icon={<EditOutlined style={{ color: "#2C5EF4", fontWeight: "bold", fontSize: "10px" }} />}
                        onClick={() => handleEdit(text)}
                        size="small"
                    />
                    <Button
                        type="circle"
                        style={{ backgroundColor: "#FEF2F3", marginLeft: 10, width: "25px", height: "25px" }}
                        icon={<DeleteOutlined style={{ color: "#FF0000", fontWeight: "bold", fontSize: "10px" }} />}
                        onClick={() => handleDelete(text)}
                        size="small"
                    />
                    <Button
                        type="circle"
                        style={{ backgroundColor: "#ebe6f2", marginLeft: 10, width: "25px", height: "25px" }}
                        icon={<EyeOutlined style={{ color: "#2f066b", fontWeight: "bold", fontSize: "10px" }} />}
                        size="small"
                        onClick={() => openInfoModelOperation(text)}
                    />
                </>
            ),
        },
    ];

    const handleSearch = (selectedKeys, confirm) => {
        confirm();
        setSearchText(selectedKeys[0]);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };

    const handleEdit = (record) => {
        fetchSelectedVehicle(record, false);
    };

    const deleteByID = async (id) => {
        const response = await deleteVehicleByID(id);
        if (response.success) {
            message.success("Vehicle Deleted Successfully");
            fetchAllVehicles();
        } else {
            message.error("Failed To Delete Vehicle");
        }
    }

    const handleDelete = (id) => {
        Modal.confirm({
            title: "Confirm Delete",
            content: `Are you sure you want to delete this vehicle?`,
            onOk: () => {
                deleteByID(id);
            },
            onCancel: () => { },
        });
    };

    const handleNewRegisterVehicle = (record) => {
        Modal.confirm({
            title: "Confirm Register",
            content: `Are you Sure You Want To register This Vehicle?`,
            onOk: () => {
                registerNewVehicle(record);
            },
            onCancel: () => { },
        });
    };

    const handleVehicleUpdate = (vehicleID, record) => {
        Modal.confirm({
            title: "Confirm Update",
            content: `Are you sure you want to Update This Vehicle?`,
            onOk: () => {
                updateVehicleDetailsByGivenID(vehicleID, record);
            },
            onCancel: () => { },
        });
    };

    const validatePositiveNumber = (rule, value, callback) => {
        if (value && value < 0) {
            callback('Seating capacity must be a positive number!');
        } else {
            callback();
        }
    };

    const handleFormSubmit = (values) => {
        values.usedMileage = 0;
        if (isUpdate) {
            const updateForm = new FormData();
            const revenueLicense = values.revenueLicense !== undefined && values.revenueLicense[0] ? values.revenueLicense[0].originFileObj : null;
            const insuranceCard = values.insuranceCard !== undefined && values.insuranceCard[0] ? values.insuranceCard[0].originFileObj : null;
            const numberPlateFront = values.numberPlateFront !== undefined && values.numberPlateFront[0] ? values.numberPlateFront[0].originFileObj : null;
            const numberPlateBack = values.numberPlateBack !== undefined && values.numberPlateBack[0] ? values.numberPlateBack[0].originFileObj : null;
            const vehicleImage1 = values.vehicleImage1 !== undefined && values.vehicleImage1[0] ? values.vehicleImage1[0].originFileObj : null;
            const vehicleImage2 = values.vehicleImage2 !== undefined && values.vehicleImage2[0] ? values.vehicleImage2[0].originFileObj : null;
            const vehicleImage3 = values.vehicleImage3 !== undefined && values.vehicleImage3[0] ? values.vehicleImage3[0].originFileObj : null;
            const vehicleImage4 = values.vehicleImage4 !== undefined && values.vehicleImage4[0] ? values.vehicleImage4[0].originFileObj : null;
            const ecoTest = values.ecoTest !== undefined && values.ecoTest[0] ? values.ecoTest[0].originFileObj : null;
            const vehicleBook = values.vehicleBook !== undefined && values.vehicleBook[0] ? values.vehicleBook[0].originFileObj : null;

            if (revenueLicense !== null) {
                updateForm.append('revenueLicense', revenueLicense);
            }

            if (insuranceCard !== null) {
                updateForm.append('insuranceCard', insuranceCard);
            }

            if (numberPlateFront !== null) {
                updateForm.append('numberPlateFront', numberPlateFront);
            }

            if (numberPlateBack !== null) {
                updateForm.append('numberPlateBack', numberPlateBack);
            }

            if (vehicleImage1 !== null) {
                updateForm.append('vehicleImage1', vehicleImage1);
            }

            if (vehicleImage2 !== null) {
                updateForm.append('vehicleImage2', vehicleImage2);
            }

            if (vehicleImage3 !== null) {
                updateForm.append('vehicleImage3', vehicleImage3);
            }

            if (vehicleImage4 !== null) {
                updateForm.append('vehicleImage4', vehicleImage4);
            }

            if (ecoTest !== null) {
                updateForm.append('ecoTest', ecoTest);
            }

            if (vehicleBook !== null) {
                updateForm.append('vehicleBook', vehicleBook);
            }

            const vehicleUpdateJsonString = JSON.stringify({
                active: values.active,
                model: values.model,
                brand: values.brand,
                distanceUnit: values.distanceUnit,
                seatingCapacity: values.seatingCapacity,
                mileage: 0,
                usedMileage: 0,
                registrationNumber: values.registrationNumber,
                vendorPoolId: values.vendorPoolId,
                vehicleTypeId: values.vehicleTypeId,
                vehicleSubTypeId: values.vehicleSubTypeId,
                vehicleOwnerId: values.vehicleOwnerId,
                vehiclePaymentCategoryId: null,

                revenueLicenseUrl: selectedVehicle.revenueLicenseUrl ? selectedVehicle.revenueLicenseUrl : null,
                insuranceCardUrl: selectedVehicle.insuranceCardUrl ? selectedVehicle.insuranceCardUrl : null,
                ecoTestUrl: selectedVehicle.ecoTestUrl ? selectedVehicle.ecoTestUrl : null,
                vehicleBookUrl: selectedVehicle.vehicleBookUrl ? selectedVehicle.vehicleBookUrl : null,
                numberPlateFrontUrl: selectedVehicle.numberPlateFrontUrl ? selectedVehicle.numberPlateFrontUrl : null,
                numberPlateBackUrl: selectedVehicle.numberPlateBackUrl ? selectedVehicle.numberPlateBackUrl : null,
                vehicleImageUrl1: selectedVehicle.vehicleImageUrl1 ? selectedVehicle.vehicleImageUrl1 : null,
                vehicleImageUrl2: selectedVehicle.vehicleImageUrl2 ? selectedVehicle.vehicleImageUrl2 : null,
                vehicleImageUrl3: selectedVehicle.vehicleImageUrl3 ? selectedVehicle.vehicleImageUrl3 : null,
                vehicleImageUrl4: selectedVehicle.vehicleImageUrl4 ? selectedVehicle.vehicleImageUrl4 : null,
            });

            updateForm.append('vehicleRequest', new Blob([vehicleUpdateJsonString], { type: 'application/json' }));

            handleVehicleUpdate(selectedVehicle.id, updateForm);
        } else {
            const formData = new FormData();

            const vehicleRequestJsonString = JSON.stringify({
                active: values.active,
                model: values.model,
                brand: values.brand,
                distanceUnit: values.distanceUnit,
                seatingCapacity: values.seatingCapacity,
                mileage: 0,
                usedMileage: 0,
                registrationNumber: values.registrationNumber,
                vendorPoolId: values.vendorPoolId,
                vehicleTypeId: values.vehicleTypeId,
                vehicleSubTypeId: values.vehicleSubTypeId,
                vehicleOwnerId: values.vehicleOwnerId,
                vehiclePaymentCategoryId: null,
            });

            const revenueLicense = values.revenueLicense?.file ? values.revenueLicense.file : null;
            const insuranceCard = values.insuranceCard?.file ? values.insuranceCard.file : null;
            const numberPlateFront = values.numberPlateFront?.file ? values.numberPlateFront.file : null;
            const numberPlateBack = values.numberPlateBack?.file ? values.numberPlateBack.file : null;
            const vehicleImage1 = values.vehicleImage1?.file ? values.vehicleImage1.file : null;
            const vehicleImage2 = values.vehicleImage2?.file ? values.vehicleImage2.file : null;
            const vehicleImage3 = values.vehicleImage3?.file ? values.vehicleImage3.file : null;
            const vehicleImage4 = values.vehicleImage4?.file ? values.vehicleImage4.file : null;
            const ecoTest = values.ecoTest?.file ? values.ecoTest.file : null;
            const vehicleBook = values.vehicleBook?.file ? values.vehicleBook.file : null;

            formData.append('revenueLicense', revenueLicense ? revenueLicense : null);
            formData.append('insuranceCard', insuranceCard ? insuranceCard : null);
            formData.append('numberPlateFront', numberPlateFront ? numberPlateFront : null);
            formData.append('numberPlateBack', numberPlateBack ? numberPlateBack : null);
            formData.append('vehicleImage1', vehicleImage1 ? vehicleImage1 : null);
            formData.append('vehicleImage2', vehicleImage2 ? vehicleImage2 : null);
            formData.append('vehicleImage3', vehicleImage3 ? vehicleImage3 : null);
            formData.append('vehicleImage4', vehicleImage4 ? vehicleImage4 : null);
            formData.append('ecoTest', ecoTest ? ecoTest : null);
            formData.append('vehicleBook', vehicleBook ? vehicleBook : null);

            formData.append('vehicleRequest', new Blob([vehicleRequestJsonString], { type: 'application/json' }));

            handleNewRegisterVehicle(formData);
        }
    };

    const registerNewVehicle = async (values) => {
        const response = await registerNewVehicles(values);
        if (response.success) {
            message.success("New vehicle registered successfully");
            fetchAllVehicles();
            handleCancel(false);
        } else {
            message.error("Failed to register new vehicle");
        }
    }

    const updateVehicleDetailsByGivenID = async (registerID, values) => {
        setSpinning(true);
        const response = await updateVehicleByID(registerID, values);
        if (response.success) {
            message.success("Vehicle Details Updated Successfully");
            fetchAllVehicles();
            handleCancel(false);
            setSpinning(false);
        } else {
            console.log(response);
            message.error("Failed To Update Vehicle Details");
        }
        setSpinning(false);
    }

    const searchDetailsByID = (value) => {
        if (value === "") {
            setSearchData(registeredVehicle);
        } else {
            const filteredData = registeredVehicle.filter((session) => {
                return session.registrationNumber.toLowerCase().includes(value.toLowerCase());
            });
            setSearchData(filteredData);
        }
    };

    const validateRegisterNumber = (rule, value) => {
        if (vehicleRegisterNumbers.includes(value)) {
            return Promise.reject('Vehicle Number Already Exists');
        }
        return Promise.resolve();
    };

    const anotherFunction = (rule, value) => {
        if (value === selectedVehicle.registrationNumber) {
            return Promise.resolve();
        }
        if (vehicleRegisterNumbers.includes(value)) {
            return Promise.reject('Vehicle Number Already Exists');
        }
        return Promise.resolve();
    }

    return (
        <body>
            <div style={{ margin: '0', padding: '10px', height: '100%' }}>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="page-title-box">
                            <div className="row">
                                <div className="col">
                                    <h4 className="page-title" style={{ fontWeight: "bold" }}>
                                        Vehicle Management
                                    </h4>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <a href="javascript:void(0);">Vehicle </a>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <a href="javascript:void(0);">Vehicle Management </a>
                                        </li>
                                    </ol>
                                </div>
                                <div className="col-auto align-self-center">
                                    <a
                                        href="#"
                                        className="btn btn-sm btn-outline-primary"
                                        id="Dash_Date"
                                    >
                                        <span className="day-name" id="Day_Name">
                                            Today:
                                        </span>
                                        &nbsp;
                                        <span className="" id="Select_date">
                                            {new Date().toUTCString().slice(5, 16)}
                                        </span>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            className="feather feather-calendar align-self-center icon-xs ms-1"
                                        >
                                            <rect
                                                x="3"
                                                y="4"
                                                width="18"
                                                height="18"
                                                rx="2"
                                                ry="2"
                                            ></rect>
                                            <line x1="16" y1="2" x2="16" y2="6"></line>
                                            <line x1="8" y1="2" x2="8" y2="6"></line>
                                            <line x1="3" y1="10" x2="21" y2="10"></line>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal
                    title={<div>
                        <div style={{ backgroundColor: "#FFFFFF" }}>
                            <b style={{ color: "black" }}>Vehicle Registration Form</b>
                            <br />
                            <span style={{ fontSize: "12px", color: "##9D9B9B" }}>
                                475, Union Place, Colombo 02
                            </span>
                        </div>
                    </div>}
                    visible={visible}
                    onCancel={() => handleCancel(false)}
                    closable={false}
                    footer={null}
                    width={850}
                    destroyOnClose={true}
                    icon={null}
                >
                    <div>
                        <Spin spinning={spinning} fullscreen>
                            <Form
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                layout="vertical"
                                onFinish={handleFormSubmit}
                            // className="textStyles-small"
                            >

                                <div style={{ marginLeft: "10px", overflow: 'auto', height: '500px' }}>
                                    <Row>
                                        <Col span={12}>
                                            <Form.Item
                                                label={<span style={{ fontWeight: "bold" }}>Registration Number</span>}
                                                name="registrationNumber"
                                                rules={[
                                                    { required: true, message: 'Please input vehicle number!' },
                                                    isUpdate
                                                        ? { validator: anotherFunction }
                                                        : { validator: validateRegisterNumber }
                                                ]}
                                                initialValue={selectedVehicle.registrationNumber}
                                            >
                                                <Input
                                                    placeholder="ex: MH 12 AB 1234"
                                                    style={{ width: "90%" }}
                                                />

                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label={<span style={{ fontWeight: "bold" }}>Vehicle Model</span>}
                                                name="model"
                                                rules={[{ required: true, message: 'Please select brand!' }]}
                                                initialValue={selectedVehicle.model}
                                            >
                                                <Input placeholder="Type Vehicle Brand" style={{ width: "90%" }} />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col span={12}>
                                            <Form.Item
                                                label={<span style={{ fontWeight: "bold" }}>Vehicle Brand</span>}
                                                name="brand"
                                                rules={[{ required: true, message: 'Please select brand!' }]}
                                                initialValue={selectedVehicle.brand}
                                            >
                                                <Input placeholder="Type Vehicle Brand" style={{ width: "90%" }} />
                                            </Form.Item>
                                        </Col>

                                        <Col span={12}>
                                            <Form.Item
                                                label={<span style={{ fontWeight: "bold" }}>Distance Unit</span>}
                                                name="distanceUnit"
                                                rules={[{ required: true, message: 'Please select distance unit!' }]}
                                                initialValue={selectedVehicle.distanceUnit}
                                            >
                                                <Select placeholder="Select Distance Unit" style={{ width: "90%" }}>
                                                    <Option value="KM" key="Kilometer">Kilometer</Option>
                                                    <Option value="MILE" key="Mile">Mile</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>


                                    <Row>
                                        <Col span={12}>
                                            <Form.Item
                                                label={<span style={{ fontWeight: "bold" }}>Vehicle Type</span>}
                                                name="vehicleTypeId"
                                                rules={[{ required: true, message: 'Please select vehicle type!' }]}
                                                initialValue={selectedVehicle?.vehicleTypeId}
                                            >
                                                <Select placeholder="Select Vehicle Type" style={{ width: "90%" }}>
                                                    {vehicleType.map((item) => (
                                                        <Option value={item.id} key={item.id}>{item.name}</Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label={<span style={{ fontWeight: "bold" }}>Vehicle Sub Type</span>}
                                                name="vehicleSubTypeId"
                                                rules={[{ required: true, message: 'Please select vehicle sub type!' }]}
                                                initialValue={selectedVehicle.vehicleSubTypeId}
                                            >
                                                <Select placeholder="Select Vehicle Sub Type" style={{ width: "90%" }}>
                                                    {vehicleSubType.map((item) => (
                                                        <Option value={item.id} key={item.id}>{item.name}</Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col span={12}>
                                            <Form.Item
                                                label={<span style={{ fontWeight: "bold" }}>Vehicle Owner</span>}
                                                name="vehicleOwnerId"
                                                rules={[{ required: true, message: 'Please Select Vehicle Owner!' }]}
                                                initialValue={selectedVehicle.vehicleOwnerId}
                                            >
                                                <Select placeholder="Select Vehicle Owner" style={{ width: "90%" }}>
                                                    {
                                                        vehicleOwner.map((item) => (
                                                            <Option value={item.id} key={item.id}>{item.name}</Option>
                                                        ))
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col span={12}>
                                            <Form.Item
                                                label={<span style={{ fontWeight: "bold" }}>Vendor Pool</span>}
                                                name="vendorPoolId"
                                                rules={[{ required: true, message: 'Please Select Vendor Pool!' }]}
                                                initialValue={selectedVehicle.vendorPoolId}
                                            >
                                                <Select placeholder="Select Vendor Pool" style={{ width: "90%" }}>
                                                    {
                                                        vendorPool.map((item) => (
                                                            <Option value={item.id} key={item.id}>{item.name}</Option>
                                                        ))
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col span={12}>
                                            <Form.Item
                                                label={<span style={{ fontWeight: "bold" }}>Vehicle Status</span>}
                                                name="active"
                                                rules={[{ required: true, message: 'Please Select Vehicle Status!' }]}
                                                initialValue={selectedVehicle.active}
                                            >
                                                <Select placeholder="Select Vehicle Status" style={{ width: "90%" }}>
                                                    <Option value={true} key="1">Active</Option>
                                                    <Option value={false} key="2">Inactive</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col span={12}>
                                            <Form.Item
                                                label={<span style={{ fontWeight: "bold" }}>Seating Capacity</span>}
                                                name="seatingCapacity"
                                                rules={[
                                                    { required: true, message: 'Please input seating capacity!' },
                                                    { validator: validatePositiveNumber }
                                                ]}
                                                initialValue={selectedVehicle.seatingCapacity}
                                            >
                                                <Input placeholder="Seating Capacity" style={{ width: "90%" }} type="number" />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    {isUpdate == false ?
                                        <>
                                            <Row>
                                                <Col span={12}>
                                                    <Form.Item
                                                        label={<span style={{ fontWeight: "bold" }}>Image of the Front Number Plate</span>}
                                                        name="numberPlateFront"
                                                    >
                                                        <Upload.Dragger
                                                            name="file"
                                                            multiple={false}
                                                            width="20%"
                                                            beforeUpload={() => false} // Prevent automatic upload
                                                            style={{ width: "90%", height: "20px", backgroundColor: "white" }}
                                                        >
                                                            <p className="ant-upload-drag-icon" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                <div style={{ fontSize: "30px", fontWeight: "bold", borderRadius: "50%", backgroundColor: "#2D5FF4", padding: "10px", width: "30px", height: "30px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                    <UploadOutlined style={{ color: "white", fontWeight: "bold", fontSize: "15px" }} />
                                                                </div>
                                                            </p>
                                                            <p className="ant-upload-text" style={{ textAlign: "center", fontSize: "14px" }}>
                                                                <b>Attach Your Image</b>
                                                            </p>
                                                            <p className="ant-upload-hint" style={{ textAlign: "center", fontSize: "12px" }}>
                                                                <span style={{ color: "red" }}>*</span>File Supported .png & .jpg
                                                            </p>
                                                        </Upload.Dragger>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item
                                                        label={<span style={{ fontWeight: "bold" }}>Image of the Back Number Plate</span>}
                                                        name="numberPlateBack"
                                                    >
                                                        <Upload.Dragger
                                                            name="fileRegisteration"
                                                            multiple={false}
                                                            beforeUpload={() => false} // Prevent automatic upload
                                                            style={{ width: "90%", height: "30%", backgroundColor: "white" }}
                                                        >
                                                            <p className="ant-upload-drag-icon" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                <div style={{ fontSize: "30px", fontWeight: "bold", borderRadius: "50%", backgroundColor: "#2D5FF4", padding: "10px", width: "30px", height: "30px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                    <UploadOutlined style={{ color: "white", fontWeight: "bold", fontSize: "15px" }} />
                                                                </div>
                                                            </p>
                                                            <p className="ant-upload-text" style={{ textAlign: "center", fontSize: "14px" }}>
                                                                <b>Attach Your Image</b>
                                                            </p>
                                                            <p className="ant-upload-hint" style={{ textAlign: "center", fontSize: "12px" }}>
                                                                <span style={{ color: "red" }}>*</span>File Supported .png & .jpg
                                                            </p>
                                                        </Upload.Dragger>
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col span={12}>
                                                    <Form.Item
                                                        label={<span style={{ fontWeight: "bold" }}>Image of Insurance Documents</span>}
                                                        name="insuranceCard"
                                                    >
                                                        <Upload.Dragger
                                                            name="file"
                                                            multiple={false}
                                                            width="20%"
                                                            beforeUpload={() => false} // Prevent automatic upload
                                                            style={{ width: "90%", height: "20px", backgroundColor: "white" }}
                                                        >
                                                            <p className="ant-upload-drag-icon" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                <div style={{ fontSize: "30px", fontWeight: "bold", borderRadius: "50%", backgroundColor: "#2D5FF4", padding: "10px", width: "30px", height: "30px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                    <UploadOutlined style={{ color: "white", fontWeight: "bold", fontSize: "15px" }} />
                                                                </div>
                                                            </p>
                                                            <p className="ant-upload-text" style={{ textAlign: "center", fontSize: "14px" }}>
                                                                <b>Attach Your Image</b>
                                                            </p>
                                                            <p className="ant-upload-hint" style={{ textAlign: "center", fontSize: "12px" }}>
                                                                <span style={{ color: "red" }}>*</span>File Supported .png & .jpg
                                                            </p>
                                                        </Upload.Dragger>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item
                                                        label={<span style={{ fontWeight: "bold" }}>Image Of Revenue License Documents</span>}
                                                        name="revenueLicense"
                                                    >
                                                        <Upload.Dragger
                                                            name="fileRegisteration"
                                                            multiple={false}
                                                            beforeUpload={() => false} // Prevent automatic upload
                                                            style={{ width: "90%", height: "30%", backgroundColor: "white" }}
                                                        >
                                                            <p className="ant-upload-drag-icon" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                <div style={{ fontSize: "30px", fontWeight: "bold", borderRadius: "50%", backgroundColor: "#2D5FF4", padding: "10px", width: "30px", height: "30px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                    <UploadOutlined style={{ color: "white", fontWeight: "bold", fontSize: "15px" }} />
                                                                </div>
                                                            </p>
                                                            <p className="ant-upload-text" style={{ textAlign: "center", fontSize: "14px" }}>
                                                                <b>Attach Your Image</b>
                                                            </p>
                                                            <p className="ant-upload-hint" style={{ textAlign: "center", fontSize: "12px" }}>
                                                                <span style={{ color: "red" }}>*</span>File Supported .png & .jpg
                                                            </p>
                                                        </Upload.Dragger>
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col span={12}>
                                                    <Form.Item
                                                        label={<span style={{ fontWeight: "bold" }}>Image of Eco Test Certificate</span>}
                                                        name="ecoTest"
                                                    >
                                                        <Upload.Dragger
                                                            name="file"
                                                            multiple={false}
                                                            width="20%"
                                                            beforeUpload={() => false} // Prevent automatic upload
                                                            style={{ width: "90%", height: "20px", backgroundColor: "white" }}
                                                        >
                                                            <p className="ant-upload-drag-icon" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                <div style={{ fontSize: "30px", fontWeight: "bold", borderRadius: "50%", backgroundColor: "#2D5FF4", padding: "10px", width: "30px", height: "30px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                    <UploadOutlined style={{ color: "white", fontWeight: "bold", fontSize: "15px" }} />
                                                                </div>
                                                            </p>
                                                            <p className="ant-upload-text" style={{ textAlign: "center", fontSize: "14px" }}>
                                                                <b>Attach Your Image</b>
                                                            </p>
                                                            <p className="ant-upload-hint" style={{ textAlign: "center", fontSize: "12px" }}>
                                                                <span style={{ color: "red" }}>*</span>File Supported .png & .jpg
                                                            </p>
                                                        </Upload.Dragger>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item
                                                        label={<span style={{ fontWeight: "bold" }}>Image Of Vehicle Book Documents</span>}
                                                        name="vehicleBook"
                                                    >
                                                        <Upload.Dragger
                                                            name="fileRegisteration"
                                                            multiple={false}
                                                            beforeUpload={() => false} // Prevent automatic upload
                                                            style={{ width: "90%", height: "30%", backgroundColor: "white" }}
                                                        >
                                                            <p className="ant-upload-drag-icon" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                <div style={{ fontSize: "30px", fontWeight: "bold", borderRadius: "50%", backgroundColor: "#2D5FF4", padding: "10px", width: "30px", height: "30px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                    <UploadOutlined style={{ color: "white", fontWeight: "bold", fontSize: "15px" }} />
                                                                </div>
                                                            </p>
                                                            <p className="ant-upload-text" style={{ textAlign: "center", fontSize: "14px" }}>
                                                                <b>Attach Your Image</b>
                                                            </p>
                                                            <p className="ant-upload-hint" style={{ textAlign: "center", fontSize: "12px" }}>
                                                                <span style={{ color: "red" }}>*</span>File Supported .png & .jpg
                                                            </p>
                                                        </Upload.Dragger>
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col span={12}>
                                                    <Form.Item
                                                        label={<span style={{ fontWeight: "bold" }}>1. Image of Vehicle </span>}
                                                        name="vehicleImage1"
                                                    >
                                                        <Upload.Dragger
                                                            name="file"
                                                            multiple={false}
                                                            width="20%"
                                                            beforeUpload={() => false} // Prevent automatic upload
                                                            style={{ width: "90%", height: "20px", backgroundColor: "white" }}
                                                        >
                                                            <p className="ant-upload-drag-icon" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                <div style={{ fontSize: "30px", fontWeight: "bold", borderRadius: "50%", backgroundColor: "#2D5FF4", padding: "10px", width: "30px", height: "30px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                    <UploadOutlined style={{ color: "white", fontWeight: "bold", fontSize: "15px" }} />
                                                                </div>
                                                            </p>
                                                            <p className="ant-upload-text" style={{ textAlign: "center", fontSize: "14px" }}>
                                                                <b>Attach Your Image</b>
                                                            </p>
                                                            <p className="ant-upload-hint" style={{ textAlign: "center", fontSize: "12px" }}>
                                                                <span style={{ color: "red" }}>*</span>File Supported .png & .jpg
                                                            </p>
                                                        </Upload.Dragger>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item
                                                        label={<span style={{ fontWeight: "bold" }}>2. Image of Vehicle</span>}
                                                        name="vehicleImage2"
                                                    >
                                                        <Upload.Dragger
                                                            name="fileRegisteration"
                                                            multiple={false}
                                                            beforeUpload={() => false} // Prevent automatic upload
                                                            style={{ width: "90%", height: "30%", backgroundColor: "white" }}
                                                        >
                                                            <p className="ant-upload-drag-icon" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                <div style={{ fontSize: "30px", fontWeight: "bold", borderRadius: "50%", backgroundColor: "#2D5FF4", padding: "10px", width: "30px", height: "30px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                    <UploadOutlined style={{ color: "white", fontWeight: "bold", fontSize: "15px" }} />
                                                                </div>
                                                            </p>
                                                            <p className="ant-upload-text" style={{ textAlign: "center", fontSize: "14px" }}>
                                                                <b>Attach Your Image</b>
                                                            </p>
                                                            <p className="ant-upload-hint" style={{ textAlign: "center", fontSize: "12px" }}>
                                                                <span style={{ color: "red" }}>*</span>File Supported .png & .jpg
                                                            </p>
                                                        </Upload.Dragger>
                                                    </Form.Item>
                                                </Col>
                                            </Row>


                                            <Row>
                                                <Col span={12}>
                                                    <Form.Item
                                                        label={<span style={{ fontWeight: "bold" }}>3. Image of Vehicle </span>}
                                                        name="vehicleImage3"
                                                    >
                                                        <Upload.Dragger
                                                            name="file"
                                                            multiple={false}
                                                            width="20%"
                                                            beforeUpload={() => false} // Prevent automatic upload
                                                            style={{ width: "90%", height: "20px", backgroundColor: "white" }}
                                                        >
                                                            <p className="ant-upload-drag-icon" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                <div style={{ fontSize: "30px", fontWeight: "bold", borderRadius: "50%", backgroundColor: "#2D5FF4", padding: "10px", width: "30px", height: "30px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                    <UploadOutlined style={{ color: "white", fontWeight: "bold", fontSize: "15px" }} />
                                                                </div>
                                                            </p>
                                                            <p className="ant-upload-text" style={{ textAlign: "center", fontSize: "14px" }}>
                                                                <b>Attach Your Image</b>
                                                            </p>
                                                            <p className="ant-upload-hint" style={{ textAlign: "center", fontSize: "12px" }}>
                                                                <span style={{ color: "red" }}>*</span>File Supported .png & .jpg
                                                            </p>
                                                        </Upload.Dragger>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item
                                                        label={<span style={{ fontWeight: "bold" }}>4. Image of Vehicle</span>}
                                                        name="vehicleImage4"
                                                    >
                                                        <Upload.Dragger
                                                            name="fileRegisteration"
                                                            multiple={false}
                                                            beforeUpload={() => false} // Prevent automatic upload
                                                            style={{ width: "90%", height: "30%", backgroundColor: "white" }}
                                                        >
                                                            <p className="ant-upload-drag-icon" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                <div style={{ fontSize: "30px", fontWeight: "bold", borderRadius: "50%", backgroundColor: "#2D5FF4", padding: "10px", width: "30px", height: "30px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                    <UploadOutlined style={{ color: "white", fontWeight: "bold", fontSize: "15px" }} />
                                                                </div>
                                                            </p>
                                                            <p className="ant-upload-text" style={{ textAlign: "center", fontSize: "14px" }}>
                                                                <b>Attach Your Image</b>
                                                            </p>
                                                            <p className="ant-upload-hint" style={{ textAlign: "center", fontSize: "12px" }}>
                                                                <span style={{ color: "red" }}>*</span>File Supported .png & .jpg
                                                            </p>
                                                        </Upload.Dragger>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </> : <>

                                            <Row>
                                                <Col span={7}>
                                                    <Image
                                                        style={{ borderRadius: '5px' }}
                                                        width={200}
                                                        height={200}
                                                        src={selectedVehicle.numberPlateFrontUrl ? baseImgURL + selectedVehicle.numberPlateFrontUrl : "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"}
                                                        placeholder={
                                                            <Image
                                                                preview={false}
                                                                src={selectedVehicle.numberPlateFrontUrl ? baseImgURL + selectedVehicle.numberPlateFrontUrl : "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"}
                                                                width="90%"
                                                            />
                                                        }
                                                    /> <br></br>
                                                    <div style={{ marginTop: '10px' }} />
                                                    <Form.Item
                                                        label={<span style={{ fontWeight: "bold" }}>Front Number Plate</span>}
                                                        name="numberPlateFront"
                                                        valuePropName="fileList"
                                                        getValueFromEvent={(e) => e.fileList}
                                                        rules={[
                                                            {
                                                                validator: (rule, value) => {
                                                                    if (value && value.length > 0) {
                                                                        const isImage = value.every(file => file.type.startsWith('image/'));
                                                                        if (!isImage) {
                                                                            return Promise.reject('You can only upload image files!');
                                                                        }
                                                                    }
                                                                    return Promise.resolve();
                                                                },
                                                            },
                                                        ]}
                                                    >
                                                        <Upload
                                                            style={{ borderRadius: '5px' }}
                                                            name="fileRegisteration"
                                                            multiple={false}
                                                            beforeUpload={() => false} // Prevent automatic upload
                                                            accept=".png, .jpg"
                                                        >
                                                            <Button icon={<UploadOutlined />} style={{
                                                                borderRadius: '5px',
                                                                borderColor: '#2D5FF4',
                                                                width: '200px',
                                                            }} >
                                                                Edit Image
                                                            </Button>
                                                        </Upload>
                                                    </Form.Item>
                                                </Col>

                                                <Col span={7} style={{ marginLeft: '30px' }}>
                                                    <Image
                                                        style={{ borderRadius: '5px' }}
                                                        width={200}
                                                        height={200}
                                                        src={selectedVehicle.numberPlateBackUrl ? baseImgURL + selectedVehicle.numberPlateBackUrl : "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"}
                                                        placeholder={
                                                            <Image
                                                                preview={false}
                                                                src={selectedVehicle.numberPlateBackUrl ? baseImgURL + selectedVehicle.numberPlateBackUrl : "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"}
                                                                width="90%"
                                                            />
                                                        }
                                                    /> <br></br>
                                                    <div style={{ marginTop: '10px' }} />
                                                    <Form.Item
                                                        label={<span style={{ fontWeight: "bold" }}>Back Number Plate</span>}
                                                        name="numberPlateBack"
                                                        valuePropName="fileList"
                                                        getValueFromEvent={(e) => e.fileList}
                                                        rules={[
                                                            {
                                                                validator: (rule, value) => {
                                                                    if (value && value.length > 0) {
                                                                        const isImage = value.every(file => file.type.startsWith('image/'));
                                                                        if (!isImage) {
                                                                            return Promise.reject('You can only upload image files!');
                                                                        }
                                                                    }
                                                                    return Promise.resolve();
                                                                },
                                                            },
                                                        ]}
                                                    >
                                                        <Upload
                                                            style={{ borderRadius: '5px' }}
                                                            name="fileRegisteration"
                                                            multiple={false}
                                                            beforeUpload={() => false} // Prevent automatic upload
                                                            accept=".png, .jpg"
                                                        >
                                                            <Button icon={<UploadOutlined />} style={{
                                                                borderRadius: '5px',
                                                                borderColor: '#2D5FF4',
                                                                width: '200px',
                                                            }} >
                                                                Edit Image
                                                            </Button>
                                                        </Upload>
                                                    </Form.Item>
                                                </Col>

                                                <Col span={7} style={{ marginLeft: '30px' }}>
                                                    <Image
                                                        style={{ borderRadius: '5px' }}
                                                        width={200}
                                                        height={200}
                                                        src={selectedVehicle.vehicleImageUrl1 ? baseImgURL + selectedVehicle.vehicleImageUrl1 : "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"}
                                                        placeholder={
                                                            <Image
                                                                preview={false}
                                                                src={selectedVehicle.vehicleImageUrl1 ? baseImgURL + selectedVehicle.vehicleImageUrl1 : "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"}
                                                                width="90%"
                                                            />
                                                        }
                                                    /> <br></br>
                                                    <div style={{ marginTop: '10px' }} />
                                                    <Form.Item
                                                        label={<span style={{ fontWeight: "bold" }}>1. Image of Vehicle </span>}
                                                        name="vehicleImage1"
                                                        valuePropName="fileList"
                                                        getValueFromEvent={(e) => e.fileList}
                                                        rules={[
                                                            {
                                                                validator: (rule, value) => {
                                                                    if (value && value.length > 0) {
                                                                        const isImage = value.every(file => file.type.startsWith('image/'));
                                                                        if (!isImage) {
                                                                            return Promise.reject('You can only upload image files!');
                                                                        }
                                                                    }
                                                                    return Promise.resolve();
                                                                },
                                                            },
                                                        ]}
                                                    >
                                                        <Upload
                                                            style={{ borderRadius: '5px' }}
                                                            name="fileRegisteration"
                                                            multiple={false}
                                                            beforeUpload={() => false} // Prevent automatic upload
                                                            accept=".png, .jpg"
                                                        >
                                                            <Button icon={<UploadOutlined />} style={{
                                                                borderRadius: '5px',
                                                                borderColor: '#2D5FF4',
                                                                width: '200px',
                                                            }} >
                                                                Edit Image
                                                            </Button>
                                                        </Upload>
                                                    </Form.Item>
                                                </Col>

                                            </Row>
                                            <Row>
                                                <Col span={7}>
                                                    <Image
                                                        style={{ borderRadius: '5px' }}
                                                        width={200}
                                                        height={200}
                                                        src={selectedVehicle.vehicleImageUrl2 ? baseImgURL + selectedVehicle.vehicleImageUrl2 : "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"}
                                                        placeholder={
                                                            <Image
                                                                preview={false}
                                                                src={selectedVehicle.vehicleImageUrl2 ? baseImgURL + selectedVehicle.vehicleImageUrl2 : "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"}
                                                                width="90%"
                                                            />
                                                        }
                                                    /> <br></br>
                                                    <div style={{ marginTop: '10px' }} />
                                                    <Form.Item
                                                        label={<span style={{ fontWeight: "bold" }}>2. Image of Vehicle</span>}
                                                        name="vehicleImage2"
                                                        valuePropName="fileList"
                                                        getValueFromEvent={(e) => e.fileList}
                                                        rules={[
                                                            {
                                                                validator: (rule, value) => {
                                                                    if (value && value.length > 0) {
                                                                        const isImage = value.every(file => file.type.startsWith('image/'));
                                                                        if (!isImage) {
                                                                            return Promise.reject('You can only upload image files!');
                                                                        }
                                                                    }
                                                                    return Promise.resolve();
                                                                },
                                                            },
                                                        ]}
                                                    >
                                                        <Upload
                                                            style={{ borderRadius: '5px' }}
                                                            name="fileRegisteration"
                                                            multiple={false}
                                                            beforeUpload={() => false} // Prevent automatic upload
                                                            accept=".png, .jpg"
                                                        >
                                                            <Button icon={<UploadOutlined />} style={{
                                                                borderRadius: '5px',
                                                                borderColor: '#2D5FF4',
                                                                width: '200px',
                                                            }} >
                                                                Edit Image
                                                            </Button>
                                                        </Upload>
                                                    </Form.Item>
                                                </Col>

                                                <Col span={7} style={{ marginLeft: '30px' }}>
                                                    <Image
                                                        style={{ borderRadius: '5px' }}
                                                        width={200}
                                                        height={200}
                                                        src={selectedVehicle.vehicleImageUrl3 ? baseImgURL + selectedVehicle.vehicleImageUrl3 : "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"}
                                                        placeholder={
                                                            <Image
                                                                preview={false}
                                                                src={selectedVehicle.vehicleImageUrl3 ? baseImgURL + selectedVehicle.vehicleImageUrl3 : "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"}
                                                                width="90%"
                                                            />
                                                        }
                                                    /> <br></br>
                                                    <div style={{ marginTop: '10px' }} />
                                                    <Form.Item
                                                        label={<span style={{ fontWeight: "bold" }}>3. Image of Vehicle</span>}
                                                        name="vehicleImage3"
                                                        valuePropName="fileList"
                                                        getValueFromEvent={(e) => e.fileList}
                                                        rules={[
                                                            {
                                                                validator: (rule, value) => {
                                                                    if (value && value.length > 0) {
                                                                        const isImage = value.every(file => file.type.startsWith('image/'));
                                                                        if (!isImage) {
                                                                            return Promise.reject('You can only upload image files!');
                                                                        }
                                                                    }
                                                                    return Promise.resolve();
                                                                },
                                                            },
                                                        ]}
                                                    >
                                                        <Upload
                                                            style={{ borderRadius: '5px' }}
                                                            name="fileRegisteration"
                                                            multiple={false}
                                                            beforeUpload={() => false} // Prevent automatic upload
                                                            accept=".png, .jpg"
                                                        >
                                                            <Button icon={<UploadOutlined />} style={{
                                                                borderRadius: '5px',
                                                                borderColor: '#2D5FF4',
                                                                width: '200px',
                                                            }} >
                                                                Edit Image
                                                            </Button>
                                                        </Upload>
                                                    </Form.Item>
                                                </Col>

                                                <Col span={7} style={{ marginLeft: '30px' }}>
                                                    <Image
                                                        style={{ borderRadius: '5px' }}
                                                        width={200}
                                                        height={200}
                                                        src={selectedVehicle.vehicleImageUrl4 ? baseImgURL + selectedVehicle.vehicleImageUrl4 : "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"}
                                                        placeholder={
                                                            <Image
                                                                preview={false}
                                                                src={selectedVehicle.vehicleImageUrl4 ? baseImgURL + selectedVehicle.vehicleImageUrl4 : "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"}
                                                                width="90%"
                                                            />
                                                        }
                                                    /> <br></br>
                                                    <div style={{ marginTop: '10px' }} />
                                                    <Form.Item
                                                        label={<span style={{ fontWeight: "bold" }}>4. Image of Vehicle</span>}
                                                        name="vehicleImage4"
                                                        valuePropName="fileList"
                                                        getValueFromEvent={(e) => e.fileList}
                                                        rules={[
                                                            {
                                                                validator: (rule, value) => {
                                                                    if (value && value.length > 0) {
                                                                        const isImage = value.every(file => file.type.startsWith('image/'));
                                                                        if (!isImage) {
                                                                            return Promise.reject('You can only upload image files!');
                                                                        }
                                                                    }
                                                                    return Promise.resolve();
                                                                },
                                                            },
                                                        ]}
                                                    >
                                                        <Upload
                                                            style={{ borderRadius: '5px' }}
                                                            name="fileRegisteration"
                                                            multiple={false}
                                                            beforeUpload={() => false} // Prevent automatic upload
                                                            accept=".png, .jpg"
                                                        >
                                                            <Button icon={<UploadOutlined />} style={{
                                                                borderRadius: '5px',
                                                                borderColor: '#2D5FF4',
                                                                width: '200px',
                                                            }} >
                                                                Edit Image
                                                            </Button>
                                                        </Upload>
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col span={7}>
                                                    <Image
                                                        style={{ borderRadius: '5px' }}
                                                        width={200}
                                                        height={200}
                                                        src={selectedVehicle.revenueLicenseUrl ? baseImgURL + selectedVehicle.revenueLicenseUrl : "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"}
                                                        placeholder={
                                                            <Image
                                                                preview={false}
                                                                src={selectedVehicle.revenueLicenseUrl ? baseImgURL + selectedVehicle.revenueLicenseUrl : "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"}
                                                                width="90%"
                                                            />
                                                        }
                                                    /> <br></br>
                                                    <div style={{ marginTop: '10px' }} />
                                                    <Form.Item
                                                        label={<span style={{ fontWeight: "bold" }}>Revenue License</span>}
                                                        name="revenueLicense"
                                                        valuePropName="fileList"
                                                        getValueFromEvent={(e) => e.fileList}
                                                        rules={[
                                                            {
                                                                validator: (rule, value) => {
                                                                    if (value && value.length > 0) {
                                                                        const isImage = value.every(file => file.type.startsWith('image/'));
                                                                        if (!isImage) {
                                                                            return Promise.reject('You can only upload image files!');
                                                                        }
                                                                    }
                                                                    return Promise.resolve();
                                                                },
                                                            },
                                                        ]}
                                                    >
                                                        <Upload
                                                            style={{ borderRadius: '5px' }}
                                                            name="fileRegisteration"
                                                            multiple={false}
                                                            beforeUpload={() => false} // Prevent automatic upload
                                                            accept=".png, .jpg"
                                                        >
                                                            <Button icon={<UploadOutlined />} style={{
                                                                borderRadius: '5px',
                                                                borderColor: '#2D5FF4',
                                                                width: '200px',
                                                            }} >
                                                                Edit Image
                                                            </Button>
                                                        </Upload>
                                                    </Form.Item>
                                                </Col>

                                                <Col span={7} style={{ marginLeft: '30px' }}>
                                                    <Image
                                                        style={{ borderRadius: '5px' }}
                                                        width={200}
                                                        height={200}
                                                        src={selectedVehicle.insuranceCardUrl ? baseImgURL + selectedVehicle.insuranceCardUrl : "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"}
                                                        placeholder={
                                                            <Image
                                                                preview={false}
                                                                src={selectedVehicle.insuranceCardUrl ? baseImgURL + selectedVehicle.insuranceCardUrl : "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"}
                                                                width="90%"
                                                            />
                                                        }
                                                    /> <br></br>
                                                    <div style={{ marginTop: '10px' }} />
                                                    <Form.Item
                                                        label={<span style={{ fontWeight: "bold" }}>Insurance Card</span>}
                                                        name="insuranceCard"
                                                        valuePropName="fileList"
                                                        getValueFromEvent={(e) => e.fileList}
                                                        rules={[
                                                            {
                                                                validator: (rule, value) => {
                                                                    if (value && value.length > 0) {
                                                                        const isImage = value.every(file => file.type.startsWith('image/'));
                                                                        if (!isImage) {
                                                                            return Promise.reject('You can only upload image files!');
                                                                        }
                                                                    }
                                                                    return Promise.resolve();
                                                                },
                                                            },
                                                        ]}
                                                    >
                                                        <Upload
                                                            style={{ borderRadius: '5px' }}
                                                            name="fileRegisteration"
                                                            multiple={false}
                                                            beforeUpload={() => false} // Prevent automatic upload
                                                            accept=".png, .jpg"
                                                        >
                                                            <Button icon={<UploadOutlined />} style={{
                                                                borderRadius: '5px',
                                                                borderColor: '#2D5FF4',
                                                                width: '200px',
                                                            }} >
                                                                Edit Image
                                                            </Button>
                                                        </Upload>
                                                    </Form.Item>
                                                </Col>

                                                <Col span={7} style={{ marginLeft: '30px' }}>
                                                    <Image
                                                        style={{ borderRadius: '5px' }}
                                                        width={200}
                                                        height={200}
                                                        src={selectedVehicle.ecoTestUrl ? baseImgURL + selectedVehicle.ecoTestUrl : "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"}
                                                        placeholder={
                                                            <Image
                                                                preview={false}
                                                                src={selectedVehicle.ecoTestUrl ? baseImgURL + selectedVehicle.ecoTestUrl : "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"}
                                                                width="90%"
                                                            />
                                                        }
                                                    /> <br></br>
                                                    <div style={{ marginTop: '10px' }} />
                                                    <Form.Item
                                                        label={<span style={{ fontWeight: "bold" }}>Eco Test Certificate</span>}
                                                        name="ecoTest"
                                                        valuePropName="fileList"
                                                        getValueFromEvent={(e) => e.fileList}
                                                        rules={[
                                                            {
                                                                validator: (rule, value) => {
                                                                    if (value && value.length > 0) {
                                                                        const isImage = value.every(file => file.type.startsWith('image/'));
                                                                        if (!isImage) {
                                                                            return Promise.reject('You can only upload image files!');
                                                                        }
                                                                    }
                                                                    return Promise.resolve();
                                                                },
                                                            },
                                                        ]}
                                                    >
                                                        <Upload
                                                            style={{ borderRadius: '5px' }}
                                                            name="fileRegisteration"
                                                            multiple={false}
                                                            beforeUpload={() => false} // Prevent automatic upload
                                                            accept=".png, .jpg"
                                                        >
                                                            <Button icon={<UploadOutlined />} style={{
                                                                borderRadius: '5px',
                                                                borderColor: '#2D5FF4',
                                                                width: '200px',
                                                            }} >
                                                                Edit Image
                                                            </Button>
                                                        </Upload>
                                                    </Form.Item>
                                                </Col>

                                            </Row>

                                            <Row>
                                                <Col span={7}>
                                                    <Image
                                                        style={{ borderRadius: '5px' }}
                                                        width={200}
                                                        height={200}
                                                        src={selectedVehicle.vehicleBookUrl ? baseImgURL + selectedVehicle.vehicleBookUrl : "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"}
                                                        placeholder={
                                                            <Image
                                                                preview={false}
                                                                src={selectedVehicle.vehicleBookUrl ? baseImgURL + selectedVehicle.vehicleBookUrl : "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"}
                                                                width="90%"
                                                            />
                                                        }
                                                    /> <br></br>
                                                    <div style={{ marginTop: '10px' }} />
                                                    <Form.Item
                                                        label={<span style={{ fontWeight: "bold" }}>Vehicle Book</span>}
                                                        name="vehicleBook"
                                                        valuePropName="fileList"
                                                        getValueFromEvent={(e) => e.fileList}
                                                        rules={[
                                                            {
                                                                validator: (rule, value) => {
                                                                    if (value && value.length > 0) {
                                                                        const isImage = value.every(file => file.type.startsWith('image/'));
                                                                        if (!isImage) {
                                                                            return Promise.reject('You can only upload image files!');
                                                                        }
                                                                    }
                                                                    return Promise.resolve();
                                                                },
                                                            },
                                                        ]}
                                                    >
                                                        <Upload
                                                            style={{ borderRadius: '5px' }}
                                                            name="fileRegisterations"
                                                            multiple={false}
                                                            beforeUpload={() => false} // Prevent automatic upload
                                                            accept=".png, .jpg"
                                                        >
                                                            <Button icon={<UploadOutlined />} style={{
                                                                borderRadius: '5px',
                                                                borderColor: '#2D5FF4',
                                                                width: '200px',
                                                            }} >
                                                                Edit Image
                                                            </Button>
                                                        </Upload>
                                                    </Form.Item>
                                                </Col>

                                            </Row>
                                        </>
                                    }
                                </div>

                                <Row>
                                    <Col span={24} style={{ textAlign: 'right', marginRight: '100px', marginTop: '40px' }}>
                                        <Form.Item>
                                            {
                                                isUpdate == false ?
                                                    <Button type="primary" htmlType="submit"
                                                        style={{ marginLeft: '10px', borderRadius: '5px', width: '150px', backgroundColor: '#2D5FF4', borderColor: '#2D5FF4' }}>
                                                        Register Vehicle
                                                    </Button> :
                                                    <Button type="primary" htmlType="submit"
                                                        style={{ marginLeft: '10px', borderRadius: '5px', width: '150px', backgroundColor: '#2D5FF4', borderColor: '#2D5FF4' }}>
                                                        Update Vehicle
                                                    </Button>
                                            }
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Spin>
                    </div>
                </Modal>

                <Modal
                    title={<span>
                        <span className="textStyleChilds" 
                        style={{ fontSize: "14px", fontWeight: "bold" }}>
                            Vehicle Information : {selectedVehicle?.registrationNumber}
                        </span>
                    </span>}
                    visible={openInfoModel}
                    onCancel={() => closeInfoModel()}
                    footer={null}
                    width={800}
                >
                    <div style={{ overflow: 'auto', height: '500px' }}>
                    <Descriptions
                        bordered
                        column={2}
                        size="small"
                        style={{ fontSize: "9px" }}
                        className="textStyleChilds"
                    >
                        <Descriptions.Item label="Registration Number" style={{fontSize: "12px"}}> {selectedVehicle?.registrationNumber ? selectedVehicle?.registrationNumber : "N/A"} </Descriptions.Item>
                        <Descriptions.Item label="Model" style={{fontSize: "12px"}}> {selectedVehicle?.model ? selectedVehicle?.model : "N/A"} </Descriptions.Item>
                        <Descriptions.Item label="Brand" style={{fontSize: "12px"}}> {selectedVehicle?.brand ? selectedVehicle?.brand : "N/A"} </Descriptions.Item>
                        <Descriptions.Item label="Driver Name" style={{fontSize: "12px"}}> {selectedVehicle?.driverName ? selectedVehicle?.driverName : "N/A"} </Descriptions.Item>
                        <Descriptions.Item label="Driver Contact" style={{fontSize: "12px"}}> {selectedVehicle?.driverContact ? selectedVehicle?.driverContact : "N/A"} </Descriptions.Item>
                        <Descriptions.Item label="Vehicle Type" style={{fontSize: "12px"}}> {selectedVehicle?.vehicleType?.name ? selectedVehicle?.vehicleType?.name : "N/A"} </Descriptions.Item>
                        <Descriptions.Item label="Vehicle Sub Type" style={{fontSize: "12px"}}> {selectedVehicle?.vehicleSubType?.name ? selectedVehicle?.vehicleSubType?.name : "N/A"} </Descriptions.Item>
                        <Descriptions.Item label="Vehicle Owner" style={{fontSize: "12px"}}> {selectedVehicle?.vehicleOwner?.name ? selectedVehicle?.vehicleOwner?.name : "N/A"} </Descriptions.Item>
                        <Descriptions.Item label="Vehicle Payment Category" style={{fontSize: "12px"}}> {selectedVehicle?.vehiclePaymentCategory?.name ? selectedVehicle?.vehiclePaymentCategory?.name : "N/A"} </Descriptions.Item>
                        <Descriptions.Item label="Vehicle Category" style={{fontSize: "12px"}}> {selectedVehicle?.vehicleMaster?.id === 0 ? "POOL" : selectedVehicle?.vehicleMaster?.vehicleCategory} </Descriptions.Item>
                        <Descriptions.Item label="Region" style={{fontSize: "12px"}}> {selectedVehicle?.regionId ? selectedVehicle?.regionId : "N/A"} </Descriptions.Item>
                        <Descriptions.Item label="Vendor Pool" style={{fontSize: "12px"}}> {selectedVehicle?.vendorPool?.name ? selectedVehicle?.vendorPool?.name : "N/A"} </Descriptions.Item>
                        <Descriptions.Item label="Travelling Officer" style={{fontSize: "12px"}}> {selectedVehicle?.vehicleMaster?.travellingOfficer ? selectedVehicle?.vehicleMaster?.travellingOfficer : "N/A"} </Descriptions.Item>
                        <Descriptions.Item label="Mileage" style={{fontSize: "12px"}}> {selectedVehicle?.mileage ? selectedVehicle?.mileage : "N/A"} </Descriptions.Item>
                        <Descriptions.Item label="Used Mileage" style={{fontSize: "12px"}}> {selectedVehicle?.usedMileage ? selectedVehicle?.usedMileage : "N/A"} </Descriptions.Item>
                        <Descriptions.Item label="Distance Unit" style={{fontSize: "12px"}}> {selectedVehicle?.distanceUnit ? selectedVehicle?.distanceUnit : "N/A"} </Descriptions.Item>
                    </Descriptions>

                    <Row style={{
                        marginTop: '20px',
                    }}>
                        <Col span={6}>
                            <div>
                                <Image
                                    style={{ borderRadius: '5px' }}
                                    width={200}
                                    height={200}
                                    src={selectedVehicle.vehicleBookUrl ? baseImgURL + selectedVehicle.vehicleBookUrl : "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"}
                                    placeholder={
                                        <Image
                                            preview={false}
                                            src={selectedVehicle.vehicleBookUrl ? baseImgURL + selectedVehicle.vehicleBookUrl : "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"}
                                            width="90%"
                                        />
                                    }
                                />
                                <span className="textStyles-small" 
                                style={{ fontSize: "12px", alignItems: "center", justifyContent: "center", display: "flex", marginTop: "10px" }}>
                                    Vehicle Book Image
                                </span>
                            </div>
                        </Col>

                        <Col span={6} style={{ marginLeft: '50px' }}>
                            <div>
                                <Image
                                    style={{ borderRadius: '5px' }}
                                    width={200}
                                    height={200}
                                    src={selectedVehicle.revenueLicenseUrl ? baseImgURL + selectedVehicle.revenueLicenseUrl : "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"}
                                    placeholder={
                                        <Image
                                            preview={false}
                                            src={selectedVehicle.revenueLicenseUrl ? baseImgURL + selectedVehicle.revenueLicenseUrl : "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"}
                                            width="90%"
                                        />
                                    }
                                />
                                <span className="textStyles-small"
                                style={{ fontSize: "12px", alignItems: "center", justifyContent: "center", display: "flex", marginTop: "10px" }}>
                                    Revenue License Image
                                </span>
                            </div>
                        </Col>

                        <Col span={6} style={{ marginLeft: '50px' }}>
                            <div>
                                <Image
                                    style={{ borderRadius: '5px' }}
                                    width={200}
                                    height={200}
                                    src={selectedVehicle.insuranceCardUrl ? baseImgURL + selectedVehicle.insuranceCardUrl : "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"}
                                    placeholder={
                                        <Image
                                            preview={false}
                                            src={selectedVehicle.insuranceCardUrl ? baseImgURL + selectedVehicle.insuranceCardUrl : "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"}
                                            width="90%"
                                        />
                                    }
                                />
                                <span className="textStyles-small"
                                style={{ fontSize: "12px", alignItems: "center", justifyContent: "center", display: "flex", marginTop: "10px" }}>
                                    Insurance Card Image
                                </span>
                            </div>
                        </Col>
                    </Row>

                    <Row style={{
                        marginTop: '20px',
                    }}>
                        <Col span={6}>
                            <div>
                                <Image
                                    style={{ borderRadius: '5px' }}
                                    width={200}
                                    height={200}
                                    src={selectedVehicle.ecoTestUrl ? baseImgURL + selectedVehicle.ecoTestUrl : "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"}
                                    placeholder={
                                        <Image
                                            preview={false}
                                            src={selectedVehicle.ecoTestUrl ? baseImgURL + selectedVehicle.ecoTestUrl : "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"}
                                            width="90%"
                                        />
                                    }
                                />
                                <span className="textStyles-small"
                                style={{ fontSize: "12px", alignItems: "center", justifyContent: "center", display: "flex", marginTop: "10px" }}>
                                    Eco Test Certificate Image
                                </span>
                            </div>
                        </Col>

                        <Col span={6} style={{ marginLeft: '50px' }}>
                            <div>
                                <Image
                                    style={{ borderRadius: '5px' }}
                                    width={200}
                                    height={200}
                                    src={selectedVehicle.numberPlateFrontUrl ? baseImgURL + selectedVehicle.numberPlateFrontUrl : "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"}
                                    placeholder={
                                        <Image
                                            preview={false}
                                            src={selectedVehicle.numberPlateFrontUrl ? baseImgURL + selectedVehicle.numberPlateFrontUrl : "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"}
                                            width="90%"
                                        />
                                    }
                                />
                                <span className="textStyles-small"
                                style={{ fontSize: "12px", alignItems: "center", justifyContent: "center", display: "flex", marginTop: "10px" }}>
                                    Front Number Plate Image
                                </span>
                            </div>
                        </Col>

                        <Col span={6} style={{ marginLeft: '50px' }}>
                            <div>
                                <Image
                                    style={{ borderRadius: '5px' }}
                                    width={200}
                                    height={200}
                                    src={selectedVehicle.numberPlateBackUrl ? baseImgURL + selectedVehicle.numberPlateBackUrl : "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"}
                                    placeholder={
                                        <Image
                                            preview={false}
                                            src={selectedVehicle.numberPlateBackUrl ? baseImgURL + selectedVehicle.numberPlateBackUrl : "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"}
                                            width="90%"
                                        />
                                    }
                                />
                                <span className="textStyles-small"
                                style={{ fontSize: "12px", alignItems: "center", justifyContent: "center", display: "flex", marginTop: "10px" }}>
                                    Back Number Plate Image
                                </span>
                            </div>
                        </Col>
                    </Row>

                    <Row style={{
                        marginTop: '20px',
                    }}>
                        <Col span={6}>
                            <div>
                                <Image
                                    style={{ borderRadius: '5px' }}
                                    width={200}
                                    height={200}
                                    src={selectedVehicle.vehicleImageUrl1 ? baseImgURL + selectedVehicle.vehicleImageUrl1 : "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"}
                                    placeholder={
                                        <Image
                                            preview={false}
                                            src={selectedVehicle.vehicleImageUrl1 ? baseImgURL + selectedVehicle.vehicleImageUrl1 : "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"}
                                            width="90%"
                                        />
                                    }
                                />
                                <span className="textStyles-small"
                                style={{ fontSize: "12px", alignItems: "center", justifyContent: "center", display: "flex", marginTop: "10px" }}>
                                    1. Image of Vehicle
                                </span>
                            </div>
                        </Col>

                        <Col span={6} style={{ marginLeft: '50px' }}>
                            <div>
                                <Image
                                    style={{ borderRadius: '5px' }}
                                    width={200}
                                    height={200}
                                    src={selectedVehicle.vehicleImageUrl2 ? baseImgURL + selectedVehicle.vehicleImageUrl2 : "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"}
                                    placeholder={
                                        <Image
                                            preview={false}
                                            src={selectedVehicle.vehicleImageUrl2 ? baseImgURL + selectedVehicle.vehicleImageUrl2 : "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"}
                                            width="90%"
                                        />
                                    }
                                />
                                <span className="textStyles-small"
                                style={{ fontSize: "12px", alignItems: "center", justifyContent: "center", display: "flex", marginTop: "10px" }}>
                                    2. Image of Vehicle
                                </span>
                            </div>
                        </Col>

                        <Col span={6} style={{ marginLeft: '50px' }}>
                            <div>
                                <Image
                                    style={{ borderRadius: '5px' }}
                                    width={200}
                                    height={200}
                                    src={selectedVehicle.vehicleImageUrl3 ? baseImgURL + selectedVehicle.vehicleImageUrl3 : "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"}
                                    placeholder={
                                        <Image
                                            preview={false}
                                            src={selectedVehicle.vehicleImageUrl3 ? baseImgURL + selectedVehicle.vehicleImageUrl3 : "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"}
                                            width="90%"
                                        />
                                    }
                                />
                                <span className="textStyles-small"
                                style={{ fontSize: "12px", alignItems: "center", justifyContent: "center", display: "flex", marginTop: "10px" }}>
                                    3. Image of Vehicle
                                </span>
                            </div>
                        </Col>
                    </Row>

                    <Row style={{
                        marginTop: '20px',
                    }}>
                        <Col span={6}>
                            <div>
                                <Image
                                    style={{ borderRadius: '5px' }}
                                    width={200}
                                    height={200}
                                    src={selectedVehicle.vehicleImageUrl4 ? baseImgURL + selectedVehicle.vehicleImageUrl4 : "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"}
                                    placeholder={
                                        <Image
                                            preview={false}
                                            src={selectedVehicle.vehicleImageUrl4 ? baseImgURL + selectedVehicle.vehicleImageUrl4 : "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"}
                                            width="90%"
                                        />
                                    }
                                />
                                <span className="textStyles-small"
                                style={{ fontSize: "12px", alignItems: "center", justifyContent: "center", display: "flex", marginTop: "10px" }}>
                                    4. Image of Vehicle
                                </span>
                            </div>
                        </Col>
                    </Row>
                    </div>
                </Modal>

                <div style={{
                    backgroundColor: "#FFFFFF",
                    padding: "20px 20px 20px 20px",
                    borderRadius: "10px",
                    marginTop: "5px",
                }}>


                    {/* <div style={{ padding: 10, borderRadius: 10 }}>
                        <Row>
                            <Col span={12}>
                                <Input
                                            placeholder="Search By Registration Number"
                                            prefix={<SearchOutlined />}
                                            className="textStyle-small"
                                            style={{ width: 250, marginRight: 10, borderRadius: 5, borderColor: '#2327fe' }}
                                            onChange={(e) => { searchDetailsByID(e.target.value) }}
                                        />
                            </Col>
                            <Col span={12}>
                            <div className="alignRight">
                                    <Col span={24}>
                                        <Button 
                                        style={{ borderRadius: "5px", backgroundColor: "#00AC4F", width: "180px" }}
                                            onClick={() => openModel(false)}>
                                            <span className="textStyles-small" style={{ color: "white" }}>
                                                New Registration
                                            </span>
                                        </Button>

                                        <Select
                                            className='borderedSelect'
                                            bordered={false}
                                            allowClear
                                            style={{ width: '220px' }}
                                            placeholder="Export As"
                                            onChange={(value) => {
                                                if (value === 1) {
                                                    exportData(vehicleListExportDataMap(registeredVehicle), "Vehicle List Export_", 1);
                                                } else {
                                                    exportData(vehicleListExportDataMap(registeredVehicle), "Vehicle List Export_", 2);
                                                }
                                            }}
                                        >
                                            <Select.Option value={2}><span className="textStyles-small">CSV Export</span></Select.Option>
                                            <Select.Option value={1}><span className="textStyles-small">Excel Export</span></Select.Option>
                                        </Select>
                                    </Col>
                                </div>
                            </Col>
                        </Row>
                    </div> <br /> */}

                    <div style={{ padding: 10, borderRadius: 10 }}>
                        <Row>
                            <Col span={12}>
                            <Input
                                    placeholder="Search By Registration Number"
                                    prefix={<SearchOutlined />}
                                    className="textStyle-small"
                                    style={{ width: 250, marginRight: 10, borderRadius: 5, borderColor: '#A9A9A9',height: 35  }}
                                    onChange={(e) => { searchDetailsByID(e.target.value) }}
                            />
                            </Col>
                            <Col span={12}>
                                <div className="alignRight">
                                        <Select
                                            className='borderedSelect'
                                            bordered={false}
                                            allowClear
                                            style={{ width: '220px', height: 35 }}
                                            placeholder="Export As"
                                            onChange={(value) => {
                                                if (value === 1) {
                                                    exportData(vehicleListExportDataMap(registeredVehicle), "Vehicle List Export_", 1);
                                                } else {
                                                    exportData(vehicleListExportDataMap(registeredVehicle), "Vehicle List Export_", 2);
                                                }
                                            }}
                                        >
                                            <Select.Option value={2}><span className="textStyles-small">CSV Export</span></Select.Option>
                                            <Select.Option value={1}><span className="textStyles-small">Excel Export</span></Select.Option>
                                        </Select>

                                        <Button 
                                        style={{ borderRadius: "5px", backgroundColor: "#00AC4F", width: "180px", marginLeft: 5, height: 35 }}
                                            onClick={() => openModel(false)}>
                                            <span className="textStyles-small" style={{ color: "white" }}>
                                                New Registration
                                            </span>
                                        </Button>
                                </div>
                            </Col>

                        </Row>
                    </div> <br />


                    <Table
                        className="table-striped-rows"
                        dataSource={searchData === null ? registeredVehicle : searchData}
                        columns={columns}
                        loading={registeredVehicle.length === 0 ? true : false}
                        rowKey="key"
                        size="small"
                    />
                </div>
            </div>
        </body>
    );
};

export default VehicleList;

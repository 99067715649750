import React, { Component } from 'react'
import { Table, Input, InputNumber, Tag, Space, Button, Row, Col, Drawer, Select, Spin, Switch, message, Modal, DatePicker, Form } from "antd";
import { updateMpConnection, getAllDropdowns, dataUpdateGeneralDetails } from "../../api/index";
import moment from 'moment';
import { ConsoleSqlOutlined } from '@ant-design/icons';
import SimpleReactValidator from "simple-react-validator";
const { Option } = Select;

class MainPowerConnection extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        
        this.state = {
            switchStatus: true,
            objectSet: {
                "mpcPowerConnectionType": {},
                "mpcNoOfPhases": 0,
                "mpcCapacityPerPhase": 0,
                "mpcPhase1Current": 0,
                "mpcPhase2Current": 0,
                "mpcPhase3Current": 0,
                "mpcPhase1Voltage": 0,
                "mpcPhase2Voltage": 0,
                "mpcPhase3Voltage": 0,
                "mpcSeparateFeederAvailablity": 'No',
                "mpcAverageAvailabilityPerday": 0,
                "noOfPIVCopies": 0,
                "sooCEBLECODepot": null,
                "powerConnectionAuthority": null,
                "powerConnectedDate": null,
                "mpcCebLecoAccountNumber": null,
            },
            dropdownDataSet: {
                "mpcNoOfPhases": [],
                "mpcCapacityPerPhase": [],
                "mpcSeparateFeederAvailablity": [],
                "powerAuthority": [],
            },
            sopdataSet : {
                "noOfPIVCopies": null,
                "sooCEBLECODepot": null,
                "powerConnectionAuthority": null,
                "powerConnectedDate": null,
            },
            noOfPIVCopies: null,
            sooCEBLECODepot: null,
            powerConnectionAuthority: null,
            powerConnectedDate: null,
        }

        this.fieldValidation = {
            mpcPowerConnectionType: {
                status: "",
                errorMsg: "Please Select Power Connection Type"
            },
            mpcNoOfPhases: {
                status: "",
                errorMsg: "Please Enter No. of phases"
            },
            mpcCapacityPerPhase: {
                status: "",
                errorMsg: "Please Enter Capacity per phase (A)"
            },
            mpcPhase1Current: {
                status: "",
                errorMsg: "Please Enter Phase 1 Current (A)"
            },
            mpcPhase2Current: {
                status: "",
                errorMsg: "Please Enter Phase 2 Current (A)"
            },
            mpcPhase3Current: {
                status: "",
                errorMsg: "Please Enter Phase 3 Current (A)"
            },
            mpcPhase1Voltage: {
                status: "",
                errorMsg: "Please Enter Phase 1 Voltage (V)"
            },
            mpcPhase2Voltage: {
                status: "",
                errorMsg: "Please Enter Phase 2 Voltage (V)"
            },
            mpcPhase3Voltage: {
                status: "",
                errorMsg: "Please Enter Phase 3 Voltage (V)"
            }
        }

        this.handlempcCapacityPerPhase = this.handlempcCapacityPerPhase.bind(this);
        this.handlEmpcNoOfPhases = this.handlEmpcNoOfPhases.bind(this);
        this.handleMpcPhase1Current = this.handleMpcPhase1Current.bind(this);
        this.handleMpcPhase2Current = this.handleMpcPhase2Current.bind(this);
        this.handleMpcPhase3Current = this.handleMpcPhase3Current.bind(this);
        this.handleMpcPhase1Voltage = this.handleMpcPhase1Voltage.bind(this);
        this.handleMpcPhase2Voltage = this.handleMpcPhase2Voltage.bind(this);
        this.handleMpcPhase3Voltage = this.handleMpcPhase3Voltage.bind(this);
        this.handleMpcAverageAvailabilityPerday = this.handleMpcAverageAvailabilityPerday.bind(this);
        this.onChangeMpcPowerConnectionType = this.onChangeMpcPowerConnectionType.bind(this);

        this.getMpConnectionDropDownData();
    }

    getMpConnectionDropDownData = async () => {
        var res = await getAllDropdowns();
        if (res.success) {
            console.log("Main Power Connection Type : ", res.data["Main Power Connection"])
            this.setState({
                dropdownDataSet: {
                    "mpcNoOfPhases": res.data["Main Power Connection"]["No. of phases"],
                    "mpcCapacityPerPhase": res.data["Main Power Connection"]["Capacity per phase (A)"],
                    "mpcSeparateFeederAvailablity": res.data["Main Power Connection"]["Separate Feeder Availablity"],
                    "powerAuthority":  ["CEB", "LECO"],
                }
            })
            console.log(this.state.dropdownDataSet);
        } else {
            message.warning('Issue With Dropdown Data Fetching! : ' + res.data.message);
        }
    }

    mandetorySet = (status, attribute) => {
        switch (attribute) {
            case "mpcPowerConnectionType":
                if (status) {
                    this.fieldValidation.mpcPowerConnectionType.status = "true";
                } else {
                    this.fieldValidation.mpcPowerConnectionType.status = "flase";
                    message.warning(this.fieldValidation.mpcPowerConnectionType.errorMsg);
                    this.mandetorySet(false, "mpcPowerConnectionType");
                } break;
            case "mpcNoOfPhases":
                if (status) {
                    this.fieldValidation.mpcNoOfPhases.status = "true";
                } else {
                    this.fieldValidation.mpcNoOfPhases.status = "flase";
                    message.warning(this.fieldValidation.mpcNoOfPhases.errorMsg);
                } break;
            case "mpcCapacityPerPhase":
                if (status) {
                    this.fieldValidation.mpcCapacityPerPhase.status = "true";
                } else {
                    this.fieldValidation.mpcCapacityPerPhase.status = "flase";
                    message.warning(this.fieldValidation.mpcCapacityPerPhase.errorMsg);
                } break;
            case "mpcPhase1Current":
                if (status) {
                    this.fieldValidation.mpcPhase1Current.status = "true";
                } else {
                    this.fieldValidation.mpcPhase1Current.status = "flase";
                    message.warning(this.fieldValidation.mpcPhase1Current.errorMsg);
                } break;
            case "mpcPhase2Current":
                if (status) {
                    this.fieldValidation.mpcPhase2Current.status = "true";
                } else {
                    this.fieldValidation.mpcPhase2Current.status = "flase";
                    message.warning(this.fieldValidation.mpcPhase2Current.errorMsg);
                } break;
            case "mpcPhase3Current":
                if (status) {
                    this.fieldValidation.mpcPhase3Current.status = "true";
                } else {
                    this.fieldValidation.mpcPhase3Current.status = "flase";
                    message.warning(this.fieldValidation.mpcPhase3Current.errorMsg);
                } break;
            case "mpcPhase1Voltage":
                if (status) {
                    this.fieldValidation.mpcPhase1Voltage.status = "true";
                } else {
                    this.fieldValidation.mpcPhase1Voltage.status = "flase";
                    message.warning(this.fieldValidation.mpcPhase1Voltage.errorMsg);
                } break;
            case "mpcPhase2Voltage":
                if (status) {
                    this.fieldValidation.mpcPhase2Voltage.status = "true";
                } else {
                    this.fieldValidation.mpcPhase2Voltage.status = "flase";
                    message.warning(this.fieldValidation.mpcPhase2Voltage.errorMsg);
                } break;
            case "mpcPhase3Voltage":
                if (status) {
                    this.fieldValidation.mpcPhase3Voltage.status = "true";
                } else {
                    this.fieldValidation.mpcPhase3Voltage.status = "flase";
                    message.warning(this.fieldValidation.mpcPhase3Voltage.errorMsg);
                } break;
        }
    }

    mandetorySet = (status, attribute) => {
        const field = this.fieldValidation[attribute];
        if (status) {
            field.status = "true";
        } else {
            field.status = "false";
            message.warning(field.errorMsg);
        }
    };
    
    onChangeMpcPowerConnectionType(value) {
        console.log("Main Power Connection Type Value : ", value);
        if (value != null) {
            this.mandetorySet(true, "mpcPowerConnectionType");
        } else {
            this.mandetorySet(false, "mpcPowerConnectionType");
        }
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                mpcPowerConnectionType: { "id": value }
            },
        })
    }
    
    switchTo() {
        this.state.objectSet['mpcSeparateFeederAvailablity'] = !this.state.objectSet['mpcSeparateFeederAvailablity'];
    }
    handleMpcAverageAvailabilityPerday(e) {
        console.log(e)
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                mpcCebLecoAccountNumber: e.target.value
            },

        }, () => {
            console.log(this.state.objectSet)
        })
    }
    handleMpcPhase3Voltage(e) {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                mpcPhase3Voltage: e
            }

        }, () => {
            console.log(this.state.objectSet)
        })
    }
    handleMpcPhase2Voltage(e) {
        console.log(e)


        this.setState({
            objectSet: {
                ...this.state.objectSet,
                mpcPhase2Voltage: e
            }

        }, () => {
            console.log(this.state.objectSet)
        })
    }

    handleMpcPhase1Voltage(e) {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                mpcPhase1Voltage: e
            }

        }, () => {
            console.log(this.state.objectSet)
        })
    }
    handleMpcPhase3Current(e) {
        console.log(e)


        this.setState({
            objectSet: {
                ...this.state.objectSet,
                mpcPhase3Current: e
            }

        }, () => {
            console.log(this.state.objectSet)
        })
    }
    handleMpcPhase2Current(e) {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                mpcPhase2Current: e
            }

        }, () => {
            console.log(this.state.objectSet)
        })
    }
    handleMpcPhase1Current(e) {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                mpcPhase1Current: e
            }
        }, () => {
            console.log(this.state.objectSet)
        })
    }
    handlEmpcNoOfPhases(e) {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                mpcNoOfPhases: e.target.value
            }

        }, () => {
            console.log(this.state.objectSet)
        })
    }
    handlempcCapacityPerPhase(e) {
        console.log(e)


        this.setState({
            objectSet: {
                ...this.state.objectSet,
                mpcCapacityPerPhase: e
            }

        }, () => {
            console.log(this.state.objectSet)
        })
    }
    componentDidMount() {
        console.log(' Power Connection type')
        console.log(this.props.details.access_technologies_data)
        this.setState({
            objectSet: {
                "mpcPowerConnectionType": this.props.details.access_technologies_data?.mpcPowerConnectionType,
                "mpcNoOfPhases": this.props.details.access_technologies_data?.mpcNoOfPhases,
                "mpcCapacityPerPhase": this.props.details.access_technologies_data?.mpcCapacityPerPhase,
                "mpcPhase1Current": this.props.details.access_technologies_data?.mpcPhase1Current,
                "mpcPhase2Current": this.props.details.access_technologies_data?.mpcPhase2Current,
                "mpcPhase3Current": this.props.details.access_technologies_data?.mpcPhase3Current,
                "mpcPhase1Voltage": this.props.details.access_technologies_data?.mpcPhase1Voltage,
                "mpcPhase2Voltage": this.props.details.access_technologies_data?.mpcPhase2Voltage,
                "mpcPhase3Voltage": this.props.details.access_technologies_data?.mpcPhase3Voltage,
                "mpcSeparateFeederAvailablity": this.props.details.access_technologies_data?.mpcSeparateFeederAvailablity,
                "mpcAverageAvailabilityPerday": this.props.details.access_technologies_data?.mpcAverageAvailabilityPerday,
                "noOfPIVCopies": this.props.details?.access_technologies_data?.noOfPIVCopies,
                "sooCEBLECODepot": this.props?.details?.access_technologies_data?.sooCEBLECODepot,
                "powerConnectionAuthority": this.props?.details?.access_technologies_data?.powerConnectionAuthority,
                "powerConnectedDate": this.props.details?.access_technologies_data?.powerConnectedDate,
                "mpcCebLecoAccountNumber": this.props.details?.access_technologies_data?.mpcCebLecoAccountNumber,
            },
            sopdataSet: {
                "noOfPIVCopies": this.props.details?.access_technologies_data?.noOfPIVCopies,
                "sooCEBLECODepot": this.props?.details?.access_technologies_data?.sooCEBLECODepot,
                "powerConnectionAuthority": this.props?.details?.access_technologies_data?.powerConnectionAuthority,
                "powerConnectedDate": this.props.details?.access_technologies_data?.powerConnectedDate,
            }
        }, () => {
            console.log("opopop")
            console.log(this.state.objectSet)
        })
        console.log(this.props.details)
    }

        
	showUpdateConfirm = () => {
		const { confirm } = Modal;
		confirm({
			title: 'Are You Want To Update These Data, Related To Main Power Connection?',
			onOk: async () => {
                this.dataSetToUpdate();
			},
			onCancel() { },
		});
	};

    dataSetToUpdate = async () => {
        console.log(this.state.onChangeDropdownData);
        console.log(this.state.objectSet)
        var res = await updateMpConnection(this.props.details2.site_details.id, this.state.objectSet);
        if (res.success) {
            message.success('Data Related To Main Power Connection Updated Successfully!');
            this.props.parentMethod();
            this.setState({
                switchStatus: true
            })
        } else {
            message.warning('Data Related To Main Power Connection Not Updated!');
        }
        this.dataSetToUpdateMain();
    }

    dataSetToUpdateMain = async () => {
        console.log(this.state.objectSet)
        console.log(this.props.detailsupdateGeneralDetails.updateGeneralDetails)
        let remoteJob = {
            ...this.props.detailsupdateGeneralDetails.updateGeneralDetails,
            ...this.state.sopdataSet
        };
        const formData = new FormData();
        formData.append('id', this.props?.details?.access_technologies_data?.id);
        formData.append('object', JSON.stringify(remoteJob));

        var res = await dataUpdateGeneralDetails(formData);

        if (res.success) {
            message.success('Successfully Update!, Data Related To Main Power Connection');
            this.props.parentMethod();
            this.setState({
                switchStatus: true
            })
        } else {
            message.warning(' Something went wrong!')
        }
    }

    
    dataUpdate() {
        console.log("ssss")
        this.setState({
            switchStatus: false
        })
    }

    
    // Common Section Data Management Functions For 2024-01-31 C.R
    onChangepowerConnectedDate = (date, dateString) => {
        this.setState({
            sopdataSet: {
                ...this.state.sopdataSet,
                powerConnectedDate: dateString
            }

        }, () => {
            console.log(this.state.objectSet)
        })
    };
    handlsooCEBLECODepot = (e) => {
        this.setState({
            sopdataSet: {
                ...this.state.sopdataSet,
                sooCEBLECODepot: e.target.value
            }

        }, () => {
            console.log(this.state.objectSet)
        })

    }
    handlpowerConnectionAuthority = (e) => {
        this.setState({
            sopdataSet: {
                ...this.state.sopdataSet,
                powerConnectionAuthority: e.target.value
            }

        }, () => {
            console.log(this.state.objectSet)
        })
    }
    handlnoOfPIVCopies = (e) => {
        this.setState({
            sopdataSet: {
                ...this.state.sopdataSet,
                noOfPIVCopies: e
            }
        }, () => {
            console.log(this.state.objectSet)
        })
    }
    
    handlsooCapexAmount3PPowerConnection = (e) => {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                sooCapexAmount3PPowerConnection: e
            }

        }, () => {
            console.log(this.state.objectSet)
        })
    }
    
    close = async () => {
        this.props.parentMethod();
        this.setState({
            objectSet: {
                "mpcPowerConnectionType": this.props.details.access_technologies_data?.mpcPowerConnectionType,
                "mpcNoOfPhases": this.props.details.access_technologies_data?.mpcNoOfPhases,
                "mpcCapacityPerPhase": this.props.details.access_technologies_data?.mpcCapacityPerPhase,
                "mpcPhase1Current": this.props.details.access_technologies_data?.mpcPhase1Current,
                "mpcPhase2Current": this.props.details.access_technologies_data?.mpcPhase2Current,
                "mpcPhase3Current": this.props.details.access_technologies_data?.mpcPhase3Current,
                "mpcPhase1Voltage": this.props.details.access_technologies_data?.mpcPhase1Voltage,
                "mpcPhase2Voltage": this.props.details.access_technologies_data?.mpcPhase2Voltage,
                "mpcPhase3Voltage": this.props.details.access_technologies_data?.mpcPhase3Voltage,
                "mpcSeparateFeederAvailablity": this.props.details.access_technologies_data?.mpcSeparateFeederAvailablity,
                "mpcAverageAvailabilityPerday": this.props.details.access_technologies_data?.mpcAverageAvailabilityPerday,
                "mpcCebLecoAccountNumber": this.props.details?.access_technologies_data?.mpcCebLecoAccountNumber,
            },
            sopdataSet: {
                "noOfPIVCopies": this.props.details?.access_technologies_data?.noOfPIVCopies,
                "sooCEBLECODepot": this.props?.details?.access_technologies_data?.sooCEBLECODepot,
                "powerConnectionAuthority": this.props?.details?.access_technologies_data?.powerConnectionAuthority,
                "powerConnectedDate": this.props.details?.access_technologies_data?.powerConnectedDate,
            }
        });
        this.setState({
            switchStatus: true
        });
    }
    render() {
        const { Option } = Select;
        const { details } = this.props.details
        return (
          <>
            {this.props.details.access_technologies_data != null ? (
              <div className="row">
                <div className="col-md-12">
                  <div class="card">
                    <div class="card-header">
                      <div class="d-flex justify-content-between align-items-center">
                        <h4 class="card-title">Information</h4>
                        {this.state.switchStatus ? (
                          <button
                            type="button"
                            class="btn btn-soft-primary btn-icon-square-sm"
                            onClick={() => this.dataUpdate()}
                          >
                            {" "}
                            <i class="mdi mdi-pencil-outline"></i>
                          </button>
                        ) : (
                          <div class="d-flex justify-content-end align-items-center">
                            <button
                              type="button"
                              class="btn btn-soft-secondary "
                              onClick={() => this.close()}
                              style={{ marginRight: "10px" }}
                            >
                              {" "}
                              Cancel{" "}
                            </button>
                            {
                                this.state.objectSet?.mpcPowerConnectionType != null &&
                                this.state.objectSet?.mpcNoOfPhases != null &&
                                this.state.objectSet?.mpcCapacityPerPhase != null &&
                                this.state.objectSet?.mpcPhase1Current != null &&
                                this.state.objectSet?.mpcPhase2Current != null &&
                                this.state.objectSet?.mpcPhase3Current != null &&
                                this.state.objectSet?.mpcPhase1Voltage != null &&
                                this.state.objectSet?.mpcPhase2Voltage != null &&
                                this.state.objectSet?.mpcPhase3Voltage != null ?
                                <button
                                  type="button"
                                  class="btn btn-soft-primary "
                                  onClick={() => this.showUpdateConfirm()}
                                >
                                  {" "}
                                    Submit to Update{" "}
                                </button> : <></>
                            }
                          </div>
                        )}
                      </div>
                    </div>

                    <div class="card-body">
                      <div class="row">
                        <div className="col-md-6">
                          <ul class="list-group">
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                              <div>
                                <i class="la la-hand-o-right text-primary me-2"></i>{" "}
                                No of PIV Copies
                              </div>
                              {this.state.switchStatus ? (
                                <>
                                  {this.props.details?.access_technologies_data
                                    ?.noOfPIVCopies != null ? (
                                    <span class="badge badge-outline-success">
                                      {
                                        this.props.details
                                          ?.access_technologies_data
                                          ?.noOfPIVCopies
                                      }
                                    </span>
                                  ) : (
                                    <span class="badge badge-outline-warning">
                                      Currently Not Set
                                    </span>
                                  )}
                                </>
                              ) : (
                                <>
                                  {" "}
                                  <InputNumber
                                    min={0}
                                    style={{ width: "200px" }}
                                    value={this.state.sopdataSet?.noOfPIVCopies}
                                    id="airCoolingMethod"
                                    onChange={this.handlnoOfPIVCopies}
                                  />{" "}
                                </>
                              )}
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                              <div>
                                <i class="la la-hand-o-right text-primary me-2"></i>{" "}
                                Power Connection Authority
                              </div>
                              <div>
                                {this.state.switchStatus.toString() ==
                                "true" ? (
                                  this.props?.details?.access_technologies_data
                                    ?.powerConnectionAuthority ? (
                                    <span class="badge badge-outline-success">
                                      {
                                        this.props?.details
                                          ?.access_technologies_data
                                          ?.powerConnectionAuthority
                                      }
                                    </span>
                                  ) : (
                                    <span class="badge badge-outline-danger">
                                      Currently Not Set
                                    </span>
                                  )
                                ) : (
                                  <Select
                                    defaultValue={
                                      this.props?.details
                                        ?.access_technologies_data
                                        ?.powerConnectionAuthority
                                    }
                                    style={{ width: "200px" }}
                                    onChange={(e) => {
                                      this.state.sopdataSet.powerConnectionAuthority =
                                        e;
                                    }}
                                  >
                                    {this.state.dropdownDataSet.powerAuthority.map(
                                      (x, y) => {
                                        return <Option value={x}>{x}</Option>;
                                      }
                                    )}
                                  </Select>
                                )}
                              </div>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                              <div>
                                <i className="la la-hand-o-right text-primary me-2"></i>{" "}
                                Power Connection type
                                {!this.state.switchStatus &&
                                  !this.props.details.access_technologies_data
                                    ?.mpcPowerConnectionType && (
                                    <span style={{ color: "red" }}> *</span>
                                  )}
                              </div>
                              {this.state.switchStatus ? (
                                <>
                                  {" "}
                                  {this.props.details.access_technologies_data
                                    ?.mpcPowerConnectionType != null ? (
                                    <span class="badge badge-outline-success">
                                      {
                                        this.props.details
                                          .access_technologies_data
                                          ?.mpcPowerConnectionType?.name
                                      }
                                    </span>
                                  ) : (
                                    <span class="badge badge-outline-warning">
                                      Currently Not Set
                                    </span>
                                  )}
                                </>
                              ) : (
                                <>
                                                                <p style={{color: "red"}}>
                                    {this.state.objectSet?.mpcPowerConnectionType == null? "Please Select Power Connection Type": ""}
                                    </p>
                                <Select
                                  showSearch
                                  onChange={this.onChangeMpcPowerConnectionType}
                                  defaultValue={
                                    this.state.objectSet?.mpcPowerConnectionType
                                      ?.id
                                  }
                                  style={{ width: "200px" }}
                                  placeholder="Search to Select"
                                  optionFilterProp="children"
                                >
                                  {this.props.mpConnectionMasterData?.powerConnectionType?.map(
                                    (item, index) => {
                                      return (
                                        <Option value={item?.id}>
                                          {item.name}
                                        </Option>
                                      );
                                    }
                                  )}
                                </Select>
                                </>
                              )}
                            </li>

                            <li class="list-group-item d-flex justify-content-between align-items-center">
                              <div>
                                <i class="la la-hand-o-right text-primary me-2"></i>{" "}
                                No. of phases{" "}
                                {this.state.switchStatus ? (
                                  <></>
                                ) : (
                                  <span style={{ color: "red" }}> *</span>
                                )}
                              </div>
                              <div>
                                {this.state.switchStatus.toString() ==
                                "true" ? (
                                  this.props.details.access_technologies_data
                                    ?.mpcNoOfPhases != null ||
                                  this.props.details.access_technologies_data
                                    ?.mpcNoOfPhases != undefined ? (
                                    <span className="badge badge-outline-success">
                                      {
                                        this.props.details
                                          .access_technologies_data
                                          .mpcNoOfPhases
                                      }
                                    </span>
                                  ) : (
                                    <span className="badge badge-outline-success">
                                      Not Defined Yet
                                    </span>
                                  )
                                ) : (
                                    <>
                                  <Select
                                    defaultValue={
                                      this.props.details
                                        .access_technologies_data?.mpcNoOfPhases
                                    }
                                    style={{ width: '200px' }}
                                    onChange={(e) => {
                                      this.state.objectSet.mpcNoOfPhases = e;
                                    }}
                                  >
                                    {this.state.dropdownDataSet.mpcNoOfPhases.map(
                                      (x, y) => {
                                        return <Option value={x}>{x}</Option>;
                                      }
                                    )}
                                  </Select>
                                    <p style={{color: "red"}}>
                                        {this.state.objectSet?.mpcNoOfPhases == null? "Please Enter No. of phases": ""}
                                    </p>
                                  </>
                                )}
                              </div>
                            </li>

                            <li class="list-group-item d-flex justify-content-between align-items-center">
                              <div>
                                <i class="la la-hand-o-right text-primary me-2"></i>{" "}
                                Capacity per phase (A){" "}
                                {this.state.switchStatus ? (
                                  <></>
                                ) : (
                                  <span style={{ color: "red" }}> *</span>
                                )}
                              </div>
                              <div>
                                {this.state.switchStatus.toString() ==
                                "true" ? (
                                  this.props.details.access_technologies_data
                                    ?.mpcCapacityPerPhase != null ||
                                  this.props.details.access_technologies_data
                                    ?.mpcCapacityPerPhase != undefined ? (
                                    <span class="badge badge-outline-success">
                                      {
                                        this.props.details
                                          .access_technologies_data
                                          .mpcCapacityPerPhase
                                      }
                                    </span>
                                  ) : (
                                    <span class="badge badge-outline-warning">
                                      Not Defined Yet
                                    </span>
                                  )
                                ) : (
                                  <>
                                  <Select
                                    defaultValue={
                                      this.props.details
                                        .access_technologies_data
                                        ?.mpcCapacityPerPhase
                                    }
                                    style={{ width: '200px' }}
                                    onChange={(e) => {
                                      this.state.objectSet.mpcCapacityPerPhase =
                                        e;
                                    }}
                                  >
                                    {this.state.dropdownDataSet.mpcCapacityPerPhase.map(
                                      (x, y) => {
                                        return <Option value={x}>{x}</Option>;
                                      }
                                    )}
                                  </Select>
                                  <p style={{color: "red"}}>
                                    {this.state.objectSet?.mpcCapacityPerPhase == null? "Please Enter Capacity per phase": ""}
                                    </p>
                                  </>
                                )}
                              </div>
                            </li>

                            <li class="list-group-item d-flex justify-content-between align-items-center">
                              <div>
                                <i class="la la-hand-o-right text-primary me-2"></i>{" "}
                                Phase 1 Voltage (V){" "}
                                {this.state.switchStatus ? (
                                  <></>
                                ) : (
                                  <span style={{ color: "red" }}> *</span>
                                )}
                              </div>

                              {this.state.switchStatus ? (
                                <>
                                  {" "}
                                  {this.props.details.access_technologies_data
                                    ?.mpcPhase1Voltage ? (
                                    <span class="badge badge-outline-success">
                                      {
                                        this.props.details
                                          .access_technologies_data
                                          .mpcPhase1Voltage
                                      }
                                    </span>
                                  ) : (
                                    <span class="badge badge-outline-warning">
                                      {
                                        this.props.details
                                          .access_technologies_data
                                          ?.mpcPhase1Voltage
                                      }
                                    </span>
                                  )}
                                </>
                              ) : (
                                <>
                                  {" "}
                                  <p style={{color: "red"}}>
                                    {this.state.objectSet?.mpcPhase1Voltage == null? "Please Enter Phase 1 Voltage":""}
                                  </p>
                                  <InputNumber
                                    style={{ width: "200px" }}
                                    value={
                                      this.state.objectSet?.mpcPhase1Voltage
                                    }
                                    id="cabinetSerialNumber"
                                    onChange={this.handleMpcPhase1Voltage}
                                  />
                                  {" "}
                                </>
                              )}
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                              <div>
                                <i class="la la-hand-o-right text-primary me-2"></i>{" "}
                                Phase 2 Voltage (V){" "}
                                {this.state.switchStatus ? (
                                  <></>
                                ) : (
                                  <span style={{ color: "red" }}> *</span>
                                )}
                              </div>

                              {this.state.switchStatus ? (
                                <>
                                  {this.props.details.access_technologies_data
                                    ?.mpcPhase2Voltage ? (
                                    <span class="badge badge-outline-success">
                                      {
                                        this.props.details
                                          .access_technologies_data
                                          .mpcPhase2Voltage
                                      }
                                    </span>
                                  ) : (
                                    <span class="badge badge-outline-warning">
                                      {
                                        this.props.details
                                          .access_technologies_data
                                          ?.mpcPhase2Voltage
                                      }
                                    </span>
                                  )}
                                </>
                              ) : (
                                                            <>
                                                                <p style={{ color: "red" }}>
                                                                    {this.state.objectSet?.mpcPhase2Voltage == null ? "Please Enter Phase 2 Voltage" : ""}
                                                                </p>
                                                                <InputNumber
                                                                    style={{ width: "200px" }}
                                                                    value={
                                                                        this.state.objectSet?.mpcPhase2Voltage
                                                                    }
                                                                    id="cabinetSerialNumber"
                                                                    onChange={this.handleMpcPhase2Voltage}
                                                                />
                                                            </>
                              )}
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                              <div>
                                <i class="la la-hand-o-right text-primary me-2"></i>{" "}
                                Phase 3 Voltage (V){" "}
                                {this.state.switchStatus ? (
                                  <></>
                                ) : (
                                  <span style={{ color: "red" }}> *</span>
                                )}
                              </div>
                              {this.state.switchStatus ? (
                                <>
                                  {" "}
                                  {this.props.details.access_technologies_data
                                    ?.mpcPhase3Voltage ? (
                                    <span class="badge badge-outline-success">
                                      {
                                        this.props.details
                                          .access_technologies_data
                                          .mpcPhase3Voltage
                                      }
                                    </span>
                                  ) : (
                                    <span class="badge badge-outline-warning">
                                      {
                                        this.props.details
                                          .access_technologies_data
                                          ?.mpcPhase3Voltage
                                      }
                                    </span>
                                  )}
                                </>
                              ) : (
                                <>
                                  {" "}
                                  <p style={{color: "red"}}>
                                    {this.state.objectSet?.mpcPhase3Voltage == null? "Please Enter Phase 3 Voltage": ""}
                                    </p>
                                  <InputNumber
                                    style={{ width: "200px" }}
                                    value={
                                      this.state.objectSet?.mpcPhase3Voltage
                                    }
                                    id="cabinetSerialNumber"
                                    onChange={this.handleMpcPhase3Voltage}
                                  />{" "}
                                </>
                              )}
                            </li>
                          </ul>
                        </div>

                        <div className="col-md-6">
                          <ul class="list-group">
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                              <div>
                                <i class="la la-hand-o-right text-primary me-2"></i>{" "}
                                CEB/LECO Depot
                              </div>
                              {this.state.switchStatus ? (
                                <>
                                  {this.props?.details.access_technologies_data
                                    ?.sooCEBLECODepot != null ? (
                                    <span class="badge badge-outline-success">
                                      {
                                        this.props?.details
                                          .access_technologies_data
                                          ?.sooCEBLECODepot
                                      }
                                    </span>
                                  ) : (
                                    <span class="badge badge-outline-warning">
                                      Currently Not Set
                                    </span>
                                  )}
                                </>
                              ) : (
                                <>
                                  {" "}
                                  <Input
                                    style={{ width: "200px" }}
                                    value={
                                      this.state.sopdataSet?.sooCEBLECODepot
                                    }
                                    id="airCoolingMethod"
                                    onChange={this.handlsooCEBLECODepot}
                                  />{" "}
                                </>
                              )}
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                              <div>
                                <i class="la la-hand-o-right text-primary me-2"></i>{" "}
                                Power Connected Date
                              </div>
                              {this.state.switchStatus ? (
                                <>
                                  {" "}
                                  {this.props?.details.access_technologies_data
                                    ?.powerConnectedDate != null ? (
                                    <span class="badge badge-outline-success">
                                      {
                                        this.props?.details
                                          .access_technologies_data
                                          ?.powerConnectedDate
                                      }
                                    </span>
                                  ) : (
                                    <span class="badge badge-outline-warning">
                                      Currently Not Set
                                    </span>
                                  )}
                                </>
                              ) : (
                                <>
                                  {" "}
                                  <DatePicker
                                    style={{ width: "200px" }}
                                    defaultValue={
                                      this.state.sopdataSet
                                        ?.powerConnectedDate != null
                                        ? moment(
                                            this.state.sopdataSet
                                              ?.powerConnectedDate
                                          )
                                        : ""
                                    }
                                    onChange={this.onChangepowerConnectedDate}
                                  />{" "}
                                </>
                              )}
                            </li>

                            <li class="list-group-item d-flex justify-content-between align-items-center">
                              <div>
                                <i class="la la-hand-o-right text-primary me-2"></i>{" "}
                                Phase 1 Current (A){" "}
                                {this.state.switchStatus ? (
                                  <></>
                                ) : (
                                  <span style={{ color: "red" }}> *</span>
                                )}
                              </div>
                              {this.state.switchStatus ? (
                                <>
                                  {" "}
                                  {this.props.details.access_technologies_data
                                    ?.mpcPhase1Current ? (
                                    <span class="badge badge-outline-success">
                                      {
                                        this.props.details
                                          .access_technologies_data
                                          .mpcPhase1Current
                                      }
                                    </span>
                                  ) : (
                                    <span class="badge badge-outline-warning">
                                      {
                                        this.props.details
                                          .access_technologies_data
                                          ?.mpcPhase1Current
                                      }
                                    </span>
                                  )}
                                </>
                              ) : (
                                <>
                                  {" "}
                                  <p style={{color: "red"}}>
                                    {this.state.objectSet?.mpcPhase1Current == null? "Please Enter Phase 1 Current": ""}
                                    </p>
                                  <InputNumber
                                    style={{ width: "200px" }}
                                    value={
                                      this.state.objectSet?.mpcPhase1Current
                                    }
                                    id="cabinetSerialNumber"
                                    onChange={this.handleMpcPhase1Current}
                                  />
                                  {" "}
                                </>
                              )}
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                              <div>
                                <i class="la la-hand-o-right text-primary me-2"></i>{" "}
                                Phase 2 Current (A){" "}
                                {this.state.switchStatus ? (
                                  <></>
                                ) : (
                                  <span style={{ color: "red" }}> *</span>
                                )}
                              </div>

                              {this.state.switchStatus ? (
                                <>
                                  {" "}
                                  {this.props.details.access_technologies_data
                                    ?.mpcPhase2Current ? (
                                    <span class="badge badge-outline-success">
                                      {
                                        this.props.details
                                          .access_technologies_data
                                          .mpcPhase2Current
                                      }
                                    </span>
                                  ) : (
                                    <span class="badge badge-outline-warning">
                                      {
                                        this.props.details
                                          .access_technologies_data
                                          ?.mpcPhase2Current
                                      }
                                    </span>
                                  )}
                                </>
                              ) : (
                                <>
                                  {" "}
                                  <p style={{color: "red"}}>
                                    {this.state.objectSet?.mpcPhase2Current == null? "Please Enter Phase 2 Current": ""}
                                    </p>
                                  <InputNumber
                                    style={{ width: "200px" }}
                                    value={
                                      this.state.objectSet?.mpcPhase2Current
                                    }
                                    id="cabinetSerialNumber"
                                    onChange={this.handleMpcPhase2Current}
                                  />
                                  {" "}
                                </>
                              )}
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                              <div>
                                <i class="la la-hand-o-right text-primary me-2"></i>{" "}
                                Phase 3 Current (A){" "}
                                {this.state.switchStatus ? (
                                  <></>
                                ) : (
                                  <span style={{ color: "red" }}> *</span>
                                )}
                              </div>

                              {this.state.switchStatus ? (
                                <>
                                  {" "}
                                  {this.props.details.access_technologies_data
                                    ?.mpcPhase3Current ? (
                                    <span class="badge badge-outline-success">
                                      {
                                        this.props.details
                                          .access_technologies_data
                                          .mpcPhase3Current
                                      }
                                    </span>
                                  ) : (
                                    <span class="badge badge-outline-warning">
                                      {
                                        this.props.details
                                          .access_technologies_data
                                          ?.mpcPhase3Current
                                      }
                                    </span>
                                  )}
                                </>
                              ) : (
                                <>
                                  {" "}
                                  <p style={{color: "red"}}>
                                    {this.state.objectSet?.mpcPhase3Current == null? "Please Enter Phase 3 Current": ""}
                                    </p>
                                  <InputNumber
                                    style={{ width: "200px" }}
                                    value={
                                      this.state.objectSet?.mpcPhase3Current
                                    }
                                    id="cabinetSerialNumber"
                                    onChange={this.handleMpcPhase3Current}
                                  />
                                  {" "}
                                </>
                              )}
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                              <div>
                                <i class="la la-hand-o-right text-primary me-2"></i>{" "}
                                Separate Feeder Availablity
                              </div>
                              <div>
                                {this.state.switchStatus.toString() ==
                                "true" ? (
                                  this.props.details.access_technologies_data
                                    ?.mpcSeparateFeederAvailablity != null ||
                                  this.props.details.access_technologies_data
                                    ?.mpcSeparateFeederAvailablity !=
                                    undefined ? (
                                    <span className="badge badge-outline-success">
                                      {this.props.details.access_technologies_data.mpcSeparateFeederAvailablity.toString()}
                                    </span>
                                  ) : (
                                    <span className="badge badge-outline-warning">
                                      Not Defined Yet
                                    </span>
                                  )
                                ) : (
                                  <Select
                                    defaultValue={
                                      this.props.details
                                        .access_technologies_data
                                        ?.mpcSeparateFeederAvailablity
                                    }
                                    style={{ width: "200px" }}
                                    onChange={(e) => {
                                      this.state.objectSet.mpcSeparateFeederAvailablity =
                                        e;
                                    }}
                                  >
                                    {this.state.dropdownDataSet.mpcSeparateFeederAvailablity.map(
                                      (x, y) => {
                                        return <Option value={x}>{x}</Option>;
                                      }
                                    )}
                                  </Select>
                                )}
                              </div>
                            </li>
                            {/* <li class="list-group-item d-flex justify-content-between align-items-center">
                              <div>
                                <i class="la la-hand-o-right text-primary me-2"></i>{" "}
                                Average Availability Per Day(Hours)
                              </div>
                              {this.state.switchStatus ? (
                                <>
                                  {" "}
                                  {this.props.details.access_technologies_data
                                    ?.mpcAverageAvailabilityPerday ? (
                                    <span class="badge badge-outline-success">
                                      {
                                        this.props.details
                                          .access_technologies_data
                                          .mpcAverageAvailabilityPerday
                                      }
                                    </span>
                                  ) : (
                                    <span class="badge badge-outline-warning">
                                      {
                                        this.props.details
                                          .access_technologies_data
                                          ?.mpcAverageAvailabilityPerday
                                      }
                                    </span>
                                  )}
                                </>
                              ) : (
                                <>
                                  {" "}
                                  <InputNumber
                                    style={{ width: "200px" }}
                                    value={
                                      this.state.objectSet
                                        ?.mpcAverageAvailabilityPerday
                                    }
                                    id="cabinetSerialNumber"
                                    onChange={
                                      this.handleMpcAverageAvailabilityPerday
                                    }
                                  />{" "}
                                </>
                              )}
                            </li> */}
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                              <div>
                                <i class="la la-hand-o-right text-primary me-2"></i>{" "}
                                  CEB/LECO Account Number
                              </div>
                              {this.state.switchStatus ? (
                                <>
                                  {" "}
                                  {this.props.details.access_technologies_data
                                    ?.mpcCebLecoAccountNumber ? (
                                    <span class="badge badge-outline-success">
                                      {
                                        this.props.details
                                          .access_technologies_data
                                          .mpcCebLecoAccountNumber
                                      }
                                    </span>
                                  ) : (
                                    <span class="badge badge-outline-warning">
                                      {
                                        this.props.details
                                          .access_technologies_data
                                          ?.mpcCebLecoAccountNumber
                                      }
                                    </span>
                                  )}
                                </>
                              ) : (
                                <>
                                  {" "}
                                  <Input
                                    style={{ width: "200px" }}
                                    value={
                                      this.state.objectSet
                                        ?.mpcCebLecoAccountNumber
                                    }
                                    id="cabinetSerialNumber"
                                    onChange={
                                      this.handleMpcAverageAvailabilityPerday
                                    }
                                  />{" "}
                                </>
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                style={{
                  height: "120px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h4 style={{ color: "#c5c5c5" }}>
                  No Dat Found Access Technologies
                </h4>
              </div>
            )}
          </>
        );
    }
}
export default MainPowerConnection;

import React, { Component } from 'react'
import ReactApexChart from 'react-apexcharts'

 class DradientDonutChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
          
            series: [],
            options: {
              chart: {
                width: 380,
                type: 'donut',
                dropShadow: {
                  enabled: true,
                  color: '#111',
                  top: -1,
                  left: 3,
                  blur: 3,
                  opacity: 0.2
                }
              },
              stroke: {
                width: 0,
              },
              plotOptions: {
                pie: {
                  donut: {
                    labels: {
                      show: false,
                      total: {
                        showAlways: false,
                        show: false
                      }
                    }
                  }
                }
              },
              legend: {
                show: true,
                position: 'bottom',
            },
              labels: [],
              dataLabels: {
                dropShadow: {
                  blur: 3,
                  opacity: 0.8
                }
              },
              fill: {
              type: 'pattern',
                opacity: 1,
                pattern: {
                  enabled: true,
                  style: ['verticalLines', 'squares', 'horizontalLines', 'circles','slantedLines'],
                },
              },
              states: {
                hover: {
                  filter: 'none'
                }
              },
              theme: {
                palette: 'palette2'
              },
              title: {
                text: "Site Access"
              },
              responsive: [{
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  },
               
                }
              }]
            },
          
          
          };
    }
    componentDidMount() {
        this.setState({
            series:this.props.siteAccessSeries.siteAccessSeriesCount,
            options: {
              chart: {
                width: 380,
                type: 'donut',
                dropShadow: {
                  enabled: true,
                  color: '#111',
                  top: -1,
                  left: 3,
                  blur: 3,
                  opacity: 0.2
                }
              },
              stroke: {
                width: 0,
              },
              plotOptions: {
                pie: {
                  donut: {
                    labels: {
                      show: false,
                      total: {
                        showAlways: false,
                        show: false
                      }
                    }
                  }
                }
              },
              legend: {
                show: true,
                position: 'bottom',
            },
              labels:this.props.siteAccessLable.siteAccessSerieslabels,
              dataLabels: {
                dropShadow: {
                  blur: 3,
                  opacity: 0.8
                }
              },
              fill: {
              type: 'pattern',
                opacity: 1,
                pattern: {
                  enabled: true,
                  style: ['verticalLines', 'squares', 'horizontalLines', 'circles','slantedLines'],
                },
              },
              states: {
                hover: {
                  filter: 'none'
                }
              },
              theme: {
                palette: 'palette2'
              },
              title: {
                text: "Site Access"
              },
              responsive: [{
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  },
               
                }
              }]
            },
          
        })

    }
    
  render() {
    return (
        <div id="chart">
        <ReactApexChart options={this.state.options} series={this.state.series} type="donut" height={350} />
    </div>
    )
  }
}
export default DradientDonutChart;
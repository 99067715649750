import React, { Component } from 'react';
import { Select, DatePicker, Space, Row, Col } from "antd";
import moment from 'moment';
import SpeedMeterChat from '../../components/charts/speed-meter';
import StackedBarLine from '../../components/charts/stacked-bar-line';
import StackedBarChat from '../../components/charts/stacked-bar';
import LineCharts from '../../components/charts/line-charts';
import SemiCircleGaugeChat from '../../components/charts/semi-circle-gauge';
import { Badge } from 'antd';
import { getDonationCategories, getData } from "../../actions/index";
import { connect } from "react-redux";
import { LoginOutlined, PlusCircleOutlined, FilterOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
  RefuelStatusTypesCount,
  RefuelChartRequest,
  getRefuelDashboardCharts,
  getRefuelRegionViseCount,

} from "../../api/index";
const { RangePicker } = DatePicker;


class DashboardRefuel extends Component {
  constructor(props) {
    super(props);
    const currentDate_landing = new Date();
    this.state = {
      appVersionCount: [25, 24, 53, 63],
      appVersionlabels: ['Excellent', 'Very Good', 'Good', 'Fair'],
      daily_status: false,
      monthly_status: false,
      weekly_status: false,
      SubRegion_list: [],
      depot_list: [],
      request_initiation: 0,
      fuel_procurement: 0,
      fuel_filled: 0,
      request_completed: 0,
      filter_date: currentDate_landing.getFullYear(),
      //filter_date_to: moment().format("YYYY-MM-DD"),
      // filter date to end of month
      filter_date_to: currentDate_landing.getMonth() + 1,
      filter_depot: [],
      filter_region: [],
      setAudoMeterShow: false,
      filter_sub_region: [],
      pg_by_count_chart_data: [
        // {
        //   "type": "2022-02-16",
        //   "count": 0
        // },
        // {
        //   "type": "2022-02-17",
        //   "count": 0
        // },
        // {
        //   "type": "2022-02-18",
        //   "count": 0
        // },
        // {
        //   "type": "2022-02-19",
        //   "count": 0
        // },
        // {
        //   "type": "2022-02-20",
        //   "count": 0
        // },
        // {
        //   "type": "2022-02-21",
        //   "count": 0
        // },
        // {
        //   "type": "2022-02-22",
        //   "count": 0
        // },
        {
          "type": "2022-02-23",
          "count": 3
        },
        {
          "type": "2022-02-24",
          "count": 6
        },
        {
          "type": "2022-02-25",
          "count": 4
        },
        {
          "type": "2022-02-26",
          "count": 5
        },
        {
          "type": "2022-02-27",
          "count": 6
        },
        {
          "type": "2022-02-28",
          "count": 6
        },
        {
          "type": "2022-03-01",
          "count": 7
        },
        {
          "type": "2022-03-02",
          "count": 9
        },
        {
          "type": "2022-03-03",
          "count": 5
        }
      ],
      all_cost_data: [],
      daily_req_data: [],
      requesteTrendChart: false,
      requesteTrendChartSetY1: null,
      requesteTrendChartSetX1: null,
      fillingStatus: false,
      fillingStatusSetY1: null,
      fillingStatusSetX1: null,
      fuelQuantity: false,
      fuelQuantitySetY1: null,
      fuelQuantitySetX1: null,
      fuelQuantityMom: false,
      fuelQuantityMomSetY1: null,
      fuelQuantityMomSetX1: null,
      requestByRegion: false,
      requestByRegionSetY1: null,
      requestByRegionSetX1: null,
      installationStatusChartData: null,
      isRegionViseCountList: false,
      regionViseCountList: [],

    };
  }
  componentDidMount() {
    this.setState({
      depot_list: this.props.depots,
      SubRegion_list: this.props.regions.filter((item) => {
        return item.isSubRegion == 1;
      }),
    });
    var form = new FormData();

    form.append("depotId", '');
    form.append("regionId", '');
    form.append("parentRegionId", '');
    form.append("year", this.state.filter_date);
    form.append("month", this.state.filter_date_to);
    this.getRefuelStatusTypesCount(form);
    this.getRefuelChartRequest(form);

    this.getDataRefuelDashboardCharts(form);
    this.getRefuelRegionViseCount(form);
  }

  getRefuelRegionViseCount = async (data) => {
    this.setState({
      isRegionViseCountList: false,
      setAudoMeterShow: true
    })
    var res = await getRefuelRegionViseCount(data);
    res.data.map(e => {
      e.trend = e.totalCount / e.targetCount
    })
    this.setState({
      regionViseCountList: res.data
    }, () => {
      this.setState({
        isRegionViseCountList: true
      })
    })
  }

  getLastDayOfMonth(inputDate) {
    const [year, month] = inputDate.split('-');
    const nextMonth = new Date(year, month);
    nextMonth.setDate(0);
    const lastDay = nextMonth.getDate();
    return `${year}-${month}-${lastDay.toString().padStart(2, '0')}`;
  }

  setRequestTrendChartData(data) {

    if (data != null) {
      this.setState({
        requesteTrendChart: false,
        requesteTrendChartSetX1: null,
        requesteTrendChartSetY1: null
      })
      let set_year = this.state.filter_date;
      let set_month = this.state.filter_date_to;
      let set_data = set_year + '-' + set_month
      const inputDate = set_data;
      const lastDayOfMonth = this.getLastDayOfMonth(inputDate);
      const today = new Date(lastDayOfMonth);
      const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
      const formattedFirstDay = firstDayOfMonth.toISOString().split('T')[0];
      const filteredData = data
        .filter(entry => entry.date >= formattedFirstDay && entry.date <= today.toISOString().split('T')[0])
        .sort((a, b) => Date.parse(a.date) - Date.parse(b.date));

      const countSet = filteredData.map(entry => entry.count);
      const dateSet = filteredData.map(entry => entry.date.slice(5));

      this.setState({
        requesteTrendChartSetY1: [{
          name: "Count",
          data: countSet
        }],
        requesteTrendChartSetX1: dateSet,
      }, () => {
        this.setState({
          requesteTrendChart: true
        })
      })

    }

  }

  stripYearFromDates(dates) {
    return dates.map(date => date.slice(12));
  }

  setInstallationStatusChartData(data) {
    if (data != null) {
      this.setState({
        requestByRegion: false
      })
      let set_year = this.state.filter_date;
      let set_month = this.state.filter_date_to;
      let set_data = set_year + '-' + set_month
      const inputDate = set_data;
      const lastDayOfMonth = this.getLastDayOfMonth(inputDate);

      const today = new Date(lastDayOfMonth);

      const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
      const formattedFirstDay = firstDayOfMonth.toISOString().split('T')[0];

      const filteredData = data
        .filter(entry => entry.date >= formattedFirstDay && entry.date <= today.toISOString().split('T')[0])
        .sort((a, b) => Date.parse(a.date) - Date.parse(b.date));

      const canceledSet = filteredData?.map(entry => entry?.canceled);
      const filling_pending = filteredData?.map(entry => entry?.filling_pending);
      const approval_pending = filteredData?.map(entry => entry?.approval_pending);
      const filledSet = filteredData.map(entry => entry.filled);
      const dateSet = filteredData.map(entry => entry.date.slice(5));

      this.setState({
        requestByRegionSetY1: [{
          name: 'Cancelled',
          type: 'column',
          data: canceledSet
        }, 
        {
          name: 'Approval Pending',
          type: 'column',
          data: approval_pending
        },
        {
          name: 'Filling Pending',
          type: 'column',
          data: filling_pending
        },
        {
          name: 'Filled',
          type: 'column',
          data: filledSet
        }],
        requestByRegionSetX1: dateSet,
      }, () => {
        this.setState({
          requestByRegion: true
        })
      })
    }
  }
  setFillingStatusChartData(data) {
    if (data != null) {

      this.setState({
        fillingStatus: false
      })

      let canceledSet = data.map(a => a?.canceled);
      let pendingSet = data.map(a => a?.approval_pending);
      let filling_pending = data.map(a => a?.filling_pending);
      let filledSet = data.map(a => a?.filled);
      let regionSet = data.map(a => a?.region);

      this.setState({
        fillingStatusSetY1: [{
          name: 'Cancelled',
          type: 'column',
          data: canceledSet
        }, 
        {
          name: 'Approval Pending',
          type: 'column',
          data: pendingSet
        },
        {
          name: 'Filling Pending',
          type: 'column',
          data: filling_pending
        },
        {
          name: 'Filled',
          type: 'column',
          data: filledSet
        },],
        fillingStatusSetX1: regionSet,
      }, () => {
        this.setState({
          fillingStatus: true
        })
      })
    }
  }

  
  setFuelQuantityChartData(data) {
    if (data != null) {
      this.setState({
        fuelQuantity: false
      })
      let dateSet = data.map(a => a.date);
      let CRSet = data.map(a => a.CR);
      let ERSet = data.map(a => a.ER);
      let NRSet = data.map(a => a.NR);
      let SRSet = data.map(a => a.SR);
      let WRSet = data.map(a => a.WR);
      let csSet = data.map(a => a.cs);
      this.setState({
        fuelQuantitySetY1: [
          {
            label: 'Cumulative Value',
            yAxisID: 'y2',
            type: 'line',
            data: csSet,
            borderWidth: 2,
            backgroundColor: 'rgb(53, 162, 235)',
            borderColor: 'rgb(53, 162, 200)',
          },
          {
            label: 'CR',
            yAxisID: 'y',
            data: CRSet,
            backgroundColor: 'rgb(255, 99, 132)',
          },
          {
            label: 'ER',
            yAxisID: 'y',
            data: ERSet,
            backgroundColor: 'rgb(255,165,0)',
          },
          {
            label: 'NR',
            yAxisID: 'y',
            data: NRSet,
            backgroundColor: 'rgb(0,250,154)',
          },
          {
            label: 'SR',
            yAxisID: 'y',
            data: SRSet,
            backgroundColor: 'rgb(0,191,255)',
          },
          {
            label: 'WR',
            yAxisID: 'y',
            data: WRSet,
            backgroundColor: 'rgb(139,0,139)',
          },

        ],
        fuelQuantitySetX1: dateSet,
      }, () => {
        this.setState({
          fuelQuantity: true
        })
      })
    }

  }
  setFuelQuantityMOMChartData(data) {
    this.state.fuelQuantityMomSetY1 = null;
    this.state.fuelQuantityMomSetX1 = null;
    this.state.fuelQuantityMom = false;
    if (data != null) {
      this.setState({
        fuelQuantityMom: false
      })
      data.reverse();
      let monthSet = data.map(a => a.month?.slice(0, 3));
      let countSet = data.map(a => a.count);
      this.setState({
        fuelQuantityMomSetY1: [{
          name: "Count",
          data: countSet
        }],
        fuelQuantityMomSetX1: monthSet,
      }, () => {
        this.setState({
          fuelQuantityMom: true
        })
      })
    }
  }
getDataRefuelDashboardCharts = async (data) => {
    this.setState({
        installationStatusChartData: null,
        requestTrendChartData: null,
        fillingStatusChartData: null,
        fuelQuantityChartData: null,
        fuelQuantityMOMChartData: null
    });

    var res = await getRefuelDashboardCharts(data);
    console.log(res);
    if (res.success) {
        this.setState({
            installationStatusChartData: res.data?.chart2FillingStatus,
            requestTrendChartData: res.data?.requestTrend,
            fillingStatusChartData: res.data?.regionalBreakdown,
            fuelQuantityChartData: res.data?.fuelQuantity,
            fuelQuantityMOMChartData: res.data?.fuelQuantityMom,
        }, () => {
            this.setInstallationStatusChartData(this.state.installationStatusChartData)
            this.setRequestTrendChartData(this.state.requestTrendChartData)
            this.setFillingStatusChartData(this.state.fillingStatusChartData)
            this.setFuelQuantityChartData(this.state.fuelQuantityChartData)
            this.setFuelQuantityMOMChartData(this.state.fuelQuantityMOMChartData)
        })
    }
}

  getRefuelChartRequest = async (data) => {

    //console.log("sdsdsdrrrrs")
    //console.log(data)

    var res = await RefuelChartRequest(data);
    //console.log("RefuelChartRequest");
    //console.log(res);

    if (res.success) {
      console.log(res);


      if (!res.data?.daily?.length == 0) {

        this.setState({
          daily_status: true,
          weekly_status: false,
          monthly_status: false,
          get_cost_by_parent_region_loading: false,
          all_cost_data: res?.data,
          daily_req_data: res?.data?.daily,




        });

      }
      if (!res.data?.weekly?.length == 0) {

        this.setState({
          daily_status: false,
          weekly_status: true,
          monthly_status: false,
          get_cost_by_parent_region_loading: false,
          all_cost_data: res?.data,
          daily_req_data: res?.data?.weekly,




        });

      }
      if (!res.data?.monthly?.length == 0) {
        this.setState({
          daily_status: false,
          weekly_status: false,
          monthly_status: true,
          get_cost_by_parent_region_loading: false,
          all_cost_data: res?.data,
          daily_req_data: res?.data?.monthly,
        });
      }

      // this.setState({
      //   request_initiation: res.data.filter(alldate => "request_initiation".includes(alldate.statusType))[0].count,
      //   fuel_procurement: res.data.filter(alldate => "fuel_procurement".includes(alldate.statusType))[0].count,
      //   fuel_filled: res.data.filter(alldate => "fuel_illed".includes(alldate.statusType))[0].count,
      //   request_completed: res.data.filter(alldate => "request_completed".includes(alldate.statusType))[0].count,
      // });
    }
  };

  getRefuelStatusTypesCount = async (data) => {
    var res = await RefuelStatusTypesCount(data);
    if (res.success) {
      this.setState({
        request_initiation: res.data[0]["filling_pending"] || 0,
        fuel_procurement: res.data[0]["approval_pending"] || 0,
        fuel_filled: res.data[0]["filled"] || 0,
        request_completed: res.data[0]["cancel"] || 0,
      });
    }
  };
  
  dataonChange = (date, dateString) => {
    const [yearValue, monthValue] = dateString.split('-');
    console.log(monthValue)
    console.log(yearValue)
    this.setState({
      filter_date: yearValue,
      filter_date_to: monthValue,
    }, () => {

      var form = new FormData();
      form.append('year', this.state.filter_date);
      form.append('month', this.state.filter_date_to);
      form.append("depotId", this.state.filter_depot);
      form.append("regionId", this.state.filter_sub_region);
      form.append("parentRegionId", this.state.filter_region);


      this.getRefuelStatusTypesCount(form);
      this.getRefuelChartRequest(form);
      this.getDataRefuelDashboardCharts(form);
      this.getRefuelRegionViseCount(form);
    });


  };
  onChange = (value) => {
    this.setState({
      depot_list: [],
      filter_depot: [],
      filter_sub_region: [],

    });
    //console.log(`selected rr${value}`);
    this.setState({
      filter_region: value,
      selectedDepot: "all_depots",
      selected_region_id: value,
      SubRegion_list: this.props.regions.filter(alldate => value.map((n) => n).includes(alldate.parentRegion?.id)),
      depot_list: this.props.depots.filter(alldate => value.map((n) => n).includes(alldate.region?.parentRegion?.id))

    });
    var data = {
      id: value,
      type: "region",
    };
    //this.getPgCountData(data);
    //this.getPgStatusData(data);
    //this.getPgCostData(data);
    //this.getPgSLAData(data);
    if (this.state.filter_sub_region == "") {
      var getall = this.props.regions.filter(alldate => value.includes(alldate.parentRegion?.id))
      var output = [];
      for (var i = 0; i < getall.length; ++i)
        output.push(getall[i]['id']);

      //sthis.state.filterSubRegion=output;
      var form = new FormData();
      //console.log("sdsrrrrrr")
      //console.log(output)
      form.append("depotId", this.state.filter_depot);
      form.append("regionId", this.state.filter_sub_region)
      form.append("parentRegionId", value);
      form.append("year", this.state.filter_date);
      form.append("month", this.state.filter_date_to);

      this.getRefuelStatusTypesCount(form);
      this.getRefuelChartRequest(form)
    }
  };

  stripYearFromDates(dates) {
    return dates.map(date => date.slice(3));
  }

  onsubChange = (value) => {
    this.setState({
      depot_list: [],
      filter_depot: [],
    });
    //console.log(`selected rr${value}`);
    this.setState({
      selectedDepot: "all_depots",
      selected_region_id: value,
      filter_sub_region: value,
      depot_list: this.props.depots.filter(alldate => value.map((n) => n).includes(alldate.region?.id))
    });
    var data = {
      id: value,
      type: "region",
    };
    // this.getPgCountData(data);
    // this.getPgStatusData(data);
    // this.getPgCostData(data);
    // this.getPgSLAData(data);
    var form = new FormData();
    //console.log("sdsrrrrrr")
    ////console.log(output)
    form.append("depotId", this.state.filter_depot);
    form.append("regionId", value)
    form.append("parentRegionId", this.state.filter_region);
    form.append("year", this.state.filter_date);
    form.append("month", this.state.filter_date_to);

    this.getRefuelStatusTypesCount(form);
    this.getRefuelChartRequest(form);

  };
  onChange2 = (value) => {
    //console.log(`selected depot ${value}`);
    this.setState({
      selectedDepot: value,
      filter_depot: value,
    });
    var data = {
      id: value,
      type: "depot",
    };
    var form = new FormData();
    //console.log("sdsrrrrrr")
    ////console.log(output)
    form.append("depotId", value);
    form.append("regionId", this.state.filter_sub_region)
    form.append("parentRegionId", this.state.filter_region);
    form.append("year", this.state.filter_date);
    form.append("month", this.state.filter_date_to);
    this.getRefuelStatusTypesCount(form);
    this.getRefuelChartRequest(form);


    // this.getPgCountData(data);
    // this.getPgStatusData(data);
    // this.getPgCostData(data);
    // this.getPgSLAData(data);
  }
  handleClickDaily = () => {
    //console.log(this.state);
    if (!this.state.all_cost_data?.daily?.length == 0) {
      this.setState({
        daily_req_data: this.state.all_cost_data?.daily,
        // pg_cost_count: this.state?.pg_cost_daily_weekly_monthly_count[0]?.cost,
        //pg_cost_count: 18756,
        //sla_status: this.state?.sla_status_daily_weekly_monthly?.daily,
        //regoin_cost: this.state?.daily_regoin_cost,
        //pg_count: this.state?.pg_count_daily_weekly_monthly.sevenDays,
        daily_status: true,
        weekly_status: false,
        monthly_status: false,
        // select_filer_type: 'Last 7 Days'
      });
    } else {
      alert("The time range is not applicable.")
    }
  }
  handleClickWeekly = () => {
    //console.log(this.state.day_week_month_filter_data_set)
    if (!this.state.all_cost_data?.weekly?.length == 0) {
      this.setState({
        daily_req_data: this.state.all_cost_data?.weekly,
        // pg_cost_count: this.state?.pg_cost_daily_weekly_monthly_count[1]?.cost,
        // pg_cost_count: 45869,
        //sla_status: this.state?.sla_status_daily_weekly_monthly?.weekly,
        //regoin_cost: this.state?.weekly_regoin_cost,
        //pg_count: this.state?.pg_count_daily_weekly_monthly.sevenWeeks,

        daily_status: false,
        weekly_status: true,
        monthly_status: false,
        select_filer_type: 'Last 7 weeks'

      });
    } else {
      alert("The time range is not applicable.")
    }
  }
  handleClickMonthly = () => {
    if (!this.state.all_cost_data?.monthly?.length == 0) {

      //console.log("gttttt");
      //console.log(this.state.day_week_month_filter_data_set_monthly);

      this.setState({
        daily_req_data: this.state.all_cost_data?.monthly,
        // pg_cost_count: this.state?.pg_cost_daily_weekly_monthly_count[2]?.cost,
        // pg_cost_count: 784512,
        // sla_status: this.state?.sla_status_daily_weekly_monthly?.monthly,
        // regoin_cost: this.state?.monthly_regoin_cost,
        // pg_count: this.state?.pg_count_daily_weekly_monthly.sevenMonths,

        monthly_status: true,
        daily_status: false,
        weekly_status: false,
        select_filer_type: 'Last 7 Month'


      });
    } else {
      alert("The time range is not applicable.")
      //console.log("sdsdsooooo")

    }

  }
  render() {

    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long' });

    const { Option } = Select;
    function onBlur() {
      //console.log("blur");
    }

    function onFocus() {
      //console.log("focus");
    }

    function onSearch(val) {
      //console.log("search:", val);
    }
    const config2 = {
      data: this.state.daily_req_data,
      isGroup: true,
      xField: 'key',
      yField: 'value',
      seriesField: 'type',
      height: 283,
      label: {
        // 可手动配置 label 数据标签位置
        position: 'middle',
        // 'top', 'bottom', 'middle'
        // 可配置附加的布局方法
        layout: [
          // 柱形图数据标签位置自动调整
          {
            type: 'interval-adjust-position',
          }, // 数据标签防遮挡
          {
            type: 'interval-hide-overlap',
          }, // 数据标签文颜色自动调整
          {
            type: 'adjust-color',
          },
        ],
      },
    };
    const config = {
      data: this.state.pg_by_count_chart_data,
      xField: 'type',
      yField: 'count',

      height: 283,
      label: {},
      point: {
        size: 5,
        shape: 'diamond',
        style: {
          fill: 'white',
          stroke: '#5B8FF9',
          lineWidth: 2,
          height: 280

        },
      },
      tooltip: {
        showMarkers: false,
      },
      state: {
        active: {
          style: {
            shadowBlur: 4,
            stroke: '#000',
            fill: 'red',
          },
        },
      },
      interactions: [
        {
          type: 'marker-active',
        },
      ],
    };
    const { appVersionCount } = this.state
    const { appVersionlabels } = this.state

    const getPresentage = (value, total) => {
      console.log(value / total * 100);
      return (value / total) * 100;
    }

    return (

      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <div class="page-title-box">
              <div class="row">
                <div class="col">
                  <h4 class="page-title">Dashboard Refuel</h4>
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0);">Dashboard</a></li>
                    <li class="breadcrumb-item"><a href="javascript:void(0);">Refuel</a></li>

                  </ol>
                </div>
                <div class="col-auto align-self-center">
                  <a href="#" class="btn btn-sm btn-outline-primary" id="Dash_Date">
                    <span class="day-name" id="Day_Name">Today:</span>&nbsp;
                    <span class="" id="Select_date">{new Date().toUTCString().slice(5, 16)}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar align-self-center icon-xs ms-1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                  </a>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12" style={{
            background: '#fafafa', padding: '20px', borderRadius: '10px'
          }}>
            <div className="row">
              <div className="col-md-2 col-lg-3">
                <DatePicker
                  picker="month"
                  className='customDropdown textStyles-small'
                  style={{ width: "100%", borderRadius: "5px" }}
                  allowClear={false}
                  defaultValue={moment(moment().format("YYYY-MM-DD").slice(0, -2) + '01')}
                  size='medium'
                  onChange={this.dataonChange}
                />
              </div>
            </div>
          </div>
        </div>

        <div style={{ background: 'white', borderRadius: '10px', padding: '20px' }}>
          <Row>
            <Col span={12}>
              <h5 style={{
                fontWeight: "bold", fontFamily: 'Roboto'
              }}>Dashboard Statistics </h5>
            </Col>

            <Col span={12}>
              <div style={{ float: 'right' }}>
                <Select
                  mode="multiple"
                  className="borderedSelect"
                  bordered={false}
                  showSearch
                  style={{ width: "250px", borderRadius: "5px" }}
                  placeholder="Select a region"
                  optionFilterProp="children"
                  onChange={this.onChange}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {this.props.regions.filter(allret => "0".includes(allret.isSubRegion)).map((item, index) => {
                    return <Option value={item.id}>{item.name}</Option>;
                  })}
                </Select>

                <Select
                  mode="multiple"
                  className="borderedSelect"
                  bordered={false}
                  showSearch
                  style={{ width: "250px", marginLeft: "5px" }}
                  placeholder="Select a Subregion"
                  optionFilterProp="children"
                  onChange={this.onsubChange}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {this.state.SubRegion_list.map((item, index) => {
                    return <Option value={item.id}>{item.name}</Option>;
                  })}
                </Select>
              </div>

            </Col>
          </Row>

          {/* this.setState({
        request_initiation: res.data[0]["filling_pending"] || 0,
        fuel_procurement: res.data[0]["approval_pending"] || 0,
        fuel_filled: res.data[0]["filled"] || 0,
        request_completed: res.data[0]["cancel"] || 0,
      }); */}

          <Row style={{ marginTop: '10px' }}>
            <div className="col-md-12 col-lg-12">
              <div class="row justify-content-center">

                <div class="col-md-2 col-lg-3" >
                  <div class="card report-card  bg-soft-primary" style={{ borderRadius: '20px' }}>
                    <div class="card-body">
                      <div class="row d-flex justify-content-center">
                        <div class="col">
                          <p class="mb-0 fw-semibold" style={{
                            fontSize: "14px"
                          }}>
                            <b>Filling Pending</b></p>
                          <h2 class="m-0" style={{ marginTop: '3px' }}>{this.state.request_initiation}</h2>
                        </div>
                        <div class="col-auto align-self-center">
                          <div style={{
                            background: "white", borderRadius: "50%", width: "40px", height: "40px", display: "grid",
                            alignItems: "center", justifyContent: "center", margin: "auto"
                          }} float="left" className="mt-1">
                            <LoginOutlined style={{ color: "#1761fd", fontSize: '20px' }} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-lg-3" >
                  <div class="card report-card bg-soft-success" style={{ borderRadius: '20px' }}>
                    <div class="card-body" >
                      <div class="row d-flex justify-content-center">
                        <div class="col">
                          <p class="mb-0 fw-semibold" style={{
                            fontSize: "14px"
                          }}><b>Approval Pending</b></p>
                          <h2 class="m-0" style={{ marginTop: '3px' }}>{this.state.fuel_procurement}</h2>
                        </div>
                        <div class="col-auto align-self-center">
                          <div style={{
                            background: "white", borderRadius: "50%", width: "40px", height: "40px", display: "grid",
                            alignItems: "center", justifyContent: "center", margin: "auto"
                          }} float="left" className="mt-1">
                            <PlusCircleOutlined style={{ color: "#99db7f", fontSize: '20px' }} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-lg-3" >
                  <div class="card report-card bg-soft-pink" style={{ borderRadius: '20px' }}>
                    <div class="card-body" >
                      <div class="row d-flex justify-content-center">
                        <div class="col">
                          <p class="mb-0 fw-semibold" style={{
                            fontSize: "14px"
                          }}><b>Filled</b></p>
                          <h2 class="m-0" style={{ marginTop: '3px' }}>{this.state.fuel_filled}</h2>
                        </div>
                        <div class="col-auto align-self-center">
                          <div style={{
                            background: "white", borderRadius: "50%", width: "40px", height: "40px", display: "grid",
                            alignItems: "center", justifyContent: "center", margin: "auto"
                          }} float="left" className="mt-1">
                            <FilterOutlined style={{ color: "#fd3ca3", fontSize: '20px' }} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div class="col-md-6 col-lg-3">
                  <div class="card report-card bg-soft-warning" style={{ borderRadius: '20px' }}>
                    <div class="card-body" >
                      <div class="row d-flex justify-content-center">
                        <div class="col">
                          <p class="mb-0 fw-semibold" style={{
                            fontSize: "14px"
                          }}><b>Cancelled</b></p>
                          <h2 class="m-0" style={{ marginTop: '3px' }}>{this.state.request_completed}</h2>
                        </div>
                        <div class="col-auto align-self-center">
                          <div style={{
                            background: "white", borderRadius: "50%", width: "40px", height: "40px", display: "grid",
                            alignItems: "center", justifyContent: "center", margin: "auto"
                          }} float="left" className="mt-1">
                            <CloseOutlined style={{ color: "#ffda7e", fontSize: '20px' }} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </Row>
        </div>

        <br></br>
        <div className='col-md-12'>
          <div className="card">
            <div className="card-header">

              <div className='row'>
                <div className="col-md-3">
                  <h4 className="card-title" style={{
                    fontWeight: "bold", fontFamily: 'Roboto'
                  }}>Fuel Filling Monthly</h4>
                </div>
                <div className="container" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <div className="col">
                    <span><Badge color="#2D5FF4" text="Value" style={{ marginLeft: "10px", fontFamily: 'Roboto', fontSize: '9px', fontWeight: 'bold' }} /></span>
                    <span style={{ marginLeft: "10px", fontFamily: 'Roboto', fontSize: '9px', fontWeight: 'bold' }}>
                      <Badge color="#42B02A" text="Target" /></span>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className='row'>

                {
                  this.state.isRegionViseCountList ? (
                    <div class="col-md-6 col-lg-3" style={{ textAlign: "center", width: "20%" }} >
                      <SemiCircleGaugeChat
                        setcolor="#2D5FF4"
                        setvalues={this.state?.isRegionViseCountList ?
                          getPresentage(this.state.regionViseCountList.find(item => item?.region === 'Western')?.totalCount || 0, this.state.regionViseCountList.find(item => item.region === 'Western')?.targetCount || 1).toFixed(2) : 0}
                        setTitel="Western"
                        realValue={
                          this.state.isRegionViseCountList
                            ? this.state.regionViseCountList.find(item => item.region === 'Western')?.totalCount || 0
                            : 0
                        }
                        targetValue={
                          this.state.isRegionViseCountList
                            ? this.state.regionViseCountList.find(item => item.region === 'Western')?.targetCount || 0
                            : 0
                        }
                      />
                    </div>

                  ) : null
                }

                {
                  this.state.isRegionViseCountList ? (
                    <div class="col-md-6 col-lg-3" style={{ textAlign: "center", width: "20%" }} >
                      <SemiCircleGaugeChat
                        setcolor="#2D5FF4"
                        setvalues={this.state.isRegionViseCountList ?
                          getPresentage(this.state.regionViseCountList.find(item => item.region === 'Southern')?.totalCount || 0, this.state.regionViseCountList.find(item => item.region === 'Southern')?.targetCount || 1).toFixed(2) : 0}
                        setTitel="Southern"
                        realValue={this.state.isRegionViseCountList
                          ? this.state.regionViseCountList.filter(item => item.region == 'Southern')[0].totalCount
                          : 0}
                        targetValue={this.state.isRegionViseCountList
                          ? this.state.regionViseCountList.filter(item => item.region == 'Southern')[0].targetCount
                          : 0}
                      />
                    </div>
                  ) : null
                }

                {
                  this.state.isRegionViseCountList ? (
                    <div class="col-md-6 col-lg-3" style={{ textAlign: "center", width: "20%" }} >
                      <SemiCircleGaugeChat
                        setcolor="#2D5FF4"
                        setvalues={this.state.isRegionViseCountList ?
                          getPresentage(this.state.regionViseCountList.find(item => item.region === 'Centrel')?.totalCount || 0, this.state.regionViseCountList.find(item => item.region === 'Centrel')?.targetCount || 1).toFixed(2) : 0}
                        setTitel="Central"
                        realValue={this.state.isRegionViseCountList
                          ? this.state.regionViseCountList.filter(item => item.region == 'Centrel')[0].totalCount
                          : 0}
                        targetValue={this.state.isRegionViseCountList
                          ? this.state.regionViseCountList.filter(item => item.region == 'Centrel')[0].targetCount
                          : 0}
                      />
                    </div>
                  ) : null
                }

                {
                  this.state.isRegionViseCountList ? (

                    <div class="col-md-6 col-lg-3" style={{ textAlign: "center", width: "20%" }} >
                      <SemiCircleGaugeChat
                        setcolor="#2D5FF4"
                        setvalues={this.state.isRegionViseCountList ?
                          getPresentage(this.state.regionViseCountList.find(item => item.region === 'Northern')?.totalCount || 0, this.state.regionViseCountList.find(item => item.region === 'Northern')?.targetCount || 1).toFixed(2) : 0}
                        setTitel="Northern"
                        realValue={this.state.isRegionViseCountList
                          ? this.state.regionViseCountList.filter(item => item.region == 'Northern')[0].totalCount
                          : 0}
                        targetValue={this.state.isRegionViseCountList
                          ? this.state.regionViseCountList.filter(item => item.region == 'Northern')[0].targetCount
                          : 0}
                      />
                    </div>
                  ) : null
                }

                {
                  this.state.isRegionViseCountList ? (
                    <div class="col-md-6 col-lg-3" style={{ textAlign: "center", width: "20%" }} >
                      <SemiCircleGaugeChat
                        setcolor="#2D5FF4"
                        setvalues={this.state.isRegionViseCountList ?
                          getPresentage(this.state.regionViseCountList.find(item => item.region === 'Eastern')?.totalCount || 0, this.state.regionViseCountList.find(item => item.region === 'Eastern')?.targetCount || 1).toFixed(2) : 0}
                        setTitel="Eastern"
                        realValue={this.state.isRegionViseCountList
                          ? this.state.regionViseCountList.filter(item => item.region == 'Eastern')[0].totalCount
                          : 0}
                        targetValue={this.state.isRegionViseCountList
                          ? this.state.regionViseCountList.filter(item => item.region == 'Eastern')[0].targetCount
                          : 0}
                      />
                    </div>
                  ) : null
                }

              </div>
            </div>
          </div>
        </div>
        <br></br>
        <div className="row">
          <div className="col-md-12 col-lg-6">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-md-8">
                    <h4 className="card-title" style={{
                      fontWeight: "bold", fontFamily: 'Roboto'
                    }}>Request Trend</h4>
                  </div>
                </div>
              </div>
              <div className="card-body">
                {this.state.requesteTrendChart ? (
                  <LineCharts
                    y1={this.state.requesteTrendChartSetY1}
                    x1={this.stripYearFromDates(this.state.requesteTrendChartSetX1)}
                    yLabale={"Total"} />) : ('')}

              </div>
            </div>
          </div>

          <div className="col-md-12 col-lg-6">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-md-8">
                    <h4 className="card-title" style={{
                      fontWeight: "bold", fontFamily: 'Roboto'
                    }}>Request Status</h4>
                  </div>
                </div>
              </div>
              <div className="card-body">
                {this.state.requestByRegion ? (
                  <StackedBarChat
                    y1={this.state.requestByRegionSetY1}
                    x1={this.stripYearFromDates(this.state.requestByRegionSetX1)}
                    barColors={['#FF3A29', '#42B02A', '#FFB200', '#4C77F6']}
                    yLabale={'Total'} />) : ('1')}
              </div>
            </div>
          </div>

          <div className="col-md-12 col-lg-6">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-md-8">
                    <h4 className="card-title" style={{
                      fontWeight: "bold", fontFamily: 'Roboto'
                    }}>Filling Status by Region</h4>
                  </div>
                </div>
              </div>
              <div className="card-body">
                {this.state.fillingStatus ? (
                  <StackedBarChat
                    y1={this.state.fillingStatusSetY1}
                    x1={this.state.fillingStatusSetX1}
                    yLabale={'Total'}
                    barColors={['#FF3A29', '#42B02A', '#FFB200', '#4C77F6']}
                  />) : ('1')}
              </div>
            </div>
          </div>

          <div className="col-md-12 col-lg-6">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-md-4">
                    <h4 className="card-title" style={{
                      fontWeight: "bold", fontFamily: 'Roboto'
                    }}>Fuel Quantity MOM (Last 12 Monthes)</h4>
                  </div>
                </div>
              </div>
              <div className="card-body">
                {this.state.fuelQuantityMom ? (
                  <LineCharts
                    y1={this.state.fuelQuantityMomSetY1}
                    x1={this.state.fuelQuantityMomSetX1}
                    yLabale={"Total"} />) : ('')}
              </div>
            </div>
          </div>

          <div className="col-md-12 col-lg-6">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-md-3">
                    <h4 className="card-title" style={{
                      fontWeight: "bold", fontFamily: 'Roboto'
                    }}>Fuel Quantity</h4>
                  </div>
                </div>
              </div>
              <div className="card-body">
                {this.state.fuelQuantity ? (
                  <StackedBarLine
                    y1={this.state.fuelQuantitySetY1}
                    x1={this.state.fuelQuantitySetX1}
                    y1Lable={"Liters"}
                    // y2Lable={'MTD'} 
                    />) : ('')}
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    organization_count: state.organization.length,
    regions: state.regions,
    depots: state.depots,
    sites_count: state.sites.length,
    vendor_count: state.vendor.length,
    roles_count: state.roles.length,
  };
}

export default connect(mapStateToProps, { getDonationCategories, getData })(
  DashboardRefuel
);
import React, { Component } from 'react'
import { connect } from "react-redux";
import { getDonationCategories, getData } from "../../actions/index";
import { SiteAccessDashboardCounts } from "../../api/index";
import ColumnCharts from '../../components/charts/column-charts'
import SimplePieChart from '../../components/charts/simple-pie-chart'

import { CompressOutlined, ClockCircleOutlined, LoginOutlined, FrownOutlined, RestOutlined } from '@ant-design/icons';
import { Select, DatePicker, Space } from "antd";
import moment from 'moment';
const { RangePicker } = DatePicker;

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            SubRegion_list: [],
            getAllData: [],
            depot_list: [],
            filter_date: moment(moment().format("YYYY-MM-DD").slice(0, -2) + '01')._i,
            filter_date_to: moment().format("YYYY-MM-DD"),
            filter_depot: [],
            filter_region: [],
            filter_sub_region: [],
            journey_end_count: 0,
            journey_admin_verify_count: 0,
            journey_start_count: 0,
            journey_officer_verify_count: 0,
            journey_vehicle_release_count: 0,
            total_count: 0,
            journey_initiate_count: 0,
            journey_canceled_count: 0,
            vehicleSeriesCount: [20, 28, 30, 12],
            vehicleSerieslabels: [],
        }
    }
    
    componentDidMount() {
        this.props.getData();
        var data = new FormData();
        console.log(this.state.filter_date);
        console.log(this.state.filter_date_to);
        data.append('scheduleDateFrom', this.state.filter_date);
        data.append('scheduleDateTo', this.state.filter_date_to);
        data.append('regions', this.state.filter_region);
        data.append('subregions', this.state.filter_sub_region);
        data.append('depots', this.state.filter_depot);

        this.getSiteAccessDashboardCounts(data);
        this.setState({
            depot_list: this.props.depots,
            SubRegion_list: this.props.regions.filter((item) => {
                return item.isSubRegion == 1;
            }),
        });
    }
    
    getSiteAccessDashboardCounts = async (data) => {
        var data = data;
        var res = await SiteAccessDashboardCounts(data);
        console.log(res.data.SITE_ACCESS_INITIATED);

        if (res.success) {
            this.setState({
                getAllData: res.data,
                journey_admin_verify_count: res.data.journey_admin_verify,
                journey_start_count: res.data.journey_start,
                journey_end_count: res.data.journey_end,
                journey_canceled_count: res.data.journey_canceled,
                journey_initiate_count: res.data.journey_initiate,
                journey_officer_verify_count: res.data.journey_officer_verify,
                journey_vehicle_release_count: res.data.journey_vehicle_release,
                total_count: res.data.total,
            })
        }
    };
    
    onChangeRegion = (value) => {
        this.setState({
            depot_list: [],
            filter_depot: [],
            filter_sub_region: [],

        });
        console.log(value);
        this.setState({
            filter_region: value,
            selectedDepot: "all_depots",
            selected_region_id: value,
            SubRegion_list: this.props.regions.filter(alldate => value.map((n) => n).includes(alldate.parentRegion?.id)),
            depot_list: this.props.depots.filter(alldate => value.map((n) => n).includes(alldate.region?.parentRegion?.id))


        }, () => {
            if (this.state.filter_sub_region == "") {
                var getall = this.props.regions.filter(alldate => value.includes(alldate.parentRegion?.id))
                var output = [];
                for (var i = 0; i < getall.length; ++i)
                    output.push(getall[i]['id']);
                var data1 = new FormData();

                console.log(this.state.filter_region);
                data1.append('scheduleDateFrom', this.state.filter_date);
                data1.append('scheduleDateTo', this.state.filter_date_to);
                data1.append('regions', this.state.filter_region);
                data1.append('subregions', this.state.filter_sub_region);
                data1.append('depots', this.state.filter_depot);

                this.getSiteAccessDashboardCounts(data1);
            }
        });
        var data = {
            id: value,
            type: "region",
        };
    };
    
    onChangeDepot = (value) => {
        this.setState({
            selectedDepot: value,
            filter_depot: value,
        }, () => {
            var data1 = new FormData();

            console.log(this.state.filter_region);
            data1.append('scheduleDateFrom', this.state.filter_date);
            data1.append('scheduleDateTo', this.state.filter_date_to);
            data1.append('regions', this.state.filter_region);
            data1.append('subregions', this.state.filter_sub_region);
            data1.append('depots', this.state.filter_depot);

            this.getSiteAccessDashboardCounts(data1);
        });
    }
    
    dataonChange = (date, dateString) => {
        this.setState({
            filter_date: dateString[0],
            filter_date_to: dateString[1],
        });
    };
    
    onsubChange = (value) => {
        this.setState({
            depot_list: [],
            filter_depot: [],
        });
        this.setState({
            selectedDepot: "all_depots",
            selected_region_id: value,
            filter_sub_region: value,
            depot_list: this.props.depots.filter(alldate => value.map((n) => n).includes(alldate.region?.id))
        }, () => {
            var data1 = new FormData();

            console.log(this.state.filter_region);
            data1.append('scheduleDateFrom', this.state.filter_date);
            data1.append('scheduleDateTo', this.state.filter_date_to);
            data1.append('regions', this.state.filter_region);
            data1.append('subregions', this.state.filter_sub_region);

            this.getSiteAccessDashboardCounts(data1);
        });
        var data = {
            id: value,
            type: "region",
        };
    };

    render() {
        const { Option } = Select;

        function onBlur() {
            //console.log("blur");
        }

        function onFocus() {
            //console.log("focus");
        }

        function onSearch(val) {
            //console.log("search:", val);
        }
        const { vehicleSeriesCount } = this.state
        const { vehicleSerieslabels } = this.state
        return (
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="page-title-box">
                            <div class="row">
                                <div class="col">
                                    <h4 class="page-title">Dashboard</h4>
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="javascript:void(0);">Dashboard</a></li>
                                        <li class="breadcrumb-item"><a href="javascript:void(0);">Site Access</a></li>

                                    </ol>
                                </div>
                                <div class="col-auto align-self-center">
                                    <a href="#" class="btn btn-sm btn-outline-primary" id="Dash_Date">
                                        <span class="day-name" id="Day_Name">Today:</span>&nbsp;
                                        <span class="" id="Select_date">{new Date().toUTCString().slice(5, 16)}</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar align-self-center icon-xs ms-1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                                    </a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">

                    <div class="col-md-12" style={{
                        background: '#fafafa', padding: '20px', borderRadius: '10px'
                    }}>
                        <div className="row">
                            <div className="col-md-2 col-lg-3">
                                <RangePicker
                                    style={{ width: "100%", borderRadius: "5px" }}
                                    defaultValue={[moment(moment().format("YYYY-MM-DD").slice(0, -2) + '01'), moment()]}
                                    onChange={this.dataonChange}
                                />
                            </div>
                            <div className="col-md-2 col-lg-3">
                                <Select
                                    mode="multiple"
                                    showSearch
                                    style={{ width: "100%", borderRadius: "5px" }}
                                    placeholder="Select a region"
                                    optionFilterProp="children"
                                    onChange={this.onChangeRegion}
                                    onFocus={onFocus}
                                    onBlur={onBlur}
                                    onSearch={onSearch}
                                    filterOption={(input, option) =>
                                        option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {this.props.regions.filter(allret => "0".includes(allret.isSubRegion)).map((item, index) => {
                                        return <Option value={item.id}>{item.name}</Option>;
                                    })}
                                </Select>
                            </div>
                            <div className="col-md-3 col-lg-3">
                                <Select
                                    mode="multiple"
                                    showSearch
                                    style={{ width: "100%", borderRadius: "5px" }}
                                    placeholder="Select a Subregion"
                                    optionFilterProp="children"
                                    onChange={this.onsubChange}
                                    onFocus={onFocus}
                                    onBlur={onBlur}
                                    onSearch={onSearch}
                                    filterOption={(input, option) =>
                                        option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }>
                                    {this.state.SubRegion_list.map((item, index) => {
                                        return <Option value={item.id}>{item.name}</Option>;
                                    })}
                                </Select>
                            </div>
                            <div className="col-md-2 col-lg-3">
                                <Select
                                    mode="multiple"
                                    showSearch
                                    style={{ width: "100%", borderRadius: "5px" }}
                                    placeholder="Select a depot"
                                    optionFilterProp="children"
                                    onChange={this.onChangeDepot}
                                    defaultActiveFirstOption={true}
                                    allowClear={true}
                                    filterOption={(input, option) =>
                                        option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }>
                                    {this.state.depot_list.map((item, index) => {
                                        return <Option value={item.id}>{item.name}</Option>;
                                    })}
                                </Select>
                            </div>
                        </div>
                    </div>
                </div>
                
                <br></br>
                <div class="row">
                    <div className="col-md-12 col-lg-12">
                        <div class="row justify-content-center">

                            {/* <div class="col-md-6 col-lg-2" style={{ width: "20%" }}>
                                <div class="card report-card bg-soft-primary">
                                    <div class="card-body">
                                        <div class="row d-flex justify-content-center">
                                            <div class="col">
                                                <p class=" mb-0 fw-semibold">Initiated</p>
                                                <h3 class="m-0">{this.state.getAllData?.SITE_ACCESS_INITIATED ? (this.state.getAllData?.SITE_ACCESS_INITIATED) : (0)}</h3>
                                                <p class="mb-0 text-truncate text-muted">Initiated &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</p>
                                            </div>
                                            <div class="col-auto align-self-center">
                                                <div class="report-main-icon bg-light-alt">
                                                    <svg style={{ color: "#1761fd" }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users align-self-center  icon-sm"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            <div class="col-md-2 col-lg-3" >
                                <div class="card report-card  bg-soft-primary" style={{ borderRadius: '20px' }}>
                                    <div class="card-body">
                                        <div class="row d-flex justify-content-center">
                                            <div class="col">
                                                <p class="mb-0 fw-semibold" style={{
                                                    fontSize: "14px"
                                                }}>
                                                    <b>Initiated</b></p>
                                                <h2 class="m-0" style={{ marginTop: '3px' }}>{this.state.getAllData?.SITE_ACCESS_INITIATED ? (this.state.getAllData?.SITE_ACCESS_INITIATED) : (0)}</h2>
                                            </div>
                                            <div class="col-auto align-self-center">
                                                <div style={{
                                                    background: "white", borderRadius: "50%", width: "40px", height: "40px", display: "grid",
                                                    alignItems: "center", justifyContent: "center", margin: "auto"
                                                }} float="left" className="mt-1">
                                                    <CompressOutlined style={{ color: "#1761fd", fontSize: '20px' }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div class="col-md-6 col-lg-2" style={{ width: "20%" }}>
                                <div class="card report-card bg-soft-success">
                                    <div class="card-body">
                                        <div class="row d-flex justify-content-center">
                                            <div class="col">
                                                <p class=" mb-0 fw-semibold">Pending</p>
                                                <h3 class="m-0">25</h3>
                                                <p class="mb-0 text-truncate text-muted">Pending &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;</p>
                                            </div>
                                            <div class="col-auto align-self-center">
                                                <div class="report-main-icon bg-light-alt">
                                                    <svg style={{ color: "#03d87f" }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clock align-self-center  icon-sm"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            {/* <div class="col-md-6 col-lg-2" style={{ width: "20%" }}>
                                <div class="card report-card bg-soft-pink">
                                    <div class="card-body">
                                        <div class="row d-flex justify-content-center">
                                            <div class="col">
                                                <p class=" mb-0 fw-semibold">Access Permission Received</p>
                                                <h3 class="m-0">{this.state.getAllData?.RECEVED_APPROVE ? (this.state.getAllData?.RECEVED_APPROVE) : (0)}</h3>
                                                <p class="mb-0 text-truncate text-muted">Access Permission Received</p>
                                            </div>
                                            <div class="col-auto align-self-center">
                                                <div class="report-main-icon bg-light-alt">
                                                    <svg style={{ color: "#fd3c97" }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-activity align-self-center icon-sm"><polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline></svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            <div class="col-md-2 col-lg-3" >
                                <div class="card report-card bg-soft-success" style={{ borderRadius: '20px' }}>
                                    <div class="card-body" >
                                        <div class="row d-flex justify-content-center">
                                            <div class="col">
                                                <p class="mb-0 fw-semibold" style={{
                                                    fontSize: "14px"
                                                }}><b>Permission Received</b></p>
                                                <h2 class="m-0" style={{ marginTop: '3px' }}>{this.state.getAllData?.RECEVED_APPROVE ? (this.state.getAllData?.RECEVED_APPROVE) : (0)}</h2>
                                            </div>
                                            <div class="col-auto align-self-center">
                                                <div style={{
                                                    background: "white", borderRadius: "50%", width: "40px", height: "40px", display: "grid",
                                                    alignItems: "center", justifyContent: "center", margin: "auto"
                                                }} float="left" className="mt-1">
                                                    <LoginOutlined style={{ color: "#99db7f", fontSize: '20px' }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
{/* 
                            <div class="col-md-6 col-lg-2" style={{ width: "20%" }}>
                                <div class="card report-card bg-soft-warning">
                                    <div class="card-body">
                                        <div class="row d-flex justify-content-center">
                                            <div class="col">
                                                <p class=" mb-0 fw-semibold">Rejected by Handler</p>
                                                <h3 class="m-0">{this.state.getAllData?.ACCESS_REJECT_BY_HANDLER ? (this.state.getAllData?.ACCESS_REJECT_BY_HANDLER) : (0)}</h3>
                                                <p class="mb-0 text-truncate text-muted">Rejected by Handler &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;</p>
                                            </div>
                                            <div class="col-auto align-self-center">
                                                <div class="report-main-icon bg-light-alt">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-briefcase align-self-center  icon-sm"><rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect><path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path></svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            <div class="col-md-6 col-lg-3" >
                                <div class="card report-card bg-soft-pink" style={{ borderRadius: '20px' }}>
                                    <div class="card-body" >
                                        <div class="row d-flex justify-content-center">
                                            <div class="col">
                                                <p class="mb-0 fw-semibold" style={{
                                                    fontSize: "14px"
                                                }}><b>Rejected by Handler</b></p>
                                                <h2 class="m-0" style={{ marginTop: '3px' }}>{this.state.getAllData?.ACCESS_REJECT_BY_HANDLER ? (this.state.getAllData?.ACCESS_REJECT_BY_HANDLER) : (0)}</h2>
                                            </div>
                                            <div class="col-auto align-self-center">
                                                <div style={{
                                                    background: "white", borderRadius: "50%", width: "40px", height: "40px", display: "grid",
                                                    alignItems: "center", justifyContent: "center", margin: "auto"
                                                }} float="left" className="mt-1">
                                                    <FrownOutlined style={{ color: "#fd3ca3", fontSize: '20px' }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div class="col-md-6 col-lg-2" style={{ width: "20%" }}>
                                <div class="card report-card  bg-soft-purple">
                                    <div class="card-body">
                                        <div class="row d-flex justify-content-center">
                                            <div class="col">
                                                <p class=" mb-0 fw-semibold">Rejected by Site</p>
                                                <h3 class="m-0">{this.state.getAllData?.REJECT_FROM_SITE ? (this.state.getAllData?.REJECT_FROM_SITE) : (0)}</h3>
                                                <p class="mb-0 text-truncate text-muted"> Rejected by Site &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp;</p>
                                            </div>
                                            <div class="col-auto align-self-center">
                                                <div class="report-main-icon bg-light-alt">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-briefcase align-self-center  icon-sm"><rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect><path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path></svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            <div class="col-md-6 col-lg-3">
                                <div class="card report-card bg-soft-warning" style={{ borderRadius: '20px' }}>
                                    <div class="card-body" >
                                        <div class="row d-flex justify-content-center">
                                            <div class="col">
                                                <p class="mb-0 fw-semibold" style={{
                                                    fontSize: "14px"
                                                }}><b>Rejected by Site</b></p>
                                                <h2 class="m-0" style={{ marginTop: '3px' }}>{this.state.getAllData?.REJECT_FROM_SITE ? (this.state.getAllData?.REJECT_FROM_SITE) : (0)}</h2>
                                            </div>
                                            <div class="col-auto align-self-center">
                                                <div style={{
                                                    background: "white", borderRadius: "50%", width: "40px", height: "40px", display: "grid",
                                                    alignItems: "center", justifyContent: "center", margin: "auto"
                                                }} float="left" className="mt-1">
                                                    <RestOutlined style={{ color: "#ffda7e", fontSize: '20px' }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {/* <div className='row'>
                    <div class="col-lg-6">
                        <div class="card">
                            <div class="card-header">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <h4 class="card-title">Most Populer Products</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="table-responsive">
                                    <table class="table mb-0">
                                        <thead class="table-light">
                                            <tr>
                                                <th class="border-top-0">Product</th>
                                                <th class="border-top-0">Price</th>
                                                <th class="border-top-0">Sell</th>
                                                <th class="border-top-0">Status</th>
                                                <th class="border-top-0">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div class="media">
                                                        <img src={'https://mannatthemes.com/dastone/default/assets/images/products/img-5.png'} height="30" class="me-3 align-self-center rounded" alt="..."></img>
                                                        <div class="media-body align-self-center">
                                                            <h6 class="m-0">Dastone Camera EDM 5D(White)</h6>
                                                            <a href="#" class="font-12 text-primary">ID: A3652</a>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>$50 <del class="text-muted font-10">$70</del></td>
                                                <td>450 <small class="text-muted">(550)</small></td>
                                                <td><span class="badge badge-soft-warning px-2">Stock</span></td>
                                                <td>
                                                    <a href="#"><i class="las la-pen text-secondary font-16"></i></a>
                                                    <a href="#"><i class="las la-trash-alt text-secondary font-16"></i></a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="media">
                                                        <img src={'https://mannatthemes.com/dastone/default/assets/images/products/img-5.png'} height="30" class="me-3 align-self-center rounded" alt="..."></img>
                                                        <div class="media-body align-self-center">
                                                            <h6 class="m-0">Dastone Shoes Max-Zon</h6>
                                                            <a href="#" class="font-12 text-primary">ID: A5002</a>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>$99 <del class="text-muted font-10">$150</del></td>
                                                <td>750 <small class="text-muted">(00)</small></td>
                                                <td><span class="badge badge-soft-primary px-2">Out of Stock</span></td>
                                                <td>
                                                    <a href="#"><i class="las la-pen text-secondary font-16"></i></a>
                                                    <a href="#"><i class="las la-trash-alt text-secondary font-16"></i></a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="media">
                                                        <img src={'https://mannatthemes.com/dastone/default/assets/images/products/img-5.png'} height="30" class="me-3 align-self-center rounded" alt="..."></img>
                                                        <div class="media-body align-self-center">
                                                            <h6 class="m-0">Dastone Mask N99 [ISI]</h6>
                                                            <a href="#" class="font-12 text-primary">ID: A6598</a>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>$199 <del class="text-muted font-10">$250</del></td>
                                                <td>280 <small class="text-muted">(220)</small></td>
                                                <td><span class="badge badge-soft-warning px-2">Stock</span></td>
                                                <td>
                                                    <a href="#"><i class="las la-pen text-secondary font-16"></i></a>
                                                    <a href="#"><i class="las la-trash-alt text-secondary font-16"></i></a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="media">
                                                        <img src={'https://mannatthemes.com/dastone/default/assets/images/products/img-5.png'} height="30" class="me-3 align-self-center rounded" alt="..."></img>
                                                        <div class="media-body align-self-center">
                                                            <h6 class="m-0">Dastone Bag (Blue)</h6>
                                                            <a href="#" class="font-12 text-primary">ID: A9547</a>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>$40 <del class="text-muted font-10">$49</del></td>
                                                <td>500 <small class="text-muted">(1000)</small></td>
                                                <td><span class="badge badge-soft-primary px-2">Out of Stock</span></td>
                                                <td>
                                                    <a href="#"><i class="las la-pen text-secondary font-16"></i></a>
                                                    <a href="#"><i class="las la-trash-alt text-secondary font-16"></i></a>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">Pie Chart</h4>
                                <p class="text-muted mb-0">Pie and doughnut charts are probably
                                    the most commonly used chart there are. They are divided into segments,
                                    the arc of each segment shows the proportional value of each piece of
                                    data.
                                </p>
                            </div>
                            <div class="card-body">
                                <SimplePieChart vehicleSeries={{ vehicleSeriesCount }} vehicleLable={{ vehicleSerieslabels }} />
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="card">
                            <div class="card-header">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <h4 class="card-title">Sessions By Channel</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <div id="barchart" class="apex-charts ms-n4">
                                    <ColumnCharts />
                                </div>
                            </div>
                        </div>
                    </div>

                </div> */}
                <div className='row'>
                    <div class="col-lg-12 col-xl-6">
                    </div>
                </div>
            </div>
        )
    }
}
function mapStateToProps(state) {
    //console.log("state", state);
    return {
        organization_count: state.organization.length,
        regions: state.regions,
        depots: state.depots,
        sites_count: state.sites.length,
        vendor_count: state.vendor.length,
        roles_count: state.roles.length,
    };
}

export default connect(mapStateToProps, { getDonationCategories, getData })(
    Dashboard
);

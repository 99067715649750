import React, { Component } from 'react'
import ReactApexChart from 'react-apexcharts'

 class GradientDonutChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
          
            series: [],
            options: {
              chart: {
                width: 380,
                type: 'donut',
              },
              plotOptions: {
                pie: {
                  startAngle: -90,
                  endAngle: 270
                }
              },
              dataLabels: {
                enabled: true
              },
              fill: {
                type: 'gradient',
              },
              legend: {
                show: true,
                position: 'bottom',
            },
              labels: [],
              title: {
                text: ''
              },
              responsive: [{
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  },
              
                }
              }]
            },
          
          
          };
    }
    componentDidMount(){

        this.setState({
            series: this.props.pGSeries.pGSeriesCount,
            options: {
              chart: {
                width: 380,
                type: 'donut',
              },
              plotOptions: {
                pie: {
                  startAngle: -90,
                  endAngle: 270
                }
              },
              dataLabels: {
                enabled: true
              },
              fill: {
                type: 'gradient',
              },
              legend: {
                show: true,
                position: 'bottom',
            },
              labels: this.props.pGLable.pGSerieslabels,
              title: {
                text:this.props.setTitel,
              },
              responsive: [{
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  },
              
                }
              }]
            },
        })
    }
    
  render() {
    return (
        <div id="chart">
        <ReactApexChart options={this.state.options} series={this.state.series} type="donut" height={350} />
    </div>
    )
  }
}
export default GradientDonutChart;
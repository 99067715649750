import React, { useState, useEffect } from 'react';
import {
    CarOutlined,
    NodeIndexOutlined,
    EditOutlined,
    DeleteOutlined,
    EyeOutlined,
    CheckOutlined,
    DashboardOutlined,
    SearchOutlined,
    PhoneOutlined,
    PaperClipOutlined,
    UploadOutlined,
    DownloadOutlined,
    CloudSyncOutlined
} from '@ant-design/icons';
import { Checkbox, Avatar, Card, Skeleton, Switch, Table, Select, Input, Button, Row, Upload, Col, Dropdown, Tag, Modal, Steps, message, Form, DatePicker, TimePicker, Descriptions, Image, Empty, Spin} from 'antd';
import {
    getAllActiveVehicleOwner,
    getAllMainRegions,
    getVehicleListFullExport,
    getSessionFullExport,
    getVehicleSummaryReport,
    getMonthlySummaryReport,
    getAccessTeamExportDataREWAMP,
    getAccessFullExportDataREWAMP
} from "../../../api/index";
import '../style.css';
import xlsx from 'xlsx';
import jsonfile from 'jsonfile';
import fs from 'fs';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';

const { Option } = Select;
const { RangePicker } = DatePicker;

const AccessReportsData = () => {
    const [expoertCategory, setExportCategory] = useState('1');
    const [startAndEndDate, setStartAndEndDate] = useState([]);
    const [exportFormat, setExportFormat] = useState('1');
    const [regionList, setRegionList] = useState([]);
    const [vendorName, setVendorName] = useState([]);
    const [fetching, setFetching] = useState(false);
    const [searchData, setSearchData] = useState(null);
    const [selectedRegions, setSelectedRegions] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState([]);
    const [mappedData, setMappedData] = useState([]);
    const [buttonVisible, setButtonVisible] = useState(false);
    const [selectedSupplier, setSelectedSupplier] = useState([]);
    const [isStillLoading, setIsStillLoading] = useState(false);

    useEffect(() => {
        fetchAllRegions();
        fetchVehicleOwner();
    }, []);

    const setMonthlyStartAndEnd = (date, dateString) => {
        const startMonth = dateString;
        const endMonth = dateString;
        const startYear = parseInt(startMonth.substring(0, 4));
        const startMonthNum = parseInt(startMonth.substring(5, 7));
        const endYear = parseInt(endMonth.substring(0, 4));
        const endMonthNum = parseInt(endMonth.substring(5, 7));
        const startDate = new Date(startYear, startMonthNum - 1, 1);
        startDate.setDate(startDate.getDate() + 1);
        const endDate = new Date(endYear, endMonthNum, 0);
        endDate.setDate(endDate.getDate() + 1);
        const formattedStartDate = startDate.toISOString().slice(0, 10);
        const formattedEndDate = endDate.toISOString().slice(0, 10);
        const monArry = [];
        monArry.push(formattedStartDate);
        monArry.push(formattedEndDate);
        setSelectedMonth(monArry);
    }


    // Fetch Access Full Export Data
    const fetchAccessFullDataExport = async () => {
        setIsStillLoading(true);
        const requestFormData = new FormData();
        requestFormData.append("startDate", startAndEndDate[0]);
        requestFormData.append("endDate", startAndEndDate[1]);
        requestFormData.append("regions", selectedRegions);
        const response = await getAccessFullExportDataREWAMP(requestFormData);
        if (response.success) {
            setSearchData(response.data.results);
            const mapData = accessFullDataMap(response.data.results);
            setMappedData(mapData);
            setButtonVisible(true);
            setIsStillLoading(false);
        } else {
            message.error("Failed to fetch access data full export");
            setIsStillLoading(false);
        }
    };
    
    // Fetch Access Team Data
    const fetchAccessFullTeamDataExport = async () => {
        setIsStillLoading(true);
        const requestFormData = new FormData();
        requestFormData.append("startDate", startAndEndDate[0]);
        requestFormData.append("endDate", startAndEndDate[1]);
        requestFormData.append("regions", selectedRegions);
        const response = await getAccessTeamExportDataREWAMP(requestFormData);
        if (response.success) {
            setSearchData(response.data.results);
            const mapData = siteAccessTeamsMap(response.data.results);
            setMappedData(mapData);
            setButtonVisible(true);
            setIsStillLoading(false);
        } else {
            message.error("Failed to fetch access team data full export");
            setIsStillLoading(false);
        }
    };

    const prepareDownloadData = () => {
        const mapDataSet = mappedData;
        if (mapDataSet.length === 0) {
            message.error("No data to export");
            return;
        }
        if (expoertCategory === '1') {
            exportData(mapDataSet, "Access Full Export_");
        } else if (expoertCategory === '2') {
            exportData(mapDataSet, "Access Team Full Export_");
        } 
    }

    const  transformKey = (key) => {
        const words = key.split(/(?=[A-Z])/);
        const transformedKey = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        return transformedKey;
      }

    const allKeys = searchData?.reduce((keys, obj) => {
        Object.keys(obj).forEach(key => {
            if (!keys.includes(key)) {
                keys.push(key);
            }
        });
        return keys;
    }, []);


    const maxColumns = Math.min(7, allKeys?.length);
    const columns = allKeys?.slice(0, maxColumns).map(key => ({
        title: transformKey(key),
        dataIndex: key,
        key: key,
    }));

    const timeConversionToReadable = (inputTime) => {
        if (inputTime !== null || inputTime !== '') {
            return inputTime.replace("T", " ").slice(0, 19);
        } else {
            return "N/A";
        }
    }

    const siteAccessTeamsMap = (apiData) => {
        return apiData.map(item => ({
            "Bulk Code": item?.bulkCode ? item.bulkCode : "N/A",
            "Access Permission Code": item?.accessPermissionCode ? item.accessPermissionCode : "N/A",
            "Site Id": item?.siteId ? item.siteId : "N/A",
            "Site Name": item?.siteName ? item.siteName : "N/A",
            "Depot": item?.depotName ? item.depotName : "N/A",
            "Region": item?.regionName ? item.regionName : "N/A",
            "Sub Region": item?.subRegionName ? item.subRegionName : "N/A",
            "Requester": item?.requester ? item.requester : "N/A",
            "Requester Company": item?.requesterCompany ? item.requesterCompany : "N/A",
            "Name": item?.name ? item.name : "N/A",
            "Mobile No": item?.mobileNumber ? item.mobileNumber : "N/A",
            "Nic": item?.nic ? item.nic : "N/A",
            "Company": item?.company ? item.company : "N/A",
        }));
    }

    const accessFullDataMap = (apiData) => {
        return apiData.map(item => ({
            "Bulk Code": item?.bulkCode ? item.bulkCode : "N/A",
            "Access Permission Code": item?.accessPermissionCode ? item.accessPermissionCode : "N/A",
            "Site Id": item?.siteId ? item.siteId : "N/A",
            "Site Name": item?.siteName ? item.siteName : "N/A",
            "Depot": item?.depotName ? item.depotName : "N/A",
            "Region": item?.regionName ? item.regionName : "N/A",
            "Sub Region": item?.subRegionName ? item.subRegionName : "N/A",
            "Create Name": item?.createName ? item.createName : "N/A",
            "Type": item?.type ? item.type : "N/A",
            "Request Category": item?.requestCategory ? item.requestCategory : "N/A",
            "Requester Company": item?.requesterCompany ? item.requesterCompany : "N/A",
            "Requester Group": item?.requesterGroup ? item.requesterGroup : "N/A",
            "Handler Name": item?.handlerName ? item.handlerName : "N/A",
            "Handler Mobile No": item?.handlerMobile ? item.handlerMobile : "N/A",
            "Handler Email": item?.handlerEmail ? item.handlerEmail : "N/A",
            "Handler Group": item?.handlerGroup ? item.handlerGroup : "N/A",
            "Group": item?.group ? item.group : "N/A",
            "Ticket Create Time": item?.ticketCreateTime ? timeConversionToReadable(item.ticketCreateTime) : "N/A",
            "Access Start Date": item?.accessStartDateAndTime ? item.accessStartDateAndTime.split("T")[0] : "N/A",
            "Access Start Time": item?.accessStartDateAndTime ? item.accessStartDateAndTime.split("T")[1].split(".")[0] : "N/A",
            "Access End Date": item?.accessEndDateAndTime ? item.accessEndDateAndTime.split("T")[0] : "N/A",
            "Access End Time": item?.accessEndDateAndTime ? item.accessEndDateAndTime.split("T")[1].split(".")[0] : "N/A",
            "Ticket Status": item?.status ? item.status : "N/A",
            "Sub Request Categories": item?.requestSubCategory ? item.requestSubCategory : "N/A",
            "Site Owner Access Request Time": item?.siteOwnerRequestTime ? timeConversionToReadable(item.siteOwnerRequestTime) : "N/A",
            "Site Owner Access Permission Status": item?.siteOwnerAccessPermitionStatus ? item.siteOwnerAccessPermitionStatus : "N/A",
            "Access Permission Received / Rejected Time": item?.accessPermissionReceivedTime ? timeConversionToReadable(item.accessPermissionReceivedTime) : "N/A",
            "Reject / Accept Comments": item?.rejectAcceptComment ? item.rejectAcceptComment : "N/A",
            "Access Receive": item?.accessReceive ? item.accessReceive : "N/A",
            "Access Request Method": item?.accessRequestedMethod ? item.accessRequestedMethod : "N/A",
            "Access Request Time": item?.accessRequestedTime ? timeConversionToReadable(item.accessRequestedTime) : "N/A",
            "Access Receive Method": item?.accessReceivedMethod ? item.accessReceivedMethod : "N/A",
            "Access Receive Time": item?.accessReceivedTime ? timeConversionToReadable(item.accessReceivedTime) : "N/A",
            "Note": item?.note ? item.note : "N/A",
            "Handler Reject Time": item?.handlerRejectTime ? timeConversionToReadable(item.handlerRejectTime) : "N/A",
            "Handler Reject Note": item?.handlerRejectNote ? item.handlerRejectNote : "N/A",
            "Site Reject Time": item?.siteRejectTime ? timeConversionToReadable(item.siteRejectTime) : "N/A",
            "Action Permission Status": item?.actionPermisionStatus ? item.actionPermisionStatus : "N/A",
            "Final Status": item?.finalStatus ? item.finalStatus : "N/A",
            "Last Action Taken On": item?.lastActionTakenOn ? timeConversionToReadable(item.lastActionTakenOn) : "N/A",
            "Project Name": item?.projectName ? item.projectName : "N/A",
            "Reference No": item?.referanceNumber ? item.referanceNumber : "N/A",
            "Visiting Purpose": item?.visitingPurpos ? item.visitingPurpos : "N/A",
            "Work Description": item?.workDescription ? item.workDescription : "N/A",
            "Access Need": item?.accessNeed ? item.accessNeed : "N/A",
        }));
    }
    
    const exportData = (data, exportName) => {
        if (exportFormat === '1') {
            const worksheet = xlsx.utils.json_to_sheet(data);
            const workbook = xlsx.utils.book_new();
            xlsx.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            xlsx.writeFile(workbook, exportName+ new Date().toISOString().slice(0, 10) + '.xlsx');

        } else {
            const csv = Papa.unparse(data);
            const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
            saveAs(blob, exportName + new Date().toISOString().slice(0, 10) + '.csv');
        }
    }


    const manageActionExport = () => {
        setButtonVisible(false);
        setSearchData([]);
        if (expoertCategory === '1' || expoertCategory === '2') {
            if (startAndEndDate[0] === undefined || startAndEndDate[1] === undefined || startAndEndDate[0] === "" || startAndEndDate[1] === "") {
                message.error("Please Select Start and End Date");
                return;
            } else {
                if (expoertCategory === '1') {
                    fetchAccessFullDataExport();
                } else if (expoertCategory === '2') {
                    fetchAccessFullTeamDataExport();
                }
            }
        } 
    }

    const fetchAllRegions = async () => {
        const response = await getAllMainRegions();
        if (response.success) {
            setRegionList(response.data.results);
        } else {
            message.error("Failed to fetch regions");
        }
    };

    const fetchVehicleOwner = async () => {
        const response = await getAllActiveVehicleOwner();
        if (response.success) {
            setVendorName(response.data.results);
        } else {
            message.error("Failed to fetch vehicle owners");
        }
    };

    const setMultipleRegions = (value) => {
        setSelectedRegions(value.join(","));
    }

    const setSuppliersToExport = (value) => {
        setVendorName(value);
    }

    return (
        <body>
            <div style={{ margin: '0', padding: '10px', height: '100%' }}>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="page-title-box">
                            <div className="row">
                                <div className="col">
                                    <h4 className="page-title" style={{ fontWeight: "bold" }}>
                                        Reports And Exports
                                    </h4>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <a href="javascript:void(0);">Site Access </a>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <a href="javascript:void(0);">Reports And Exports</a>
                                        </li>
                                    </ol>
                                </div>
                                <div className="col-auto align-self-center">
                                    <a
                                        href="#"
                                        className="btn btn-sm btn-outline-primary"
                                        id="Dash_Date"
                                    >
                                        <span className="day-name" id="Day_Name">
                                            Today:
                                        </span>
                                        &nbsp;
                                        <span className="" id="Select_date">
                                            {new Date().toUTCString().slice(5, 16)}
                                        </span>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            className="feather feather-calendar align-self-center icon-xs ms-1"
                                        >
                                            <rect
                                                x="3"
                                                y="4"
                                                width="18"
                                                height="18"
                                                rx="2"
                                                ry="2"
                                            ></rect>
                                            <line x1="16" y1="2" x2="16" y2="6"></line>
                                            <line x1="8" y1="2" x2="8" y2="6"></line>
                                            <line x1="3" y1="10" x2="21" y2="10"></line>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{
                    backgroundColor: "#FFFFFF",
                    padding: "20px 20px 20px 20px",
                    borderRadius: "10px",
                    marginTop: "5px",
                }}>


                    <div style={{ padding: 10, borderRadius: 10 }}>
                        <Row>
                            <Col span={24}>
                                <Select placeholder="Export Category"
                                    className='borderedSelect'
                                    style={{ width: '220px' }}
                                    onChange={(value) => {
                                        setExportCategory(value);
                                        setButtonVisible(false);
                                        setSearchData([]);
                                    }}
                                    defaultValue={expoertCategory}
                                    bordered={false}
                                >
                                    <Option value="1" className="textStyle-small">Acceess Full Export</Option>
                                    <Option value="2" className="textStyle-small">Acceess Teams Full Export</Option>
                                </Select>

                                <Select placeholder="Select Export Format"
                                    className='borderedSelect'
                                    style={{ width: '220px', marginLeft: "10px" }}
                                    onChange={(value) => setExportFormat(value)}
                                    defaultValue={exportFormat}
                                    bordered={false}
                                >
                                    <Option value="1" className="textStyle-small">EXCEL</Option>
                                    <Option value="2" className="textStyle-small">CSV</Option>
                                </Select>

                                {
                                    isStillLoading !== true ? (
                                        <Button
                                        className='textStyles-small'
                                        type="primary"
                                        style={{ marginLeft: "10px", width: "150px", borderRadius: "10px" }}
                                        icon={<CloudSyncOutlined />}
                                        onClick={manageActionExport}
                                    >
                                        Fetch Data
                                    </Button>
                                    ) : null
                                }

                                {
                                    buttonVisible ? (
                                        <Button
                                            className='textStyles-small'
                                            type="primary"
                                            style={{ marginLeft: "10px", width: "150px", borderRadius: "10px", backgroundColor: "#52c41a" }}
                                            icon={<DownloadOutlined />}
                                            onClick={prepareDownloadData}
                                        >
                                            Export Report
                                        </Button>
                                    ) : <></>
                                }

                            </Col>
                            <Col span={12}>
                                <div style={{ float: "right" }}>
                                </div>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: "10px" }}>
                            <Col span={24}>
                                <div style={{
                                    backgroundColor: "#fafafa",
                                    padding: "10px 10px 10px 10px",
                                    borderRadius: "10px",
                                    marginTop: "5px",
                                }}>
                                    <div>
                                        <h5 
                                        className='textStyles-small'
                                        style={{ fontWeight: "bold" }}>Custom Filters</h5>
                                    </div>

                                    {
                                        expoertCategory === '1' || expoertCategory === '2' ? (
                                            <RangePicker
                                                className='customDropdown'
                                                onChange={(date, dateString) => setStartAndEndDate(dateString)}
                                            />
                                        ) :
                                        <DatePicker
                                            className='customDropdown'
                                            picker="month"
                                            onChange={setMonthlyStartAndEnd}
                                        />
                                    }

                                    <Select
                                        placeholder="Select Region"
                                        className='borderedSelect'
                                        style={{ width: '220px', marginLeft: "10px", height: "auto" }}
                                        bordered={false}
                                        onChange={setMultipleRegions}
                                        mode="multiple"
                                    >
                                        {regionList === null ? [] : regionList.map((region) => (
                                            <Option key={region.id} value={region.id} className="textStyle-small">{region.name}</Option>
                                        ))}
                                    </Select>
                                    {
                                        expoertCategory === '3' || expoertCategory === '4' ? (
                                            <>
                                                <Select
                                                    placeholder="Select Supplier"
                                                    className='borderedSelect'
                                                    style={{ width: '220px', marginLeft: "10px", height: "30px" }}
                                                    bordered={false}
                                                    onChange={(value) => setSelectedSupplier(value)}
                                                    allowClear
                                                >
                                                    {vendorName?.map((vendor) => (
                                                        <Option value={vendor.id} className="textStyle-small" key={vendor.id}>
                                                            {vendor.name}
                                                        </Option>
                                                    ))}
                                                </Select>

                                            </>
                                        ) : <></>
                                    }

                                </div>
                            </Col>
                        </Row>
                    </div> <br />

                    <Spin tip="Fetching Data..." size="large" spinning={isStillLoading}>
                        <Table
                            className="table-striped-rows"
                            dataSource={searchData === null ? [] : searchData}
                            columns={columns}
                            loading={searchData === null ? fetching : false}
                            rowKey="key"
                            size="small"
                        />
                    </Spin>
                </div>
            </div>

        </body>

    )
}

export default AccessReportsData;
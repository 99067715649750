
import React, { Component } from "react";
import { Table, Input, Tag, Space, DatePicker } from "antd";
import ReactExport from "react-data-export";
import { getVehicleFullExport } from "../../api/index";
import { getDonationCategories, getData } from "../../actions/index";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'

import ReactLoading from 'react-loading';
const moment = require('moment');




const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const { RangePicker } = DatePicker;

class VehicleFullExport extends Component {
    constructor(props) {
        super(props);
        this.state = {
          title: "",
          filterTable: null,
          data_list: [],
          fullArray: [],
          startDate: "",
          endDate: "",
          loading:false,
          buttonLodading:false,
    
        };
      }
    
      componentDidMount() {
        if (JSON.parse(localStorage.getItem('serviceTypeSet')) == null) {
          const { history } = this.props;
   
      history.push("/");
         
        } else {
          this.getSetPageData(JSON.parse(localStorage.getItem('serviceTypeSet')) , 16)
        }
        this.props.getData();

      }
      getSetPageData =(serviceTypeIs,Pageid) =>  {
        if(serviceTypeIs.serviceType.filter(checkuser=>checkuser?.id==Pageid)[0] == null ){
          const { history } = this.props;
       
          history.push("/not-allowed");
        }
        
      };
      getDataexpot = async (data) => {
        var pageCount = 0;
        var res = await getVehicleFullExport(data);
        console.log(res);
        pageCount = res?.data?.pageCount;
        if (pageCount == 0) {

            this.setState({
                loading: false,
                data_list: [],
                buttonLodading:false
            })
        }
        if (pageCount == 1) {
            this.setState({

                data_list: res.data.data,
                loading: false,
                buttonLodading:false
            });
        }
        if (pageCount > 1) {
            for (let i = 0; i < pageCount; i++) {
                this.getDataexpot2(i, pageCount)
                if (i == pageCount - 1) {
                    this.setState({
                        loading: false,

                    })
                }
            }
        }
      };
      getDataexpot2 = async (data, pagecout) => { 
       
        var form = new FormData();
        form.append("pageNo", data);
        form.append("RequestDateFrom",this.state.startDate);
        form.append("RequestDateTo",this.state.endDate);
        var res = await getVehicleFullExport(form);


        if (res.success) {
            this.setState({
                fullArray: [...this.state.fullArray, ...res.data.data]
            }, () => {
                if(data+1==pagecout){
                    this.setState({
                        buttonLodading:false
                    })


                }
                this.setState({

                    data_list: this.state.fullArray,
                });

            })
          }
      };
      onChangeDate = (date, dateString) => {
        //console.log(date);
        //console.log(dateString);
        this.setState({
          startDate: dateString,
        });
      };
      onChangeDate2 = (date, dateString) => {
        //console.log(date);
        //console.log(dateString);
        this.setState({
          endDate: dateString,
        });
      };
      searchClick = () => {
        if(this.state.startDate!="" && this.state.endDate!=""){
          this.setState({
            fullArray: [],
            data_list: [],
            buttonLodading:true
          })
          var data = new FormData();
          data.append("pageNo", 0);
          data.append("RequestDateFrom",this.state.startDate);
          data.append("RequestDateTo",this.state.endDate);
          this.setState({
            loading: true,
          });
          this.getDataexpot(data);
        }
        
      };
      render() {
        const fontValue =  {sz: "12", bold: true,fill: {patternType: "solid", fgColor: {rgb: "FF0B0713"}}};
        const { filterTable } = this.state;
        const dataSet1 = this.state.data_list;
    
       
    
        const StatisticDataSet = [
    {
      columns: [
        // Sec 01
        {title: "Request Code",style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:210}},//char width 
        {title: "Request Created On", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width 
       // {title: "Journey ID", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
        {title: "Job Requested Time", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
        {title: "Request Locations", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
      
       {title: "Journey Code", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:180}},//char width cManual_transport_amount
       {title: "Journey Created On", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:180}},//char width cManual_transport_amount
       {title: "Journey Start Time", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:180}},//char width cManual_transport_amount
       {title: "Journey End Time", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:180}},//char width cManual_transport_amount
        {title: "Journey Schedule Start Time", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:180}},//char width cManual_transport_amount
        {title: "Journey Schedule End Time",style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}} },fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:180}},//pixels width 
       
        {title: "Journey Start Meter Reading", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:180}},//char width cManual_transport_amount
        {title: "Journey End Meter Reading", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:180}},//char width cManual_transport_amount
        {title: "Running KM’s", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:180}},//char width cManual_transport_amount
        {title: "Journey Locations", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:180}},//char width cManual_transport_amount
        // Sec 02
        {title: "Driver Name",style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}} },fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:180}},//pixels width 
        {title: "Driver Contact No",style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width   
       // Sec03
       {title: "Vehicle Request Status",style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width   
       {title: "Vehicle Registation Number",style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width   
       {title: "Vehicle Brand",style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width   
       {title: "Vehicle Model",style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width   
       {title: "Vehicle Owner Name",style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width   
       {title: "Vehicle  Code",style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width   
       {title: "Vehicle  Region",style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width   
        
        
          // Sec 04
        {title: "Fixed Username", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:150}},//char width cManual_transport_amount
       // {title: "Fixuser User ID", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:150}},//char width cManual_transport_amount
        {title: "Requested Duration", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:150}},//char width cManual_transport_amount
        {title: "Passenger Count", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:150}},//char width cManual_transport_amount
        {title: "Job Type", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
        {title: "Assign To", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:150}},//char width cManual_transport_amount
       
        {title: "Passenger Name", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:150}},//char width cManual_transport_amount
        {title: "Passenger Mobile No", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:150}},//char width cManual_transport_amount
         {title: "Requested User", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:150}},//char width cManual_transport_amount
        
    
        // Sec 05
        {title: "Requested Email", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
        {title: "Requested Mobile No", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
        {title: "Canceled User", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
        {title: "Canceled Time", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
        {title: "Vehicle Category", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
        {title: "Vehicle Sub Type", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
        {title: "Vehicle Type", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
        
        
        
    ],
    data:dataSet1?.map((data)=>[
    
      // Sec 01
      {value:data.vehicleRequestCode ,style: { font: { sz: "12" } }},
      {value:data.createdOn?moment((data.createdOn)).format('YYYY-MM-DD HH:mm:ss'):("Null") ,style: { font: { sz: "12" } }},
   //   {value:data.journeyId?(data.journeyId):("Null") ,style: { font: { sz: "12" } }},
      {value:data.jobTime?moment((data.jobTime)).format('YYYY-MM-DD HH:mm:ss'):("Null") ,style: { font: { sz: "12" } }},  
     
      {value:data.vehicleRequestLocations ?(data.vehicleRequestLocations ):("Null") ,style: { font: { sz: "12" } }},
      {value:data.journeyCode?(data.journeyCode):("Null") ,style: { font: { sz: "12" } }},
      {value:data.journeyCreatedOn?moment((data.journeyCreatedOn)).format('YYYY-MM-DD HH:mm:ss'):("Null") ,style: { font: { sz: "12" } }},
      {value:data.journeyStartTime?moment((data.journeyStartTime)).format('YYYY-MM-DD HH:mm:ss'):("Null") ,style: { font: { sz: "12" } }},
      {value:data.journeyEndTime?moment((data.journeyEndTime)).format('YYYY-MM-DD HH:mm:ss'):("Null") ,style: { font: { sz: "12" } }},
      {value:data.journey_schedule_start_time?moment((data.journey_schedule_start_time)).format('YYYY-MM-DD HH:mm:ss'):("Null") ,style: { font: { sz: "12" } }},
      {value:data.journey_schedule_end_time? moment((data.journey_schedule_end_time)).format('YYYY-MM-DD HH:mm:ss'):("Null") ,style: { font: { sz: "12" } }},
     
      {value:data.journey_start_meter_reading?(data.journey_start_meter_reading):(0) ,style: { font: { sz: "12" } }}, 
      {value:data.journey_end_meter_reading?(data.journey_end_meter_reading):(0) ,style: { font: { sz: "12" } }}, 
      {value:data.journey_end_meter_reading?(data.journey_end_meter_reading - data.journey_start_meter_reading):(0) ,style: { font: { sz: "12" } }}, 
      {value:data.journeyRequestLocations?(data.journeyRequestLocations):(0) ,style: { font: { sz: "12" } }}, 
      
      // Sec 2
      {value:data.driverName?(data.driverName):("Null") ,style: { font: { sz: "12" } }},
      {value:data.driverContactNo?(data.driverContactNo):("Null") ,style: { font: { sz: "12" } }},
  
      // Sec 03
      {value:data.vehicle_request_status?(data.vehicle_request_status):("Null") ,style: { font: { sz: "12" } }},
      {value:data.vehicle_registation_number?(data.vehicle_registation_number):("Null") ,style: { font: { sz: "12" } }},
      {value:data.vehicleBrand?(data.vehicleBrand):("Null") ,style: { font: { sz: "12" } }},
      {value:data.vehicleModel?(data.vehicleModel):("Null") ,style: { font: { sz: "12" } }},
      {value:data.vehicleOwnerName?(data.vehicleOwnerName):("Null") ,style: { font: { sz: "12" } }},
      {value:data.vehicleMasterCode?(data.vehicleMasterCode):("Null") ,style: { font: { sz: "12" } }},
      {value:data.vehicleMasterRegion?(data.vehicleMasterRegion):("Null") ,style: { font: { sz: "12" } }},
         
      // Sec 04
     
      {value:data.fixuserUsername?(data.fixuserUsername):("Null") ,style: { font: { sz: "12" } }},
     // {value:data.fixuserUserId?(data.fixuserUserId):(0) ,style: { font: { sz: "12" } }},
      {value:data.requestedDuration?(data.requestedDuration):(0) ,style: { font: { sz: "12" } }},
      {value:data.passengerCount?(data.passengerCount):(0) ,style: { font: { sz: "12" } }},
      {value:data.jobTypeName?(data.jobTypeName):(0) ,style: { font: { sz: "12" } }},
      {value:data.assignTo?(data.assignTo):("Null") ,style: { font: { sz: "12" } }},
      
      {value:data.passengerName?(data.passengerName):("Null") ,style: { font: { sz: "12" } }},
      {value:data.passengerMobileNo?(data.passengerMobileNo):("Null") ,style: { font: { sz: "12" } }},
      {value:data.requestUsername?(data.requestUsername):("Null") ,style: { font: { sz: "12" } }},
    
    
      // Sec 05
      {value:data.email?(data.email):("Yes"),style: { font: { sz: "12" } }}, 
      {value:data.mobileNo?(data.mobileNo):("Null"),style: { font: { sz: "12" } }}, 
      {value:data.canceledUser?(data.canceledUser):("Null"),style: { font: { sz: "12" } }}, 
      {value:data.canceledTime?(data.canceledTime):("Null"),style: { font: { sz: "12" } }}, 
      {value:data.vehicleCategory?(data.vehicleCategory):("Null"),style: { font: { sz: "12" } }}, 
      {value:data.vehicle_sub_type_name?(data.vehicle_sub_type_name):("Null"),style: { font: { sz: "12" } }}, 
      {value:data.vehicleTypeName?(data.vehicleTypeName):("Null"),style: { font: { sz: "12" } }}, 
     
     
    
     
   
    
       
    ]),
    }
        ];
    
        const columns = [
          {
            title: "Request Code",
            dataIndex: "vehicleRequestCode",
            key: "vehicleRequestCode",
            render: (item) => <span>{item}</span>,
          },
          {
            title: "Created On",
            dataIndex: "createdOn",
            key: "createdOn",
            render: (item) => <span>{moment(item).format('YYYY-MM-DD HH:mm:ss')}</span>,
          },
          {
            title: "Journey Code",
            dataIndex: "journeyCode",
            key: "journeyCode",
            render: (item) => <span>{item}</span>,
          },
          {
            title: "Driver Name",
            dataIndex: "driverName",
            key: "driverName",
            render: (item) => <span>{item}</span>,

          },
          {
            title: "Vehicle Number",
            dataIndex: "vehicle_registation_number",
            key: "vehicle_registation_number",
            render: (item) => <span>{item}</span>,
          },
        ];
        const timestamp = Date.now();
        return (
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-12">
                <div class="page-title-box">
                  <div class="row">
                    <div class="col">
                      <h4 class="page-title">Vehicle Full Export</h4>
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Data Export </a></li>
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Vehicle Full Export</a></li>

                      </ol>
                    </div>
                    <div class="col-auto align-self-center">
                      <a href="#" class="btn btn-sm btn-outline-primary" id="Dash_Date">
                        <span class="day-name" id="Day_Name">Today:</span>&nbsp;
                        <span class="" id="Select_date">{new Date().toUTCString().slice(5, 16)}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar align-self-center icon-xs ms-1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                      </a>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div className="row">
            <div className="col-md-3 col-lg-3 col-xl-3">
              <DatePicker
                placeholder="Start Date"
                style={{ width: "100%" }}
                onChange={this.onChangeDate}
              />
            </div>
            <div className="col-md-3 col-lg-3 col-xl-3">
              <DatePicker
                placeholder="End Date"
                style={{ width: "100%" }}
                onChange={this.onChangeDate2}
              />
            </div>
            <div className="col-md-6 col-lg-6 col-xl-6">
              <button
                onClick={this.searchClick}
                class="btn btn-primary"
                //style={{ float: "right" }}
              >
                <i class="fas fa-search"> </i> Search
              </button>
            </div>
            <br/><br/>
            <div className="col-lg-12 col-xl-12">
              <div className="card">
                <div className="card-header">
                  <div className="row ">
                    <div className="col">
                      <h4 className="card-title">
                        Report -Vehicle Full export{" "}
                        <ExcelFile
                          filename={"Vehicle Full export -" + timestamp}
                          element={
                            <button
                              disabled={this.state.buttonLodading}  
                              class="btn btn-primary"
                              style={{ float: "right" }}
                            >
                              <i class="fas fa-file-export"> </i> {this.state.buttonLodading?(<img width={20} src={'https://media.tenor.com/wpSo-8CrXqUAAAAj/loading-loading-forever.gif'}></img> ):('')} Vehicle Full Export
                            </button>
                          }
                        >
                         
                          <ExcelSheet dataSet={StatisticDataSet} name="Vehicle Full Export"/>
                        </ExcelFile>
                      </h4>
                      <div></div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
               
                  <Table
                    size="small"
                    columns={columns}
                    // rowKey="id"
                    loading={this.state.loading}
                    dataSource={this.state.data_list}
                  />
        
                </div>
              </div>
            </div>
          </div>
          </div>
        );
      }
}
function mapStateToProps(state) {
  //console.log("state", state);
  return {
    organization_count: state.organization.length,
    regions: state.regions,
    depots: state.depots,
    sites_count: state.sites.length,
    vendor_count: state.vendor.length,
    roles_count: state.roles.length,
  };
}
export default  withRouter(connect(mapStateToProps, { getDonationCategories, getData })(
    VehicleFullExport
)); 
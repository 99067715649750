import React, { Component } from 'react'
import { Select, DatePicker, Table, Image, Avatar, List } from 'antd';

import { Link } from "react-router-dom";
import componentsimg from "../../icon/components-svgrepo-com.svg";
import detailsView from './view'
import GeneratorDetails from '../../components/image-capture/image-details'


import subCompomentd from "../../icon/sub-compomentd.svg";
import { getLastThreeRequestDetails, getUserSites } from "../../api/index";
import Modal from 'react-modal';

import { THE_FAMILY_ID } from '@azure/msal-common/dist/utils/Constants';
import Item from 'antd/lib/list/Item';
import ReactLoading from 'react-loading';
import { tupleNum } from 'antd/lib/_util/type';




class ImageView extends Component {

    constructor(props) {
        super(props);
        this.state = {

            loading: false,
            showModal: false,
            allList: null,
            allSite_loading: true,
            allSite: [],
            selectSiteId: null,
            selectSiteName: null,
            selectComptName: null,
            comptId: null,
            selectImagesDetails: null,
            compList: [
                { "id": "1", "description": "19\" Rack ", "name": "19\" Rack " },
                { "id": "2", "description": "Access Antenna", "name": "Access Antenna" },
                { "id": "3", "description": "Access Equipment", "name": "Access Equipment" },
                { "id": "4", "description": "ACDB", "name": "ACDB" },
                { "id": "5", "description": "ATS ", "name": "ATS " },
                { "id": "6", "description": "Battery ", "name": "Battery " },
                { "id": "7", "description": "Combine & Filters ", "name": "Combine & Filters " },
                { "id": "8", "description": "Bulkhead", "name": "Bulkhead" },
                { "id": "9", "description": "Cooling System", "name": "Cooling System" },
                { "id": "10", "description": "Franklin Rod", "name": "Franklin Rod" },
                { "id": "11", "description": "SPD ", "name": "SPD " },
                { "id": "12", "description": "Generator ", "name": "Generator " },
                { "id": "13", "description": "MW Link ", "name": "MW Link " },
                { "id": "14", "description": "RRU ", "name": "RRU " },
                { "id": "15", "description": "Transport Equipment ", "name": "Transport Equipment " },
                { "id": "16", "description": "Tower Light", "name": "Tower Light" },
                { "id": "17", "description": "Rectifier", "name": "Rectifier" },
                { "id": "18", "description": "Tower", "name": "Tower" },
                { "id": "19", "description": "Cabin\/Cabinets", "name": "Cabin\/Cabinets" }
            ]
        };
        this.handleCloseFullModal = this.handleCloseFullModal.bind(this);

    }
    handleCloseFullModal() {
        this.setState({ showModal: false });
    }
    componentDidMount() {
        this.getDataSitesByuser();
        // this.getDataLastThreeRequestDetails()
    }
    getDataLastThreeRequestDetails = async () => {

        //console.log("getLastThreeRequestDetails");
        if (this.state.selectSiteId != null) {
            this.setState({
                loading: true

            })
            var setData = {
                "siteId": this.state.selectSiteId,
                "componentId": this.state.comptId
            }

            var res = await getLastThreeRequestDetails(setData);
            console.log("getLastThreeRequestDetails");
            console.log(res);
            if (res.success) {
                this.setState({
                    loading: false,
                    allList: res.data

                })


            }

        } else {
            alert('Please select a Site')
        }

    };
    getDataSitesByuser = async () => {

        var res = await getUserSites();
        //console.log("getUserSites");
        //console.log(res.data);
        if (res.success) {
            this.setState({
                allSite_loading: false,
                allSite: res.data,
            });
        }
    };
    onChangeUserSites = (value) => {
        //console.log("onChangeUserSites");
        //console.log(value);
        var siteName = this.state.allSite.filter(person => person.id == value)

        this.setState({
            selectSiteId: value,
            selectSiteName: siteName[0].siteName

        }, () => {
            this.getDataLastThreeRequestDetails();
        })

    };

    onChangeAllCopmt() {

        this.setState({
            comptId: null,
            selectComptName: null

        }, () => {
            this.getDataLastThreeRequestDetails();
        })

    }
    onChangeAllDetails(item) {

        //console.log(item);
        this.setState({
            selectImagesDetails: item
        }, () => {
            this.setState({
                showModal: true
            })
        })

    }
    onChangeCopmt(id, name) {
        console.log(id);

        // if (this.state.selectSiteId != null) {
        //     this.setState({
        //         comptId: id,
        //         selectComptName: name

        //     }, () => {
        //         this.getDataLastThreeRequestDetails();
        //     })
        //     window.scrollTo(0, 0)

        // } else {

        //     if (!alert('Please select a Site !')) { window.location.reload(); }
        // }


    }

    clearBtnClick = () => {
        window.location.reload()
    }
    render() {
        const { Option } = Select;
        const data = [
            {
                title: 'Ant Design Title 1',
            },
            {
                title: 'Ant Design Title 2',
            },
            {
                title: 'Ant Design Title 3',
            },
            {
                title: 'Ant Design Title 4',
            },
        ];
        return (
            <div>
                <div class="row" id='home'>
                    <div class="col">
                        <h4 class="page-title">Image Capture</h4>
                    </div>
                    <div class="col-auto align-self-center">
                        <a href="#" class="btn btn-sm btn-outline-primary" id="Dash_Date">
                            <span class="ay-name" id="Day_Name">Today:</span>&nbsp;
                            <span class="" id="Select_date">Jan 19</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar align-self-center icon-xs ms-1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                        </a>

                    </div>
                </div>
                <br></br>
                <div class="row">
                    <div class="col-lg-3">
                        <div class="card">
                            <div class="card-header">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <h4 class="card-title">Component</h4>
                                    </div>

                                </div>
                            </div>
                            <div class="card-body">
                                <div class="files-nav">
                                    <div class="nav flex-column nav-pills" aria-orientation="vertical">
                                        <a onClick={() => this.onChangeAllCopmt()} class="nav-link active " data-bs-toggle="pill" >

                                            <i class="fas fa-regular fa-border-all" style={{ paddingRight: 25 }}></i>

                                            <div class="d-inline-block align-self-center">
                                                <h5 class="m-0">All Component </h5>

                                            </div>
                                        </a>
                                        <hr></hr>
                                        {this.state.compList.map((item, index) => {
                                            return <a href='#home' role='tab' onClick={() => this.onChangeCopmt(item.id, item.name)} class="nav-link" data-bs-toggle="pill" aria-selected="true">


                                                <span class="mdi mdi-arrow-right-drop-circle  float-end" style={this.state.comptId == item.id ? ({ paddingRight: 8, color: 'red', paddingLeft: 8 }) : ({ paddingRight: 8, color: '#03d87f' })}> </span>
                                                <div class="d-inline-block align-self-center">
                                                    <h5 class="m-0" style={this.state.comptId == item.id ? ({ color: '#1761fd' }) : ({})}> {item.name}</h5>

                                                </div>
                                            </a>
                                        })}



                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div className='col-lg-9'>


                        <div className='row'>
                            <div class="">
                                <div class="tab-content" id="files-tabContent">

                                    <div class="tab-pane fade show active" >

                                        <div class="row">
                                            <div class="col">

                                                <ol class="breadcrumb">
                                                    {this.state.selectSiteName = null ? (<li class="breadcrumb-item"><a href="javascript:void(0);">DNS-SA20221028122241</a></li>) : (<li class="breadcrumb-item"><a href="javascript:void(0);">DNS-SA20221028122241</a></li>)}
                                                    {this.state.selectComptName = null ? (<li class="breadcrumb-item"><a href="javascript:void(0);">Test_Site_2</a></li>) : (<li class="breadcrumb-item"><a href="javascript:void(0);">Test_Site_2</a></li>)}
                                                    {this.state.selectComptName = null ? (<li class="breadcrumb-item"><a href="javascript:void(0);">Access Antenna</a></li>) : (<li class="breadcrumb-item"><a href="javascript:void(0);">Access Antenna</a></li>)}


                                                </ol>
                                            </div>
                                            <div class="col-auto align-self-center">

                                            </div>
                                        </div>
                                        <div class="file-box-content">

                                            {this.state.loading ? (<div className='row' style={{ display: "flex", justifyContent: 'center' }} > <ReactLoading type={'spokes'} color={'#03d87f'} height={'10%'} width={'10%'} /></div>) : (<div>{this.state.allList != null ? (<div>{this.state.allList[0] ? (

                                                <div>
                                                    {this.state.allList.map((item, index) => {
                                                        return <a onClick={() => this.onChangeCopmt(item.id, item.name)}>
                                                            <div class="file-box" style={{ width: 156 }}>

                                                                <div class="text-center">
                                                                    {/* <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="grid-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-grid-2 fa-lg"><path fill="currentColor" d="M192 80c0-26.5-21.5-48-48-48H48C21.5 32 0 53.5 0 80v96c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48V80zm0 256c0-26.5-21.5-48-48-48H48c-26.5 0-48 21.5-48 48v96c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48V336zM256 80v96c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48H304c-26.5 0-48 21.5-48 48zM448 336c0-26.5-21.5-48-48-48H304c-26.5 0-48 21.5-48 48v96c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48V336z" class=""></path></svg> */}
                                                                    <img src={componentsimg} alt="Logo" style={{ width: 35 }} />

                                                                    <h6 class="text-truncate">{item.name}</h6>

                                                                </div>
                                                            </div>
                                                        </a>
                                                    })}
                                                </div>
                                            ) : (
                                                <div>
                                                    {this.state.allList?.imageFields[0] ? (<div>
                                                        <List
                                                            itemLayout="horizontal"
                                                            dataSource={this.state.allList?.imageFields}
                                                            renderItem={item => (
                                                                <List.Item>
                                                                    <List.Item.Meta
                                                                        avatar={<Avatar src="https://cdn-icons-png.flaticon.com/128/891/891058.png" />}
                                                                        title={<a onClick={() => this.onChangeAllDetails(item)} href="#">{item.name}</a>}
                                                                        description={'Image Count: ' + item.imageCount}
                                                                    />
                                                                </List.Item>
                                                            )}
                                                        />

                                                    </div>) : ('2')}

                                                </div>
                                            )}</div>) : (<div className='row'>
                                                <div class="col-lg-12">
                                                    <div class="card">
                                                        <div class="card-header">
                                                            <h4 class="card-title">Sub component 1</h4>
                                                            <p class="text-muted mb-0">You can use <code class="highlighter-rouge">.float-end</code> and <code class="highlighter-rouge">.float-start</code>
                                                                to give an image position.
                                                            </p>
                                                        </div>
                                                        <div class="card-body">
                                                        <img style={{ width: 150}} src={'https://media.bechtle.com/is/180712/1c4b3d4ee288fc9434f5175bf56070570/c3/gallery/da80bd11c7ea48e88b423f1d014f76e2?version=0'} alt="" class="rounded float-start"></img>
                                                        <img style={{ width: 150}} src={'https://media.bechtle.com/is/180712/1c4b3d4ee288fc9434f5175bf56070570/c3/gallery/da80bd11c7ea48e88b423f1d014f76e2?version=0'} alt="" class="rounded float-start"></img>
                                                        <img style={{ width: 150}} src={'https://media.bechtle.com/is/180712/1c4b3d4ee288fc9434f5175bf56070570/c3/gallery/da80bd11c7ea48e88b423f1d014f76e2?version=0'} alt="" class="rounded float-start"></img>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                    <div class="card">
                                                        <div class="card-header">
                                                            <h4 class="card-title">Sub component 2</h4>
                                                            <p class="text-muted mb-0">You can use <code class="highlighter-rouge">.float-end</code> and <code class="highlighter-rouge">.float-start</code>
                                                                to give an image position.
                                                            </p>
                                                        </div>
                                                        <div class="card-body">
                                                        <img style={{ width: 150}} src={'https://media.bechtle.com/is/180712/1c4b3d4ee288fc9434f5175bf56070570/c3/gallery/da80bd11c7ea48e88b423f1d014f76e2?version=0'} alt="" class="rounded float-start"></img>
                                                        <img style={{ width: 150}} src={'https://media.bechtle.com/is/180712/1c4b3d4ee288fc9434f5175bf56070570/c3/gallery/da80bd11c7ea48e88b423f1d014f76e2?version=0'} alt="" class="rounded float-start"></img>
                                                        <img style={{ width: 150}} src={'https://media.bechtle.com/is/180712/1c4b3d4ee288fc9434f5175bf56070570/c3/gallery/da80bd11c7ea48e88b423f1d014f76e2?version=0'} alt="" class="rounded float-start"></img>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                    <div class="card">
                                                        <div class="card-header">
                                                            <h4 class="card-title">Sub component 3</h4>
                                                            <p class="text-muted mb-0">You can use <code class="highlighter-rouge">.float-end</code> and <code class="highlighter-rouge">.float-start</code>
                                                                to give an image position.
                                                            </p>
                                                        </div>
                                                        <div class="card-body">
                                                        <img style={{ width: 150}} src={'https://media.bechtle.com/is/180712/1c4b3d4ee288fc9434f5175bf56070570/c3/gallery/da80bd11c7ea48e88b423f1d014f76e2?version=0'} alt="" class="rounded float-start"></img>
                                                        <img style={{ width: 150}} src={'https://media.bechtle.com/is/180712/1c4b3d4ee288fc9434f5175bf56070570/c3/gallery/da80bd11c7ea48e88b423f1d014f76e2?version=0'} alt="" class="rounded float-start"></img>
                                                        <img style={{ width: 150}} src={'https://media.bechtle.com/is/180712/1c4b3d4ee288fc9434f5175bf56070570/c3/gallery/da80bd11c7ea48e88b423f1d014f76e2?version=0'} alt="" class="rounded float-start"></img>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>)} </div>)}







                                            </div>



                                    </div>






                                    </div>
                                </div>
                            </div>

                        </div>


                    </div>

                    {/* modal start */}
                    <Modal

                        isOpen={this.state.showModal}
                        contentLabel="Minimal Modal Example"
                        style={{
                            overlay: {

                                zIndex: 10000
                            },
                            content: {
                                right: '15%',
                                left: '15%',
                                top: '9px',
                                bottom: '9px'
                            }
                        }}
                    //    className="modal"
                    >
                        <div class="col-lg-12 mx-auto">


                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Image List:</h4>
                                </div>
                                <div className="card-body">
                                    <div class="row">
                                        <GeneratorDetails details={this.state.selectImagesDetails} siteName1={this.state.selectSiteName} comptName={this.state.selectComptName} />




                                    </div>



                                </div>
                            </div>








                            <div class="row d-flex justify-content-center">

                                <div class="col-lg-12">
                                    <div class="float-end d-print-none">

                                        <button class="btn btn-primary btn-sm" onClick={this.handleCloseFullModal} >Close</button>
                                    </div>
                                </div>
                            </div>


                        </div>


                    </Modal>

                    {/* modal end */}


                </div>
                )
    }
}
                export default ImageView;


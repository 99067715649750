import React, { Component } from 'react'
import { Table, Tag, Button, Drawer, Form, Input, Select, InputNumber, DatePicker, Popconfirm, message, Collapse } from 'antd';

import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import SimpleReactValidator from "simple-react-validator";

import { createNewReSoler, getReSolerDetails, UpdateReSoler, dataUpdateGeneralDetails } from "../../api/index";
import moment from 'moment';
import { checkServiceType } from "../../actions/service-type";
import AssetDelete from "../site_components/asset_delete";


class Res extends Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();

        this.state = {
            title: "",
            tabaleLoading: false,
            open: false,
            actionView: false,
            resAverageEnergyGenerationFromSolar: '',
            resConverterInverterQuantity: '',
            resEquipmentModel: '',
            resInstalledLocation: '',
            resInverterConverterModel: null,
            resInverterType: '',
            NonShadingSpaceAvailabilityOnGround: '',
            NonShadingSpaceAvailabilityOnShelter: '',
            NoofDifferentSolarPanelModels: '',
            resPanelCountOnRoofTop: '',
            resPanelCountOnGround: '',
            resSolarPanelInstalledOverTheOutdoorCabinet: '',
            resPerConverterInverterCapacity: '',
            resShelterDirectionFromTheTower: '',
            resNonShadingSpaceDirectionFromTheTower: '',
            resPvcapacity: '',
            resPvmake: '',
            resRmsconnectionInband: '',
            resCommissionedDate: null,
            resLastMaintenanceDate: null,
            resRmsconnectionMode: '',
            openViewMoreDetails: false,
            selectedElement: "",
            rectifier: [],
            selectedRectifierList: [],
            selectedInverterConverterModelId: null,
            solarPanelModel: null,
            selectedSolarPanelModelId: null,
            AssetManagementPermission: false,
            switchStatus: true,
            objectSet: {
                "resNoOfDifferentSolarPanelModels": 0,
                "resSolarExpectedOutput": 0,
                "nonShadingSpaceAvailabilityOnShelter": 0,
                "nonShadingSpaceAvailabilityOnGround": 0,
                "shelterDirectionFromTheTower": null,
                "nonShadingSpaceDirectionFromTheTower": null,
            },

            dropdownObjSolar : {
                "resShelterDirectionFromTheTower": "",
                "resNonShadingSpaceDirectionFromTheTower" : "",
                "resPvmake" : "",  
                "resInstalledLocation" : "",
                "resSolarPanelInstalledOverTheOutdoorCabinet": "",
                "resInverterType" : "",
                "resConverterInverterQuantity": "",
                "resRmsconnectionInband": "",
            },

        };
        this.requestFromClose = this.requestFromClose.bind(this);
        this.handleAverageEnergyGenerationFromSolar = this.handleAverageEnergyGenerationFromSolar.bind(this);
        this.handleConverterInverterQuantity = this.handleConverterInverterQuantity.bind(this);
        this.handleEquipmentModel = this.handleEquipmentModel.bind(this);
        this.handleInstalledLocation = this.handleInstalledLocation.bind(this);
        this.handleInverterType = this.handleInverterType.bind(this);
        this.handleNonShadingSpaceAvailabilityOnGround = this.handleNonShadingSpaceAvailabilityOnGround.bind(this);
        this.handleNonShadingSpaceAvailabilityOnShelter = this.handleNonShadingSpaceAvailabilityOnShelter.bind(this);
        this.handleNoofDifferentSolarPanelModels = this.handleNoofDifferentSolarPanelModels.bind(this);
        this.handlePanelCountOnRoofTop = this.handlePanelCountOnRoofTop.bind(this);
        this.handlePanelCountOnGround = this.handlePanelCountOnGround.bind(this);
        this.handleSolarPanelInstalledOverTheOutdoorCabinet = this.handleSolarPanelInstalledOverTheOutdoorCabinet.bind(this);
        this.handlePerPanelCapacity = this.handlePerPanelCapacity.bind(this);
        this.handlePerConverterInverterCapacity = this.handlePerConverterInverterCapacity.bind(this);
        this.handleShelterDirectionFromTheTower = this.handleShelterDirectionFromTheTower.bind(this);
        this.handleNonShadingSpaceDirectionFromTheTower = this.handleNonShadingSpaceDirectionFromTheTower.bind(this);
        this.handlePvcapacity = this.handlePvcapacity.bind(this);
        this.handlePvmake = this.handlePvmake.bind(this);
        this.handleRmsconnectionInband = this.handleRmsconnectionInband.bind(this);
        this.handleRmsconnectionMode = this.handleRmsconnectionMode.bind(this);
    }
    handleRmsconnectionMode(e) {
        this.setState({ resRmsconnectionMode: e});
    }
    handleRmsconnectionInband(e) {
        this.setState({ resRmsconnectionInband: e });
    }
    handlePvmake(e) {
        this.setState({ resPvmake: e });
    }
    handlePvcapacity(e) {
        console.log(e)
        this.setState({ resPvcapacity: e });
    }
    handleNonShadingSpaceDirectionFromTheTower(e) {
        this.setState({ resNonShadingSpaceDirectionFromTheTower: e });
    }
    handleShelterDirectionFromTheTower(e) {
        this.setState({ resShelterDirectionFromTheTower: e });
    }
    handlePerConverterInverterCapacity(e) {
        console.log(e.target.value)
        this.setState({ resPerConverterInverterCapacity: e.target.value });
    }
    handlePerPanelCapacity(e) {
        console.log(e.target.value)
        this.setState({ resPerPanelCapacity: e.target.value });
    }
    handleSolarPanelInstalledOverTheOutdoorCabinet(e) {
        this.setState({ resSolarPanelInstalledOverTheOutdoorCabinet: e });
    }
    handlePanelCountOnGround(e) {
        console.log(e)
        this.setState({ resPanelCountOnGround: e });
    }
    handlePanelCountOnRoofTop(e) {
        console.log(e)
        this.setState({ resPanelCountOnRoofTop: e });
    }
    handleNoofDifferentSolarPanelModels(e) {
        console.log(e.target.value)
        this.setState({ NoofDifferentSolarPanelModels: e.target.value });
    }
    // handleNonShadingSpaceAvailabilityOnShelter(e) {
    //     this.setState({ NonShadingSpaceAvailabilityOnShelter: e });
    // }
    handleNonShadingSpaceAvailabilityOnGround(e) {
        console.log(e)
        this.setState({ NonShadingSpaceAvailabilityOnGround: e });
    }
    handleInverterType(e) {
        this.setState({ resInverterType: e });
    }
    handleInstalledLocation(e) {
        this.setState({ resInstalledLocation: e });
    }
    handleEquipmentModel(e) {
        console.log(e.target.value)
        this.setState({ resEquipmentModel: e.target.value });
    }
    handleConverterInverterQuantity(e) {
        console.log(e)
        this.setState({ resConverterInverterQuantity: e });
    }
    handleAverageEnergyGenerationFromSolar(e) {
        console.log(e)
        this.setState({ resAverageEnergyGenerationFromSolar: e });
    }
    onChangeLastMaintenanceDate = (date, dateString) => {
        console.log(date);
        console.log(dateString);
        this.setState({
            resLastMaintenanceDate: dateString,
        });
    };
    onChangeCommissionedDate = (date, dateString) => {
        console.log(date);
        console.log(dateString);
        this.setState({
            resCommissionedDate: dateString,
        });
    };
    onChangeRectifier = (value) => {
        var _tempList = [];
        value.map((e) => {
            _tempList.push({ "id": e })
        })
        this.setState({
            rectifier: _tempList
        })
    };
    onChangeSolarPanelModel = (value) => {
        this.setState({
            selectedSolarPanelModelId: value,
            solarPanelModel: { "id": value }
        })
    };
    onChangeResInverterConverterModel = (value) => {
        this.setState({
            selectedInverterConverterModelId: value,
            resInverterConverterModel: { "id": value }
        })
    };
    componentDidMount() {
        console.log('tytqwe')
        console.log(this.props.details)
        if (JSON.parse(localStorage.getItem('serviceTypeSet')) == null) {
            const { history } = this.props;

            history.push("/");

        } else {

            this.getSetPageData(JSON.parse(localStorage.getItem('serviceTypeSet')), 38)

        }
        checkServiceType(38).then((data) => this.setState({
            AssetManagementPermission: data
        }, () => {
        }),)
        this.setState({
            objectSet: {
                "resNoOfDifferentSolarPanelModels": this.props?.details.access_technologies_data?.resNoOfDifferentSolarPanelModels,
                "resSolarExpectedOutput": this.props?.details.access_technologies_data?.resSolarExpectedOutput,
                "nonShadingSpaceAvailabilityOnShelter": this.props?.details.access_technologies_data?.nonShadingSpaceAvailabilityOnShelter,
                "nonShadingSpaceAvailabilityOnGround": this.props?.details.access_technologies_data?.nonShadingSpaceAvailabilityOnGround,
                "shelterDirectionFromTheTower": this.props?.details.access_technologies_data?.shelterDirectionFromTheTower,
                "nonShadingSpaceDirectionFromTheTower": this.props?.details.access_technologies_data?.nonShadingSpaceDirectionFromTheTower,
            }
        })
        // console.log(this.props.details2)
    }
    getSetPageData = (serviceTypeIs, Pageid) => {

        if (serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == Pageid)[0] != null) {
            console.log('fddf');
            this.setState({
                actionView: true,
            })
        }

    };
    requestFromClose() {
        console.log('dsdsd')
        this.setState({ showModal: true, open: true });
    }
    ResUpdata(id) {
        // console.log('dfdfd')
        console.log(id)
        this.getDataReSolarsDetails(id);
        this.setState({
            openEdit: true,
        });
    }
    getDataReSolarsDetails = async (id) => {
        var res = await getReSolerDetails(id);
        console.log(res);
        if (res.success) {
            this.setState({
                RESId: res.data?.id,
                RESEdit: res.data,
                resAverageEnergyGenerationFromSolar: res.data?.averageEnergyGenerationFromSolar,
                resConverterInverterQuantity: res.data?.converterInverterQuantity,
                resEquipmentModel: res.data?.equipmentModel,
                resInstalledLocation: res.data?.installedLocation,
                resInverterType: res.data?.inverterType,
                resPerPanelCapacity: res.data?.perPanelCapacity,
                NonShadingSpaceAvailabilityOnGround: res.data?.nonShadingSpaceAvailabilityOnGround,
                NonShadingSpaceAvailabilityOnShelter: res.data?.nonShadingSpaceAvailabilityOnShelter,
                NoofDifferentSolarPanelModels: res.data?.noofDifferentSolarPanelModels,
                resPanelCountOnRoofTop: res.data?.panelCountOnRoofTop,
                resPanelCountOnGround: res.data?.panelCountOnGround,
                resSolarPanelInstalledOverTheOutdoorCabinet: res.data?.solarPanelInstalledOverTheOutdoorCabinet,
                resPerConverterInverterCapacity: res.data?.perConverterInverterCapacity,
                resShelterDirectionFromTheTower: res.data?.shelterDirectionFromTheTower,
                resNonShadingSpaceDirectionFromTheTower: res.data?.nonShadingSpaceDirectionFromTheTower,
                resPvcapacity: res.data?.pvcapacity,
                resPvmake: res.data?.pvmake,
                resRmsconnectionInband: res.data?.rmsconnectionInband,

                resRmsconnectionMode: res.data?.rmsconnectionMode,
                rectifier: res.data.rectifiers,
                solarPanelModel: res.data?.solarPanelModel,
                selectedSolarPanelModelId: res.data?.solarPanelModel?.id,
                inverterConverterModel: res.data?.inverterConverterModel,
                selectedInverterConverterModelId: res.data?.inverterConverterModel?.id,

            })
            if (res.data.rectifiers != null) {
                res.data.rectifiers.map((e) => this.state.selectedRectifierList.push(e.id))
            }
            if (res.data?.commissionedDate != null) {

                console.log('commissionedDate')
                console.log(res.data?.commissionedDate)

                this.setState({
                    resCommissionedDate: res.data?.commissionedDate
                })
            } else {

                this.setState({
                    resCommissionedDate: '',
                })
            }

            if (res.data?.lastMaintenanceDate != null) {

                this.setState({
                    resLastMaintenanceDate: res.data?.lastMaintenanceDate
                })
            } else {

                this.setState({
                    resLastMaintenanceDate: '',
                })
            }

        }
    }
    onDrawerClose = () => {
        console.log('sddsds')
        this.formRef.current.resetFields();
        this.validator.hideMessages();

        this.setState({
            open: false,
            openEdit: false,
            selectedAssetId: null,
            AssetType: [],
            selectedMakeId: null,
            makeType: [],
            fbcCapacity: '',
            selectedModelId: null,
            modelType: [],
            fbcCapacity: '',
            fbclastMaintainanceDate: null,
            fbcwarrantyExpireDate: null,
            fbcInstalledDate: null,
            soohandletenant: '',
            sharedPowerType: '',
            sooDcPowerCapacity: '',
            sooBillingMethod: '',
            sooGenPowerNoofPhases: '',
            sooCommercialPowerNoofPhases: '',
            sooCommercialPowerPerPhaseCapacity: '',
            sooGenPowerPerPhaseCapacity: '',
            sooBillingAmount: '',
            sooBillingStartDate: null,
            rectifier: [],
            selectedRectifierList: [],
            selectedInverterConverterModelId: null,
            solarPanelModel: null,
            selectedSolarPanelModelId: null,
            resInverterConverterModel: null,
            resAverageEnergyGenerationFromSolar: '',
            resConverterInverterQuantity: '',
            resEquipmentModel: '',
            resInstalledLocation: '',

            resInverterType: '',
            NonShadingSpaceAvailabilityOnGround: '',
            NonShadingSpaceAvailabilityOnShelter: '',
            NoofDifferentSolarPanelModels: '',
            resPanelCountOnRoofTop: '',
            resPanelCountOnGround: '',
            resSolarPanelInstalledOverTheOutdoorCabinet: '',
            resPerConverterInverterCapacity: '',
            resShelterDirectionFromTheTower: '',
            resNonShadingSpaceDirectionFromTheTower: '',
            resPvcapacity: '',
            resPvmake: '',
            resRmsconnectionInband: '',
            resCommissionedDate: null,
            resLastMaintenanceDate: null,
            resRmsconnectionMode: '',
            openViewMoreDetails: false,
            selectedElement: "",




        })

    }
    AllDataSubmitUpdate = async () => {
        if (this.validator.allValid()) {
            var dataSet;
            dataSet = {
                "id": this.state.RESId,
                "site": { "id": this.props?.detailsSite?.site_details.id },
                "asset": {
                    "id": 2
                },
                // "noofDifferentSolarPanelModels": this.state.NoofDifferentSolarPanelModels,
                "equipmentModel": this.state.resEquipmentModel,
                "commissionedDate": this.state.resCommissionedDate,
                "panelCountOnRoofTop": this.state.resPanelCountOnRoofTop,
                "panelCountOnGround": this.state.resPanelCountOnGround,
                "installedLocation": this.state.resInstalledLocation,
                "solarPanelInstalledOverTheOutdoorCabinet": this.state.resSolarPanelInstalledOverTheOutdoorCabinet,
                // "perPanelCapacity": this.state.resPerPanelCapacity,
                "inverterType": this.state.resInverterType,
                // "perConverterInverterCapacity": this.state.resPerConverterInverterCapacity,
                "converterInverterQuantity": this.state.resConverterInverterQuantity,
                "lastMaintenanceDate": this.state.resLastMaintenanceDate,
                "nonShadingSpaceAvailabilityOnShelter": this.state.NonShadingSpaceAvailabilityOnShelter,
                "nonShadingSpaceAvailabilityOnGround": this.state.NonShadingSpaceAvailabilityOnGround,
                "shelterDirectionFromTheTower": this.state.resShelterDirectionFromTheTower,
                "nonShadingSpaceDirectionFromTheTower": this.state.resNonShadingSpaceDirectionFromTheTower,
                "averageEnergyGenerationFromSolar": this.state.resAverageEnergyGenerationFromSolar,
                "pvcapacity": this.state.resPvcapacity,
                "pvmake": this.state.resPvmake,
                "rmsconnectionInband": this.state.resRmsconnectionInband,
                "rmsconnectionMode": this.state.resRmsconnectionMode,
                "rectifiers": this.state.rectifier,
                "inverterConverterModel": this.state.resInverterConverterModel,
                "solarPanelModel": this.state.solarPanelModel
            }
            console.log(dataSet)
            var res = await UpdateReSoler(dataSet);
            console.log(res)
            if (res.success) {
                message.success('Successfully Renewable Energy Solar Edited!');
                this.formRef.current.resetFields();
                this.setState({
                    showModal: false,
                    open: false,
                    openEdit: false,

                    resAverageEnergyGenerationFromSolar: '',
                    resConverterInverterQuantity: '',
                    resEquipmentModel: '',
                    resInstalledLocation: '',
                    resInverterConverterModel: null,
                    resInverterType: '',
                    NonShadingSpaceAvailabilityOnGround: '',
                    NonShadingSpaceAvailabilityOnShelter: '',
                    NoofDifferentSolarPanelModels: '',
                    resPanelCountOnRoofTop: '',
                    resPanelCountOnGround: '',
                    resSolarPanelInstalledOverTheOutdoorCabinet: '',
                    resPerConverterInverterCapacity: '',
                    resShelterDirectionFromTheTower: '',
                    resNonShadingSpaceDirectionFromTheTower: '',
                    resPvcapacity: '',
                    resPvmake: '',
                    resRmsconnectionInband: '',
                    resCommissionedDate: null,
                    resLastMaintenanceDate: null,
                    resRmsconnectionMode: '',
                    rectifier: [],
                    selectedRectifierList: [],
                    selectedInverterConverterModelId: null,
                    solarPanelModel: null,
                    selectedSolarPanelModelId: null
                })
                this.props.parentMethod();
            } else {
                message.warning(' Something went wrong!')
            }
        } else {

            console.log(this.validator)
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }

    }
    AllDataSubmit = async () => {
        if (this.validator.allValid()) {
            var dataSet;

            dataSet = {

                "site": { "id": this.props?.detailsSite?.site_details.id },
                "asset": {
                    "id": 2
                },
                // "noofDifferentSolarPanelModels": this.state.NoofDifferentSolarPanelModels,
                "equipmentModel": this.state.resEquipmentModel,
                "commissionedDate": this.state.resCommissionedDate,
                "panelCountOnRoofTop": this.state.resPanelCountOnRoofTop,
                "panelCountOnGround": this.state.resPanelCountOnGround,
                "installedLocation": this.state.resInstalledLocation,
                "solarPanelInstalledOverTheOutdoorCabinet": this.state.resSolarPanelInstalledOverTheOutdoorCabinet,
                // "perPanelCapacity": this.state.resPerPanelCapacity,
                "inverterType": this.state.resInverterType,
                "converterInverterQuantity": this.state.resConverterInverterQuantity,
                "lastMaintenanceDate": this.state.resLastMaintenanceDate,
                //"nonShadingSpaceAvailabilityOnShelter": this.state.NonShadingSpaceAvailabilityOnShelter,
                //"nonShadingSpaceAvailabilityOnGround": this.state.NonShadingSpaceAvailabilityOnGround,
                //"shelterDirectionFromTheTower": this.state.resShelterDirectionFromTheTower,
                //"nonShadingSpaceDirectionFromTheTower": this.state.resNonShadingSpaceDirectionFromTheTower,
                "averageEnergyGenerationFromSolar": this.state.resAverageEnergyGenerationFromSolar,
                "pvcapacity": this.state.resPvcapacity,
                "pvmake": this.state.resPvmake,
                "rmsconnectionInband": this.state.resRmsconnectionInband,
                "rmsconnectionMode": this.state.resRmsconnectionMode,
                "rectifiers": this.state.rectifier,
                "inverterConverterModel": this.state.resInverterConverterModel,
                "solarPanelModel": this.state.solarPanelModel

            }
            console.log(dataSet)
            var res = await createNewReSoler(dataSet);
            console.log(res)
            if (res.success) {
                message.success('Successfully Renewable Energy Solar Added!');
                this.setState({
                    showModal: false,
                    open: false,

                    resAverageEnergyGenerationFromSolar: '',
                    resConverterInverterQuantity: '',
                    resEquipmentModel: '',
                    resInstalledLocation: '',
                    resInverterConverterModel: null,
                    resInverterType: '',
                    NonShadingSpaceAvailabilityOnGround: '',
                    NonShadingSpaceAvailabilityOnShelter: '',
                    NoofDifferentSolarPanelModels: '',
                    resPanelCountOnRoofTop: '',
                    resPanelCountOnGround: '',
                    resSolarPanelInstalledOverTheOutdoorCabinet: '',
                    resPerConverterInverterCapacity: '',
                    resShelterDirectionFromTheTower: '',
                    resNonShadingSpaceDirectionFromTheTower: '',
                    resPvcapacity: '',
                    resPvmake: '',
                    resRmsconnectionInband: '',
                    resCommissionedDate: null,
                    resLastMaintenanceDate: null,
                    resRmsconnectionMode: '',
                    rectifier: [],
                    selectedRectifierList: [],
                    selectedInverterConverterModelId: null,
                    solarPanelModel: null,
                    selectedSolarPanelModelId: null
                })
                this.props.parentMethod();
            } else {
                message.warning(' Something went wrong!')
            }
        } else {

            console.log(this.validator)
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    }

    viewMoreDetails(element) {
        this.setState({
            showModal2: true,
            openViewMoreDetails: true,
            selectedElement: element
        });
    }

    onViewMoreDetailsDrawerClose = () => {
        this.setState({
            showModal2: false,
            openViewMoreDetails: false,
        })
    }

    validatedTextLable = (field) => {
        return (field !== null && field !== '')
            ? <span class="badge badge-outline-secondary text-muted">{(field)}</span>
            : <span class="badge text-danger">Currently Not Set</span>;
    }
    submitForm = async (item) => {
        this.getDataDetailsForDelete(item);

    }
    getDataDetailsForDelete = async (id) => {

        var res = await getReSolerDetails(id);
        console.log(res);
        if (res.success) {
            var respons = AssetDelete.Action(res.data, this.props?.detailsSite?.site_details.id, UpdateReSoler)

            respons.then((value) => {
                if (value == 1) {
                    message.success('Successfully Delete!')
                    this.props.parentMethod();
                } else {
                    message.warning(' Something went wrong!')
                }


            });



        }
    }

    handlresSolarExpectedOutput = (e) => {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                resSolarExpectedOutput: e
            }

        }, () => {
            console.log(this.state.objectSet)
        })

    }

    handlresNoOfDifferentSolarPanelModels = (e) => {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                resNoOfDifferentSolarPanelModels: e
            }

        }, () => {
            console.log(this.state.objectSet)
        })
    }

                    //"nonShadingSpaceAvailabilityOnShelter": this.state.NonShadingSpaceAvailabilityOnShelter,
                //"nonShadingSpaceAvailabilityOnGround": this.state.NonShadingSpaceAvailabilityOnGround,
                //"shelterDirectionFromTheTower": this.state.resShelterDirectionFromTheTower,
                //"nonShadingSpaceDirectionFromTheTower": this.state.resNonShadingSpaceDirectionFromTheTower,

    handleResShelterDirectionFromTheTower = (e) => {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                resShelterDirectionFromTheTower: e
            }
        }, () => {
            console.log(this.state.objectSet)
        })
    }

    handleResNonShadingSpaceDirectionFromTheTower = (e) => {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                resNonShadingSpaceDirectionFromTheTower: e
            }
        }, () => {
            console.log(this.state.objectSet)
        })
    }

    handleNonShadingSpaceAvailabilityOnShelter = (e) => {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                resNonShadingSpaceAvailabilityOnShelter: e
            }
        }, () => {
            console.log(this.state.objectSet)
        })
    }

    handleNonShadingSpaceAvailabilityOnGround = (e) => {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                resNonShadingSpaceAvailabilityOnGround: e
            }
        }, () => {
            console.log(this.state.objectSet)
        })
    }
    



    dataUpdate() {
        console.log("ssss")
        this.setState({
            switchStatus: false
        })
    }
    close = async () => {
        this.props.parentMethod();
        this.setState({
            objectSet: {
                "resNoOfDifferentSolarPanelModels": this.props?.details.access_technologies_data?.resNoOfDifferentSolarPanelModels,
                "resSolarExpectedOutput": this.props?.details.access_technologies_data?.resSolarExpectedOutput,



            }
        })
        this.setState({
            switchStatus: true
        });
    }
    dataSetToUpdate = async () => {

        console.log(this.state.objectSet)
        console.log(this.props.detailsupdateGeneralDetails.updateGeneralDetails)
        let remoteJob = {
            ...this.props.detailsupdateGeneralDetails.updateGeneralDetails,
            ...this.state.objectSet
        };
        const formData = new FormData();
        formData.append('id', this.props?.details?.access_technologies_data?.id);
        formData.append('object', JSON.stringify(remoteJob));

        var res = await dataUpdateGeneralDetails(formData);

        if (res.success) {
            message.success('Successfully Update!')
            this.props.parentMethod();
            this.setState({
                switchStatus: true
            })
        } else {
            message.warning(' Something went wrong!')
        }


    }

    render() {
        const { Option } = Select;
        const { Panel } = Collapse;
        const _res = this.state.selectedElement

        const columns2 = [
            {
                title: "Installed Solar Capacity (kW)",
                details: [],
                content: this.validatedTextLable(_res.pvcapacity),
            },
            {
                title: "Solar Panel Make",
                details: [],
                content: _res !== ""
                    ? <Tag color="gray" style={{ margin: "0px" }}><span>{_res.pvmake}</span></Tag> : <></>,
            },
            {
                title: "Solar Panel Model",
                content: _res.solarPanelModel != null
                    ? this.validatedTextLable(_res.solarPanelModel?.name)
                    : <span class="badge text-danger">Currently Not Set</span>,
                details: [
                    {
                        title: "Per Panel Capacity (W)",
                        content: _res.solarPanelModel != null
                            ? this.validatedTextLable(_res.solarPanelModel?.perPanelCapacity)
                            : <span class="badge text-danger">Currently Not Set</span>,
                    },
                ],
            },
            {
                title: "Commissioned Date",
                details: [],
                content: this.validatedTextLable(_res.commissionedDate),
            },
            {
                title: "Panel Count On Roof Top",
                details: [],
                content: this.validatedTextLable(_res.panelCountOnRoofTop),
            },
            {
                title: "Panel Count On Ground",
                details: [],
                content: this.validatedTextLable(_res.panelCountOnGround),
            },
            {
                title: "Installed Location",
                details: [],
                content: this.validatedTextLable(_res.installedLocation),
            },
            {
                title: "Solar Panel Installed Over The Outdoor Cabinet",
                details: [],
                content: this.validatedTextLable(_res.solarPanelInstalledOverTheOutdoorCabinet),
            },
            {
                title: "Inverter/Converter Type",
                details: [],
                content: this.validatedTextLable(_res.inverterType),
            },
            {
                title: "Inverter Converter Model",
                content: _res.inverterConverterModel != null
                    ? this.validatedTextLable(_res.inverterConverterModel?.name)
                    : <span class="badge text-danger">Currently Not Set</span>,
                details: [
                    {
                        title: "Per Converter/Inverter Capacity (kW)",
                        content: _res.inverterConverterModel != null
                            ? this.validatedTextLable(_res.inverterConverterModel?.perConverterInverterCapacity)
                            : <span class="badge text-danger">Currently Not Set</span>,
                    },
                ],
            },
            {
                title: "Converter/Inverter Quantity",
                details: [],
                content: this.validatedTextLable(_res.converterInverterQuantity),
            },
            {
                title: "RMS Connection Inband/Outband",
                details: [],
                content: this.validatedTextLable(_res.rmsconnectionInband),
            },
            {
                title: "RMS Connection Mode",
                details: [],
                content: this.validatedTextLable(_res.rmsconnectionMode),
            },

            {
                title: "Last Maintenance Date",
                details: [],
                content: this.validatedTextLable(_res.lastMaintenanceDate),
            },
            {
                title: "Average Energy Generation From Solar (kWh /day)",
                details: [],
                content: this.validatedTextLable(_res.averageEnergyGenerationFromSolar),
            },
            // -------------------------------------------------------------------------------------
            {
                title: "Rectifiers",
                content: _res !== ""
                    ? _res.rectifiers.filter(name => name.delete == false).length != 0
                        ? <div class="d-flex justify-content-end align-items-center">
                            {_res.rectifiers.filter(name => name.delete == false).map((e) => <span class="badge badge-soft-pink fw-semibold" style={{ marginLeft: "5px" }}>{e.name}</span>)}
                        </div>
                        : <span class="badge badge-outline-secondary text-muted">No</span>
                    : <></>,
                details: [],
            },

            // Requested To Common Section
            // {
            //     title: "Non Shading Space Availability as 2mx1m Slots on Shelter (Rooftop)",
            //     details: [],
            //     content: this.validatedTextLable(_res.nonShadingSpaceAvailabilityOnShelter),
            // },
            // {
            //     title: "Non Shading Space Availability as 2mx1m Slots On Ground",
            //     details: [],
            //     content: this.validatedTextLable(_res.nonShadingSpaceAvailabilityOnGround),
            // },
            // {
            //     title: "Shelter Direction From The Tower",
            //     details: [],
            //     content: this.validatedTextLable(_res.shelterDirectionFromTheTower),
            // },
            // {
            //     title: "Non Shading Space Direction From The Tower",
            //     details: [],
            //     content: this.validatedTextLable(_res.nonShadingSpaceDirectionFromTheTower),
            // },
        ];

        return (
            <>
                <div className='row'>
                    <div className='row'>
                        <div class="col-lg-12">
                            <div class="text-end">
                                <ul class="list-inline">
                                    <li class="list-inline-item">
                                        {this.state.actionView
                                            ? <button type="button" class="btn btn-soft-primary btn-sm" onClick={this.requestFromClose}><i class="fas fa-plus me-2"></i>Add Renewable Energy Solars</button>
                                            : ('')
                                        }
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-12'>
                            <div class="card">
                                <div class="card-header">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <h4 class="card-title">Information</h4>
                                        {this.state.switchStatus
                                            ? <button type="button" class="btn btn-soft-primary btn-icon-square-sm" onClick={() => this.dataUpdate()}> <i class="mdi mdi-pencil-outline"></i></button>
                                            : <div class="d-flex justify-content-end align-items-center">
                                                <button type="button" class="btn btn-soft-secondary " onClick={() => this.close()} style={{ marginRight: "10px" }}> Cancel </button>
                                                {this.state.objectSet?.resNoOfDifferentSolarPanelModels == null || this.state.objectSet?.resNoOfDifferentSolarPanelModels == '' ?
                                                    <button type="button" class="btn btn-soft-primary" disabled> 
                                                    Submit To Update </button>
                                                    : <button type="button" class="btn btn-soft-primary" onClick={() => this.dataSetToUpdate()}> Submit To Update </button>
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="row">

                                        <div className='col-md-6 mt-3'>
                                            <ul class="list-group">
                                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <i class="la la-hand-o-right text-primary me-2"></i>  Non Shading Space Availability as 2mx1m Slots On Shelter (Rooftop):
                                                    </div>
                                                    {this.state.switchStatus ?
                                                        (<>{this.props.details.access_technologies_data?.nonShadingSpaceAvailabilityOnShelter != null ? (
                                                            <span class="badge badge-outline-success">
                                                                {this.props?.details.access_technologies_data?.nonShadingSpaceAvailabilityOnShelter}
                                                            </span>) :
                                                            (<span class="badge badge-outline-warning">Currently Not Set</span>)}</>) :
                                                        (<>  <InputNumber min={0} style={{ width: '200px' }}
                                                            value={this.state.objectSet?.nonShadingSpaceAvailabilityOnShelter}
                                                            onChange={(e) => {
                                                                this.state.objectSet.nonShadingSpaceAvailabilityOnShelter = e
                                                            }
                                                            } /> </>)}
                                                </li>
                                            </ul>
                                        </div>

                                        <div className='col-md-6 mt-3'>
                                            <ul class="list-group">
                                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <i class="la la-hand-o-right text-primary me-2"></i>  Non Shading Space Availability as 2mx1m Slots On Ground:
                                                    </div>
                                                    {this.state.switchStatus ?
                                                        (<>{this.props.details.access_technologies_data?.nonShadingSpaceAvailabilityOnGround != null ? (
                                                            <span class="badge badge-outline-success">
                                                                {this.props?.details.access_technologies_data?.nonShadingSpaceAvailabilityOnGround}
                                                            </span>) :
                                                            (<span class="badge badge-outline-warning">Currently Not Set</span>)}</>) :
                                                        (<>  <InputNumber min={0} style={{ width: '200px' }}
                                                            value={this.state.objectSet?.nonShadingSpaceAvailabilityOnGround}
                                                            onChange={(e) => {
                                                                this.state.objectSet.nonShadingSpaceAvailabilityOnGround = e
                                                            }
                                                            } /> </>)}
                                                </li>
                                            </ul>
                                        </div>

                                        <div className='col-md-6 mt-3'>
                                            <ul class="list-group">
                                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <i class="la la-hand-o-right text-primary me-2"></i>  Shelter Direction From The Tower:
                                                    </div>
                                                    {this.state.switchStatus ?
                                                        (<>{this.props.details.access_technologies_data?.shelterDirectionFromTheTower != null ? (
                                                            <span class="badge badge-outline-success">
                                                                {this.props?.details.access_technologies_data?.shelterDirectionFromTheTower}
                                                            </span>) :
                                                            (<span class="badge badge-outline-warning">Currently Not Set</span>)}</>) :
                                                        (<>
                                                            <Select
                                                                style={{ width: '200px' }}
                                                                value={this.state.objectSet?.shelterDirectionFromTheTower}
                                                                onChange={(selectedValue) => {
                                                                    this.setState((prevState) => ({
                                                                        objectSet: {
                                                                            ...prevState.objectSet,
                                                                            shelterDirectionFromTheTower: selectedValue
                                                                        }
                                                                    }), () => {
                                                                        console.log(this.state.objectSet);
                                                                    });
                                                                }}
                                                            >
                                                                {this.props.solarDropdown["dropdownObjSolar"]["resShelterDirectionFromTheTower"].map((x, y) => (
                                                                    <Option key={y} value={x}>
                                                                        {x}
                                                                    </Option>
                                                                ))}
                                                            </Select>
                                                        </>)}
                                                </li>
                                            </ul>
                                        </div>

                                        <div className='col-md-6 mt-3'>
                                            <ul className='list-group'>
                                                <li className='list-group-item d-flex justify-content-between align-items-center'>
                                                    <div>
                                                        <i className='la la-hand-o-right text-primary me-2'></i> Non Shading Space Direction From The Tower:
                                                    </div>
                                                    {this.state.switchStatus ? (
                                                        <>
                                                            {this.props.details.access_technologies_data?.nonShadingSpaceDirectionFromTheTower != null ? (
                                                                <span className='badge badge-outline-success'>
                                                                    {this.props?.details.access_technologies_data?.nonShadingSpaceDirectionFromTheTower}
                                                                </span>
                                                            ) : (
                                                                <span className='badge badge-outline-warning'>Currently Not Set</span>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Select
                                                                style={{ width: '200px' }}
                                                                value={this.state.objectSet?.nonShadingSpaceDirectionFromTheTower}
                                                                onChange={(selectedValue) => {
                                                                    this.setState((prevState) => ({
                                                                        objectSet: {
                                                                            ...prevState.objectSet,
                                                                            nonShadingSpaceDirectionFromTheTower: selectedValue
                                                                        }
                                                                    }), () => {
                                                                        console.log(this.state.objectSet);
                                                                    });
                                                                }}
                                                            >
                                                                {this.props.solarDropdown["dropdownObjSolar"]["resNonShadingSpaceDirectionFromTheTower"].map((x, y) => (
                                                                    <Option key={y} value={x}>
                                                                        {x}
                                                                    </Option>
                                                                ))}
                                                            </Select>
                                                        </>
                                                    )}
                                                </li>
                                            </ul>
                                        </div>

                                        <div className='col-md-6 mt-3'>
                                            <ul class="list-group">
                                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <i class="la la-hand-o-right text-primary me-2"></i>  No of Different Solar Panel Models:
                                                    </div>
                                                    {/* {this.props.details?.access_technologies_data?.noOfAirConditioners != null ? (<span class="badge badge-outline-success">{this.props.details?.access_technologies_data?.noOfAirConditioners}</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>)} */}
                                                    {this.state.switchStatus ? (<>
                                                    {this.props?.details.access_technologies_data?.resNoOfDifferentSolarPanelModels != null ? 
                                                    (<span class="badge badge-outline-success">{this.props?.details.access_technologies_data?.resNoOfDifferentSolarPanelModels}</span>) : 
                                                    (<span class="badge badge-outline-warning">Currently Not Set</span>)}</>) : 
                                                    (<>  
                                                    <p style={{ color: 'red' }}>
                                                        {this.state.objectSet?.resNoOfDifferentSolarPanelModels == null || this.state.objectSet?.resNoOfDifferentSolarPanelModels == '' ? 'Required' : ''}</p>
                                                    <InputNumber min={0} style={{ width: '200px' }} 
                                                    value={this.state.objectSet?.resNoOfDifferentSolarPanelModels} 
                                                    id="airCoolingMethod" onChange={this.handlresNoOfDifferentSolarPanelModels} /> 
                                                    </>)}
                                                </li>
                                            </ul>
                                        </div>

                                        <div className='col-md-6 mt-3'>
                                            <ul class="list-group">

                                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <i class="la la-hand-o-right text-primary me-2"></i>  Solar expected Output (kWh/Solar kW/day):
                                                    </div>
                                                    {/* {this.props?.details?.access_technologies_data?.cplannedFromOperationalTeam? (<span class="badge badge-outline-success">true</span>) : (<span class="badge badge-outline-warning">false</span>)} */}
                                                    {this.state.switchStatus ? (<>{this.props?.details.access_technologies_data?.resSolarExpectedOutput != null ? (<span class="badge badge-outline-success">
                                                        {this.props?.details.access_technologies_data?.resSolarExpectedOutput}</span>) :
                                                        (<span class="badge badge-outline-warning">Currently Not Set</span>)}</>) :
                                                        (<>  <InputNumber min={0} style={{ width: '200px' }}
                                                            value={this.state.objectSet?.resSolarExpectedOutput} id="airCoolingMethod"
                                                            onChange={this.handlresSolarExpectedOutput} /> </>)}
                                                    {/* {this.props?.details?.access_technologies_data?.airTicketNumberOfPendingFaultyTicket ? (<> <Switch disabled={this.state.switchStatus} defaultChecked onChange={() => this.switchTo()} checkedChildren="true" unCheckedChildren="false" /></>) : (<Switch disabled={this.state.switchStatus} onChange={() => this.switchTo()} checkedChildren="true" unCheckedChildren="false" />)} */}
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        {this.props.details2.renewableEnergySolars_data.map((element) => (
                            <div className='col-md-4'>
                                <div class="card">
                                    <div class="card-header">
                                        <div class="d-flex justify-content-start align-items-center">
                                            <img src={'https://static.vecteezy.com/system/resources/previews/001/902/502/large_2x/telecommunication-radio-antenna-and-satelite-tower-with-blue-sky-free-photo.JPG'} alt="" class="thumb-md rounded" />
                                            <div class="badge badge-soft-primary" style={{ padding: "5px", marginLeft: "15px", border: "1px solid rgba(0, 0, 0, 0.05)" }}>
                                                <i class="la la-home font-14 text-dark" style={{ paddingLeft: "10px" }} />
                                                <i class="la la-angle-right text-dark font-14" style={{ padding: "5px" }} />
                                                <span class="fw-semibold text-pink">{
                                                    element.rectifiers != null && element.rectifiers.length > 0
                                                        ? element.rectifiers[0].name + (element.rectifiers.filter(name => name.delete == false).length > 1 ? (" ...") : (""))
                                                        : ""
                                                }</span>
                                                <i class="la la-angle-right text-dark font-14" style={{ padding: "5px" }} />
                                                <span class="fw-semibold text-primary" style={{ paddingRight: "10px" }}>{element.pvmake}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="col">
                                            <div class="d-flex justify-content-between align-items-center mb-2">
                                                <h6 class="fw-normal m-0">Solar Panel Make</h6>
                                                <span class="badge bg-secondary">{element.pvmake}</span>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center mb-2">
                                                <h6 class="fw-normal m-0">Installed Solar Capacity (kW)</h6>
                                                <span class="badge badge-outline-info badge-pill">{element.pvcapacity}</span>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center mb-2">
                                                <h6 class="fw-normal m-0">Inverter/Converter Type</h6>
                                                <span class="badge badge-soft-warning fw-semibold ms-2">{element.inverterType}</span>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center mb-2">
                                                <h6 class="fw-normal m-0">Installed Location</h6>
                                                <div class="d-flex align-items-center">
                                                    <span class="badge badge-soft-success fw-semibold ms-2"><i class="far fa-fw dripicons-location"></i> {element.installedLocation} </span>
                                                    {/* <span class="badge badge-soft-pink fw-semibold ms-2"><i class="far fa-fw ti-ruler"></i> {element.installedHeight} m Hight</span> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p class="card-footer m-0">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <a href="#custom-modal" class="btn btn-soft-dark btn-sm waves-effect btn-round" onClick={() => this.viewMoreDetails(element)}>
                                                <i class="mdi mdi mdi-unfold-more-horizontal"></i> More Details </a>
                                            <div class="text-end">
                                                {this.state.AssetManagementPermission
                                                    ? (<button type="button" class="btn btn-soft-primary btn-icon-square-sm" onClick={() => this.ResUpdata(element.id)} style={{ marginRight: "0px" }}><i class="mdi mdi-pencil-outline"></i></button>)
                                                    : ("")}
                                                <Popconfirm
                                                    title="Are You Sure?"
                                                    description="Are you sure to delete this task?"
                                                    onConfirm={() => this.submitForm(element.id)}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    {this.state.AssetManagementPermission
                                                        ? (<button type="button" class="btn btn-soft-danger btn-icon-square-sm"><i class="mdi mdi-trash-can-outline"></i></button>)
                                                        : ('')}
                                                </Popconfirm>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>

                    <Drawer maskClosable={false} title={"Renewable Energy Solar Details"} width={"50%"} placement="right" onClose={() => this.onViewMoreDetailsDrawerClose()} visible={this.state.openViewMoreDetails} closeIcon={<button type="button" class="btn btn-soft-danger btn-icon-square-sm" style={{ marginRight: "8px" }}><i class="mdi dripicons-cross"></i></button>}>
                        <Collapse style={{ borderColor: "lavender", alignContent: "center" }}>
                            {columns2.map((item) => (
                                <Panel
                                    style={{ borderColor: "lavender" }}
                                    header={
                                        <li class="d-flex justify-content-between align-items-center">
                                            <div>
                                                {item.details.length == 0 ? <i class="la la-hand-o-right text-primary" style={{ paddingLeft: "5px", paddingRight: "5px" }} /> : <></>}
                                                <span class="fw-normal m-2">{item.title}</span>
                                            </div>
                                            {item.content}
                                        </li>
                                    }
                                    key={item.title}
                                    showArrow={item.details.length > 0}
                                    collapsible={item.details.length > 0 ? "" : "icon"}
                                >
                                    {item.details.map((detail) => (
                                        <li class="d-flex justify-content-between align-items-center me-2" style={{ paddingBottom: "10px", paddingLeft: "10px" }}>
                                            <div>
                                                <i class="la la-circle text-primary me-2"></i> <span>{detail.title}</span>
                                            </div>
                                            {detail.content}
                                        </li>
                                    ))}
                                </Panel>
                            ))}
                        </Collapse>
                    </Drawer>
                </div>

                <Drawer maskClosable={false} title={"New Renewable Energy Solars"} width={"60%"} placement="right" onClose={() => this.onDrawerClose()} visible={this.state.open} closeIcon={<button type="button" class="btn btn-soft-danger btn-icon-square-sm" style={{ marginRight: "8px" }}><i class="mdi dripicons-cross"></i></button>}>
                    <div className="row" >
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <Form
                                        ref={this.formRef}
                                    >
                                        <div className='row'>


                                            <div className='col-md-6'>
                                                <label className="form-label" for="dcdu">
                                                    Installed Solar Capacity (kW)  <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item required name="pvcapacity" tooltip="This is a required field">
                                                    <InputNumber style={{ width: '100%' }} id="pvcapacity" onChange={this.handlePvcapacity} />
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Pv Capacity', this.state.resPvcapacity, 'required')}</p>
                                                </Form.Item>

                                            </div>
                                            <div className='col-md-6'>
                                                <label className="form-label" for="dcdu">
                                                    Solar Panel Make <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item required name="pvmake" tooltip="This is a required field">
                                                    {/* <Input id="pvmake" onChange={this.handlePvmake} /> */}
                                                    <div>
                                                        <Select
                                                            style={{ width: '100%' }}
                                                            onChange={(e) => {
                                                                this.handlePvmake(e);
                                                            }}>
                                                            {this.props.solarDropdown["dropdownObjSolar"]["resPvmake"].map((x, y) => {
                                                                return <Option value={x}>{x}</Option>
                                                            })}
                                                        </Select>
                                                    </div>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message(' Pv make', this.state.resPvmake, 'required')}</p>
                                                </Form.Item>
                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label" for="dcdu">
                                                    Solar Panel Model <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item required name="SolarPanelModel" tooltip="This is a required field">
                                                    <Select
                                                        showSearch
                                                        onChange={this.onChangeSolarPanelModel}
                                                        style={{ width: "100%" }}
                                                        placeholder="Search to Select"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        {this.props.resMasterData?.resMasterData?.solarPanelModelList?.map((item, index) => {
                                                            return <Option value={item?.id}>{item?.name}</Option>;
                                                        })}
                                                    </Select>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Solar Panel Model', this.state.selectedSolarPanelModelId, 'required')}</p>
                                                </Form.Item>
                                            </div>

                                            <div className='col-md-6'>
                                                <label className="form-label" for="depot">
                                                    Commissioned Date
                                                </label>
                                                <Form.Item required name="commissionedDate" tooltip="This is a required field">
                                                    <DatePicker

                                                        style={{ width: "100%" }}
                                                        onChange={this.onChangeCommissionedDate}
                                                    />
                                                    {/* <p style={{ color: "#f5325c" }}>{this.validator2.message('depot', this.state.siteOnAirDate, 'required')}</p> */}
                                                </Form.Item>
                                            </div>

                                            <div className='col-md-6'>
                                                <label className="form-label" for="dcdu">
                                                    Panel Count On Roof Top <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item required name="panelCountOnRoofTop" tooltip="This is a required field">
                                                    <InputNumber style={{ width: '100%' }} id="panelCountOnRoofTop" onChange={this.handlePanelCountOnRoofTop} />
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Panel Count On Roof Top', this.state.resPanelCountOnRoofTop, 'required')}</p>
                                                </Form.Item>
                                            </div>

                                            <div className='col-md-6'>
                                                <label className="form-label" for="dcdu">
                                                    Panel Count On Ground <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item required name="panelCountOnGround" tooltip="This is a required field">
                                                    <InputNumber style={{ width: '100%' }} id="panelCountOnGround" onChange={this.handlePanelCountOnGround} />
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Panel Count On Ground', this.state.resPanelCountOnGround, 'required')}</p>
                                                </Form.Item>
                                            </div>


                                            <div className='col-md-6'>
                                                <label className="form-label" for="dcdu">
                                                    Installed Location <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item required name="installedLocation" tooltip="This is a required field">
                                                    {/* <Input id="installedLocation" onChange={this.handleInstalledLocation} /> */}
                                                    <div>
                                                        <Select
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            onChange={(e) => {
                                                                this.handleInstalledLocation(e);
                                                            }}>
                                                            {this.props.solarDropdown["dropdownObjSolar"]["resInstalledLocation"].map((x, y) => {
                                                                return <Option value={x}>{x}</Option>
                                                            })}
                                                        </Select>
                                                    </div>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Installed Location', this.state.resInstalledLocation, 'required')}</p>
                                                </Form.Item>
                                            </div>

                                            <div className='col-md-6'>
                                                <label className="form-label" for="dcdu">
                                                    Solar Panel Installed Over The Outdoor Cabinet <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item required name="solarPanelInstalledOverTheOutdoorCabinet" tooltip="This is a required field">
                                                    <div>
                                                        <Select
                                                            style={{ width: '100%' }}
                                                            onChange={(e) => {
                                                                this.handleSolarPanelInstalledOverTheOutdoorCabinet(e);
                                                            }}>
                                                            {this.props.solarDropdown["dropdownObjSolar"]["resSolarPanelInstalledOverTheOutdoorCabinet"].map((x, y) => {
                                                                return <Option value={x}>{x}</Option>
                                                            })}
                                                        </Select>
                                                    </div>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Solar Panel Installed Over The Outdoor Cabinet', this.state.resSolarPanelInstalledOverTheOutdoorCabinet, 'required')}</p>
                                                </Form.Item>
                                            </div>

                                            <div className='col-md-6'>
                                                <label className="form-label" for="dcdu">
                                                    Inverter/Converter Type <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item required name="inverterType" tooltip="This is a required field">
                                                    {/* <Input id="inverterType" onChange={this.handleInverterType} /> */}
                                                    <div>
                                                        <Select
                                                            style={{ width: '100%' }}
                                                            onChange={(e) => {
                                                                this.handleInverterType(e);
                                                            }}>
                                                            {this.props.solarDropdown["dropdownObjSolar"]["resInverterType"].map((x, y) => {
                                                                return <Option value={x}>{x}</Option>
                                                            })}
                                                        </Select>
                                                    </div>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Inverter Type', this.state.resInverterType, 'required')}</p>
                                                </Form.Item>
                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label" for="dcdu">
                                                    Inverter Converter Model <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item required name="inverterConverterModel" tooltip="This is a required field">
                                                    <Select
                                                        showSearch
                                                        onChange={this.onChangeResInverterConverterModel}
                                                        style={{ width: "100%" }}
                                                        placeholder="Search to Select"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        {this.props.resMasterData?.resMasterData?.inverterConverterModelList?.map((item, index) => {
                                                            return <Option value={item?.id}>{item?.name}</Option>;
                                                        })}
                                                    </Select>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Inverter Converter Model', this.state.selectedInverterConverterModelId, 'required')}</p>
                                                </Form.Item>
                                            </div>
                                            <div className='col-md-6'>
                                                <label className="form-label" for="dcdu">
                                                    Converter Inverter Quantity <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item required name="converterInverterQuantity" tooltip="This is a required field">
                                                    {/* <InputNumber style={{ width: '100%' }} id="converterInverterQuantity" onChange={this.handleConverterInverterQuantity} /> */}
                                                    <div>
                                                        <Select
                                                            style={{ width: '100%' }}
                                                            onChange={(e) => {
                                                                this.handleConverterInverterQuantity(e);
                                                            }}>
                                                            {this.props.solarDropdown["dropdownObjSolar"]["resConverterInverterQuantity"].map((x, y) => {
                                                                return <Option value={x}>{x}</Option>
                                                            })}
                                                        </Select>
                                                    </div>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Converter Inverter Quantity', this.state.resConverterInverterQuantity, 'required')}</p>
                                                </Form.Item>
                                            </div>

                                            <div className='col-md-6'>
                                                <label className="form-label" for="dcdu">
                                                    RMS Connection Inband/Outband
                                                </label>
                                                <Form.Item required name="rmsconnectionInband" tooltip="This is a required field">
                                                    {/* <Input id="rmsconnectionInband" onChange={this.handleRmsconnectionInband} /> */}
                                                    <div>
                                                        <Select
                                                            style={{ width: '100%' }}
                                                            onChange={(e) => {
                                                                this.handleRmsconnectionInband(e);
                                                            }}>
                                                            {this.props.solarDropdown["dropdownObjSolar"]["resRmsconnectionInband"]?.map((x, y) => {
                                                                return <Option value={x}>{x}</Option>
                                                            })}
                                                        </Select>
                                                    </div>
                                                </Form.Item>
                                            </div>

                                            <div className='col-md-6'>
                                                <label className="form-label" for="dcdu">
                                                    RMS Connection Mode
                                                </label>
                                                <Form.Item required name="rmsconnectionMode" tooltip="This is a required field">
                                                    <Select
                                                        showSearch
                                                        style={{ width: '100%' }}
                                                        onChange={(e) => {
                                                            this.handleRmsconnectionMode(e);
                                                        }}>
                                                        {this.props.solarDropdown["dropdownObjSolar"]["rmsRmsconnectionMode"]?.map((x, y) => {
                                                            return <Option value={x}>{x}</Option>
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </div>

                                            <div className='col-md-6'>
                                                <label className="form-label" for="depot">
                                                    Last Maintenance Date
                                                </label>
                                                <Form.Item required name="lastMaintenanceDate" tooltip="This is a required field">
                                                    <DatePicker

                                                        style={{ width: "100%" }}
                                                        onChange={this.onChangeLastMaintenanceDate}
                                                    />
                                                </Form.Item>
                                            </div>

                                            <div className='col-md-6'>
                                                <label className="form-label" for="dcdu">
                                                    Average Energy Generation From Solar (kWh /day)
                                                </label>
                                                <Form.Item required name="averageEnergyGenerationFromSolar" tooltip="This is a required field">
                                                    <InputNumber style={{ width: '100%' }} id="averageEnergyGenerationFromSolar" onChange={this.handleAverageEnergyGenerationFromSolar} />
                                                    {/* <p style={{ color: "#f5325c" }}>{this.validator.message('site_name', this.state.siteName, 'required')}</p> */}
                                                </Form.Item>
                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-label" for="dcdu">
                                                    Rectifiers <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item required name="Is_active" tooltip="This is a required field">
                                                    <Select
                                                        mode="multiple"
                                                        showSearch
                                                        onChange={this.onChangeRectifier}
                                                        style={{ width: "100%" }}
                                                        placeholder="Search to Select"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        {this.props.resMasterData?.resMasterData?.rectifierList?.map((item, index) => {
                                                            return <Option value={item?.id}>{item?.name}</Option>;
                                                        })}
                                                    </Select>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Rectifiers', this.state.rectifier, 'required')}</p>
                                                </Form.Item>
                                            </div>

                                        </div>
                                    </Form>
                                    <div className='row' style={{ justifyContent: "end" }}>
                                        <div className='col-md-2' >   <button className="btn btn-primary" onClick={() => this.AllDataSubmit()} >
                                            Create New Asset
                                        </button></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Drawer>

                <Drawer maskClosable={false} title={"Edit Renewable Energy Solars"} width={"60%"} placement="right" onClose={() => this.onDrawerClose()} visible={this.state.openEdit} closeIcon={<button type="button" class="btn btn-soft-danger btn-icon-square-sm" style={{ marginRight: "8px" }}><i class="mdi dripicons-cross"></i></button>}>
                    <div className="row" >
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <Form
                                        ref={this.formRef}
                                    >
                                        <div className='row'>

                                            <div className='col-md-6'>
                                                <label className="form-label" for="dcdu">
                                                    Installed Solar Capacity (kW) <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item >
                                                    <InputNumber style={{ width: '100%' }} id="pvcapacity" value={this.state.resPvcapacity} onChange={this.handlePvcapacity} />
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Pv Capacity', this.state.resPvcapacity, 'required')}</p>
                                                </Form.Item>

                                            </div>

                                            <div className='col-md-6'>
                                                <label className="form-label" for="dcdu">
                                                    Solar Panel Make <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item >
                                                    {/* <Input id="pvmake" value={this.state.resPvmake} onChange={this.handlePvmake} /> */}
                                                    <div>
                                                        <Select
                                                            value={this.state.resPvmake}
                                                            style={{ width: '100%' }}
                                                            onChange={(e) => {
                                                                this.handlePvmake(e);
                                                            }}>
                                                            {this.props?.solarDropdown["dropdownObjSolar"]["resPvmake"]?.map((x, y) => {
                                                                return <Option value={x}>{x}</Option>
                                                            })}
                                                        </Select>
                                                    </div>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Pv make', this.state.resPvmake, 'required')}</p>
                                                </Form.Item>
                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-label" for="dcdu">
                                                    Solar Panel Model <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item required name="SolarPanelModel" tooltip="This is a required field">
                                                    {this.state.selectedSolarPanelModelId != null
                                                        ? <Select
                                                            showSearch
                                                            defaultValue={this.state.selectedSolarPanelModelId}
                                                            onChange={this.onChangeSolarPanelModel}
                                                            style={{ width: "100%" }}
                                                            placeholder="Search to Select"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            filterSort={(optionA, optionB) =>
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                            }
                                                        >
                                                            {this.props.resMasterData?.resMasterData?.solarPanelModelList?.map((item, index) => {
                                                                return <Option value={item?.id}>{item?.name}</Option>;
                                                            })}
                                                        </Select>
                                                        : <></>}
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Solar Panel Model', this.state.selectedSolarPanelModelId, 'required')}</p>
                                                </Form.Item>
                                            </div>

                                            <div className='col-md-6'>
                                                <label className="form-label" for="depot">
                                                    Commissioned Date
                                                </label>
                                                <div> { }</div>

                                                {this.state.resCommissionedDate != null ? (<Form.Item >
                                                    <DatePicker
                                                        defaultValue={this.state.resCommissionedDate != '' ? (moment(this.state.resCommissionedDate)) : ("")}
                                                        style={{ width: "100%" }}
                                                        onChange={this.onChangeCommissionedDate}
                                                    />
                                                    {/* <p style={{ color: "#f5325c" }}>{this.validator2.message('depot', this.state.siteOnAirDate, 'required')}</p> */}

                                                </Form.Item>) : ('')}
                                            </div>

                                            <div className='col-md-6'>
                                                <label className="form-label" for="dcdu">
                                                    Panel Count On Roof Top <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item >
                                                    <InputNumber style={{ width: '100%' }} id="panelCountOnRoofTop" value={this.state.resPanelCountOnRoofTop} onChange={this.handlePanelCountOnRoofTop} />
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Panel Count On Roof Top', this.state.resPanelCountOnRoofTop, 'required')}</p>
                                                </Form.Item>

                                            </div>


                                            <div className='col-md-6'>
                                                <label className="form-label" for="dcdu">
                                                    Panel Count On Ground <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item>
                                                    <InputNumber style={{ width: '100%' }} id="panelCountOnGround" value={this.state.resPanelCountOnGround} onChange={this.handlePanelCountOnGround} />
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Panel Count On Ground', this.state.resPanelCountOnGround, 'required')}</p>
                                                </Form.Item>

                                            </div>
                                            <div className='col-md-6'>
                                                <label className="form-label" for="dcdu">
                                                    Installed Location <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item >
                                                    <div>
                                                        <Select
                                                            showSearch
                                                            value={this.state.resInstalledLocation}
                                                            style={{ width: '100%' }}
                                                            onChange={(e) => {
                                                                this.handleInstalledLocation(e);
                                                            }}>
                                                            {this.props.solarDropdown["dropdownObjSolar"]["resInstalledLocation"].map((x, y) => {
                                                                return <Option value={x}>{x}</Option>
                                                            })}
                                                        </Select>
                                                    </div>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Installed Location', this.state.resInstalledLocation, 'required')}</p>
                                                </Form.Item>
                                            </div>

                                            <div className='col-md-6'>
                                                <label className="form-label" for="dcdu">
                                                    Solar Panel Installed Over The Outdoor Cabinet <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item >
                                                    <div>
                                                        <Select
                                                            value={this.state.resSolarPanelInstalledOverTheOutdoorCabinet}
                                                            style={{ width: '100%' }}
                                                            onChange={(e) => {
                                                                this.handleSolarPanelInstalledOverTheOutdoorCabinet(e);
                                                            }}>
                                                            {this.props.solarDropdown["dropdownObjSolar"]["resSolarPanelInstalledOverTheOutdoorCabinet"].map((x, y) => {
                                                                return <Option value={x}>{x}</Option>
                                                            })}
                                                        </Select>
                                                    </div>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Solar Panel Installed Over The Outdoor Cabinet', this.state.resSolarPanelInstalledOverTheOutdoorCabinet, 'required')}</p>
                                                </Form.Item>
                                            </div>


                                            <div className='col-md-6'>
                                                <label className="form-label" for="dcdu">
                                                    Inverter/Converter Type <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item >
                                                    {/* <Input id="inverterType" value={this.state.resInverterType} onChange={this.handleInverterType} /> */}
                                                    <div>
                                                        <Select
                                                            value={this.state.resInverterType}
                                                            style={{ width: '100%' }}
                                                            onChange={(e) => {
                                                                this.handleInverterType(e);
                                                            }}>
                                                            {this.props.solarDropdown["dropdownObjSolar"]["resInverterType"].map((x, y) => {
                                                                return <Option value={x}>{x}</Option>
                                                            })}
                                                        </Select>
                                                    </div>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Inverter Type', this.state.resInverterType, 'required')}</p>
                                                </Form.Item>
                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-label" for="dcdu">
                                                    Inverter Converter Model <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item required name="inverterConverterModel" tooltip="This is a required field">
                                                    {this.state.selectedInverterConverterModelId != null
                                                        ? <Select
                                                            showSearch
                                                            defaultValue={this.state.selectedInverterConverterModelId}
                                                            onChange={this.onChangeResInverterConverterModel}
                                                            style={{ width: "100%" }}
                                                            placeholder="Search to Select"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            filterSort={(optionA, optionB) =>
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                            }
                                                        >
                                                            {this.props.resMasterData?.resMasterData?.inverterConverterModelList?.map((item, index) => {
                                                                return <Option value={item?.id}>{item?.name}</Option>;
                                                            })}
                                                        </Select>
                                                        : <>
                                                            <Select
                                                                showSearch

                                                                onChange={this.onChangeResInverterConverterModel}
                                                                style={{ width: "100%" }}
                                                                placeholder="Search to Select"
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) =>
                                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                filterSort={(optionA, optionB) =>
                                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                                }
                                                            >
                                                                {this.props.resMasterData?.resMasterData?.inverterConverterModelList?.map((item, index) => {
                                                                    return <Option value={item?.id}>{item?.name}</Option>;
                                                                })}
                                                            </Select>
                                                        </>}
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Inverter Converter Model', this.state.selectedInverterConverterModelId, 'required')}</p>
                                                </Form.Item>
                                            </div>

                                            <div className='col-md-6'>
                                                <label className="form-label" for="dcdu">
                                                    Converter Inverter Quantity <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item >
                                                    {/* <InputNumber style={{ width: '100%' }} id="converterInverterQuantity" value={this.state.resConverterInverterQuantity} onChange={this.handleConverterInverterQuantity} /> */}
                                                    <div>
                                                        <Select
                                                            value={this.state.resConverterInverterQuantity}
                                                            style={{ width: '100%' }}
                                                            onChange={(e) => {
                                                                this.handleConverterInverterQuantity(e);
                                                            }}>
                                                            {this.props.solarDropdown["dropdownObjSolar"]["resConverterInverterQuantity"].map((x, y) => {
                                                                return <Option value={x}>{x}</Option>
                                                            })}
                                                        </Select>
                                                    </div>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Converter Inverter Quantity', this.state.resConverterInverterQuantity, 'required')}</p>
                                                </Form.Item>
                                            </div>

                                            <div className='col-md-6'>
                                                <label className="form-label" for="dcdu">
                                                    RMS Connection Inband/Outband
                                                </label>
                                                <Form.Item >
                                                    {/* <Input id="rmsconnectionInband" value={this.state.resRmsconnectionInband} onChange={this.handleRmsconnectionInband} /> */}
                                                    <div>
                                                        <Select
                                                            value={this.state?.resRmsconnectionInband}
                                                            style={{ width: '100%' }}
                                                            onChange={(e) => {
                                                                this.handleRmsconnectionInband(e);
                                                            }}>
                                                            {this.props?.solarDropdown["dropdownObjSolar"]["resRmsconnectionInband"]?.map((x, y) => {
                                                                return <Option value={x}>{x}</Option>
                                                            })}
                                                        </Select>
                                                    </div>
                                                </Form.Item>
                                            </div>

                                            <div className='col-md-6'>
                                                <label className="form-label" for="dcdu">
                                                    RMS Connection Mode
                                                </label>
                                                <Form.Item >
                                                    <Select
                                                        showSearch
                                                        value={this.state?.resRmsconnectionMode}
                                                        style={{ width: '100%' }}
                                                        onChange={(e) => {
                                                            this.handleRmsconnectionMode(e);
                                                        }}>
                                                        {this.props?.solarDropdown["dropdownObjSolar"]["rmsRmsconnectionMode"]?.map((x, y) => {
                                                            return <Option value={x}>{x}</Option>
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </div>

                                            <div className='col-md-6'>
                                                <label className="form-label" for="depot">
                                                    Last Maintenance Date
                                                </label>
                                                {this.state.resLastMaintenanceDate != null ? (<Form.Item >
                                                    <DatePicker
                                                        defaultValue={this.state.resLastMaintenanceDate != '' ? (moment(this.state.resLastMaintenanceDate)) : ("")}
                                                        style={{ width: "100%" }}
                                                        onChange={this.onChangeLastMaintenanceDate}
                                                    />
                                                </Form.Item>) : ('')}
                                            </div>

                                            <div className='col-md-6'>
                                                <label className="form-label" for="dcdu">
                                                    Average Energy Generation From Solar (kWh /day)
                                                </label>
                                                <Form.Item >
                                                    <InputNumber style={{ width: '100%' }} id="averageEnergyGenerationFromSolar" value={this.state.resAverageEnergyGenerationFromSolar} onChange={this.handleAverageEnergyGenerationFromSolar} />
                                                    {/* <p style={{ color: "#f5325c" }}>{this.validator.message('site_name', this.state.siteName, 'required')}</p> */}
                                                </Form.Item>
                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Rectifiers <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item required name="Is_active" tooltip="This is a required field">
                                                    <Select
                                                        mode="multiple"
                                                        showSearch
                                                        defaultValue={this.state.selectedRectifierList}
                                                        onChange={this.onChangeRectifier}
                                                        style={{ width: "100%" }}
                                                        placeholder="Search to Select"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        {this.props.resMasterData?.resMasterData?.rectifierList?.map((item, index) => {
                                                            return <Option value={item?.id}>{item?.name}</Option>;
                                                        })}
                                                    </Select>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Rectifiers', this.state.rectifier, 'required')}</p>
                                                </Form.Item>
                                            </div>

                                        </div>
                                    </Form>
                                    <div className='row' style={{ justifyContent: "end" }}>
                                        <div className='col-md-2' >   <button className="btn btn-primary" onClick={() => this.AllDataSubmitUpdate()} >Update This Asset</button></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Drawer>


            </>
        )
    }
}
export default Res;

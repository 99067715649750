import React, { Component } from "react";
import { connect } from "react-redux";
import { Table, Input, Tag, Space } from "antd";
import {
  RightOutlined,
  DownOutlined,
  BorderOuterOutlined,
  TeamOutlined,
  AppstoreOutlined,
  DeploymentUnitOutlined,
  ClusterOutlined,
} from "@ant-design/icons";


class RegionGeneral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      filterTable: null,
    };
  }

  componentDidMount() {
    //console.log(this.props.details);
  }

  render() {
    const { site_details } = this.props.details;
    const { filterTable } = this.state;
    const columns = [
      // {
      //   title: "#",
      //   dataIndex: "id",
      //   defaultSortOrder: "ascend",
      //   sorter: (a, b) => a.id - b.id,
      //   sortDirections: ["descend"],
      //   render: (text, record, index) => <span>{text}</span>,
      // },
      // {
      //   title: "Site Id",
      //   dataIndex: "siteId",
      //   key: "siteId",
      //   //sorter: (a, b) => a.name.localeCompare(b.name),
      //   render: (item) => <span>{item}</span>,
      // },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        //sorter: (a, b) => a.name.localeCompare(b.name),
        render: (item) => <span>{item}</span>,
      },
    ];
    return (
      <div className="row">
        <div className="col-lg-6 col-xl-4">
          <div className="card">
            <div className="card-header">
              <div className="row align-items-center">
                <div className="col">
                  <h4 className="card-title">Depot List</h4>
                </div>
              </div>
            </div>
            <div className="card-body">
              <Table
                size="small"
                columns={columns}
                rowKey="id"
                // pagination={{ pageSize: 15 }}
                onRow={(record, rowIndex) => ({
                  onClick: (e) => {
                    /* Call some endPoint to log this click event */
                    //console.log(record);
                    this.setState({
                      // isPgSelected: true,
                      // selectedPgData: record,
                    });
                  },
                })}
                // expandable={{
                //   expandedRowRender:(record,key )=> <p key={key+1} style={{ margin: 0 }}>{record.id}</p>,
                // }}

                dataSource={
                  filterTable == null ? this.props.details : filterTable
                }
              />
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-xl-8">
         
          
        </div>
      </div>
    );
  }
}

export default RegionGeneral;

import React, { Component } from 'react'

import { Table, Input, InputNumber, Tag, Space, Button, Row, Col, Drawer, Select, Spin, Switch, message } from "antd";
import {  updateCabinDetails } from "../../api/index";
const { Option } = Select;


class CabinDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "",
            tabaleLoading: false,
            switchStatus: true,
            objectSet: {
                "noofIndoorCabins": null,
                "indoorCabinType": null,
                "indoorCabinLength": null,
                "indoorCabinWidth": null,
            },

            dropdownDetailsCabins : {
                "noofIndoorCabins": "",
                "indoorCabinType": "",
            }
        };
        this.handlNofIndoorCabin = this.handlNofIndoorCabin.bind(this);
        this.handlIndoorCabinType = this.handlIndoorCabinType.bind(this);
        this.handlIndoorCabinLenght = this.handlIndoorCabinLenght.bind(this);
        this.handlIndoorCabinWidth = this.handlIndoorCabinWidth.bind(this);

    }
    handlIndoorCabinWidth(e) {



        this.setState({
            objectSet: {
                ...this.state.objectSet,
                indoorCabinWidth: e
            }

        }, () => {

        })
    }
    handlIndoorCabinLenght(e) {



        this.setState({
            objectSet: {
                ...this.state.objectSet,
                indoorCabinLength: e
            }

        }, () => {
            console.log(this.state.objectSet)
        })
    }
    handlIndoorCabinType(e) {
        console.log(e.target.value)


        this.setState({
            objectSet: {
                ...this.state.objectSet,
                indoorCabinType: e.target.value
            }

        }, () => {
            console.log(this.state.objectSet)
        })
    }
    handlNofIndoorCabin(e) {
        console.log(e)


        this.setState({
            objectSet: {
                ...this.state.objectSet,
                noofIndoorCabins: e
            }

        }, () => {
            console.log(this.state.objectSet)
        })
    }

    componentDidMount() {
        console.log('tytqwe')
        console.log(this.props.details)
        this.setState({
            objectSet: {
                "noofIndoorCabins": this.props.details3?.allData?.noofIndoorCabins,
                "indoorCabinType": this.props.details3?.allData?.indoorCabinType,
                "indoorCabinLength": this.props.details3?.allData?.indoorCabinLength,
                "indoorCabinWidth": this.props.details3?.allData?.indoorCabinWidth,

            }
        })
        // console.log(this.props.details2)
    }
    dataSetToUpdate = async () => {
          console.log(this.props.detailsSite)
        let formData = new FormData(); //formdata object
        let setobjectToUpate ={"noofIndoorCabins":this.state.objectSet.noofIndoorCabins,"indoorCabinWidth":this.state.objectSet.indoorCabinWidth,"indoorCabinLength":this.state.objectSet.indoorCabinLength,"indoorCabinType":this.state.objectSet.indoorCabinType}
        console.log(setobjectToUpate)
        formData.append("object",JSON.stringify(setobjectToUpate)); //append the values with key, value pair
        formData.append("id",this.props.detailsSite.site_details.id);
        var res = await updateCabinDetails(formData);
        if(res.success){
            message.success('Successfully Update!')
            this.props.parentMethod();
            this.setState({
              switchStatus: true
          })
         }else{
          message.warning(' Something went wrong!')
         }



     

    }
    
    dataUpdate() {
        console.log("ssss")
        this.setState({
            switchStatus: false
        })
    }
    close = async () => {
        this.props.parentMethod();
        this.setState({
            objectSet:
            {
                "noofIndoorCabins": this.props.details3?.allData?.noofIndoorCabins,
                "indoorCabinType": this.props.details3?.allData?.indoorCabinType,
                "indoorCabinLength": this.props.details3?.allData?.indoorCabinLength,
                "indoorCabinWidth": this.props.details3?.allData?.indoorCabinWidth,

            }
        });
        this.setState({
            switchStatus: true
        });
    }
    render() {
        const { details } = this.props.details

        return (
            <>
                {this.props.details.access_technologies_data != null ? (
                    <div className='row'>
                        <div className='col-md-5'>
                            <div class="card">
                                <div class="card-header">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <h4 class="card-title">Information</h4>
                                        {this.state.switchStatus
                                            ? <button type="button" class="btn btn-soft-primary btn-icon-square-sm" onClick={() => this.dataUpdate()}> <i class="mdi mdi-pencil-outline"></i></button>
                                            : <div class="d-flex justify-content-end align-items-center">
                                                <button type="button" class="btn btn-soft-secondary " onClick={() => this.close()} style={{ marginRight: "10px" }}> Cancel </button>
                                                <button type="button" class="btn btn-soft-primary " onClick={() => this.dataSetToUpdate()}> Submit to Update </button>
                                            </div>
                                        }
                                    </div>

                                </div>
                                <div class="card-body">
                                    <div class="row">

                                        <ul class="list-group">
                                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                                <div>
                                                    <i class="la la-hand-o-right text-primary me-2"></i>No of Indoor Cabins
                                                </div>
                                                <div>
                                                        {this.state.switchStatus.toString() == "true" ? (
                                                            this.props.details3?.allData?.noofIndoorCabins ?(
                                                                <span class="badge badge-outline-success">{this.props.details3?.allData?.noofIndoorCabins}</span>
                                                            ) : (
                                                                <span class="badge badge-outline-danger">Currently Not Set</span>
                                                            )
                                                        ) : (
                                                            <Select
                                                                defaultValue={this.props.details3?.allData?.noofIndoorCabins}
                                                                style={{ width: 120 }}
                                                                onChange={(e) => {
                                                                    this.state.objectSet.noofIndoorCabins = e;
                                                                }}>
                                                                {this.props.cabindropdown["dropdownDetailsCabins"]["noofIndoorCabins"]?.map((x) => {
                                                                    return <Option value={x}>{x}</Option>
                                                                })}
                                                            </Select>
                                                        )}
                                                    </div>

                                                {/* {this.state.switchStatus ? (<> {this.props.details3?.allData?.noofIndoorCabins != null ? (<span class="badge badge-outline-success">{this.props.details3?.allData?.noofIndoorCabins}</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>)}</>) : (<> <InputNumber style={{ width: '20%' }} value={this.state.objectSet?.noofIndoorCabins} id="mpcNoOfPhases" onChange={this.handlNofIndoorCabin} /> </>)} */}
                                            </li>
                                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                                <div>
                                                    <i class="la la-hand-o-right text-primary me-2"></i>Indoor Cabin Type
                                                </div>
                                                <div>
                                                    {this.state.switchStatus.toString() == "true" ? (
                                                        this.props.details3?.allData?.indoorCabinType ? (
                                                            <span class="badge badge-outline-success">{this.props.details3?.allData?.indoorCabinType}</span>
                                                        ) : (
                                                            <span class="badge badge-outline-danger">Currently Not Set</span>
                                                        )
                                                    ) : (
                                                        <Select
                                                            defaultValue={this.props.details3?.allData?.indoorCabinType}
                                                            style={{ width: 120 }}
                                                            onChange={(e) => {
                                                                this.state.objectSet.indoorCabinType = e;
                                                            }}>
                                                            {this.props.cabindropdown["dropdownDetailsCabins"]["indoorCabinType"]?.map((x) => {
                                                                return <Option value={x}>{x}</Option>
                                                            })}
                                                        </Select>
                                                    )}
                                                </div>

                                                {/* {this.state.switchStatus ? (<> {this.props.details3?.allData?.indoorCabinType != null ? (<span class="badge badge-outline-success">{this.props.details3?.allData?.indoorCabinType}</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>)}</>) : (<> <Input value={this.state.objectSet?.indoorCabinType} id="mpcNoOfPhases" onChange={this.handlIndoorCabinType} /> </>)} */}

                                            </li>
                                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                                <div>
                                                    <i class="la la-hand-o-right text-primary me-2"></i>Indoor Cabin Length (m)
                                                </div>


                                                {this.state.switchStatus ? (<> {this.props.details3?.allData?.indoorCabinLength != null ? (<span class="badge badge-outline-success">{this.props.details3?.allData?.indoorCabinLength}</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>)}</>) : (<> <InputNumber style={{ width: '20%' }} value={this.state.objectSet?.indoorCabinLength} id="mpcNoOfPhases" onChange={this.handlIndoorCabinLenght} /> </>)}


                                            </li>
                                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                                <div>
                                                    <i class="la la-hand-o-right text-primary me-2"></i>Indoor Cabin Width (m)
                                                </div>

                                                {/* {this.props.details3?.allData?.indoorCabinWidth ? (<span class="badge badge-outline-success">{this.props.details3?.allData?.indoorCabinWidth}</span>) : (<span class="badge badge-outline-warning">{JSON.stringify(this.props.details3?.allData?.indoorCabinWidth)}</span>)} */}
                                                {this.state.switchStatus ? (<> {this.props.details3?.allData?.indoorCabinWidth != null ? (<span class="badge badge-outline-success">{this.props.details3?.allData?.indoorCabinWidth}</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>)}</>) : (<> <InputNumber style={{ width: '20%' }} value={this.state.objectSet?.indoorCabinWidth} id="mpcNoOfPhases" onChange={this.handlIndoorCabinWidth} /> </>)}


                                            </li>







                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-7'>


                            {/* {this.props.details2.generators_data.length > 0 ? (<div class="card">
                                <div class="card-header">
                                    <h4 class="card-title">Generators</h4>

                                </div>
                                <div class="card-body">
                                <Table
                                size="small"
                                columns={columns2}
                                // loading={this.state.loading && this.state.rectifierloading && this.state.batteryBanksloading && this.state.airConditionersloading && this.state.spdsloading && this.state.cabinetsloading && this.state.generatorsloading && this.state.atsesloading && this.state.dcdusloading && this.state.fcbsloading && this.state.sharedOutOperatorsesloading}
                                loading={this.state.tabaleLoading}
                                dataSource={this.props.details2.generators_data}
                                scroll={{
                                    x: 1300,
                                }}
                            />
                            </div>
                            </div>) : ('')} */}



                        </div>
                    </div>
                ) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <h4 style={{ color: "#c5c5c5" }}>No Dat Found Access Technologies</h4></div>)}

            </>
        )
    }
}
export default CabinDetails;

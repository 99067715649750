import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { getDonationCategories, getData } from "../../actions/index";


class OutdoorCabinetOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allDataset: null,
            tableView: false,
            regionSet: null,

        }

    }

    componentWillMount() {

    }

    componentDidMount() {


        this.setState({
            allDataset: Object.values(this.props.dataSet),
            regionSet: this.props.regions.filter(re => re.isSubRegion == 0)
        }, () => {
            console.log(this.state.regionSet)
            this.setState({
                tableView: true
            })
        })
    }

    // componentWillReceiveProps(nextProps) {

    // }

    // shouldComponentUpdate(nextProps, nextState) { 
    //     console.log(nextProps);
    //     console.log(nextState);
    // }

    // componentWillUpdate(nextProps, nextState) {

    // }

    // componentDidUpdate(prevProps, prevState) {

    // }

    componentWillUnmount() {

    }

    keysToRemove = ["Test Region", "N/A", "ND Super", "Central office"];

    render() {
        let fullTotal = 0;
        return (
            <div>{
                this.state.tableView ? (<table class="table table-bbtype mb-0" style={{ fontSize: 'x-small' }} >
                    <thead style={{ borderWidth: 1 }}>
                        <tr >

                            <th className='table-th-color'><b>No. of Cabinets</b></th>
                            
                            {this.state.regionSet?.map((region) => {
                                if (!this.keysToRemove.includes(region.name)) {
                                    return <th className='table-th-color'><b>{region.name}</b></th>
                                }
                            })}

                            <th className='table-th-color'><b>Total</b></th>
                        </tr>
                    </thead>
                    <tbody style={{ borderWidth: 1 }}>

                        {this.state.allDataset?.map((x) => {
                            return <tr>

                                <td className='table-th-color'  >{x[0].cab_per_site}</td>
                                {(() => {
                                    const arr = [];
                                    let totalValue = 0;
                                    this.state.regionSet.map((a) => {
                                        if (! this.keysToRemove.includes(a.name)) {
                                            if (x.filter(re => re.regionId == a.id).length != 0) {
                                                totalValue = totalValue + x.filter(re => re.regionId == a.id)[0].no_of_sites
                                                arr.push(
                                                    <td className='power-data-dashboard-table-tb' style={{textAlign:'end'}}>{x.filter(re => re.regionId == a.id)[0].no_of_sites}</td>
                                                );
                                            } else {
                                                arr.push(
                                                    <td className='power-data-dashboard-table-tb' style={{textAlign:'end'}}>0</td>
                                                );
                                            }
                                        }
                                    })
                                    arr.push(
                                        <td className='power-data-dashboard-table-tb' style={{textAlign:'end'}}>{totalValue}</td>
                                    );

                                    return arr;
                                })()}


                            </tr>

                        })}

                        <tr>
                            <td className='table-th-color'  ><b>Total</b></td>
                            {this.state.regionSet.map((reg) => {
                                if (!this.keysToRemove.includes(reg.name)) {
                                    return <>
                                    {
                                        (() => {
                                            const arr = [];
                                            let totalValue = 0;
                                            this.state.allDataset.map((a) => {
                                                if (a.filter(re => re.regionId == reg.id).length != 0) {
                                                    totalValue = totalValue + a.filter(re => re.regionId == reg.id)[0].no_of_sites
                                                }
                                            })
                                            arr.push(
                                                <td className='power-data-dashboard-table-tb' style={{textAlign:'end'}}>{totalValue}</td>
                                            );

                                            fullTotal=fullTotal+totalValue;

                                            return arr;
                                        })()
                                    }
                                </>
                                }
                            })}
                            <td className='power-data-dashboard-table-tb' style={{textAlign:'end'}}><b>{fullTotal}</b></td>
                        </tr>
                    </tbody>
                </table>) : ('')}

            </div>
        );
    }
}

OutdoorCabinetOverview.propTypes = {

};


function mapStateToProps(state) {
    //console.log("state", state);
    return {
        organization_count: state.organization.length,
        regions: state.regions,
        depots: state.depots,
        sites_count: state.sites.length,
        vendor_count: state.vendor.length,
        roles_count: state.roles.length,
    };
}

export default connect(mapStateToProps, { getDonationCategories, getData })(
    OutdoorCabinetOverview
);
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class NotAllowed extends Component {
    render() {
        return (
            <div className='row' style={{textAlign:"center"}}>
    <h1>404 - Not Found!</h1>
    <Link to="/">Go Home</Link>
  </div>
        );
    }
}

export default NotAllowed;
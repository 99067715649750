import React, { Component } from "react";
import { connect } from "react-redux";
import { DatePicker, Table, InputNumber, Input, Avatar, Tag, message } from 'antd';
import moment from 'moment';

import { runEquations, dataUpdateGeneralDetails } from "../../api/index";
import { set } from "js-cookie";



class GeneralDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			title: "",
			sec1Item1: '',
			sec1Item2: true,
			sec1Item3: 'show',
			sec2Item1: 'collapsed',
			sec2Item2: false,
			sec2Item3: '',
			switchStatus: true,
			objectSet: {
				"puNoOfwipProjects": 0,
				"sdPowerDependencies": null,
				"sdSAQDependencies": null,
				"sdCivilDependencies": null,
				"lastMonthCommercialPowerConsumption": 0.0,
				"lastMonthSolarGeneration": 0.0,
				"lastMonthGeneratorRunningHours": 0.0,
				"lastMonthPGRunningHours": 0.0,
				"lastMonthCommercialPowerCost": 0.0,
				"lastMonthFuelFillingLiters": 0.0,
				"lastMonthFuelCost": 0.0,
				"lastMonthPGCost": 0.0,




			}
			// sec2Item1: '',
			// sec2Item2: true,
			// sec2Item3: 'show',

		};
	}

	componentDidMount() {
		console.log("sssssssssss")
		console.log(this.props.details)
		this.setState({
			objectSet: {
				"puNoOfwipProjects": this.props.details1?.access_technologies_data?.puNoOfwipProjects,
				"sdPowerDependencies": this.props.details1?.access_technologies_data?.sdPowerDependencies,
				"sdSAQDependencies": this.props.details1?.access_technologies_data?.sdSAQDependencies,
				"sdCivilDependencies": this.props.details1?.access_technologies_data?.sdCivilDependencies,
				"lastMonthCommercialPowerConsumption": this.props.details1?.access_technologies_data?.lastMonthCommercialPowerConsumption,
				"lastMonthSolarGeneration": this.props.details1?.access_technologies_data?.lastMonthSolarGeneration,
				"lastMonthGeneratorRunningHours": this.props.details1?.access_technologies_data?.lastMonthGeneratorRunningHours,
				"lastMonthPGRunningHours": this.props.details1?.access_technologies_data?.lastMonthPGRunningHours,
				"lastMonthCommercialPowerCost": this.props.details1?.access_technologies_data?.lastMonthCommercialPowerCost,
				"lastMonthFuelFillingLiters": this.props.details1?.access_technologies_data?.lastMonthFuelFillingLiters,
				"lastMonthFuelCost": this.props.details1?.access_technologies_data?.lastMonthFuelCost,
				"lastMonthPGCost": this.props.details1?.access_technologies_data?.lastMonthPGCost,




			}
		})
	}

	runEquations = async () => {
		var form = new FormData();
		form.append("siteId", this.props.details.site_details.id);
		var res = await runEquations(form);
		if (res.success) {
			message.success('Successful..!')
			this.props.parentMethod();
		} else {
			message.warning(' Something went wrong!')
		}
	}
	SiteInfoSection(element) {
		if (element == 1) {
			this.setState({
				sec1Item1: this.state.sec1Item1 != '' ? '' : 'collapsed',
				sec1Item2: this.state.sec1Item2 ? false : true,
				sec1Item3: this.state.sec1Item3 != '' ? '' : 'show',
			}, () => {

				if (this.state.sec2Item2 == true) {
					this.setState({
						sec2Item1: this.state.sec2Item1 != '' ? '' : 'collapsed',
						sec2Item2: this.state.sec2Item2 ? false : true,
						sec2Item3: this.state.sec2Item3 != '' ? '' : 'show',
					})
				}
			})

		}

		if (element == 2) {
			this.setState({
				sec2Item1: this.state.sec2Item1 != '' ? '' : 'collapsed',
				sec2Item2: this.state.sec2Item2 ? false : true,
				sec2Item3: this.state.sec2Item3 != '' ? '' : 'show',
			}, () => {
				if (this.state.sec1Item2 == true) {
					this.setState({
						sec1Item1: this.state.sec1Item1 != '' ? '' : 'collapsed',
						sec1Item2: this.state.sec1Item2 ? false : true,
						sec1Item3: this.state.sec1Item3 != '' ? '' : 'show',
					})

				}
			})
		}



	}

	handlsdCivilDependencies = (e) => {
		this.setState({
			objectSet: {
				...this.state.objectSet,
				sdCivilDependencies: e.target.value
			}

		}, () => {
			console.log(this.state.objectSet)
		})

	}
	handlsdSAQDependencies = (e) => {
		this.setState({
			objectSet: {
				...this.state.objectSet,
				sdSAQDependencies: e.target.value
			}

		}, () => {
			console.log(this.state.objectSet)
		})

	}
	handlsdPowerDependencies = (e) => {
		this.setState({
			objectSet: {
				...this.state.objectSet,
				sdPowerDependencies: e.target.value
			}

		}, () => {
			console.log(this.state.objectSet)
		})

	}
	handllastMonthPGCost = (e) => {
		this.setState({
			objectSet: {
				...this.state.objectSet,
				lastMonthPGCost: e
			}

		}, () => {
			console.log(this.state.objectSet)
		})

	}
	handllastMonthFuelCost = (e) => {
		this.setState({
			objectSet: {
				...this.state.objectSet,
				lastMonthFuelCost: e
			}

		}, () => {
			console.log(this.state.objectSet)
		})

	}
	handllastMonthFuelFillingLiters = (e) => {
		this.setState({
			objectSet: {
				...this.state.objectSet,
				lastMonthFuelFillingLiters: e
			}

		}, () => {
			console.log(this.state.objectSet)
		})

	}
	handllastMonthCommercialPowerCost = (e) => {
		this.setState({
			objectSet: {
				...this.state.objectSet,
				lastMonthCommercialPowerCost: e
			}

		}, () => {
			console.log(this.state.objectSet)
		})

	}
	handllastMonthPGRunningHours = (e) => {
		this.setState({
			objectSet: {
				...this.state.objectSet,
				lastMonthPGRunningHours: e
			}

		}, () => {
			console.log(this.state.objectSet)
		})

	}
	handllastMonthGeneratorRunningHours = (e) => {
		this.setState({
			objectSet: {
				...this.state.objectSet,
				lastMonthGeneratorRunningHours: e
			}

		}, () => {
			console.log(this.state.objectSet)
		})

	}
	handllastMonthSolarGeneration = (e) => {
		this.setState({
			objectSet: {
				...this.state.objectSet,
				lastMonthSolarGeneration: e
			}

		}, () => {
			console.log(this.state.objectSet)
		})

	}
	handllastMonthCommercialPowerConsumption = (e) => {
		this.setState({
			objectSet: {
				...this.state.objectSet,
				lastMonthCommercialPowerConsumption: e
			}

		}, () => {
			console.log(this.state.objectSet)
		})

	}
	handlpuNoOfwipProjects = (e) => {
		this.setState({
			objectSet: {
				...this.state.objectSet,
				puNoOfwipProjects: e
			}

		}, () => {
			console.log(this.state.objectSet)
		})

	}

	dataUpdate() {
		console.log("ssss")
		this.setState({
			switchStatus: false
		})
	}
	close = async () => {
		this.props.parentMethod();
		this.setState({
			objectSet: {
				"puNoOfwipProjects": this.props.details1?.access_technologies_data?.puNoOfwipProjects,
				"sdPowerDependencies": this.props.details1?.access_technologies_data?.sdPowerDependencies,
				"sdSAQDependencies": this.props.details1?.access_technologies_data?.sdSAQDependencies,
				"sdCivilDependencies": this.props.details1?.access_technologies_data?.sdCivilDependencies,
				"lastMonthCommercialPowerConsumption": this.props.details1?.access_technologies_data?.lastMonthCommercialPowerConsumption,
				"lastMonthSolarGeneration": this.props.details1?.access_technologies_data?.lastMonthSolarGeneration,
				"lastMonthGeneratorRunningHours": this.props.details1?.access_technologies_data?.lastMonthGeneratorRunningHours,
				"lastMonthPGRunningHours": this.props.details1?.access_technologies_data?.lastMonthPGRunningHours,
				"lastMonthCommercialPowerCost": this.props.details1?.access_technologies_data?.lastMonthCommercialPowerCost,
				"lastMonthFuelFillingLiters": this.props.details1?.access_technologies_data?.lastMonthFuelFillingLiters,
				"lastMonthFuelCost": this.props.details1?.access_technologies_data?.lastMonthFuelCost,
				"lastMonthPGCost": this.props.details1?.access_technologies_data?.lastMonthPGCost,




			}
		})
		this.setState({
			switchStatus: true
		});
	}
	dataSetToUpdate = async () => {

		console.log(this.state.objectSet)
		console.log(this.props.detailsupdateGeneralDetails.updateGeneralDetails)
		let remoteJob = {
			...this.props.detailsupdateGeneralDetails.updateGeneralDetails,
			...this.state.objectSet
		};
		const formData = new FormData();
		formData.append('id', this.props.details1?.access_technologies_data?.id);
		formData.append('object', JSON.stringify(remoteJob));

		var res = await dataUpdateGeneralDetails(formData);

		if (res.success) {
			message.success('Successfully Update!')
			this.props.parentMethod();
			this.setState({
				switchStatus: true
			})
		} else {
			message.warning(' Something went wrong!')
		}


	}

	render() {
		const columns123 = [
			{
				title: "Name",
				key: "Name",
				render: (item) => <span> <img style={{ width: 23 }} src={'https://cdn-icons-png.flaticon.com/512/3135/3135715.png'} />  {item?.name}</span>,
			},
			{
				title: "Mobile Number",
				//   dataIndex: "accessPermissionCode",
				key: "Number",
				render: (item) => <span>{item?.mobileNumber}</span>,
			},
			{
				title: "Email",
				//   dataIndex: "accessPermissionCode",
				key: "Email",
				render: (item) => <span>{item?.email}</span>,
			},
		];
		const { site_details } = this.props.details

		console.log('site_powerData')
		console.log(this.props)

		return (
			<div className="row">
				<div class="col-lg-12">
					<div class="text-end">
						<ul class="list-inline">
							<li class="list-inline-item">
								<button type="button" class="btn btn-soft-primary btn-sm" onClick={this.runEquations}><i class="fas dripicons-toggles me-2"></i>Run Equations</button>
							</li>
						</ul>
					</div>
				</div>

				<div className="col-lg-6 col-xl-4">
					<div class="accordion" id="accordionExample">
						<div class="accordion-item">
							<h5 class="accordion-header m-0" id="headingOne">
								<a href="#accordionExample" class={"accordion-button  fw-semibold " + this.state.sec1Item1} type="button" aria-expanded={this.state.sec1Item2} aria-controls="collapseOne" onClick={() => this.SiteInfoSection(1)} >
									<h5> Site Information Section #1 </h5>
								</a>
							</h5>
							<div id="collapseOne" class={"accordion-collapse collapse " + this.state.sec1Item3} aria-labelledby="headingOne" data-bs-parent="#accordionExample">
								<div class="accordion-body">
									<div class="col-lg-12">
										<ul class="list-group">
											<li class="list-group-item d-flex justify-content-between align-items-center">
												<div>
													<i class="la la-home text-muted font-16 me-2"></i>Operator
												</div>
												<span class="badge badge-outline-primary badge-pill">{site_details?.depot?.region?.organization?.name}</span>
											</li>
											<li class="list-group-item d-flex justify-content-between align-items-center">
												<div>
													<i class="la la-map-pin text-muted font-18 me-2"></i>District
												</div>

												<span class="badge badge-outline-secondary badge-pill">{site_details?.district?.nameEn}</span>
											</li>
											<li class="list-group-item d-flex justify-content-between align-items-center">
												<div>
													<i class="la la-key text-muted font-16 me-2"></i>Site Owner
												</div>
												<span class="badge badge-outline-success badge-pill">{site_details?.siteOwner?.name}</span>
											</li>
											<li class="list-group-item d-flex justify-content-between align-items-center">
												<div>
													<i class="la la-box text-muted font-16 me-2"></i>Site Category
												</div>
												<span class="badge badge-outline-warning">{site_details?.siteCategory?.name}</span>
											</li>
											<li class="list-group-item d-flex justify-content-between align-items-center">
												<div>
													<i class="la la-align-center text-muted font-16 me-2"></i>Site Type
												</div>
												<span class="badge badge-outline-info badge-pill">{site_details?.siteType?.name}</span>
											</li>
											<li class="list-group-item d-flex justify-content-between align-items-center">
												<div>
													<i class="la la-home text-muted font-16 me-2"></i>Site Elevation (m)
												</div>
												<span class="badge badge-outline-info badge-pill">{site_details?.siteElevation}</span>
											</li>
											<li class="list-group-item d-flex justify-content-between align-items-center">
												<div>
													<i class="la la-battery text-muted font-18 me-2"></i>Energy Category
												</div>

												<span class="badge badge-outline-secondary badge-pill">{site_details?.energyCategory?.name}</span>
											</li>
											<li class="list-group-item d-flex justify-content-between align-items-center">
												<div>
													<i class="la la-award text-muted font-16 me-2"></i>Entity
												</div>
												<span class="badge badge-outline-success badge-pill">{site_details?.entity?.name}</span>
											</li>
											<li class="list-group-item d-flex justify-content-between align-items-center">
												<div>
													<i class="la la-rss text-muted font-16 me-2"></i>Indoor/Outdoor
												</div>
												<span class="badge badge-outline-info badge-pill">{site_details?.indoorOutdoor == null ? ('N/A') : (site_details?.indoorOutdoor?.name)}</span>
											</li>
											<li class="list-group-item d-flex justify-content-between align-items-center">
												<div>
													<i class="la la-money text-muted font-18 me-2"></i>Shelter Type
												</div>

												<span class="badge badge-outline-warning">{site_details?.shelterType?.name}</span>
											</li>
											<li class="list-group-item d-flex justify-content-between align-items-center">
												<div>
													<i class="la la-clock text-muted font-16 me-2"></i>Accessible Hours
												</div>
												<span class="badge badge-outline-success badge-pill">{site_details?.weekendsOrHolidaysAccessibleHours}</span>
											</li>
											<li class="list-group-item d-flex justify-content-between align-items-center">
												<div>
													<i class="la la-money text-muted font-18 me-2"></i>Outside Ambient Tempture (Cel)
												</div>
												<span class="badge badge-outline-success badge-pill">{site_details?.ambientTemperature}</span>
											</li>
											<li class="list-group-item d-flex justify-content-between align-items-center">
												<div>
													<i class="la la-map-marker text-muted font-18 me-2"></i>Site Key Location
												</div>
												<span class="badge badge-outline-success badge-pill">{site_details?.keyLocation ? site_details?.keyLocation : 'N/A'}</span>
											</li>
											<li class="list-group-item d-flex justify-content-between align-items-center">
												<div>
													<i class="la la-user text-muted font-18 me-2"></i>Site Owner Name
												</div>
												<span class="badge badge-outline-success badge-pill">{site_details?.siteOwnerNameForAccess ? site_details?.siteOwnerNameForAccess : 'N/A'}</span>
											</li>
											<li class="list-group-item d-flex justify-content-between align-items-center">
												<div>
													<i class="la la-street-view text-muted font-18 me-2"></i>Site Owner Address
												</div>
												<span class="badge badge-outline-success badge-pill">{site_details?.siteOwnerAddressForAccess ? site_details?.siteOwnerAddressForAccess : 'N/A'}</span>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div class="accordion-item">
							<h5 class="accordion-header m-0" id="headingTwo">
								<a href="#accordionExample" class={"accordion-button  fw-semibold " + this.state.sec2Item1} type="button" aria-expanded={this.state.sec2Item2} aria-controls="collapseTwo" onClick={() => this.SiteInfoSection(2)}>
									<h5>Site Information Section #2 </h5>
								</a>
							</h5>
							<div id="collapseTwo" class={"accordion-collapse collapse " + this.state.sec2Item3} aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
								<div class="accordion-body">
									<div class="col-lg-12">
										<ul class="list-group">
											<li class="list-group-item d-flex justify-content-between align-items-center">
												<div>
													<i class="la la-home text-muted font-16 me-2"></i>PIS vendor
												</div>
												<span class="badge badge-outline-primary badge-pill">{site_details?.pisVendor} </span>
											</li>
											<li class="list-group-item d-flex justify-content-between align-items-center">
												<div>
													<i class="la la-map-pin text-muted font-18 me-2"></i>Tenants
												</div>
												<span class="">{this.props.detailssharedOutOperatorses_data?.sharedOutOperatorses_data?.map(txt => <span class="badge badge-outline-warning">{txt.tenant + ''}</span>)}</span>

											</li>
											<li class="list-group-item d-flex justify-content-between align-items-center">
												<div>
													<i class="la la-key text-muted font-16 me-2"></i>No. of tenants
												</div>
												<span class="badge badge-outline-secondary badge-pill">{site_details?.tenants}</span>

											</li>
											<li class="list-group-item d-flex justify-content-between align-items-center">
												<div>
													<i class="la la-box text-muted font-16 me-2"></i>No. of power shared oot tenants
												</div>
												<span class="badge badge-outline-warning">{this.props.detailssharedOutOperatorses_data?.sharedOutOperatorses_data?.length}</span>
											</li>
											<li class="list-group-item d-flex justify-content-between align-items-center">
												<div>
													<i class="la la-align-center text-muted font-16 me-2"></i>Rectification Rank
												</div>
												<span class="badge badge-outline-info badge-pill">{site_details?.rectificationRank}</span>
											</li>
											<li class="list-group-item d-flex justify-content-between align-items-center">
												<div>
													<i class="la la-battery text-muted font-18 me-2"></i>Land Area in perch
												</div>

												<span class="badge badge-outline-secondary badge-pill">{site_details?.landAreainPerch}</span>
											</li>
											<li class="list-group-item d-flex justify-content-between align-items-center">
												<div>
													<i class="la la-award text-muted font-16 me-2"></i>Horizontal Manual Distance (m)
												</div>
												<span class="badge badge-outline-success badge-pill">{site_details?.horizontalManualDisctance}</span>
											</li>
											<li class="list-group-item d-flex justify-content-between align-items-center">
												<div>
													<i class="la la-rss text-muted font-16 me-2"></i>Vertical Manual Disctance (No of Floors)
												</div>
												<span class="badge badge-outline-info badge-pill">{site_details?.verticalManualDisctance}</span>
											</li>
											<li class="list-group-item d-flex justify-content-between align-items-center">
												<div>
													<i class="la la-money text-muted font-18 me-2"></i>Boat Manual Distance by Boat (m)
												</div>

												<span class="badge badge-outline-warning">{site_details?.boatManualdistancebyBoat}</span>
											</li>
											<li class="list-group-item d-flex justify-content-between align-items-center">
												<div>
													<i class="la la-clock text-muted font-16 me-2"></i>Travel time from depot (Minutes)
												</div>
												<span class="badge badge-outline-success badge-pill">{site_details?.travelTimeFrom}</span>
											</li>
											<li class="list-group-item d-flex justify-content-between align-items-center">
												<div>
													<i class="la la-money text-muted font-18 me-2"></i>Weekends/Holidays Accessible hours
												</div>
												<span class="badge badge-outline-success badge-pill">{site_details?.weekendsOrHolidaysAccessibleHours}</span>
											</li>
											<li class="list-group-item d-flex justify-content-between align-items-center">
												<div>
													<i class="la la-money text-muted font-18 me-2"></i>Site On Air Date
												</div>
												<span class="badge badge-outline-success badge-pill">{site_details?.siteOnAirDate}</span>
											</li>




										</ul>
									</div>
								</div>
							</div>
						</div>

					</div>







					<br></br>

					<div className="card">
						<div className="card-header">
							<div className="row align-items-center">
								<div className="col">
									<h4 className="card-title">Power Summary View</h4>
								</div>
							</div>
						</div>
						<div className="card-body" style={{ fontSize: 12 }}>
							<div class="row">
								<div class="col-lg-12">
									<ul class="list-group">
										<li class="list-group-item d-flex justify-content-between align-items-center">
											<div>
												Power Connection type
											</div>
											<span class="badge badge-outline-primary badge-pill">{this.props.details1.access_technologies_data?.mpcPowerConnectionType != null ? (this.props.details1.access_technologies_data?.mpcPowerConnectionType.name) : ('null')}</span>
										</li>
										<li class="list-group-item d-flex justify-content-between align-items-center">
											<div>
												No. of phases
											</div>

											<span class="badge badge-outline-secondary badge-pill">{this.props.details1?.access_technologies_data?.mpcNoOfPhases != null ? (this.props.details1?.access_technologies_data?.mpcNoOfPhases) : ('null')}</span>
										</li>
										<li class="list-group-item d-flex justify-content-between align-items-center">
											<div>
												Installed Solar Capacity (kW)
											</div>
											<span class="">{this.props.details3?.renewableEnergySolars_data?.map(txt => <span class="badge badge-outline-warning">{txt.pvcapacity + ''}</span>)}</span>

										</li>
										<li class="list-group-item d-flex justify-content-between align-items-center">
											<div>
												No of Rectifiers
											</div>
											<span class="badge badge-outline-secondary badge-pill">{site_details?.powerSummaryDetaSet?.SitePowerSummaryDeta[0]?.noofRectifiers != null ? (site_details?.powerSummaryDetaSet?.SitePowerSummaryDeta[0]?.noofRectifiers) : ('null')}</span>

										</li>
										<li class="list-group-item d-flex justify-content-between align-items-center">
											<div>
												Rectifier - Make
											</div>
											<span class="">{this.props.detailsRectifiersData?.rectifiers_data?.map(txt => <span class="badge badge-outline-warning">{txt.make?.name + ''}</span>)}</span>
										</li>
										<li class="list-group-item d-flex justify-content-between align-items-center">
											<div>
												No of Battery Banks
											</div>

											<span class="badge badge-outline-secondary badge-pill">{site_details?.powerSummaryDetaSet?.SitePowerSummaryDeta[0]?.noofBatteryBanks != null ? (site_details?.powerSummaryDetaSet?.SitePowerSummaryDeta[0]?.noofBatteryBanks) : ('null')}</span>

										</li>
										<li class="list-group-item d-flex justify-content-between align-items-center">
											<div>
												BB Type
											</div>
											<span class="">{this.props.detailsBatteryBanksData?.battery_banks_data?.map(txt => <span class="badge badge-outline-success">{txt.bbType?.name + ''}</span>)}</span>


										</li>
										<li class="list-group-item d-flex justify-content-between align-items-center">
											<div>
												Designed Back up time
											</div>
											<span class="badge badge-outline-secondary badge-pill">{site_details?.powerSummaryDetaSet?.SitePowerSummaryDeta[0]?.designedBackUpTime != null ? (site_details?.powerSummaryDetaSet?.SitePowerSummaryDeta[0]?.designedBackUpTime) : ('null')}</span>

										</li>
										<li class="list-group-item d-flex justify-content-between align-items-center">
											<div>
												Actual Back up time
											</div>

											<span class="badge badge-outline-secondary badge-pill">{site_details?.powerSummaryDetaSet?.SitePowerSummaryDeta[0]?.actualBackUpTime != null ? (site_details?.powerSummaryDetaSet?.SitePowerSummaryDeta[0]?.actualBackUpTime) : ('null')}</span>

										</li>
										<li class="list-group-item d-flex justify-content-between align-items-center">
											<div>
												No. of Cabinets
											</div>
											<span class="badge badge-outline-secondary badge-pill">{site_details?.powerSummaryDetaSet?.SitePowerSummaryDeta[0]?.noofCabinets != null ? (site_details?.powerSummaryDetaSet?.SitePowerSummaryDeta[0]?.noofCabinets) : ('null')}</span>

										</li>
										<li class="list-group-item d-flex justify-content-between align-items-center">
											<div>
												No of Air Conditioners
											</div>
											<span class="badge badge-outline-secondary badge-pill">{site_details?.powerSummaryDetaSet?.SitePowerSummaryDeta[0]?.noofAirConditioners != null ? (site_details?.powerSummaryDetaSet?.SitePowerSummaryDeta[0]?.noofAirConditioners) : ('null')}</span>

										</li>
										<li class="list-group-item d-flex justify-content-between align-items-center">
											<div>
												No. of Generators
											</div>
											<span class="badge badge-outline-secondary badge-pill">{site_details?.powerSummaryDetaSet?.SitePowerSummaryDeta[0]?.noofGenerators != null ? (site_details?.powerSummaryDetaSet?.SitePowerSummaryDeta[0]?.noofGenerators) : ('null')}</span>

										</li>
										<li class="list-group-item d-flex justify-content-between align-items-center">
											<div>
												Generator - Capacity(kVA)
											</div>
											<span class="">{this.props.detailsGeneratorsData?.generators_data?.map(txt => <span class="badge badge-outline-warning">{txt.capacity + ''}</span>)}</span>
										</li>
										<li class="list-group-item d-flex justify-content-between align-items-center">
											<div>
												Wind turbine count
											</div>
											<span class="">{this.props.detailsRenewableEnergyWindData?.renewableEnergyWind_data?.map(txt => <span class="badge badge-outline-warning">{txt.windTurbineCount + ''}</span>)}</span>

										</li>

									</ul>
								</div>
							</div>




						</div>
					</div>
				</div>
				<div className="col-lg-6 col-xl-8">
					<div className="card">
						<div className="card-header">
							<div className="row align-items-center">
								<div className="col">
									<h4 className="card-title">Site Status</h4>
								</div>
							</div>
						</div>
						<div className="card-body pt-3">
							<div className="tab-content">

								<div className="tab-pane active" id="Project3_Tab" role="tabpanel">


									<div className="holder">
										<ul className="steppedprogress pt-1">
											<li className="complete"><span>Planing</span></li>
											<li className="complete"><span>SAQ</span></li>
											<li className="complete"><span>Civil Work</span></li>
											<li className="complete finish"><span>Live</span></li>
										</ul>
									</div>

								</div>
							</div>
						</div>
					</div>
					<div className="card">
						<div className="card-header">
							<div className="row align-items-center">
								<div className="col">
									<h4 className="card-title">Tower Details</h4>
									<p class="text-muted mb-0">Details of network tower.</p>
								</div>
							</div>
						</div>
						<div className="card-body pt-3">
							<div className="row">
								<div class="table-responsive">
									<table class="table table-dark2 mb-0">

										<tbody>
											<tr>
												<td scope="row">Tower Height</td>
												<td>: {site_details.towerHeight}</td>
												<td>Tower Category</td>
												<td>: {site_details.towerCategory.name}</td>
											</tr>
											<tr>
												<td scope="row">Tower Type</td>
												<td>: {site_details.towerType.name}</td>
												<td>Tower Legs Count</td>
												<td>: {site_details.towerLegsCount != null ? site_details.towerLegsCount : "N/A"}</td>
											</tr>
											<tr>
												<td scope="row">Manual Distance</td>
												<td>: {site_details.manualDistance !== '' ? site_details.manualDistance : "N/A"}</td>
												<td> Access Permission Providing by</td>
												<td>: {site_details.accessPermissionProvidingParty !== "" ? site_details.accessPermissionProvidingParty : "N/A"}</td>
											</tr>
											<tr>
												<td scope="row">Distance From Regional Office</td>
												<td>: {site_details.distanceFromRegionalOffice != null ? site_details.distanceFromRegionalOffice + " Km" : "N/A"} </td>
												<td> Capacity PePhase</td>
												<td>: {site_details.capacityPePhase !== '' ? site_details.capacityPePhase : "N/A"}</td>
											</tr>
											<tr>
												<td scope="row">PG Possibility</td>
												<td>: {site_details.pgPossibility != null ? site_details.pgPossibility : "N/A"}  </td>
												<td> Tower Owner Category</td>
												<td>: {site_details.towerOwnerCategory !== null ? site_details.towerOwnerCategory : "N/A"}</td>
											</tr>
											<tr>
												<td scope="row">PG connecting setup availability</td>
												<td>: {site_details.portablegenConnectingSetupAvailability ? "Yes" : "No"}  </td>

											</tr>
										</tbody>
									</table>
								</div>


							</div>

						</div>
					</div>
					<div className="card">
						<div className="card-header">
							<div className="row align-items-center">
								<div className="col">
									<h4 className="card-title">Region Details</h4>
									<p class="text-muted mb-0">Geographical region of network tower.</p>
								</div>
							</div>
						</div>
						<div className="card-body pt-3">
							<table class="table table-dark1 mb-0">

								<tbody>
									<tr>
										<td scope="row" >Region Name</td>
										<td>: {site_details.depot.region.name}</td>
										<td>ParentRegion Region</td>
										<td>: {site_details.depot.region.parentRegion != null ? site_details.depot.region.parentRegion.name : site_details.depot.region.name}</td>
									</tr>

								</tbody>
							</table>

						</div>
					</div>


					<div className="card">
						<div className="card-header">
							<div className="row align-items-center">
								<div className="col">
									<h4 className="card-title">Depot Details</h4>
									<p class="text-muted mb-0">
										<span className="text-muted fw-normal">Depot Name : <x style={{ color: '#57B39C' }}>{site_details.depot.name} </x>  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   Depot description:  <x style={{ color: '#57B39C' }}>{site_details.depot.description}</x></span>

									</p>
								</div>
							</div>
						</div>
						<div className="card-body pt-3">

							<div className="row">

								<Table
									size='small'
									fontSize={9}
									columns={columns123}
									bordered
									pagination={false}
									loading={this.state.loading}
									dataSource={site_details.depot != null && site_details.depot.depotOfficers}
									title={() => <h6 className="fw-semibold m-0">Depot Officers:</h6>}
								/>
							</div>
						</div>
					</div>
					<div className='row'>

						<div className='col-md-12'>
							<div class="card">
								<div class="card-header">
									<div class="d-flex justify-content-between align-items-center">
										<h4 class="card-title">Other details</h4>
										{this.state.switchStatus
											? <button type="button" class="btn btn-soft-primary btn-icon-square-sm" onClick={() => this.dataUpdate()}> <i class="mdi mdi-pencil-outline"></i></button>
											: <div class="d-flex justify-content-end align-items-center">
												<button type="button" class="btn btn-soft-secondary " onClick={() => this.close()} style={{ marginRight: "10px" }}> Cancel </button>
												<button type="button" class="btn btn-soft-primary " onClick={() => this.dataSetToUpdate()}> Submit to Update </button>
											</div>
										}
									</div>
								</div>
								<div class="card-body">
									<div class="row">
										<div className='col-md-12'>
											<ul class="list-group">

												<li class="list-group-item d-flex justify-content-between align-items-center">
													<div>
														<i class="la la-hand-o-right text-primary me-2"></i> No. of  WIP projects

													</div>
													{/* {this.props.details1?.access_technologies_data?.noOfAirConditioners != null ? (<span class="badge badge-outline-success">{this.props.details1?.access_technologies_data?.noOfAirConditioners}</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>)} */}
													{this.state.switchStatus ? (<>{this.props.details1?.access_technologies_data?.puNoOfwipProjects != null ? (<span class="badge badge-outline-success">{this.props.details1?.access_technologies_data?.puNoOfwipProjects}</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>)}</>) : (<>  <InputNumber min={0} style={{ width: '20%' }} value={this.state.objectSet?.puNoOfwipProjects} id="puNoOfwipProjects" onChange={this.handlpuNoOfwipProjects} /> </>)}





												</li>
												<li class="list-group-item d-flex justify-content-between align-items-center">
													<div>
														<i class="la la-hand-o-right text-primary me-2"></i> Power Dependencies

													</div>
													{/* {this.props.details1?.access_technologies_data?.noOfAirConditioners != null ? (<span class="badge badge-outline-success">{this.props.details1?.access_technologies_data?.noOfAirConditioners}</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>)} */}
													{this.state.switchStatus ? (<>{this.props.details1?.access_technologies_data?.sdPowerDependencies != null ? (<span class="badge badge-outline-success">{this.props.details1?.access_technologies_data?.sdPowerDependencies}</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>)}</>) : (<>  <Input style={{ width: '20%' }} value={this.state.objectSet?.sdPowerDependencies} id="sdPowerDependencies" onChange={this.handlsdPowerDependencies} /> </>)}





												</li>
												<li class="list-group-item d-flex justify-content-between align-items-center">
													<div>
														<i class="la la-hand-o-right text-primary me-2"></i> SAQ Dependencies

													</div>
													{/* {this.props.details1?.access_technologies_data?.noOfAirConditioners != null ? (<span class="badge badge-outline-success">{this.props.details1?.access_technologies_data?.noOfAirConditioners}</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>)} */}
													{this.state.switchStatus ? (<>{this.props.details1?.access_technologies_data?.sdSAQDependencies != null ? (<span class="badge badge-outline-success">{this.props.details1?.access_technologies_data?.sdSAQDependencies}</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>)}</>) : (<>  <Input style={{ width: '20%' }} value={this.state.objectSet?.sdSAQDependencies} id="sdSAQDependencies" onChange={this.handlsdSAQDependencies} /> </>)}





												</li>
												<li class="list-group-item d-flex justify-content-between align-items-center">
													<div>
														<i class="la la-hand-o-right text-primary me-2"></i> Civil Dependencies

													</div>
													{/* {this.props.details1?.access_technologies_data?.noOfAirConditioners != null ? (<span class="badge badge-outline-success">{this.props.details1?.access_technologies_data?.noOfAirConditioners}</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>)} */}
													{this.state.switchStatus ? (<>{this.props.details1?.access_technologies_data?.sdCivilDependencies != null ? (<span class="badge badge-outline-success">{this.props.details1?.access_technologies_data?.sdCivilDependencies}</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>)}</>) : (<>  <Input style={{ width: '20%' }} value={this.state.objectSet?.sdCivilDependencies} id="sdCivilDependencies" onChange={this.handlsdCivilDependencies} /> </>)}





												</li>
												<li class="list-group-item d-flex justify-content-between align-items-center">
													<div>
														<i class="la la-hand-o-right text-primary me-2"></i> Last Month Commercial Power  Consumption  (kWh)

													</div>
													{/* {this.props.details1?.access_technologies_data?.noOfAirConditioners != null ? (<span class="badge badge-outline-success">{this.props.details1?.access_technologies_data?.noOfAirConditioners}</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>)} */}
													{this.state.switchStatus ? (<>{this.props.details1?.access_technologies_data?.lastMonthCommercialPowerConsumption != null ? (<span class="badge badge-outline-success">{this.props.details1?.access_technologies_data?.lastMonthCommercialPowerConsumption}</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>)}</>) : (<>  <InputNumber min={0} style={{ width: '20%' }} value={this.state.objectSet?.lastMonthCommercialPowerConsumption} id="lastMonthCommercialPowerConsumption" onChange={this.handllastMonthCommercialPowerConsumption} /> </>)}





												</li>
												<li class="list-group-item d-flex justify-content-between align-items-center">
													<div>
														<i class="la la-hand-o-right text-primary me-2"></i> Last Month Solar Generation  (kWh)

													</div>
													{/* {this.props.details1?.access_technologies_data?.noOfAirConditioners != null ? (<span class="badge badge-outline-success">{this.props.details1?.access_technologies_data?.noOfAirConditioners}</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>)} */}
													{this.state.switchStatus ? (<>{this.props.details1?.access_technologies_data?.lastMonthSolarGeneration != null ? (<span class="badge badge-outline-success">{this.props.details1?.access_technologies_data?.lastMonthSolarGeneration}</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>)}</>) : (<>  <InputNumber min={0} style={{ width: '20%' }} value={this.state.objectSet?.lastMonthSolarGeneration} id="lastMonthSolarGeneration" onChange={this.handllastMonthSolarGeneration} /> </>)}





												</li>
												<li class="list-group-item d-flex justify-content-between align-items-center">
													<div>
														<i class="la la-hand-o-right text-primary me-2"></i> Last Month Generator Running Hours (Hr)

													</div>
													{/* {this.props.details1?.access_technologies_data?.noOfAirConditioners != null ? (<span class="badge badge-outline-success">{this.props.details1?.access_technologies_data?.noOfAirConditioners}</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>)} */}
													{this.state.switchStatus ? (<>{this.props.details1?.access_technologies_data?.lastMonthGeneratorRunningHours != null ? (<span class="badge badge-outline-success">{this.props.details1?.access_technologies_data?.lastMonthGeneratorRunningHours}</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>)}</>) : (<>  <InputNumber min={0} style={{ width: '20%' }} value={this.state.objectSet?.lastMonthGeneratorRunningHours} id="lastMonthGeneratorRunningHours" onChange={this.handllastMonthGeneratorRunningHours} /> </>)}





												</li>
												<li class="list-group-item d-flex justify-content-between align-items-center">
													<div>
														<i class="la la-hand-o-right text-primary me-2"></i> Last Month PG Running Hours (Hr)

													</div>
													{/* {this.props.details1?.access_technologies_data?.noOfAirConditioners != null ? (<span class="badge badge-outline-success">{this.props.details1?.access_technologies_data?.noOfAirConditioners}</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>)} */}
													{this.state.switchStatus ? (<>{this.props.details1?.access_technologies_data?.lastMonthPGRunningHours != null ? (<span class="badge badge-outline-success">{this.props.details1?.access_technologies_data?.lastMonthPGRunningHours}</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>)}</>) : (<>  <InputNumber min={0} style={{ width: '20%' }} value={this.state.objectSet?.lastMonthPGRunningHours} id="lastMonthPGRunningHours" onChange={this.handllastMonthPGRunningHours} /> </>)}





												</li>
												<li class="list-group-item d-flex justify-content-between align-items-center">
													<div>
														<i class="la la-hand-o-right text-primary me-2"></i> Last Month Commercial Power Cost (LKR)

													</div>
													{/* {this.props.details1?.access_technologies_data?.noOfAirConditioners != null ? (<span class="badge badge-outline-success">{this.props.details1?.access_technologies_data?.noOfAirConditioners}</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>)} */}
													{this.state.switchStatus ? (<>{this.props.details1?.access_technologies_data?.lastMonthCommercialPowerCost != null ? (<span class="badge badge-outline-success">{this.props.details1?.access_technologies_data?.lastMonthCommercialPowerCost}</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>)}</>) : (<>  <InputNumber min={0} style={{ width: '20%' }} value={this.state.objectSet?.lastMonthCommercialPowerCost} id="lastMonthCommercialPowerCost" onChange={this.handllastMonthCommercialPowerCost} /> </>)}





												</li>
												<li class="list-group-item d-flex justify-content-between align-items-center">
													<div>
														<i class="la la-hand-o-right text-primary me-2"></i> Last Month Fuel Filling  Liters

													</div>
													{/* {this.props.details1?.access_technologies_data?.noOfAirConditioners != null ? (<span class="badge badge-outline-success">{this.props.details1?.access_technologies_data?.noOfAirConditioners}</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>)} */}
													{this.state.switchStatus ? (<>{this.props.details1?.access_technologies_data?.lastMonthFuelFillingLiters != null ? (<span class="badge badge-outline-success">{this.props.details1?.access_technologies_data?.lastMonthFuelFillingLiters}</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>)}</>) : (<>  <InputNumber min={0} style={{ width: '20%' }} value={this.state.objectSet?.lastMonthFuelFillingLiters} id="lastMonthFuelFillingLiters" onChange={this.handllastMonthFuelFillingLiters} /> </>)}





												</li>
												<li class="list-group-item d-flex justify-content-between align-items-center">
													<div>
														<i class="la la-hand-o-right text-primary me-2"></i> Last Month Fuel Cost (LKR)

													</div>
													{/* {this.props.details1?.access_technologies_data?.noOfAirConditioners != null ? (<span class="badge badge-outline-success">{this.props.details1?.access_technologies_data?.noOfAirConditioners}</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>)} */}
													{this.state.switchStatus ? (<>{this.props.details1?.access_technologies_data?.lastMonthFuelCost != null ? (<span class="badge badge-outline-success">{this.props.details1?.access_technologies_data?.lastMonthFuelCost}</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>)}</>) : (<>  <InputNumber min={0} style={{ width: '20%' }} value={this.state.objectSet?.lastMonthFuelCost} id="airCoolingMethod" onChange={this.handllastMonthFuelCost} /> </>)}





												</li>
												<li class="list-group-item d-flex justify-content-between align-items-center">
													<div>
														<i class="la la-hand-o-right text-primary me-2"></i> Last Month PG Cost (LKR)

													</div>
													{/* {this.props.details1?.access_technologies_data?.noOfAirConditioners != null ? (<span class="badge badge-outline-success">{this.props.details1?.access_technologies_data?.noOfAirConditioners}</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>)} */}
													{this.state.switchStatus ? (<>{this.props.details1?.access_technologies_data?.lastMonthPGCost != null ? (<span class="badge badge-outline-success">{this.props.details1?.access_technologies_data?.lastMonthPGCost}</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>)}</>) : (<>  <InputNumber min={0} style={{ width: '20%' }} value={this.state.objectSet?.lastMonthPGCost} id="airCoolingMethod" onChange={this.handllastMonthPGCost} /> </>)}





												</li>


											</ul>
										</div>





									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}




export default GeneralDetails;

export const data_set = {
    "data": [{
        "id": 1,
        "import_sheet_name": "General",
    }],
    "fields": [
        // Data Values Related To The Site
        {
            "value": 1,
            "detail_view": false,
            "field_name": "site_id",
            "display_name": "site_id_value",
            "label": "Site- Site Id",
            "table_name": "site",
            "is_primary_table": true,
            "table_prefix": "tbl_site",
            "response_type": "text",
            "operotors": [{
                "key": 1,
                "lable": "=",
                "value": "="
            }]
        },
        {
            "value": 2,
            "field_name": "site_name",
            "label": "Site- Site Name",
            "display_name": "site_name_value",
            "table_name": "site",
            "is_primary_table": true,
            "table_prefix": "tbl_site",
            "response_type": "text",
            "query_name": "SELECT `site_name` AS `name`, `id` FROM `site`",
            "extra_fields": ['name', 'id'],
            "detail_view": true,
            "operotors": [{
                "key": 1,
                "key": "LIKE",
                "value": "LIKE"
            }, {
                "key": 2,
                "key": "=",
                "value": "="
            }]
        },
        {
            "value": 3,
            "field_name": "name",
            "display_name": "indoor_outdoor_type_value",
            "label": "Site - Indoor/Outdoor Condition Type",
            "is_primary_table": false,
            "table_name": "indoor_outdoor_type",
            "table_prefix": "tbl_indoor_outdoor_type",
            "primary_table_name": "site",
            "primary_table_prefix": "tbl_site",
            "response_type": "text",
            "detail_view": true,
            "extra_fields": ['name', 'id'],
            "query_name": "SELECT `name`, `id` FROM `indoor_outdoor_type`",
            "operotors": [{
                "key": 1,
                "key": "equals",
                "value": "="
            }, {
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 4,
            "field_name": "rectification_rank",
            "display_name": "rectification_rank_value",
            "label": "Site- Rectification Rank",
            "table_name": "site",
            "is_primary_table": true,
            "table_prefix": "tbl_site",
            "response_type": "text",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 5,
            "field_name": "mpc_no_of_phases",
            "display_name": "site_no_of_phases_value",
            "label": "Main Power Connection - No of Phases",
            "table_name": "site_power_db",
            "is_primary_table": true,
            "table_prefix": "tbl_site_power_db",
            "response_type": "text",
            "detail_view": true,
            "query_name": "SELECT `mpc_no_of_phases` AS `name`, `id` FROM `site_power_db`",
            "extra_fields": ['name', 'id'],
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 6,
            "field_name": "mpc_capacity_per_phase",
            "display_name": "capacity_pe_phase_value",
            "label": "Site- Capacity Per Phase (A)",
            "table_name": "site_power_db",
            "is_primary_table": true,
            "table_prefix": "tbl_site_power_db",
            "response_type": "number",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            },{
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 7,
            "field_name": "pg_possibility",
            "display_name": "pg_possibility_value",
            "label": "Site- PG Possibility",
            "table_name": "site",
            "is_primary_table": true,
            "table_prefix": "tbl_site",
            "response_type": "text",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 8,
            "field_name": "number_of_generator_hut",
            "display_name": "number_of_generator_hut_value",
            "label": "Generator Hut - Number Of Generator Hut",
            "table_name": "site",
            "is_primary_table": true,
            "table_prefix": "tbl_site",
            "response_type": "number",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            },
            {
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 9,
            "field_name": "per_panel_capacity",
            "display_name": "per_panel_capacity_value",
            "label": "Solar- Installed Solar Capacity (kW)",
            "is_primary_table": false,
            "table_name": "solar_panel_model",
            "table_prefix": "tbl_solar_panel_model",
            "primary_table_name": "renewable_energy_solar",
            "primary_table_prefix": "tbl_renewable_energy_solar",
            "response_type": "number",
            "detail_view": false,
            "extra_fields": ['name', 'id'],
            "query_name": "SELECT `name`, `id` FROM `cabinet_make`",
            "operotors": [{
                "key": 1,
                "key": "equals",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            }, {
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 10,
            "field_name": "pv_make",
            "display_name": "solar_panel_make_value",
            "label": "Solar- Solar Panel Make",
            "table_name": "renewable_energy_solar",
            "is_primary_table": true,
            "table_prefix": "tbl_renewable_energy_solar",
            "response_type": "text",
            "query_name": "SELECT `pv_make` AS `name`, `id` FROM `renewable_energy_solar`",
            "extra_fields": ['name', 'id'],
            "detail_view": true,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 11,
            "field_name": "name",
            "display_name": "solar_panel_model_value",
            "label": "Solar- Solar Panel Model Name",
            "is_primary_table": false,
            "table_name": "solar_panel_model",
            "table_prefix": "tbl_solar_panel_model",
            "primary_table_name": "renewable_energy_solar",
            "primary_table_prefix": "tbl_renewable_energy_solar",
            "response_type": "text",
            "detail_view": true,
            "extra_fields": ['name', 'id'],
            "query_name": "SELECT `name`, `id` FROM `solar_panel_model`",
            "operotors": [{
                "key": 1,
                "key": "equals",
                "value": "="
            }, {
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 12,
            "field_name": "per_wind_turbine_capacity",
            "display_name": "per_wind_turbine_capacity_value",
            "label": "Wind- Wind Turbine Capacity of a single unit (kW)",
            "table_name": "renewable_energy_wind",
            "is_primary_table": true,
            "table_prefix": "tbl_renewable_energy_wind",
            "response_type": "text",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            }, {
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 13,
            "field_name": "wind_turbine_count",
            "display_name": "wind_turbine_count_value",
            "label": "Wind- Wind Turbine Count",
            "table_name": "renewable_energy_wind",
            "is_primary_table": true,
            "table_prefix": "tbl_renewable_energy_wind",
            "response_type": "number",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            }, {
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 14,
            "field_name": "make_id",
            "display_name": "make_id_value",
            "label": "Wind- Wind Turbine Make ID",
            "table_name": "renewable_energy_wind",
            "is_primary_table": true,
            "table_prefix": "tbl_renewable_energy_wind",
            "response_type": "number",
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            },
            {
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 15,
            "field_name": "tenant",
            "display_name": "tenant_value",
            "label": "S.O.O- Tenants",
            "table_name": "shared_out_operators",
            "is_primary_table": true,
            "table_prefix": "tbl_shared_out_operators",
            "response_type": "text",
            "detail_view": true,
            "extra_fields": ['name', 'id'],
            "query_name": "SELECT `tenant` AS `name`, `id` FROM `shared_out_operators`",
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 16,
            "field_name": "shared_power_type",
            "display_name": "shared_power_type_value",
            "label": "S.O.O- Shared Power Type",
            "table_name": "shared_out_operators",
            "is_primary_table": true,
            "table_prefix": "tbl_shared_out_operators",
            "response_type": "text",
            "detail_view": true,
            "extra_fields": ['name', 'id'],
            "query_name": "SELECT `shared_power_type` AS `name`, `id` FROM `shared_out_operators`",
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 17,
            "field_name": "commercial_power_noof_phases",
            "display_name": "commercial_power_noof_phases_value",
            "label": "S.O.O- Commercial Power- No of Phases",
            "table_name": "shared_out_operators",
            "is_primary_table": true,
            "table_prefix": "tbl_shared_out_operators",
            "response_type": "number",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            }, {
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 18,
            "field_name": "commercial_power_per_phase_capacity",
            "display_name": "commercial_power_per_phase_capacity_value",
            "label": "S.O.O- Commercial Power- Per Phase Capacity (A)",
            "table_name": "shared_out_operators",
            "is_primary_table": true,
            "table_prefix": "tbl_shared_out_operators",
            "response_type": "number",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            }, {
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 19,
            "field_name": "genpower_noof_phases",
            "display_name": "genpower_noof_phases_value",
            "label": "S.O.O- Gen Power- No of Phases",
            "table_name": "shared_out_operators",
            "is_primary_table": true,
            "table_prefix": "tbl_shared_out_operators",
            "response_type": "number",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            }, {
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 20,
            "field_name": "gen_power_per_phase_capacity",
            "display_name": "gen_power_per_phase_capacity_value",
            "label": "S.O.O- Gen Power- Per Phase Capacity (A)",
            "table_name": "shared_out_operators",
            "is_primary_table": true,
            "table_prefix": "tbl_shared_out_operators",
            "response_type": "number",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            }, {
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 21,
            "field_name": "dc_power_capacity",
            "display_name": "dc_power_capacity_value",
            "label": "S.O.O- DC power capacity (A)",
            "table_name": "shared_out_operators",
            "is_primary_table": true,
            "table_prefix": "tbl_shared_out_operators",
            "response_type": "number",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            }, {
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 22,
            "field_name": "equipment_space",
            "display_name": "equipment_space_value",
            "label": "Cabinet- Equipment Space (U)",
            "is_primary_table": false,
            "table_name": "cabinet_model",
            "table_prefix": "tbl_cabinet_model",
            "primary_table_name": "cabinet",
            "primary_table_prefix": "tbl_cabinet",
            "response_type": "text",
            "detail_view": false,
            "extra_fields": ['name', 'id'],
            "query_name": "SELECT `name`, `id` FROM `cabinet_make`",
            "operotors": [{
                "key": 1,
                "key": "equals",
                "value": "="
            }, {
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 23,
            "field_name": "utilized_equipment_space",
            "display_name": "utilized_equipment_space_value",
            "label": "Cabinet- Utilized Equipment Space (U)",
            "table_name": "cabinet",
            "is_primary_table": true,
            "table_prefix": "tbl_cabinet",
            "response_type": "number",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            },{
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 24,
            "field_name": "battery_space",
            "display_name": "battery_space_value",
            "label": "Cabinet- Battery Space (U)",
            "is_primary_table": false,
            "table_name": "cabinet_model",
            "table_prefix": "tbl_cabinet_model",
            "primary_table_name": "cabinet",
            "primary_table_prefix": "tbl_cabinet",
            "response_type": "number",
            "detail_view": false,
            "extra_fields": ['name', 'id'],
            "query_name": "SELECT `name`, `id` FROM `cabinet_make`",
            "operotors": [{
                "key": 1,
                "key": "equals",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            },{
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 25,
            "field_name": "utilized_battery_space",
            "display_name": "utilized_battery_space_value",
            "label": "Cabinet- Utilized Battery Space (U)",
            "table_name": "cabinet",
            "is_primary_table": true,
            "table_prefix": "tbl_cabinet",
            "response_type": "number",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            },{
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 26,
            "field_name": "install_battery_capercity",
            "display_name": "install_battery_capercity_value",
            "label": "Cabinet- Installed Battery Capacity (A/H)",
            "table_name": "cabinet",
            "is_primary_table": true,
            "table_prefix": "tbl_cabinet",
            "response_type": "number",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            }, {
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 27,
            "field_name": "name",
            "display_name": "cab_make_name_value",
            "label": "Cabinet- Cabinet Make Name",
            "is_primary_table": false,
            "table_name": "cabinet_make",
            "table_prefix": "tbl_cabinet_make",
            "primary_table_name": "cabinet_model",
            "primary_table_prefix": "tbl_cabinet_model",
            "response_type": "text",
            "detail_view": true,
            "extra_fields": ['name', 'id'],
            "query_name": "SELECT `name`, `id` FROM `cabinet_make`",
            "operotors": [{
                "key": 1,
                "key": "equals",
                "value": "="
            }, {
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 28,
            "field_name": "name",
            "display_name": "cab_model_name_value",
            "label": "Cabinet- Cabinet Model Name",
            "is_primary_table": false,
            "table_name": "cabinet_model",
            "table_prefix": "tbl_cabinet_model",
            "primary_table_name": "cabinet",
            "primary_table_prefix": "tbl_cabinet",
            "response_type": "text",
            "detail_view": true,
            "extra_fields": ['name', 'id'],
            "query_name": "SELECT `name`, `id` FROM `cabinet_model`",
            "operotors": [{
                "key": 1,
                "key": "equals",
                "value": "="
            }, {
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 29,
            "field_name": "total_capacity",
            "display_name": "total_capacity_value",
            "label": "Battery Bank- Total Capacity(A/H)",
            "table_name": "battery_bank",
            "is_primary_table": true,
            "table_prefix": "tbl_battery_bank",
            "response_type": "number",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            }, {
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 31,
            "field_name": "id_battery_rack_model",
            "display_name": "id_battery_rack_model_value",
            "label": "Battery Rack - ID Battery Rack Model",
            "table_name": "id_battery_rack",
            "is_primary_table": true,
            "table_prefix": "tbl_id_battery_rack",
            "response_type": "text",
            "detail_view": true,
            "extra_fields": ['name', 'id'],
            "query_name": "SELECT `id_battery_rack_model` AS `name`, `id` FROM `id_battery_rack`",
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 32,
            "field_name": "id_battery_rack_model_count",
            "display_name": "id_battery_rack_model_count_value",
            "label": "Battery Rack- ID Battery Rack Model Count",
            "table_name": "id_battery_rack",
            "is_primary_table": true,
            "table_prefix": "tbl_id_battery_rack",
            "response_type": "number",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            }, {
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 33,
            "field_name": "type",
            "display_name": "type_value_aircon",
            "label": "Air Conditioner- Type",
            "table_name": "air_conditioner",
            "is_primary_table": true,
            "table_prefix": "tbl_air_conditioner",
            "response_type": "text",
            "query_name": "SELECT `type` AS `name`, `id` FROM `air_conditioner`",
            "extra_fields": ['name', 'id'],
            "detail_view": true,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 34,
            "field_name": "name",
            "display_name": "air_conditioner_make_vale",
            "label": "Air Conditioner- Make Name",
            "table_name": "ac_make",
            "is_primary_table": false,
            "primary_table_name": "air_conditioner",
            "table_prefix": "tbl_ac_make",
            "primary_table_prefix": "tbl_air_conditioner",
            "response_type": "text",
            "detail_view": true,
            "extra_fields": ['name', 'id'],
            "query_name": "SELECT `name`, `id` FROM `ac_make`",
            "operotors": [{
                "key": 1,
                "key": "equals",
                "value": "="
            }, {
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 35,
            "field_name": "capacity",
            "display_name": "air_conditioner_capacity_value",
            "label": "Air Conditioner- Capacity (BTU/H)",
            "table_name": "ac_make",
            "is_primary_table": false,
            "primary_table_name": "air_conditioner",
            "table_prefix": "tbl_ac_make",
            "primary_table_prefix": "tbl_air_conditioner",
            "response_type": "number",
            "detail_view": false,
            "extra_fields": ['name', 'id'],
            "query_name": "SELECT `capacity` as `name`, `id` FROM `ac_make`",
            "operotors": [{
                "key": 1,
                "key": "equals",
                "value": "="
            },
            {
                "key": 2,
                "key": ">",
                "value": ">"
            },
            {
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 36,
            "field_name": "pc_total_capacity_available_kw",
            "display_name": "pc_total_capacity_available_kw_value",
            "label": "Power Capacity (kW) - Total Capacity Available (KW)",
            "table_name": "site_power_db",
            "is_primary_table": true,
            "table_prefix": "tbl_site_power_db",
            "response_type": "number",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            },
            {
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 37,
            "field_name": "pc_used_capacity_with_battery_charging",
            "display_name": "pc_used_capacity_with_battery_charging_value",
            "label": "Power Capacity (kW) - Used Capacity with Battery Charging (10%)",
            "table_name": "site_power_db",
            "is_primary_table": true,
            "table_prefix": "tbl_site_power_db",
            "response_type": "number",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            },
            {
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 38,
            "field_name": "pc_remaining_capacity",
            "display_name": "pc_remaining_capacity_value",
            "label": "Power Capacity (kW) - Remaining Capacity(KW)",
            "table_name": "site_power_db",
            "is_primary_table": true,
            "table_prefix": "tbl_site_power_db",
            "response_type": "number",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            },
            {
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 39,
            "field_name": "dcl_peak_dc_load",
            "display_name": "dcl_peak_dc_load_value",
            "label": "S.P.D- Peak DC Load (kW)",
            "table_name": "site_power_db",
            "is_primary_table": true,
            "table_prefix": "tbl_site_power_db",
            "response_type": "number",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            },
            {
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 40,
            "field_name": "dcl_dc_load",
            "display_name": "dcl_dc_load_value",
            "label": "DC Load - DC Load (kW)",
            "table_name": "site_power_db",
            "is_primary_table": true,
            "table_prefix": "tbl_site_power_db",
            "response_type": "number",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            },
            {
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 41,
            "field_name": "no_of_rectifiers",
            "display_name": "no_of_rectifiers_value",
            "label": "Rectifiers - No of Rectifiers",
            "table_name": "site_power_db",
            "is_primary_table": true,
            "table_prefix": "tbl_site_power_db",
            "response_type": "number",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            }, {
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 42,
            "field_name": "no_of_battery_banks",
            "display_name": "no_of_battery_banks_value",
            "label": "Battery Banks - Number Of Battery Banks",
            "table_name": "site_power_db",
            "is_primary_table": true,
            "table_prefix": "tbl_site_power_db",
            "response_type": "number",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            }, {
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 43,
            "field_name": "bb_designed_backup_time",
            "display_name": "bb_designed_backup_time_value",
            "label": "Battery Banks - Designed Backup Time(Hrs)",
            "table_name": "site_power_db",
            "is_primary_table": true,
            "table_prefix": "tbl_site_power_db",
            "response_type": "number",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            }, {
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 44,
            "field_name": "bb_actual_backup_time",
            "display_name": "bb_actual_backup_time_value",
            "label": "Battery Banks - Actual Backup Time(Hrs)",
            "table_name": "site_power_db",
            "is_primary_table": true,
            "table_prefix": "tbl_site_power_db",
            "response_type": "number",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            }, {
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 45,
            "field_name": "mpc_separate_feeder_availablity",
            "display_name": "mpc_separate_feeder_availablity_value",
            "label": "Main Power Connection - Separate Feeder Availability",
            "table_name": "site_power_db",
            "is_primary_table": true,
            "table_prefix": "tbl_site_power_db",
            "response_type": "text",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }
            ]
        },
        {
            "value": 46,
            "field_name": "no_of_cabinets",
            "display_name": "no_of_cabinets_value",
            "label": "Cabinets - No. of Cabinets",
            "table_name": "site_power_db",
            "is_primary_table": true,
            "table_prefix": "tbl_site_power_db",
            "response_type": "number",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            }, {
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 47,
            "field_name": "c_no_of_cabinets_with_equipment_installed",
            "display_name": "c_no_of_cabinets_with_equipment_installed_value",
            "label": "Cabinets - No of Cabinets with Equipment installed",
            "table_name": "site_power_db",
            "is_primary_table": true,
            "table_prefix": "tbl_site_power_db",
            "response_type": "number",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            }, {
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 48,
            "field_name": "no_of_air_conditioners",
            "display_name": "no_of_air_conditioners_value",
            "label": "Air Conditioners - No of Air Conditioners",
            "table_name": "site_power_db",
            "is_primary_table": true,
            "table_prefix": "tbl_site_power_db",
            "response_type": "number",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            }, {
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 49,
            "field_name": "no_of_atss",
            "display_name": "no_of_atss_value",
            "label": "ATS - No.of ATSs",
            "table_name": "site_power_db",
            "is_primary_table": true,
            "table_prefix": "tbl_site_power_db",
            "response_type": "number ",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            }, {
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 50,
            "field_name": "no_of_spds",
            "display_name": "no_of_spds_value",
            "label": "SPDs - No. of SPDs",
            "table_name": "site_power_db",
            "is_primary_table": true,
            "table_prefix": "tbl_site_power_db",
            "response_type": "number",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            }, {
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 51,
            "field_name": "ear_er_value",
            "display_name": "ear_er_value_value",
            "label": "Earthing - Latest ER Value (Ohms)",
            "table_name": "site_power_db",
            "is_primary_table": true,
            "table_prefix": "tbl_site_power_db",
            "response_type": "number",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            }, {
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 52,
            "field_name": "soo_ceb_leco_depot",
            "display_name": "soo_ceb_leco_depot_value",
            "label": "Shared Out -Operators - CEB/LECO Depot",
            "table_name": "site_power_db",
            "is_primary_table": true,
            "table_prefix": "tbl_site_power_db",
            "response_type": "text",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 53,
            "field_name": "no_of_power_share_out_operators",
            "display_name": "no_of_power_share_out_operators_value",
            "label": "Shared Out -Operators - No. of power share out operators",
            "table_name": "site_power_db",
            "is_primary_table": true,
            "table_prefix": "tbl_site_power_db",
            "response_type": "number",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            }, {
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 54,
            "field_name": "sbp_shared_from",
            "display_name": "sbp_shared_from_value",
            "label": "Shared Backup Power - Shared From",
            "table_name": "site_power_db",
            "is_primary_table": true,
            "table_prefix": "tbl_site_power_db",
            "response_type": "text",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 55,
            "field_name": "no_of_fcm",
            "display_name": "no_of_fcm_value",
            "label": "Shared Backup Power - No Of FCB",
            "table_name": "site_power_db",
            "is_primary_table": true,
            "table_prefix": "tbl_site_power_db",
            "response_type": "number",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            }, {
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 56,
            "field_name": "no_of_generators",
            "display_name": "no_of_generators_value",
            "label": "Generator - No Of Generators",
            "table_name": "site_power_db",
            "is_primary_table": true,
            "table_prefix": "tbl_site_power_db",
            "response_type": "number",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            }, {
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 57,
            "field_name": "total_dc_load",
            "display_name": "total_dc_load_value",
            "label": "Rectifier- Total DC Load - (A)",
            "table_name": "rectifier",
            "is_primary_table": true,
            "table_prefix": "tbl_rectifier",
            "response_type": "number",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            }, {
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 94,
            "field_name": "rms_integrated_status",
            "display_name": "rectifier_rms_integrated_status_value",
            "label": "Rectifier- RMS Integrated Status",
            "table_name": "rectifier",
            "is_primary_table": true,
            "table_prefix": "tbl_rectifier",
            "response_type": "text",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 95,
            "field_name": "rms_inband_outband",
            "display_name": "rectifier_rms_inband_outband_value",
            "label": "Rectifier- RMS Inband Outband",
            "table_name": "rectifier",
            "is_primary_table": true,
            "table_prefix": "tbl_rectifier",
            "response_type": "text",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 96,
            "field_name": "rms_connection_method",
            "display_name": "rectifier_rms_connection_method_value",
            "label": "Rectifier- RMS Connection Method",
            "table_name": "rectifier",
            "is_primary_table": true,
            "table_prefix": "tbl_rectifier",
            "response_type": "text",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 97,
            "field_name": "rms_related_sim_number",
            "display_name": "rectifier_rms_related_sim_number_value",
            "label": "Rectifier- RMS Related SIM Number",
            "table_name": "rectifier",
            "is_primary_table": true,
            "table_prefix": "tbl_rectifier",
            "response_type": "text",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 98,
            "field_name": "name",
            "display_name": "generator_model_name_value",
            "label": "Generator- Model Name",
            "table_name": "generator_model",
            "is_primary_table": false,
            "primary_table_name": "generator",
            "table_prefix": "tbl_generator_model",
            "primary_table_prefix": "tbl_generator",
            "response_type": "text",
            "detail_view": true,
            "extra_fields": ['name', 'id'],
            "query_name": "SELECT `name`, `id` FROM `generator_model`",
            "operotors": [{
                "key": 1,
                "key": "equals",
                "value": "="
            }, {
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 58,
            "field_name": "no_of_modules_at_site",
            "display_name": "no_of_modules_at_site_value",
            "label": "Rectifier- Number Of Modules At Site",
            "table_name": "rectifier",
            "is_primary_table": true,
            "table_prefix": "tbl_rectifier",
            "response_type": "number",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            }, {
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 59,
            "field_name": "no_of_breaker_spaces_available_80a",
            "display_name": "no_of_breaker_spaces_available_80a_value",
            "label": "Rectifier- No of breaker spaces available (size 80A or below)",
            "table_name": "rectifier",
            "is_primary_table": true,
            "table_prefix": "tbl_rectifier",
            "response_type": "number",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            }, {
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 60,
            "field_name": "no_of_breaker_spaces_available_100a",
            "display_name": "no_of_breaker_spaces_available_100a_value",
            "label": "Rectifiers- No of breaker spaces available (size 100A or above)",
            "table_name": "rectifier",
            "is_primary_table": true,
            "table_prefix": "tbl_rectifier",
            "response_type": "number",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            }, {
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 61,
            "field_name": "no_of_powered_rec_modules",
            "display_name": "no_of_powered_rec_modules_value",
            "label": "Rectifiers- No. of Powered Rec Modules",
            "table_name": "rectifier",
            "is_primary_table": true,
            "table_prefix": "tbl_rectifier",
            "response_type": "number",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            }, {
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 62,
            "field_name": "free_breaker_availability",
            "display_name": "free_breaker_availability_value",
            "label": "Rectifiers- Free Breaker Availability",
            "table_name": "rectifier",
            "is_primary_table": true,
            "table_prefix": "tbl_rectifier",
            "response_type": "number",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            },{
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 63,
            "field_name": "module_model_id",
            "display_name": "module_model_id_value",
            "label": "Rectifier- Module Model ID",
            "table_name": "rectifier",
            "is_primary_table": true,
            "table_prefix": "tbl_rectifier",
            "response_type": "number",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            }, {
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 64,
            "field_name": "make",
            "display_name": "make_value",
            "label": "Generator- Make",
            "table_name": "generator",
            "is_primary_table": true,
            "table_prefix": "tbl_generator",
            "response_type": "text",
            "detail_view": true,
            "extra_fields": ['name', 'id'],
            "query_name": "SELECT `make` AS `name`, `id` FROM `generator`",
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 65,
            "field_name": "capacity",
            "display_name": "generator_capacity_value",
            "label": "Generator- Capacity (KVA)",
            "table_name": "generator",
            "is_primary_table": true,
            "table_prefix": "tbl_generator",
            "response_type": "number",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            },
            {
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 66,
            "field_name": "rms_integrated_status",
            "display_name": "rms_integrated_status_value",
            "label": "Generator- RMS Integrated Status",
            "table_name": "generator",
            "is_primary_table": true,
            "table_prefix": "tbl_generator",
            "response_type": "number",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            }, {
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 67,
            "field_name": "rms_inband_outband",
            "display_name": "rms_inband_outband_value",
            "label": "Generator- RMS Inbound/Oubound",
            "table_name": "generator",
            "is_primary_table": true,
            "table_prefix": "tbl_generator",
            "response_type": "text",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 68,
            "field_name": "rms_connection_method",
            "display_name": "rms_connection_method_value",
            "label": "Generator- RMS Connection Method",
            "table_name": "generator",
            "is_primary_table": true,
            "table_prefix": "tbl_generator",
            "response_type": "text",
            "detail_view": true,
            "extra_fields": ['name', 'id'],
            "query_name": "SELECT `rms_connection_method` AS `name`, `id` FROM `generator`",
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 69,
            "field_name": "type",
            "display_name": "type_value_dcdu",
            "label": "DCDU- Type",
            "table_name": "dcdu",
            "is_primary_table": true,
            "table_prefix": "tbl_dcdu",
            "response_type": "text",
            "query_name": "SELECT `type` AS `name`, `id` FROM `dcdu`",
            "extra_fields": ['name', 'id'],
            "detail_view": true,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 70,
            "field_name": "power_from_llvd_blvd",
            "display_name": "power_from_llvd_blvd_value",
            "label": "DCDU- Power from LLVD/BLVD",
            "table_name": "dcdu",
            "is_primary_table": true,
            "table_prefix": "tbl_dcdu",
            "response_type": "text",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            },
            {
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 71,
            "field_name": "rb_capacity_to_power_dcdu",
            "display_name": "rb_capacity_to_power_dcdu_value",
            "label": "DCDU- Rectifier Breaker Capacity To Power DCDU",
            "table_name": "dcdu",
            "is_primary_table": true,
            "table_prefix": "tbl_dcdu",
            "response_type": "text",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 72,
            "field_name": "free_breaker_slots",
            "display_name": "free_breaker_slots_value",
            "label": "DCDU- Free Breaker Slots ",
            "table_name": "dcdu",
            "is_primary_table": true,
            "table_prefix": "tbl_dcdu",
            "response_type": "number",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            },
            {
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 73,
            "field_name": "type",
            "display_name": "type_value_cabinet",
            "label": "Cabinet- Cabinet Type",
            "is_primary_table": true,
            "table_name": "cabinet",
            "table_prefix": "tbl_cabinet",
            "response_type": "text",
            "detail_view": true,
            "extra_fields": ['name', 'id'],
            "query_name": "SELECT `name` AS `name`, `id` FROM `cabinet_type`",
            "operotors": [{
                "key": 1,
                "key": "equals",
                "value": "="
            }, {
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 74,
            "field_name": "name",
            "display_name": "rectifier_model_value",
            "label": "Rectifier- Model Name",
            "table_name": "rectifier_model",
            "is_primary_table": false,
            "primary_table_name": "rectifier",
            "table_prefix": "tbl_rectifier_model",
            "primary_table_prefix": "tbl_rectifier",
            "response_type": "text",
            "detail_view": true,
            "extra_fields": ['name', 'id'],
            "query_name": "SELECT `name`, `id` FROM `rectifier_model`",
            "operotors": [{
                "key": 1,
                "key": "equals",
                "value": "="
            }, {
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 75,
            "field_name": "name",
            "display_name": "rectifier_make_value",
            "label": "Rectifier- Make Name",
            "table_name": "rectifier_make",
            "is_primary_table": false,
            "primary_table_name": "rectifier",
            "table_prefix": "tbl_rectifier_make",
            "primary_table_prefix": "tbl_rectifier",
            "response_type": "text",
            "detail_view": true,
            "extra_fields": ['name', 'id'],
            "query_name": "SELECT `name`, `id` FROM `rectifier_make`",
            "operotors": [{
                "key": 1,
                "key": "equals",
                "value": "="
            }, {
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 76,
            "field_name": "bb_type",
            "display_name": "bb_type_value",
            "label": "Battery Bank- Type",
            "is_primary_table": true,
            "table_name": "battery_bank",
            "table_prefix": "tbl_battery_bank",
            "response_type": "text",
            "detail_view": true,
            "extra_fields": ['name', 'id'],
            "query_name": "SELECT `bb_type` AS `name`, `id` FROM `battery_bank_type`",
            "operotors": [{
                "key": 1,
                "key": "equals",
                "value": "="
            }, {
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 77,
            "field_name": "name",
            "display_name": "battery_bank_make_value",
            "label": "Battery Bank- Make Name",
            "table_name": "battery_bank_make",
            "is_primary_table": false,
            "primary_table_name": "battery_bank",
            "table_prefix": "tbl_battery_bank_make",
            "primary_table_prefix": "tbl_battery_bank",
            "response_type": "text",
            "detail_view": true,
            "extra_fields": ['name', 'id'],
            "query_name": "SELECT `name`, `id` FROM `battery_bank_make`",
            "operotors": [{
                "key": 1,
                "key": "equals",
                "value": "="
            }, {
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 78,
            "field_name": "name",
            "display_name": "battery_bank_model_value",
            "label": "Battery Bank- Model Name",
            "table_name": "battery_bank_model",
            "is_primary_table": false,
            "primary_table_name": "battery_bank",
            "table_prefix": "tbl_battery_bank_model",
            "primary_table_prefix": "tbl_battery_bank",
            "response_type": "text",
            "detail_view": true,
            "extra_fields": ['name', 'id'],
            "query_name": "SELECT `name`, `id` FROM `battery_bank_model`",
            "operotors": [{
                "key": 1,
                "key": "equals",
                "value": "="
            }, {
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 79,
            "field_name": "name",
            "display_name": "site_owner_value",
            "label": "Site- Owner Name",
            "table_name": "site_owner",
            "is_primary_table": false,
            "primary_table_name": "site",
            "table_prefix": "tbl_site_owner",
            "primary_table_prefix": "tbl_site",
            "response_type": "text",
            "detail_view": true,
            "extra_fields": ['name', 'id'],
            "query_name": "SELECT `name`, `id` FROM `site_owner`",
            "operotors": [{
                "key": 1,
                "key": "equals",
                "value": "="
            }, {
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 80,
            "field_name": "name",
            "display_name": "depot_value",
            "label": "Site- Depot Name",
            "table_name": "depot",
            "is_primary_table": false,
            "primary_table_name": "site",
            "table_prefix": "tbl_depot",
            "primary_table_prefix": "tbl_site",
            "response_type": "text",
            "detail_view": true,
            "extra_fields": ['name', 'id'],
            "query_name": "SELECT `name`, `id` FROM `depot`",
            "operotors": [{
                "key": 1,
                "key": "equals",
                "value": "="
            }, {
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 81,
            "field_name": "name",
            "display_name": "region_value",
            "label": "Site- Region Name",
            "table_name": "region",
            "is_primary_table": false,
            "primary_table_name": "depot",
            "table_prefix": "tbl_region",
            "primary_table_prefix": "tbl_depot",
            "response_type": "text",
            "detail_view": true,
            "extra_fields": ['name', 'id'],
            "query_name": "SELECT `name`, `id` FROM `region`",
            "operotors": [{
                "key": 1,
                "key": "equals",
                "value": "="
            }, {
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 82,
            "field_name": "name",
            "display_name": "fcb_model_name",
            "label": "FCB- Model Name",
            "table_name": "fcb_model",
            "is_primary_table": false,
            "primary_table_name": "fcb",
            "table_prefix": "tbl_fcb_model",
            "primary_table_prefix": "tbl_fcb",
            "response_type": "text",
            "detail_view": true,
            "extra_fields": ['name', 'id'],
            "query_name": "SELECT `name`, `id` FROM `fcb_model`",
            "operotors": [{
                "key": 1,
                "key": "equals",
                "value": "="
            }, {
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 83,
            "field_name": "name",
            "display_name": "fcb_make_name",
            "label": "FCB- Make Name",
            "table_name": "fcb_make",
            "is_primary_table": false,
            "primary_table_name": "fcb",
            "table_prefix": "tbl_fcb_make",
            "primary_table_prefix": "tbl_fcb",
            "response_type": "text",
            "detail_view": true,
            "extra_fields": ['name', 'id'],
            "query_name": "SELECT `name`, `id` FROM `fcb_make`",
            "operotors": [{
                "key": 1,
                "key": "equals",
                "value": "="
            }, {
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 84,
            "field_name": "name",
            "display_name": "ats_make_name",
            "label": "ATS- Make Name",
            "table_name": "ats_make",
            "is_primary_table": false,
            "primary_table_name": "ats",
            "table_prefix": "tbl_ats_make",
            "primary_table_prefix": "tbl_ats",
            "response_type": "text",
            "detail_view": true,
            "extra_fields": ['name', 'id'],
            "query_name": "SELECT name, id FROM ats_make",
            "operotors": [{
                "key": 1,
                "key": "equals",
                "value": "="
            }, {
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 85,
            "field_name": "name",
            "display_name": "ats_model_name",
            "label": "ATS- Model Name",
            "table_name": "ats_model",
            "is_primary_table": false,
            "primary_table_name": "ats",
            "table_prefix": "tbl_ats_model",
            "primary_table_prefix": "tbl_ats",
            "response_type": "text",
            "detail_view": true,
            "extra_fields": ['name', 'id'],
            "query_name": "SELECT name, id FROM ats_model",
            "operotors": [{
                "key": 1,
                "key": "equals",
                "value": "="
            }, {
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 86,
            "field_name": "name",
            "display_name": "spds_make_name",
            "label": "SPD- SPD Make Name",
            "table_name": "spds_make",
            "is_primary_table": false,
            "primary_table_name": "spds",
            "table_prefix": "tbl_spds_make",
            "primary_table_prefix": "tbl_spds",
            "response_type": "text",
            "detail_view": true,
            "extra_fields": ['name', 'id'],
            "query_name": "SELECT name, id FROM spds_make",
            "operotors": [{
                "key": 1,
                "key": "equals",
                "value": "="
            }, {
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 87,
            "field_name": "deployed_location",
            "display_name": "spd_displayed_location",
            "label": "SPDs - Displayed Location",
            "table_name": "spds",
            "is_primary_table": true,
            "table_prefix": "tbl_spds",
            "response_type": "text",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 88,
            "field_name": "sbp_no_of_phases",
            "display_name": "sbp_no_of_phases_value",
            "label": "Shared Backup Power - No Of Phases",
            "table_name": "site_power_db",
            "is_primary_table": true,
            "table_prefix": "tbl_site_power_db",
            "response_type": "text",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 89,
            "field_name": "sbp_capacity_per_phase",
            "display_name": "sbp_capacity_per_phase_value",
            "label": "Shared Backup Power - Capacity Per Phase (A)",
            "table_name": "site_power_db",
            "is_primary_table": true,
            "table_prefix": "tbl_site_power_db",
            "response_type": "number",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            },{
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 90,
            "field_name": "indoor_model",
            "display_name": "air_conditioner_indoor_model",
            "label": "Air Conditioner- Indoor Unit Model",
            "table_name": "air_conditioner",
            "is_primary_table": true,
            "table_prefix": "tbl_air_conditioner",
            "response_type": "text",
            "detail_view": true,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 91,
            "field_name": "no_of_dcdus",
            "display_name": "no_of_dcdus_value",
            "label": "DCDU - No Of DCDU",
            "table_name": "site_power_db",
            "is_primary_table": true,
            "table_prefix": "tbl_site_power_db",
            "response_type": "number",
            "detail_view": true,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            },{
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 92,
            "field_name": "name",
            "display_name": "power_connection_type_name",
            "label": "Main Power Connection - Power Connection Type",
            "is_primary_table": false,
            "table_name": "power_connection_type",
            "table_prefix": "tbl_power_connection_type",
            "primary_table_name": "site_power_db",
            "primary_table_prefix": "tbl_site_power_db",
            "response_type": "text",
            "detail_view": true,
            "extra_fields": ['name', 'id'],
            "query_name": "SELECT `name`, `id` FROM `power_connection_type`",
            "operotors": [{
                "key": 1,
                "key": "equals",
                "value": "="
            }, {
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        }


        /*{
            "value": 3,
            "field_name": "height",
            "label": "Height",
            "table_name": "site",
            "is_primary_table": true,
            "table_prefix": "tbl_site",
            "response_type": "text",
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            },
            {
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 4,
            "field_name": "gen_capacity",
            "label": "Capacity",
            "table_name": "Generator",
            "is_primary_table": true,
            "table_prefix": "tbl_generator",
            "response_type": "text",
            "operotors": [{
                "key": 1,
                "key": "equals",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            },
            {
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 5,
            "field_name": "last_filling_date",
            "label": "Last Filling Date",
            "table_name": "Generator",
            "is_primary_table": true,
            "table_prefix": "tbl_generator",
            "response_type": "text",
            "operotors": [{
                "key": 1,
                "key": "equals",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            },
            {
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 6,
            "field_name": "asset_id",
            "label": "Asset_id",
            "table_name": "Asset",
            "is_primary_table": true,
            "table_prefix": "tbl_asset",
            "response_type": "text",
            "operotors": [{
                "key": 1,
                "key": "equals",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            },
            {
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 7,
            "field_name": "id",
            "label": "Generator Model Id",
            "table_name": "generator_model",
            "is_primary_table": false,
            "primary_table_name": "Generator",
            "table_prefix": "tbl_generator_model",
            "primary_table_prefix": "tbl_generator",
            "response_type": "number",
            "operotors": [{
                "key": 1,
                "key": "equals",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            },
            {
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 7,
            "field_name": "name",
            "label": "Generator Model Name",
            "table_name": "generator_model",
            "is_primary_table": false,
            "primary_table_name": "Generator",
            "table_prefix": "tbl_generator_model",
            "primary_table_prefix": "tbl_generator",
            "response_type": "text",
            "operotors": [{
                "key": 1,
                "key": "equals",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            },
            {
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },*/
    ],
    "relations": [{
        "main_table": "site",
        "main_table_prefix": "tbl_site",
        "sub_table": "generator",
        "sub_table_prefix": "tbl_generator",
        "main_table_id": "id",
        "sub_table_id": "site_id",
    },
    {
        "main_table": "site",
        "main_table_prefix": "tbl_site",
        "sub_table": "asset",
        "sub_table_prefix": "asset",
        "main_table_id": "id",
        "sub_table_id": "site_id",
    },
    {
        "main_table": "generator",
        "main_table_prefix": "tbl_generator",
        "sub_table": "asset",
        "sub_table_prefix": "tbl_asset",
        "main_table_id": "id",
        "sub_table_id": "asset_id",
    },
    {
        "main_table": "generator",
        "main_table_prefix": "tbl_generator",
        "sub_table": "generator_model",
        "sub_table_prefix": "tbl_generator_model",
        "main_table_id": "model_id",
        "sub_table_id": "id",
    },
        {
            "main_table": "site",
            "main_table_prefix": "tbl_site",
            "sub_table": "indoor_outdoor_type",
            "sub_table_prefix": "tbl_indoor_outdoor_type",
            "main_table_id": "indoor_outdoor_type_id",
            "sub_table_id": "id",
        },
    {
        "main_table": "site",
        "main_table_prefix": "tbl_site",
        "sub_table": "renewable_energy_solar",
        "sub_table_prefix": "tbl_renewable_energy_solar",
        "main_table_id": "id",
        "sub_table_id": "site_id",
    },
        {
            "main_table": "renewable_energy_solar",
            "main_table_prefix": "tbl_renewable_energy_solar",
            "sub_table": "solar_panel_model",
            "sub_table_prefix": "tbl_solar_panel_model",
            "main_table_id": "panel_model_id",
            "sub_table_id": "id",
        },
    {
        "main_table": "site",
        "main_table_prefix": "tbl_site",
        "sub_table": "renewable_energy_wind",
        "sub_table_prefix": "tbl_renewable_energy_wind",
        "main_table_id": "id",
        "sub_table_id": "site_id",
    },
    {
        "main_table": "site",
        "main_table_prefix": "tbl_site",
        "sub_table": "shared_out_operators",
        "sub_table_prefix": "tbl_shared_out_operators",
        "main_table_id": "id",
        "sub_table_id": "site_id",
    },
    {
        "main_table": "site",
        "main_table_prefix": "tbl_site",
        "sub_table": "cabinet",
        "sub_table_prefix": "tbl_cabinet",
        "main_table_id": "id",
        "sub_table_id": "site_id",
    },
    {
        "main_table": "cabinet_model",
        "main_table_prefix": "tbl_cabinet_model",
        "sub_table": "cabinet_make",
        "sub_table_prefix": "tbl_cabinet_make",
        "main_table_id": "make_id",
        "sub_table_id": "id",
    },
    {
        "main_table": "cabinet",
        "main_table_prefix": "tbl_cabinet",
        "sub_table": "cabinet_model",
        "sub_table_prefix": "tbl_cabinet_model",
        "main_table_id": "model_id",
        "sub_table_id": "id",
    },
    {
        "main_table": "cabinet",
        "main_table_prefix": "tbl_cabinet",
        "sub_table": "cabinet_type",
        "sub_table_prefix": "tbl_cabinet_type",
        "main_table_id": "type",
        "sub_table_id": "id",
    },
    {
        "main_table": "site",
        "main_table_prefix": "tbl_site",
        "sub_table": "battery_bank",
        "sub_table_prefix": "tbl_battery_bank",
        "main_table_id": "id",
        "sub_table_id": "site_id",
    },
    {
        "main_table": "battery_bank",
        "main_table_prefix": "tbl_battery_bank",
        "sub_table": "battery_bank_make",
        "sub_table_prefix": "tbl_battery_bank_make",
        "main_table_id": "make_id",
        "sub_table_id": "id",
    },
    {
        "main_table": "battery_bank",
        "main_table_prefix": "tbl_battery_bank",
        "sub_table": "battery_bank_model",
        "sub_table_prefix": "tbl_battery_bank_model",
        "main_table_id": "model_id",
        "sub_table_id": "id",
    },
    {
        "main_table": "site",
        "main_table_prefix": "tbl_site",
        "sub_table": "air_conditioner",
        "sub_table_prefix": "tbl_air_conditioner",
        "main_table_id": "id",
        "sub_table_id": "site_id",
    },
        {
            "main_table": "air_conditioner",
            "main_table_prefix": "tbl_air_conditioner",
            "sub_table": "ac_make",
            "sub_table_prefix": "tbl_ac_make",
            "main_table_id": "make_id",
            "sub_table_id": "id",
        },
    {
        "main_table": "site",
        "main_table_prefix": "tbl_site",
        "sub_table": "id_battery_rack",
        "sub_table_prefix": "tbl_id_battery_rack",
        "main_table_id": "id",
        "sub_table_id": "site_id",
    },
    {
        "main_table": "site",
        "main_table_prefix": "tbl_site",
        "sub_table": "site_power_db",
        "sub_table_prefix": "tbl_site_power_db",
        "main_table_id": "id",
        "sub_table_id": "site_id",
    },
    {
        "main_table": "site",
        "main_table_prefix": "tbl_site",
        "sub_table": "dcdu",
        "sub_table_prefix": "tbl_dcdu",
        "main_table_id": "id",
        "sub_table_id": "site_id",
    },
    {
        "main_table": "site",
        "main_table_prefix": "tbl_site",
        "sub_table": "rectifier",
        "sub_table_prefix": "tbl_rectifier",
        "main_table_id": "id",
        "sub_table_id": "site_id",
    },
    {
        "main_table": "rectifier",
        "main_table_prefix": "tbl_rectifier",
        "sub_table": "rectifier_make",
        "sub_table_prefix": "tbl_rectifier_make",
        "main_table_id": "make_id",
        "sub_table_id": "id",
    },
    {
        "main_table": "rectifier",
        "main_table_prefix": "tbl_rectifier",
        "sub_table": "rectifier_model",
        "sub_table_prefix": "tbl_rectifier_model",
        "main_table_id": "model_id",
        "sub_table_id": "id",
    },
    {
        "main_table": "site",
        "main_table_prefix": "tbl_site",
        "sub_table": "generator",
        "sub_table_prefix": "tbl_generator",
        "main_table_id": "id",
        "sub_table_id": "site_id",
    },
    {
        "main_table": "site",
        "main_table_prefix": "tbl_site",
        "sub_table": "site_owner",
        "sub_table_prefix": "tbl_site_owner",
        "main_table_id": "site_owner_id",
        "sub_table_id": "id",
    },
    {
        "main_table": "site",
        "main_table_prefix": "tbl_site",
        "sub_table": "depot",
        "sub_table_prefix": "tbl_depot",
        "main_table_id": "depot_id",
        "sub_table_id": "id",
    },
    {
        "main_table": "depot",
        "main_table_prefix": "tbl_depot",
        "sub_table": "region",
        "sub_table_prefix": "tbl_region",
        "main_table_id": "region_id",
        "sub_table_id": "id",
    },
        {
            "main_table": "site_power_db",
            "main_table_prefix": "tbl_site_power_db",
            "sub_table": "power_connection_type",
            "sub_table_prefix": "tbl_power_connection_type",
            "main_table_id": "mpc_power_connection_type",
            "sub_table_id": "id",
        },
    {
        "main_table": "site",
        "main_table_prefix": "tbl_site",
        "sub_table": "fcb",
        "sub_table_prefix": "tbl_fcb",
        "main_table_id": "id",
        "sub_table_id": "site_id",
    },
    {
        "main_table": "fcb",
        "main_table_prefix": "tbl_fcb",
        "sub_table": "fcb_make",
        "sub_table_prefix": "tbl_fcb_make",
        "main_table_id": "make_id",
        "sub_table_id": "id",
    },
    {
        "main_table": "fcb",
        "main_table_prefix": "tbl_fcb",
        "sub_table": "fcb_model",
        "sub_table_prefix": "tbl_fcb_model",
        "main_table_id": "model_id",
        "sub_table_id": "id",
    },
    {
        "main_table": "site",
        "main_table_prefix": "tbl_site",
        "sub_table": "ats",
        "sub_table_prefix": "tbl_ats",
        "main_table_id": "id",
        "sub_table_id": "site_id",
    },
    {
        "main_table": "ats",
        "main_table_prefix": "tbl_ats",
        "sub_table": "ats_make",
        "sub_table_prefix": "tbl_ats_make",
        "main_table_id": "make_id",
        "sub_table_id": "id",
    },
    {
        "main_table": "ats",
        "main_table_prefix": "tbl_ats",
        "sub_table": "ats_model",
        "sub_table_prefix": "tbl_ats_model",
        "main_table_id": "model_id",
        "sub_table_id": "id",
    },
    {
        "main_table": "site",
        "main_table_prefix": "tbl_site",
        "sub_table": "spds",
        "sub_table_prefix": "tbl_spds",
        "main_table_id": "id",
        "sub_table_id": "site_id",
    },
    {
        "main_table": "spds",
        "main_table_prefix": "tbl_spds",
        "sub_table": "spds_make",
        "sub_table_prefix": "tbl_spds_make",
        "main_table_id": "make_id",
        "sub_table_id": "id",
    },
    ],
    "all_operators": [{

    }]
}

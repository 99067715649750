import React, { Component } from 'react'
import { getDashboardTarget, getDashboardTargetUpdate } from "../../api/index";
import { Drawer, Table, List, Skeleton, Avatar, Tag, Form, Input, Select, InputNumber } from 'antd';

class TargetValues extends Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            allData: null,
            targetValuesUpdateOpen: false,
            updateArraya: null,
            newTargetValue: null

        }
        this.handleNewTargetValue = this.handleNewTargetValue.bind(this);

    }
    handleNewTargetValue(e) {
        console.log(e)
        this.setState({ newTargetValue: e });
    }
    componentDidMount() {
        this.getDataDashboardTarget();

    }

    getDataDashboardTarget = async () => {
        this.setState({
            loading: true
        })
        var res = await getDashboardTarget();
        if (res.success) {
            this.setState({
                allData: res.data,
                loading: false
            })

        }



    };
    onDrawerClose = () => {
        this.formRef.current.resetFields();
        this.setState({
            targetValuesUpdateOpen: false
        })
    }
    historyView = (item) => {
        console.log(item)
        this.setState({
            historyViewLoging: true,
            targetValuesUpdateOpen: true,
            updateArraya: item,
        })
    }
    AllDataSubmit = async () => {

        var dataSet;
        console.log(this.state.updateArraya)
        dataSet = {
            "id": this.state.updateArraya?.id,
            "name": this.state.updateArraya?.name,
            "value": this.state.newTargetValue
        }

        var res = await getDashboardTargetUpdate(dataSet);
        console.log(res)
        if (res.success) {
            this.formRef.current.resetFields();
            this.getDataDashboardTarget();

            this.setState({
                targetValuesUpdateOpen: false
            })

        }

    }
    render() {
        const columns123 = [
            {
                title: "Id",
                //   dataIndex: "accessPermissionCode",
                key: "id",
                render: (item) => <span>{item?.id}</span>,
            },
            {
                title: "Name",
                //   dataIndex: "accessPermissionCode",
                key: "name",
                render: (item) => <span>{item?.name}</span>,
            },
            {
                title: "Value",
                //   dataIndex: "accessPermissionCode",
                key: "value",
                render: (item) => <span>{item?.value}</span>,
            },

            {
                title: '',

                key: 'x',
                width: '5%',
                render: (item) => <a href='#' onClick={() => this.historyView(item)} key="list-loadmore-more" ><i class="fa fa-pen"></i></a>,
            },

        ];
        return (
            <div class="container-fluid">
                < div class="row">
                    <div class="col-sm-12">
                        <div class="page-title-box">
                            <div class="row">
                                <div class="col">
                                    <h4 class="page-title">Target Values</h4>
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="javascript:void(0);">Dashboard</a></li>
                                        <li class="breadcrumb-item"><a href="javascript:void(0);">Target Values</a></li>


                                    </ol>
                                </div>
                                <div class="col-auto align-self-center">
                                    <a href="#" class="btn btn-sm btn-outline-primary" id="Dash_Date">
                                        <span class="day-name" id="Day_Name">Today:</span>&nbsp;
                                        <span class="" id="Select_date">{new Date().toUTCString().slice(5, 16)}</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar align-self-center icon-xs ms-1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                                    </a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className='col-md-6'>
                        <Table
                            size='small'
                            fontSize={9}
                            columns={columns123}
                            bordered
                            pagination={false}
                            loading={this.state.loading}
                            dataSource={this.state.allData}
                        />
                    </div>

                    <Drawer title={"Target Values Update"} style={{ zIndex: 0 }} width={"50%"} placement="right" onClose={() => this.onDrawerClose()} visible={this.state.targetValuesUpdateOpen}>
                        <div className="row" >
                            <div className="col-lg-12">
                                <div className="card">

                                    <div className="card-body">
                                        <div className='row'>

                                            <div class="col-lg-12 align-self-center">
                                                <div class="single-pro-detail">


                                                    <h4 class="pro-title">Name :- {this.state.updateArraya?.name} </h4>
                                                    <p class="text-muted mb-6"><h4>Target Value:   {this.state.updateArraya?.value}	 </h4></p>



                                                </div>
                                            </div>

                                        </div>
                                        <br></br>
                                        <Form
                                            ref={this.formRef}
                                        >

                                            <div className='row'>


                                                <div className="col-md-6">
                                                    <label className="form-label" for="dcdu">
                                                        New Target Value
                                                    </label>
                                                    <Form.Item required name="NewTargetValue" >
                                                        <InputNumber style={{ width: '100%' }} id="NewTargetValue" onChange={this.handleNewTargetValue} />
                                                    </Form.Item>

                                                </div>




                                            </div>





                                        </Form>




                                        <div className='row' style={{ justifyContent: "end" }}>
                                            <div className='col-md-2' >   <button className="btn btn-primary" onClick={() => this.AllDataSubmit()} >Update</button></div>


                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </Drawer>


                </div>

            </div >
        )
    }
}
export default TargetValues; 
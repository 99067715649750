import React, { Component } from "react";
import RegionGeneral from "./region-general";
import AssetDetails from "../../components/site_components/asset_details";
import { withRouter } from "react-router-dom";
import { getRegionDetails, getDepotByRegionId } from "../../api/index";
//import GoogleMapReact from "google-map-react";

const AnyReactComponent = ({ text }) => (
  <div>
    <i
      style={{ color: "#ff0000", fontSize: 30 }}
      class="fas fa-map-marker-alt"
    ></i>
  </div>
);

class RegionSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      site_details: {},
      site_list: {},
      center: {
        lat: 7.959444,
        lng: 80.743333,
      },
      zoom: 7,
    };
  }

  componentDidMount() {
    this.getSiteData();
    this.getSitesByRegionId();
  }
  getSitesByRegionId = async () => {
    var res = await getDepotByRegionId(this.props.match.params.id);
    //console.log(res);
    if (res.success) {
      this.setState(
        {
          site_list: res.data,
          //   zoom: 12,
          // center: {
          // 	lat: parseFloat(res.data.siteAddressList[0].latitude),
          // 	lng: parseFloat(res.data.siteAddressList[0].longitude),
          //   },
        },
        () => {
          //console.log(this.state.center);
        }
      );
    }
  };
  getSiteData = async () => {
    var res = await getRegionDetails(this.props.match.params.id);
    if (res.success) {
      this.setState(
        {
          site_details: res.data,
          zoom: 12,
          // center: {
          // 	lat: parseFloat(res.data.siteAddressList[0].latitude),
          // 	lng: parseFloat(res.data.siteAddressList[0].longitude),
          //   },
        },
        () => {
          //console.log(this.state.center);
        }
      );
    }
  };

  render() {
    const { site_details } = this.state;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="page-title-box">
              <div className="row">
                <div className="col">
                  <h4 className="page-title">Region Summary</h4>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="javascript:void(0);">Region</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="javascript:void(0);">{site_details.name}</a>
                    </li>
                    <li className="breadcrumb-item active">
                      {site_details.id}
                    </li>
                  </ol>
                </div>
                <div className="col-auto align-self-center">
                  {/* <a href="#" className="btn btn-sm btn-outline-primary" id="Dash_Date">
                                            <span className="day-name" id="Day_Name">Today:</span>&nbsp;
                                            <span className="" id="Select_date">Jan 11</span>
                                            <i data-feather="calendar" className="align-self-center icon-xs ms-1"></i>
                                        </a> */}
                  <a href="#" className="btn btn-sm btn-outline-primary">
                    <i
                      data-feather="download"
                      className="align-self-center icon-xs"
                    ></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body p-0">
                <div
                  id="user_map"
                  className="pro-map"
                  style={{
                    height: "320px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <GoogleMapReact
                    bootstrapURLKeys={{
                      key: "AIzaSyC7QjauaBLV9W__koQgy65PIuxYbcSPpfg",
                    }}
                    defaultCenter={this.state.center}
                    defaultZoom={this.state.zoom}
                  >
                    <AnyReactComponent
                      lat={this.state.center.lat}
                      lng={this.state.center.lng}
                      text=""
                    />
                  </GoogleMapReact> */}
                </div>
              </div>
              <div className="card-body">
                <div className="dastone-profile">
                  <div className="row">
                    <div className="col-lg-7 align-self-center mb-3 mb-lg-0">
                      <div className="dastone-profile-main">
                        <div className="dastone-profile-main-pic">
                          <img
                            src="assets/images/users/user-4.jpg"
                            alt=""
                            height="110"
                            className="rounded-circle"
                          />
                          <span className="dastone-profile_main-pic-change">
                            <i className="fas fa-camera"></i>
                          </span>
                        </div>
                        <div className="dastone-profile_user-detail">
                          <h5 className="dastone-user-name">
                            {site_details.name}
                          </h5>
                          <p className="mb-0 dastone-user-name-post">
                            {site_details.id}
                          </p>
                        </div>
                      </div>
                    </div>

                    {site_details.organization != null && (
                      <div className="col-lg-5 ms-auto align-self-center">
                        <ul className="list-unstyled personal-detail mb-0">
                          {/* <li className=""><i className="ti ti-mobile me-2 text-secondary font-16 align-middle"></i> <b> Depot Officer </b> : {  site_details.depot.depotOfficers[0].name}</li> */}
                          {/* <li className="mt-2"><i className="ti ti-email text-secondary font-16 align-middle me-2"></i> <b> Depot Contact </b> : {site_details.depot.depotOfficers != null && site_details.depot.depotOfficers[0].mobileNumber}</li> */}
                          <li className="mt-2">
                            <i className="ti ti-world text-secondary font-16 align-middle me-2"></i>{" "}
                            <b> Organization </b> :
                            <a className="font-14 text-primary">
                              {site_details.organization.name}
                            </a>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <ul className="nav nav-tabs" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-bs-toggle="tab"
                  href="#home"
                  role="tab"
                  aria-selected="false"
                >
                  General
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="tab"
                  href="#profile"
                  role="tab"
                  aria-selected="false"
                >
                  Assets
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="tab"
                  href="#settings"
                  role="tab"
                  aria-selected="true"
                >
                  Services
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="tab"
                  href="#req"
                  role="tab"
                  aria-selected="true"
                >
                  Access Requests
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link "
                  data-bs-toggle="tab"
                  href="#Payments"
                  role="tab"
                  aria-selected="true"
                >
                  Payments
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link "
                  data-bs-toggle="tab"
                  href="#Submitions"
                  role="tab"
                  aria-selected="true"
                >
                  CEA Submitions
                </a>
              </li>
            </ul>

            <div className="tab-content">
              <div className="tab-pane p-3 active" id="home" role="tabpanel">
                {Object.keys(this.state.site_list).length != 0 && (
                  <RegionGeneral details={this.state.site_list} />
                )}
              </div>
              <div className="tab-pane p-3" id="profile" role="tabpanel">
                <AssetDetails />
              </div>
              <div className="tab-pane p-3 " id="settings" role="tabpanel">
                <div
                  style={{
                    height: "120px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h4 style={{ color: "#c5c5c5" }}>No Dat Found</h4>
                </div>
              </div>
              <div className="tab-pane p-3 " id="Payments" role="tabpanel">
                <div
                  style={{
                    height: "120px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h4 style={{ color: "#c5c5c5" }}>No Dat Found</h4>
                </div>
              </div>
              <div className="tab-pane p-3 " id="Submitions" role="tabpanel">
                <div
                  style={{
                    height: "120px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h4 style={{ color: "#c5c5c5" }}>No Dat Found</h4>
                </div>
              </div>
              <div className="tab-pane p-3 " id="req" role="tabpanel">
                <div
                  style={{
                    height: "120px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h4 style={{ color: "#c5c5c5" }}>No Dat Found</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(RegionSummary);

import React, { useState, useEffect, useRef } from 'react';
import {
    CarOutlined,
    NodeIndexOutlined,
    EditOutlined,
    DeleteOutlined,
    EyeOutlined,
    CheckOutlined,
    DashboardOutlined,
    SearchOutlined,
    PhoneOutlined,
    PaperClipOutlined,
    UploadOutlined,
    PlusOutlined,
    CalendarOutlined,
    BankOutlined,
    UserOutlined,
    ArrowDownOutlined,
    KeyOutlined,
    GlobalOutlined,
    DownCircleFilled,
    FormOutlined
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { Spin, Avatar, Notification, notification, Card, Skeleton, Switch, Table, Select, Input, Button, Row, Upload, Col, Dropdown, Tag, Modal, Steps, message, Form, DatePicker, TimePicker, Descriptions, Image, Collapse } from 'antd';
import '../style.css';
import {
    getAllGroupHandlersREWAMP,
    getAllRequestCategoryREWAMP,
    getAllSubRequestTypeREWAMP,
    getSiteDetailsBySiteIDREWAMP,
    bulkSiteAccessRequestREWAMP,
    getSiteAccessRequestByIDREWAMP,
    updateSiteAccessRequestStatusREWAMP,
    getAllVisitingPurposeREWAMP,
    getAllWorkTypeREWAMP,
    getAllWorkAreaREWAMP,
    getAllSiteAccessRequestBULKPending,
    getAllAccessNeedREWAMP
} from "../../../api/index";
import Index from '../../vehicle';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import site from '../../site';
import {
    createBulkSiteAccessRequestREWAMP,
    uploadAccessRequestFiles
} from "../../../api/executor";


const AccessDashboard = () => {
    const { id, type } = useParams();
    console.log(useParams());
    const userUploadFileHeaders = [
        "name",
        "contactNumber",
        "nic",
        "company"
    ]
    const siteDetailsHeaders = [
        "siteId"
    ]

    const baseImgURL = "https://storage.googleapis.com/dns_storage_v2/dnsrevampimages/";

    const history = useHistory();
    const [form] = Form.useForm();
    const [bulkUpload, setBulkUpload] = useState(false);
    const [formID, setFormID] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [editModelOpen, setEditModelOpen] = useState(false);
    const [equpmentJson, setEqupmentJson] = useState([]);
    const [vehicleJson, setVehicleJson] = useState([]);
    const [instrumentJson, setInstrumentJson] = useState([]);
    const [teamMemberJson, setTeamMemberJson] = useState([]);
    const [operationModal, setOperationModal] = useState(2);

    const [sitejsonData, setSiteJsonData] = useState([]);
    const [handlerGroup, setHandlerGroup] = useState([]);
    const [requestCategory, setRequestCategory] = useState([]);
    const [subRequestType, setSubRequestType] = useState([]);
    const [siteDataArray, setSiteDataArray] = useState([]);
    const [requestDetails, setReqDetails] = useState([]);

    const [reqFormData, setreqFormData] = useState([]);

    const [isOOType, setIsOOType] = useState(false);
    const [siteType, setSiteType] = useState([]);
    const [visitingPurpose, setVisitingPurpose] = useState(null);
    const [workingType, setWorkingType] = useState(null);
    const [workArea, setWorkArea] = useState(null);
    const [accessNeed, setAccessNeed] = useState(null);
    const [getBulkIDSet, setBulkIDSet] = useState([]);
    const { Panel } = Collapse;

    const [siteKeyLocations, setSiteKeyLocations] = useState([]);
    const [fileUploading, setFileUploading] = useState(false);
    
    const [userValidation, setUserValidation] = useState([]);
    const [usersValidation, setUsersValidation] = useState(true);
    const [openTeamErrorLog, setOpenTeamErrorLog] = useState(false);

    const [fileInformations, setFileInformations] = useState({
        initiatorFileOne: null,
        initiatorFileOneOriginal: null,
        isUpdateFileOne: false,
        isDeleteFileOne: false,

        initiatorFileTwo: null,
        initiatorFileTwoOriginal: null,
        isUpdateFileTwo: false,
        isDeleteFileTwo: false,

        initiatorFileThree: null,
        initiatorFileThreeOriginal: null,
        isUpdateFileThree: false,
        isDeleteFileThree: false,

        initiatorFileFour: null,
        initiatorFileFourOriginal: null,
        isUpdateFileFour: false,
        isDeleteFileFour: false,

        initiatorFileFive: null,
        initiatorFileFiveOriginal: null,
        isUpdateFileFive: false,
        isDeleteFileFive: false,
    });
    const [selectedTeamMember, setSelectedTeamMember] = useState({
        id: '',
        name: '',
        contactNumber: '',
        nic: '',
        company: '',
    });

    const [selectedEquipment, setSelectedEquipment] = useState({
        id: '',
        itemName: '',
        serialNumber: '',
        actionType: '',
        description: '',
        quantity: '',
    });

    const [selectedInstrument, setSelectedInstrument] = useState({
        id: '',
        itemName: '',
        serialNumber: '',
        actionType: '',
        description: '',
        quantity: '',
    });

    const [selectedVehicle, setSelectedVehicle] = useState({
        id: '',
        vehicleNumber: '',
        vehicleType: '',
    });
    

    const [isUpdate, setIsUpdate] = useState(false);
    const [filterJson, setFilterJson] = useState(null);

    const [fileLibrary, setFileLibrary] = useState(
        {
            initiatorFileOne: null,
            initiatorFileOneOriginal: null,

            initiatorFileTwo: null,
            initiatorFileTwoOriginal: null,

            initiatorFileThree: null,
            initiatorFileThreeOriginal: null,

            initiatorFileFour: null,
            initiatorFileFourOriginal: null,

            initiatorFileFive: null,
            initiatorFileFiveOriginal: null,
        }
    );

    const formRefVH = useRef();
    const formRefEQ = useRef();
    const formRefIN = useRef();
    const formRefUS = useRef();
    const formRefMain = useRef();

    const handleResetModalForms = () => {
        formRefVH?.current?.resetFields();
        formRefEQ?.current?.resetFields();
        formRefIN?.current?.resetFields();
        formRefUS?.current?.resetFields();
    };

    React.useEffect(() => {
        fetchAllGroupHandlers();
        fetchAllRequestCategory();
        fetchAllSubRequestType();
        fetchSiteAccessRequestByID(id, type);

        fetchAllVisitingPurpose();
        fetchAllWorkType();
        fetchAllWorkArea();
        fetchAllAccessNeed();

        type === "BULK" && fetchAllSiteAccessRequestBULKPending(id);
    }, []);

    const fetchAllSiteAccessRequestBULKPending = async (id) => {
        var responseAPI = await getAllSiteAccessRequestBULKPending(id);
        if (responseAPI.success) {
            setBulkIDSet(responseAPI.data.results);
            const siteKeyLocations = responseAPI.data.results.map((item, index) => ({
                key: index + 1,
                location: item.keyLocation,
                siteId: item.siteId,
                siteName: item.siteName
            }));
            setSiteKeyLocations(siteKeyLocations);
        } else {
            message.error('Error In Fetching API Request : ' + responseAPI.message);
        }
    }

    const columns = [
        {
            title: <span className="textStyle">Request ID</span>,
            dataIndex: 'requestId',
            key: 'requestId',
            render: text => <span className="textStyleChild">{text}</span>,
        },
        {
            title: <span className="textStyle">Site Name</span>,
            dataIndex: 'siteName',
            key: 'siteName',
            render: text => <span className="textStyleChild">{text}</span>,
        },
        {
            title: <span className="textStyle">Site Type</span>,
            dataIndex: 'siteType',
            key: 'siteType',
            render: text => <span className="textStyleChild">{text}</span>,
        },
        {
            title: <span className="textStyle">Region Name</span>,
            dataIndex: 'regionName',
            key: 'regionName',
            render: text => <span className="textStyleChild">{text}</span>,
        },
        {
            title: <span className="textStyle">Depot Name</span>,
            dataIndex: 'depotName',
            key: 'depotName',
            render: text => <span className="textStyleChild">{text}</span>,
        },
        {
            title: <span className="textStyle">Site Owner</span>,
            dataIndex: 'siteOwner',
            key: 'siteOwner',
            render: text => <span className="textStyleChild">{text}</span>,
        },
        {
            title: <span className="textStyle">Site Owner Full Name</span>,
            dataIndex: 'siteOwnerFullName',
            key: 'siteOwnerFullName',
            render: text => <span className="textStyleChild">{text}</span>,
        },
        {
            title: <span className="textStyle">Site Owner Address</span>,
            dataIndex: 'siteOwnerAddress',
            key: 'siteOwnerAddress',
            render: text => <span className="textStyleChild">{text}</span>,
        },
    ]

    const fetchSiteAccessRequestByID = async (id, type) => {
        console.log(id);
        var responseAPI = await getSiteAccessRequestByIDREWAMP(id, type);
        console.log(responseAPI.data);
        if (responseAPI.success) {
            setTeamMemberJson(responseAPI.data.results.teamMembers);
            setEqupmentJson(responseAPI.data.results.equipments);
            setVehicleJson(responseAPI.data.results.vehicles);
            setInstrumentJson(responseAPI.data.results.instruments);
            setReqDetails(responseAPI.data.results);
            setreqFormData(responseAPI?.data?.results);

            setFileInformations({
                initiatorFileOne: responseAPI?.data?.results?.initiatorFileOne,
                initiatorFileOneOriginal: responseAPI?.data?.results?.initiatorFileOneOriginal,
                initiatorFileTwo: responseAPI?.data?.results?.initiatorFileTwo,
                initiatorFileTwoOriginal: responseAPI?.data?.results?.initiatorFileTwoOriginal,
                initiatorFileThree: responseAPI?.data?.results?.initiatorFileThree,
                initiatorFileThreeOriginal: responseAPI?.data?.results?.initiatorFileThreeOriginal,
                initiatorFileFour: responseAPI?.data?.results?.initiatorFileFour,
                initiatorFileFourOriginal: responseAPI?.data?.results?.initiatorFileFourOriginal,
                initiatorFileFive: responseAPI?.data?.results?.initiatorFileFive,
                initiatorFileFiveOriginal: responseAPI?.data?.results?.initiatorFileFiveOriginal,
            });

            setFileLibrary({
                initiatorFileOne: responseAPI?.data?.results?.initiatorFileOne,
                initiatorFileOneOriginal: responseAPI?.data?.results?.initiatorFileOneOriginal,
                initiatorFileTwo: responseAPI?.data?.results?.initiatorFileTwo,
                initiatorFileTwoOriginal: responseAPI?.data?.results?.initiatorFileTwoOriginal,
                initiatorFileThree: responseAPI?.data?.results?.initiatorFileThree,
                initiatorFileThreeOriginal: responseAPI?.data?.results?.initiatorFileThreeOriginal,
                initiatorFileFour: responseAPI?.data?.results?.initiatorFileFour,
                initiatorFileFourOriginal: responseAPI?.data?.results?.initiatorFileFourOriginal,
                initiatorFileFive: responseAPI?.data?.results?.initiatorFileFive,
                initiatorFileFiveOriginal: responseAPI?.data?.results?.initiatorFileFiveOriginal,
            });

            const getOperationModal = responseAPI?.data?.results?.requestType === "PROJECT" ? 1 : 0;
            setOperationModal(getOperationModal);
            form.resetFields();
            responseAPI?.data?.results?.siteType === "OO" ? setIsOOType(true) : setIsOOType(false);

            if (type !== "BULK") {
                if (responseAPI?.data?.results?.keyLocation) {
                    setSiteKeyLocations([{
                        key: 1,
                        location: responseAPI?.data?.results?.keyLocation,
                        siteId: responseAPI?.data?.results?.siteId,
                        siteName: responseAPI?.data?.results?.siteName
                    }]);
                } else {
                    setSiteKeyLocations([{
                        key: 1,
                        location: responseAPI?.data?.results?.keyLocation ? responseAPI?.data?.results?.keyLocation : "N/A",
                        siteId: responseAPI?.data?.results?.siteId,
                        siteName: responseAPI?.data?.results?.siteName
                    }]);
                }
            }
        } else {
            message.error('Error In Fetching API Request : ' + responseAPI.message);
        }
    }

    
    const nicValidation = (nic_number) => {
        var nic = nic_number;
        if (nic.length === 10) {
            if (nic.charAt(9) === 'V' || nic.charAt(9) === 'v') {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    const mobile_numberValidation = (mobile_number) => {
        var mobile = mobile_number;
        // Check if the number is exactly 9 or 10 digits long and consists only of digits
        if ((mobile.length === 9 || mobile.length === 10) && /^[0-9]+$/.test(mobile)) {
            // If it's 10 digits long, the first character must be '0'
            if (mobile.length === 10 && mobile.charAt(0) === '0') {
                return true;
            } else if (mobile.length === 9) { // If it's 9 digits long, no additional checks are needed
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    const fetchAllVisitingPurpose = async () => {
        var responseAPI = await getAllVisitingPurposeREWAMP();
        if (responseAPI.success) {
            setVisitingPurpose(responseAPI.data.results);
        } else {
            message.error('Error In Fetching API Request : ' + responseAPI.message);
        }
    }

    const fetchAllWorkType = async () => {
        var responseAPI = await getAllWorkTypeREWAMP();
        if (responseAPI.success) {
            setWorkingType(responseAPI.data.results);
        } else {
            message.error('Error In Fetching API Request : ' + responseAPI.message);
        }
    }

    const fetchAllWorkArea = async () => {
        var responseAPI = await getAllWorkAreaREWAMP();
        if (responseAPI.success) {
            setWorkArea(responseAPI.data.results);
        } else {
            message.error('Error In Fetching API Request : ' + responseAPI.message);
        }
    }

    const fetchAllAccessNeed = async () => {
        var responseAPI = await getAllAccessNeedREWAMP();
        if (responseAPI.success) {
            setAccessNeed(responseAPI.data.results);
        } else {
            message.error('Error In Fetching API Request : ' + responseAPI.message);
        }
    }


    const fetchAllGroupHandlers = async () => {
        var responseAPI = await getAllGroupHandlersREWAMP();
        if (responseAPI.success) {
            setHandlerGroup(responseAPI.data.results);
        } else {
            message.error('Error In Fetching API Request : ' + responseAPI.message);
        }
    }

    const showNotification = (type, message, description) => {
        notification[type]({
            message: message,
            description: description,
            //   placement: 'top'
        });
    }

    const fetchAllRequestCategory = async () => {
        var responseAPI = await getAllRequestCategoryREWAMP();
        if (responseAPI.success) {
            setRequestCategory(responseAPI.data.results);
        } else {
            message.error('Error In Fetching API Request : ' + responseAPI.message);
        }
    }

    const fetchAllSubRequestType = async () => {
        var responseAPI = await getAllSubRequestTypeREWAMP();
        if (responseAPI.success) {
            setSubRequestType(responseAPI.data.results);
        } else {
            message.error('Error In Fetching API Request : ' + responseAPI.message);
        }
    }

    // bulkSiteAccessRequestREWAMP
    const fetchBulkSiteAccessRequestREWAMP = async (id, data) => {
        setIsLoading(true);
        var responseAPI = await updateSiteAccessRequestStatusREWAMP(id, data, type);
        if (responseAPI.success) {
            fetchSiteAccessRequestByID(id, type);
            setIsLoading(false);
            successAccessRequest();
        } else {
            message.error('Error In Fetching API Request : ' + responseAPI.message);
        }
    }

    const fetchSiteINFObySiteID = async (siteID) => {
        var responseAPI = await getSiteDetailsBySiteIDREWAMP(siteID);
        const currentSiteDataArray = siteDataArray;
        if (responseAPI.success) {
            const dataResponse = responseAPI.data;
            const startTimeFetchWeekdays = dataResponse?.workingDaysAccessibleHours?.split('-')[0];
            const endTimeFetchWeekDays = dataResponse?.workingDaysAccessibleHours?.split('-')[1];
            const startTimeFetchWeekends = dataResponse?.weekendsOrHolidaysAccessibleHours?.split('-')[0];
            const endTimeFetchWeekends = dataResponse?.weekendsOrHolidaysAccessibleHours?.split('-')[1];
            const dataArray = {
                siteId: siteID,
                siteName: dataResponse?.siteName,
                siteType: dataResponse?.siteTypeName,
                regionId: dataResponse?.regionId,
                regionName: dataResponse?.regionName,
                depotId: dataResponse?.depotId,
                depotName: dataResponse?.depotName,
                sitePrimaryKey: dataResponse?.id,
                latitude: dataResponse?.latitude,
                longitude: dataResponse?.longitude,
                siteOpeningTime: startTimeFetchWeekdays,
                siteClosingTime: endTimeFetchWeekDays,
                weekendSiteOpeningTime: startTimeFetchWeekends,
                weekendSiteClosingTime: endTimeFetchWeekends,
                keyLocation: dataResponse?.keyLocation,
                siteAddress: dataResponse?.siteAddress,
                siteOwner: dataResponse?.siteOwner,
                siteOwnerAddress: dataResponse?.siteOwnerAddress,
                siteOwnerFullName: dataResponse?.siteOwnerName,
            }
            currentSiteDataArray.push(dataArray);
            setSiteJsonData(currentSiteDataArray);
        } else {
            message.error('Error In Fetching API Request : ' + responseAPI.message);
        }
        console.log(siteDataArray);
    }

    const handleFileUpload = (info) => {
        const { file, fileList } = info;
        const uploadedFile = file;
        const reader = new FileReader();
        reader.onload = (e) => {
            const text = e?.target?.result;
            const headers = parseCsvHeaders(text);
            const isHeaderAvailable = siteDetailsHeaders?.every((header) => headers?.includes(header));
            if (!isHeaderAvailable) {
                showNotification('error', 'Site Details', 'Invalid Headers In CSV File');
            } else {
                const data = parseCsvToJson(text);
                const siteIDList = data?.map((item, index) => {
                    fetchSiteINFObySiteID(item?.siteId);
                    return item?.siteId;
                });
                showNotification('info', 'Site Details', 'Site Details Fetch From Server Successfully');
            }
        };
        reader?.readAsText(uploadedFile);
    };

    // const handleFileUploadUsers = (info) => {
    //     const { file, fileList } = info;
    //     const uploadedFile = file;
    //     const reader = new FileReader();
    //     reader.onload = (e) => {
    //         const text = e.target.result;
    //         const headers = parseCsvHeaders(text);
    //         const isHeaderAvailable = userUploadFileHeaders.every((header) => headers.includes(header));
    //         if (!isHeaderAvailable) {
    //             showNotification('error', 'Team Members', 'Invalid Headers In CSV File');
    //         } else {
    //             const data = parseCsvToJson(text);
    //             const userDataArray = [];
    //             const existingTeamArr = teamMemberJson.length > 0 ? teamMemberJson : [];
    //             const userRelatedData = data?.map((item, index) => {
    //                 const userJsonData = {
    //                     name: item?.name ? item.name : "N/A",
    //                     contactNumber: item?.contactNumber ? item.contactNumber : "N/A",
    //                     nic: item?.nic ? item.nic : "N/A",
    //                     company: item?.company ? item.company : "N/A",
    //                     isKeyHolder: false,
    //                 }
    //                 userDataArray.push(userJsonData);
    //                 return userJsonData;
    //             });
    //             const newConatArr = existingTeamArr.concat(userDataArray);
    //             setTeamMemberJson([]);
    //             setTeamMemberJson(newConatArr);
    //             message.info("Team Members Bulk Data Extracted Successfully");
    //         }
    //     };
    //     reader?.readAsText(uploadedFile);
    // };

    const handleFileUploadUsers = (info) => {
        const { file } = info;
        if (file === null) {
            message.error('No File Uploaded, Please Upload File');
            return;
        }
    
        const reader = new FileReader();
        reader.onload = (e) => {
            const text = e.target.result;
            const headers = parseCsvHeaders(text);
            const isHeaderAvailable = userUploadFileHeaders.every((header) => headers.includes(header));
            
            if (!isHeaderAvailable) {
                showNotification('error', 'Team Members', 'Invalid Headers In CSV File');
                return;
            }
    
            const data = parseCsvToJson(text);
            const userDataArray = [];
            const validationMatrix = [];
            const existingTeamArr = teamMemberJson.length > 0 ? teamMemberJson : [];
    
            try {
                data?.forEach((item) => {
                    if (!nicValidation(item?.nic) || !mobile_numberValidation(item?.contactNumber)) {
                        validationMatrix.push({ 
                            name: item?.name,
                            nic: item?.nic,
                            nicValidationStatus: nicValidation(item?.nic),
                            contactNumber: item?.contactNumber,
                            contactNumberValidationStatus: mobile_numberValidation(item?.contactNumber) 
                        });
                    } else {
                        const userJsonData = {
                            name: item?.name ? item.name : "N/A",
                            contactNumber: item?.contactNumber ? item.contactNumber : "N/A",
                            nic: item?.nic ? item.nic : "N/A",
                            company: item?.company ? item.company : "N/A",
                            isKeyHolder: false,
                            keyLocations: []
                        };
                        userDataArray.push(userJsonData);
                    }
                });
    
                const newConcatArr = existingTeamArr.concat(userDataArray);
                const isAnyInvalid = validationMatrix.some((val) => !val.nicValidationStatus || !val.contactNumberValidationStatus);
    
                if (isAnyInvalid) {
                    setUserValidation(validationMatrix);
                    setOpenTeamErrorLog(true);
                } else {
                    setTeamMemberJson(newConcatArr);
                    setOpenTeamErrorLog(false);
                    message.success('Team Members Bulk Data Extracted Successfully');
                }
            } catch (error) {
                message.error('Error In Parsing CSV Data');
            }
        };
    
        reader.readAsText(file);
    };

    const teamMembersError = [
        {
            title: <span className='textStyle-small'>Name</span>,
            dataIndex: 'name',
            key: 'name',
            render: text => <span className='textStyle-small'>{text}</span>,
        },
        {
            title: <span className='textStyle-small'>NIC</span>,
            dataIndex: 'nic',
            key: 'nic',
            render: text => <span className='textStyle-small'>{text}</span>,
        },
        {
            title: <span className='textStyle-small'>NIC Validation</span>,
            dataIndex: 'nicValidationStatus',
            key: 'nicValidationStatus',
            render: text => <Tag color={text === true ? 'green' : 'red'}>
                <span className='textStyle-small'>
                    {text === true ? 'Valid NIC' : 'Invalid NIC'}
                </span>
            </Tag>,
        },
        {
            title: <span className='textStyle-small'>Contact Number</span>,
            dataIndex: 'contactNumber',
            key: 'contactNumber',
            render: text => <span className='textStyle-small'>{text}</span>,
        },
        {
            title: <span className='textStyle-small'>Contact Number Validation</span>,
            dataIndex: 'contactNumberValidationStatus',
            key: 'contactNumberValidationStatus',
            render: text => <Tag color={text === true ? 'green' : 'red'}>
                <span className='textStyle-small'>
                    {text === true ? 'Valid Contact Number' : 'Invalid Contact Number'}
                </span>
            </Tag>,
        }
    ]    

    const parseCsvToJson = (text) => {
        const lines = text.split('\n');
        const headers = lines[0].split(',').map(header => header?.trim());
        const jsonData = [];

        for (let i = 1; i < lines.length; i++) {
            if (lines[i].trim() === '') continue;

            const values = lines[i].split(',');
            console.log('Line', i, 'values:', values);

            const obj = {};
            for (let j = 0; j < headers.length; j++) {
                obj[headers[j]] = values[j] ? values[j].trim() : '';
            }
            jsonData.push(obj);
        }

        return jsonData;
    };

    const parseCsvHeaders = (text) => {
        try {
            const lines = text.split('\n');
            const headers = lines[0].split(',').map(header => header?.trim());
            return headers;
        } catch (error) {
            message.error('Error In Parsing CSV Headers');
        }
    };


    const handleBulkUpload = () => {
        if (bulkUpload === false) {
            setBulkUpload(true);
        } else {
            setBulkUpload(false);
        }
    }

    const openModelForInsertDataCreateUsers = () => {
        setEditModelOpen(true);
        setFormID(1);
    }

    const openModelForInsertDataCreateVehicles = () => {
        setEditModelOpen(true);
        setFormID(2);
    }

    const openModelForInsertDataCreateEquipment = () => {
        setEditModelOpen(true);
        setFormID(3);
    }

    const openModelForInsertDataCreateInstruments = () => {
        setEditModelOpen(true);
        setFormID(4);
    }

    const closeModelForInsertData = () => {
        setFormID(0);
        setEditModelOpen(false);
    }

    const addTeamMembers = (values) => {
        if (isUpdate) {
            const currentJsonArray = teamMemberJson;
            const currentArr_Index = selectedTeamMember?.arrIndex;
            const memberJson = {
                name: values.name,
                contactNumber: values.contactNumber,
                nic: values.nic,
                company: values.company,
                isKeyHolder: currentJsonArray[currentArr_Index]?.isKeyHolder ? currentJsonArray[currentArr_Index]?.isKeyHolder : false,
                isBlacklisted: currentJsonArray[currentArr_Index]?.isBlacklisted ? currentJsonArray[currentArr_Index]?.isBlacklisted : false,
                siteAccessRequestId: currentJsonArray[currentArr_Index]?.siteAccessRequestId ? currentJsonArray[currentArr_Index]?.siteAccessRequestId : null,
                keyLocations: currentJsonArray[currentArr_Index]?.keyLocations ? currentJsonArray[currentArr_Index]?.keyLocations : [],
            }
            currentJsonArray[currentArr_Index] = memberJson;
            setTeamMemberJson(currentJsonArray);
            message.success('Team Member Updated Successfully');
            handleResetModalForms();
            setSelectedTeamMember({
                id: '',
                name: '',
                contactNumber: '',
                nic: '',
                company: '',
            });
            setIsUpdate(false);
        } else {
            const currentJsonArray = teamMemberJson;
            const memberJson = {
                name: values.name,
                contactNumber: values.contactNumber,
                nic: values.nic,
                company: values.company,
                isKeyHolder: false,
            }
            currentJsonArray.push(memberJson);
            setTeamMemberJson(currentJsonArray);
            message.success('Team Member Added Successfully');
            handleResetModalForms();
        }

        closeModelForInsertData();
        setSelectedTeamMember({
            id: '',
            name: '',
            contactNumber: '',
            nic: '',
            company: '',
        });
        setIsUpdate(false);
    }

    const addInstrumentsActions = (values) => {
        if (isUpdate) {
            const currentJsonArray = instrumentJson;
            const currentArr_Index = selectedInstrument?.arrIndex;
            const instrumentJsonData = {
                itemName: values.itemName ? values.itemName : null,
                serialNumber: values.serialNumber ? values.serialNumber : null,
                actionType: values.actionType ? values.actionType : null,
                description: values.description ? values.description : null,
                quantity: values.quantity ? values.quantity : null
            }
            currentJsonArray[currentArr_Index] = instrumentJsonData;
            setInstrumentJson(currentJsonArray);
            message.success('Instrument Updated Successfully');
            handleResetModalForms();
            setSelectedInstrument({
                id: '',
                itemName: '',
                serialNumber: '',
                actionType: '',
                description: '',
                quantity: '',
            });
            setIsUpdate(false);
        } else {
            const currentJsonArray = instrumentJson;
            const instrumentJsonData = {
                itemName: values.itemName,
                serialNumber: values.serialNumber,
                actionType: values.actionType,
                description: values.description,
                quantity: values.quantity
            }
            currentJsonArray.push(instrumentJsonData);
            setInstrumentJson(currentJsonArray);
            message.success('Instrument Added Successfully');
            handleResetModalForms();
            console.log(currentJsonArray);
        }

        closeModelForInsertData();
        setSelectedInstrument({
            id: '',
            itemName: '',
            serialNumber: '',
            actionType: '',
            description: '',
            quantity: '',
        });
        setIsUpdate(false);
    }

    const addEquipmentActions = (values) => {
        if (isUpdate) {
            const currentJsonArray = equpmentJson;
            const currentArr_Index = selectedEquipment?.arrIndex;
            const equipmentJsonData = {
                itemName: values.itemName ? values.itemName : null,
                serialNumber: values.serialNumber ? values.serialNumber : null,
                actionType: values.actionType ? values.actionType : null,
                description: values.description ? values.description : null,
                quantity: values.quantity ? values.quantity : null
            }
            currentJsonArray[currentArr_Index] = equipmentJsonData;
            setEqupmentJson(currentJsonArray);
            message.success('Equipment Updated Successfully');
            handleResetModalForms();
            setSelectedEquipment({
                id: '',
                itemName: '',
                serialNumber: '',
                actionType: '',
                description: '',
                quantity: '',
            });
        } else {
            const currentJsonArray = equpmentJson;
            const equipmentJsonData = {
                itemName: values.itemName,
                serialNumber: values.serialNumber,
                actionType: values.actionType,
                description: values.description,
                quantity: values.quantity
            }
            currentJsonArray.push(equipmentJsonData);
            setEqupmentJson(currentJsonArray);
            message.success('Equipment Added Successfully');
            handleResetModalForms();
            console.log(currentJsonArray);
        }

        closeModelForInsertData();
        setIsUpdate(false);
        setSelectedEquipment({
            id: '',
            itemName: '',
            serialNumber: '',
            actionType: '',
            description: '',
            quantity: '',
        });
    }

    const resetAll_Fields = () => {
        setSelectedEquipment({
            id: '',
            itemName: '',
            serialNumber: '',
            actionType: '',
            description: '',
            quantity: '',
        });

        setSelectedInstrument({
            id: '',
            itemName: '',
            serialNumber: '',
            actionType: '',
            description: '',
            quantity: '',
        });

        setSelectedTeamMember({
            id: '',
            name: '',
            contactNumber: '',
            nic: '',
            company: '',
        });

        setSelectedVehicle({
            id: '',
            vehicleNumber: '',
            vehicleType: '',
        });
        setIsUpdate(false);
    }

    const addVehicleActions = (values) => {
        if (isUpdate) {
            const currentJsonArray = vehicleJson;
            const currentArr_Index = selectedVehicle?.arrIndex;
            const vehicleJsonData = {
                vehicleNumber: values.vehicleNumber ? values.vehicleNumber : null,
                vehicleType: values.vehicleType ? values.vehicleType : null
            }
            currentJsonArray[currentArr_Index] = vehicleJsonData;
            setVehicleJson(currentJsonArray);
            message.success('Vehicle Updated Successfully');
            handleResetModalForms();
            setSelectedVehicle({
                id: '',
                vehicleNumber: '',
                vehicleType: '',
            });
            setIsUpdate(false);
        } else {
            const currentJsonArray = vehicleJson;
            const vehicleJsonData = {
                vehicleNumber: values.vehicleNumber,
                vehicleType: values.vehicleType
            }
            currentJsonArray.push(vehicleJsonData);
            setVehicleJson(currentJsonArray);
            message.success('Vehicle Added Successfully');
            handleResetModalForms();
        }

        closeModelForInsertData();
        setSelectedVehicle({
            id: '',
            vehicleNumber: '',
            vehicleType: '',
        });
        setIsUpdate(false);
    }

    const getTitle = (formID) => {
        if (formID === 1) {
            return <span style={{ fontWeight: "bold" }} className='textStyle-small'>Add Team Members </span>;
        } else if (formID === 2) {
            return <span style={{ fontWeight: "bold" }} className='textStyle-small'>Add Vehicles </span>;
        } else if (formID === 3) {
            return <span style={{ fontWeight: "bold" }} className='textStyle-small'>Add Equipment </span>;
        } else if (formID === 4) {
            return <span style={{ fontWeight: "bold" }} className='textStyle-small'>Add Instruments </span>
        } else if (formID === 5) {
            return <span style={{ fontWeight: "bold" }} className='textStyle-small'>Key Location Details </span>
        }
    };

    const requestInit = (values) => {
        let getPermissionCreateTimeDate = moment(values?.permissionStartDatetime).format('YYYY-MM-DD HH:mm');
        getPermissionCreateTimeDate = getPermissionCreateTimeDate.split(' ').join('T');

        let permissionEndDate = moment(values?.permissionEndtDatetime).format('YYYY-MM-DD');

        let downTimeRequiredDate = moment(values?.downTimeRequiredDate).format('YYYY-MM-DD');

        if (teamMemberJson.length === 0) {
            message.error('Please Add Team Members to Proceed');
            return;
        }

        const requestJsonFile = JSON.stringify({
            requestId: requestDetails?.requestId,
            siteId: requestDetails?.siteId,
            siteName: requestDetails?.siteName,
            siteType: requestDetails?.siteType,
            regionId: requestDetails?.regionId,
            regionName: requestDetails?.regionName,
            depotId: requestDetails?.depotId,
            depotName: requestDetails?.depotName,
            keyLocationLatitude: requestDetails?.keyLocationLatitude,
            keyLocationLongitude: requestDetails?.keyLocationLongitude,
            siteOpeningTime: requestDetails?.siteOpeningTime,
            siteClosingTime: requestDetails?.siteClosingTime,
            weekendSiteOpeningTime: requestDetails?.weekendSiteOpeningTime,
            weekendSiteClosingTime: requestDetails?.weekendSiteClosingTime,
            handlerGroupId: values?.handler ? values.handler : null,
            handlerGroupName: handlerGroup?.filter(item => item.id === values.handler)[0]?.name ? handlerGroup?.filter(item => item.id === values.handler)[0]?.name : null,

            sitePrimaryKey: requestDetails?.sitePrimaryKey,
            latitude: requestDetails?.latitude,
            longitude: requestDetails?.longitude,
            siteAddress: requestDetails?.siteAddress,
            siteOwner: requestDetails?.siteOwner,
            siteOwnerAddress: requestDetails?.siteOwnerAddress,
            siteOwnerFullName: requestDetails?.siteOwnerName,

            permissionStartDateTime: getPermissionCreateTimeDate ? getPermissionCreateTimeDate : null,
            permissionEndDate: values?.permissionEndtDatetime ? values.permissionEndtDatetime : null,
            description: values?.workDescriptions ? values.workDescriptions : null,
            projectName: values?.projectName ? values.projectName : null,
            scopeOfWork: values?.scopeOfWork ? values.scopeOfWork : null,
            teamLeaderName: values?.nameOfTeamLeader ? values.nameOfTeamLeader : null,
            downTimeRequiredDate: values?.downTimeRequiredDate ? values.downTimeRequiredDate : null,
            teamLeaderContact: values?.contactNumberOfTeamLeader ? values.contactNumberOfTeamLeader : null,
            requestType: values?.type,
            requestCategoryId: values?.category ? values.category : null,
            subRequestTypeId: values?.subCategories ? values.subCategories : null,

            initiatorFileOne: null,
            initiatorFileTwo: null,
            initiatorFileThree: null,
            initiatorFileFour: null,
            initiatorFileFive: null,
            initiatorUserId: 1,
            initiatorGroupId: 1,
            initiatorGroupName: null,

            optionalRemarksOne: values?.reason ? values.reason : null,
            optionalRemarksTwo: values?.remark ? values.remark : null,
            optionalRemarksThree: null,

            visitingPurposeId: values?.visitingPurpose ? values.visitingPurpose : null,
            workAreaId: values?.workArea ? values.workArea : null,
            workTypeId: values?.workType ? values.workType : null,
            accessNeedId: values?.accessNeed ? values.accessNeed : null,

            teamMembers: teamMemberJson,
            equipments: equpmentJson,
            instruments: instrumentJson,
            vehicles: vehicleJson,

            // initiatorFileOne: fileInformations?.isDeleteFileOne === true ? null : requestDetails?.initiatorFileOne,
            // initiatorFileOneOriginal: fileInformations?.isDeleteFileOne === true ? null : requestDetails?.initiatorFileOneOriginal,

            // initiatorFileTwo: fileInformations?.isDeleteFileTwo === true ? null : requestDetails?.initiatorFileTwo,
            // initiatorFileTwoOriginal: fileInformations?.isDeleteFileTwo === true ? null : requestDetails?.initiatorFileTwoOriginal,

            // initiatorFileThree: fileInformations?.isDeleteFileThree === true ? null : requestDetails?.initiatorFileThree,
            // initiatorFileThreeOriginal: fileInformations?.isDeleteFileThree === true ? null : requestDetails?.initiatorFileThreeOriginal,

            // initiatorFileFour: fileInformations?.isDeleteFileFour === true ? null : requestDetails?.initiatorFileFour,
            // initiatorFileFourOriginal: fileInformations?.isDeleteFileFour === true ? null : requestDetails?.initiatorFileFourOriginal,

            // initiatorFileFive: fileInformations?.isDeleteFileFive === true ? null : requestDetails?.initiatorFileFive,
            // initiatorFileFiveOriginal: fileInformations?.isDeleteFileFive === true ? null : requestDetails?.initiatorFileFiveOriginal,

            initiatorFileOne: fileLibrary?.initiatorFileOne || null,
            initiatorFileOneOriginal: fileLibrary?.initiatorFileOneOriginal || null,

            initiatorFileTwo: fileLibrary?.initiatorFileTwo || null,
            initiatorFileTwoOriginal: fileLibrary?.initiatorFileTwoOriginal || null,

            initiatorFileThree: fileLibrary?.initiatorFileThree || null,
            initiatorFileThreeOriginal: fileLibrary?.initiatorFileThreeOriginal || null,

            initiatorFileFour: fileLibrary?.initiatorFileFour || null,
            initiatorFileFourOriginal: fileLibrary?.initiatorFileFourOriginal || null,

            initiatorFileFive: fileLibrary?.initiatorFileFive || null,
            initiatorFileFiveOriginal: fileLibrary?.initiatorFileFiveOriginal || null,

        });

        console.log(requestJsonFile);

        // const fileOne = fileInformations?.initiatorFileOne;
        // const fileTwo = fileInformations?.initiatorFileTwo;
        // const fileThree = fileInformations?.initiatorFileThree;
        // const fileFour = fileInformations?.initiatorFileFour;
        // const fileFive = fileInformations?.initiatorFileFive;

        // console.log(fileOne);

        // const fileOne = values?.fileOne?.fileList[0]?.originFileObj;
        // const fileTwo = values?.fileTwo?.fileList[0]?.originFileObj;
        // const fileThree = values?.fileThree?.fileList[0]?.originFileObj;
        // const fileFour = values?.fileFour?.fileList[0]?.originFileObj;
        // const fileFive = values?.fileFive?.fileList[0]?.originFileObj;

        // console.log(fileOne);
        // console.log(fileTwo);
        // console.log(fileThree);
        // console.log(fileFour);
        console.log(fileInformations?.initiatorFileFive);

        const requestForm = new FormData();
        requestForm.append('requestDto', new Blob([requestJsonFile], { type: 'application/json' }));
        requestForm.append('file1', null);
        requestForm.append('file2', null);
        requestForm.append('file3', null);
        requestForm.append('file4', null);
        requestForm.append('file5', null);

        // if (moment(getPermissionCreateTimeDate).isBefore(moment(), 'day')) {
        //     message.error('Permission Start Date must be today or after the current date.');
        //     return;
        // } 

        // if (moment(permissionEndDate).isBefore(getPermissionCreateTimeDate, 'day')) {
        //     message.error('Permission End Date must be after the Permission Start Date.');
        //     return;
        // }

        // if (values?.downTimeRequiredDate !== null) {
        //     if (moment(downTimeRequiredDate).isBetween(getPermissionCreateTimeDate, permissionEndDate, 'day', '[]')) {
        //         newRequestHandler(requestForm);
        //     } else {
        //         message.error('Downtime Required Date must be between Permission Start Date and Permission End Date.');
        //         return;
        //     }
        // } else {
        //     newRequestHandler(requestForm);
        // }

        newRequestHandler(id, requestForm);
    }

    const newRequestHandler = (id, requestForm) => {
        Modal.confirm({
            title: "Confirm Access Update Request",
            content: `Do you want to update the access request?`,
            onOk: () => {
                fetchBulkSiteAccessRequestREWAMP(id, requestForm);
            },
            onCancel: () => { },
        });
    };

    const successAccessRequest = () => {
        Modal.success({
            title: 'Success',
            content: 'Access Request Updated Successfully',
            onOk() {
                history.goBack();
            },
            onCancel() {
                window.location.href = '/access-dashboard';
            },
        });
    }

    const popFromUserArray = (arrIndex) => {
        const existingArray = [...teamMemberJson];
        if (arrIndex !== -1) {
            existingArray.splice(arrIndex, 1);
        }
        setTeamMemberJson(existingArray);
    }

    const popFromEquipmentArray = (arrIndex) => {
        const existingArray = [...equpmentJson];
        if (arrIndex !== -1) {
            existingArray.splice(arrIndex, 1);
        }
        setEqupmentJson(existingArray);
    }

    const popFromVehicleArray = (arrIndex) => {
        const existingArray = [...vehicleJson];
        if (arrIndex !== -1) {
            existingArray.splice(arrIndex, 1);
        }
        setVehicleJson(existingArray);
    }

    const popFromInstrumentArray = (arrIndex) => {
        const existingArray = [...instrumentJson];
        if (arrIndex !== -1) {
            existingArray.splice(arrIndex, 1);
        }
        setInstrumentJson(existingArray);
    }

    const makeAsKeyHolder = (index) => {
        const existingArray = [...teamMemberJson];
        if (existingArray[index]) {
            existingArray[index].isKeyHolder = !existingArray[index].isKeyHolder;
        } else {
            existingArray[index] = { isKeyHolder: false };
        }
        setTeamMemberJson(existingArray);
    }

    const openModelForInsertDataCreateKeyLocation = () => {
        setEditModelOpen(true);
        setFormID(5);
    }

    const keyLocationInformations = [
        {
            title: <span className="textStyle">Site ID</span>,
            dataIndex: 'siteId',
            key: 'siteId',
            render: text => <span className="textStyleChild">{text}</span>,
        },
        {
            title: <span className="textStyle">Site Name</span>,
            dataIndex: 'siteName',
            key: 'siteName',
            render: text => <span className="textStyleChild">{text}</span>,
        },
        {
            title: <span className="textStyle">Key Location Address</span>,
            dataIndex: 'location',
            key: 'location',
            render: text => <span className="textStyleChild">{text ? text : 'Address Not Available'}</span>,
        },
    ]

    const fetchBulkSiteAccessRequestREWAMPImgUpload = async (data) => {
        var responseAPI = await uploadAccessRequestFiles(data);
        console.log(responseAPI);
        if (responseAPI.success) {
            return responseAPI;
        } else {
            return false;
        }
    };

    const handleRemove = (file) => {
        //setAccessFileList(prevList => prevList.filter(item => item.uid !== file.uid));
    };

    const getFileKey = (fileIndex) => {
        const mapping = {
            1: 'initiatorFileOne',
            2: 'initiatorFileTwo',
            3: 'initiatorFileThree',
            4: 'initiatorFileFour',
            5: 'initiatorFileFive',
        };
        return mapping[fileIndex] || null;
    };

    const resetFileLibrarySlot = (fileIndex) => {
        const fileKey = getFileKey(fileIndex);
        if (fileKey) {
            setFileLibrary((prevLibrary) => ({
                ...prevLibrary,
                [fileKey]: null,
                [`${fileKey}Original`]: null,
            }));
        }
    };
    

    const handleFileUploadFunc = async (file, fileIndex) => {
        setFileUploading(true);
        const formData = new FormData();
        formData.append('file', file);
        const result = await fetchBulkSiteAccessRequestREWAMPImgUpload(formData);
        if (result.success) {
            const updatedFileLibrary = { ...fileLibrary };
            const fileKey = getFileKey(fileIndex);
            if (fileKey) {
                updatedFileLibrary[fileKey] = result?.data?.results?.generatedImageName; 
                updatedFileLibrary[`${fileKey}Original`] = result?.data?.results?.originalImageName;
                setFileLibrary(updatedFileLibrary);
            }
            setFileUploading(false);
        } else {
            message.error(`${file.name} file upload failed. ${result.data}`);
            setFileUploading(false);
        }
    };
    
    const fileFive = {
        name: 'file',
        multiple: false,
        customRequest: async ({ file, onSuccess, onError }) => {
            try {
                await handleFileUploadFunc(file, 5);
                onSuccess("ok");
            } catch (error) {
                onError(error);
            }
        },
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onRemove: handleRemove,
    };

    const fileFour = {
        name: 'file',
        multiple: false,
        customRequest: async ({ file, onSuccess, onError }) => {
            try {
                await handleFileUploadFunc(file, 4);
                onSuccess("ok");
            } catch (error) {
                onError(error);
            }
        },
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onRemove: handleRemove,
    };

    const fileThree = {
        name: 'file',
        multiple: false,
        customRequest: async ({ file, onSuccess, onError }) => {
            try {
                await handleFileUploadFunc(file, 3);
                onSuccess("ok");
            } catch (error) {
                onError(error);
            }
        },
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onRemove: handleRemove,
    };

    const fileTwo = {
        name: 'file',
        multiple: false,
        customRequest: async ({ file, onSuccess, onError }) => {
            try {
                await handleFileUploadFunc(file, 2);
                onSuccess("ok");
            } catch (error) {
                onError(error);
            }
        },
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onRemove: handleRemove,
    };

    const fileOne = {
        name: 'file',
        multiple: false,
        customRequest: async ({ file, onSuccess, onError }) => {
            try {
                await handleFileUploadFunc(file, 1);
                onSuccess("ok");
            } catch (error) {
                onError(error);
            }
        },
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onRemove: handleRemove,
    };


    return (
        <body>
            <div style={{ margin: '0', padding: '10px', height: '100%' }}>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="page-title-box">
                            <div className="row">
                                <div className="col">
                                    <h4 className="page-title" style={{ fontWeight: "bold" }}>
                                        Update {type === 'BULK' ? ' Bulk' : ' Single'} Request
                                    </h4>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <a href="javascript:void(0);">Site Access </a>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <a href="javascript:void(0);">Update {type === 'BULK' ? ' Bulk' : ' Single'} Request </a>
                                        </li>
                                    </ol>
                                </div>
                                <div className="col-auto align-self-center">
                                    <a
                                        href="#"
                                        className="btn btn-sm btn-outline-primary"
                                        id="Dash_Date"
                                    >
                                        <span className="day-name" id="Day_Name">
                                            Today:
                                        </span>
                                        &nbsp;
                                        <span className="" id="Select_date">
                                            {new Date().toUTCString().slice(5, 16)}
                                        </span>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            className="feather feather-calendar align-self-center icon-xs ms-1"
                                        >
                                            <rect
                                                x="3"
                                                y="4"
                                                width="18"
                                                height="18"
                                                rx="2"
                                                ry="2"
                                            ></rect>
                                            <line x1="16" y1="2" x2="16" y2="6"></line>
                                            <line x1="8" y1="2" x2="8" y2="6"></line>
                                            <line x1="3" y1="10" x2="21" y2="10"></line>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{
                    backgroundColor: "#FFFFFF",
                    padding: "20px 20px 20px 20px",
                    borderRadius: "10px",
                    marginTop: "5px",
                    marginLeft: "20px"
                }}>
                    <Spin tip="Loading..." spinning={isLoading}>
                        <Form
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            layout="vertical"
                            style={{ marginLeft: "30px", marginTop: "20px" }}
                            onFinish={requestInit}
                            ref={formRefMain}
                            loading={true}
                            form={form}
                        >
                            <div>
                                {
                                    type === 'BULK' ? (
                                        <Collapse
                                            bordered={false}
                                            style={{ marginBottom: '20px' }}
                                        >
                                            <Panel header={<span className='textStyle-small'>Affected Sites</span>}
                                                key="1">
                                                <Row>
                                                    <Table
                                                        columns={columns}
                                                        dataSource={getBulkIDSet}
                                                        pagination={false}
                                                        className="table-striped-rows"
                                                        size='medium'
                                                    />
                                                </Row>
                                            </Panel>
                                        </Collapse>
                                    ) : null}


                                {
                                    type !== 'BULK' ? (
                                        <>
                                            <Row>
                                                <Col span={12}>
                                                    <Form.Item
                                                        label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Site ID</span>}
                                                        name="siteID"
                                                        initialValue={reqFormData?.siteId || ''}
                                                    >
                                                        <Input placeholder="Enter Site ID" defaultValue={reqFormData?.siteId || ''}
                                                            className='textStyle-small'
                                                            style={{ width: "80%", height: "35px", color: 'black' }}
                                                            disabled />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item
                                                        label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Site Name</span>}
                                                        name="siteName"
                                                        initialValue={reqFormData?.siteName || ''}
                                                    >
                                                        <Input placeholder="Enter Site Name" defaultValue={reqFormData?.siteName || ''}
                                                            style={{ width: "80%", height: "35px", color: 'black' }} className='textStyle-small'
                                                            disabled />
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col span={12}>
                                                    <Form.Item
                                                        label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Site Type</span>}
                                                        name="siteType"
                                                        initialValue={reqFormData?.siteType || ''}
                                                    >
                                                        <Input placeholder="Enter Site Type" defaultValue={reqFormData?.siteType || ''}
                                                            style={{ width: "80%", height: "35px", color: 'black' }} className='textStyle-small'
                                                            disabled />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item
                                                        label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Region Name</span>}
                                                        name="regionName"
                                                        initialValue={reqFormData?.regionName || ''}
                                                    >
                                                        <Input placeholder="Enter Region Name" defaultValue={reqFormData?.regionName || ''}
                                                            style={{ width: "80%", height: "35px", color: 'black' }} className='textStyle-small'
                                                            disabled />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </>
                                    ) : null
                                }


                                <Row>
                                    {
                                        type !== 'BULK' ? (
                                            <Col span={12}>
                                                <Form.Item
                                                    label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Depot Name</span>}
                                                    name="depotName"
                                                    initialValue={reqFormData?.depotName || ''}
                                                >
                                                    <Input placeholder="Enter Depot Name" defaultValue={reqFormData?.depotName || ''}
                                                        style={{ width: "80%", height: "35px", color: 'black' }} className='textStyle-small'
                                                        disabled />
                                                </Form.Item>
                                            </Col>
                                        ) : null
                                    }

                                    <Col span={type === 'BULK' ? 24 : 12}>
                                        <Form.Item
                                            label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Handler Group</span>}
                                            name="handler"
                                            initialValue={reqFormData?.handlerGroupId || ''}
                                        >
                                            <Select
                                                className='textStyle-small'
                                                style={{
                                                    width: type === 'BULK' ? "90%" : "80%",
                                                    height: "35px"
                                                }}
                                                placeholder="Select Handler"
                                                allowClear
                                            >
                                                {
                                                    handlerGroup?.map((item, index) => {
                                                        return (
                                                            <Select.Option value={item.id}>{item.name}</Select.Option>
                                                        )
                                                    }
                                                    )
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                {isOOType ? (<>

                                    <Row>
                                        <Col span={12}>
                                            <Form.Item
                                                label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Visiting Purpose</span>}
                                                name="visitingPurpose"
                                                initialValue={reqFormData?.visitingPurpose?.id || ''}
                                                placeholder="Select Visiting Purpose"
                                            >
                                                <Select
                                                    className='textStyle-small'
                                                    style={{ width: "80%", height: "35px" }}
                                                    placeholder="Select Visiting Purpose"
                                                >
                                                    {
                                                        visitingPurpose?.map((item, index) => {
                                                            return (
                                                                <Select.Option value={item.id}>{item.name}</Select.Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Access Need</span>}
                                                name="accessNeed"
                                                placeholder="Select Access Need"
                                                initialValue={reqFormData?.accessNeed?.id || ''}
                                            >
                                                <Select
                                                    className='textStyle-small'
                                                    style={{ width: "80%", height: "35px" }}
                                                    placeholder="Select Access Need"
                                                >
                                                    {
                                                        accessNeed?.map((item, index) => {
                                                            return (
                                                                <Select.Option value={item.id}>{item.name}</Select.Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col span={12}>
                                            <Form.Item
                                                label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Reason</span>}
                                                name="reason"
                                                placeholder="Enter Reason"
                                                initialValue={reqFormData?.optionalRemarksOne || ''}
                                            >
                                                <Input
                                                    className='textStyle-small'
                                                    style={{ width: "80%", height: "35px" }}
                                                    placeholder="Enter Reason"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Remark</span>}
                                                name="remark"
                                                placeholder="Enter Remark"
                                                initialValue={reqFormData?.optionalRemarksTwo || ''}
                                            >
                                                <Input
                                                    className='textStyle-small'
                                                    style={{ width: "80%", height: "35px" }}
                                                    placeholder="Enter Remark"
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col span={12}>
                                            <Form.Item
                                                label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Work Type</span>}
                                                name="workType"
                                                placeholder="Select Work Type"
                                                initialValue={reqFormData?.workType?.id || ''}
                                            >
                                                <Select
                                                    className='textStyle-small'
                                                    placeholder="Select Work Type"
                                                    style={{ width: "80%", height: "35px" }}>
                                                    {
                                                        workingType?.map((item, index) => {
                                                            return (
                                                                <Select.Option value={item.id}>{item.name}</Select.Option>
                                                            )
                                                        })
                                                    }
                                                </Select>

                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Work Area</span>}
                                                name="workArea"
                                                placeholder="Select Work Area"
                                                initialValue={reqFormData?.workArea?.id || ''}
                                            >
                                                <Select
                                                    className='textStyle-small'
                                                    placeholder="Select Work Area"
                                                    style={{ width: "80%", height: "35px" }}>
                                                    {
                                                        workArea?.map((item, index) => {
                                                            return (
                                                                <Select.Option value={item.id}>{item.name}</Select.Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                </>) : (<></>)}

                                <Row>
                                    <Col span={24}>
                                        <Form.Item
                                            label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Type</span>}
                                            name="type"
                                            rules={[{ required: true, message: 'Please select type!' }]}
                                            initialValue={reqFormData?.requestType === "PROJECT" ? 1 : 0}
                                        >
                                            <Select
                                                className='textStyle-small'
                                                style={{ width: "90%", height: "35px" }}
                                                placeholder="Select Type"
                                                onChange={(value) => setOperationModal(value)}
                                            >
                                                <Select.Option value={0}>Operation</Select.Option>
                                                <Select.Option value={1}>Project</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                {
                                    operationModal === 1 ? (
                                        <>
                                            <Row>
                                                <Col span={12}>
                                                    <Form.Item
                                                        label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Project Name</span>}
                                                        name="projectName"
                                                        initialValue={reqFormData?.projectName || ''}
                                                    >
                                                        <Input
                                                            className='textStyle-small'
                                                            style={{ width: "80%", height: "35px" }}
                                                            placeholder="Enter Project Name"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item
                                                        label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Scope of Work</span>}
                                                        name="scopeOfWork"
                                                        initialValue={reqFormData?.scopeOfWork || ''}
                                                    >
                                                        <Input
                                                            className='textStyle-small'
                                                            style={{ width: "80%", height: "35px" }}
                                                            placeholder="Enter Scope of Work"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col span={12}>
                                                    <Form.Item
                                                        label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Down Time Required Date</span>}
                                                        name="downTimeRequiredDate"
                                                        initialValue={reqFormData?.downTimeRequiredDate ? moment(reqFormData.downTimeRequiredDate, "YYYY-MM-DD") : null}
                                                    >
                                                        <DatePicker
                                                            placeholder="Select Date and Time"
                                                            className='ant-picker-input'
                                                            style={{ width: "80%", height: "35px" }}
                                                            format="YYYY-MM-DD"
                                                            suffixIcon={<CalendarOutlined style={{ fontSize: '14px', color: '#1890ff' }} />}
                                                        />
                                                    </Form.Item>
                                                </Col>

                                                <Col span={12}>
                                                    <Form.Item
                                                        label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Name of Team Leader</span>}
                                                        name="nameOfTeamLeader"
                                                        initialValue={reqFormData?.teamLeaderName || ''}
                                                    >
                                                        <Input
                                                            className='textStyle-small'
                                                            style={{ width: "80%", height: "35px" }}
                                                            placeholder="Enter Name of Team Leader"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col span={12}>
                                                    <Form.Item
                                                        label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Contact Number of Team Leader</span>}
                                                        name="contactNumberOfTeamLeader"
                                                        rules={[{ message: 'Please input your phone number!' },
                                                        { pattern: new RegExp(/^[0-9\b]+$/), message: 'The phone number is not valid!' },
                                                        { min: 10, message: 'The phone number is not valid!' }
                                                        ]}
                                                        initialValue={reqFormData?.teamLeaderContact || ''}>
                                                        <Input
                                                            className='textStyle-small'
                                                            style={{ width: "80%", height: "35px" }}
                                                            placeholder="Enter Contact Number of Team Leader"
                                                            type='number'
                                                        />
                                                    </Form.Item>
                                                </Col>

                                                <Col span={12}>
                                                    <Form.Item
                                                        label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Sub Request Categories</span>}
                                                        name="subCategories"
                                                        rules={[{ required: true, message: 'Please select sub categories!' }]}
                                                        initialValue={reqFormData?.subRequestType?.id || ''}
                                                    >
                                                        <Select
                                                            className='textStyle-small'
                                                            style={{ width: "80%", height: "35px" }}
                                                            placeholder="Select Sub Categories"
                                                        >
                                                            {
                                                                subRequestType?.map((item, index) => {
                                                                    return (
                                                                        <Select.Option value={item.id}>{item.name}</Select.Option>
                                                                    )
                                                                })
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                        </>
                                    ) : (
                                        <> </>
                                    )
                                }

                                {
                                    operationModal === 0 ? (
                                        <>
                                            <Row>

                                                <Col span={12}>
                                                    <Form.Item
                                                        label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Sub Request Categories</span>}
                                                        name="subCategories"
                                                        rules={[{ required: true, message: 'Please select sub categories!' }]}
                                                        initialValue={reqFormData?.subRequestType?.id || ''}
                                                    >
                                                        <Select
                                                            className='textStyle-small'
                                                            style={{ width: "80%", height: "35px" }}
                                                            placeholder="Select Sub Categories"
                                                        >                                                        {
                                                                subRequestType?.map((item, index) => {
                                                                    return (
                                                                        <Select.Option value={item.id}>{item.name}</Select.Option>
                                                                    )
                                                                })
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </>
                                    ) : (
                                        <></>
                                    )
                                }

                                <Row>
                                    <Col span={24}>
                                        <Form.Item
                                            label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Request Category</span>}
                                            name="category"
                                            rules={[{ required: true, message: 'Please select category!' }]}
                                            initialValue={reqFormData?.requestCategory?.id || ''}
                                        >
                                            <Select
                                                disabled
                                                className='textStyle-small'
                                                style={{ width: "90%", height: "35px" }}
                                                placeholder="Select Category"
                                            >
                                                {
                                                    requestCategory?.map((item, index) => {
                                                        return (
                                                            <Select.Option value={item.id}>{item.name}</Select.Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={12}>
                                        <Form.Item
                                            label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>
                                                Permission Start Date and Time
                                            </span>}
                                            name="permissionStartDatetime"
                                            rules={[{ required: true, message: 'Please input permission start date and time!' }]}
                                            initialValue={moment(reqFormData?.permissionStartDateTime)}
                                        >
                                            <DatePicker
                                                showTime
                                                placeholder="Select Date and Time"
                                                className='ant-picker-input'
                                                style={{ width: "80%", height: "35px" }}
                                                format="YYYY-MM-DD HH:mm"
                                                suffixIcon={<CalendarOutlined style={{ fontSize: '14px', color: '#1890ff' }} />}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Permission End Date</span>}
                                            name="permissionEndtDatetime"
                                            rules={[{ required: true, message: 'Please input permission end date!' }]}
                                            initialValue={moment(reqFormData?.permissionEndDate)}
                                        >
                                            <DatePicker
                                                placeholder="Select Date and Time"
                                                className='ant-picker-input'
                                                style={{ width: "80%", height: "35px", }}
                                                format="YYYY-MM-DD"
                                                suffixIcon={<CalendarOutlined style={{ fontSize: '14px', color: '#1890ff' }} />}
                                            />
                                        </Form.Item>

                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={12}>
                                        <Form.Item
                                            label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Add Team Members</span>}
                                            name="addTeamMembers"
                                            required={true}
                                            initialValue="Add Team Members"
                                        >
                                            <Input
                                                placeholder="Add New Team Members"
                                                className='textStyle-small'
                                                style={{ width: "80%", height: "35px", backgroundColor: "white" }}
                                                disabled
                                                suffix={
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <div
                                                            style={{
                                                                backgroundColor: '#1890ff',
                                                                width: '20px',
                                                                height: '20px',
                                                                borderRadius: '3px',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center'
                                                            }}
                                                            onClick={openModelForInsertDataCreateUsers}
                                                        >
                                                            <PlusOutlined
                                                                size='small'
                                                                style={{ cursor: 'pointer', color: 'white' }} />
                                                        </div>
                                                    </div>
                                                }
                                            />
                                        </Form.Item>
                                        {
                                            teamMemberJson?.map((item, index) => {
                                                return (
                                                    <div style={{
                                                        width: "80%",
                                                        backgroundColor: "#f0f0f0",
                                                        padding: "10px",
                                                        borderRadius: "10px",
                                                        marginTop: "10px"
                                                    }}
                                                    >
                                                        <Row>
                                                            <Col span={18}>
                                                                <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "12px" }}>
                                                                    {item?.name ? item.name : "N/A"}
                                                                </span>
                                                                <Row>
                                                                    <Col span={24}>
                                                                        <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "10px" }}>
                                                                            <PhoneOutlined style={{ fontSize: "12px", color: "#1890ff", marginRight: "5px" }} />
                                                                            {item?.contactNumber ? item.contactNumber : "N/A"}
                                                                        </span>
                                                                        <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "10px", marginLeft: "5px" }}>
                                                                            <UserOutlined style={{ fontSize: "12px", color: "#1890ff", marginRight: "5px" }} />
                                                                            {item?.nic ? item.nic : "N/A"}
                                                                        </span>
                                                                        <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "10px", marginLeft: "5px" }}>
                                                                            <BankOutlined style={{ fontSize: "12px", color: "#1890ff", marginRight: "5px" }} />
                                                                            {item?.company ? item.company : "N/A"}
                                                                        </span>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col span={6} style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                                                                <div
                                                                    onClick={() => makeAsKeyHolder(index)}
                                                                    style={{
                                                                        cursor: "pointer", color: "red", float: "right", width: "25px", height: "25px",
                                                                        borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "center",
                                                                        background: item?.isKeyHolder === true ? "#2d5ff4" : "gray"
                                                                    }}>
                                                                    <KeyOutlined style={{ cursor: "pointer", color: "white", fontSize: "14px" }} />
                                                                </div>
                                                                {
                                                                    item?.isKeyHolder === true ? (
                                                                        <div
                                                                            onClick={() => openModelForInsertDataCreateKeyLocation()}
                                                                            style={{
                                                                                cursor: "pointer", color: "red", float: "right", width: "25px", height: "25px", marginLeft: "5px",
                                                                                borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "center", background: "#f68407"
                                                                            }}>
                                                                            <GlobalOutlined style={{ cursor: "pointer", color: "white", fontSize: "14px" }} />
                                                                        </div>
                                                                    ) : null
                                                                }
                                                                <div
                                                                    onClick={() => popFromUserArray(index)}
                                                                    style={{
                                                                        cursor: "pointer", color: "red", float: "right", width: "25px", height: "25px", marginLeft: "5px",
                                                                        borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "center", background: "red"
                                                                    }}>
                                                                    <DeleteOutlined style={{ cursor: "pointer", color: "white", fontSize: "14px" }} />
                                                                </div>
                                                                <div
                                                                    onClick={() => {
                                                                        setSelectedTeamMember({
                                                                            arrIndex: index,
                                                                            id: item.id,
                                                                            name: item.name,
                                                                            contactNumber: item.contactNumber,
                                                                            nic: item.nic,
                                                                            company: item.company,
                                                                        });
                                                                        setIsUpdate(true);
                                                                        openModelForInsertDataCreateUsers();
                                                                    }}
                                                                    style={{
                                                                        cursor: "pointer", color: "red", float: "right", width: "25px", height: "25px", borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "center", background: "#42b02a", marginLeft: "5px"
                                                                    }}>
                                                                    <FormOutlined style={{ cursor: "pointer", color: "white", fontSize: "14px" }} />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Upload Team Members .csv Sheet</span>}
                                            name="uploadTeamMembers"
                                        >
                                            <Upload
                                                multiple={false}
                                                beforeUpload={() => false}
                                                accept=".csv"
                                                onChange={handleFileUploadUsers}
                                            >
                                                <Button icon={<UploadOutlined />} style={{
                                                    width: '395px'
                                                }} >
                                                    <span className='textStyle-small'>Upload Team Members</span>
                                                </Button>
                                            </Upload>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={12}>
                                        <Form.Item
                                            label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Add Equipment</span>}
                                            name="addEquipment"
                                        >
                                            <Input
                                                placeholder="Add New Equipment"
                                                style={{ width: "80%", height: "35px", backgroundColor: "white" }}
                                                className='textStyle-small'
                                                disabled
                                                suffix={
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <div
                                                            style={{
                                                                backgroundColor: '#1890ff',
                                                                width: '20px',
                                                                height: '20px',
                                                                borderRadius: '3px',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center'
                                                            }}
                                                            onClick={openModelForInsertDataCreateEquipment}
                                                        >
                                                            <PlusOutlined
                                                                size='small'
                                                                // onClick={handleIconClick} 
                                                                style={{ cursor: 'pointer', color: 'white' }} />
                                                        </div>
                                                    </div>
                                                }
                                            />
                                        </Form.Item>

                                        {
                                            equpmentJson?.map((item, index) => {
                                                return (
                                                    <div style={{ width: "80%", backgroundColor: "#f0f0f0", padding: "10px", borderRadius: "10px", marginTop: "10px" }}
                                                    >
                                                        <Row>
                                                            <Col span={20}>
                                                                <Row>
                                                                    <Col span={8}>
                                                                        <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "11px" }}>
                                                                            {item?.itemName ? item.itemName : "N/A"}
                                                                        </span>
                                                                    </Col>
                                                                    <Col span={16} style={{ display: "flex", flexDirection: "row" }}>
                                                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#cbc300", width: "auto", height: "20px", borderRadius: "5px", padding: "5px" }}>
                                                                            <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "10px" }}>
                                                                                <span style={{ color: "white" }}>{item?.actionType ? item.actionType : "N/A"}</span>
                                                                            </span>
                                                                        </div>
                                                                        <div style={{
                                                                            display: "flex", alignItems: "center", justifyContent: "center",
                                                                            backgroundColor: "#ab0eb9", width: "auto", height: "20px", borderRadius: "5px", marginLeft: "5px", padding: "5px"
                                                                        }}>
                                                                            <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "10px" }}>
                                                                                <span style={{ color: "white" }}>{item?.quantity ? item.quantity : "N/A"}</span>
                                                                            </span>
                                                                        </div>
                                                                    </Col>
                                                                    <span className='textStyle-small' style={{ fontSize: "12px", marginTop: "7px" }}>
                                                                        <b>{item?.serialNumber ? item.serialNumber : "N/A"}</b><br></br>
                                                                        {item?.description ? item.description : "N/A"}
                                                                    </span>
                                                                </Row>
                                                            </Col>
                                                            <Col span={4} style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                                                                <div
                                                                    onClick={
                                                                        () => {
                                                                            setSelectedEquipment({
                                                                                arrIndex: index,
                                                                                id: item.id,
                                                                                itemName: item.itemName,
                                                                                actionType: item.actionType,
                                                                                quantity: item.quantity,
                                                                                serialNumber: item.serialNumber,
                                                                                description: item.description,
                                                                            });
                                                                            setIsUpdate(true);
                                                                            openModelForInsertDataCreateEquipment();
                                                                        }
                                                                    }
                                                                    style={{
                                                                        cursor: "pointer", color: "red", float: "right", width: "25px", height: "25px", borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "center", background: "#42b02a", marginRight: '2px'
                                                                    }}>
                                                                    <FormOutlined style={{ cursor: "pointer", color: "white", fontSize: "14px" }} />
                                                                </div>
                                                                <div
                                                                    onClick={() => popFromEquipmentArray(index)}
                                                                    style={{
                                                                        cursor: "pointer", color: "red", float: "right", width: "25px", height: "25px", borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "center", background: "red"
                                                                    }}>
                                                                    <DeleteOutlined style={{ cursor: "pointer", color: "white", fontSize: "14px" }} />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Col>
                                    {/* add instruments */}
                                    <Col span={12}>
                                        <Form.Item
                                            label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Add Instruments</span>}
                                            name="addInstruments"
                                        >
                                            <Input
                                                placeholder="Add New Instruments"
                                                style={{ width: "80%", height: "35px", backgroundColor: "white"  }}
                                                className='textStyle-small'
                                                disabled
                                                suffix={
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <div
                                                            style={{
                                                                backgroundColor: '#1890ff',
                                                                width: '20px',
                                                                height: '20px',
                                                                borderRadius: '3px',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center'
                                                            }}
                                                            onClick={openModelForInsertDataCreateInstruments}
                                                        >
                                                            <PlusOutlined
                                                                size='small'
                                                                // onClick={handleIconClick} 
                                                                style={{ cursor: 'pointer', color: 'white' }} />
                                                        </div>
                                                    </div>
                                                }
                                            />
                                        </Form.Item>
                                        {
                                            instrumentJson?.map((item, index) => {
                                                return (
                                                    <div style={{ width: "80%", backgroundColor: "#f0f0f0", padding: "10px", borderRadius: "10px", marginTop: "10px" }}
                                                    >
                                                        <Row>
                                                            <Col span={20}>
                                                                <Row>
                                                                    <Col span={8}>
                                                                        <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "11px" }}>
                                                                            {item?.itemName ? item.itemName : "N/A"}
                                                                        </span>
                                                                    </Col>
                                                                    <Col span={16} style={{ display: "flex", flexDirection: "row" }}>
                                                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#cbc300", width: "auto", height: "20px", borderRadius: "5px", padding: "5px" }}>
                                                                            <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "10px" }}>
                                                                                <span style={{ color: "white" }}>{item?.actionType ? item.actionType : "N/A"}</span>
                                                                            </span>
                                                                        </div>
                                                                        <div style={{
                                                                            display: "flex", alignItems: "center", justifyContent: "center",
                                                                            backgroundColor: "#ab0eb9", width: "auto", height: "20px", borderRadius: "5px", marginLeft: "5px", padding: "5px"
                                                                        }}>
                                                                            <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "10px" }}>
                                                                                <span style={{ color: "white" }}>{item?.quantity ? item.quantity : "N/A"}</span>
                                                                            </span>
                                                                        </div>
                                                                    </Col>
                                                                    <span className='textStyle-small' style={{ fontSize: "12px", marginTop: "7px" }}>
                                                                        <b>{item?.serialNumber ? item.serialNumber : "N/A"}</b><br></br>
                                                                        {item?.description ? item.description : "N/A"}
                                                                    </span>
                                                                </Row>
                                                            </Col>
                                                            <Col span={4} style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                                                                <div
                                                                    onClick={() => {
                                                                        setSelectedInstrument({
                                                                            arrIndex: index,
                                                                            id: item.id,
                                                                            itemName: item.itemName,
                                                                            actionType: item.actionType,
                                                                            quantity: item.quantity,
                                                                            serialNumber: item.serialNumber,
                                                                            description: item.description,
                                                                        });
                                                                        setIsUpdate(true);
                                                                        openModelForInsertDataCreateInstruments();
                                                                    }}
                                                                    style={{
                                                                        cursor: "pointer", color: "red", float: "right", width: "25px", height: "25px", borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "center", background: "#42b02a", marginRight: '2px'
                                                                    }}>
                                                                    <FormOutlined style={{ cursor: "pointer", color: "white", fontSize: "14px" }} />
                                                                </div>
                                                                <div
                                                                    onClick={() => popFromInstrumentArray(index)}
                                                                    style={{
                                                                        cursor: "pointer", color: "red", float: "right", width: "25px", height: "25px",
                                                                        borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "center", background: "red"
                                                                    }}>
                                                                    <DeleteOutlined style={{ cursor: "pointer", color: "white", fontSize: "14px" }} />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={12}>
                                        <Form.Item
                                            label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Add Vehicles</span>}
                                            name="addVehicles"
                                        >
                                            <Input
                                                placeholder="Add New Vehicles"
                                                className='textStyle-small'
                                                style={{ width: "80%", height: "35px", backgroundColor: "white" }}
                                                disabled
                                                suffix={
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <div
                                                            style={{
                                                                backgroundColor: '#1890ff',
                                                                width: '20px',
                                                                height: '20px',
                                                                borderRadius: '3px',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center'
                                                            }}
                                                            onClick={openModelForInsertDataCreateVehicles}
                                                        >
                                                            <PlusOutlined
                                                                size='small'
                                                                // onClick={handleIconClick} 
                                                                style={{ cursor: 'pointer', color: 'white' }} />
                                                        </div>
                                                    </div>
                                                }
                                            />
                                        </Form.Item>

                                        {
                                            vehicleJson?.map((item, index) => {
                                                return (
                                                    <div style={{ width: "80%", backgroundColor: "#f0f0f0", padding: "10px", borderRadius: "10px", marginTop: "10px" }}
                                                    >
                                                        <Row>
                                                            <Col span={20}>
                                                                <Row>
                                                                    <Col span={8}>
                                                                        <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "11px" }}>
                                                                            {item?.vehicleNumber ? item.vehicleNumber : "N/A"}
                                                                        </span>
                                                                    </Col>
                                                                    <Col span={16} style={{ display: "flex", flexDirection: "row" }}>
                                                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#cbc300", width: "auto", height: "20px", borderRadius: "5px", padding: "5px" }}>
                                                                            <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "10px" }}>
                                                                                <span style={{ color: "white" }}>{item?.vehicleType ? item.vehicleType : "N/A"}</span>
                                                                            </span>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col span={4} style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                                                                <div
                                                                    onClick={() => {
                                                                        setSelectedVehicle({
                                                                            arrIndex: index,
                                                                            id: item.id,
                                                                            vehicleNumber: item.vehicleNumber,
                                                                            vehicleType: item.vehicleType,
                                                                        });
                                                                        setIsUpdate(true);
                                                                        openModelForInsertDataCreateVehicles();
                                                                    }}
                                                                    style={{
                                                                        cursor: "pointer", color: "red", float: "right", width: "25px", height: "25px", borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "center", background: "#42b02a", marginRight: '2px'
                                                                    }}>
                                                                    <FormOutlined style={{ cursor: "pointer", color: "white", fontSize: "14px" }} />
                                                                </div>
                                                                <div
                                                                    onClick={() => popFromVehicleArray(index)}
                                                                    style={{
                                                                        cursor: "pointer", color: "red", float: "right", width: "25px", height: "25px",
                                                                        borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "center", background: "red"
                                                                    }}>
                                                                    <DeleteOutlined style={{ cursor: "pointer", color: "white", fontSize: "14px" }} />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                )
                                            })

                                        }
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item
                                            label={<span style={{ fontWeight: 'bold' }} className='textStyle-small'>Work Descriptions</span>}
                                            name="workDescriptions"
                                            initialValue={reqFormData?.description || ''}
                                        >
                                            <Input showCount maxLength={80}
                                                style={{ width: "80%", height: "35px" }}
                                                className='textStyle-small'
                                                placeholder='Enter Work Descriptions'
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                
                                <Spin spinning={fileUploading} size="large" tip="File Processing For Upload...">
                                <Row>
                                    <Col span={12}>
                                        <Form.Item
                                            label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Upload File One</span>}
                                            name="fileOne"
                                        >
                                            <div style={{
                                                border: '1px solid #d9d9d9', width: '80%', flexDirection: 'row', display: 'flex', height: 'auto'
                                            }}>
                                                <Upload
                                                    multiple={false}
                                                    // beforeUpload={() => false}
                                                    // onChange={(info) => {
                                                    //     try {
                                                    //         setFileInformations(prevState => ({
                                                    //             ...prevState,
                                                    //             initiatorFileOne: info.fileList[0]?.originFileObj,
                                                    //             initiatorFileOneOriginal: info.fileList[0]?.name,
                                                    //         }));
                                                    //     } catch (error) {
                                                    //         console.error(error);
                                                    //     }
                                                    // }}
                                                    {...fileOne}
                                                    style={{ width: '500px', color: '#c1c2c3' }}
                                                    showUploadList={false}
                                                >
                                                    <Button icon={<UploadOutlined />}
                                                        style={{
                                                            width: 'auto', backgroundColor: '#1890ff', borderColor: '#1890ff', color: 'white',
                                                        }}
                                                    >
                                                        <span className='textStyle-small' style={{ color: 'white' }}>
                                                            Upload File
                                                        </span>
                                                    </Button>
                                                    <span className='textStyle-small' style={{ marginLeft: '10px' }}>
                                                        {fileLibrary?.initiatorFileOneOriginal ? fileLibrary?.initiatorFileOneOriginal : "Upload Site Data File"}
                                                    </span>
                                                </Upload>
                                                {fileLibrary?.initiatorFileOneOriginal !== null ?
                                                    (
                                                        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 'auto' }}>
                                                            {
                                                                requestDetails?.initiatorFileOneOriginal !== null ? (
                                                                    <a href={baseImgURL + reqFormData?.initiatorFileOne} target="_blank" style={{ marginLeft: 'auto', marginRight: '5px' }}>
                                                                        <Button
                                                                            type='primary'
                                                                            size='small'
                                                                            style={{
                                                                                backgroundColor: '#1890ff',
                                                                                borderColor: '#1890ff',
                                                                                color: 'white',
                                                                                width: '25px',
                                                                                height: '25px',
                                                                                borderRadius: '5px',
                                                                                display: 'flex',
                                                                                justifyContent: 'center', // Added this line
                                                                                alignItems: 'center', // Added this line,
                                                                                marginTop: '4px',
                                                                                marginRight: '5px',
                                                                                marginLeft: 'auto'
                                                                            }}
                                                                        >
                                                                            <ArrowDownOutlined style={{ fontSize: '10px' }} />
                                                                        </Button>
                                                                    </a>
                                                                ) : null
                                                            }
                                                            <Button
                                                                type='primary'
                                                                size='small'
                                                                style={{
                                                                    backgroundColor: 'red',
                                                                    borderColor: 'red',
                                                                    color: 'white',
                                                                    width: '25px',
                                                                    height: '25px',
                                                                    borderRadius: '5px',
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    marginTop: '4px',
                                                                    marginLeft: fileLibrary?.initiatorFileOneOriginal !== null ? 'auto' : '0px',
                                                                    marginRight: '5px'
                                                                }}
                                                                onClick={() => {
                                                                    resetFileLibrarySlot(1);
                                                                }}
                                                            >
                                                                <DeleteOutlined style={{ fontSize: '10px' }} />
                                                            </Button>
                                                        </div>
                                                    ) : (<></>)}
                                            </div>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Upload File Two</span>}
                                            name="fileTwo"
                                        >
                                            <div style={{
                                                border: '1px solid #d9d9d9', width: '80%', flexDirection: 'row', display: 'flex', height: 'auto'
                                            }}>
                                                <Upload
                                                    multiple={false}
                                                    // beforeUpload={() => false}
                                                    // onChange={(info) => {
                                                    //     setFileInformations(prevState => ({
                                                    //         ...prevState,
                                                    //         initiatorFileTwo: info.fileList[0]?.originFileObj,
                                                    //         initiatorFileTwoOriginal: info.fileList[0]?.name,
                                                    //         isUpdateFileTwo: true
                                                    //     }));
                                                    // }}
                                                    {...fileTwo}
                                                    style={{ width: '500px', color: '#c1c2c3' }}
                                                    showUploadList={false}
                                                >
                                                    <Button icon={<UploadOutlined />}
                                                        style={{
                                                            width: 'auto', backgroundColor: '#1890ff', borderColor: '#1890ff', color: 'white',
                                                        }}
                                                    >
                                                        <span className='textStyle-small' style={{ color: 'white' }}>
                                                            Upload File
                                                        </span>
                                                    </Button>
                                                    <span className='textStyle-small' style={{ marginLeft: '10px' }}>
                                                        {fileLibrary?.initiatorFileTwoOriginal ? fileLibrary?.initiatorFileTwoOriginal : "Upload Site Data File"}
                                                    </span>
                                                </Upload>

                                                {fileLibrary?.initiatorFileTwoOriginal !== null ?
                                                    (
                                                        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 'auto' }}>
                                                            {
                                                                requestDetails?.initiatorFileTwoOriginal !== null ? (
                                                                    <a href={baseImgURL + reqFormData?.initiatorFileTwo} target="_blank" style={{ marginLeft: 'auto', marginRight: '5px' }}>
                                                                        <Button
                                                                            type='primary'
                                                                            size='small'
                                                                            style={{
                                                                                backgroundColor: '#1890ff',
                                                                                borderColor: '#1890ff',
                                                                                color: 'white',
                                                                                width: '25px',
                                                                                height: '25px',
                                                                                borderRadius: '5px',
                                                                                display: 'flex', // Added this line
                                                                                justifyContent: 'center', // Added this line
                                                                                alignItems: 'center', // Added this line,
                                                                                marginTop: '4px',
                                                                                marginRight: '5px',
                                                                                marginLeft: 'auto'
                                                                            }}
                                                                        >
                                                                            <ArrowDownOutlined style={{ fontSize: '10px' }} />
                                                                        </Button>
                                                                    </a>
                                                                ) : null
                                                            }
                                                            <Button
                                                                type='primary'
                                                                size='small'
                                                                style={{
                                                                    backgroundColor: 'red',
                                                                    borderColor: 'red',
                                                                    color: 'white',
                                                                    width: '25px',
                                                                    height: '25px',
                                                                    borderRadius: '5px',
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    marginTop: '4px',
                                                                    marginLeft: fileLibrary?.initiatorFileTwoOriginal !== null ? 'auto' : '0px',
                                                                    marginRight: '5px'
                                                                }}
                                                                onClick={() => {
                                                                    resetFileLibrarySlot(2);
                                                                }}
                                                            >
                                                                <DeleteOutlined style={{ fontSize: '10px' }} />
                                                            </Button>
                                                        </div>
                                                    ) : (<></>)}
                                            </div>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row style={{ marginTop: '10px' }}>
                                    <Col span={12}>
                                        <Form.Item
                                            label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Upload File Three</span>}
                                            name="fileThree"
                                        >
                                            <div style={{
                                                border: '1px solid #d9d9d9', width: '80%', flexDirection: 'row', display: 'flex', height: 'auto'
                                            }}>
                                                <Upload
                                                    multiple={false}
                                                    {...fileThree}
                                                    style={{ width: '500px', color: '#c1c2c3' }}
                                                    showUploadList={false}
                                                >
                                                    <Button icon={<UploadOutlined />}
                                                        style={{
                                                            width: 'auto', backgroundColor: '#1890ff', borderColor: '#1890ff', color: 'white',
                                                        }}
                                                    >
                                                        <span className='textStyle-small' style={{ color: 'white' }}>
                                                            Upload File
                                                        </span>
                                                    </Button>
                                                    <span className='textStyle-small' style={{ marginLeft: '10px' }}>
                                                        {fileLibrary?.initiatorFileThreeOriginal ? fileLibrary?.initiatorFileThreeOriginal : "Upload Site Data File"}
                                                    </span>
                                                </Upload>

                                                {fileLibrary?.initiatorFileThreeOriginal !== null ?
                                                    (
                                                        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 'auto' }}>
                                                            {
                                                                requestDetails?.initiatorFileThree !== null ? (
                                                                    <a href={baseImgURL + reqFormData?.initiatorFileThree} target="_blank" style={{ marginLeft: 'auto', marginRight: '5px' }}>
                                                                        <Button
                                                                            type='primary'
                                                                            size='small'
                                                                            style={{
                                                                                backgroundColor: '#1890ff',
                                                                                borderColor: '#1890ff',
                                                                                color: 'white',
                                                                                width: '25px',
                                                                                height: '25px',
                                                                                borderRadius: '5px',
                                                                                display: 'flex', // Added this line
                                                                                justifyContent: 'center', // Added this line
                                                                                alignItems: 'center', // Added this line,
                                                                                marginTop: '4px',
                                                                                marginRight: '5px',
                                                                                marginLeft: 'auto'
                                                                            }}
                                                                        >
                                                                            <ArrowDownOutlined style={{ fontSize: '10px' }} />
                                                                        </Button>
                                                                    </a>
                                                                ) : null
                                                            }
                                                            <Button
                                                                type='primary'
                                                                size='small'
                                                                style={{
                                                                    backgroundColor: 'red',
                                                                    borderColor: 'red',
                                                                    color: 'white',
                                                                    width: '25px',
                                                                    height: '25px',
                                                                    borderRadius: '5px',
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    marginTop: '4px',
                                                                    marginLeft: fileLibrary?.initiatorFileThreeOriginal !== null ? 'auto' : '0px',
                                                                    marginRight: '5px'
                                                                }}
                                                                onClick={() => {
                                                                    // setFileInformations(prevState => ({
                                                                    //     ...prevState,
                                                                    //     initiatorFileThree: null,
                                                                    //     initiatorFileThreeOriginal: null,
                                                                    //     isDeleteFileThree: true
                                                                    // }));
                                                                    resetFileLibrarySlot(3);
                                                                }}
                                                            >
                                                                <DeleteOutlined style={{ fontSize: '10px' }} />
                                                            </Button>
                                                        </div>
                                                    ) : (<></>)}
                                            </div>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Upload File Four</span>}
                                            name="fileFour"
                                        >
                                            <div style={{
                                                border: '1px solid #d9d9d9', width: '80%', flexDirection: 'row', display: 'flex', height: 'auto'
                                            }}>
                                                <Upload
                                                    multiple={false}
                                                    // beforeUpload={() => false}
                                                    // onChange={(info) => {
                                                    //     setFileInformations(prevState => ({
                                                    //         ...prevState,
                                                    //         initiatorFileFour: info.fileList[0]?.originFileObj,
                                                    //         initiatorFileFourOriginal: info.fileList[0]?.name,
                                                    //         isUpdateFileFour: true
                                                    //     }));
                                                    // }}
                                                    {...fileFour}
                                                    style={{ width: '500px', color: '#c1c2c3' }}
                                                    showUploadList={false}
                                                >
                                                    <Button icon={<UploadOutlined />}
                                                        style={{
                                                            width: 'auto', backgroundColor: '#1890ff', borderColor: '#1890ff', color: 'white',
                                                        }}
                                                    >
                                                        <span className='textStyle-small' style={{ color: 'white' }}>
                                                            Upload File
                                                        </span>
                                                    </Button>
                                                    <span className='textStyle-small' style={{ marginLeft: '10px' }}>
                                                        {fileLibrary?.initiatorFileFourOriginal ? fileLibrary?.initiatorFileFourOriginal : "Upload Site Data File"}
                                                    </span>
                                                </Upload>

                                                {fileLibrary?.initiatorFileFourOriginal !== null ?
                                                    (
                                                        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 'auto' }}>
                                                            {
                                                                requestDetails?.initiatorFileFour !== null ? (
                                                                    <a href={baseImgURL + reqFormData?.initiatorFileFour} target="_blank" style={{ marginLeft: 'auto', marginRight: '5px' }}>
                                                                        <Button
                                                                            type='primary'
                                                                            size='small'
                                                                            style={{
                                                                                backgroundColor: '#1890ff',
                                                                                borderColor: '#1890ff',
                                                                                color: 'white',
                                                                                width: '25px',
                                                                                height: '25px',
                                                                                borderRadius: '5px',
                                                                                display: 'flex', // Added this line
                                                                                justifyContent: 'center', // Added this line
                                                                                alignItems: 'center', // Added this line,
                                                                                marginTop: '4px',
                                                                                marginRight: '5px',
                                                                                marginLeft: 'auto'
                                                                            }}
                                                                        >
                                                                            <ArrowDownOutlined style={{ fontSize: '10px' }} />
                                                                        </Button>
                                                                    </a>
                                                                ) : null
                                                            }
                                                            <Button
                                                                type='primary'
                                                                size='small'
                                                                style={{
                                                                    backgroundColor: 'red',
                                                                    borderColor: 'red',
                                                                    color: 'white',
                                                                    width: '25px',
                                                                    height: '25px',
                                                                    borderRadius: '5px',
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    marginTop: '4px',
                                                                    marginLeft: fileLibrary?.initiatorFileFourOriginal !== null ? 'auto' : '0px',
                                                                    marginRight: '5px'
                                                                }}
                                                                onClick={() => {
                                                                    resetFileLibrarySlot(4);
                                                                }}
                                                            >
                                                                <DeleteOutlined style={{ fontSize: '10px' }} />
                                                            </Button>
                                                        </div>
                                                    ) : (<></>)}
                                            </div>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row style={{ marginTop: '10px' }}>
                                    <Col span={12}>
                                        <Form.Item
                                            label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Upload File Five</span>}
                                            name="fileFive"
                                        >
                                            <div style={{
                                                border: '1px solid #d9d9d9', width: '80%', flexDirection: 'row', display: 'flex', height: 'auto'
                                            }}>
                                                <Upload
                                                    multiple={false}
                                                    {...fileFive}
                                                    style={{ width: '500px', color: '#c1c2c3' }}
                                                    showUploadList={false}
                                                >
                                                    <Button icon={<UploadOutlined />}
                                                        style={{
                                                            width: 'auto', backgroundColor: '#1890ff', borderColor: '#1890ff', color: 'white',
                                                        }}
                                                    >
                                                        <span className='textStyle-small' style={{ color: 'white' }}>
                                                            Upload File
                                                        </span>
                                                    </Button>
                                                    <span className='textStyle-small' style={{ marginLeft: '10px' }}>
                                                        {fileLibrary?.initiatorFileFiveOriginal ? fileLibrary?.initiatorFileFiveOriginal : "Upload Site Data File"}
                                                    </span>
                                                </Upload>

                                                {fileLibrary?.initiatorFileFiveOriginal !== null ?
                                                    (
                                                        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 'auto' }}>
                                                            {
                                                                requestDetails?.initiatorFileFiveOriginal !== null ? (
                                                                    <a href={baseImgURL + reqFormData?.initiatorFileFive} target="_blank" style={{ marginLeft: 'auto', marginRight: '5px' }}>
                                                                        <Button
                                                                            type='primary'
                                                                            size='small'
                                                                            style={{
                                                                                backgroundColor: '#1890ff',
                                                                                borderColor: '#1890ff',
                                                                                color: 'white',
                                                                                width: '25px',
                                                                                height: '25px',
                                                                                borderRadius: '5px',
                                                                                display: 'flex',
                                                                                justifyContent: 'center',
                                                                                alignItems: 'center',
                                                                                marginTop: '4px',
                                                                                marginRight: '5px',
                                                                                marginLeft: 'auto'
                                                                            }}
                                                                        >
                                                                            <ArrowDownOutlined style={{ fontSize: '10px' }} />
                                                                        </Button>
                                                                    </a>
                                                                ) : null
                                                            }
                                                            <Button
                                                                type='primary'
                                                                size='small'
                                                                style={{
                                                                    backgroundColor: 'red',
                                                                    borderColor: 'red',
                                                                    color: 'white',
                                                                    width: '25px',
                                                                    height: '25px',
                                                                    borderRadius: '5px',
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    marginTop: '4px',
                                                                    marginLeft: fileLibrary?.initiatorFileFiveOriginal !== null ? 'auto' : '0px',
                                                                    marginRight: '5px'
                                                                }}
                                                                onClick={() => {
                                                                    // setFileInformations(prevState => ({
                                                                    //     ...prevState,
                                                                    //     initiatorFileFive: null,
                                                                    //     initiatorFileFiveOriginal: null,
                                                                    //     isDeleteFileFive: true
                                                                    // }));
                                                                    resetFileLibrarySlot(5);
                                                                }}
                                                            >
                                                                <DeleteOutlined style={{ fontSize: '10px' }} />
                                                            </Button>
                                                        </div>
                                                    ) : (<></>)}
                                            </div>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                </Spin>

                                <Row>
                                    <Col span={24}>
                                        <Button
                                            className='primary-button'
                                            style={{ width: '200px', float: 'right', marginRight: '10%' }}
                                            htmlType='submit'
                                        >
                                            <span className='textStyle-small'>
                                                Update Request
                                            </span>
                                        </Button>
                                        <Button
                                            className='danger-button'
                                            danger
                                            style={{ width: '200px', float: 'right', marginRight: '10px' }}
                                            onClick={() => {
                                                window.location.reload();
                                            }}
                                        >
                                            <span className='textStyle-small'>
                                                Cancel Update
                                            </span>
                                        </Button>
                                        <Button
                                            className='secondary-button'
                                            style={{ width: '100px', float: 'right', marginRight: '15px' }}
                                            onClick={() => { history.goBack() }}
                                        > <span className='textStyle-small'>Back</span>
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </Form>
                    </Spin>
                </div>

                <Modal
                    title={getTitle(formID)}
                    visible={editModelOpen}
                    onOk={() => {
                        closeModelForInsertData();
                        resetAll_Fields();
                        setIsUpdate(false);
                    }}
                    onCancel={() => {
                        closeModelForInsertData();
                        resetAll_Fields();
                        setIsUpdate(false);
                    }}
                    footer={null}
                    style={{
                        borderRadius: "20px",
                    }}
                    destroyOnClose={true}
                >
                    {
                        formID === 1 ? (

                            <Form
                                title={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Request Summary</span>}
                                labelCol={{ span: 16 }}
                                wrapperCol={{ span: 24 }}
                                style={{ paddingLeft: '20px', paddingRight: '20px' }}
                                layout="horizontal"
                                onFinish={addTeamMembers}
                                ref={formRefUS}
                            >
                                <Form.Item
                                    name="name"
                                    rules={[{ required: true, message: 'Please input your name!' }]}
                                    initialValue={selectedTeamMember?.name || ''}
                                >
                                    <Input
                                        className='textStyle-small customInput'
                                        style={{ width: "100%", height: "35px" }}
                                        placeholder="Enter Name"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="contactNumber"
                                    initialValue={selectedTeamMember?.contactNumber || ''}
                                    rules={[{ required: true, message: 'Please input your contact number!' },
                                    { pattern: /^[0-9]\d*$/, message: 'Please input valid contact number!' },
                                    { min: 10, message: 'Please input valid contact number!' }]}
                                >
                                    <Input
                                        className='textStyle-small customInput'
                                        style={{ width: "100%", height: "35px" }}
                                        placeholder="Enter Contact Number"
                                        type='number'
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="nic"
                                    initialValue={selectedTeamMember?.nic || ''}
                                    rules={[
                                        { required: true, message: 'Please input your NIC!' },
                                        {
                                            pattern: /^(?:\d{9}[vVxX]|\d{12})$/,
                                            message: 'Please input a valid NIC!'
                                        }
                                    ]}
                                >
                                    <Input
                                        className='textStyle-small customInput'
                                        style={{ width: "100%", height: "35px" }}
                                        placeholder="Enter NIC"
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="company"
                                    initialValue={selectedTeamMember?.company || ''}
                                >
                                    <Input
                                        className='textStyle-small customInput'
                                        style={{ width: "100%", height: "35px" }}
                                        placeholder="Enter Company"
                                    />
                                </Form.Item>

                                <Row style={{ float: 'right' }}>
                                    <Col span={24}>
                                        <Button
                                            className='primary-button'
                                            htmlType="submit"
                                            style={{ width: '170px' }}
                                        >
                                            <span className='textStyle-small'>
                                                {isUpdate ? 'Update Team Member ' : 'Add Team Member '}
                                            </span>
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        ) : (
                            <></>
                        )
                    }

                    {
                        formID === 4 ? (
                            <Form
                                title={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Equipment Details</span>}
                                layout="horizontal"
                                onFinish={addInstrumentsActions}
                                labelCol={{ span: 16 }}
                                wrapperCol={{ span: 24 }}
                                style={{ paddingLeft: '20px', paddingRight: '20px' }}
                                ref={formRefIN}
                            >
                                <Form.Item
                                    name="itemName"
                                    rules={[{ required: true, message: 'Please input your item name!' }]}
                                    initialValue={selectedInstrument?.itemName || ''}
                                >
                                    <Input
                                        className='textStyle-small customInput'
                                        style={{ width: "100%", height: "35px" }}
                                        placeholder="Enter Item Name"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="serialNumber"
                                    initialValue={selectedInstrument?.serialNumber || ''}
                                    rules={[{ required: true, message: 'Please input your serial number!' }]}
                                >
                                    <Input
                                        className='textStyle-small customInput'
                                        style={{ width: "100%", height: "35px" }}
                                        placeholder="Enter Serial Number"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="actionType"
                                    initialValue={selectedInstrument?.actionType || ''}
                                >
                                    <Input
                                        className='textStyle-small customInput'
                                        style={{ width: "100%", height: "35px" }}
                                        placeholder="Enter Action Type"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="description"
                                    initialValue={selectedInstrument?.description || ''}
                                >
                                    <Input.TextArea
                                        className='textStyle-small customInput'
                                        style={{ width: "100%", height: "50px" }}
                                        placeholder="Enter Description"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="quantity"
                                    initialValue={selectedInstrument?.quantity || ''}
                                    rules={[{ required: true, message: 'Please input your quantity!' }, { pattern: /^[0-9]\d*$/, message: 'Please input valid quantity!' }]}
                                >
                                    <Input
                                        className='textStyle-small customInput'
                                        style={{ width: "100%", height: "35px" }}
                                        placeholder="Enter Quantity"
                                        type='number'
                                    />
                                </Form.Item>

                                <Row style={{ float: 'right' }}>
                                    <Col span={24}>
                                        <Button
                                            className='primary-button'
                                            htmlType='submit'
                                        >
                                            <span className='textStyle-small'>
                                                {isUpdate ? 'Edit Instrument' : 'Add Instrument'}
                                            </span>
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        ) : (
                            <></>
                        )
                    }

                    {
                        formID === 5 ? (
                            <>
                                <div>
                                    <Table
                                        dataSource={siteKeyLocations}
                                        columns={keyLocationInformations} />
                                </div>
                            </>
                        ) :
                            (<> </>)
                    }

                    {/* Add Instrument */}
                    {
                        formID === 3 ? (
                            <Form
                                layout="horizontal"
                                onFinish={addEquipmentActions}
                                labelCol={{ span: 16 }}
                                wrapperCol={{ span: 24 }}
                                style={{ paddingLeft: '20px', paddingRight: '20px' }}
                                ref={formRefEQ}
                            >
                                <Form.Item
                                    name="itemName"
                                    initialValue={selectedEquipment?.itemName || ''}
                                    rules={[{ required: true, message: 'Please input your item name!' }]}
                                >
                                    <Input
                                        className='textStyle-small customInput'
                                        style={{ width: "100%", height: "35px" }}
                                        placeholder="Enter Item Name"
                                    />
                                </Form.Item>
                                <Form.Item
                                    initialValue={selectedEquipment?.serialNumber || ''}
                                    name="serialNumber"
                                    rules={[{ required: true, message: 'Please input your serial number!' }]}
                                >
                                    <Input
                                        className='textStyle-small customInput'
                                        style={{ width: "100%", height: "35px" }}
                                        placeholder="Enter Serial Number"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="actionType"
                                    initialValue={selectedEquipment?.actionType || ''}
                                >
                                    <Input
                                        className='textStyle-small customInput'
                                        style={{ width: "100%", height: "35px" }}
                                        placeholder="Enter Action Type"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="description"
                                    initialValue={selectedEquipment?.description || ''}
                                >
                                    <Input.TextArea
                                        className='textStyle-small customInput'
                                        style={{ width: "100%", height: "50px" }}
                                        placeholder="Enter Description"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="quantity"
                                    initialValue={selectedEquipment?.quantity || ''}
                                    rules={[{ required: true, message: 'Please input your quantity!' }, { pattern: /^[0-9]\d*$/, message: 'Please input valid quantity!' }]}
                                >
                                    <Input
                                        className='textStyle-small customInput'
                                        style={{ width: "100%", height: "35px" }}
                                        placeholder="Enter Quantity"
                                        type='number'
                                    />
                                </Form.Item>

                                <Row style={{ float: 'right' }}>
                                    <Col span={24}>
                                        <Button
                                            className='primary-button'
                                            htmlType='submit'
                                        >
                                            <span className='textStyle-small'>
                                                {isUpdate ? 'Edit Equipment' : 'Add Equipment'}
                                            </span>
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        ) : (
                            <> </>
                        )
                    }

                    {
                        formID === 2 ? (
                            <Form
                                title={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Vehicle Details</span>}
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 24 }}
                                layout="horizontal"
                                onFinish={addVehicleActions}
                                centered
                                style={{ paddingLeft: '20px', paddingRight: '20px' }}
                                ref={formRefVH}
                            >
                                <Form.Item
                                    name="vehicleNumber"
                                    initialValue={selectedVehicle?.vehicleNumber || ''}
                                    rules={[{ required: true, message: 'Please input your vehicle number!' }]}
                                >
                                    <Input
                                        className='textStyle-small customInput'
                                        style={{ width: "100%", height: "35px" }}
                                        placeholder="Enter Vehicle Number"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="vehicleType"
                                    initialValue={selectedVehicle?.vehicleType || ''}
                                    rules={[{ required: true, message: 'Please input your vehicle type!' }]}
                                >
                                    <Input
                                        className='textStyle-small customInput'
                                        style={{ width: "100%", height: "35px" }}
                                        placeholder="Enter Vehicle Type"
                                    />
                                </Form.Item>
                                <Row style={{ float: 'right' }}>
                                    <Col span={24}>
                                        {/* <Button
                                            className='secondary-button'
                                            onClick={() => { closeModelForInsertData() }}
                                        >
                                            <span className='textStyle-small'>Done</span>
                                        </Button> */}
                                        <Button
                                            className='primary-button'
                                            htmlType='submit'
                                        >
                                            <span className='textStyle-small'>
                                                {isUpdate ? 'Edit Vehicle' : 'Add Vehicle'}
                                            </span>
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        ) :
                            (<> </>)
                    }

                    <br></br>
                    <br></br>

                    {
                        formID === 1 && teamMemberJson?.map((item, index) => {
                            return (
                                <div style={{ width: "100%", backgroundColor: "#f0f0f0", padding: "10px", borderRadius: "10px", marginTop: "10px" }}>
                                    <Row>
                                        <Col span={22}>
                                            <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "12px" }}>
                                                {item?.name ? item.name : "N/A"}
                                            </span>
                                            <Row>
                                                <Col span={8}>
                                                    <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "10px" }}>
                                                        <PhoneOutlined style={{ fontSize: "12px", color: "#1890ff", marginRight: "5px" }} />
                                                        {item?.contactNumber ? item.contactNumber : "N/A"}
                                                    </span>
                                                </Col>
                                                <Col span={8}>
                                                    <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "10px" }}>
                                                        <UserOutlined style={{ fontSize: "12px", color: "#1890ff", marginRight: "5px" }} />
                                                        {item?.nic ? item.nic : "N/A"}
                                                    </span>
                                                </Col>
                                                <Col span={8}>
                                                    <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "10px" }}>
                                                        <BankOutlined style={{ fontSize: "12px", color: "#1890ff", marginRight: "5px" }} />
                                                        {item?.company ? item.company : "N/A"}
                                                    </span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={2} style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                                            <div
                                                onClick={() => popFromUserArray(index)}
                                                style={{
                                                    cursor: "pointer", color: "red", float: "right", width: "25px", height: "25px",
                                                    borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "center", background: "red"
                                                }}>
                                                <DeleteOutlined style={{ cursor: "pointer", color: "white", fontSize: "14px" }} />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            )
                        })
                    }

                    {
                        formID === 4 && instrumentJson?.map((item, index) => {
                            return (
                                <div style={{ width: "100%", backgroundColor: "#f0f0f0", padding: "10px", borderRadius: "10px", marginTop: "10px" }}>
                                    <Row>
                                        <Col span={22}>
                                            <Row>
                                                <Col span={8}>
                                                    <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "11px" }}>
                                                        {item?.itemName ? item.itemName : "N/A"}
                                                    </span>
                                                </Col>
                                                <Col span={16} style={{ display: "flex", flexDirection: "row" }}>
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#cbc300", width: "auto", height: "20px", borderRadius: "5px", padding: "5px" }}>
                                                        <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "10px" }}>
                                                            <span style={{ color: "white" }}>{item?.actionType ? item.actionType : "N/A"}</span>
                                                        </span>
                                                    </div>
                                                    <div style={{
                                                        display: "flex", alignItems: "center", justifyContent: "center",
                                                        backgroundColor: "#ab0eb9", width: "auto", height: "20px", borderRadius: "5px", marginLeft: "5px", padding: "5px"
                                                    }}>
                                                        <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "10px" }}>
                                                            <span style={{ color: "white" }}>{item?.quantity ? item.quantity : "N/A"}</span>
                                                        </span>
                                                    </div>
                                                </Col>
                                                <span className='textStyle-small' style={{ fontSize: "12px", marginTop: "7px" }}>
                                                    <b>{item?.serialNumber ? item.serialNumber : "N/A"}</b><br></br>
                                                    {item?.description ? item.description : "N/A"}
                                                </span>
                                            </Row>
                                        </Col>
                                        <Col span={2} style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                                            <div
                                                onClick={() => popFromInstrumentArray(index)}
                                                style={{
                                                    cursor: "pointer", color: "red", float: "right", width: "25px", height: "25px",
                                                    borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "center", background: "red"
                                                }}>
                                                <DeleteOutlined style={{ cursor: "pointer", color: "white", fontSize: "14px" }} />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            )
                        })
                    }

                    {
                        formID === 3 && equpmentJson?.map((item, index) => {
                            return (
                                <div style={{ width: "100%", backgroundColor: "#f0f0f0", padding: "10px", borderRadius: "10px", marginTop: "10px" }}>
                                    <Row>
                                        <Col span={22}>
                                            <Row>
                                                <Col span={8}>
                                                    <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "11px" }}>
                                                        {item?.itemName ? item.itemName : "N/A"}
                                                    </span>
                                                </Col>
                                                <Col span={16} style={{ display: "flex", flexDirection: "row" }}>
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#cbc300", width: "auto", height: "20px", borderRadius: "5px", padding: "5px" }}>
                                                        <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "10px" }}>
                                                            <span style={{ color: "white" }}>{item?.actionType ? item.actionType : "N/A"}</span>
                                                        </span>
                                                    </div>
                                                    <div style={{
                                                        display: "flex", alignItems: "center", justifyContent: "center",
                                                        backgroundColor: "#ab0eb9", width: "auto", height: "20px", borderRadius: "5px", marginLeft: "5px", padding: "5px"
                                                    }}>
                                                        <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "10px" }}>
                                                            <span style={{ color: "white" }}>{item?.quantity ? item.quantity : "N/A"}</span>
                                                        </span>
                                                    </div>
                                                </Col>
                                                <span className='textStyle-small' style={{ fontSize: "12px", marginTop: "7px" }}>
                                                    <b>{item?.serialNumber ? item.serialNumber : "N/A"}</b><br></br>
                                                    {item?.description ? item.description : "N/A"}
                                                </span>
                                            </Row>
                                        </Col>
                                        <Col span={2} style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                                            <div
                                                onClick={() => popFromEquipmentArray(index)}
                                                style={{
                                                    cursor: "pointer", color: "red", float: "right", width: "25px", height: "25px", borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "center", background: "red"
                                                }}>
                                                <DeleteOutlined style={{ cursor: "pointer", color: "white", fontSize: "14px" }} />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            )
                        })
                    }

                    {
                        formID === 2 && vehicleJson?.map((item, index) => {
                            return (
                                <div style={{ width: "100%", backgroundColor: "#f0f0f0", padding: "10px", borderRadius: "10px", marginTop: "10px" }}>
                                    <Row>
                                        <Col span={22}>
                                            <Row>
                                                <Col span={8}>
                                                    <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "11px" }}>
                                                        {item?.vehicleNumber ? item.vehicleNumber : "N/A"}
                                                    </span>
                                                </Col>
                                                <Col span={16} style={{ display: "flex", flexDirection: "row" }}>
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#cbc300", width: "auto", height: "20px", borderRadius: "5px", padding: "5px" }}>
                                                        <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "10px" }}>
                                                            <span style={{ color: "white" }}>{item?.vehicleType ? item.vehicleType : "N/A"}</span>
                                                        </span>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={2} style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                                            <div
                                                onClick={() => popFromVehicleArray(index)}
                                                style={{
                                                    cursor: "pointer", color: "red", float: "right", width: "25px", height: "25px",
                                                    borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "center", background: "red"
                                                }}>
                                                <DeleteOutlined style={{ cursor: "pointer", color: "white", fontSize: "14px" }} />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            )
                        })
                    }
                </Modal>

                <Modal
                    title={<span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "14px" }}>Import Summary - Team Members</span>}
                    visible={openTeamErrorLog}
                    footer={null}
                    style={{
                        borderRadius: "20px",
                    }}
                    width={800}
                >
                    <Row>
                        <Col span={24}>
                            <Table
                                size='small'
                                pagination={false}
                                dataSource={userValidation}
                                columns={teamMembersError} />
                        </Col>
                    </Row>
                    <Row align={'end'}>
                        <Button
                            type='primary'
                            style={{ borderRadius: '5px', marginTop: '10px' }}
                            danger
                            onClick={() => {
                                setOpenTeamErrorLog(false);
                            }}
                        >
                            <span className='textStyle-small'>Close And Import Again</span>
                        </Button>
                    </Row>
                </Modal>
            </div >
        </body >
    )
}

export default AccessDashboard;

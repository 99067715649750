import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import SimpleReactValidator from "simple-react-validator";
import { Collapse } from 'reactstrap';
import { getVehicleMaster, createVehicleMaster, getVehicleData, getVehicleMasterById, editVehicleMaster, getUnAsingeVehicleDetails, getUnAsingeDrivers, getFilterUser, getAllRegionList } from "../../api/index";

import { DatePicker, Table, List, Popconfirm, Avatar, Form, Tag, message, Drawer, Select, InputNumber, Input, Switch, Icon } from 'antd';

class VehicleMaster extends Component {
    formRef = React.createRef();
    constructor(props) {
        super(props)
        this.validator = new SimpleReactValidator();
        this.state = {
            isOpen: false,
            open: false,
            openPop1: false,
            openView: false,
            switchDisable:false,
            confirmLoading: false,
            selectPageNo: 0,
            selectRecodePerPage: 30,
            dataPageCount:0,
            searchVehicleReNo: '',
            vehicleDataCategory: [],
            vehicleDataOwner: [],
            vehicleDataPaymentCategory: [],
            vehicleDataType: [],
            vehicleDataSubType: [],
            vehicle_list: [],
            loading: false,
            vehicleModel: '',
            vehicleBrand: '',
            vehicleReNum: '',
            vehicleSeatingCapacity: '',
            vehiclePaymentCategory: null,
            selectedTypeId: null,
            vehicleType: [],
            selectedVehicleSubTypeId: null,
            vehicleSubType: [],
            selectedVehicleOwnerId: null,
            VehicleOwner: [],
            selectVehiclePaymentCategoryId: null,
            VehiclePayment: [],
            selectIdforUpdate: '',
            vehicleMasterActive: null,

            allVehicleMasterData: null,
            vehicleMasterCode: '',
            vehicleMasterdescription: '',
            selectedMasterRegionId: null,
            vehicleMasterRegion: [],

            selectVehicleDetailsId: null,
            vehicleDetails: [],
            selectedVehicleDetails: [],
            selectedDriverId: null,
            vehicleDriver: [],
            selectedDriverDetails: null,
            selectedUserId: null,
            vehicleUser: null,
            selectedUser: null,
            unAsingeVehicleDetailsList: [],
            unAsingeDriversList: [],
            userList: [],
            sub_region_list: [],
            selectIdForActive:null,
            selectForActiveOrNoActive:null,


        }
        this.handleVehicleModel = this.handleVehicleModel.bind(this);
        this.handleVehicleBrand = this.handleVehicleBrand.bind(this);
        this.handleVehicleMasterCode = this.handleVehicleMasterCode.bind(this);
        this.handleVehicleMasterDescription = this.handleVehicleMasterDescription.bind(this);
        this.handleSeatingCapacity = this.handleSeatingCapacity.bind(this);
        this.handleVehiclePaymentCategory = this.handleVehiclePaymentCategory.bind(this);
    }
    onSearchUser = (value) => {

        console.log(value)
        this.getDataFilterUser(value)

    }
    onChangeUser = (value) => {
        console.log(value)
        console.log('dddd')
        if(value!=="no"){
            console.log('ffff')
            this.setState({
                selectedUserId: value,
                vehicleUser: {
                    "id": value
                },
    
            })
        }else{
            this.setState({
                selectedUserId: null,
                vehicleUser:null
    
            }) 
        }
    

    }
    onChangeRegion = (value) => {
        console.log("onChangeRegion");
        //console.log(value);
        this.setState({
            selectedMasterRegionId: value,
            vehicleMasterRegion: {
                "id": value
            }

        })
    };
    onChangeVehiclePaymentCategory = (value) => {
        console.log("onChangeVehicleSubType");
        //console.log(value);
        this.setState({
            selectVehiclePaymentCategoryId: value,
            VehiclePayment: {
                "id": value
            },

        })
    };
    onChangeDrivers = (value) => {
        console.log("onChangeDrivers");
        //console.log(value);
        this.setState({
            selectedDriverId: value,
            vehicleDriver: {
                "id": value
            },

        })
    };
    onChangeVehicleDetails = (value) => {
        console.log("onChangeVehicleDetails");
        //console.log(value);
        this.setState({
            selectVehicleDetailsId: value,
            vehicleDetails: {
                "id": value
            },

        })
    };
    onChangeVehicleType = (value) => {
        console.log("onChangeVehicleType");
        //console.log(value);
        this.setState({
            selectedTypeId: value,
            vehicleType: {
                "id": value
            },

        })
    };
    handleVehiclePaymentCategory(e) {
        console.log(e.target.value)
        this.setState({ vehiclePaymentCategory: e.target.value });
    }
    handleSeatingCapacity(e) {
        console.log(e)
        this.setState({ vehicleSeatingCapacity: e });
    }
    handleVehicleMasterDescription(e) {
        console.log(e.target.value)
        this.setState({ vehicleMasterdescription: e.target.value });
    }
    handleVehicleMasterCode(e) {
        console.log(e.target.value)
        this.setState({ vehicleMasterCode: e.target.value });
    }
    handleVehicleModel(e) {
        console.log(e.target.value)
        this.setState({ vehicleModel: e.target.value });
    }
    handleVehicleBrand(e) {
        console.log(e.target.value)
        this.setState({ vehicleBrand: e.target.value });
    }
    componentDidMount() {
        this.getAllVehicleMaster();
        this.getAllVehicleData();
        this.getDataUnAsingeVehicleDetails();
        this.getDataUnAsingeDrivers();
        this.getDataAllRegionList();
        //   this.getDataFilterUser();
    }
    onChangeCreateVehicle = () => {
        console.log('sadsgf')
        this.setState({
            open: true
        })
    }
    getDataAllRegionList = async () => {

        var _sub_regionsList = [];
        var res = await getAllRegionList();
        console.log('getAllRegionList');
        console.log(res);
        if (res.success) {
            res.data.filter(allret => "1".includes(allret.isSubRegion)).map((regions) => {
                _sub_regionsList.push({ id: regions.id, name: regions.name })
            })

            this.setState({
                sub_region_list: _sub_regionsList
            })


        }

    }
    getDataFilterUser = async (value1) => {
        var data = new FormData();
        data.append('userEmail', value1);
        var res = await getFilterUser(data);
        console.log('getFilterUser');
        console.log(res);
        if (res.success) {
            this.setState({
                userList: res.data
            })
        }
    }
    getDataUnAsingeDrivers = async () => {

        var res = await getUnAsingeDrivers();
        console.log('getUnAsingeDrivers');
        console.log(res);
        if (res.success) {
            this.setState({
                unAsingeDriversList: res.data
            })
        }
    }
    getDataUnAsingeVehicleDetails = async () => {

        var res = await getUnAsingeVehicleDetails();
        console.log('getUnAsingeVehicleDetails');
        console.log(res);
        if (res.success) {
            this.setState({
                unAsingeVehicleDetailsList: res.data
            })
        }
    }
    getDataVehicleMasterById = async (id) => {
        var data = new FormData();
        data.append('id', id);
        var res = await getVehicleMasterById(data);
        console.log('getVehicleMasterById');
        console.log(res);
        if (res.success) {
            this.setState({

                selectIdforUpdate: id,
                allVehicleMasterData: res.data,
                vehicleMasterCode: res.data?.code,
                vehicleMasterdescription: res.data?.description,

                selectedMasterRegionId: res.data?.region?.id,
                vehicleMasterRegion: res.data?.region,

                selectedUserId: res.data?.user?.id,
                selectedUser: res.data?.user,
                vehicleUser: res.data?.user,

                selectVehicleDetailsId: res.data?.vehicleDetails?.id,
                vehicleDetails: res.data?.vehicleDetails,
                selectedVehicleDetails: res.data?.vehicleDetails,


                selectedDriverId: res.data?.vehicleDetails?.driver?.id,
                vehicleDriver: res.data?.vehicleDetails?.driver,
                selectedDriverDetails: res.data?.vehicleDetails?.driver,
                // vehicleModel: res.data.model,
                // vehicleBrand: res.data.brand,
                // vehicleReNum: res.data.vehicleRegistationNumber,
                // vehicleSeatingCapacity: res.data.seatingCapacity,
                // selectedTypeId: res.data.vehicleType.id,
                // vehicleType: res.data.vehicleType,
                // selectedVehicleSubTypeId: res.data.vehicleSubType.id,
                // vehicleSubType: res.data.vehicleSubType,
                // selectedVehicleOwnerId: res.data.vehicleOwner.id,
                // VehicleOwner: res.data.vehicleOwner,
                // vehicleMasterActive:res.data.active,


            })

            if (res.data?.active == false) {

                this.setState({
                    vehicleMasterActive: 0
                })

            } else {

                this.setState({
                    vehicleMasterActive: 1
                })
            }

        }
    }
    viewVehicle = (id) => {
        console.log(id)
        this.getDataVehicleMasterById(id);

        this.setState({
            openView: true,
        })
    }
    updateVehicle = (id) => {
        console.log(id)
        this.getDataVehicleMasterById(id);
        this.setState({
            openEdit: true,
        })
    }
    onDrawerCloseView = () => {
        this.setState({
            openView: false
        })
    }

    onDrawerClose = () => {
        console.log('sddsds')
        this.formRef.current.resetFields();
        this.setState({
            open: false,
            openEdit: false,

            showModal: false,
            open: false,
            vehicleModel: '',
            vehicleBrand: '',
            vehicleReNum: '',
            vehicleSeatingCapacity: '',
            vehiclePaymentCategory: null,
            selectedTypeId: null,
            vehicleType: [],
            selectedVehicleSubTypeId: null,
            vehicleSubType: [],
            selectedVehicleOwnerId: null,
            VehicleOwner: [],
            selectVehiclePaymentCategoryId: null,
            VehiclePayment: [],
            vehicleMasterActive: null,
            userList: [],
            selectedDriverDetails: null,
            selectedUser: null




        })

    }
    viewFilterClick = () => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }
    getAllVehicleData = async () => {
        var res = await getVehicleData();
        console.log('getAllVehicleData');
        console.log(res);
        if (res.success) {
            this.setState({
                vehicleDataCategory: res.data.category,
                vehicleDataOwner: res.data.owner,
                vehicleDataPaymentCategory: res.data.paymentCategory,
                vehicleDataType: res.data.type,
                vehicleDataSubType: res.data.subType,
            })
        }
    }

    getAllVehicleMaster = async () => {
        this.setState({
            loading: true,
            vehicle_list: [],
        })
        var data = new FormData();
        data.append('pageNo', this.state.selectPageNo);
        data.append('recodesPerPage', this.state.selectRecodePerPage);
        data.append('code', this.state.searchVehicleReNo);



        var res = await getVehicleMaster(data);
        console.log('getAllVehicleDetailslll')
        console.log(res)
        console.log(res.data.data)

        if (res.success) {
            this.setState({
                vehicle_list: res.data.data,
                dataPageCount:res.data.pageCount,
                loading: false
            })
        }
    }
    previousBtnClick = () => {
        if (this.state.selectPageNo > 0) {
            this.setState({
                loading: true,
                vehicle_list: [],
            })
            var aa = this.state.selectPageNo - 1
            this.setState({
                selectPageNo: aa
            }, () => {
                this.getAllVehicleMaster();
            })
        } else {
            message.success('First Page!')
        }
    }

    nextBtnClick = () => {
        console.log(this.state.vehicle_list.length)
        if (this.state.vehicle_list.length == this.state.selectRecodePerPage) {
            this.setState({
                loading: true,
                vehicle_list: [],
            })
            var aa = this.state.selectPageNo + 1
            this.setState({
                selectPageNo: aa
            }, () => {
                this.getAllVehicleMaster();
            })
        } else {
            message.warning('Last Page!')
        }



    }
    searchFormItemChange = (event) => {
        console.log(event.target.value)
        this.setState({
            searchVehicleReNo: event.target.value
        });

    }
    onChangevehicleActive = (value) => {
        //console.log("onChangeislowVoltageFunctionality");
        console.log(value);

        this.setState({
            vehicleMasterActive: parseInt(value),


        })


    };
    filterBtnClick = async () => {
        this.setState({
            loading: true,
            vehicle_list: [],
            selectPageNo:0
        }, () => {
            this.getAllVehicleMaster();
        })


    }
    clearBtnClick = () => {
        window.location.reload()
    }
    AllDataSubmitUpdateActivet = async () => {
        var dataSet;
        dataSet = {
            "id": this.state.selectIdforUpdate,

            "description": this.state.vehicleMasterdescription,
            "vehicleDetails": { "id": this.state.selectVehicleDetailsId, "driver": this.state.vehicleDriver },
            "region": this.state.vehicleMasterRegion,
            "user": this.state.vehicleUser,
            "active": this.state.selectForActiveOrNoActive
        }
        console.log(dataSet)

        var res = await editVehicleMaster(dataSet);
        console.log("editVehicleMaster");
        console.log(res);
        if (res.success) {
            console.log("awaaa")
            

            this.setState({
                showModal: false,
                open: false,
                openEdit: false,
                vehicleMasterCode: '',
                vehicleMasterdescription: '',
                selectedMasterRegionId: null,
                vehicleMasterRegion: [],

                selectVehicleDetailsId: null,
                vehicleDetails: [],
                selectedDriverId: null,
                vehicleDriver: [],
                selectedUserId: null,
                vehicleUser: null,
                userList: [],
                selectedDriverDetails: [],
                selectForActiveOrNoActive:null

            }, () => {
                this.getAllVehicleMaster();
                this.getAllVehicleData();
                this.getDataUnAsingeVehicleDetails();
                this.getDataUnAsingeDrivers();
                this.getDataAllRegionList();
            })
        }else{
            message.warning('something went wrong')
            this.getAllVehicleMaster();
                this.getAllVehicleData();
                this.getDataUnAsingeVehicleDetails();
                this.getDataUnAsingeDrivers();
                this.getDataAllRegionList();
        }
    }
    AllDataSubmitUpdate = async () => {
        var dataSet;
        dataSet = {
            "id": this.state.selectIdforUpdate,

            "description": this.state.vehicleMasterdescription,
            "vehicleDetails": { "id": this.state.selectVehicleDetailsId, "driver": this.state.vehicleDriver },
            "region": this.state.vehicleMasterRegion,
            "user": this.state.vehicleUser,
            "active": this.state.vehicleMasterActive
        }
        console.log(dataSet)

        var res = await editVehicleMaster(dataSet);
        console.log("editVehicleMaster");
        console.log(res);
        if (res.success) {
            console.log("awaaa")
            this.formRef.current.resetFields();

            this.setState({
                showModal: false,
                open: false,
                openEdit: false,
                vehicleMasterCode: '',
                vehicleMasterdescription: '',
                selectedMasterRegionId: null,
                vehicleMasterRegion: [],

                selectVehicleDetailsId: null,
                vehicleDetails: [],
                selectedDriverId: null,
                vehicleDriver: [],
                selectedUserId: null,
                vehicleUser: null,
                userList: [],
                selectedDriverDetails: [],

            }, () => {
                this.getAllVehicleMaster();
                this.getAllVehicleData();
                this.getDataUnAsingeVehicleDetails();
                this.getDataUnAsingeDrivers();
                this.getDataAllRegionList();
            })
        }
    }
    AllDataSubmit = async () => {
        var dataSet;
        dataSet = {
            "code": this.state.vehicleMasterCode,
            "description": this.state.vehicleMasterdescription,
            "vehicleDetails": { "id": this.state.selectVehicleDetailsId, "driver": this.state.vehicleDriver },
            "region": this.state.vehicleMasterRegion,
            "user": this.state.vehicleUser
        }
        console.log(dataSet)
        var res = await createVehicleMaster(dataSet);
        console.log("createVehicleMaster");
        console.log(res);
        if (res.success) {
            console.log("awaaa")
            this.formRef.current.resetFields();

            this.setState({
                showModal: false,
                open: false,
                vehicleMasterCode: '',
                vehicleMasterdescription: '',
                selectedMasterRegionId: null,
                vehicleMasterRegion: [],

                selectVehicleDetailsId: null,
                vehicleDetails: [],
                selectedDriverId: null,
                vehicleDriver: [],
                selectedUserId: null,
                vehicleUser: null,
                userList: [],
                selectedDriverDetails: [],
            }, () => {
                this.getAllVehicleMaster();
                this.getAllVehicleData();
                this.getDataUnAsingeVehicleDetails();
                this.getDataUnAsingeDrivers();
                this.getDataAllRegionList();
            })



            // const { history } = this.props;
            // history.push("/site");
        } else {
            message.warning(res?.data)
        }
    }
    submitFormTogal = async (e) => {
        this.setState({ openPop1: true,selectIdForActive:e ,switchDisable:true },()=>{
            this.getDataVehicleMasterById(e);
        })

        console.log(e)
    }
    submitForm = async (e) => {
        console.log(e)
        console.log(this.state.selectIdForActive)
        this.setState({ openPop1: false ,switchDisable:false,selectForActiveOrNoActive:e},()=>{
            this.AllDataSubmitUpdateActivet()
        })


    }
    onDrawerClose2 = (e) => {
        console.log('sss')
        this.setState({ openPop1: false,switchDisable:false },()=>{
            this.getAllVehicleMaster();
                this.getAllVehicleData();
                this.getDataUnAsingeVehicleDetails();
                this.getDataUnAsingeDrivers();
                this.getDataAllRegionList();
        })
    }

    render() {
        const { Option } = Select;

        const columns123 = [
            {
                title: "Code",
                dataIndex: "code",
                key: "code",
                // render: (item) => <span>{item}</span>,
                render: (item) => <span class="badge badge-outline-primary badge-pill">{item}</span>,
            },
           
            {
                title: "Active",

                key: "active",
                render: (item) => <>{item.active ? (
                    <>
                        <Popconfirm
                            title="Are You Sure?"
                            description="Open Popconfirm with async logic"
                            disabled={this.state.openPop1}
                            onConfirm={() => this.submitForm(false)}
                            okButtonProps={{
                                loading: this.state.confirmLoading,
                            }}
                            onCancel={this.onDrawerClose2}
                        > <Switch disabled={this.state.switchDisable} onChange={() => this.submitFormTogal(item.id)} defaultChecked checkedChildren="Yes" unCheckedChildren="No" />   </Popconfirm> </>) : (<Popconfirm
                            title="Are You Sure?"
                            description="Open Popconfirm with async logic"
                            disabled={this.state.openPop1}
                            onConfirm={() => this.submitForm(true)}
                            okButtonProps={{
                                loading: this.state.confirmLoading,
                            }}
                            onCancel={this.onDrawerClose2}
                        > <Switch disabled={this.state.switchDisable}  onChange={() => this.submitFormTogal(item.id)} checkedChildren="Yes" unCheckedChildren="No" /></Popconfirm>)}

                </>,

            },
            {
                title: "Region",
                dataIndex: "region",
                key: "region",
                render: (item) => <span>{item?.name}</span>,
            },
            {
                title: "User Name",
                dataIndex: "user",
                key: "user",
                render: (item) => <span>{item?.name}</span>,
            },
            {
                title: "User Mobile No",
                dataIndex: "user",
                key: "mobileNumber",
                render: (item) => <span>{item?.mobileNumber}</span>,
            },
            {
                title: "Pool/Fixed",
                dataIndex: "vehicleDetails",
                key: "vehicleRegistationNumber",



                render: (item) => <span>{item?.vehicleCategory?.id == 51 ? (<>
                    <Tag color="#03d87f" key={item}>
                        <span >Fixed</span>
                    </Tag>
                </>) : (<>
                    <Tag color="#6d81f5" key={item}>
                        <span >Pool</span>
                    </Tag>
                </>)}</span>,

                // render: (item) => <>
                //     <Tag color="gray" key={item}>
                //         <span >{item?.vehicleCategory?.name}</span>
                //     </Tag>

                // </>,

            },
            {
                title: "Vehicle Registation No",
                dataIndex: "vehicleDetails",
                key: "vehicleRegistationNumber",

                render: (item) => <>
                    <Tag color="gray" key={item}>
                        <span >{item?.vehicleRegistationNumber}</span>
                    </Tag>
                </>,

            },


            {
                title: 'Action',

                key: 'x',
                width: '10px',
                render: (item) => <><a href='#' data-toggle="tooltip" data-placement="top" title="View" onClick={() => this.viewVehicle(item.id)}> <i data-feather="home" class="fa fa-eye"> </i> </a> <a href='#' data-toggle="tooltip" data-placement="top" title="Update" onClick={() => this.updateVehicle(item.id)}> <i data-feather="home" class="fa fa-pen"> </i> </a></>,
            },

        ];
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="page-title-box">
                            <div className="row">
                                <div class="col">
                                    <h4 class="page-title">Vehicle Master </h4>
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="javascript:void(0);">Vehicle Master</a></li>
                                        <li class="breadcrumb-item"><a href="javascript:void(0);">List </a></li>

                                    </ol>
                                </div>
                                <div className='col-md-2' style={{ display: "inline", justifyContent: "end", textAlign: 'end' }}>



                                    <Link to="#" onClick={() => this.onChangeCreateVehicle()} type="button" style={{ marginLeft: 10 }} class="btn btn-info">Create Vehicle Master</Link>




                                </div>
                                <div className='col-md-1'>



                                    <button type="button" style={{ marginLeft: 10 }} class="btn btn-danger" onClick={() => this.viewFilterClick()}><i class="fas fa-filter"></i></button>




                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Collapse isOpen={this.state.isOpen}>
                    <div className="row">
                        <div class="card">

                            <div class="card-body bootstrap-select-1">
                                <div class="row">

                                    <div class="col-md-3">
                                        <label class="mb-3">Code</label>
                                        <input autoComplete="off" style={{ height: 40 }} type="text" value={this.state.site_name} className="form-control" name="site_name" id="site_name" placeholder="Code" onChange={(e) => this.searchFormItemChange(e)} />
                                    </div>

                                </div>
                                <div class="col-md-3" style={{ marginTop: 10 }}>
                                    <button type="button" class="btn btn-primary" onClick={() => this.filterBtnClick()}> Filter</button>
                                    <button type="button" style={{ marginLeft: 5 }} class="btn btn-dark" onClick={() => this.clearBtnClick()}>Clear Filter</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Collapse>
                <div className='row'>
                    <>
                        <Table
                            size='small'
                            fontSize={9}
                            columns={columns123}
                            bordered
                            pagination={false}
                            loading={this.state.loading}
                            dataSource={this.state.vehicle_list}
                        />
                    </>


                </div>
                <br></br>
                <div class="row">

                    <div class=" col-md-12">
                        <div class="dataTables_paginate paging_simple_numbers" id="row_callback_paginate" style={{ display: "flex", textAlign: "end", justifyContent: "end" }}>
                            <ul class="pagination">
                                <li class="paginate_button page-item previous" id="row_callback_previous">
                                    <a onClick={() => this.previousBtnClick()} aria-controls="row_callback" data-dt-idx="0" tabindex="0" class="page-link" style={{ fontSize: 15 }}>Previous</a>
                                </li>
                                <li class="paginate_button page-item ">
                                    <a aria-controls="row_callback" data-dt-idx="1" tabindex="0" class="page-link" style={{ fontSize: 15 }}>{this.state.dataPageCount} / {this.state.selectPageNo + 1}
                                    </a>
                                </li>

                                <li class="paginate_button page-item next" id="row_callback_next">
                                    <a onClick={() => this.nextBtnClick()} aria-controls="row_callback" data-dt-idx="7" tabindex="0" class="page-link" style={{ fontSize: 15 }}>Next
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {this.state.allVehicleMasterData !== null ? (<Drawer title={"Vehicle View" + " ( " + this.state.allVehicleMasterData?.code + " )"} width={"50%"} placement="right" onClose={() => this.onDrawerCloseView()} visible={this.state.openView}>
                    <div className="row" >
                        <div className="card">
                            <div className="card-header" style={{ padding: 7 }}>
                                <div className="row align-items-center">
                                    <div className="col">
                                        <h4 className="card-title">Master Details</h4>

                                    </div>
                                </div>
                            </div>
                            <div className="card-body pt-3">
                                <table class="table table-dark1 mb-0">

                                    <tbody style={{ fontSize: 13 }}>
                                        <tr>
                                            <td scope="row" >Code:</td>
                                            <td>{this.state.allVehicleMasterData?.code}</td>
                                            <td>Description:</td>
                                            <td>{this.state.allVehicleMasterData?.description}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row" >Active:</td>
                                            <td>{this.state.allVehicleMasterData?.active ? (<Tag color="#03d87f" >
                                                <span >True</span>
                                            </Tag>) : (<Tag color="#8a6d3b" >
                                                <span >False</span>
                                            </Tag>)}</td>
                                            <td>Region:</td>
                                            <td>{this.state.allVehicleMasterData?.region?.name}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row" >User Name:</td>
                                            <td>{this.state.allVehicleMasterData?.user?.name}</td>
                                            <td>User NIC:</td>
                                            <td>{this.state.allVehicleMasterData?.user?.nic}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row" >User MobileNo:</td>
                                            <td>{this.state.allVehicleMasterData?.user?.mobileNumber}</td>
                                            <td>User Email:</td>
                                            <td>{this.state.allVehicleMasterData?.user?.email}</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header" style={{ padding: 7 }}>
                                <div className="row align-items-center">
                                    <div className="col">
                                        <h4 className="card-title">Vehicle  Details</h4>

                                    </div>
                                </div>
                            </div>
                            <div className="card-body pt-3">
                                <table class="table table-dark2 mb-0">

                                    <tbody style={{ fontSize: 13 }}>
                                        <tr>
                                            <td scope="row" >Registation Number:</td>
                                            <td>  <Tag color="gray"> {this.state.allVehicleMasterData?.vehicleDetails?.vehicleRegistationNumber}</Tag></td>
                                            <td scope="row" >Active:</td>
                                            <td>{this.state.allVehicleMasterData?.vehicleDetails?.active ? (<Tag color="#03d87f" >
                                                <span >True</span>
                                            </Tag>) : (<Tag color="#8a6d3b" >
                                                <span >False</span>
                                            </Tag>)}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row" >Brand:</td>
                                            <td>{this.state.allVehicleMasterData?.vehicleDetails?.brand}</td>
                                            <td>Model:</td>
                                            <td>{this.state.allVehicleMasterData?.vehicleDetails?.model}</td>
                                        </tr>

                                        <tr>
                                            <td scope="row" >Vehicle Type:</td>
                                            <td>{this.state.allVehicleMasterData?.vehicleDetails?.vehicleType?.name}</td>
                                            <td>Vehicle Sub Type:</td>
                                            <td>{this.state.allVehicleMasterData?.vehicleDetails?.vehicleSubType?.name}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row" >Vehicle Category:</td>
                                            <td>{this.state.allVehicleMasterData?.vehicleDetails?.vehicleCategory?.name}</td>
                                            <td>Vehicle Owner:</td>
                                            <td>{this.state.allVehicleMasterData?.vehicleDetails?.vehicleOwner?.name}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row" >Seating Capacity:</td>
                                            <td>{this.state.allVehicleMasterData?.vehicleDetails?.seatingCapacity}</td>
                                            <td scope="row" >Driver Name:</td>
                                            <td>{this.state.allVehicleMasterData?.vehicleDetails?.driver?.name}</td>

                                        </tr>
                                        <tr>
                                            <td scope="row" >Driver MobileNo:</td>
                                            <td>{this.state.allVehicleMasterData?.vehicleDetails?.driver?.mobileNumber}</td>
                                            <td scope="row" >Driver Email:</td>
                                            <td>{this.state.allVehicleMasterData?.vehicleDetails?.driver?.email}</td>

                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </Drawer>) : ('')}

                <Drawer title={"New Vehicle Master"} width={"50%"} placement="right" onClose={() => this.onDrawerClose()} visible={this.state.open}>
                    <div className="row" >
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <Form
                                        ref={this.formRef}
                                    >



                                        <div className='row'>
                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Code  <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item name="Vehicle_master_code" tooltip="This is a required field"  >
                                                    <Input placeholder="code" id="Vehicle_Registation_Number" onChange={this.handleVehicleMasterCode} />
                                                    {/* <p style={{ color: "#f5325c" }}>{this.validator.message('site_name', this.state.siteName, 'required')}</p> */}
                                                </Form.Item>


                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Description  <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item name="Vehicle_master_description" tooltip="This is a required field"  >
                                                    <Input placeholder="description" id="Vehicle_Registation_description" onChange={this.handleVehicleMasterDescription} />
                                                    {/* <p style={{ color: "#f5325c" }}>{this.validator.message('site_name', this.state.siteName, 'required')}</p> */}
                                                </Form.Item>


                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Vehicle Details (Vehicle Registation No)  <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item name="Vehicle_Type" tooltip="This is a required field">
                                                    <Select
                                                        showSearch
                                                        onChange={this.onChangeVehicleDetails}

                                                        // onChange={this.onChangeTowerType}

                                                        style={{ width: "100%" }}
                                                        placeholder="Search to Select"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        {this.state.unAsingeVehicleDetailsList?.map((item, index) => {
                                                            return <Option value={item.id}>{item.vehicleRegistationNumber}</Option>;
                                                        })}



                                                    </Select>


                                                </Form.Item>

                                                {/* <p style={{ color: "#f5325c" }}>{this.validator.message('Tower Type', this.state.selectedTowerTypeId, 'required')}</p> */}


                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Driver  <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item name="Vehicle_master_drivers" tooltip="This is a required field">
                                                    <Select
                                                        showSearch
                                                        onChange={this.onChangeDrivers}

                                                        // onChange={this.onChangeTowerType}

                                                        style={{ width: "100%" }}
                                                        placeholder="Search to Select"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        {this.state.unAsingeDriversList?.map((item, index) => {
                                                            return <Option value={item.id}>{item.name}</Option>;
                                                        })}
                                                        {/* return <Option value={65}>Test</Option> */}



                                                    </Select>


                                                </Form.Item>

                                                {/* <p style={{ color: "#f5325c" }}>{this.validator.message('Tower Type', this.state.selectedTowerTypeId, 'required')}</p> */}


                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Region <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item name="Vehicle_master_region" tooltip="This is a required field">
                                                    <Select
                                                        showSearch
                                                        onChange={this.onChangeRegion}

                                                        // onChange={this.onChangeTowerType}

                                                        style={{ width: "100%" }}
                                                        placeholder="Search to Select"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        {this.state.sub_region_list?.map((item, index) => {
                                                            return <Option value={item.id}>{item.name}</Option>;
                                                        })}




                                                    </Select>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Asset', this.state.selectedGeneratorAssetId, 'required')}</p>

                                                </Form.Item>

                                                {/* <p style={{ color: "#f5325c" }}>{this.validator.message('Tower Type', this.state.selectedTowerTypeId, 'required')}</p> */}


                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Fixed User Email (If Available)
                                                </label>
                                                <Form.Item name="Vehicle_master_user" tooltip="This is a required field">
                                                    <Select
                                                        showSearch

                                                        onSearch={this.onSearchUser}
                                                        onChange={this.onChangeUser}

                                                        // onChange={this.onChangeTowerType}

                                                        style={{ width: "100%" }}
                                                        placeholder="Enter Email"
                                                        optionFilterProp="children"

                                                    >
                                                        {this.state.userList?.map((item, index) => {
                                                            return <Option value={item.id}>{item.email} ({item.name} )</Option>;
                                                        })}




                                                    </Select>


                                                </Form.Item>

                                                {/* <p style={{ color: "#f5325c" }}>{this.validator.message('Tower Type', this.state.selectedTowerTypeId, 'required')}</p> */}


                                            </div>





                                        </div>








                                    </Form>




                                    <div className='row' style={{ justifyContent: "end" }}>
                                        <div className='col-md-2' >   <button className="btn btn-primary" onClick={() => this.AllDataSubmit()} >Submit</button></div>


                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </Drawer>

                <Drawer title={"Edit Vehicle Master  "} width={"50%"} placement="right" onClose={() => this.onDrawerClose()} visible={this.state.openEdit}>
                    <div className="row" >
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <Form
                                        ref={this.formRef}
                                    >



                                        <div className='row'>
                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Code  <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item tooltip="This is a required field"  >
                                                    <Input disabled placeholder="code" id="Vehicle_Registation_Number" value={this.state.vehicleMasterCode} onChange={this.handleVehicleMasterCode} />
                                                    {/* <p style={{ color: "#f5325c" }}>{this.validator.message('site_name', this.state.siteName, 'required')}</p> */}
                                                </Form.Item>


                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Description  <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item tooltip="This is a required field"  >
                                                    <Input placeholder="description" id="Vehicle_Registation_description" value={this.state.vehicleMasterdescription} onChange={this.handleVehicleMasterDescription} />
                                                    {/* <p style={{ color: "#f5325c" }}>{this.validator.message('site_name', this.state.siteName, 'required')}</p> */}
                                                </Form.Item>


                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Vehicle Details  (Vehicle Registation No)  <x style={{ color: "red" }}>*</x>
                                                </label>
                                                {this.state.selectVehicleDetailsId != null ? (<Form.Item name="Vehicle_Type2" tooltip="This is a required field">
                                                    <Select
                                                        showSearch
                                                        onChange={this.onChangeVehicleDetails}
                                                        defaultValue={this.state.selectedVehicleDetails.vehicleRegistationNumber}

                                                        // onChange={this.onChangeTowerType}

                                                        style={{ width: "100%" }}
                                                        placeholder="Search to Select"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        return <Option value={this.state.selectedVehicleDetails.id}>{this.state.selectedVehicleDetails.vehicleRegistationNumber}</Option>;
                                                        {this.state.unAsingeVehicleDetailsList?.map((item, index) => {
                                                            return <Option value={item.id}>{item.vehicleRegistationNumber}</Option>;
                                                        })}



                                                    </Select>


                                                </Form.Item>) : ('')}

                                                {/* <p style={{ color: "#f5325c" }}>{this.validator.message('Tower Type', this.state.selectedTowerTypeId, 'required')}</p> */}


                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Drivers  <x style={{ color: "red" }}>*</x>
                                                </label>
                                                {this.state.selectedDriverDetails != null ? (<Form.Item name="Vehicle_master_drivers2" tooltip="This is a required field">
                                                    <Select
                                                        showSearch
                                                        onChange={this.onChangeDrivers}
                                                        defaultValue={this.state.selectedDriverDetails?.id}

                                                        // onChange={this.onChangeTowerType}

                                                        style={{ width: "100%" }}
                                                        placeholder="Search to Select"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        return <Option value={this.state.selectedDriverDetails?.id}>{this.state.selectedDriverDetails?.name}</Option>;

                                                        {this.state.unAsingeDriversList?.map((item, index) => {
                                                            return <Option value={item.id}>{item.name}</Option>;
                                                        })}
                                                        {/* return <Option value={65}>Test</Option> */}



                                                    </Select>


                                                </Form.Item>) : (<Form.Item name="Vehicle_master_drivers" tooltip="This is a required field">
                                                    <Select
                                                        showSearch
                                                        onChange={this.onChangeDrivers}

                                                        // onChange={this.onChangeTowerType}

                                                        style={{ width: "100%" }}
                                                        placeholder="Search to Select"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        {this.state.unAsingeDriversList?.map((item, index) => {
                                                            return <Option value={item.id}>{item.name}</Option>;
                                                        })}
                                                        {/* return <Option value={65}>Test</Option> */}



                                                    </Select>


                                                </Form.Item>)}

                                                {/* <p style={{ color: "#f5325c" }}>{this.validator.message('Tower Type', this.state.selectedTowerTypeId, 'required')}</p> */}


                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Region <x style={{ color: "red" }}>*</x>
                                                </label>
                                                {this.state.selectedMasterRegionId != null ? (<Form.Item name="Vehicle_master_region" tooltip="This is a required field">
                                                    <Select
                                                        showSearch
                                                        onChange={this.onChangeRegion}
                                                        defaultValue={this.state.selectedMasterRegionId}

                                                        // onChange={this.onChangeTowerType}

                                                        style={{ width: "100%" }}
                                                        placeholder="Search to Select"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        {this.state.sub_region_list?.map((item, index) => {
                                                            return <Option value={item.id}>{item.name}</Option>;
                                                        })}




                                                    </Select>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Asset', this.state.selectedGeneratorAssetId, 'required')}</p>

                                                </Form.Item>) : ('')}

                                                {/* <p style={{ color: "#f5325c" }}>{this.validator.message('Tower Type', this.state.selectedTowerTypeId, 'required')}</p> */}


                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Fixed User Email (If Available)
                                                </label>
                                                {this.state.selectedUser != null ? (<Form.Item name="Vehicle_master_use12r" tooltip="This is a required field">
                                                    <Select
                                                        showSearch

                                                        onSearch={this.onSearchUser}
                                                        onChange={this.onChangeUser}


                                                        // onChange={this.onChangeTowerType}

                                                        style={{ width: "100%" }}
                                                        placeholder="Enter Email"
                                                        optionFilterProp="children"
                                                        defaultValue={this.state.selectedUser?.id}

                                                    >
                                                        return <Option value='no'> </Option> <Option value={this.state.selectedUser?.id}> {this.state.selectedUser?.email} ({this.state.selectedUser?.name}) </Option>;
                                                        {this.state.userList?.map((item, index) => {
                                                            return <Option value={item.id}> {item.email}  ({item.name}) </Option>;
                                                        })}




                                                    </Select>


                                                </Form.Item>) : (<Form.Item name="Vehicle_master_user" tooltip="This is a required field">
                                                    <Select
                                                        showSearch

                                                        onSearch={this.onSearchUser}
                                                        onChange={this.onChangeUser}

                                                        // onChange={this.onChangeTowerType}

                                                        style={{ width: "100%" }}
                                                        placeholder="Enter Email"
                                                        optionFilterProp="children"

                                                    >
                                                        {this.state.userList?.map((item, index) => {
                                                            return <Option value={item.id}> {item.email} ({item.name})</Option>;
                                                        })}




                                                    </Select>


                                                </Form.Item>)}

                                                {/* <p style={{ color: "#f5325c" }}>{this.validator.message('Tower Type', this.state.selectedTowerTypeId, 'required')}</p> */}


                                            </div>


                                            {/* <div className='col-md-6'>
                                                <label className="form-label" for="depot">
                                                    Vehicle Master Active
                                                </label>

                                                {this.state.vehicleMasterActive != null ? (
                                                    <Form.Item >
                                                        <Select
                                                            showSearch
                                                            onChange={this.onChangevehicleActive}
                                                            defaultValue={(this.state.vehicleMasterActive).toString()}
                                                            //  defaultValue={this.state.isSiteKeyRequired}

                                                            style={{ width: "100%" }}
                                                            placeholder="Search to Select"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            filterSort={(optionA, optionB) =>
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                            }
                                                        >
                                                            <Option value='1'>Yes</Option>
                                                            <Option value='0'>No</Option>

                                                        </Select>
                                                    </Form.Item>) : ('')}
                                            </div> */}





                                        </div>








                                    </Form>




                                    <div className='row' style={{ justifyContent: "end" }}>
                                        <div className='col-md-2' >   <button className="btn btn-primary" onClick={() => this.AllDataSubmitUpdate()} >Submit</button></div>


                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </Drawer>

            </div>
        )
    }
}
export default VehicleMaster

import React, { Component } from 'react'
import StackedBarChart from '../../../components/charts/stacked-bar'
import SimpleDonutChart from '../../../components/charts/simple-donut-chart'
import { Select, DatePicker, Space, Badge, Alert, Button, Row, Col } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import moment from 'moment';
import { getMonthlyWiseStatusCount } from "../../../api/index";

import { getDonationCategories, getData } from "../../../actions/index";
import { connect } from "react-redux";

import { PullRequestOutlined, SettingOutlined, DeleteOutlined, CloseOutlined, BarsOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { ButtonToolbar } from 'reactstrap';
const { RangePicker } = DatePicker;

class DashboardMOM extends Component {

    constructor(props) {
        super(props);
        const currentDate_landing = new Date();
        this.state = {

            SubRegion_list: [],
            getAllData: [],
            depot_list: [],
            filter_date: currentDate_landing.getFullYear(),
            filter_date_to: currentDate_landing.getMonth() + 1,
            filter_depot: [],
            filter_region: [],
            filter_sub_region: [],
            installed_count: 0,
            requested_count: 0,
            pending_count: 0,
            cancelled_count: 0,

            momCompletionChart: false,
            momCompletionChartSetY1: null,
            momCompletionChartSetX1: null,

            momCompletionChartCivil: false,
            momCompletionChartSetY1Civil: null,
            momCompletionChartSetX1Civil: null,

            momCompletionChartGen: false,
            momCompletionChartSetY1Gen: null,
            momCompletionChartSetX1Gen: null,

            momCompletionChartSC: false,
            momCompletionChartSetY1SC: null,
            momCompletionChartSetX1SC: null,

        }
    }

    getLastDayOfMonth(inputDate) {
        const [year, month] = inputDate.split('-');
        const nextMonth = new Date(year, month);
        nextMonth.setDate(0);
        const lastDay = nextMonth.getDate();
        return `${year}-${month}-${lastDay.toString().padStart(2, '0')}`;
    }

    componentDidMount() {
        this.props.getData();
        var data = new FormData();
        data.append('year', this.state.filter_date);
        data.append('month', this.state.filter_date_to);

        this.getMonthlyWiseStatusCountForac(data);
        this.getMonthlyWiseStatusCountForCivil(data);
        this.getMonthlyWiseStatusCountForGen(data);
        this.getMonthlyWiseStatusCountForSC(data);
    }

    getMonthlyWiseStatusCountForac = async (data2) => {
        try {
            this.setState({
                momCompletionChart: false
            })
            const res = await getMonthlyWiseStatusCount("AC", data2);
            const data = res.data;
            if (data != null) {
                const sortedData = data.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
                const months = sortedData.map(entry => entry.month.substring(0, 3));
                const completedCount = sortedData.map(entry => entry.completedCount);
                const pendingCount = sortedData.map(entry => entry.pendingCount);

                this.setState({
                    momCompletionChartSetY1: [
                        {
                            name: 'Completed',
                            type: 'column',
                            data: completedCount,
                        },
                        {
                            name: 'Pending',
                            type: 'column',
                            data: pendingCount,
                        },
                    ],
                    momCompletionChartSetX1: months,
                    momCompletionChart: true,
                });
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    getMonthlyWiseStatusCountForCivil = async (data2) => {
        try {
            this.setState({
                momCompletionChartCivil: false,
            })
            const res = await getMonthlyWiseStatusCount("CR",data2);
            const data = res.data;
            if (data != null) {
                const sortedData = data.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
                const months = sortedData.map(entry => entry.month.substring(0, 3));
                const completedCount = sortedData.map(entry => entry.completedCount);
                const pendingCount = sortedData.map(entry => entry.pendingCount);
                this.setState({
                    momCompletionChartSetY1Civil: [
                        {
                            name: 'Completed',
                            type: 'column',
                            data: completedCount,
                        },
                        {
                            name: 'Pending',
                            type: 'column',
                            data: pendingCount,
                        },
                    ],
                    momCompletionChartSetX1Civil: months,
                    momCompletionChartCivil: true,
                });
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    getMonthlyWiseStatusCountForGen = async (data2) => {
        try {
        this.setState({
            momCompletionChartGen: false
        })
            const res = await getMonthlyWiseStatusCount("GEN",data2);
            const data = res.data;
            if (data != null) {
                const sortedData = data.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
                const months = sortedData.map(entry => entry.month.substring(0, 3)); // Abbreviate month names
                const completedCount = sortedData.map(entry => entry.completedCount);
                const pendingCount = sortedData.map(entry => entry.pendingCount);

                this.setState({
                    momCompletionChartSetY1Gen: [
                        {
                            name: 'Completed',
                            type: 'column',
                            data: completedCount,
                        },
                        {
                            name: 'Pending',
                            type: 'column',
                            data: pendingCount,
                        },
                    ],
                    momCompletionChartSetX1Gen: months,
                    momCompletionChartGen: true,
                });
                console.log("Months List Here:", this.state.momCompletionChartSetX1);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    getMonthlyWiseStatusCountForSC = async (data2) => {
        try {
            this.setState({
                momCompletionChartSC: false,
            })
            const res = await getMonthlyWiseStatusCount("SC",data2);
            const data = res.data;
            if (data) {
                const sortedData = data.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
                const months = sortedData.map(entry => entry.month.substring(0, 3));
                const completedCount = sortedData.map(entry => entry.completedCount);
                const pendingCount = sortedData.map(entry => entry.pendingCount);
                this.setState({
                    momCompletionChartSetY1SC: [
                        { name: 'Completed', type: 'column', data: completedCount },
                        { name: 'Pending', type: 'column', data: pendingCount },
                    ],
                    momCompletionChartSetX1SC: months,
                    momCompletionChartSC: true,
                });
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    stripYearFromDates(dates) {
        return dates.map(date => date.slice(8));
    }

    // fUNCTION fOR dASHBOARD cOUNTS DATE ON CHANGED
    dataonChange = (date, dateString) => {

        const [yearValue, monthValue] = dateString.split('-');
        console.log(monthValue)
        console.log(yearValue)
        this.setState({
            filter_date: yearValue,
            filter_date_to: monthValue,
        }, () => {
            var data1 = new FormData();
            data1.append('year', this.state.filter_date);
            data1.append('month', this.state.filter_date_to);
            
            this.getMonthlyWiseStatusCountForac(data1);
            this.getMonthlyWiseStatusCountForCivil(data1);
            this.getMonthlyWiseStatusCountForGen(data1);
            this.getMonthlyWiseStatusCountForSC(data1);
        });
    };

    render() {
        const currentDate = new Date(this.state.filter_date + "-" + this.state.filter_date_to);
        
        return (
            <div class="container-fluid" style={{
                fontFamily: 'Roboto',
            }}>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="page-title-box">
                            <div class="row">
                                <div class="col">
                                    <h4 class="page-title">PIS-Month Of Month</h4>
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="javascript:void(0);">Dashboard</a></li>
                                        <li class="breadcrumb-item"><a href="javascript:void(0);">PIS-Month Of Month</a></li>

                                    </ol>
                                </div>
                                <div class="col-auto align-self-center">
                                    <a href="#" class="btn btn-sm btn-outline-primary" id="Dash_Date">
                                        <span class="day-name" id="Day_Name">Today:</span>&nbsp;
                                        <span class="" id="Select_date">{new Date().toUTCString().slice(5, 16)}</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar align-self-center icon-xs ms-1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                                    </a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div class="col-md-12" style={{
                        background: '#fafafa', padding: '20px', borderRadius: '10px'
                    }}>
                        <div className="row">
                            <div className="col-md-2 col-lg-3">
                                <DatePicker
                                    picker="month"
                                    className='customDropdown textStyles-small'
                                    style={{ width: "100%", borderRadius: "5px" }}
                                    allowClear={false}
                                    defaultValue={moment(moment().format("YYYY-MM-DD").slice(0, -2) + '01')}
                                    size='medium'
                                    onChange={this.dataonChange}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 col-lg-6">
                            <div className="card">
                                <div className="card-header" style={{ backgroundColor: '#fafafa' }}>
                                    <h4 className="card-title" style={{
                                        fontWeight: "bold", fontFamily: 'Roboto'
                                    }}>MOM Completion AC ({this.state.filter_date})</h4>
                                </div>
                                <div className="card-body">
                                    {this.state.momCompletionChart ? (
                                        <StackedBarChart
                                            y1={this.state.momCompletionChartSetY1}
                                            x1={this.state.momCompletionChartSetX1}
                                            yLabale={'Total'}
                                            barColors={['#42B02A', '#FFB200']}
                                            horizontalStatus={false}
                                        />) : ('1')}
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 col-lg-6">
                            <div className="card">
                                <div className="card-header" style={{ backgroundColor: '#fafafa' }}>
                                    <h4 className="card-title" style={{
                                        fontWeight: "bold", fontFamily: 'Roboto'
                                    }}>MOM Completion Gen ({this.state.filter_date})</h4>
                                </div>
                                <div className="card-body">
                                    {this.state.momCompletionChartGen ? (
                                        <StackedBarChart
                                            y1={this.state.momCompletionChartSetY1Gen}
                                            x1={this.state.momCompletionChartSetX1Gen}
                                            yLabale={'Total'}
                                            barColors={['#42B02A', '#FFB200']}
                                            horizontalStatus={false}
                                        />) : ('1')}
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 col-lg-6">
                            <div className="card">
                                <div className="card-header" style={{ backgroundColor: '#fafafa' }}>
                                    <h4 className="card-title" style={{
                                        fontWeight: "bold", fontFamily: 'Roboto'
                                    }}>MOM Completion Civil ({this.state.filter_date})</h4>
                                </div>
                                <div className="card-body">
                                    {this.state.momCompletionChartCivil ? (
                                        <StackedBarChart
                                            y1={this.state.momCompletionChartSetY1Civil}
                                            x1={this.state.momCompletionChartSetX1Civil}
                                            yLabale={'Total'}
                                            barColors={['#42B02A', '#FFB200']}
                                            horizontalStatus={false}
                                        />) : ('1')}
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 col-lg-6">
                            <div className="card">
                                <div className="card-header" style={{ backgroundColor: '#fafafa' }}>
                                    <h4 className="card-title" style={{
                                        fontWeight: "bold", fontFamily: 'Roboto'
                                    }}>MOM Completion Site Cleaning ({this.state.filter_date})</h4>
                                </div>
                                <div className="card-body">
                                    {this.state.momCompletionChartSC ? (
                                        <StackedBarChart
                                            y1={this.state.momCompletionChartSetY1SC}
                                            x1={this.state.momCompletionChartSetX1SC}
                                            yLabale={'Total'}
                                            barColors={['#42B02A', '#FFB200']}
                                            horizontalStatus={false}
                                        />) : ('1')}
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        organization_count: state.organization.length,
        regions: state.regions,
        depots: state.depots,
        sites_count: state.sites.length,
        vendor_count: state.vendor.length,
        roles_count: state.roles.length,
    };
}

export default connect(mapStateToProps, { getDonationCategories, getData })(
    DashboardMOM
);


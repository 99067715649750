import axios from "axios";
import { baseUrl, headers as defaultHeaders } from "./api.config";
import { getUserRolesAndPermission2 } from "./index"

export const get = async function (api, headersExt) {
  console.log("Calling -" + baseUrl + api);
  return await axios
    .get(baseUrl + api, { headers: { ...defaultHeaders, ...headersExt } })

    .then((res) => {
      // //console.log(...defaultHeaders,...headersExt)
      //console.log(res);
      if (res.status === 200 || res.status === 201) {
        return res;
      }
    })
    .catch(function (error) {
      if (error.response.status == 500  ) {
        return error.response;
      }
  
        if (error.response.status == 400  ) {
          return error.response;
        }

        if (error.response.status == 401  ) {
          return error.response;
        }
  
        if (error.response.status == 409) {
          return error.response;
        }
  
        if (error.response.status == 403 ) {
          return error.response;
        }
    });
};

export const get2 = async function (api, headersExt) {
  console.log("Calling -" + baseUrl + api);
  return await axios
    .get(baseUrl + api, { headers: { ...defaultHeaders, ...headersExt }, responseType: 'blob' },)

    .then((res) => {
      // //console.log(...defaultHeaders,...headersExt)
      //console.log(res);
      if (res.status == 200) {
        return res;
      }
    })
    .catch(function (error) {


      if (error.response) {
        if (error.response.status == 401) {


          return getRef(api, headersExt)



          //console.log("Get Error")

        }
        //console.log(error.response);



      }
    });
};

export const getRef = async function (api, headersExt) {
  console.log("CallingGetRef -" + baseUrl + api);
  //console.log(localStorage.getItem("userId"));


  var newHeaders = {
    "Content-Type": "application/json", "cache-control": "no-cache",
    "Authorization": "Bearer " + localStorage.getItem("access_token") + "",
    "logged-user-email": localStorage.getItem("logged_user_email"),
    "userId": localStorage.getItem("userId")
  };
  //console.log("newHeaders");

  return await axios
    .get(baseUrl + api, {
      headers: {
        ...{
          "Content-Type": "application/json", "cache-control": "no-cache",
          "Authorization": "Bearer " + localStorage.getItem("access_token") + "",
          "logged-user-email": localStorage.getItem("logged_user_email"),
          "userId": localStorage.getItem("userId")
        }, ...headersExt
      }
    })

    .then((res) => {



      //console.log(res);
      if (res.status == 200) {
        return res;
      }
    })
    .catch(function (error) {


      if (error.response) {

        //console.log(error.response);



      }
    });
};


export const post = function (api, data, headersExt, proprties) {
  console.log("Calling rrrrr-" + baseUrl + api);
  return axios
    .post(baseUrl + api, data, { headers: { ...defaultHeaders, ...headersExt }, ...proprties })
    .then((res) => {
      // //console.log(...defaultHeaders,...headersExt);

      if (res.status == 201 || res.status == 200  ) {
        return res;
      }
    })
    .catch((error) => {
    
     if (error.response.status == 500  ) {
      return error.response;
    }

      if (error.response.status == 400  ) {
        return error.response;
      }

      if (error.response.status == 409) {
        return error.response;
      }

      if (error.response.status == 403 ) {
        return error.response;
      }

      if (error.response.status == 401 ) {
        return error.response;
      }
  
      // if (error.response) {
      //   if (error.response.status == 401) {
      //     return postRef(api, data, headersExt, proprties)

      //   }
      // }
    });
};

export const put = function (api, data, headersExt, proprties) {
  console.log("Calling rrrrr-" + baseUrl + api);
  return axios
    .put(baseUrl + api, data, { headers: { ...defaultHeaders, ...headersExt }, ...proprties })
    .then((res) => {
      // //console.log(...defaultHeaders,...headersExt);
      //console.log(res);
      if (res.status == 201 || res.status == 200  ) {
        return res;
      }
    })
    .catch((error) => {
    
     if (error.response.status == 500  ) {
      return error.response;
    }

      if (error.response.status == 400) {
        return error.response;
      }

      
      if (error.response.status == 405) {
        return error.response;
      }

      if (error.response.status == 409) {
        return error.response;
      }
  
      if (error.response) {
        if (error.response.status == 401) {
          return postRef(api, data, headersExt, proprties)

        }
      }
    });
  };

export const delete1 = function (api, data, headersExt, proprties) {
  console.log("Calling rrrrr-" + baseUrl + api);
  return axios
    .delete(baseUrl + api, data, { headers: { ...{
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
    },...defaultHeaders, ...headersExt }, ...proprties })
    .then((res) => {
      // //console.log(...defaultHeaders,...headersExt);
    
      if (res.status == 201 || res.status == 200  ) {
        return res;
      }
    })
    .catch((error) => {
     // console.log(error.response)

      if (error.response.status == 400  ) {
        return error.response;
      }
   
      if (error.response) {
        if (error.response.status == 401) {

          return postRef(api, data, headersExt, proprties)

        }

        //console.log(error.response);


      }
    });
};


export const postRef = function (api, data, headersExt, proprties) {
  console.log("CallingPostRef -" + baseUrl + api);



  return axios

    .post(baseUrl + api, data, {
      headers: {
        ...{
          "Content-Type": "application/json", "cache-control": "no-cache",
          "Authorization": "Bearer " + localStorage.getItem("access_token") + "",
          "logged-user-email": localStorage.getItem("logged_user_email"),
          "userId": localStorage.getItem("userId")
        }, ...headersExt
      }, ...proprties
    })
    .then((res) => {
      // //console.log(...defaultHeaders,...headersExt);
      //console.log(res);
      if (res.status == 201 || res.status == 200) {
        return res;
      }
    })
    .catch((error) => {


      //console.log(error);
      if (error.response) {
        if (error.response.status == 401) {

          //console.log("Post Error")

        }

        //console.log(error.response);


      }
    });
};

export const getAll = async function (api) {
  var data = {};
  await axios.all(api.map((endpoint) => get(endpoint))).then(

    axios.spread((...allData) => {

      console.log("all array data")
      console.log(allData)
      data = {
        status: 200,
        roleList: allData[0].data,
        organizationList: allData[1].data,
       // userList: allData[2].data,
        regionList: allData[2].data,
        depotList: allData[3].data,
      };
    })

  );
  return data;
};

export let globaAbortController = new AbortController();
import React, { Component } from "react";
import { Table, Input, Tag, Space } from "antd";
import ReactExport from "react-data-export";


import { getRegionWiseVendorTeam } from "../../api/index";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class RegionWiseVendorTeams extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      filterTable: null,
      data_list: [],
    };
  }

  componentDidMount() {
    //console.log(this.props.details);
    this.getData();
  }
  getData = async () => {
    var res = await getRegionWiseVendorTeam();
    //console.log(res.data);
    if (res.success) {
      this.setState({
        data_list: res.data,
      });
    }
  };

  render() {
    const { filterTable } = this.state;
    const dataSet1 = this.state.data_list;
    const columns = [
      {
        title: "Region",
        dataIndex: "region",
        key: "region",
        render: (item) => <span>{item}</span>,
      },
      {
        title: "Vendor Team",
        dataIndex: "vendorTeam",
        key: "vendorTeam",
        render: (item) => <span>{item}</span>,
      },
    ];
    const timestamp = Date.now();
    return (
      <div className="row">
        <div className="col-lg-12 col-xl-12">
          <div className="card">
            <div className="card-header">
              <div className="row ">
                <div className="col">
                  <h4 className="card-title">
                    Report - Region wise vendor teams{" "}
                    <ExcelFile
                      filename={"Region wise vendor teams -" + timestamp}
                      element={
                        <button
                          class="btn btn-primary"
                          style={{ float: "right" }}
                        >
                          <i class="fas fa-file-export"> </i> Export
                        </button>
                      }
                    >
                      <ExcelSheet data={dataSet1} name="Region wise vendor teams">
                       
                        <ExcelColumn label="Region" value="region" />
                        <ExcelColumn label="Vendor Team" value="vendorTeam" />
                      </ExcelSheet>
                    </ExcelFile>
                  </h4>
                  <div></div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <Table
                size="small"
                columns={columns}
                // rowKey="id"
                dataSource={this.state.data_list}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RegionWiseVendorTeams;

import React, { Component } from 'react'; 
import SimpleReactValidator from 'simple-react-validator';
import {createNewSlaTerm , getAllSlaTerms} from '../../api/index';
import {addVendor} from '../../actions/index';
import { connect } from "react-redux"; 
import { ToastContainer, toast } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';
 
 class VendorSlaTerm extends Component {

	constructor(props) {
		super(props) 
		this.validator = new SimpleReactValidator();
		this.state ={
			panelty: "",
			slaTime: "",
			minutes : "",
			hours : "",
			contract: null,
			serviceType: null,
			site: null,
			vendorSlaUom: null,
			organization:"",
			SlaTerms : []
		}
	}

	componentDidMount(){  
		 this.getAllSlaTerms();
	}

	getAllSlaTerms = async() =>{
		
		var res = await getAllSlaTerms();
		//console.log(res)
		if(res.success){
			this.setState({
				SlaTerms : res.data
			})
		} 
	}

	createSlaTerm = async() =>{
		if (this.validator.allValid()) {
			 var data = {
				"panelty": this.state.panelty,
				"slaTime": this.state.minutes + ":" + this.state.hours,
				// "contract": {"id": this.state.contract},
				// "serviceType": {"id": this.state.serviceType},
				// "site": {"id": this.state.site},
				// "vendorSlaUom": {"id": this.state.vendorSlaUom}
				"contract": {"id": 1},
				"serviceType": {"id": 1},
				"site": {"id": 3},
				"vendorSlaUom": {"id": 1}
			}

			//console.log(data)

			var res = await createNewSlaTerm(data);
		 
			 if(res.success){
				toast.success("Successfully Created")
				this.getAllSlaTerms();
				window.location.reload();
			} 
		  } else {
			this.validator.showMessages();
			// rerender to show messages for the first time
			// you can use the autoForceUpdate option to do this automatically`
			this.forceUpdate();
		  }
	}

	formItemChange = (event) =>{ 
        var obj = {};
		obj[event.target.name] = event.target.value; 
		this.setState(obj);  
	 }

	 
	

	 
 
	render(){ 
		return (
			<div>  
				
			<div >
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Create SLA Term</h4>
                                    {/* <p className="text-muted mb-0">Basic example to demonstrate Bootstrap’s form styles.</p>  */}
                                </div> 
                                <div className="card-body">
                                    <form>
										<div className="row">
										<div className="col-lg-4">
										<label className="form-label" for="name">Panelty</label>
										<input autoComplete="off"  type="text" className="form-control" name="panelty" value={this.state.panelty} aria-describedby="emailHelp"   onChange={(e)=> this.formItemChange(e)}/>
										<p style={{color:"#f5325c"}}>{this.validator.message('panelty', this.state.panelty, 'required')}</p>
									</div>
									<div className="col-lg-4">
										<label className="form-label" for="description">SLA Time</label> 
										<div class="row">
										<div class="col-md-4">
											<div class="mb-3">
													<input type="number" class="form-control" id="hours" required="" name="hours" placeholder="Hours"  onChange={(e)=> this.formItemChange(e)} />
											</div>
										</div>
										<div class="col-md-4">
											<div class="mb-3">
												<input type="number" class="form-control" id="minutes" required=""  name="minutes" placeholder="minutes"  onChange={(e)=> this.formItemChange(e)}/>
											</div>
										</div> 
									</div>
										{/* <p style={{color:"#f5325c"}}>{this.validator.message('slaTime', this.state.slaTime, 'required')}</p> */}
									</div>
									  <div className="col-lg-4">
										<label className="form-label" for="description">Code</label>
										<input autoComplete="off"  type="text" value={this.state.code} className="form-control" name="code"   onChange={(e)=> this.formItemChange(e)}/>
										<p style={{color:"#f5325c"}}>{this.validator.message('code', this.state.code, 'required')}</p>
									</div>	   
									</div>
									<div className="row">
									<div className="col-lg-4">
										<label className="form-label" for="exampleInputPassword1">Contract</label>
											<div className="col-sm-12">  
												<select className="select2 form-control mb-3 custom-select" name="contract" value={this.state.contract}  onChange={(e)=> this.formItemChange(e)} style={{width: '100%' ,height:'36px'}}>
												<option value="1">Contract 01</option>
												<option value="2">Contract 02</option>
													{/* { this.props.vendors.map((vendor , index)=>{
															return <option>{vendor.name}</option>
													}) } */}
												</select>
												{/* <p style={{color:"#f5325c"}}>{this.validator.message('contract', this.state.contract, 'required')}</p> */}
											</div> 
										</div> 
										<div className="col-lg-4">
											<label className="form-label" for="exampleInputPassword1">Service Type</label>
											<div className="col-sm-12">  
												<select className="select2 form-control mb-3 custom-select" name="serviceType"  onChange={(e)=> this.formItemChange(e)} style={{width: '100%' ,height:'36px'}}>
													<option value="1">Service Type 01</option>
													<option value="2">Service Type 02</option>
													{/* { this.props.vendors.map((vendor , index)=>{
															return <option>{vendor.name}</option>
													}) } */}
												</select>
												<p style={{color:"#f5325c"}}>{this.validator.message('serviceType', this.state.serviceType, 'required')}</p>
											</div> 
										</div> 
										<div className="col-lg-4">
											<label className="form-label" for="exampleInputPassword1">Site</label>
											<div className="col-sm-12">  
												<select className="select2 form-control mb-3 custom-select" name="site"  onChange={(e)=> this.formItemChange(e)} style={{width: '100%' ,height:'36px'}}>
													{ this.props.vendors.map((vendor , index)=>{
															return <option value={vendor.id}>{vendor.name}</option>
													}) }
												</select>
												<p style={{color:"#f5325c"}}>{this.validator.message('site', this.state.site, 'required')}</p>
											</div> 
											</div>   
											
										</div>
                                         <div className="row">
										 <div className="col-lg-4">
                                            <label className="form-label">Vendor SLA Uom</label>
                                            <div className="col-sm-12">  
												<select className="select2 form-control mb-3 custom-select" name="vendorSlaUom"  onChange={(e)=> this.formItemChange(e)} style={{width: '100%' ,height:'36px'}}>
												<option value="1">Sla Uom 01</option>
												<option value="2">Sla Uom 02</option>
													{/* { this.props.vendors.map((vendor , index)=>{
															return <option>{vendor.name}</option>
													}) } */}
												</select>
												<p style={{color:"#f5325c"}}>{this.validator.message('vendorSlaUom', this.state.vendorSlaUom, 'required')}</p>
											</div> 
										   </div> 
											 </div>
                                        
                                    </form>          
									<button  className="btn btn-primary" onClick={()=> this.createSlaTerm()}>Submit</button>                                  
                                </div> 
                            </div> 
                        </div> 
                        <div className="col-lg-12">
                            <div className="card">
							<div className="card-header">
                                    <h4 className="card-title">SLA Terms</h4>
                                    {/* <p className="text-muted mb-0">
                                        Use <code>.table-striped</code> to add zebra-striping to any table row
                                        within the <code>&lt;tbody&gt;</code>.
                                    </p> */}
                                </div> 
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table table-striped mb-0">
                                            <thead>
                                            <tr>
                                                <th>Panelty</th>
                                                <th>Sla Time</th>
												<th>Contract</th>
												<th>Site</th>  
                                                <th className="text-end">Action</th>
                                            </tr>
                                            </thead>
                                            <tbody>
												{
                                                     this.state.SlaTerms.reverse().map((SlaTerm , index)=>{
													 return	<tr>
															<td>{SlaTerm.panelty}</td>
															<td>{SlaTerm.slaTime}</td>
															<td>{SlaTerm.contract.vendor.name}</td>
															<td>{SlaTerm.site.siteName}</td> 
															<td className="text-end">                                                       
																<a href="#"><i className="las la-pen text-secondary font-16"></i></a>
																<a href="#"><i className="las la-trash-alt text-secondary font-16"></i></a>
															</td>
														</tr>
													 })
												}
                                           
                                             
                                             </tbody>
                                        </table> 
                                    </div> 
                                </div>
                            </div> 
                        </div> 
                    </div> 
					<ToastContainer position="top-right" autoClose={5000}/>
			
			</div>
		);
	}
}


function mapStateToProps(state) { 
	//console.log(state)
	return {
		vendors: state.vendor ,
		regions: state.regions ,
		organizations : state.organization
		
	};
  }
  
  function mapDispatchToProps(dispatch) {
	return {
		addVendor: data => dispatch(addVendor(data))
	};
  }
  
export default connect(
	mapStateToProps,mapDispatchToProps 
  )(VendorSlaTerm);  


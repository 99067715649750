import React, { Component } from "react";
import { connect } from "react-redux";
import { getDonationListData } from "../actions/index";
import { getDonationCategories, getData } from "../actions/index";
import { getPgData } from "../api/index";
import Pg from "../components/pg/pg";
import { Table, Input, Tag, Space } from "antd";
import { withRouter, Redirect } from 'react-router-dom'

import {
  RightOutlined,
  DownOutlined,
  BorderOuterOutlined,
  TeamOutlined,
  AppstoreOutlined,
  DeploymentUnitOutlined,
  ClusterOutlined,
} from "@ant-design/icons";

var noData = <svg class="ant-empty-img-simple" width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg"><g transform="translate(0 1)" fill="none" fill-rule="evenodd"><ellipse class="ant-empty-img-simple-ellipse" cx="32" cy="33" rx="32" ry="7"></ellipse><g class="ant-empty-img-simple-g" fill-rule="nonzero"><path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path><path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" class="ant-empty-img-simple-path"></path></g></g></svg>;
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pgData: "",
      pgPendingCount: 0,
      filterTable: null,
      isPgSelected: false,
      selectedPgData: null,
      loading: false,
      setLogin: false
    };
  }

  componentDidMount() {

    if (JSON.parse(localStorage.getItem('serviceTypeSet')) == null) {
      this.setState({
        setLogin: true,

      })
    }
    //  //console.log(process.env.NODE_ENV+'cd')
    // if(localStorage.getItem("access_token") ==null){
    //   window.location.reload(false);
    // }
    this.setState({
      loading: true,



    });
    this.props.getData();
    this.getPgData();


  }

  getPgData = async () => {
    var res = await getPgData();
    // //console.log(res.data.requests);

    if (res.success) {
      this.setState({
        pgData: res.data.requests,
        loading: false

      });

    }
  };
  search = (value) => {
    const { pgData } = this.state;
    //console.log("PASS", { value });

    const filterTable = pgData.filter((o) =>

      Object.keys(o).some((k) =>

        String(o[k]).toLowerCase().includes(value.toLowerCase())
      )
    );

    this.setState({ filterTable });
  };
  render() {

    const { filterTable, pgData } = this.state;
    const columns = [
      {
        title: "#",
        dataIndex: "id",
        defaultSortOrder: "ascend",
        sorter: (a, b) => a.id - b.id,
        sortDirections: ["descend"],
        render: (text, record, index) => <span>{text}</span>,
      },
      {
        title: "Request Date",
        dataIndex: "serviceRequest",
        key: "serviceRequest",
        //sorter: (a, b) => a.name.localeCompare(b.name),
        render: (item) => <span>{item.requestDate}</span>,
      },
      {
        title: "Code",
        dataIndex: "serviceRequest",
        key: "serviceRequest",
        //sorter: (a, b) => a.name.localeCompare(b.name),
        render: (item) => <span>{item.requestCode}</span>,
      },
      {
        title: "Site",
        dataIndex: "serviceRequest",
        key: "serviceRequest",
        //sorter: (a, b) => a.name.localeCompare(b.name),
        render: (item) => (
          <>
            <Tag style={{ fontSize: 10 }} color="blue" key={item}>
              {item.site.siteName.toUpperCase()}
            </Tag>
          </>
        ),
      },
      {
        title: "Status",
        dataIndex: "pgStatus",
        key: "pgStatus",
        //sorter: (a, b) => a.name.localeCompare(b.name),

        render: (item) => (
          <>
            <Tag style={{ fontSize: 10 }} color="success" key={item}>
              {item.name.toUpperCase()}
            </Tag>
          </>
        ),
      },
    ];


    return (
      <div>

        {this.state.setLogin ? (

          <div className="container-fluid">
            {

              setTimeout(function () {

                window.location.reload(false);
              }, 1000)

            }


            <br></br>
            <div className="row">

              <div class="col-lg-12" style={{ textAlign: 'center' }}>

                <div class="spinner-border spinner-border-custom-2 text-primary" role="status"></div>



              </div>
            </div>




          </div>) : (
          <div class="container-fluid">


            <div class="row">
              <div class="col-sm-12">
                <div class="page-title-box">
                  <div class="row">
                    <div class="col">
                      <h4 class="page-title">General</h4>
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Dashboard</a></li>
                        <li class="breadcrumb-item"><a href="javascript:void(0);">General</a></li>

                      </ol>
                    </div>
                    <div class="col-auto align-self-center">
                      <a href="#" class="btn btn-sm btn-outline-primary" id="Dash_Date">
                        <span class="day-name" id="Day_Name">Today:</span>&nbsp;
                        <span class="" id="Select_date">{new Date().toUTCString().slice(5, 16)}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar align-self-center icon-xs ms-1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                      </a>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">

                <div className="row justify-content-center">
                  <div className="col-md-6 col-lg-3">
                    <div className="card report-card">
                      <div className="card-body" style={{ backgroundColor: "#26547C" }}>
                        <div className="row d-flex justify-content-center">
                          <div className="col">
                            <p className="  mb-0 fw-semibold" style={{ color: "white" }}>
                              Organizations
                            </p>
                            <h3 className="m-0" style={{ color: "white" }}>{this.props.organization_count}</h3>
                            {/* <p className="mb-0 text-truncate text-muted">
                          <span className="text-success">
                            <i className="mdi mdi-trending-up"></i>8.5%
                          </span>{" "}
                          New Sessions Today
                        </p> */}
                          </div>
                          <div className="col-auto align-self-center">
                            <div className="report-main-icon bg-light-alt">
                              <AppstoreOutlined />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="card report-card">
                      <div className="card-body" style={{ backgroundColor: "#EF476F" }}>
                        <div className="row d-flex justify-content-center">
                          <div className="col">
                            <p className="  mb-0 fw-semibold" style={{ color: "white" }}>Regions</p>
                            <h3 className="m-0" style={{ color: "white" }}>{this.props.regions_count}</h3>
                            {/* <p className="mb-0 text-truncate text-muted">
                          <span className="text-success">
                            <i className="mdi mdi-trending-up"></i>1.5%
                          </span>{" "}
                          Weekly Avg.Sessions
                        </p> */}
                          </div>
                          <div className="col-auto align-self-center">
                            <div className="report-main-icon bg-light-alt">
                              <ClusterOutlined />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="card report-card">
                      <div className="card-body" style={{ backgroundColor: "#FFD166" }}>
                        <div className="row d-flex justify-content-center">
                          <div className="col">
                            <p className="  mb-0 fw-semibold" style={{ color: "white" }}>Depots</p>
                            <h3 className="m-0" style={{ color: "white" }}>{this.props.depots_count}</h3>
                            {/* <p className="mb-0 text-truncate text-muted">
                          <span className="text-danger">
                            <i className="mdi mdi-trending-down"></i>35%
                          </span>{" "}
                          Bounce Rate Weekly
                        </p> */}
                          </div>
                          <div className="col-auto align-self-center">
                            <div className="report-main-icon bg-light-alt">
                              <DeploymentUnitOutlined color="blue" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="card report-card">
                      <div className="card-body" style={{ backgroundColor: "#06D6A0" }}>
                        <div className="row d-flex justify-content-center">
                          <div className="col">
                            <p className="  mb-0 fw-semibold" style={{ color: "white" }}>Vendors</p>
                            <h3 className="m-0" style={{ color: "white" }}>{this.props.vendor_count}</h3>
                            {/* <p className="mb-0 text-truncate text-muted">
                          <span className="text-success">
                            <i className="mdi mdi-trending-up"></i>10.5%
                          </span>{" "}
                          Completions Weekly
                        </p> */}
                          </div>
                          <div className="col-auto align-self-center">
                            <div className="report-main-icon bg-light-alt">
                              <BorderOuterOutlined />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 col-lg-6">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">General Detail Summary</h4>
                  </div>
                  <div className="card-body">
                    <ul className="nav nav-tabs" role="tablist">
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          data-bs-toggle="tab"
                          href="#home"
                          role="tab"
                          aria-selected="true"
                        >
                          PG  ({
                            filterTable == null ? pgData.length : filterTable.length
                          })
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#profile"
                          role="tab"
                          aria-selected="false"
                        >
                          PIS
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#settings"
                          role="tab"
                          aria-selected="false"
                        >
                          Refuel
                        </a>
                      </li>
                    </ul>

                    <div className="tab-content">
                      <div className="tab-pane active" id="home" role="tabpanel">
                        <div className="card">
                          <ul className="message-list">
                            <br></br>
                            <Input
                              enterButton
                              style={{ marginBottom: 5 }}
                              placeholder="Search ..."
                              onChange={(e) => {
                                const currValue = e.target.value;
                                this.search(currValue);
                              }}
                            />

                            <Table
                              size="small"
                              columns={columns}
                              rowKey="id"
                              loading={this.state.loading}
                              pagination={{ pageSize: 5 }}
                              onRow={(record, rowIndex) => ({
                                onClick: (e) => {
                                  /* Call some endPoint to log this click event */
                                  //console.log(record);
                                  this.setState({
                                    isPgSelected: true,
                                    selectedPgData: record,
                                  });
                                },
                              })}
                              // expandable={{
                              //   expandedRowRender:(record,key )=> <p key={key+1} style={{ margin: 0 }}>{record.id}</p>,
                              // }}

                              dataSource={
                                filterTable == null ? pgData : filterTable
                              }
                            />
                          </ul>
                        </div>
                      </div>
                      <div className="tab-pane p-3" id="profile" role="tabpanel">
                        <p className="mb-0 text-muted">
                          Food truck fixie locavore, accusamus mcsweeney's
                          single-origin coffee squid.
                        </p>
                      </div>
                      <div className="tab-pane p-3" id="settings" role="tabpanel">
                        <p className="text-muted mb-0">
                          Trust fund seitan letterpress, keytar raw denim keffiyeh
                          etsy.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                {/* <div className="card">
              <div className="card-header">
                <div className="row align-items-center">
                  <div className="col">
                    <h4 className="card-title">Activity</h4>
                  </div>
                  <div className="col-auto">
                    <div className="dropdown">
                      <a
                        href="#"
                        className="btn btn-sm btn-outline-light dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        All<i className="las la-angle-down ms-1"></i>
                      </a>
                      <div className="dropdown-menu dropdown-menu-end">
                        <a className="dropdown-item" href="#">
                          Purchases
                        </a>
                        <a className="dropdown-item" href="#">
                          Emails
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="analytic-dash-activity" data-simplebar>
                  <div className="activity">
                    <div className="activity-info">
                      <div className="icon-info-activity">
                        <i className="las la-user-clock bg-soft-primary"></i>
                      </div>
                      <div className="activity-info-text">
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="text-muted mb-0 font-13 w-75">
                            <span>Donald</span>
                            updated the status of <a href="">Refund #1234</a> to
                            awaiting customer response
                          </p>
                          <small className="text-muted">10 Min ago</small>
                        </div>
                      </div>
                    </div>

                    <div className="activity-info">
                      <div className="icon-info-activity">
                        <i className="mdi mdi-timer-off bg-soft-primary"></i>
                      </div>
                      <div className="activity-info-text">
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="text-muted mb-0 font-13 w-75">
                            <span>Lucy Peterson</span>
                            was added to the group, group name is{" "}
                            <a href="">Overtake</a>
                          </p>
                          <small className="text-muted">50 Min ago</small>
                        </div>
                      </div>
                    </div>

                    <div className="activity-info">
                      <div className="icon-info-activity">
                        <img
                          src="assets/images/users/user-5.jpg"
                          alt=""
                          className="rounded-circle thumb-sm"
                        />
                      </div>
                      <div className="activity-info-text">
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="text-muted mb-0 font-13 w-75">
                            <span>Joseph Rust</span>
                            opened new showcase <a href="">
                              Mannat #112233
                            </a>{" "}
                            with theme market
                          </p>
                          <small className="text-muted">10 hours ago</small>
                        </div>
                      </div>
                    </div>

                    <div className="activity-info">
                      <div className="icon-info-activity">
                        <i className="mdi mdi-clock-outline bg-soft-primary"></i>
                      </div>
                      <div className="activity-info-text">
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="text-muted mb-0 font-13 w-75">
                            <span>Donald</span>
                            updated the status of <a href="">Refund #1234</a> to
                            awaiting customer response
                          </p>
                          <small className="text-muted">Yesterday</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
                {this.state.isPgSelected ? (
                  <Pg selectedPgData={this.state.selectedPgData} />
                ) : (

                  <div className="row">
                    <div className="col-lg-12 mx-auto">
                      <div className="card">
                        <div class="ant-empty ant-empty-normal"><div class="ant-empty-image">{noData}</div><div class="ant-empty-description">Please select PG from list</div></div>
                      </div>
                    </div>
                  </div>

                )}
              </div>
            </div>
          </div>)}



      </div>
    );
  }
}

function mapStateToProps(state) {

  //console.log("state11", state);
  return {
    organization_count: state.organization.length,
    regions_count: state.regions.length,
    depots_count: state.depots.length,
    sites_count: state.sites.length,
    vendor_count: state.vendor.length,
    roles_count: state.roles.length,
  };
}

export default withRouter(connect(mapStateToProps, { getDonationCategories, getData })(Dashboard));

import React, { Component } from 'react'
import { Table, Tag, Button, Drawer, Form, Input, Select, InputNumber, message, Popconfirm, DatePicker } from 'antd';
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { getAllModelData, powerDbModelCreate, powerDbModelGetById, powerDbModelUpdate, powerDbModelDelete } from "../../api/index";
import { getAllDropdowns } from "../../api/index";
const { Option } = Select;


class RectifiersModel extends Component {

    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            AssetManagementPermission: true,
            dataSet: [],
            actionView: true,
            open: false,
            editFlow: false,
            rectifiersModelName: '',
            materialCode: '',
            noOfPhases: 0,
            maximumCapacity: '',
            noOfModuleSlot: '',
            rectifiersModelDescription: '',
            rectifiersModelId: null,
            allRectifiersModel: null,
            tabaleLoading: false,
            setValues: null,
            clearSearchValue: '',

            dropdownDataSet: {
                "noOfPhases": [],
            },


        }
        this.requestFromClose = this.requestFromClose.bind(this);
        this.handleName = this.handleName.bind(this);
        this.handleMaterialCode = this.handleMaterialCode.bind(this);
        this.handleNoOfPhases = this.handleNoOfPhases.bind(this);
        this.handleMaximumCapacity = this.handleMaximumCapacity.bind(this);
        this.handleNoOfModuleSlot = this.handleNoOfModuleSlot.bind(this);
        this.handleDescription = this.handleDescription.bind(this);
    }

    handleDescription(e) {
        console.log(e.target.value)
        this.setState({ rectifiersModelDescription: e.target.value });
    }
    handleNoOfModuleSlot(e) {
        console.log(e)
        this.setState({ noOfModuleSlot: e });
    }
    handleMaximumCapacity(e) {
        console.log(e)
        this.setState({ maximumCapacity: e });
    }
    handleNoOfPhases(e) {
        console.log(e)
        this.setState({ noOfPhases: e });
    }
    handleMaterialCode(e) {
        console.log(e.target.value)
        this.setState({ materialCode: e.target.value });
    }
    handleName(e) {
        console.log(e.target.value)
        this.setState({ rectifiersModelName: e.target.value });
    }

    requestFromClose() {
        console.log('dsdsd')
        this.setState({ showModal: true, open: true, editFlow: false });
    }

    onDrawerClose = () => {
        console.log('sddsds')
        this.formRef.current.resetFields();

        this.setState({
            open: false,
            rectifiersModelName: '',
            materialCode: '',
            noOfPhases: 0,
            maximumCapacity: 0,
            noOfModuleSlot: 0,
            rectifiersModelDescription: '',
        })

    }
    rectifierModelUpdata = (id) => {
        var Url = '/rectifierModel/getById/' + id
        this.getModelById(Url)


    }
    getModelById = async (Url) => {

        var res = await powerDbModelGetById(Url);
        console.log(res)
        if (res.success) {
            this.setState({
                editFlow: true,
                open: true,
                rectifiersModelId: res.data.id,
                rectifiersModelName: res.data.name,
                materialCode: res.data.materialCode,
                noOfPhases: res.data.noOfPhases,
                maximumCapacity: res.data.maximumCapacity,
                noOfModuleSlot: res.data.noOfModuleSlot,
                rectifiersModelDescription: res.data.description,

            })
        }


    }
    editDataSubmit = async () => {
        var Url = '/rectifierModel/update'
        var data = {
            "id": this.state.rectifiersModelId,
            "name": this.state.rectifiersModelName,
            "description": 'ss',
            "materialCode": this.state.materialCode,
            "noOfPhases": this.state.noOfPhases,
            "maximumCapacity": this.state.maximumCapacity,
            "noOfModuleSlot": this.state.noOfModuleSlot,
            "description": this.state.rectifiersModelDescription
        }
        var res = await powerDbModelUpdate(Url, data);

        if (res.success) {
            this.setState({
                open: false,
                rectifiersModelName: '',
                materialCode: '',
                noOfPhases: 0,
                maximumCapacity: 0,
                noOfModuleSlot: 0,
                rectifiersModelDescription: '',
            }, () => {
                this.getAllModelData()
                message.success('Successfully Updated!')
            })
        }


    }
    AllDataSubmit = async () => {
        var Url = '/rectifierModel/create'
        var data = {

            "name": this.state.rectifiersModelName,
            "description": 'ss',
            "materialCode": this.state.materialCode,
            "noOfPhases": this.state.noOfPhases,
            "maximumCapacity": this.state.maximumCapacity,
            "noOfModuleSlot": this.state.noOfModuleSlot

        }
        var res = await powerDbModelCreate(Url, data);

        if (res.success) {
            this.formRef.current.resetFields();
            this.setState({
                open: false,
                rectifiersModelName: '',
                materialCode: '',
                noOfPhases: 0,
                maximumCapacity: 0,
                noOfModuleSlot: 0,
                rectifiersModelDescription: '',
                filteredTable: null
            }, () => {
                this.getAllModelData()
                message.success('Successfully Created!')
            })

        }

    }
    componentDidMount() {
        this.getAllModelData();
        this.getDropDownData();
    }

    getDropDownData = async () => {
        var res = await getAllDropdowns();
        console.log("---------------------------------------------------------------------------------------------------");
        console.log(res);
        if (res.success) {
            this.setState({
                dropdownDataSet: {
                    "noOfPhases": res.data["Rectifier 1"]["No of phases"],
                }
            })
            console.log(this.state.dropdownDataSet)
        } else {
            message.warning('Issue With Dropdown Data Fetching! : ' + res.data.message);
        }
    }

    submitForm = async (item) => {
        this.deleteModelData(item);


    }
    deleteModelData = async (item) => {
        var Url = '/rectifierModel/deleteRectifierModel?rectifierModelId=' + item
        var res = await powerDbModelDelete(Url);
        if (res.success) {
            message.success('Successfully Delete!')
            this.getAllModelData();
        } else {
            message.warning(' Something went wrong!')
        }

    }
    getAllModelData = async () => {
        this.setState({
            tabaleLoading: true
        })
        var Url = '/rectifierModel/getAll'

        var res = await getAllModelData(Url);
        console.log(res.data)
        if (res.success) {

            if (this.state.setValues == null) {
                this.setState({
                    allRectifiersModel: res.data,
                    tabaleLoading: false
                })
            } else {
                this.setState({
                    allRectifiersModel: res.data,
                    tabaleLoading: false
                }, () => {
                    const filtered = this.state.allRectifiersModel.filter(user => (user?.name?.toLowerCase().includes(this.state.setValues.toLowerCase()) || user?.materialCode?.toLowerCase().includes(this.state.setValues.toLowerCase()) || user?.maximumCapacity == this.state.setValues || user?.noOfModuleSlot == this.state.setValues));
                    this.setState({
                        filteredTable: filtered
                    })


                })
            }


        }

    }
    search = (value) => {
        this.setState({
            setValues: value
        })

        const filtered = this.state.allRectifiersModel.filter(user => (user?.name?.toLowerCase().includes(value.toLowerCase()) || user?.materialCode?.toLowerCase().includes(value.toLowerCase()) || user?.maximumCapacity == value || user?.noOfModuleSlot == value));
        this.setState({
            filteredTable: filtered
        })



    }

    render() {
        const columns2 = [
            {
                title: "Rectifier Model",

                dataIndex: "name",
                key: "name",
                fixed: 'left',

                render: (item) => <>
                    <Tag color="gray" key={item}>
                        <span>{item}</span>
                    </Tag>
                </>,


            },
            {
                title: "Material Code",
                dataIndex: "materialCode",
                key: "materialCode",

                fixed: 'left',
                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,

            },

            {
                title: "Maximum Capacity",
                dataIndex: "maximumCapacity",
                key: "maximumCapacity",



                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,
            },
            {
                title: "No Of Module Slot",
                dataIndex: "noOfModuleSlot",
                key: "noOfModuleSlot",



                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,
            },
            // {
            //     title: "No Of Phases",
            //     dataIndex: "noOfPhases",
            //     key: "noOfPhases",
            //     render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,
            // },

            {

                dataIndex: "id",
                key: "id",
                width: '50px',

                fixed: 'right',

                render: (item) => <>
                    {this.state.AssetManagementPermission ? (<a style={{ color: 'blue' }} onClick={() => this.rectifierModelUpdata(item)}><i className="las la-pen font-15"></i></a>) : ('')}
                    <Popconfirm
                        title="Are You Sure?"
                        description="Are you sure to delete this task?"
                        onConfirm={() => this.submitForm(item)}
                        okText="Yes"
                        cancelText="No"
                    >
                        {this.state.AssetManagementPermission ? (<a style={{ color: 'blue' }}><i className="las la-trash-alt font-15"></i></a>) : ('')}
                    </Popconfirm> </>,

            },









        ];
        return (
            <div class="row">
                <div class="card">
                    <div class="card-header">

                        <div className="row align-items-center">
                            <div className="col">
                                <h4 className="card-title">Rectifier Model List</h4>
                            </div>
                            <div className="col-auto">
                                {this.state.actionView ? (<Button className="btn btn-primary" onClick={this.requestFromClose} block style={{ textAlign: "end" }} >
                                    <i class="la la-plus-circle text-muted font-16 me-2"></i> <b style={{ color: "#40a9ff" }}>Add Data</b>
                                </Button>) : ('')}
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div className='row'>
                            <div className='col-md-10'>
                                <Input
                                    id='clearSearch'
                                    enterButton
                                    style={{ marginBottom: 5 }}
                                    placeholder="Search ..."
                                    value={this.state.clearSearchValue}
                                    onChange={(e) => {
                                        const currValue = e.target.value;
                                        this.search(currValue);
                                        this.setState({
                                            clearSearchValue: currValue
                                        })
                                    }}
                                />
                            </div>
                            <div className='col-md-2' style={{ textAlign: 'end' }}>
                                <button type="button" class="btn btn-primary " onClick={(e) => {
                                    this.setState({
                                        filteredTable: null
                                    }, () => {
                                        this.setState({
                                            clearSearchValue: ''
                                        })

                                    })

                                }} >Clear Search</button>
                            </div>

                        </div>


                        <Table
                            size="small"
                            columns={columns2}
                            // loading={this.state.loading && this.state.rectifierloading && this.state.batteryBanksloading && this.state.airConditionersloading && this.state.spdsloading && this.state.cabinetsloading && this.state.generatorsloading && this.state.atsesloading && this.state.dcdusloading && this.state.fcbsloading && this.state.sharedOutOperatorsesloading}
                            loading={this.state.tabaleLoading}


                            dataSource={this.state.filteredTable == null ? this.state.allRectifiersModel?.reverse() : this.state.filteredTable?.reverse()}
                        />


                        <Drawer title={this.state.editFlow ? ('Edit Data') : ('Add Data')} width={"60%"} placement="right" onClose={() => this.onDrawerClose()} visible={this.state.open}>
                            <div className="row" >
                                <div className="col-lg-12">
                                    <div className="card">

                                        <div className="card-body">
                                            <Form
                                                ref={this.formRef}
                                            >

                                                <div className='row'>

                                                    {/*  */}

                                                    <div className="col-md-6">


                                                        <label className="form-label" for="dcdu">
                                                            Rectifier Model
                                                        </label>
                                                        <Form.Item required >
                                                            <Input id="Name" onChange={this.handleName} value={this.state.rectifiersModelName} />
                                                            {/* <p style={{ color: "#f5325c" }}>{this.validator.message('site_name', this.state.siteName, 'required')}</p> */}
                                                        </Form.Item>



                                                    </div>
                                                    <div className="col-md-6">


                                                        <label className="form-label" for="materialCode">
                                                            Material Code
                                                        </label>
                                                        <Form.Item required >
                                                            <Input id="materialCode" onChange={this.handleMaterialCode} value={this.state.materialCode} />
                                                            {/* <p style={{ color: "#f5325c" }}>{this.validator.message('site_name', this.state.siteName, 'required')}</p> */}
                                                        </Form.Item>



                                                    </div>


                                                </div>
                                                <div className='row'>

                                                    {/*  */}


                                                    <div className="col-md-6">


                                                        <label className="form-label" for="maximumCapacity">
                                                            Maximum Capacity
                                                        </label>
                                                        <Form.Item required>
                                                            <InputNumber min={0} style={{ width: '100%' }} id="maximumCapacity" onChange={this.handleMaximumCapacity} value={this.state.maximumCapacity} />

                                                            {/* <p style={{ color: "#f5325c" }}>{this.validator.message('site_name', this.state.siteName, 'required')}</p> */}
                                                        </Form.Item>



                                                    </div>
                                                    <div className="col-md-6">


                                                        <label className="form-label" for="noOfModuleSlot">
                                                            No Of Module Slot
                                                        </label>
                                                        <Form.Item required
                                                        >
                                                            <InputNumber min={0} style={{ width: '100%' }} id="noOfModuleSlot" onChange={this.handleNoOfModuleSlot} value={this.state.noOfModuleSlot} />
                                                            {/* <p style={{ color: "#f5325c" }}>{this.validator.message('site_name', this.state.siteName, 'required')}</p> */}
                                                        </Form.Item>



                                                    </div>
                                                    {/* <div className="col-md-6">
                                                        <label className="form-label" for="noOfModuleSlot">
                                                            No of phases
                                                        </label>
                                                        <Form.Item required
                                                        >
                                                            <Select
                                                                defaultValue={this.state?.noOfPhases ? this.state.noOfPhases : 'Select No of Phases'}
                                                                style={{ width: '100%' }}
                                                                onChange={(e) => {
                                                                    this.handleNoOfPhases(e);
                                                                }}>
                                                                {this.state.dropdownDataSet.noOfPhases.map((x) => {
                                                                    return <Option value={x}>{x}</Option>
                                                                })}
                                                            </Select>
                                                        </Form.Item>
                                                    </div> */}
                                                </div>
                                                <div className='row'>

                                                    {/*  */}



                                                </div>








                                            </Form>




                                            <div className='row' style={{ justifyContent: "end" }}>
                                                <div className='col-md-2' >   <button className="btn btn-primary" onClick={this.state.editFlow ? (() => this.editDataSubmit()) : (() => this.AllDataSubmit())} >Submit</button></div>


                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </Drawer>


                    </div>
                </div>
            </div>
        )
    }
}
export default RectifiersModel;

export const ADD_ARTICLE = "ADD_ARTICLE";
export const DATA_REQUESTED= "DATA_REQUESTED";
export const MASTER_DATA_LOADED= "MASTER_DATA_LOADED";
export const ROLES_DATA_LOADED= "ROLES_DATA_LOADED";
export const USER_DATA_LOADED= "USER_DATA_LOADED";
export const DONATION_CATEGORIES= "DONATION_CATEGORIES";
export const ADD_ORGANIZATION = "ADD_ORGANIZATION";
export const ADD_VENDOR = "ADD_VENDOR";
export const ADD_REGION = "ADD_REGION";
export const ADD_DEPOT = "ADD_DEPOT";


export const DATE = "DATE";
export const TIME = "TIME";
export const DATE_TIME = "DATE_TIME";



const checkPermissions = (roleType) => {
    if (typeof localStorage === 'undefined') {
        console.error('localStorage is not defined');
        return false;
    }

    let permissions = JSON?.parse(localStorage.getItem("vehicle_permissions"));
    let permission = permissions?.find(permission => permission?.name === roleType);
    return !!permission;
}


export default checkPermissions;
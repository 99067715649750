import React, { useState, useEffect } from 'react';
import { Collapse, Select, Button, Tag, message, Row, Col, Form, Modal, Input, Result  } from 'antd';
import { PlusCircleFilled, SaveOutlined, DeleteFilled } from '@ant-design/icons';
import { getAllDropdowns, createDropdownDataAccessFunction, getAllDropdownsV03, deleteDropdownDataAccessFunction } from "../../api/index";
import './style.css';

const { Panel } = Collapse;

const DropdownManagement = () => {
    const [allDropdownData, setAllDropdownData] = useState({});
    const [allKeys, setAllKeys] = useState([]);
    const [insideData, setInsideData] = useState([]);
    const [selectedKey, setSelectedKey] = useState('General Details');
    const [openModal, setOpenModal] = useState(false);
    const [targetInformations, setTargetInformations] = useState({});
    let items = [];

    useEffect(() => {
        fetchAllDropdownData();
    }, []);

    const fetchAllDropdownData = async () => {
        const response = await getAllDropdownsV03();
        if (response.success) {
            setAllDropdownData(response.data);
            setAllKeys(Object.keys(response.data));
        }
    };

    const defineNewData = async (data) => {
        try {
            const newData = await createDropdownDataAccessFunction(data);
            if (newData.success) {
                message.success('Data inserted successfully');
                fetchAllDropdownData();
                setOpenModal(false);
                setTargetInformations({});
                setInsideData([]);
                setSelectedKey(null);
            } else {
                message.error('Data insertion failed');
            }
        } catch (error) {
            message.error('Data insertion failed');
        }
    }
    
    const deleteDataFromDropdown = async (key) => {
        const formdata = new FormData();
        formdata.append('id', key);
        try {
            const response = await deleteDropdownDataAccessFunction(formdata);
            if (response.success) {
                message.success('Data deleted successfully');
                fetchAllDropdownData();
                setInsideData([]);
                setSelectedKey(null);
            } else {
                message.error('Data deletion failed');
            }
        } catch (error) {
            message.error('An error occurred during data deletion');
        }
    }

    const onFormSubmit = (values) => {
        const submitJson = {
            groupName: targetInformations.groupName,
            fieldName: targetInformations.groupData,
            item: values.item,
            dataType: 'TEXT'
        }
        defineNewData(submitJson);
    }

    const getInsideSectionsBasedOnKey = (keyName) => {
        const data = allDropdownData[keyName];
        let dataArr = [];
        dataArr.push(data);
        setInsideData(Array.isArray(dataArr) ? dataArr : []);
    };

    const onChange = (key) => {
        console.log(key);
    };

    const generateItems = (data) => {
        console.log(data);
        if (!data || data.length === 0) {
            return [];
        }
        return Object?.keys(data[0]).map((key, index) => ({
            key: (index + 1).toString(),
            label: <div>
                <Row>
                    <Col span={12}>
                        <span className='textStyles-small'>{key}</span>
                    </Col>
                    <Col span={12}>
                        <Button
                            type="primary"
                            shape='circle'
                            size='small'
                            icon={<PlusCircleFilled style={{ fontSize: '14px' }} />}
                            style={{ background: "#38B36E", borderColor: "#38B36E", float: 'right' }}
                            onClick={() => {
                                setTargetInformations({ groupName: selectedKey, groupData: key });
                                setOpenModal(true);
                            }}
                        />
                    </Col>
                </Row>
            </div>,
            children: (
                <ul>
                    {data[0][key]?.map((item, idx) => (
                        <li key={idx} style={{ listStyleType: 'none', marginTop: '10px' }}>
                            <DeleteFilled style={{ color: 'red', marginLeft: '10px', cursor: 'pointer' }} 
                            onClick={() => deleteDataFromDropdown(item?.id)}
                            /> <span className='textStyles-small'>{item?.value}</span>
                        </li>
                    ))}
                </ul>
            ),
        }));
    };

    items = generateItems(insideData.length > 0 ? insideData : []);

    return (
        <div style={{ margin: '0', padding: '10px', height: '100%' }}>
            <div className="row">
                <div className="col-sm-12">
                    <div className="page-title-box">
                        <div className="row">
                            <div className="col">
                                <h4 className="page-title" style={{ fontWeight: "bold" }}>
                                    Dropdown Configuration
                                </h4>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <a href="javascript:void(0);">Admin</a>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <a href="javascript:void(0);">Dropdown Configuration</a>
                                    </li>
                                </ol>
                            </div>
                            <div class="col-auto align-self-center">
                            <Select
                            className='borderedSelect'
                            bordered={false}
                            showSearch
                            style={{ width: 220 }}
                            placeholder="Select Configuration Group"
                            defaultValue={selectedKey}
                            onChange={(value) => {
                                getInsideSectionsBasedOnKey(value);
                                setSelectedKey(value);
                            }}
                        >
                            {allKeys.map((key, index) => (
                                <Select.Option key={index} value={key}><span className='textStyles-small'>{key}</span></Select.Option>
                            ))}
                        </Select>

                                        </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div style={{ margin: '0', padding: '10px', width: '100%', backgroundColor: 'white', borderRadius: '10px', marginTop: '20px' }}>
                {
                    insideData.length === 0 ? (
                        <Result
                            style={{ width: '100%', height: '25%' }}
                            size="small"
                            status="404"
                            subTitle={<span className='textStyles-small'>Choose Configuration group from the dropdown</span>}
                        />
                    ) : (
                        <Collapse defaultActiveKey={['1']} onChange={onChange} ghost={true} style={{ marginTop: '20px' }}>
                            {items.map(item => (
                                <Panel header={item.label} key={item.key}>
                                    {item.children}
                                </Panel>
                            ))}
                        </Collapse>
                    )
                }
            </div>

            <Modal
                title={<span className='textStyles-small' style={{ fontSize: '16px' }}>
                    Insert New Data</span>}
                visible={openModal}
                onOk={() => {
                    setOpenModal(false);
                    setTargetInformations({});
                }}
                onCancel={() => {
                    setOpenModal(false);
                    setTargetInformations({});
                }}
                footer={[]}
            >
                <Form
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={onFormSubmit}
                    autoComplete="off"
                    layout='vertical'
                >
                    <Form.Item
                        label={<span className='textStyles-small'>Item Name</span>}
                        name="item"
                        rules={[{ required: true, message: 'Please input item name!' }]}
                    >
                        <Input className='borderedSelect' style={{ height: 32 }} />
                    </Form.Item>

                    <Row>
                        <Col span={24}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{ background: "#38B36E", borderColor: "#38B36E", borderRadius: '5px', float: 'right' }}
                            >
                                <span className='textStyles-small'><SaveOutlined /> Insert Data</span>
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    );
}

export default DropdownManagement;

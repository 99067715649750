import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Bar } from 'react-chartjs-2';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  const labels = ['01 Jan 2001', '02 Jan 2001', '03 Jan 2001', '04 Jan 2001', '05 Jan 2001', '06 Jan 2001', '07 Jan 2001', '08 Jan 2001', '09 Jan 2001', '10 Jan 2001', '11 Jan 2001', '12 Jan 2001'];
  const data = {
    labels,
    datasets: [
        {
            label: 'Cumulative Cost',
            yAxisID: 'y2',
            type:'line',
            data: [1, 3, 5, 6, 8, 14, 19, 23, 26, 32, 36, 39],
            borderWidth:2,
            backgroundColor: 'rgb(53, 162, 235)',
            borderColor: 'rgb(53, 162, 200)',
          },
      {
        label: 'CR',
        yAxisID: 'y',
        data: [200, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160],
        backgroundColor: 'rgb(255, 99, 132)',
      },
      {
        label: 'ER',
        yAxisID: 'y',
        data: [40,600, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160],
        backgroundColor: 'rgb(255,165,0)',
      },
      {
        label: 'NR',
        yAxisID: 'y',
        data: [40,600, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160],
        backgroundColor: 'rgb(0,250,154)',
      },
      {
        label: 'SR',
        yAxisID: 'y',
        data: [40,600, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160],
        backgroundColor: 'rgb(0,191,255)',
      },
      {
        label: 'WR',
        yAxisID: 'y',
        data: [40,600, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160],
        backgroundColor: 'rgb(139,0,139)',
      },
     
    ],
  };
  export const options = {
    type: 'bar',    
 
    options: {
        
        interaction: {
          intersect: false,
          mode: 'index',
        },
        plugins: {
          title: {
            display: true,
            text: (ctx) => 'Tooltip position mode: ' + ctx.chart.options.plugins.tooltip.position,
          },
        }
      },
    responsive: true,
    plugins: {
        legend: {
          labels: {
          //ntStyle: true,
            boxWidth: 12,
            boxHeight:5
            
          },
        }
      },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        position: 'left',
        title: {
            display: true,
            text:'First Line',
            color: '#8997bd',
            font: {
                family:'Roboto',
                size: 11,
                weight: 'bold',
                lineHeight: 1.2,
              },

          }
      },
      y2: {
        stacked: true,
        position: 'right',
        color: '#8997bd',

        title: {
            display: true,
            text:'Second Line',
            color: '#8997bd',
            font: {
                family:'Roboto',
                size: 11,
                weight: 'bold',
                lineHeight: 1.2,
              },

          }
      },
    },
  
  };


const StackedBar = (props) => {
  
  
return (
  <>

    <Bar data={data} options={options} />
  </>

)}

export default StackedBar;
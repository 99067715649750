import React, { Component } from 'react'
import { Table, Input, InputNumber, Tag, Space, Select, Form, DatePicker, TimePicker, Button, Switch, message, Popconfirm } from "antd";

import { getUpdatedAppVersion, UpdateAppVersions } from "../../api/index";
const moment = require('moment');

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            allData: null,
            newAppVersion: null

        }

    }



    componentDidMount() {
        this.getDataUpdatedAppVersion();


    }
    formItemChange = (event) => {



        console.log(event.target.value)
        this.setState({
            newAppVersion: event.target.value
        });
    }
    updateAppVersion = (e) => {



        if (this.state.newAppVersion == null) {

            message.warning('Please Enter new Version!')

        } else {
            console.log(this.state.newAppVersion)
            this.UpdateNewAppVersion(this.state.newAppVersion)

        }
    }
    UpdateNewAppVersion = async (data) => {


        let newData = { "version": data }




        var res = await UpdateAppVersions(newData);


        if (res.success) {
            document.getElementById('addedVersion').value = ''
            message.success('Successfully updated')

            this.getDataUpdatedAppVersion();

        } else {
            message.warning(' Something went wrong!')
        }

    }
    getDataUpdatedAppVersion = async () => {
        this.setState({
            loading: true
        })
        var res = await getUpdatedAppVersion();



        this.setState({
            allData: res.data,
            loading: false
        })

        console.log(res)

    };
    render() {
        return (
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="page-title-box">
                            <div class="row">
                                <div class="col">
                                    <h4 class="page-title">App Version</h4>
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="javascript:void(0);">App Version</a></li>
                                        <li class="breadcrumb-item"><a href="javascript:void(0);">Update</a></li>


                                    </ol>
                                </div>
                                <div class="col-auto align-self-center">
                                    <a href="#" class="btn btn-sm btn-outline-primary" id="Dash_Date">
                                        <span class="day-name" id="Day_Name">Today:</span>&nbsp;
                                        <span class="" id="Select_date">{new Date().toUTCString().slice(5, 16)}</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar align-self-center icon-xs ms-1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                                    </a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-lg-6">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Update App Version</h4>
                            </div>
                            <div className="card-body">

                                <div class="row">

                                    <div class="col-lg-12 align-self-center">
                                        <div class="single-pro-detail">


                                            <h3 class="pro-title">Latest App Version - {this.state?.allData?.version}</h3>
                                            <p class="text-muted mb-0">Last Updated Time : {moment(this.state?.allData?.lastUpdatedTime).format('YYYY-MM-DD HH:mm:ss')} </p>

                                            <h2 class="pro-price"> <span>Latest Version:</span><span class="text-danger fw-bold ms-2">{this.state.allData?.latestVersion ? (<Tag color={'#20d137'} >
                                                <span style={{ color: 'black' }}>True</span>
                                            </Tag>) : (<Tag color={'#f79d86'} >
                                                <span style={{ color: 'black' }}>False</span>
                                            </Tag>)}</span></h2>

                                            <div class="quantity mt-3 ">
                                                <form>
                                                    <input style={{ width: "25%" }} class="form-control form-control-sm" type="text" id="addedVersion" onChange={(e) => this.formItemChange(e)} />

                                                    &nbsp;&nbsp;&nbsp;  <Popconfirm
                                                        title="Are You Sure?"
                                                        description="Are you sure to delete this task?"
                                                        onConfirm={this.updateAppVersion}
                                                        okText="Yes"
                                                        cancelText="No"
                                                    >
                                                        <button class="btn btn-primary btn-sm text-white px-4 d-inline-block" ><i class="mdi mdi-update me-2"></i>Update to New Version</button>
                                                    </Popconfirm>


                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}
export default Index

import React from "react";
import { useMsal } from "@azure/msal-react";


/**
 * Renders a sign-out button
 */
export const SignOutButton = () => {
    const { instance } = useMsal();

    
    const handleLogout = (logoutType) => {
        window.localStorage.clear();
        if (logoutType === "popup") {
            instance.logoutPopup({
                postLogoutRedirectUri: "/",
                mainWindowRedirectUri: "/"
            });
        } else if (logoutType === "redirect") {
            instance.logoutRedirect({
                postLogoutRedirectUri: "/",
            });
        }
    }
    return (
      <div>
            {/* <p as="button" onClick={() => handleLogout("popup")}>Sign out using Popup</p> */}
            <p as="button" onClick={() => handleLogout("redirect")} style={{cursor:'pointer',color:'darkred'}}>Sign out</p>
            </div>
    )
}
import React, { Component } from "react";
import {
  Table,
  Tag,
  Button,
  Drawer,
  Form,
  message,
  Select,
  Input,
  DatePicker,
  Popconfirm,
  Collapse,
  InputNumber,
} from "antd";
import { PlusCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import SimpleReactValidator from "simple-react-validator";
import {
  createNewFcb,
  getFcbDetails,
  UpdateFcb,
  dataUpdateGeneralDetails,
} from "../../api/index";
import moment from "moment";
import AssetDelete from "../site_components/asset_delete";
import { checkServiceType } from "../../actions/service-type";

class Fdc extends Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();

    this.state = {
      showModal: false,
      open: false,
      openEdit: false,
      title: "",
      tabaleLoading: false,
      actionView: false,
      selectedAssetId: null,
      AssetType: [],
      selectedMakeId: null,
      makeType: [],
      fbcCapacity: "",
      selectedModelId: null,
      modelType: [],
      fbclastMaintainanceDate: null,
      fbcwarrantyExpireDate: null,
      fbcInstalledDate: null,
      AssetManagementPermission: false,
      openViewMoreDetails: false,
      selectedElement: "",
      serialNumber: null,
      switchStatus: true,

      objectSet: {
        fcmTicketNumberOfPendingFaultyTicket: 0,
      },
    };
    this.requestFromClose = this.requestFromClose.bind(this);
    this.handleFbcType = this.handleFbcType.bind(this);
    this.handleFbcSerialNumber = this.handleFbcSerialNumber.bind(this);
  }
  fbcUpdata(id) {
    // console.log('dfdfd')
    console.log(id);
    this.getDataFcbDetails(id);
    this.setState({ openEdit: true });
  }
  getDataFcbDetails = async (id) => {
    var res = await getFcbDetails(id);
    console.log(res);
    if (res.success) {
      this.setState({
        fbcEdit: res.data,
        fbcId: res.data.id,
        selectedMakeId: res.data.make.id,
        makeType: res.data.make,
        selectedModelId: res.data.model.id,
        modelType: res.data.model,
        fbcCapacity: res.data.capacity,
        serialNumber: res.data.serialNumber,
      });
      if (res.data?.installedDate != null) {
        this.setState({
          fbcInstalledDate: res.data?.installedDate,
        });
      } else {
        this.setState({
          fbcInstalledDate: "",
        });
      }
      if (res.data?.warrantyExpireDate != null) {
        this.setState({
          fbcwarrantyExpireDate: res.data?.warrantyExpireDate,
        });
      } else {
        this.setState({
          fbcwarrantyExpireDate: "",
        });
      }
      if (res.data?.lastMaintainanceDate != null) {
        this.setState({
          fbclastMaintainanceDate: res.data?.lastMaintainanceDate,
        });
      } else {
        this.setState({
          fbclastMaintainanceDate: "",
        });
      }
    }
  };
  handleFbcSerialNumber(e) {
    console.log(e.target.value);
    this.setState({ serialNumber: e.target.value });
  }

  handleFbcType(e) {
    console.log(e);
    this.setState({ fbcCapacity: e });
  }

  requestFromClose() {
    console.log("dsdsd");
    this.setState({ showModal: true, open: true });
  }

  componentDidMount() {
    console.log("fcdMasterData");
    console.log(this.props.fcdMasterData);
    if (JSON.parse(localStorage.getItem("serviceTypeSet")) == null) {
      const { history } = this.props;

      history.push("/");
    } else {
      this.getSetPageData(
        JSON.parse(localStorage.getItem("serviceTypeSet")),
        38
      );
    }
    checkServiceType(38).then((data) =>
      this.setState(
        {
          AssetManagementPermission: data,
        },
        () => {}
      )
    );
    this.setState({
      objectSet: {
        fcmTicketNumberOfPendingFaultyTicket:
          this.props?.details?.access_technologies_data
            ?.fcmTicketNumberOfPendingFaultyTicket,
      },
    });
    // console.log(this.props.details2)
  }
  getSetPageData = (serviceTypeIs, Pageid) => {
    if (
      serviceTypeIs.serviceType.filter(
        (checkuser) => checkuser?.id == Pageid
      )[0] != null
    ) {
      console.log("fddf");
      this.setState({
        actionView: true,
      });
    }
  };
  onDrawerClose = () => {
    console.log("sddsds");
    this.formRef.current.resetFields();
    this.validator.hideMessages();
    this.setState({
      open: false,
      openEdit: false,
      selectedAssetId: null,
      AssetType: [],
      selectedMakeId: null,
      makeType: [],
      fbcCapacity: "",
      selectedModelId: null,
      modelType: [],
      fbcCapacity: "",
      fbclastMaintainanceDate: null,
      fbcwarrantyExpireDate: null,
      fbcInstalledDate: null,
      serialNumber: null,
    });
  };
  onChangeAsset = (value) => {
    console.log("onChangeAsset");
    //console.log(value);
    this.setState({
      selectedAssetId: value,
      AssetType: {
        id: value,
      },
    });
  };
  onChangeMake = (value) => {
    console.log("onChangeMake");
    //console.log(value);
    this.setState({
      selectedMakeId: value,
      makeType: {
        id: value,
      },
    });
  };
  onChangeModel = (value) => {
    console.log("onChangeModel");
    //console.log(value);
    this.setState({
      selectedModelId: value,
      modelType: {
        id: value,
      },
    });
  };
  onChangeInstalledDate = (date, dateString) => {
    console.log(date);
    console.log(dateString);
    this.setState({
      fbcInstalledDate: dateString,
    });
  };
  onChangeWarrantyExpireDate = (date, dateString) => {
    console.log(date);
    console.log(dateString);
    this.setState({
      fbcwarrantyExpireDate: dateString,
    });
  };
  onChangeLastMaintainanceDate = (date, dateString) => {
    console.log(date);
    console.log(dateString);
    this.setState({
      fbclastMaintainanceDate: dateString,
    });
  };
  AllDataSubmitUpdate = async () => {
    if (this.validator.allValid()) {
      var dataSet;

      dataSet = {
        id: this.state.fbcId,
        site: { id: this.props?.detailsSite?.site_details.id },
        asset: {
          id: 2,
        },
        make: this.state.makeType,
        model: this.state.modelType,
        capacity: this.state.fbcCapacity,
        lastMaintainanceDate: this.state.fbclastMaintainanceDate,
        warrantyExpireDate: this.state.fbcwarrantyExpireDate,
        installedDate: this.state.fbcInstalledDate,
        serialNumber: this.state.serialNumber,
      };

      var res = await UpdateFcb(dataSet);
      console.log(res);
      if (res.success) {
        message.success("Successfully FBC Edited!");
        this.formRef.current.resetFields();

        this.setState({
          showModal: false,
          open: false,
          openEdit: false,

          selectedAssetId: null,
          AssetType: [],
          selectedMakeId: null,
          makeType: [],
          fbcCapacity: "",
          selectedModelId: null,
          modelType: [],
          fbcCapacity: "",
          fbclastMaintainanceDate: null,
          fbcwarrantyExpireDate: null,
          fbcInstalledDate: null,
          serialNumber: null,
        });
        this.props.parentMethod();
      } else {
        message.warning(" Something went wrong!");
      }
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  };
  AllDataSubmit = async () => {
    if (this.validator.allValid()) {
      var dataSet;
      dataSet = {
        site: { id: this.props?.detailsSite?.site_details.id },
        asset: {
          id: 2,
        },
        make: this.state.makeType,
        model: this.state.modelType,
        capacity: this.state.fbcCapacity,
        lastMaintainanceDate: this.state.fbclastMaintainanceDate,
        warrantyExpireDate: this.state.fbcwarrantyExpireDate,
        installedDate: this.state.fbcInstalledDate,
        serialNumber: this.state.serialNumber,
      };

      var res = await createNewFcb(dataSet);
      if (res.success) {
        message.success("Successfully FBC Added!");
        this.formRef.current.resetFields();

        this.setState({
          showModal: false,
          open: false,

          selectedAssetId: null,
          AssetType: [],
          selectedMakeId: null,
          makeType: [],
          fbcCapacity: "",
          selectedModelId: null,
          modelType: [],
          fbcCapacity: "",
          fbclastMaintainanceDate: null,
          fbcwarrantyExpireDate: null,
          fbcInstalledDate: null,
          serialNumber: null,
        });
        this.props.parentMethod();
        // const { history } = this.props;
        // history.push("/site");
      } else {
        message.warning(" Something went wrong!");
      }
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  };
  getDataDetailsForDelete = async (id) => {
    var res = await getFcbDetails(id);
    console.log(res);
    if (res.success) {
      var respons = AssetDelete.Action(
        res.data,
        this.props?.detailsSite?.site_details.id,
        UpdateFcb
      );
      respons.then((value) => {
        if (value == 1) {
          message.success("Successfully Delete!");
          this.props.parentMethod();
        } else {
          message.warning(" Something went wrong!");
        }
      });
    }
  };
  submitForm = async (item) => {
    this.getDataDetailsForDelete(item);
  };

  viewMoreDetails(element) {
    this.setState({
      showModal2: true,
      openViewMoreDetails: true,
      selectedElement: element,
    });
  }

  onViewMoreDetailsDrawerClose = () => {
    this.setState({
      showModal2: false,
      openViewMoreDetails: false,
    });
  };

  validatedTextLable = (field) => {
    return field !== null && field !== "" ? (
      <span class="badge badge-outline-secondary text-muted">{field}</span>
    ) : (
      <span class="badge text-danger">Currently Not Set</span>
    );
  };
  handlfcmTicketNumberOfPendingFaultyTicket = (e) => {
    this.setState(
      {
        objectSet: {
          ...this.state.objectSet,
          fcmTicketNumberOfPendingFaultyTicket: e,
        },
      },
      () => {
        console.log(this.state.objectSet);
      }
    );
  };
  dataUpdate() {
    console.log("ssss");
    this.setState({
      switchStatus: false,
    });
  }
  close = async () => {
    this.props.parentMethod();
    this.setState({
      objectSet: {
        fcmTicketNumberOfPendingFaultyTicket:
          this.props?.details?.access_technologies_data
            ?.fcmTicketNumberOfPendingFaultyTicket,
      },
    });
    this.setState({
      switchStatus: true,
    });
  };
  dataSetToUpdate = async () => {
    console.log(this.state.objectSet);
    console.log(this.props.detailsupdateGeneralDetails.updateGeneralDetails);
    let remoteJob = {
      ...this.props.detailsupdateGeneralDetails.updateGeneralDetails,
      ...this.state.objectSet,
    };
    const formData = new FormData();
    formData.append("id", this.props?.details?.access_technologies_data?.id);
    formData.append("object", JSON.stringify(remoteJob));

    var res = await dataUpdateGeneralDetails(formData);

    if (res.success) {
      message.success("Successfully Update!");
      this.props.parentMethod();
      this.setState({
        switchStatus: true,
      });
    } else {
      message.warning(" Something went wrong!");
    }
  };

  render() {
    const { Option } = Select;
    const { Panel } = Collapse;
    const _fcb = this.state.selectedElement;
    const columns2 = [
      {
        title: "Make",
        content:
          _fcb !== "" ? (
            <Tag color="gray" style={{ margin: "0px" }}>
              <span>{_fcb.make?.name}</span>
            </Tag>
          ) : (
            <></>
          ),
        details: [],
      },
      {
        title: "Model",
        content:
          _fcb !== "" ? (
            <span class="badge badge-outline-info badge-pill">
              {_fcb.model?.name}
            </span>
          ) : (
            <></>
          ),
        details: [
          {
            title: "Capacity (W)",
            content: this.validatedTextLable(_fcb.model?.capacity),
          },
        ],
      },
      {
        title: "Serial Number",
        content: this.validatedTextLable(_fcb.serialNumber),
        details: [],
      },
      {
        title: "Warranty Expire Date",
        content: this.validatedTextLable(_fcb.warrantyExpireDate),
        details: [],
      },
      {
        title: "Installed Date",
        content: this.validatedTextLable(_fcb.installedDate),
        details: [],
      },
      {
        title: "Last Maintainance Date",
        content: this.validatedTextLable(_fcb.lastMaintainanceDate),
        details: [],
      },
    ];
    return (
      <>
        <div className="row">
          <div class="col-lg-12">
            <div class="text-end">
              <ul class="list-inline">
                <li class="list-inline-item">
                  {this.state.actionView ? (
                    <button
                      type="button"
                      class="btn btn-soft-primary btn-sm"
                      onClick={this.requestFromClose}
                    >
                      <i class="fas fa-plus me-2"></i>Add FCBs
                    </button>
                  ) : (
                    ""
                  )}
                </li>
              </ul>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div class="card">
                <div class="card-header">
                  <div class="d-flex justify-content-between align-items-center">
                    <h4 class="card-title">Information</h4>
                    {this.state.switchStatus ? (
                      <button
                        type="button"
                        class="btn btn-soft-primary btn-icon-square-sm"
                        onClick={() => this.dataUpdate()}
                      >
                        {" "}
                        <i class="mdi mdi-pencil-outline"></i>
                      </button>
                    ) : (
                      <div class="d-flex justify-content-end align-items-center">
                        <button
                          type="button"
                          class="btn btn-soft-secondary "
                          onClick={() => this.close()}
                          style={{ marginRight: "10px" }}
                        >
                          {" "}
                          Cancel{" "}
                        </button>
                        <button
                          type="button"
                          class="btn btn-soft-primary "
                          onClick={() => this.dataSetToUpdate()}
                        >
                          {" "}
                          Submit to Update{" "}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div className="col-md-6">
                      <ul class="list-group">
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                          <div>
                            <i class="la la-hand-o-right text-primary me-2"></i>{" "}
                            No of FCB
                          </div>
                          {this.props.details?.access_technologies_data
                            ?.noOfFCB != null ? (
                            <span class="badge badge-outline-success">
                              {
                                this.props.details2.fcbs_data.length
                              }
                            </span>
                          ) : (
                            <span class="badge badge-outline-warning">
                              Currently Not Set
                            </span>
                          )}
                        </li>
                      </ul>
                    </div>

                    <div className="col-md-6">
                      <ul class="list-group">
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                          <div>
                            <i class="la la-hand-o-right text-primary me-2"></i>{" "}
                            Ticket number of pending Faulty ticket
                          </div>

                          {/* {this.props?.details?.access_technologies_data?.cplannedFromOperationalTeam? (<span class="badge badge-outline-success">true</span>) : (<span class="badge badge-outline-warning">false</span>)} */}
                          {/* {this.props?.details?.access_technologies_data?.cplannedFromOperationalTeam ? (<> <Switch disabled={this.state.switchStatus} defaultChecked onChange={() => this.switchTo()} checkedChildren="true" unCheckedChildren="false" /></>) : (<Switch disabled={this.state.switchStatus} onChange={() => this.switchTo()} checkedChildren="true" unCheckedChildren="false" />)} */}
                          {this.state.switchStatus ? (
                            <>
                              {this.props?.details?.access_technologies_data
                                ?.fcmTicketNumberOfPendingFaultyTicket !=
                              null ? (
                                <span class="badge badge-outline-success">
                                  {
                                    this.props?.details
                                      ?.access_technologies_data
                                      ?.fcmTicketNumberOfPendingFaultyTicket
                                  }
                                </span>
                              ) : (
                                <span class="badge badge-outline-warning">
                                  Currently Not Set
                                </span>
                              )}
                            </>
                          ) : (
                            <>
                              {" "}
                              <InputNumber
                                min={0}
                                style={{ width: "20%" }}
                                value={
                                  this.state.objectSet
                                    ?.fcmTicketNumberOfPendingFaultyTicket
                                }
                                id="cnoOfCabinetsWithEquipmentInstalled"
                                onChange={
                                  this.handlfcmTicketNumberOfPendingFaultyTicket
                                }
                              />{" "}
                            </>
                          )}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {this.props.details2.fcbs_data.map((element) => (
              <div className="col-md-4">
                <div class="card">
                  <div class="card-header">
                    <div class="d-flex justify-content-start align-items-center">
                      <img
                        src={
                          "https://static.vecteezy.com/system/resources/previews/001/902/502/large_2x/telecommunication-radio-antenna-and-satelite-tower-with-blue-sky-free-photo.JPG"
                        }
                        alt=""
                        class="thumb-md rounded"
                      />
                      <div
                        class="badge badge-soft-primary"
                        style={{
                          padding: "5px",
                          marginLeft: "15px",
                          border: "1px solid rgba(0, 0, 0, 0.05)",
                        }}
                      >
                        <i
                          class="la la-home font-14 text-dark"
                          style={{ paddingLeft: "10px" }}
                        />
                        <i
                          class="la la-angle-right text-dark font-14"
                          style={{ padding: "5px" }}
                        />
                        <span class="fw-semibold text-pink">
                          {this.props?.detailsSite?.site_details.siteName}
                        </span>
                        <i
                          class="la la-angle-right text-dark font-14"
                          style={{ padding: "5px" }}
                        />
                        <span
                          class="fw-semibold text-primary"
                          style={{ paddingRight: "10px" }}
                        >
                          {element.make?.name}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="col">
                      <div class="d-flex justify-content-between align-items-center mb-2">
                        <h6 class="fw-normal m-0">Make</h6>
                        <span class="badge bg-secondary">
                          {element.make?.name}
                        </span>
                      </div>
                      <div class="d-flex justify-content-between align-items-center mb-2">
                        <h6 class="fw-normal m-0">Model</h6>
                        <span class="badge badge-outline-info badge-pill">
                          <i
                            class="fas fa-hdd"
                            style={{ paddingRight: "8px" }}
                          />
                          {element.model?.name}
                        </span>
                      </div>
                      <div class="d-flex justify-content-between align-items-center mb-2">
                        <h6 class="fw-normal m-0">Capacity (W)</h6>
                        <div class="d-flex align-items-center">
                          <span class="badge badge-soft-success fw-semibold">
                            {element.model?.capacity}
                          </span>
                        </div>
                      </div>
                      <div class="d-flex justify-content-between align-items-center mb-2">
                        <h6 class="fw-normal m-0">Warranty Expire Date</h6>
                        <span class="badge badge-soft-pink fw-semibold">
                          <i class="far fa-fw mdi mdi-calendar-range"></i>{" "}
                          {element.warrantyExpireDate}
                        </span>
                      </div>
                    </div>
                  </div>
                  <p class="card-footer m-0">
                    <div class="d-flex justify-content-between align-items-center">
                      <a
                        href="#custom-modal"
                        class="btn btn-soft-dark btn-sm waves-effect btn-round"
                        onClick={() => this.viewMoreDetails(element)}
                      >
                        <i class="mdi mdi mdi-unfold-more-horizontal"></i> More
                        Details{" "}
                      </a>
                      <div class="text-end">
                        {this.state.AssetManagementPermission ? (
                          <button
                            type="button"
                            class="btn btn-soft-primary btn-icon-square-sm"
                            onClick={() => this.fbcUpdata(element.id)}
                            style={{ marginRight: "8px" }}
                          >
                            <i class="mdi mdi-pencil-outline"></i>
                          </button>
                        ) : (
                          ""
                        )}
                        <Popconfirm
                          title="Are You Sure?"
                          description="Are you sure to delete this task?"
                          onConfirm={() => this.submitForm(element.id)}
                          okText="Yes"
                          cancelText="No"
                        >
                          {this.state.AssetManagementPermission ? (
                            <button
                              type="button"
                              class="btn btn-soft-danger btn-icon-square-sm"
                            >
                              <i class="mdi mdi-trash-can-outline"></i>
                            </button>
                          ) : (
                            ""
                          )}
                        </Popconfirm>
                      </div>
                    </div>
                  </p>
                </div>
              </div>
            ))}
          </div>

          <Drawer
            maskClosable={false}
            title={"FCB Details"}
            width={"50%"}
            placement="right"
            onClose={() => this.onViewMoreDetailsDrawerClose()}
            visible={this.state.openViewMoreDetails}
            closeIcon={
              <button
                type="button"
                class="btn btn-soft-danger btn-icon-square-sm"
                style={{ marginRight: "8px" }}
              >
                <i class="mdi dripicons-cross"></i>
              </button>
            }
          >
            <Collapse
              style={{ borderColor: "lavender", alignContent: "center" }}
            >
              {columns2.map((item) => (
                <Panel
                  style={{ borderColor: "lavender" }}
                  header={
                    <li class="d-flex justify-content-between align-items-center">
                      <div>
                        {item.details.length == 0 ? (
                          <i
                            class="la la-hand-o-right text-primary"
                            style={{ paddingLeft: "5px", paddingRight: "5px" }}
                          />
                        ) : (
                          <></>
                        )}
                        <span class="fw-normal m-2">{item.title}</span>
                      </div>
                      {item.content}
                    </li>
                  }
                  key={item.title}
                  showArrow={item.details.length > 0}
                  collapsible={item.details.length > 0 ? "" : "icon"}
                >
                  {item.details.map((detail) => (
                    <li
                      class="d-flex justify-content-between align-items-center me-2"
                      style={{ paddingBottom: "10px", paddingLeft: "10px" }}
                    >
                      <div>
                        <i class="la la-circle text-primary me-2"></i>{" "}
                        <span>{detail.title}</span>
                      </div>
                      {detail.content}
                    </li>
                  ))}
                </Panel>
              ))}
            </Collapse>
          </Drawer>

          <Drawer
            maskClosable={false}
            title={"New FCB"}
            width={"70%"}
            placement="right"
            onClose={() => this.onDrawerClose()}
            visible={this.state.open}
            closeIcon={
              <button
                type="button"
                class="btn btn-soft-danger btn-icon-square-sm"
                style={{ marginRight: "8px" }}
              >
                <i class="mdi dripicons-cross"></i>
              </button>
            }
          >
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <Form ref={this.formRef}>
                      <div className="row">
                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            Make <x style={{ color: "red" }}>*</x>
                          </label>
                          <Form.Item
                            required
                            name="Is_active"
                            tooltip="This is a required field"
                          >
                            <Select
                              showSearch
                              onChange={this.onChangeMake}
                              style={{ width: "100%" }}
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.children
                                  .toLowerCase()
                                  .localeCompare(optionB.children.toLowerCase())
                              }
                            >
                              {this.props.fcdMasterData?.fcdMasterData?.fcdMakeList?.map(
                                (item, index) => {
                                  return (
                                    <Option value={item.id}>{item.name}</Option>
                                  );
                                }
                              )}
                            </Select>
                            <p style={{ color: "#f5325c" }}>
                              {this.validator.message(
                                "Make",
                                this.state.selectedMakeId,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            Model <x style={{ color: "red" }}>*</x>
                          </label>
                          <Form.Item
                            required
                            name="Is_active"
                            tooltip="This is a required field"
                          >
                            <Select
                              showSearch
                              onChange={this.onChangeModel}
                              style={{ width: "100%" }}
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.children
                                  .toLowerCase()
                                  .localeCompare(optionB.children.toLowerCase())
                              }
                            >
                              {this.props.fcdMasterData?.fcdMasterData?.fcdModelList?.map(
                                (item, index) => {
                                  return (
                                    <Option value={item.id}>{item.name}</Option>
                                  );
                                }
                              )}
                            </Select>
                            <p style={{ color: "#f5325c" }}>
                              {this.validator.message(
                                "Model",
                                this.state.selectedModelId,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>

                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            Serial Number <x style={{ color: "red" }}>*</x>
                          </label>
                          <Form.Item
                            required
                            name="cabinetSerialNumber"
                            tooltip="This is a required field"
                          >
                            <Input
                              id="cabinetSerialNumber"
                              onChange={this.handleFbcSerialNumber}
                            />
                            <p style={{ color: "#f5325c" }}>
                              {this.validator.message(
                                "Cabinet Serial Number",
                                this.state.serialNumber,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>

                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            Warranty Expire Date
                          </label>
                          <Form.Item
                            required
                            name="warrantyExpireDate"
                            tooltip="This is a required field"
                          >
                            <DatePicker
                              style={{ width: "100%" }}
                              onChange={this.onChangeWarrantyExpireDate}
                            />
                          </Form.Item>
                        </div>

                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            Installed Date
                          </label>
                          <Form.Item
                            required
                            name="installedDate"
                            tooltip="This is a required field"
                          >
                            <DatePicker
                              style={{ width: "100%" }}
                              onChange={this.onChangeInstalledDate}
                            />
                          </Form.Item>
                        </div>

                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            Last Maintainance Date
                          </label>
                          <Form.Item
                            required
                            name="LastMaintainanceDate"
                            tooltip="This is a required field"
                          >
                            <DatePicker
                              style={{ width: "100%" }}
                              onChange={this.onChangeLastMaintainanceDate}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Form>
                    <div className="row" style={{ justifyContent: "end" }}>
                      <div className="col-md-2">
                        {" "}
                        <button
                          className="btn btn-primary"
                          onClick={() => this.AllDataSubmit()}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Drawer>
          <Drawer
            maskClosable={false}
            title={"Edit FCB"}
            width={"70%"}
            placement="right"
            onClose={() => this.onDrawerClose()}
            visible={this.state.openEdit}
            closeIcon={
              <button
                type="button"
                class="btn btn-soft-danger btn-icon-square-sm"
                style={{ marginRight: "8px" }}
              >
                <i class="mdi dripicons-cross"></i>
              </button>
            }
          >
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <Form ref={this.formRef}>
                      <div className="row">
                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            Make <x style={{ color: "red" }}>*</x>
                          </label>
                          {this.state.selectedMakeId != null ? (
                            <Form.Item
                              required
                              name="Is_active"
                              tooltip="This is a required field"
                            >
                              <Select
                                showSearch
                                onChange={this.onChangeMake}
                                defaultValue={this.state.selectedMakeId}
                                // onChange={this.onChangeTowerType}

                                style={{ width: "100%" }}
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                              >
                                {this.props.fcdMasterData?.fcdMasterData?.fcdMakeList?.map(
                                  (item, index) => {
                                    return (
                                      <Option value={item.id}>
                                        {item.name}
                                      </Option>
                                    );
                                  }
                                )}
                              </Select>
                              <p style={{ color: "#f5325c" }}>
                                {this.validator.message(
                                  "Make",
                                  this.state.selectedMakeId,
                                  "required"
                                )}
                              </p>
                            </Form.Item>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            Model <x style={{ color: "red" }}>*</x>
                          </label>
                          {this.state.selectedModelId != null ? (
                            <Form.Item
                              required
                              name="Is_active"
                              tooltip="This is a required field"
                            >
                              <Select
                                showSearch
                                onChange={this.onChangeModel}
                                defaultValue={this.state.selectedModelId}
                                // onChange={this.onChangeTowerType}

                                style={{ width: "100%" }}
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                              >
                                {this.props.fcdMasterData?.fcdMasterData?.fcdModelList?.map(
                                  (item, index) => {
                                    return (
                                      <Option value={item.id}>
                                        {item.name}
                                      </Option>
                                    );
                                  }
                                )}
                              </Select>
                              <p style={{ color: "#f5325c" }}>
                                {this.validator.message(
                                  "Model",
                                  this.state.selectedModelId,
                                  "required"
                                )}
                              </p>
                            </Form.Item>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            Serial Number <x style={{ color: "red" }}>*</x>
                          </label>
                          <Form.Item required>
                            <Input
                              id="cabinetSerialNumber"
                              value={this.state.serialNumber}
                              onChange={this.handleFbcSerialNumber}
                            />
                            <p style={{ color: "#f5325c" }}>
                              {this.validator.message(
                                "Cabinet Serial Number",
                                this.state.serialNumber,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>

                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            Warranty Expire Date
                          </label>
                          {this.state.fbcwarrantyExpireDate != null ? (
                            <Form.Item>
                              <DatePicker
                                defaultValue={
                                  this.state.fbcwarrantyExpireDate != ""
                                    ? moment(this.state.fbcwarrantyExpireDate)
                                    : ""
                                }
                                style={{ width: "100%" }}
                                onChange={this.onChangeWarrantyExpireDate}
                              />
                            </Form.Item>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            Installed Date
                          </label>
                          {this.state.fbcInstalledDate != null ? (
                            <Form.Item>
                              <DatePicker
                                defaultValue={
                                  this.state.fbcInstalledDate != ""
                                    ? moment(this.state.fbcInstalledDate)
                                    : ""
                                }
                                style={{ width: "100%" }}
                                onChange={this.onChangeInstalledDate}
                              />
                            </Form.Item>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            Last Maintainance Date
                          </label>
                          {this.state.fbclastMaintainanceDate != null ? (
                            <Form.Item>
                              <DatePicker
                                defaultValue={
                                  this.state.fbclastMaintainanceDate != ""
                                    ? moment(this.state.fbclastMaintainanceDate)
                                    : ""
                                }
                                style={{ width: "100%" }}
                                onChange={this.onChangeLastMaintainanceDate}
                              />
                            </Form.Item>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </Form>
                    <div className="row" style={{ justifyContent: "end" }}>
                      <div className="col-md-2">
                        {" "}
                        <button
                          className="btn btn-primary"
                          onClick={() => this.AllDataSubmitUpdate()}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Drawer>
        </div>
      </>
    );
  }
}
export default Fdc;

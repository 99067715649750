import { InputNumber, message } from 'antd';
import React, { Component } from 'react'
import {updatePowerCapacity} from "../../api/index";

class PowerCapacityKw extends Component {
    constructor(props) {
        super(props);
        this.state = {
            switchStatus: true,
            objectSet: {
                "pcTotalCapacityAvailable":0,
                "pcUsedCapacityWithBatteryCharging":0,
                "pcRemainingCapacity":0
              }
        }
        this.handlepcTotalCapacityAvailable = this.handlepcTotalCapacityAvailable.bind(this);
        this.handlepcUsedCapacityWithBatteryCharging = this.handlepcUsedCapacityWithBatteryCharging.bind(this);
        this.handlepcRemainingCapacity = this.handlepcRemainingCapacity.bind(this);
    }
    handlepcTotalCapacityAvailable(e) {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                pcTotalCapacityAvailable: e
            }

        })
    }
    handlepcUsedCapacityWithBatteryCharging(e) {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                pcUsedCapacityWithBatteryCharging: e
            }

        })
    }
    handlepcRemainingCapacity(e) {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                pcRemainingCapacity: e
            }

        })
    }
    componentDidMount() {
        console.log(this.props.details)
        console.log('powerCon')
        this.setState({
            objectSet: {
                "pcTotalCapacityAvailable": this.props.details.access_technologies_data?.pcTotalCapacityAvailable,
                "pcUsedCapacityWithBatteryCharging": this.props.details.access_technologies_data?.pcUsedCapacityWithBatteryCharging,
                "pcRemainingCapacity": this.props.details.access_technologies_data?.pcRemainingCapacity

            }

        }, () => {
            console.log("opopop")
            console.log(this.state.objectSet)
        })
    }
    dataSetToUpdate = async () => {

        var res = await updatePowerCapacity(this.props.details2.site_details.id, this.state.objectSet);

        if(res.success){
           message.success('Successfully Update!')
           this.props.parentMethod();
           this.setState({
             switchStatus: true
         })
        }else{
         message.warning(' Something went wrong!')
        }

    }
    dataUpdate() {
        console.log("ssss")
        this.setState({
            switchStatus: false
        })
    }
    close = async () =>{
        this.props.parentMethod();
        this.setState({
            objectSet: {
                "pcTotalCapacityAvailable": this.props.details.access_technologies_data?.pcTotalCapacityAvailable,
                "pcUsedCapacityWithBatteryCharging": this.props.details.access_technologies_data?.pcUsedCapacityWithBatteryCharging,
                "pcRemainingCapacity": this.props.details.access_technologies_data?.pcRemainingCapacity
            }
        });
        this.setState({
            switchStatus: true
        });
    }
    render() {
        const { details } = this.props.details
        return (
            <>
                {this.props.details.access_technologies_data !=null ? (
                    <div className='row'>
                        <div className='col-md-6'>
                            <div class="card">
                                <div class="card-header">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <h4 class="card-title">Information</h4>
                                        {/* {this.state.switchStatus
                                            ?   <button type="button" class="btn btn-soft-primary btn-icon-square-sm" onClick={() => this.dataUpdate()}> <i class="mdi mdi-pencil-outline"></i></button>
                                            :   <div class="d-flex justify-content-end align-items-center">
                                                    <button type="button" class="btn btn-soft-secondary " onClick={() => this.close()} style={{marginRight:"10px"}}> Cancel </button>    
                                                    <button type="button" class="btn btn-soft-primary " onClick={() => this.dataSetToUpdate()}> Submit to Update </button>
                                                </div>
                                        } */}
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        
                                            <ul class="list-group">
                                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                                    <div>
                                                    <i class="la la-hand-o-right text-primary me-2"></i> Total Capacity Available (kW) 
                                                    </div>
                                                    {this.state.switchStatus ? this.props.details.access_technologies_data?.pcTotalCapacityAvailable ? ( <span class="badge badge-outline-success">{this.props.details.access_technologies_data.pcTotalCapacityAvailable.toFixed(2)}</span>):( <span class="badge badge-outline-warning">{this.props.details.access_technologies_data.pcTotalCapacityAvailable.toFixed(2)}</span>) : <InputNumber style={{ width: '20%' }} value={this.state.objectSet?.pcTotalCapacityAvailable} id="mpcNoOfPhases" onChange={this.handlepcTotalCapacityAvailable} />}
                                                   
                                                </li>
                                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                                    <div>
                                                    <i class="la la-hand-o-right text-primary me-2"></i> Used Capacity with Battery Charging (10%)
                                                    </div>
                                                    {this.state.switchStatus ? this.props.details.access_technologies_data?.pcUsedCapacityWithBatteryCharging ? ( <span class="badge badge-outline-success">{this.props.details.access_technologies_data.pcUsedCapacityWithBatteryCharging.toFixed(2)}</span>):( <span class="badge badge-outline-warning">{this.props.details.access_technologies_data.pcUsedCapacityWithBatteryCharging.toFixed(2)}</span>) : <span class="badge badge-outline-success">{this.props.details.access_technologies_data.pcUsedCapacityWithBatteryCharging.toFixed(2)}</span>}

                                                </li>
                                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                                    <div>
                                                    <i class="la la-hand-o-right text-primary me-2"></i>  Remaining Capacity (kW)
                                                    </div>
                                                    {this.state.switchStatus ? this.props.details.access_technologies_data?.pcRemainingCapacity ? ( <span class="badge badge-outline-success">{this.props.details.access_technologies_data.pcRemainingCapacity.toFixed(2)}</span>):( <span class="badge badge-outline-warning">{this.props.details.access_technologies_data.pcRemainingCapacity.toFixed(2)}</span>) : <span class="badge badge-outline-success">{this.props.details.access_technologies_data.pcRemainingCapacity.toFixed(2)}</span>}

                                                </li>
                                              
                                            </ul>
                                     
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : ( <div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <h4 style={{ color: "#c5c5c5" }}>No Dat Found Power Capacity</h4></div>)}

            </>
        )
    }
}
export default PowerCapacityKw;

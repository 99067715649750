import React, { Component } from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Bar } from 'react-chartjs-2';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

class StackedBarLine extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: {
                labels:[],
                datasets: [
                    
                 
                ],
              },
              options :{
                type: 'bar',    
           
                options: {
                    
                    interaction: {
                      intersect: false,
                      mode: 'index',
                    },
                    plugins: {
                      title: {
                        display: true,
                        text: (ctx) => 'Tooltip position mode: ' + ctx.chart.options.plugins.tooltip.position,
                      },
                    }
                  },
                responsive: true,
                plugins: {
                    legend: {
                      labels: {
                      //ntStyle: true,
                        boxWidth: 12,
                        boxHeight:5
                        
                      },
                    }
                  },
                scales: {
                  x: {
                    stacked: true,
                    ticks: {
                      autoSkip: true,
                      maxRotation:0,
                      minRotation:0,
                      maxTicksLimit: 5
                    }
                  },
                  y: {
                    stacked: true,
                    position: 'left',
                    title: {
                        display: true,
                        text:'First Line',
                        color: '#8997bd',
                        font: {
                            family:'Roboto',
                            size: 11,
                            weight: 'bold',
                            lineHeight: 1.2,
                          },
                      }
                  },
                  y2: {
                    stacked: true,
                    position: 'right',
                    color: '#8997bd',
            
                    title: {
                        display: true,
                        text:'Second Line',
                        color: '#8997bd',
                        font: {
                            family:'Roboto',
                            size: 11,
                            weight: 'bold',
                            lineHeight: 1.2,
                          },
            
                      }
                  },
                },
              
              },


            
        };
    }
    componentDidMount() { 
        console.log('ccccc1236')
        console.log(this.props)
        this.setState({
            data: {
                labels:this.props.x1,
                datasets: this.props.y1,
              },
              options :{
                type: 'bar',
                horizontal: false,    
           
                options: {
                    
                    interaction: {
                      intersect: false,
                      mode: 'index',
                    },
                    plugins: {
                      title: {
                        display: true,
                        text: (ctx) => 'Tooltip position mode: ' + ctx.chart.options.plugins.tooltip.position,
                      },
                    }
                  },
                responsive: true,
                plugins: {
                    legend: {
                      labels: {
                      //ntStyle: true,
                        boxWidth: 12,
                        boxHeight:5
                        
                      },
                    }
                  },
                scales: {
                  x: {
                    stacked: true,
                    ticks: {
                      autoSkip: true,
                      maxRotation:0,
                      minRotation:0
                    }
                  },
                  y: {
                    stacked: true,
                    position: 'left',
                    title: {
                        display: true,
                        text:this.props.y1Lable,
                        color: '#8997bd',
                        font: {
                            family:'Roboto',
                            size: 11,
                            weight: 'bold',
                            lineHeight: 1.2,
                          },
            
                      }
                  },
                  y2: {
                    stacked: true,
                    position: 'right',
                    color: '#8997bd',
            
                    title: {
                        display: true,
                        text:this.props.y2Lable,
                        color: '#8997bd',
                        font: {
                            family:'Roboto',
                            size: 11,
                            weight: 'bold',
                            lineHeight: 1.2,
                          },
            
                      }
                  },
                },
              
              },
        })


    }

    render() {
        return (
            <div id="chart">
               <Bar data={this.state.data} height={170}  options={this.state.options} />
            </div>
        )
    }
}

export default StackedBarLine;

import React, { Component } from "react";
import { connect } from "react-redux";
import { Table, Input, Tag, Space } from "antd";
import {
  RightOutlined,
  DownOutlined,
  BorderOuterOutlined,
  TeamOutlined,
  AppstoreOutlined,
  DeploymentUnitOutlined,
  ClusterOutlined,
} from "@ant-design/icons";



class UserWisePgCount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      filterTable: null,
    };
  }

  componentDidMount() {
    //console.log(this.props.details);
  }

  render() {
  
    const { filterTable } = this.state;
    const columns = [
      // {
      //   title: "#",
      //   dataIndex: "id",
      //   defaultSortOrder: "ascend",
      //   sorter: (a, b) => a.id - b.id,
      //   sortDirections: ["descend"],
      //   render: (text, record, index) => <span>{text}</span>,
      // },
      {
        title: "Name",
        dataIndex: "siteId",
        key: "siteId",
        //sorter: (a, b) => a.name.localeCompare(b.name),
        render: (item) => <span>{item}</span>,
      },
      {
        title: "No. of PG Requests",
        dataIndex: "siteName",
        key: "siteName",
        //sorter: (a, b) => a.name.localeCompare(b.name),
        render: (item) => <span>{item}</span>,
      },
      
      
    ];
    return (
      <div className="row">
      <div className="col-lg-12 col-xl-12">
        <div className="card">
          <div className="card-header">
            <div className="row ">
              <div className="col">
               
                  <h4 className="card-title">
                    Report - User wise PG count   <button class="btn btn-primary" style={{float:'right'}}><i class="fas fa-file-export"> </i> Export</button>
                  </h4>
               
               
              </div>
            </div>
          </div>
          <div className="card-body">
            <Table
              size="small"
              columns={columns}
              rowKey="id"
              dataSource={
                filterTable == null ? this.props.details : filterTable
              }
            />
          </div>
        </div>
      </div>
   
    </div>
    );
  }
}

export default UserWisePgCount;

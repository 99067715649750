import React, { Component } from "react";
import { connect } from "react-redux";
import { List, Typography, Divider } from "antd";

class ReportIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const data = [
      { name: "Region wise users", href: "/report/region-wise-users" },
      {
        name: "Region wise Vendor Teams",
        href: "/report/region-wise-vendor-teams",
      },
      { name: "User wise PG", href: "/report/region-wise-pg-count" },
      { name: "Region wise site list", href: "/report/region-wise-site-list" },
      {
        name: "Region wise depot list",
        href: "/report/region-wise-depot-list",
      },
      { name: "Statistic Data", href: "/report/statistic-data" },
      // { name: " PG Full Export", href: "/report/full-export" },
      // { name: " Refuel Full Export", href: "/refuel-full-export" },
      { name: "Request Report", href: "/report/request-report" },
    ];
    return (
      <div class="container-fluid">
        <div class="row">
              <div class="col-sm-12">
                <div class="page-title-box">
                  <div class="row">
                    <div class="col">
                      <h4 class="page-title">Reports</h4>
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Data Export </a></li>
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Reports</a></li>

                      </ol>
                    </div>
                    <div class="col-auto align-self-center">
                      <a href="#" class="btn btn-sm btn-outline-primary" id="Dash_Date">
                        <span class="day-name" id="Day_Name">Today:</span>&nbsp;
                        <span class="" id="Select_date">{new Date().toUTCString().slice(5, 16)}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar align-self-center icon-xs ms-1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                      </a>

                    </div>
                  </div>
                </div>
              </div>
            </div>
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <div className="card">
             
              <div className="card-body">
                <ul className="nav nav-tabs" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      data-bs-toggle="tab"
                      href="#home"
                      role="tab"
                      aria-selected="true"
                    >
                      General
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-bs-toggle="tab"
                      href="#profile"
                      role="tab"
                      aria-selected="false"
                    >
                      Analytics
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-bs-toggle="tab"
                      href="#settings"
                      role="tab"
                      aria-selected="false"
                    >
                      
                      Financial
                    </a>
                  </li>
                </ul>

                <div className="tab-content">
                  <div className="tab-pane active" id="home" role="tabpanel">
                    <Divider orientation="left">Section</Divider>
                    <List
                      //   header={<div>Header</div>}
                      //   footer={<div>Footer</div>}
                      size="small"
                      bordered
                      dataSource={data}
                      renderItem={(item) => (
                        <List.Item size="small">
                          {/* <Typography.Text mark>po</Typography.Text>  */}
                          {/* {item.name} */}
                          <a href={item.href}> {item.name}</a>
                        </List.Item>
                      )}
                    />
                  
                   
                  </div>
                  <div className="tab-pane p-3" id="profile" role="tabpanel">
                    <p className="mb-0 text-muted"></p>
                  </div>
                  <div className="tab-pane p-3" id="settings" role="tabpanel">
                    <p className="text-muted mb-0"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ReportIndex;

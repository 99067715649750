import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { createNewDepot, getAllSubRegionsDepotCreate, UpdateDepotByGivenID, removeDepotDataAccessFunction } from '../../app/api/index'
import { connect } from "react-redux";
import { addDepot, getDonationCategories, getData } from '../../app/actions/index';
import { ToastContainer, toast } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';
import { Collapse, Select, Button, Tag, message, Row, Col, Form, Modal, Input, Result, Table, Drawer } from 'antd';
import { ExclamationCircleOutlined, DeleteOutlined, EditOutlined, PlusCircleFilled, MinusCircleOutlined } from '@ant-design/icons';
import './master-data/style.css';
import { isEdge } from 'react-flow-renderer';
var page_count = 0;
class Depot extends Component {

	constructor(props) {
		super(props)
		this.validator = new SimpleReactValidator();
		this.state = {
			name: "",
			description: "",
			unitTypeId: '',
			region: '',
			page_start: 0,
			page_end: 10,
			regionList: [],
			filterTable: null,
			depotList: [],
			editDepotDetails: {
				id: '',
				name: '',
				description: '',
				unitTypeId: 0,
				region: 0,
			},
			openPopModal: false,
			isEdit: false,
		}
	}

	componentDidMount() {
		this.props.getData();
		this.setState({
			depotList: this.props.depots
		}, () => {
			console.log(this.state.depotList)
		})
		this.getAllSubRegionsDepotCreate()
	}

	getAllSubRegionsDepotCreate = async () => {
		var res = await getAllSubRegionsDepotCreate();
		console.log(this.props.region)
		if (res.success) {
			this.setState({
				regionList: this.props.region
			}, () => {
				console.log(this.state.regionList)
			})

		}
	}

	// Function For Create New Depot And Save In Database
	createDepot = async () => {
		if (this.validator.allValid()) {
			var data = {
				"name": this.state.name,
				"description": this.state.description,
				"unitTypeId": this.state.unitTypeId,
				"region": this.state.region
			}
			var res = await createNewDepot(data);
			if (res.success) {
				this.props.getData();

				toast.success("Successfully Created New Depot");
				this.setState({
					name: "",
					description: "",
					unitTypeId: '',
					region: [],
					openPopModal: false,
				}, () => {

				})
			}
		} else {
			this.validator.showMessages();
			toast.error("Please Fill All Fields");
			this.forceUpdate();
		}
	}

	// Function For Edit Depot and Save In Database
	updatedepot = async () => {
		if (this.state.editDepotDetails.name !== null && this.state.editDepotDetails.description !== null && this.state.editDepotDetails.region !== null) {
			var res = await UpdateDepotByGivenID(this.state.editDepotDetails, this.state.editDepotDetails.id);
			if (res.success) {
				this.closeEditDrawer();
				this.props.getData();
				this.setState({
					openPopModal: false,
					isEdit: false
				});
				toast.success('Depot updated successfully');
			}
		} else {
			toast.error('Failed to update Depot');
			this.validator.showMessages();
			this.forceUpdate();
		}
	};

	// Popup For create Depot and Save In Database and Update In Table
	showCreateConfirmationModal = () => {
		Modal.confirm({
			title: 'Confirm',
			icon: <ExclamationCircleOutlined />,
			content: 'Do you want to register a new Depot?',
			okText: 'Yes',
			cancelText: 'No',
			onOk: () => this.createDepot(),
		});
	};

	showUpdateConfirmationModal = () => {
		Modal.confirm({
			title: 'Confirm',
			icon: <ExclamationCircleOutlined />,
			content: 'Do you want to update this Depot?',
			okText: 'Yes',
			cancelText: 'No',
			onOk: () => this.updatedepot(),
		});
	};

	showDeleteConfirm = (record) => {
		const { confirm } = Modal;
		confirm({
			title: 'Do you want to deactivate this Depot?',
			onOk: async () => {
				this.deleteDepotFromId(record.id);
			},
			onCancel() { },
		});
	};

	deleteDepotFromId = async (id) => {
		const formData = new FormData();
		formData.append('depotId', id);
		var res = await removeDepotDataAccessFunction(formData);
		console.log(res);
		if (res.success) {
			toast.success('Depot deactivate successfully');
			this.props.getData();
		} else {
			toast.error(res?.data);
		}
	}


	// Implement Search Functionality to the Table
	search = (value) => {
		const filterTable = this.state.depotList.filter((o) =>
			Object.keys(o).some((k) =>
				String(o[k]).toLowerCase().includes(value.toLowerCase())
			)
		);
		this.setState({ filterTable });
	};

	formItemChange = (event) => {
		console.log(event.target.value)
		var obj = {};
		obj[event.target.name] = event.target.value;
		this.setState(obj);
	}

	getList = () => {
		var pagination_list = [];
		page_count = this.props.depots.length / 10;
		for (var i = 0; i < page_count; i++) {
			pagination_list.push(<li style={{ cursor: "pointer" }} class="page-item" id={i} onClick={(e) => this.gotoPage(e)}><a class="page-link" >{i + 1}</a></li>)
		}
		return pagination_list;
	}

	gotoPage = (e) => {
		var _page_end = e.target.outerText * 10;
		var _page_start = _page_end - 10;
		this.setState({
			page_start: _page_start,
			page_end: _page_end
		})
	}


	showEditDrawer = (record) => {
		console.log(record)
		this.setState({
			editDrawerVisible: true,
			name: record.name,
			description: record.description,
			unitTypeId: record.unitTypeId,
			region: record.region,
			editDepotDetails: {
				id: record.id,
				name: record.name,
				description: record.description,
				unitTypeId: record.unitTypeId,
				region: record.region,
			}
		});
	};

	closeEditDrawer = () => {
		this.setState({
			editDrawerVisible: false,
			name: "",
			description: "",
			unitTypeId: 0,
			region: 0,
			editDepotDetails: {
				id: '',
				name: '',
				description: '',
				unitTypeId: 0,
				region: 0,
			}
		});
	};

	editFormItemChange = (event) => {
		const { name, value } = event.target;
		this.setState((prevState) => ({
			editDepotDetails: {
				...prevState.editDepotDetails,
				[name]: value,
			},
		}));

		var obj = {};
		obj[event.target.name] = event.target.value;
		this.setState(obj);
	};

	render() {
		const columns = [
			{
				title: <span className='textStyles-small'>Depot Name</span>,
				dataIndex: 'name',
				key: 'name',
				render: (text) => (
					<span className='textStyles-small'>{text === ' ' ? <i>No Depot Name</i> : text}</span>
				),
			},
			{
				title: <span className='textStyles-small'>Description</span>,
				dataIndex: 'description',
				key: 'description',
				render: (text) => (
					<span className='textStyles-small'>{text === ' ' ? <i>No Description For Depot</i> : text}</span>
				),
			},
			{
				title: <class className='textStyles-small'>Region</class>,
				dataIndex: 'region',
				key: 'region',
				render: (text) => (
					<span className='textStyles-small'>{text === ' ' ? <i>No Region Found</i> : text.name}</span>
				),
			},
			{
				title: <span className='textStyles-small'>Action</span>,
				key: 'action',
				render: (text, record, index) => (
					<span className="text-end">
						<Button
							shape='circle'
							size='small'
							type='primary'
							icon={<EditOutlined style={{ fontSize: '14px' }} />}
							style={{ marginLeft: '10px' }}
							onClick={() => {
								this.showEditDrawer(record);
								this.setState({
									isEdit: true,
									openPopModal: true
								});
							}}
						/>
						<Button
							shape='circle'
							size='small'
							type='primary'
							danger
							icon={<MinusCircleOutlined style={{ fontSize: '14px' }} />}
							style={{ marginLeft: '10px' }}
							onClick={() => this.showDeleteConfirm(record)}
						/>
					</span>
				),
			},
		];

		return (
			<div class="container-fluid" >
				<div class="row">
					<div class="col-sm-12">
						<div class="page-title-box">
							<div class="row">
								<div class="col">
									<h4 class="page-title">Depots</h4>
									<ol class="breadcrumb">
										<li class="breadcrumb-item"><a href="javascript:void(0);">Admin</a></li>
										<li class="breadcrumb-item"><a href="javascript:void(0);">Depots</a></li>

									</ol>
								</div>
								<div class="col-auto align-self-center">


								</div>
							</div>
						</div>
					</div>
				</div>
				<div>
					<div style={{ margin: '0', padding: '10px', width: '100%', backgroundColor: 'white', borderRadius: '10px', marginTop: '20px' }}>
						<Row>
							<Col span={12}>
								<Button type="primary" onClick={() => this.setState({ openPopModal: true, isEdit: false })}
									style={{ background: "#38B36E", borderColor: "#38B36E", borderRadius: '5px', margin: 10 }}><PlusCircleFilled />
									<span className="textStyles-small">Create Depot</span></Button>
							</Col>
							<Col span={12} style={{ textAlign: 'right' }}>
								<Input
									enterButton
									className='borderedSelect' style={{ height: 33, margin: 10, marginRight: 10, width: 350 }}
									// style={{ width: '98%', margin: 10 }}
									placeholder="Search"
									onChange={(e) => {
										const currValue = e.target.value;
										this.search(currValue);
									}}
								/>
							</Col>
						</Row>

						<Table
							size="small"
							className="table-striped-rows"
							style={{ marginTop: "20px" }}
							columns={columns}
							dataSource={
								this.state.filterTable == null
									? [...this.props.depots].sort((a, b) => b.id - a.id)
									: [...this.state.filterTable].sort((a, b) => b.id - a.id)
							}
							rowKey="id"
						/>
					</div>

					<Modal
						title={this.state.isEdit ? <span
							style={{ fontSize: '14px', fontWeight: 'bold' }}
							className='textStyles-small'>Edit Depot</span> :
							<span
								style={{ fontSize: '14px' }}
								className='textStyles-small'>Create Depot</span>}
						visible={this.state.openPopModal}
						onOk={() => {
							this.setState({
								openPopModal: false,
								isEdit: false,
							});
							this.closeEditDrawer();
						}}
						onCancel={() => {
							this.setState({
								openPopModal: false,
								isEdit: false,
							});
							this.closeEditDrawer();
						}}
						footer={null}
					>
						<>
							{
								this.state.isEdit ? (
									<div>
										<form>
											<div className="mb-3">
												<label className="form-label" htmlFor="editName">
													<span className='textStyles-small'>Edit Depot Name</span>
												</label>
												<input
													autoComplete="off" type="text"
													className='borderedSelect'
													style={{ height: 34, width: '100%' }}
													name="name" id="name" value={this.state.name} aria-describedby="emailHelp"
													onChange={this.editFormItemChange}
												/>
												<p style={{ color: '#f5325c' }}>
													{this.validator.message(
														'editName',
														this.state.name,
														'required'
													)}
												</p>
											</div>

											<div className="mb-3">
												<label className="form-label" htmlFor="editDescription">
													<span className='textStyles-small'>Edit Description</span>
												</label>
												<input
													autoComplete="off" type="text" value={this.state.description}
													name="description" id="description"
													className='borderedSelect'
													style={{ height: 34, width: '100%' }}
													onChange={this.editFormItemChange}
												/>
												<p style={{ color: '#f5325c' }}>
													{this.validator.message(
														'editDescription',
														this.state.description,
														'required'
													)}
												</p>
											</div>

											{/* <div className="mb-3">
												<label className="form-label" htmlFor="editUnitType">
													Edit Unit Type
												</label>
												<div className="col-sm-12">
													<select className="select2 form-control mb-3 custom-select"
														name="unitTypeId" onChange={(e) => this.editFormItemChange(e)}
														style={{ width: '100%', height: '36px' }}
														value={this.state.unitTypeId}
													>
														<option value="1">1</option>
														<option value="2">2</option>
														<option value="3">3</option>
													</select>
												</div>
												<p style={{ color: '#f5325c' }}>
													{this.validator.message(
														'editUnitType',
														this.state.unitTypeId,
														'required'
													)}
												</p>
											</div> */}

											<div className="mb-3">
												<label className="form-label" htmlFor="editRegion">
													<span className='textStyles-small'>Edit Region</span>
												</label>
												{this.state.region != '' ?
													<select
														// className="select2 form-control mb-3 custom-select" 
														className='borderedSelect'
														bordered={false}
														value={this.state.region.id} name="region" onChange={(e) => this.editFormItemChange(e)} style={{ width: '100%', height: '36px' }}>
														{
															this.state.regionList.map((region, i) => {
																return <option value={region.id}>{region.name}</option>
															})
														}
													</select> : ''}
												{this.state.region == '' ?
													<select
														// className="select2 form-control mb-3 custom-select" 
														className='borderedSelect'
														bordered={false}
														value={this.state.region.id} name="region" onChange={(e) => this.editFormItemChange(e)} style={{ width: '100%', height: '36px' }}>
														{
															this.state.regionList.map((region, i) => {
																return <option value={region.id}>{region.name}</option>
															})
														}
													</select> : ''}
												<p style={{ color: '#f5325c' }}>
													{this.validator.message(
														'editRegion',
														this.state.region,
														'required'
													)}
												</p>
											</div>
										</form>
										<Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
											<button
												className="btn btn-success"
												onClick={this.showUpdateConfirmationModal}>
												<span className="textStyles-small">Update Depot</span>
											</button>
										</Row>
									</div>
								) : (
									<div>
										<form>
											<div className="mb-3">
												<label className="form-label" for="name"><span className='textStyles-small'>Depot Name</span></label>
												<input
													className='borderedSelect'
													style={{ height: 34, width: '100%' }}
													autoComplete="off" type="text"
													name="name" id="name" value={this.state.name} aria-describedby="emailHelp"
													onChange={(e) => this.formItemChange(e)} />
												<p style={{ color: "#f5325c" }}>{this.validator.message('name', this.state.name, 'required')}</p>
											</div>
											<div className="mb-3">
												<label className="form-label" for="description"><span className='textStyles-small'>Description</span></label>
												<input autoComplete="off" type="text" value={this.state.description} className='borderedSelect' style={{ height: 34, width: '100%' }}
													name="description" id="description" onChange={(e) => this.formItemChange(e)} />
												<p style={{ color: "#f5325c" }}>{this.validator.message('description', this.state.description, 'required')}</p>
											</div>
											{/* 
											<div className="mb-3">
												<label className="form-label" for="exampleInputPassword1">Unit Type</label>
												<div className="col-sm-12">
													<select className="select2 form-control mb-3 custom-select" name="unitTypeId" onChange={(e) => this.formItemChange(e)} style={{ width: '100%', height: '36px' }}>
														<option value=""> </option>
														<option value="1">1</option>
														<option value="2">2</option>
														<option value="3">3</option>

													</select>
													<p style={{ color: '#f5325c' }}>
														{this.validator.message(
															'editUnitType',
															this.state.unitTypeId,
															'required'
														)}
													</p>
												</div>
											</div> */}
											<div className="mb-3">
												<label className="form-label" for="exampleInputPassword1"><span className='textStyles-small'>Region</span></label>
												<div className="col-sm-12">
													<select
														// className="select2 form-control mb-3 custom-select" 
														className='borderedSelect'
														bordered={false}
														name="region" onChange={(e) => this.formItemChange(e)} style={{ width: '100%', height: '36px' }}>
														<option value={''}>{ }</option>
														{
															this.state.regionList.map((region, i) => {
																return <option value={region.id}>{region.name}</option>
															})
														}
													</select>
													<p style={{ color: '#f5325c' }}>
														{this.validator.message(
															'editRegion',
															this.state.region,
															'required'
														)}
													</p>
												</div>
											</div>
										</form>
										<Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
											<button type="submit" className="btn btn-primary"
												style={{ background: "#38B36E", borderColor: "#38B36E", borderRadius: '5px' }}
												onClick={() => this.showCreateConfirmationModal()}>
												<span className="textStyles-small">Create Depot</span>
											</button>
										</Row>
									</div>
								)
							}
						</>
					</Modal>
				</div >
				<ToastContainer position="top-right" autoClose={5000} />
			</div >
		);
	}
}

/*function mapDispatchToProps(dispatch) {
	return {
		addDepot: data => dispatch(addDepot(data))
	};
}*/

function mapStateToProps(state) {
	return {
		region: state.regions,
		depots: state.depots
	};
}

/*export default connect(
	mapStateToProps, mapDispatchToProps
)(Depot);

*/
export default connect(mapStateToProps, { getDonationCategories, getData })(
	Depot
);
import React from 'react';
import { Select,DatePicker } from 'antd';
import { connect } from "react-redux";
import { getDonationCategories, getData } from "../actions/index";


import FullMap from "../components/vector-map";
import { Card, VehicleCard, VehicaleCell, LocationContainer,LocationContainer2 } from "../components/cards";
import { DataContext, withContext } from "../context"
import { TickClock, StillClock }from "../components/clocks";
import { CardContainer } from "../components/essentials/containers";
import { Flex, Box } from "rebass"
import { FiToggleLeft, FiToggleRight, FiMapPin, FiNavigation,FiCalendar,FiActivity,FiWatch } from 'react-icons/fi';
import { pgByDepotsList } from "../api/index";

import { Loading } from "../components/essentials/alerts"
import { LoginModal } from "../components/essentials/modals"
import _ from "lodash";
 import './all-pg-live.css';   
import { Collapse  } from 'reactstrap';
import markers from "../../markers.config"
import "../components/cards/style.css";
import Moment from 'moment';
// import { WithContext as ReactTags } from 'react-tag-input';
import moment from "moment";



const { Option } = Select;

var colors =['#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6','#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D','#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A','#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC','#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC','#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399','#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680', '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933','#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3','#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'];
const KeyCodes = {
  comma: 188,
  enter: [10, 13],
};
const delimiters = [...KeyCodes.enter, KeyCodes.comma];
class DashboardAllPgLive extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      curTime:"",
      data: [],
      depot_list: [],
      SubRegion_list: [],

      isLight: true,
      assignedDockCount : "",
      dockCount : "",
      isOpen : false,
      filterDate:"",
      filterEndDate:"",
      filterToday:"",
      filterRegion:[],
      filterDepot:[],
      filterSubRegion:[],

      regionName:"",
      DepotName:"",
      headerName:"",
      stats: {
        total_req:0,
        delayed_allo:0  
      },
      tags: [
        
     ],
     suggestions: [
      { id:"8", text: 'Installed', color:'#00ffbf'},
      { id:"14", text: 'DO Verified' },
      { id:"20", text: 'Cancellation Request By Vendor' },
      { id:"6", text: 'Dispatch',color:'#00ffbf' },
      { id:"3", text: 'Initiated' },
      { id:"9", text: 'Installation Confirmed' },
      { id:"17", text: 'DO Request Cancellation' },
      { id:"13", text: 'Removed' },
      { id:"7", text: 'Arrival',color:'#00ffbf' },
      { id:"5", text: 'Team Assign' },
      { id:"21", text: 'DO Approved Cancellation' },
      { id:"16", text: 'Completed' },
      { id:"4", text: 'Approved' },
      { id:"18", text: 'Penalty Exclusion' },
      { id:"22", text: 'OM Rejected' },
      { id:"15", text: 'OM Verified' },
      { id:"11", text: 'Removal Requested By DO' },
      { id:"10", text: 'Removal Requested By Vendor'},
      { id:"12", text: 'Remove Request Confirm By DO'},
      { id:"19", text: 'Completed'},
      { id:"23", text: 'DO Reject Cancellation'},
      { id:"24", text: 'Sync Installation Details'},
      { id:"25", text: 'Sync Remove Details'},
      { id:"26", text: 'PG_SYNC_ARRIVED_DETAILS'},
      { id:"27", text: 'Installation Rejected by DO'},
      { id:"28", text: 'Requested to Reinstall PG'},
      { id:"29", text: 'Requested to Remove Correctly'},
      { id:"30", text: 'Removal Rejected by OM'},
      { id:"31", text: 'Rejected'},
      
   ]
    }
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
  }

  componentDidMount(){
  
    this.setState({
        depot_list: this.props.depots,
        SubRegion_list: this.props.regions.filter((item)=>{
          return item.isSubRegion==1;
        }),
      });
      this.filterBtnClick();

      this.interval = setInterval(() => this.filterBtnClick(), 30000) //30000
  }
  componentWillUnmount(){
    clearInterval(this.interval)
  }
  counter({data}){
    let total_req = 0;
    let delayed_allo = 0;
    let countpg = 0;

    //console.log("dayee");
    //console.log(data);
    
   
    
   total_req=data.length;
   data.map((data)=>{
     if(data.status=="Completed"){
       ++countpg;

     }

   });
   delayed_allo=countpg;
    return { 
      total_req,
      delayed_allo
    }
  }
  createDataWithPins({data}){
    const arrayResult = Object.keys(data).map(room => {
      return {name:data[room].siteName, data: [data[room]]} 
  });
    // data.data.final_card_data,
    return arrayResult.map(e => ({
      ...e,
      ..._.find(markers, { name: e.name })
    }));
  }
  viewFilterClick = () =>{
    this.setState({
      isOpen : !this.state.isOpen
    })
  }
  filterBtnClick = async() =>{
    //console.log("this.props");
    //console.log(this.props);
    var form = new FormData();
    var myCurrentDate = new Date();
    var date = myCurrentDate.getFullYear() + '-' + (myCurrentDate.getMonth()+1) + '-' + myCurrentDate.getDate();
  
    //console.log(date)
    if(this.state.filterDate=="" && this.state.filterEndDate==""){
      form.append("depotIds",this.state.filterDepot);
      form.append("RegionIds",this.state.filterRegion);
      form.append("RequestDateTo",date);
      form.append("RequestDateFrom",date);
      this.setState({
        filterDate:date,
        filterEndDate:date
      })

     }else{
      if(this.state.filterDepot.length>0){
        //console.log("filterDepot")
        //console.log(this.state.filterRegion)
        //console.log(this.state.filterDepot)
        
        form.append("depotIds",this.state.filterDepot);
       
        form.append("RequestDateTo",this.state.filterEndDate);
        form.append("RequestDateFrom",this.state.filterDate);
      }else{
        //console.log("filterDepotNo")
        //console.log(this.state.filterRegion)
        //console.log(this.state.filterDepot)
        if(this.state.filterSubRegion==""){
          var getall=this.props.regions.filter(alldate=>this.state.filterRegion.includes(alldate.parentRegion?.id))
          var output = [];
          for (var i=0; i < getall.length ; ++i)
              output.push(getall[i]['id']);

              //sthis.state.filterSubRegion=output;
          
              form.append("RequestDateTo",this.state.filterEndDate);
              form.append("RequestDateFrom",this.state.filterDate);
              form.append("RegionIds",output);
      
        }else{
          form.append("RequestDateTo",this.state.filterEndDate);
          form.append("RequestDateFrom",this.state.filterDate);
          form.append("RegionIds",this.state.filterSubRegion);

        }
       

      }

     }

    

    //console.log(form);

    var res = await pgByDepotsList(form);  
    ////console.log(res);

    if(res.success){

      //console.log("dsssssssssssssssssssss");
      //console.log(res);
      //console.log(this.createDataWithPins(res));
 
      this.setState({
     
        curTime : new Date(this.state.filterDate).toDateString(),
        headerName:this.state.regionName || this.state.DepotName?(this.state.regionName+' / '+this.state.DepotName):("All Sites"),
               stats: this.counter(res),
               data: this.createDataWithPins(res),
             //  pins: this.getPins(this.state.full_data_set),
               isLoading: false
             })
    } 
  }
  
  onChangeDate = (date, dateString) => {
    //console.log(date);
    //console.log(dateString);
    this.setState({
      filterDate: dateString,
    });
  };
  onChangeEndDate = (date, dateString) => {
    //console.log(date);
    //console.log(dateString);
    this.setState({
      filterEndDate: dateString,
    });
  };
  ArraySortByDown = () => {

    //console.log()
    this.setState({
      data:this.state.data.sort((a, b)=>b.name.localeCompare(a.name) )
    });
  };
 
  StatusSortByUp = () => {

    //console.log()
    this.setState({
      data:this.state.data.sort((a, b)=>a.data[0].status.localeCompare(b.data[0].status) )
    });
  };
  StatusSortByDown = () => {

    //console.log()
    this.setState({
      data:this.state.data.sort((a, b)=>b.data[0].status.localeCompare(a.data[0].status) )
    });
  };
  InstallationTimeSortByUp = () => {

    this.setState({
      data:this.state.data.sort((a, b)=>b.data[0].requestDate > (a.data[0].requestDate) )
    });
  };
  InstallationTimeSortByDown = () => {

    this.setState({
      data:this.state.data.sort((a, b)=>a.data[0].isSLABreach > (b.data[0].isSLABreach) )
    });
  };
  WithinSLASortByUp = () => {

    this.setState({
      data:this.state.data.sort((a, b)=>b.data[0].isSLABreach - (a.data[0].isSLABreach) )
    });
  };
  WithinSLASortByDown = () => {

    this.setState({
      data:this.state.data.sort((a, b)=>a.data[0].isSLABreach - (b.data[0].isSLABreach) )
    });
  };
  RequestedTimeSortByUp = () => {

    this.setState({
      data:this.state.data.sort((a, b)=>b.data[0].requestDate > (a.data[0].requestDate) )
    });
  };
  RequestedTimeSortByDown = () => {

    this.setState({
      data:this.state.data.sort((a, b)=>a.data[0].requestDate > (b.data[0].requestDate) )
    });
  };
  TeamStatusSortByUp = () => {

    this.setState({
      data:this.state.data.sort((a, b)=>a.data[0].teamAvailability-(b.data[0].teamAvailability) )
    });
  };
  TeamStatusSortByDown = () => {

    this.setState({
      data:this.state.data.sort((a, b)=>b.data[0].teamAvailability-(a.data[0].teamAvailability) )
    });
  };
  ArraySortByUp = () => {

    //console.log()
    this.setState({
      data:this.state.data.sort((a, b)=>a.name.localeCompare(b.name) )
    });
  };
  onChangeRegion= (value) => {
    //console.log("onChangeRegion");
    //console.log(value);
     const dataa= this.props.regions.filter((item) => {
      return item;
    });
    this.setState({
      filterSubRegion:[],
      filterDepot:[],

    })

    this.setState({
      regionName:dataa[value-1]?.name,
     // filterDepot:"",
      DepotName:"",
      filterRegion: value,
    
      SubRegion_list: this.props.regions.filter(alldate=>value.map((n)=>n).includes(alldate.parentRegion?.id))
     
    });
  };
  onChangeSubRegion= (value) => {
    //console.log("onChangeSubRegion");
    //console.log(value);
     const dataa= this.props.regions.filter((item) => {
      return item;
    });

    this.setState({
      filterDepot:[],
    })
    

    this.setState({
      regionName:dataa[value-1]?.name,
     // filterDepot:"",
      DepotName:"",
      filterSubRegion: value,
    
      depot_list: this.props.depots.filter(alldate=>value.map((n)=>n).includes(alldate.region?.id))
     
    });
  };
  onChangeDepot= (value) => {
    //console.log("onChangeDepot");
    //console.log( );
    const dataa= this.props.depots.filter((item) => {
      return item;
    });
//    //console.log(dataa[value-1].name);

    this.setState({
     
      DepotName:dataa[value-1]?.name,
      filterDepot: value,
    });
  };
  clearBtnClick = () =>{
    window.location.reload()
  }

  VehicaleCellContext = (_data,tags) => {
    var slaBreachNew=false;

    // //console.log(_data.data.filter(alldate=>tags.map((n)=>n.id).includes(alldate.statusId)))
     //console.log(_data)
     const {data, color, name} =_data;
     if(tags==''){
      var filterdataa=data
       
     }else{
     
      var filterdataa=data.filter(alldate=>tags.map((n)=>Number(n.id)).includes(alldate.statusId))

     }

     if(filterdataa[0].installedOrNot==false && filterdataa[0].omApprovedOrNot==false){

      const requestDataTime=filterdataa[0].requestDate+' '+filterdataa[0].requestTime;
      let nowdate =moment();
      let requestData =moment(requestDataTime);
      //console.log("newDate");
      //console.log(nowdate+" ddddd "+requestData);
      var ms = moment(nowdate,"DD/MM/YYYY HH:mm:ss").diff(moment(requestData,"DD/MM/YYYY HH:mm:ss"));
      var dg = moment.duration(ms);
      var s = Math.floor(dg.asHours()) + moment.utc(ms).format(":mm:ss");
      
      if(s.split(':')[0] >2){
          slaBreachNew=true;

      }else{
          slaBreachNew=false;
       
      
  }
}
if(filterdataa[0].installedOrNot==false && filterdataa[0].omApprovedOrNot==true){

  const requestDataTime=filterdataa[0].omApprovedTime;
  let nowdate =moment();
  let requestData =moment(requestDataTime);
  //console.log("newDate");
  //console.log(moment(nowdate,"DD/MM/YYYY HH:mm:ss")+" ddddd "+moment(requestData,"DD/MM/YYYY HH:mm:ss"));
  var ms = moment(nowdate,"DD/MM/YYYY HH:mm:ss").diff(moment(requestData,"DD/MM/YYYY HH:mm:ss"));
  var dg = moment.duration(ms);
  var s = Math.floor(dg.asHours()) + moment.utc(ms).format(":mm:ss");
  var slatime="2:00:00" 
  if(s.split(':')[0] >2){
      slaBreachNew=true;


  }else{
    slaBreachNew=false;
  
}
}
if(filterdataa[0].installedOrNot==true){
  slaBreachNew=filterdataa[0].isSLABreach;
}

     
   
    return(
      filterdataa.length > 0 &&
       <div id="box">
            <div id="boxData"  style={{width:"12%" , borderColor:colors[filterdataa[0]?.statusId]  , borderStyle:"solid", borderWidth:1, background:  colors[filterdataa[0].statusId ]   }}>
                <h5 style={{fontSize: 12, marginTop:17}}> {   filterdataa[0]?.siteName}</h5> 
                  
            </div>
  
            <div id="boxData"  style={{width:"86%" ,  borderColor:  colors[filterdataa[0].statusId] , borderStyle:"solid", borderWidth:1,  borderBottomLeftRadius:0 , borderTopLeftRadius:0 , borderTopRightRadius:10 , borderBottomRightRadius:10 ,  }}>
            
              <Flex pt={0} pr={30} pb={0} pl={2} alignItems="center" className="card-vehicle-container-header">
              <Flex bold={true} style={{ height:"100%",textAlign:"left"}} width={1/3} alignItems="center"> {filterdataa[0].status == null ? <i>"N/A"</i> : filterdataa[0].status}</Flex>
              <Flex  style={{fontWeight:10 ,height:"100%" ,display: "grid"}} width={1/3} alignItems="center">{ filterdataa[0].teamAvailability == false ? <x>No</x> : <x>Yes</x>}</Flex>
              <Flex style={{ fontWeight:10 ,height:"100%",display: "grid"}} width={1/2} alignItems="center"> {filterdataa[0].requestDate == null ? <i>"N/A"</i> : filterdataa[0].requestDate+':'+filterdataa[0].requestTime}</Flex>
              {slaBreachNew==false?(<Flex style={{ height:"90%",display: "grid",backgroundColor:"#008000a6",borderRadius:20,color:"#ffffff"}} width={1/8} alignItems="center">{<x>Yes</x>} </Flex> ):(<Flex style={{ height:"90%",display: "grid",backgroundColor:"#ff000094",borderRadius:20,color:"#ffffff"}} width={1/8} alignItems="center">{ <x>No</x>} </Flex> )}
              
              <Flex style={{ fontWeight:10 ,height:"100%", display: "grid"}} width={1/2} alignItems="center">{filterdataa[0].installedTime == null ? <i>"N/A"</i> :Moment(filterdataa[0].installedTime).format('YYYY-MM-DD HH:mm:ss')}</Flex>
          </Flex>
            </div>
   
       </div>
       
  
    )
  }


  VehicaleDetails = ( vehicles) => { 
 
	
		return (<h5 style={{color:"#b3b3b3" , marginLeft:20}}>No Vehicles Found</h5>)
	
	return (	
		 
		<ul >
			{vehicles.map((data, index) =>{
				return <li key={index} style={{cursor:"pointer" , listStyle:"none"}}  className="hash_item"><i>{data.vehicleNumber}</i></li>
				})}
			</ul> 
	)
 
}

colorCodes = () => { 
 
	return (	
		 
		<ul >
			  <li style={{cursor:"pointer" , listStyle:"none" , background:"#008000a6" , fontSize:13}}  className="color_codes"><i>Within SLA Yes</i></li>
			  <li style={{cursor:"pointer" , listStyle:"none" , background:"#ff000094", fontSize:13}}  className="color_codes"><i>Within SLA No</i></li>
			 
		</ul> 
	)
 
}

   CellContext = (   assignedDockCount , dockCount  ) => {
   
    return(
          <Card 
                value={assignedDockCount} 
                from={dockCount}
                header={<React.Fragment><FiNavigation style={{margin: 5}}/>Allocated</React.Fragment>}
                className="app-dashboard app-dashboard-card-color-lable red"
                style={{paddingLeft: 10}}/>
       
  
    )
  }

  handleDelete(i) {
    const { tags } = this.state;
    this.setState({
     tags: tags.filter((tag, index) => index !== i),
    });
}

handleAddition= async(tag)=>{
    
  await this.setState(state => ({ tags: [...state.tags, tag] }));

  //console.log(this.state.tags);
   
}

handleDrag(tag, currPos, newPos) {
    const tags = [...this.state.tags];
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    this.setState({ tags: newTags });
}
  render() {
    const { tags, suggestions } = this.state;
    return (
      <DataContext>
        {withContext(Loading)}
        {/* {withContext(LoginModal)} */}
        <div className={`App ${this.state.isLight ? 'light': 'dark'}`}>
          <span onClick={() => this.setState({ isLight: !this.state.isLight })} style={{position: 'absolute', bottom: 0, right: 10}}>{
            this.state.isLight 
            ? <FiToggleLeft style={{fontSize: 20}}/>
            : <FiToggleRight style={{fontSize: 20}}/>
          }</span>
          <div className="app-dashboard-sidebar">
            
            <div>
         

              <div> 
              <Card 
                value={this.state.stats.delayed_allo}
                from={this.state.stats.total_req}
                header={<React.Fragment><FiNavigation style={{margin: 5}}/>Completed</React.Fragment>}
                className="app-dashboard app-dashboard-card-color-lable red"
                style={{paddingLeft: 10,    width:161}}/>
                 
              </div>

              <div>
			    <h5 style={{margin:15}}>Color Codes</h5>
                 {withContext(() =>   this.colorCodes())}  </div>   
             
			   
				         
			 
				 
			 
            </div>
			
			
			  
		  	 
			
          </div>

          <div className="app-dashboard-body">
            <CardContainer style={{borderRadius: '5px 5px 30px 30px'}}>
              <Flex className="app-dashboard-body-topbar">
   
              <Flex width={1} justifyContent="flex-start">{this.state.curTime?( <div className="center-content"><FiCalendar style={{margin: 5}}/>{this.state.curTime}</div>):(<StillClock />)}</Flex>
			      <Flex width={1} justifyContent="center"><div className="departures">{this.state.headerName}</div></Flex>
                  <Flex width={1} justifyContent="flex-end"><TickClock /></Flex>
                  <div class="col-md-1">
										 <button type="button" style={{marginLeft:32}} class="btn btn-danger" onClick={()=> this.viewFilterClick()}><i class="fas fa-filter"></i></button>
                  </div> 
              </Flex>
            </CardContainer>
            <div className="app-dashboard-body-display">
            <Collapse isOpen={this.state.isOpen}>
                <div className="row" style={{paddingLeft:20,paddingRight:20}}>
                <div class="card">
                                
                                <div class="card-body bootstrap-select-1">
                                    <div class="row">
                                    <div class="col-md-2">
                                            <label class="mb-3">Start Date</label>
                                            <div className="row">
                                              <div className="col-md-12">
                                                <DatePicker
                                                  placeholder="Start Date"
                                                  style={{ width: "100%" }}
                                                  onChange={this.onChangeDate}
                                                />
                                              </div>
                                              </div>
										
                                         </div> 
                                         <div class="col-md-2">
                                            <label class="mb-3"> End Date</label>
                                            <div className="row">
                                              <div className="col-md-12">
                                                <DatePicker
                                                  placeholder="Start Date"
                                                  style={{ width: "100%" }}
                                                  onChange={this.onChangeEndDate}
                                                />
                                              </div>
                                              </div>
										
                                         </div>  
                                        <div class="col-md-3">
                                            <label class="mb-3">Region</label>
                                            
                                            <Select
                                                mode="multiple"
                                                showSearch
                                                onChange={this.onChangeRegion} 
                                                name="regionId"
                                                value={this.state.filterRegion}

                                                style={{ width: "100%" }}
                                                placeholder="Select Region"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                }
                                              >
                                                <Option value="">Select Region</Option>
                                                {this.props.regions.filter(allret=>"0".includes(allret.isSubRegion)).map((item, index) => {
                                                                return <Option value={item.id}>{item.name}</Option>;
                                                            })}
                                            </Select>
                                        </div>  
                                        <div class="col-md-3">
                                            <label class="mb-3">Sub Region</label>
                                            
                                            <Select
                                                mode="multiple"
                                                showSearch
                                                onChange={this.onChangeSubRegion} 
                                                name="regionId"
                                                value={this.state.filterSubRegion}

                                                style={{ width: "100%" }}
                                                placeholder="Select Region"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                }
                                              >
                                                <Option value="">Select Region</Option>
                                                {this.state.SubRegion_list.map((item, index) => {
                                                                return <Option value={item.id}>{item.name}</Option>;
                                                            })}
                                            </Select>
                                        </div>   
                                        <div class="col-md-2">
                                            <label class="mb-3">Depot</label>
                                            <Select
                                                mode="multiple"
                                                showSearch
                                                onChange={this.onChangeDepot} 
                                                value={this.state.filterDepot}
                                                name="depotsId"
                                                style={{ width: "100%" }}
                                                placeholder="Select Depot"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                }
                                              >
                                                <Option value="">All Depots</Option>
                                                {this.state.depot_list.map((item, index) => {
                                                                return <Option value={item.id}>{item.name}</Option>;
                                                            })}
                                            </Select>
                                        </div>                               
                                      
										                
										                                            
                                    </div>
                                    <div className='row'>
                                        <div class="col-md-2" style={{marginTop:10}}> 
                                          <button type="button" class="btn btn-primary" onClick={()=> this.filterBtnClick()}> Filter</button>
                                          <button type="button" style={{marginLeft:5}} class="btn btn-dark" onClick={()=> this.clearBtnClick()}>Clear Filter</button>
                                            </div>  
                                            <div class="col-md-10" style={{marginTop:10}}>
                                                    <div>
                                                        {/* <ReactTags tags={tags}
                                                            suggestions={suggestions}
                                                            handleDelete={this.handleDelete}
                                                            handleAddition={this.handleAddition}
                                                            handleDrag={this.handleDrag}
                                                            placeholder="Add Status.."
                                                            delimiters={delimiters} /> */}
                                                    </div>
                                              </div>
                                            </div>
                                </div>  
                            </div>
			</div>
			</Collapse>
               
              {/* <Main /> */}
                <Flex>
                  {/* LEFT SIDE */}
                  <Box width={1}>
                  <React.Fragment>
                  <Flex pt={0} pr={30} pb={0} pl={30} alignItems="center" className="card-vehicle-container-header">
                <Flex width={1/2} alignItems="center"><FiMapPin style={{margin: '0 5px'}}/>Site  <span id="grid" onClick={() => this.ArraySortByUp('first-id')}> <i className="fa fa-arrow-up" ></i></span><span id="grid" onClick={() => this.ArraySortByDown('first-id')}><i className="fa fa-arrow-down" ></i></span> </Flex>
                <Flex width={1/2} alignItems="center">Status  <span id="grid"  onClick={() => this.StatusSortByUp('first-id')}>  <i className="fa fa-arrow-up" > </i></span> <span id="grid" onClick={() => this.StatusSortByDown('first-id')}> <i className="fa fa-arrow-down" ></i></span></Flex>
                <Flex width={1/2} alignItems="center">Team status <span id="grid"  onClick={() => this.TeamStatusSortByUp('first-id')}>  <i className="fa fa-arrow-up" > </i></span> <span id="grid" onClick={() => this.TeamStatusSortByDown('first-id')}> <i className="fa fa-arrow-down" ></i></span></Flex>
                <Flex width={1/2} alignItems="center">Requested time  <span id="grid"  onClick={() => this.RequestedTimeSortByUp('first-id')}>  <i className="fa fa-arrow-up" > </i></span> <span id="grid" onClick={() => this.RequestedTimeSortByDown('first-id')}> <i className="fa fa-arrow-down" ></i></span></Flex>
                <Flex width={1/2} alignItems="center"><FiActivity style={{margin: '0 5px'}}/>Within SLA    <span id="grid"  onClick={() => this.WithinSLASortByUp('first-id')}>  <i className="fa fa-arrow-up" > </i></span> <span id="grid" onClick={() => this.WithinSLASortByDown('first-id')}> <i className="fa fa-arrow-down" ></i></span></Flex>
                {/* <Flex width={1} alignItems="center"><FiArrowUpRight style={{margin: '0 5px'}}/>Departure Time</Flex> */}
                {/* <Flex width={1} alignItems="center"><FiArrowDownRight style={{margin: '0 5px'}}/>Arrival Time</Flex> */}
                <Flex width={1/2} alignItems="center"><FiWatch style={{margin: '0 5px'}}/>Installation time  <span id="grid"  onClick={() => this.InstallationTimeSortByUp('first-id')}>  <i className="fa fa-arrow-up" > </i></span> <span id="grid" onClick={() => this.InstallationTimeSortByDown('first-id')}> <i className="fa fa-arrow-down" ></i></span></Flex>
            </Flex>
            <div>{this.state.data.map((_data, i) =>  this.VehicaleCellContext(_data,this.state.tags))}</div>
        </React.Fragment>
                    
                      
                   
                  </Box>
                  {/* RIGHT SIDE */}
                  {/* <Box width={1}>
                    <LocationContainer>
                    {withContext(({data  ,assignedDockCount , dockCount}) => data.map((_data, i) => i%2==1 &&  this.VehicaleCellContext(_data,  assignedDockCount , dockCount , i)))}
                    </LocationContainer>
                  </Box> */}
                </Flex>
            </div>
            <div  style={{display: 'flex',  justifyContent:'center', alignItems:'center' , color : "#acacac"}}>
               
            </div>
            <div  style={{display: 'flex', height: 25, justifyContent:'center', alignItems:'center' , color : "#acacac"}}> 
               <h6> © 2020 Eimsky Business Solutions Pvt Ltd. All Rights Reserved.  </h6>
            </div>
           
          </div>
        </div>
      </DataContext>
    );
  }
}
function mapStateToProps(state) {
    //console.log("state", state);
    return {
      organization_count: state.organization.length,
      regions: state.regions,
      depots: state.depots,
      sites_count: state.sites.length,
      vendor_count: state.vendor.length,
      roles_count: state.roles.length,
      pins:state.pins
    };
  }

export default connect(mapStateToProps, { getDonationCategories, getData })(DashboardAllPgLive);
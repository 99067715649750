import React, { Component } from 'react'
import { Table,Tag} from 'antd';


class GeneratorPd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "",
            tabaleLoading: false

        };
    }

    componentDidMount() {
        console.log('tytqwe')
        console.log(this.props.details)
        console.log(this.props.details2)
    }
    render() {
        const { details } = this.props.details
        const columns2 = [
            {
                title: "Name",

                dataIndex: "name",
                key: "name",
                fixed: 'left',
                width: '100px',
                render: (item) => <>
                <Tag color="gray" key={item}>
                    <span>{item}</span>
                </Tag>
            </>,

           
            },
            {
                title: "Generator SAP Serial Number",
                dataIndex: "generatorSAPSerialNumber",
                key: "generatorSAPSerialNumber",
                fixed: 'left',
                width: '100px',

                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,
            },
            {
                title: "Active",

                dataIndex: "active",
                key: "active",
                width: '100px',

                //render: (item) => <span>{item !== null ? (item.name) : ('N/A')}</span>,
                render: (item) => <span>{item ? ( <Tag color="#228520" key={item}>
                <span>{JSON.stringify(item)}</span>
            </Tag>) : (<Tag color="#9b3838" key={item}>
                <span>{JSON.stringify(item)}</span>
            </Tag>)}</span>,


            },
          
            {
                title: "Type",

                dataIndex: "type",
                key: "type",
                width: '100px',

                render: (item) => <span>{item !== null ? (item?.name) : ('N/A')}</span>,

            },
            {
                title: "Model",
                dataIndex: "model",
                key: "model",
                width: '100px',

                render: (item) => <span>{item !== null ? (item?.name) : ('N/A')}</span>,

            },
            {
                title: "Description",
                dataIndex: "description",
                key: "description",
                width: '100px',

                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,

            },
            {
                title: "Installed Date",
                dataIndex: "installedDate",
                key: "installedDate",
                width: '100px',

                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,

            },

            {
                title: "Engine Serial Number",
                dataIndex: "engineSerialNumber",
                key: "engineSerialNumber",
                width: '100px',
                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,
            },
            {
                title: "Alternater Serial Number",
                dataIndex: "alternaterSerialNumber",
                key: "alternaterSerialNumber",
                width: '100px',

                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,

                ///   render: (item) => <span>{item?.siteAddressList[0]?.address}</span>,
            },
            {
                title: "Controller Serial Number",
                dataIndex: "controllerSerialNumber",
                key: "controllerSerialNumber",
                width: '100px',

                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,

                ///   render: (item) => <span>{item?.siteAddressList[0]?.address}</span>,
            },
            {
                title: "Any Other Serial Number",
                dataIndex: "anyOtherSerialNumber",
                key: "anyOtherSerialNumber",
                width: '100px',

                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,

                ///   render: (item) => <span>{item?.siteAddressList[0]?.address}</span>,
            },
            {
                title: "Material Code Of The Generator",
                dataIndex: "materialCodeOfTheGenerator",
                key: "materialCodeOfTheGenerator",
                width: '130px',
                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,

                //render: (item) => <span>{item ? (<span class="badge badge-outline-success">{JSON.stringify(item)}</span>) : (<span class="badge badge-outline-warning">{JSON.stringify(item)}</span>)}</span>,

                // render: (item) => <span>{item?.siteAddressList[0]?.longitude}</span>,
            },

            {
                title: "Category",
                dataIndex: "category",
                key: "category",
                width: '100px',

                render: (item) => <span>{item !== null ? (item?.name) : ('N/A')}</span>,

                // render: (item) => <span>{item?.siteType.name}</span>,
            },
            {
                title: "Capacity",
                dataIndex: "capacity",
                key: "capacity",
                width: '100px',

                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,
            },
            {
                title: "Available Fuel Qty",
                dataIndex: "availableFuelQty",
                key: "availableFuelQty",
                width: '100px',

                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,
            },
            {
                title: "Availability Of Gen Canapy",
                dataIndex: "availabilityOfGenCanapy",
                key: "availabilityOfGenCanapy",
                width: '100px',

                render: (item) => <span>{item ? (<span class="badge badge-outline-success">{JSON.stringify(item)}</span>) : (<span class="badge badge-outline-warning">{JSON.stringify(item)}</span>)}</span>,

            },
            {
                title: "Average Running Hours Per Month",
                dataIndex: "averageRunningHoursPerMonth",
                key: "averageRunningHoursPerMonth",
                width: '130px',

                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,
              
            },
            {
                title: "Delay Timer Status",
                dataIndex: "delayTimerStatus",
                key: "delayTimerStatus",
                width: '100px',

                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,
              
            },
            {
                title: "Fuel Half Filling",
                dataIndex: "fuelHalfFilling",
                key: "fuelHalfFilling",
                width: '100px',

                render: (item) => <span>{item ? (<span class="badge badge-outline-success">{JSON.stringify(item)}</span>) : (<span class="badge badge-outline-warning">{JSON.stringify(item)}</span>)}</span>,

              
            },
            {
                title: "Gen Canapy Status",
                dataIndex: "genCanapyStatus",
                key: "genCanapyStatus",
                width: '100px',

                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,
              
            },
            {
                title: "Generator Controller Model",
                dataIndex: "generatorControllerModel",
                key: "generatorControllerModel",
                width: '130px',

                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,
              
            },
            {
                title: "Generator Cool Down Delay Time",
                dataIndex: "generatorCoolDownDelayTime",
                key: "generatorCoolDownDelayTime",
                width: '130px',

                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,
              
            },
            {
                title: "Generator Worm Up Delay Time",
                dataIndex: "generatorWormUpDelayTime",
                key: "generatorWormUpDelayTime",
                width: '130px',

                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,
              
            },
            {
                title: "Last Filling Date",
                dataIndex: "lastFillingDate",
                key: "lastFillingDate",
                width: '100px',

                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,
              
            },
            {
                title: "Last Filling Quantity",
                dataIndex: "lastFillingQuantity",
                key: "lastFillingQuantity",
                width: '100px',

                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,
              
            },
            {
                title: "Last Gen Efficiency",
                dataIndex: "lastGenEfficiency",
                key: "lastGenEfficiency",
                width: '160px',

                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,
              
            },
            {
                title: "Last Meter Reading",
                dataIndex: "lastMeterReading",
                key: "lastMeterReading",
                width: '100px',

                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,
              
            },
            {
                title: "Last Remaining Fuel Quantity",
                dataIndex: "lastRemainingFuelQuantity",
                key: "lastRemainingFuelQuantity",
                width: '130px',

                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,
              
            },
            {
                title: "Last Runing Hours",
                dataIndex: "lastRuningHours",
                key: "lastRuningHours",
                width: '100px',

                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,
              
            },
            {
                title: "Last Serviced Date",
                dataIndex: "lastServicedDate",
                key: "lastServicedDate",
                width: '100px',

                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,
              
            },
            {
                title: "Loading With Battery Charging",
                dataIndex: "loadingWithBatteryCharging",
                key: "loadingWithBatteryCharging",
                width: '130px',

                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,
              
            },
            {
                title: "Loading Without Battery Charging",
                dataIndex: "loadingWithoutBatteryCharging",
                key: "loadingWithoutBatteryCharging",
                width: '130px',

                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,
              
            },
            {
                title: "Max Filling Limit",
                dataIndex: "maxFillingLimit",
                key: "maxFillingLimit",
                width: '130px',

                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,
              
            },
            {
                title: "Phase1 Load (A)",
                dataIndex: "phase1Load",
                key: "phase1Load",
                width: '100px',

                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,
              
            },
            {
                title: "Phase2 Load (A)",
                dataIndex: "phase2Load",
                key: "phase2Load",
                width: '100px',

                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,
              
            },
            {
                title: "Phase3 Load (A)",
                dataIndex: "phase3Load",
                key: "phase3Load",
                width: '100px',

                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,
              
            },
            {
                title: "Rms",
                dataIndex: "rms",
                key: "rms",
                width: '100px',

                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,
              
            },
            {
                title: "Rms Connection Method",
                dataIndex: "rmsconnectionMethod",
                key: "rmsconnectionMethod",
                width: '130px',

                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,
              
            },
            {
                title: "Rm sintegrated Status",
                dataIndex: "rmsintegratedStatus",
                key: "rmsintegratedStatus",
                width: '130px',

                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,
              
            },
            {
                title: "Rm sintegrated Status",
                dataIndex: "rmsintegratedStatus",
                key: "rmsintegratedStatus",
                width: '130px',

                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,
              
            },
            {
                title: "Rm Srelated SIM Number",
                dataIndex: "rmsrelatedSIMNumber",
                key: "rmsrelatedSIMNumber",
                width: '130px',

                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,
              
            },
            {
                title: "Sound Proof Status",
                dataIndex: "soundProofStatus",
                key: "soundProofStatus",
                width: '100px',

                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,
              
            },
            {
                title: "Warranty Expired Date",
                dataIndex: "warrantyExpiredDate",
                key: "warrantyExpiredDate",
                width: '100px',

                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,
              
            },
           



        ];
        return (
            <>
                {this.props.details.access_technologies_data != null ? (
                    <div className='row'>
                        <div className='col-md-5'>
                            <div class="card">
                                <div class="card-header">
                                    <h4 class="card-title">Information</h4>

                                </div>
                                <div class="card-body">
                                    <div class="row">

                                        <ul class="list-group">
                                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                                <div>
                                                    <i class="la la-hand-o-right text-primary me-2"></i>No of Generators
                                                </div>

                                                {this.props.details.access_technologies_data?.noOfGenerators ? (<span class="badge badge-outline-success">{this.props.details.access_technologies_data.noOfGenerators}</span>) : (<span class="badge badge-outline-warning">{this.props.details.access_technologies_data?.noOfGenerators}</span>)}

                                            </li>
                                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                                <div>
                                                    <i class="la la-hand-o-right text-primary me-2"></i>Ticket Number Of Pending Faulty Ticket
                                                </div>

                                                {this.props.details.access_technologies_data?.genTicketNumberOfPendingFaultyTicket ? (<span class="badge badge-outline-success">{JSON.stringify(this.props.details.access_technologies_data.genTicketNumberOfPendingFaultyTicket)}</span>) : (<span class="badge badge-outline-warning">{JSON.stringify(this.props.details.access_technologies_data?.genTicketNumberOfPendingFaultyTicket)}</span>)}

                                            </li>
                                           
                                            

                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-7'>

                            
                                {this.props.details2?.generators_data?.length > 0 ? (<div class="card">
                                <div class="card-header">
                                    <h4 class="card-title">Generators</h4>

                                </div>
                                <div class="card-body">
                                <Table
                                size="small"
                                columns={columns2}
                                // loading={this.state.loading && this.state.rectifierloading && this.state.batteryBanksloading && this.state.airConditionersloading && this.state.spdsloading && this.state.cabinetsloading && this.state.generatorsloading && this.state.atsesloading && this.state.dcdusloading && this.state.fcbsloading && this.state.sharedOutOperatorsesloading}
                                loading={this.state.tabaleLoading}
                                dataSource={this.props.details2?.generators_data}
                                scroll={{
                                    x: 1300,
                                }}
                            />
                            </div>
                            </div>) : ('')}
                                
                        

                        </div>
                    </div>
                ) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <h4 style={{ color: "#c5c5c5" }}>No Dat Found Access Technologies</h4></div>)}

            </>
        )
    }
}
export default GeneratorPd;

import React, { useState, useEffect } from 'react';
import {
    ClockCircleOutlined,
    ClockCircleFilled,
    PlusOutlined,
    DeleteFilled
} from '@ant-design/icons';
import { Divider, Space, Avatar, Card, Skeleton, Switch, Table, Select, Badge, Input, Button, Row, Upload, Col, Dropdown, Tag, Modal, Steps, message, Form, DatePicker, TimePicker, Descriptions, Image, Radio, Checkbox, Collapse } from 'antd';
import { getAllBlackListDataREWAMP, addBlackListDataREWAMP, deleteBlackListDataREWAMP } from "../../../api/index";
const { Step } = Steps;

const BlacklistAcc = () => {
    const [blacklistData, setBlacklistData] = useState([]);
    const [openBlacklistModal, setOpenBlacklistModal] = useState(false);

    useEffect(() => {
        getAllBlackListDataREWAMP().then((response) => {
            setBlacklistData(response?.data?.results);
        });
    }, []);

    const closeModal = () => {
        setOpenBlacklistModal(false);
    }

    const fetchAllBuikRequests = () => {
        getAllBlackListDataREWAMP().then((response) => {
            setBlacklistData(response?.data?.results);
        });
    }

    const deleteBlacklist = (id) => {
        deleteBlackListDataREWAMP(id).then((response) => {
            if (response) {
                message.success('Blacklist NIC Deleted Successfully');
                getAllBlackListDataREWAMP().then((response) => {
                    setBlacklistData(response?.data?.results);
                });
            } else {
                message.error('Something went wrong');
            }
        });
    }

    const columns = [
        {
            title: <span className="textStyle">Blacklisted Nic</span>,
            dataIndex: 'nic',
            key: 'nic',
            render: text => <a className="textStyleChild">{text}</a>,
        },
        {
            title: <span className="textStyle">Blacklisted By</span>,
            dataIndex: 'blacklistedBy',
            key: 'blacklistedBy',
            render: text => <a className="textStyleChild">{text}</a>,
        },
        {
            title: <span className="textStyle">Created At</span>,
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: text => <a className="textStyleChild">{new Date(text).toLocaleString()}</a>,
        },
        {
            title: <span className="textStyle">Updated At</span>,
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: text => <a className="textStyleChild">{new Date(text).toLocaleString()}</a>,
        },
        {
            title: <span className="textStyle">Action</span>,
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Button
                        shape='circle'
                        danger
                        size='small'
                        icon={<DeleteFilled />}
                        type="primary"
                        onClick={() => deleteBlacklist(record.id)} />
                </Space>
            ),
        },
    ];

    const addBlackListDataREWAMPVal = (values) => {
        addBlackListDataREWAMP(values).then((response) => {
            if (response) {
                message.success('Blacklist NIC Added Successfully');
                getAllBlackListDataREWAMP().then((response) => {
                    setBlacklistData(response?.data?.results);
                });
                fetchAllBuikRequests();
                closeModal();
            } else {
                message.error('Something went wrong');
            }
        });
    }

    return (

        <body>
            <div style={{ margin: '0', padding: '10px', height: '100%' }}>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="page-title-box">
                            <div className="row">
                                <div className="col">
                                    <h4 className="page-title" style={{ fontWeight: "bold" }}>
                                        Blacklist Users
                                    </h4>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <a href="javascript:void(0);">Site Access </a>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <a href="javascript:void(0);">Blacklist Users</a>
                                        </li>
                                    </ol>
                                </div>
                                <div className="col-auto align-self-center">
                                    <Button
                                        style={{ width: '150px', borderRadius: '5px', backgroundColor: '#00AC4F', borderColor: '#00AC4F' }}
                                        type="primary"
                                        onClick={() => setOpenBlacklistModal(true)}
                                    >
                                        <span className='textStyleChild' style={{ fontSize: '12px', color: 'white' }}>
                                            Add New Blacklist </span>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div style={{ marginTop: '20px', backgroundColor: 'white', padding: '20px', borderRadius: '10px' }}>
                        <Table
                            className="table-striped-rows"
                            dataSource={blacklistData === null ? [] : blacklistData}
                            columns={columns}
                            rowKey="key"
                            size="small"
                        />
                    </div>

                    <Modal
                        title={<span className='textStyle' style={{ fontSize: '16px' }}>
                            Blacklist Members
                        </span>}
                        visible={openBlacklistModal}
                        onCancel={closeModal}
                        footer={null}
                        destroyOnClose={true}
                    >
                        <>
                            <Form
                                name="basic"
                                initialValues={{ remember: true }}
                                onFinish={addBlackListDataREWAMPVal}
                                layout="vertical"
                            >
                                <Form.Item
                                    label={<span className='textStyleChild'>NIC</span>}
                                    name="nic"
                                    rules={[
                                        { required: true, message: 'Please input NIC Number!' },
                                        {
                                            pattern: /^(?:\d{9}[vVxX]|\d{12})$/,
                                            message: 'Please input a valid NIC!'
                                        }
                                    ]}
                                >
                                    <Input style={{ borderRadius: '8px' }} />
                                </Form.Item>

                                <Form.Item
                                    label={<span className='textStyleChild'>Blacklisted By</span>}
                                    name="blacklistedBy"
                                    rules={[{ required: true, message: 'Please input Blacklisted By!' }]}
                                    initialValue={localStorage.getItem('logged_user_email')}
                                >
                                    <Input type='email' style={{ borderRadius: '8px' }} />
                                </Form.Item>

                                <Form.Item>
                                    <Button danger onClick={closeModal} style={{ borderRadius: '8px', marginRight: '10px', width: '120px' }}>
                                        <span className='textStyleChild' style={{ fontSize: '12px', color: 'red' }}>
                                            Back
                                        </span>
                                    </Button>

                                    <Button type="primary" htmlType="submit" style={{ width: '120px', borderRadius: '8px', backgroundColor: '#00AC4F', borderColor: '#00AC4F' }}>
                                        <span className='textStyleChild' style={{ fontSize: '12px', color: 'white' }}>
                                            Blacklist NIC
                                        </span>
                                    </Button>
                                </Form.Item>
                            </Form>
                        </>
                    </Modal>
                </div>
            </div>
        </body>
    );
}

export default BlacklistAcc;
import React, { Component } from "react";
import { Table, Input, Tag, Space, DatePicker } from "antd";
import ReactExport from "react-data-export";
import { getFuelBalanceExport } from "../../api/index";
import ReactLoading from 'react-loading';
import { getDonationCategories, getData } from "../../actions/index";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'

const moment = require('moment');




const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const { RangePicker } = DatePicker;

class FuelBalanceExport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      filterTable: null,
      data_list: [],
      startDate: "",
      endDate: "",
      loading: false,

    };
  }

  componentDidMount() {
    //console.log(this.props.details);
    if (JSON.parse(localStorage.getItem('serviceTypeSet')) == null) {
      const { history } = this.props;
   
      history.push("/");
     
    } else {
      this.getSetPageData(JSON.parse(localStorage.getItem('serviceTypeSet')) , 15)
    }

    this.getDataExport()
    this.props.getData();

  }
  getSetPageData =(serviceTypeIs,Pageid) =>  {
    //console.log("this.props");
    //console.log(serviceTypeIs.serviceType);
    if(serviceTypeIs.serviceType.filter(checkuser=>checkuser?.id==Pageid)[0] == null ){
      const { history } = this.props;
   
      history.push("/not-allowed");
    }
    
  };
  getDataExport = async () => {
    this.setState({
      loading: true,

    })
    ////console.log(data);
    var res = await getFuelBalanceExport();
    //console.log("getFuelBalanceExport");
    //console.log(res);
    if (res.success) {
      this.setState({
        loading: false,
        data_list: res.data,
      });
    }
  };
  onChangeDate = (date, dateString) => {
    //console.log(date);
    //console.log(dateString);
    this.setState({
      startDate: dateString,
    });
  };
  onChangeDate2 = (date, dateString) => {
    //console.log(date);
    //console.log(dateString);
    this.setState({
      endDate: dateString,
    });
  };
  searchClick = () => {
    //console.log("search");
    if (this.state.startDate != "" && this.state.endDate != "") {
      var data = {
        "startDate": this.state.startDate,
        "endDate": this.state.endDate,

      };
      this.setState({
        loading: true,
      });
      this.getDataExport(data);
    }

  };
  render() {
    const fontValue = { sz: "12", bold: true, fill: { patternType: "solid", fgColor: { rgb: "FF0B0713" } } };
    const { filterTable } = this.state;
    const dataSet1 = this.state.data_list;



    const StatisticDataSet = [
      {
        columns: [
          // Sec 01
          { title: "Region", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 210 } },//char width 
          { title: "Vendor", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
          { title: "Team", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
          { title: "Current Balance", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width cManual_transport_amount


        ],
        data: dataSet1?.map((data) => [

          // Sec 01
          { value: data.region ? (data.region) : ("Null"), style: { font: { sz: "12" } } },
          { value: data.vendor ? (data.vendor) : ("Null"), style: { font: { sz: "12" } } },
          { value: data.vendorTeam ? (data.vendorTeam) : ("Null"), style: { font: { sz: "12" } } },
          { value: data.currentBalance ? (data.currentBalance) : (0), style: { font: { sz: "12" } } },
        ]),
      }
    ];

    const columns = [
      {
        title: "Region",
        dataIndex: "region",
        key: "region",
        render: (item) => <span>{item}</span>,
      },
      {
        title: "Vendor",
        dataIndex: "vendor",
        key: "vendor",
        render: (item) => <span>{item}</span>,
      },
      {
        title: "Team",
        dataIndex: "vendorTeam",
        key: "vendorTeam",
        render: (item) => <span>{item}</span>,
      },
      {
        title: "Current Balance",
        dataIndex: "currentBalance",
        key: "currentBalance",
        render: (item) => <span>{item}</span>,

      },

    ];
    const timestamp = Date.now();
    return (
      <div class="container-fluid">
        <div class="row">
              <div class="col-sm-12">
                <div class="page-title-box">
                  <div class="row">
                    <div class="col">
                      <h4 class="page-title">Fuel Balance Export</h4>
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Data Export </a></li>
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Fuel Balance Export</a></li>

                      </ol>
                    </div>
                    <div class="col-auto align-self-center">
                      <a href="#" class="btn btn-sm btn-outline-primary" id="Dash_Date">
                        <span class="day-name" id="Day_Name">Today:</span>&nbsp;
                        <span class="" id="Select_date">{new Date().toUTCString().slice(5, 16)}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar align-self-center icon-xs ms-1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                      </a>

                    </div>
                  </div>
                </div>
              </div>
            </div>
      <div className="row">



        <br /><br />
        <div className="col-lg-12 col-xl-12">
          <div className="card">
            <div className="card-header">
              <div className="row ">
                <div className="col">
                  <h4 className="card-title">
                    Report -Fuel Balance Export{" "}
                    <ExcelFile
                      filename={"Fuel Balance Export -" + timestamp}
                      element={
                        <button
                          class="btn btn-primary"
                          style={{ float: "right" }}
                        >
                          <i class="fas fa-file-export"> </i>Fuel Balance Export
                        </button>
                      }
                    >

                      <ExcelSheet dataSet={StatisticDataSet} name="Fuel Balance Export" />
                    </ExcelFile>
                  </h4>
                  <div></div>
                </div>
              </div>
            </div>
            <div className="card-body">

              <Table
                size="small"
                columns={columns}
                // rowKey="id"
                loading={this.state.loading}
                dataSource={this.state.data_list}
              />

            </div>
          </div>
        </div>
      </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  //console.log("state", state);
  return {
    organization_count: state.organization.length,
    regions: state.regions,
    depots: state.depots,
    sites_count: state.sites.length,
    vendor_count: state.vendor.length,
    roles_count: state.roles.length,
  };
}
export default withRouter(connect(mapStateToProps, { getDonationCategories, getData })(
  FuelBalanceExport
)); 
import React, { Component } from 'react'; 
import SimpleReactValidator from 'simple-react-validator';
import {createNewSite} from '../../api/index';
import {addVendor} from '../../actions/index';
import { connect } from "react-redux"; 
import { ToastContainer, toast } from 'material-react-toastify';
  import 'material-react-toastify/dist/ReactToastify.css';
  import { Stepper , Step} from 'react-form-stepper';

 class SiteCreation extends Component {

	constructor(props) {
		super(props) 
		this.validator = new SimpleReactValidator();
		this.state ={
			name : "", 
			shelterTypes : []
		}
	}

	componentDidMount(){  
	 
   }
 
	createOrganization = async() =>{
		if (this.validator.allValid()) {
			 var data = {
				"depot": {"id":1},
				"user":{"id":1},
				"siteId": "test",
				"siteName": "test",
				"status": "test",
				"siteType": {"id":1},
				"towerLegsCount": 4,
				"towerHeight": "test",
				"siteElevation": "test",
				"siteEntity": {"id":1},
				"indoorOutdoor": "test",
				"ambientTemperature": "test",
				"tenants": 1,
				"powerSharedTenants": 1,
				"dimension": "test",
				"freeLandAvailable": "test",
				"siteCategory": {"id":1},
				"coolingMethods": {"id":1},
				"siteOwner": {"id":1},
				"shelterType": {"id":1},
				"shelterSize": "test",
				"nonShadingSpaceAvailabilityOnshelter": "test",
				"nonShadingSpaceAvailabilityGround": "test",
				"dependentCellCount": 1,
				"manualDistance": "test",
				"manualDelivery": "test",
				"travelTimeFromDepot": "test",
				"workingDaysAccessibleHours": "test",
				"weekendsOrHolidaysAccessibleHours": "test",
				"yearoftheJFS": 1,
				"isIdleGeneratorHutAvaialable": 1,
				"isSpaceAvailableNewGen": 1,
				"siteOnAirDate": "2020-01-01",
				"pgMannualDistance": "test",
				"siteAddressList":[{"longitude":"longitude","latitude":"latitude"}]
			}

			var res = await createNewSite(data);
		     
			if(res.success){
				toast.success("Successfully Created")
				 this.getAllShelterTypes();
				 this.setState({
					"name": ""
				 })
			} 
		  } else {
			this.validator.showMessages();
			// rerender to show messages for the first time
			// you can use the autoForceUpdate option to do this automatically`
			this.forceUpdate();
		  }
	}

	formItemChange = (event) =>{
        var obj = {};
		obj[event.target.name] = event.target.value; 
		this.setState(obj);  
	 }

	 
 
	render(){ 
		return (
			<div>  
				<Stepper activeStep={2}>
					<Step label="Children Step 1"  />
					<Step label="Children Step 2" />
					<Step label="Children Step 3" />
				</Stepper>
  					<div className="row">
                        <div>
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Create Site</h4>
                                    {/* <p className="text-muted mb-0">Small description.</p>  */}
                                </div> 
                                <div className="card-body">
                                    <form>
										<div className="row">
											<div className="col-lg-4">
												<label className="form-label" for="name">Site Id</label>
												<input autoComplete="off"  type="text" className="form-control" name="name" value={this.state.name} aria-describedby="emailHelp"   onChange={(e)=> this.formItemChange(e)}/>
												<p style={{color:"#f5325c"}}>{this.validator.message('name', this.state.name, 'required')}</p>
											</div>
											<div className="col-lg-4">
												<label className="form-label" for="name">Site Name</label>
												<input autoComplete="off"  type="text" className="form-control" name="name" value={this.state.name} aria-describedby="emailHelp"   onChange={(e)=> this.formItemChange(e)}/>
												<p style={{color:"#f5325c"}}>{this.validator.message('name', this.state.name, 'required')}</p>
											</div>
											
											<div className="col-lg-4">
												<label className="form-label" for="name">Depot</label>
												<input autoComplete="off"  type="text" className="form-control" name="name" value={this.state.name} aria-describedby="emailHelp"   onChange={(e)=> this.formItemChange(e)}/>
												<p style={{color:"#f5325c"}}>{this.validator.message('name', this.state.name, 'required')}</p>
											</div>
                                        </div>
										<div className="row">
											<div className="col-lg-4">
												<label className="form-label" for="name">User</label>
												<input autoComplete="off"  type="text" className="form-control" name="name" value={this.state.name} aria-describedby="emailHelp"   onChange={(e)=> this.formItemChange(e)}/>
												<p style={{color:"#f5325c"}}>{this.validator.message('name', this.state.name, 'required')}</p>
											</div>
											<div className="col-lg-4">
												<label className="form-label" for="name">Status</label>
												<input autoComplete="off"  type="text" className="form-control" name="name" value={this.state.name} aria-describedby="emailHelp"   onChange={(e)=> this.formItemChange(e)}/>
												<p style={{color:"#f5325c"}}>{this.validator.message('name', this.state.name, 'required')}</p>
											</div>
											
											<div className="col-lg-4">
												<label className="form-label" for="name">Site Type</label>
												<input autoComplete="off"  type="text" className="form-control" name="name" value={this.state.name} aria-describedby="emailHelp"   onChange={(e)=> this.formItemChange(e)}/>
												<p style={{color:"#f5325c"}}>{this.validator.message('name', this.state.name, 'required')}</p>
											</div>
                                        </div>
										<div className="row">
											<div className="col-lg-4">
												<label className="form-label" for="name">Tower Legs Count</label>
												<input autoComplete="off"  type="text" className="form-control" name="name" value={this.state.name} aria-describedby="emailHelp"   onChange={(e)=> this.formItemChange(e)}/>
												<p style={{color:"#f5325c"}}>{this.validator.message('name', this.state.name, 'required')}</p>
											</div>
											<div className="col-lg-4">
												<label className="form-label" for="name">Tower Height</label>
												<input autoComplete="off"  type="text" className="form-control" name="name" value={this.state.name} aria-describedby="emailHelp"   onChange={(e)=> this.formItemChange(e)}/>
												<p style={{color:"#f5325c"}}>{this.validator.message('name', this.state.name, 'required')}</p>
											</div>
											
											<div className="col-lg-4">
												<label className="form-label" for="name">Site Elevation</label>
												<input autoComplete="off"  type="text" className="form-control" name="name" value={this.state.name} aria-describedby="emailHelp"   onChange={(e)=> this.formItemChange(e)}/>
												<p style={{color:"#f5325c"}}>{this.validator.message('name', this.state.name, 'required')}</p>
											</div>
                                        </div>
										<div className="row">
											<div className="col-lg-4">
												<label className="form-label" for="name">Site Entity</label>
												<input autoComplete="off"  type="text" className="form-control" name="name" value={this.state.name} aria-describedby="emailHelp"   onChange={(e)=> this.formItemChange(e)}/>
												<p style={{color:"#f5325c"}}>{this.validator.message('name', this.state.name, 'required')}</p>
											</div>
											<div className="col-lg-4">
												<label className="form-label" for="name">indoorOutdoor</label>
												<input autoComplete="off"  type="text" className="form-control" name="name" value={this.state.name} aria-describedby="emailHelp"   onChange={(e)=> this.formItemChange(e)}/>
												<p style={{color:"#f5325c"}}>{this.validator.message('name', this.state.name, 'required')}</p>
											</div>
											
											<div className="col-lg-4">
												<label className="form-label" for="name">Ambient Temperature</label>
												<input autoComplete="off"  type="text" className="form-control" name="name" value={this.state.name} aria-describedby="emailHelp"   onChange={(e)=> this.formItemChange(e)}/>
												<p style={{color:"#f5325c"}}>{this.validator.message('name', this.state.name, 'required')}</p>
											</div>
                                        </div>
										<div className="row">
											<div className="col-lg-4">
												<label className="form-label" for="name">Tenants</label>
												<input autoComplete="off"  type="text" className="form-control" name="name" value={this.state.name} aria-describedby="emailHelp"   onChange={(e)=> this.formItemChange(e)}/>
												<p style={{color:"#f5325c"}}>{this.validator.message('name', this.state.name, 'required')}</p>
											</div>
											<div className="col-lg-4">
												<label className="form-label" for="name">Power Shared Tenants</label>
												<input autoComplete="off"  type="text" className="form-control" name="name" value={this.state.name} aria-describedby="emailHelp"   onChange={(e)=> this.formItemChange(e)}/>
												<p style={{color:"#f5325c"}}>{this.validator.message('name', this.state.name, 'required')}</p>
											</div>
											
											<div className="col-lg-4">
												<label className="form-label" for="name">Dimension</label>
												<input autoComplete="off"  type="text" className="form-control" name="name" value={this.state.name} aria-describedby="emailHelp"   onChange={(e)=> this.formItemChange(e)}/>
												<p style={{color:"#f5325c"}}>{this.validator.message('name', this.state.name, 'required')}</p>
											</div>
                                        </div>

										<div className="row">
											<div className="col-lg-4">
												<label className="form-label" for="name">Free Land Available</label>
												<input autoComplete="off"  type="text" className="form-control" name="name" value={this.state.name} aria-describedby="emailHelp"   onChange={(e)=> this.formItemChange(e)}/>
												<p style={{color:"#f5325c"}}>{this.validator.message('name', this.state.name, 'required')}</p>
											</div>
											<div className="col-lg-4">
												<label className="form-label" for="name">Site Category</label>
												<input autoComplete="off"  type="text" className="form-control" name="name" value={this.state.name} aria-describedby="emailHelp"   onChange={(e)=> this.formItemChange(e)}/>
												<p style={{color:"#f5325c"}}>{this.validator.message('name', this.state.name, 'required')}</p>
											</div>
											
											<div className="col-lg-4">
												<label className="form-label" for="name">Cooling Methods</label>
												<input autoComplete="off"  type="text" className="form-control" name="name" value={this.state.name} aria-describedby="emailHelp"   onChange={(e)=> this.formItemChange(e)}/>
												<p style={{color:"#f5325c"}}>{this.validator.message('name', this.state.name, 'required')}</p>
											</div>
                                        </div>


										<div className="row">
											<div className="col-lg-4">
												<label className="form-label" for="name">Site Owner</label>
												<input autoComplete="off"  type="text" className="form-control" name="name" value={this.state.name} aria-describedby="emailHelp"   onChange={(e)=> this.formItemChange(e)}/>
												<p style={{color:"#f5325c"}}>{this.validator.message('name', this.state.name, 'required')}</p>
											</div>
											<div className="col-lg-4">
												<label className="form-label" for="name">Shelter Type</label>
												<input autoComplete="off"  type="text" className="form-control" name="name" value={this.state.name} aria-describedby="emailHelp"   onChange={(e)=> this.formItemChange(e)}/>
												<p style={{color:"#f5325c"}}>{this.validator.message('name', this.state.name, 'required')}</p>
											</div>
											
											<div className="col-lg-4">
												<label className="form-label" for="name">Shelter Size</label>
												<input autoComplete="off"  type="text" className="form-control" name="name" value={this.state.name} aria-describedby="emailHelp"   onChange={(e)=> this.formItemChange(e)}/>
												<p style={{color:"#f5325c"}}>{this.validator.message('name', this.state.name, 'required')}</p>
											</div>
                                        </div>
										<div className="row">
											<div className="col-lg-4">
												<label className="form-label" for="name">Dependent Cell Count</label>
												<input autoComplete="off"  type="text" className="form-control" name="name" value={this.state.name} aria-describedby="emailHelp"   onChange={(e)=> this.formItemChange(e)}/>
												<p style={{color:"#f5325c"}}>{this.validator.message('name', this.state.name, 'required')}</p>
											</div>
											<div className="col-lg-4">
												<label className="form-label" for="name">Manual Distance</label>
												<input autoComplete="off"  type="text" className="form-control" name="name" value={this.state.name} aria-describedby="emailHelp"   onChange={(e)=> this.formItemChange(e)}/>
												<p style={{color:"#f5325c"}}>{this.validator.message('name', this.state.name, 'required')}</p>
											</div>
											
											<div className="col-lg-4">
												<label className="form-label" for="name">Manual Delivery</label>
												<input autoComplete="off"  type="text" className="form-control" name="name" value={this.state.name} aria-describedby="emailHelp"   onChange={(e)=> this.formItemChange(e)}/>
												<p style={{color:"#f5325c"}}>{this.validator.message('name', this.state.name, 'required')}</p>
											</div>
                                        </div>
										<div className="row">
											<div className="col-lg-4">
												<label className="form-label" for="name">Travel Time FromDepot</label>
												<input autoComplete="off"  type="text" className="form-control" name="name" value={this.state.name} aria-describedby="emailHelp"   onChange={(e)=> this.formItemChange(e)}/>
												<p style={{color:"#f5325c"}}>{this.validator.message('name', this.state.name, 'required')}</p>
											</div>
											<div className="col-lg-4">
												<label className="form-label" for="name">Working Days Accessible Hours</label>
												<input autoComplete="off"  type="text" className="form-control" name="name" value={this.state.name} aria-describedby="emailHelp"   onChange={(e)=> this.formItemChange(e)}/>
												<p style={{color:"#f5325c"}}>{this.validator.message('name', this.state.name, 'required')}</p>
											</div>
											
											<div className="col-lg-4">
												<label className="form-label" for="name">weekends Or Holidays Accessible Hours</label>
												<input autoComplete="off"  type="text" className="form-control" name="name" value={this.state.name} aria-describedby="emailHelp"   onChange={(e)=> this.formItemChange(e)}/>
												<p style={{color:"#f5325c"}}>{this.validator.message('name', this.state.name, 'required')}</p>
											</div>
                                        </div>
										<div className="row">
											<div className="col-lg-4">
												<label className="form-label" for="name">Year of the JFS</label>
												<input autoComplete="off"  type="text" className="form-control" name="name" value={this.state.name} aria-describedby="emailHelp"   onChange={(e)=> this.formItemChange(e)}/>
												<p style={{color:"#f5325c"}}>{this.validator.message('name', this.state.name, 'required')}</p>
											</div>
											<div className="col-lg-4">
												<label className="form-label" for="name">isIdleGeneratorHutAvaialable</label>
												<input autoComplete="off"  type="text" className="form-control" name="name" value={this.state.name} aria-describedby="emailHelp"   onChange={(e)=> this.formItemChange(e)}/>
												<p style={{color:"#f5325c"}}>{this.validator.message('name', this.state.name, 'required')}</p>
											</div>
											
											<div className="col-lg-4">
												<label className="form-label" for="name">isSpaceAvailableNewGen</label>
												<input autoComplete="off"  type="text" className="form-control" name="name" value={this.state.name} aria-describedby="emailHelp"   onChange={(e)=> this.formItemChange(e)}/>
												<p style={{color:"#f5325c"}}>{this.validator.message('name', this.state.name, 'required')}</p>
											</div>
                                        </div>
										<div className="row">
											<div className="col-lg-4">
												<label className="form-label" for="name">siteOnAirDate</label>
												<input autoComplete="off"  type="text" className="form-control" name="name" value={this.state.name} aria-describedby="emailHelp"   onChange={(e)=> this.formItemChange(e)}/>
												<p style={{color:"#f5325c"}}>{this.validator.message('name', this.state.name, 'required')}</p>
											</div>
											<div className="col-lg-4">
												<label className="form-label" for="name">pgMannualDistance</label>
												<input autoComplete="off"  type="text" className="form-control" name="name" value={this.state.name} aria-describedby="emailHelp"   onChange={(e)=> this.formItemChange(e)}/>
												<p style={{color:"#f5325c"}}>{this.validator.message('name', this.state.name, 'required')}</p>
											</div>
											
											<div className="col-lg-4">
												<label className="form-label" for="name">siteAddressList</label>
												<input autoComplete="off"  type="text" className="form-control" name="name" value={this.state.name} aria-describedby="emailHelp"   onChange={(e)=> this.formItemChange(e)}/>
												<p style={{color:"#f5325c"}}>{this.validator.message('name', this.state.name, 'required')}</p>
											</div>
                                        </div>
                                    </form>           
									<button   className="btn btn-primary" onClick={()=> this.createOrganization()}>Submit</button>                                 
                                </div> 
                            </div> 
                        </div> 
                     </div> 
					<ToastContainer position="top-right" autoClose={5000}/>
			</div>
		);
	}
}


function mapStateToProps(state) { 
	//console.log(state)
	return {
		vendors: state.vendor ,
		
	};
  }
  
  function mapDispatchToProps(dispatch) {
	return {
		addVendor : data => dispatch(addVendor(data))
	};
  }
  
export default connect(
	mapStateToProps,mapDispatchToProps 
  )(SiteCreation);  


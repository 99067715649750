import React, { Component } from 'react'
import { connect } from "react-redux";
import { Line } from '@ant-design/plots';
import { getDonationCategories, getData } from "../../actions/index";
import { VehicleDashboardCounts, VehicleDashboardCounts2, getMonthlyRegionViceMillage, getVehicleDashboardCharts } from "../../api/index";
import SpeedMeterChat from '../../components/charts/speed-meter';
import StackedBarLine from '../../components/charts/stacked-bar-line';
import StackedBarChat from '../../components/charts/stacked-bar';
import ColumnChartDataLable from '../../components/charts/column-charts-data-lables';
import LineCharts from '../../components/charts/line-charts';
import ColumnChartsDataLablesChart from '../../components/charts/column-charts-data-lables'
import SemiCircleGaugeChat from '../../components/charts/semi-circle-gauge'
import {
    PlusCircleFilled,
    PlusSquareFilled,
    ClockCircleFilled,
    CarFilled,
    UserOutlined,
    CarOutlined,
    PlusCircleOutlined,
    NodeIndexOutlined,
    FieldTimeOutlined
} from '@ant-design/icons';

import { Select, DatePicker, Space, Badge } from "antd";
import moment from 'moment';
const { RangePicker } = DatePicker;

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            SubRegion_list: [],
            depot_list: [],
            filter_date: moment(moment().format("YYYY-MM-DD").slice(0, -2) + '01')._i,
            filter_date_to: moment().format("YYYY-MM-DD"),
            filter_depot: [],
            filter_region: [],
            filter_sub_region: [],
            journey_end_count: 0,
            journey_admin_verify_count: 0,
            journey_start_count: 0,
            journey_officer_verify_count: 0,
            journey_vehicle_release_count: 0,
            total_count: 0,
            journey_initiate_count: 0,
            journey_canceled_count: 0,
            getAllData: [],
            vehicleSeriesCount: [20, 28, 30, 12],
            vehicleSerieslabels: [],
            runningKMChart2: false,
            runningKMChart2SetY1: null,
            runningKMChart2SetX1: null,
            runningKMChart2: false,
            runningKMChart2SetY1: null,
            runningKMChart2SetX1: null,
            requesteTrendChart: false,
            requesteTrendChartSetY1: null,
            requesteTrendChartSetX1: null,
            journeyStatus: false,
            journeyStatusSetY1: null,
            journeyStatusSetX1: null,
            runningKMChart1: false,
            runningKMChart1SetY1: null,
            runningKMChart1SetX1: null,

            vehicleRegionViceMillageERChart: false,
            vehicleRegionViceMillageERChartValues: null,
            vehicleRegionViceMillageERChartTarget: null,
            vehicleRegionViceMillageERChartRealValue: null,

            vehicleRegionViceMillageWRChart: false,
            vehicleRegionViceMillageWRChartValues: null,
            vehicleRegionViceMillageWRChartTarget: null,
            vehicleRegionViceMillageWRChartRealValue: null,


            vehicleRegionViceMillageCRChart: false,
            vehicleRegionViceMillageCRChartValues: null,
            vehicleRegionViceMillageCRChartTarget: null,
            vehicleRegionViceMillageCRChartRealValue: null,

            vehicleRegionViceMillageSRChart: false,
            vehicleRegionViceMillageSRChartValues: null,
            vehicleRegionViceMillageSRChartTarget: null,
            vehicleRegionViceMillageSRChartRealValue: null,
            vehicleRegionViceMillageNRChart: false,
            vehicleRegionViceMillageNRChartValues: null,
            vehicleRegionViceMillageNRChartTarget: null,
            vehicleRegionViceMillageNRChartRealValue: null,

            runningKMChartWithcumulativeData: null,
            journeyStatusChartData: null,
            requestTrendChartData: null
        }
    }
    componentDidMount() {
        this.getDashboardChartData();
        this.props.getData();
        var data = new FormData();
        console.log(this.state.filter_date);
        console.log(this.state.filter_date_to);
        data.append('scheduleDateFrom', this.state.filter_date);
        data.append('scheduleDateTo', this.state.filter_date_to);
        data.append('regions', this.state.filter_region);
        data.append('subregions', this.state.filter_sub_region);

        this.getVehicleDashboardCounts(data);
        this.getVehicleDashboardCounts2(data);

        this.getDataMonthlyRegionViceMillage();
        this.setState({
            depot_list: this.props.depots,
            SubRegion_list: this.props.regions.filter((item) => {
                return item.isSubRegion == 1;
            }),
        });
    }
    getDashboardChartData = async () => {
        var res = await getVehicleDashboardCharts();
        console.log('getVehicleDashboardCharts')
        console.log(res)
        if (res.success) {
            this.setState({
                runningKMChartWithcumulativeData: res?.data?.runningKMs,
                journeyStatusChartData: res?.data?.journeyStatus,
                requestTrendChartData: res?.data?.requestTrend,
                runningKM2ChartData: res?.data?.runningKMs2,
            }, () => {
                this.runningKMChartWithcumulativeDataSet(this.state.runningKMChartWithcumulativeData)
                this.journeyStatusDataSet(this.state.journeyStatusChartData)
                this.requestTrendDataSet(this.state.requestTrendChartData)
                this.runningKM2ChartDataSet(this.state.runningKM2ChartData)
            })
        }
    }
    runningKM2ChartDataSet(data) {
        console.log(data)
        data.reverse();
        if (data != null) {
            let countSet = data.map(a => a.count);
            let monthSet = data.map(a => a.month.substring(0, 3));
            this.setState({
                runningKMChart2SetY1: [{
                    name: 'Inflation',
                    data: countSet
                }],
                runningKMChart2SetX1: monthSet
            }, () => {
                this.setState({
                    runningKMChart2: true
                })
            })
        }
    }
    requestTrendDataSet(data) {
        console.log("Request Trend Data Set : "+data);
        if (data != null) {
            
            // let fff = data.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
            // let dateSet = fff.map(a => a.date);
            // let countSet = fff.map(a => a.count);

            const today = new Date();
            const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
            const formattedFirstDay = firstDayOfMonth.toISOString().split('T')[0];

            const filteredData = data
                .filter(entry => entry.date >= formattedFirstDay && entry.date <= today.toISOString().split('T')[0])
                .sort((a, b) => Date.parse(a.date) - Date.parse(b.date));

            const countSet = filteredData.map(entry => entry.count);
            const dateSet = filteredData.map(entry => entry.date);

            this.setState({
                requesteTrendChartSetY1: [{
                    name: "Request",
                    data: countSet
                }],
                requesteTrendChartSetX1: dateSet,
            }, () => {
                this.setState({
                    requesteTrendChart: true
                })
            })
        }
    }
    journeyStatusDataSet(data) {
        console.log('journeyStatusDataSet')
        console.log(data)
        if (data != null) {
            data.splice(5, 3);
            let fff = data.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
            let regionSet = data.map(a => a.region != null ? a.region : 'null');
            let canceledSet = fff.map(a => a.canceled);
            let compleateSet = fff.map(a => a.compleate);
            let pendingSet = fff.map(a => a.pending);

            console.log(regionSet)
            this.setState({
                journeyStatusSetY1: [{
                    name: 'Cancelled',
                    type: 'column',

                    data: canceledSet
                }, {
                    name: 'Completed',
                    type: 'column',

                    data: compleateSet
                }, {
                    name: 'Pending',
                    type: 'column',

                    data: pendingSet
                },],
                journeyStatusSetX1: regionSet,
            }, () => {
                this.setState({
                    journeyStatus: true
                })
            })
        }
    }
    runningKMChartWithcumulativeDataSet(data) {
        console.log('runningKMChartWithcumulativeDataSet')
        console.log(data)
        if (data != null) {
            let fff = data.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
            let dateSet = fff.map(a => a.date);
            let cumulative_sumSet = fff.map(a => a.cumulative_sum);
            let WRSet = fff.map(a => a.WR);
            let CRSet = fff.map(a => a.CR);
            let ERSet = fff.map(a => a.ER);
            let NRSet = fff.map(a => a.NR);
            let SRSet = fff.map(a => a.SR);

            console.log(NRSet)
            this.setState({
                runningKMChart1SetY1: [
                    {
                        label: 'Cumulative Cost',
                        yAxisID: 'y2',
                        type: 'line',
                        data: cumulative_sumSet,
                        borderWidth: 2,
                        backgroundColor: 'rgb(53, 162, 235)',
                        borderColor: 'rgb(53, 162, 200)',
                    },
                    {
                        label: 'CR',
                        yAxisID: 'y',
                        data: CRSet,
                        backgroundColor: 'rgb(255, 99, 132)',
                    },
                    {
                        label: 'ER',
                        yAxisID: 'y',
                        data: ERSet,
                        backgroundColor: 'rgb(255,165,0)',
                    },
                    {
                        label: 'NR',
                        yAxisID: 'y',
                        data: NRSet,
                        backgroundColor: 'rgb(0,250,154)',
                    },
                    {
                        label: 'SR',
                        yAxisID: 'y',
                        data: SRSet,
                        backgroundColor: 'rgb(0,191,255)',
                    },
                    {
                        label: 'WR',
                        yAxisID: 'y',
                        data: WRSet,
                        backgroundColor: 'rgb(139,0,139)',
                    },

                ],
                runningKMChart1SetX1: dateSet,
            }, () => {
                this.setState({
                    runningKMChart1: true
                })
            })

        }

    }
    getDataMonthlyRegionViceMillage = async (data) => {
        var res = await getMonthlyRegionViceMillage(data);
        console.log('getMonthlyRegionViceMillage');
        console.log(res);
        if (res.success) {
            if (res?.data != null) {
                res?.data.map((x, y) => {
                    if (x.region == 'Eastern (ER)') {
                        let setvaluesscr = (x?.value / x?.target)
                        console.log('setvaluesscr' + setvaluesscr)
                        this.setState({
                            vehicleRegionViceMillageERChartValues: setvaluesscr,
                            vehicleRegionViceMillageERChartTarget: x?.target,
                            vehicleRegionViceMillageERChartRealValue: x?.value,
                        }, () => {
                            this.setState({
                                vehicleRegionViceMillageERChart: true
                            })
                        })
                    }
                    if (x.region == 'Western (WR)') {
                        let setvaluesscr = (x?.value / x?.target)
                        console.log('setvaluesscr' + setvaluesscr)
                        this.setState({
                            vehicleRegionViceMillageWRChartValues: setvaluesscr,
                            vehicleRegionViceMillageWRChartTarget: x?.target,
                            vehicleRegionViceMillageWRChartRealValue: x?.value,
                        }, () => {
                            this.setState({
                                vehicleRegionViceMillageWRChart: true
                            })
                        })
                    }
                    if (x.region == 'Central (CR)') {
                        let setvaluesscr = (x?.value / x?.target)

                        console.log('setvaluesscr' + setvaluesscr)
                        this.setState({
                            vehicleRegionViceMillageCRChartValues: setvaluesscr,
                            vehicleRegionViceMillageCRChartTarget: x?.target,
                            vehicleRegionViceMillageCRChartRealValue: x?.value,
                        }, () => {
                            this.setState({
                                vehicleRegionViceMillageCRChart: true
                            })
                        })
                    }
                    if (x.region == 'Southern (SR)') {
                        let setvaluesscr = (x?.value / x?.target)
                        console.log('setvaluesscr' + setvaluesscr)
                        this.setState({
                            vehicleRegionViceMillageSRChartValues: setvaluesscr,
                            vehicleRegionViceMillageSRChartTarget: x?.target,
                            vehicleRegionViceMillageSRChartRealValue: x?.value,
                        }, () => {
                            this.setState({
                                vehicleRegionViceMillageSRChart: true
                            })
                        })
                    }
                    if (x.region == 'Northern (NR)') {
                        let setvaluesscr = (x?.value / x?.target)

                        console.log('setvaluesscr' + setvaluesscr)
                        this.setState({
                            vehicleRegionViceMillageNRChartValues: setvaluesscr,
                            vehicleRegionViceMillageNRChartTarget: x?.target,
                            vehicleRegionViceMillageNRChartRealValue: x?.value,
                        }, () => {
                            this.setState({
                                vehicleRegionViceMillageNRChart: true
                            })
                        })
                    }
                })
            }
        }
    }


    // Main Filteration Section Data Fetching And Data Loading
    getVehicleDashboardCounts = async (data) => {
        var data = data;
        var res = await VehicleDashboardCounts(data);
        console.log(res);

        if (res.success) {
            this.setState({
                journey_admin_verify_count: res.data.journey_admin_verify,
                journey_start_count: res.data.journey_start,
                journey_end_count: res.data.journey_end,
                journey_canceled_count: res.data.journey_canceled,
                journey_initiate_count: res.data.journey_initiate,
                journey_officer_verify_count: res.data.journey_officer_verify,
                journey_vehicle_release_count: res.data.journey_vehicle_release,
                total_count: res.data.total,
            })
        }
    };
    getVehicleDashboardCounts2 = async (data) => {
        var data = data;
        var res = await VehicleDashboardCounts2(data);
        console.log("VehicleDashboardCounts2");
        console.log(res);
        if (res.success) {
            this.setState({
                getAllData: res.data
            })
        }
    };
    onChangeRegion = (value) => {
        this.setState({
            depot_list: [],
            filter_depot: [],
            filter_sub_region: [],

        });
        console.log(value);
        this.setState({
            filter_region: value,
            selectedDepot: "all_depots",
            selected_region_id: value,
            SubRegion_list: this.props.regions.filter(alldate => value.map((n) => n).includes(alldate.parentRegion?.id)),
            depot_list: this.props.depots.filter(alldate => value.map((n) => n).includes(alldate.region?.parentRegion?.id))
        }, () => {
            if (this.state.filter_sub_region == "") {
                var getall = this.props.regions.filter(alldate => value.includes(alldate.parentRegion?.id))
                var output = [];
                for (var i = 0; i < getall.length; ++i)
                    output.push(getall[i]['id']);
                var data1 = new FormData();

                console.log(this.state.filter_region);
                data1.append('scheduleDateFrom', this.state.filter_date);
                data1.append('scheduleDateTo', this.state.filter_date_to);
                data1.append('regions', this.state.filter_region);
                data1.append('subregions', this.state.filter_sub_region);
                this.getVehicleDashboardCounts(data1);
                this.getVehicleDashboardCounts2(data1);
            }
        });
        var data = {
            id: value,
            type: "region",
        };
    };
    dataonChange = (date, dateString) => {
        this.setState({
            filter_date: dateString[0],
            filter_date_to: dateString[1],
        });
    };
    onsubChange = (value) => {
        this.setState({
            depot_list: [],
            filter_depot: [],
        });
        this.setState({
            selectedDepot: "all_depots",
            selected_region_id: value,
            filter_sub_region: value,
            depot_list: this.props.depots.filter(alldate => value.map((n) => n).includes(alldate.region?.id))
        }, () => {
            var data1 = new FormData();
            data1.append('scheduleDateFrom', this.state.filter_date);
            data1.append('scheduleDateTo', this.state.filter_date_to);
            data1.append('regions', this.state.filter_region);
            data1.append('subregions', this.state.filter_sub_region);

            this.getVehicleDashboardCounts(data1);
            this.getVehicleDashboardCounts2(data1);
        });
        var data = {
            id: value,
            type: "region",
        };
    };

    stripYearFromDates(dates) {
        return dates.map(date => date.slice(8));
    }

    render() {
        const { Option } = Select;

        function onBlur() {
            //console.log("blur");
        }

        function onFocus() {
            //console.log("focus");
        }

        function onSearch(val) {
            //console.log("search:", val);
        }
        const { vehicleSeriesCount } = this.state
        const { vehicleSerieslabels } = this.state
        return (
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="page-title-box">
                            <div class="row">
                                <div class="col">
                                    <h4 class="page-title">Dashboard</h4>
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="javascript:void(0);">Dashboard</a></li>
                                        <li class="breadcrumb-item"><a href="javascript:void(0);">Vehicle</a></li>
                                    </ol>
                                </div>
                                <div class="col-auto align-self-center">
                                    <a href="#" class="btn btn-sm btn-outline-primary" id="Dash_Date">
                                        <span class="day-name" id="Day_Name">Today:</span>&nbsp;
                                        <span class="" id="Select_date">{new Date().toUTCString().slice(5, 16)}</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar align-self-center icon-xs ms-1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                                    </a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div className="row" style={{
                        background: '#fafafa', padding: '20px', borderRadius: '10px'
                    }}>
                            <div className="col-md-2 col-lg-3">
                                <RangePicker
                                    style={{ width: "100%", borderRadius: "5px" }}
                                    defaultValue={[moment(moment().format("YYYY-MM-DD").slice(0, -2) + '01'), moment()]}
                                    onChange={this.dataonChange}
                                />
                            </div>
                            <div className="col-md-2 col-lg-3">
                                <Select
                                    mode="multiple"
                                    showSearch
                                    style={{ width: "100%" }}
                                    placeholder="Select a region"
                                    optionFilterProp="children"
                                    onChange={this.onChangeRegion}
                                    onFocus={onFocus}
                                    onBlur={onBlur}
                                    onSearch={onSearch}
                                    filterOption={(input, option) =>
                                        option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {this.props.regions.filter(allret => "0".includes(allret.isSubRegion)).map((item, index) => {
                                        return <Option value={item.id}>{item.name}</Option>;
                                    })}
                                </Select>
                            </div>
                            <div className="col-md-3 col-lg-3">
                                <Select
                                    mode="multiple"
                                    showSearch
                                    style={{ width: "100%" }}
                                    placeholder="Select a Subregion"
                                    optionFilterProp="children"
                                    onChange={this.onsubChange}
                                    onFocus={onFocus}
                                    onBlur={onBlur}
                                    onSearch={onSearch}
                                    filterOption={(input, option) =>
                                        option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {this.state.SubRegion_list.map((item, index) => {
                                        return <Option value={item.id}>{item.name}</Option>;
                                    })}
                                </Select>
                            </div>
                            <div className="col-md-2 col-lg-3">
                                <Select
                                    mode="multiple"
                                    showSearch
                                    style={{ width: "100%" }}
                                    placeholder="Select a depot"
                                    optionFilterProp="children"
                                    onChange={this.onChange2}
                                    defaultActiveFirstOption={true}
                                    allowClear={true}
                                    filterOption={(input, option) =>
                                        option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {this.state.depot_list.map((item, index) => {
                                        return <Option value={item.id}>{item.name}</Option>;
                                    })}
                                </Select>
                            </div>
                        </div>
                    </div>
                </div>
                <br></br>

                <div class="row">
                    <div className="col-md-12 col-lg-12">
                        <div class="row justify-content-center">
                            <div class="col-md-2 col-lg-3" style={{ width: "20%" }}>
                                <div class="card report-card  bg-soft-primary" style={{ borderRadius: '20px' }}>
                                    <div class="card-body">
                                        <div class="row d-flex justify-content-center">
                                            <div class="col">
                                                <p class="mb-0 fw-semibold" style={{
                                                    fontSize: "14px"
                                                }}>
                                                    <b>Total Vehicle <br />Requests</b></p>
                                                <h2 class="m-0" style={{ marginTop: '3px' }}>{this.state.getAllData?.totalVehicleRequests ? (this.state.getAllData?.totalVehicleRequests) : (0)}</h2>
                                            </div>
                                            <div class="col-auto align-self-center">
                                                <div style={{
                                                    background: "white", borderRadius: "50%", width: "40px", height: "40px", display: "grid",
                                                    alignItems: "center", justifyContent: "center", margin: "auto"
                                                }} float="left" className="mt-1">
                                                    <PlusCircleOutlined style={{ color: "#1761fd", fontSize: '20px' }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-2 col-lg-3" style={{ width: "20%" }}>
                                <div class="card report-card bg-soft-success" style={{ borderRadius: '20px' }}>
                                    <div class="card-body" >
                                        <div class="row d-flex justify-content-center">
                                            <div class="col">
                                                <p class="mb-0 fw-semibold" style={{
                                                    fontSize: "14px"
                                                }}><b>Total No.<br />Journeys</b></p>
                                                <h2 class="m-0" style={{ marginTop: '3px' }}>{this.state.getAllData?.totalJourneys ? (this.state.getAllData?.totalJourneys) : (0)}</h2>
                                            </div>
                                            <div class="col-auto align-self-center">
                                                <div style={{
                                                    background: "white", borderRadius: "50%", width: "40px", height: "40px", display: "grid",
                                                    alignItems: "center", justifyContent: "center", margin: "auto"
                                                }} float="left" className="mt-1">
                                                    <NodeIndexOutlined style={{ color: "#99db7f", fontSize: '20px' }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 col-lg-3" style={{ width: "20%" }}>
                                <div class="card report-card bg-soft-pink" style={{ borderRadius: '20px' }}>
                                    <div class="card-body" >
                                        <div class="row d-flex justify-content-center">
                                            <div class="col">
                                                <p class="mb-0 fw-semibold" style={{
                                                    fontSize: "14px"
                                                }}><b>Opening <br />Journeys</b></p>
                                                <h2 class="m-0" style={{ marginTop: '3px' }}>{this.state.getAllData?.ongoingJourneys ? (this.state.getAllData?.ongoingJourneys) : (0)}</h2>
                                            </div>
                                            <div class="col-auto align-self-center">
                                                <div style={{
                                                    background: "white", borderRadius: "50%", width: "40px", height: "40px", display: "grid",
                                                    alignItems: "center", justifyContent: "center", margin: "auto"
                                                }} float="left" className="mt-1">
                                                    <FieldTimeOutlined style={{ color: "#fd3ca3", fontSize: '20px' }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 col-lg-3" style={{ width: "20%" }}>
                                <div class="card report-card bg-soft-warning" style={{ borderRadius: '20px' }}>
                                    <div class="card-body" >
                                        <div class="row d-flex justify-content-center">
                                            <div class="col">
                                                <p class="mb-0 fw-semibold" style={{
                                                    fontSize: "14px"
                                                }}><b>Number Of<br /> Vehicles</b></p>
                                                <h2 class="m-0" style={{ marginTop: '3px' }}>{this.state.getAllData?.vehicles ? (this.state.getAllData?.vehicles) : (0)}</h2>
                                            </div>
                                            <div class="col-auto align-self-center">
                                                <div style={{
                                                    background: "white", borderRadius: "50%", width: "40px", height: "40px", display: "grid",
                                                    alignItems: "center", justifyContent: "center", margin: "auto"
                                                }} float="left" className="mt-1">
                                                    <CarOutlined style={{ color: "#ffda7e", fontSize: '20px' }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 col-lg-3" style={{ width: "20%" }}>
                                <div class="card report-card bg-soft-info" style={{ borderRadius: '20px' }}>
                                    <div class="card-body" >
                                        <div class="row d-flex justify-content-center">
                                            <div class="col">
                                                <p class="mb-0 fw-semibold" style={{
                                                    fontSize: "14px"
                                                }}><b>Number Of<br />Drivers</b></p>
                                                <h2 class="m-0" style={{ marginTop: '3px' }}>{this.state.getAllData?.drivers ? (this.state.getAllData?.drivers) : (0)}</h2>
                                            </div>
                                            <div class="col-auto align-self-center">
                                                <div style={{
                                                    background: "white", borderRadius: "50%", width: "40px", height: "40px", display: "grid",
                                                    alignItems: "center", justifyContent: "center", margin: "auto"
                                                }} float="left" className="mt-1">
                                                    <UserOutlined style={{ color: "#2d81fe", fontSize: '20px' }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <br></br>
                <div className='col-md-12'>

                    <div className="card">
                        <div className="card-header">
                            <div className='row'>
                                <div className="col-md-3">
                                    <h4 className="card-title" style={{
                                            fontWeight: "bold", fontFamily: 'Roboto', textTransform: 'capitalize'
                                        }}>Vehicle Running KM's Monthly</h4>
                                </div>
                                <div className="container" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <div className="col">
                                        <span><Badge color="#2D5FF4" text="Value" style={{ marginLeft: "10px", fontFamily: 'Roboto', fontSize: '9px', fontWeight: 'bold' }} /></span>
                                        <span style={{ marginLeft: "10px", fontFamily: 'Roboto', fontSize: '9px', fontWeight: 'bold' }}><Badge color="#42B02A" text="Target" /></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card-body">
                            <div className='row'>

                                {/* <div class="col-md-6 col-lg-2" style={{ textAlign: "center", width: "20%" }} >
                                    {this.state.vehicleRegionViceMillageWRChart ? (<SpeedMeterChat setValues={this.state.vehicleRegionViceMillageWRChartValues} realValue={this.state.vehicleRegionViceMillageWRChartRealValue} targetValue={this.state.vehicleRegionViceMillageWRChartTarget} setTitel="WESTERN" />) : ('')}
                                </div> */}

                                <div class="col-md-6 col-lg-3" style={{ textAlign: "center", width: "20%" }} >
                                    {this.state.vehicleRegionViceMillageWRChartTarget ? (
                                        <SemiCircleGaugeChat
                                            setcolor="#2D5FF4"
                                            setvalues={((this.state.vehicleRegionViceMillageWRChartRealValue / this.state.vehicleRegionViceMillageWRChartTarget) * 100).toFixed(2)}
                                            setTitel="Western"
                                            realValue={this.state.vehicleRegionViceMillageWRChartRealValue}
                                            targetValue={this.state.vehicleRegionViceMillageWRChartTarget}
                                        />
                                    ) : (
                                        console.log(((this.state.vehicleRegionViceMillageCRChartRealValue / this.state.vehicleRegionViceMillageCRChartTarget) * 100).toFixed(2))
                                    )}
                                </div>

                                {/* <div class="col-md-6 col-lg-2" style={{ textAlign: "center", width: "20%" }} >
                                    {this.state.vehicleRegionViceMillageSRChart ? (<SpeedMeterChat setValues={this.state.vehicleRegionViceMillageSRChartValues} realValue={this.state.vehicleRegionViceMillageSRChartRealValue} targetValue={this.state.vehicleRegionViceMillageSRChartTarget} setTitel="SOUTHERN" />) : ('')}
                                </div> */}

                                <div class="col-md-6 col-lg-3" style={{ textAlign: "center", width: "20%" }} >
                                    {this.state.vehicleRegionViceMillageSRChartTarget ? (
                                        <SemiCircleGaugeChat
                                            setcolor="#2D5FF4"
                                            setvalues={((this.state.vehicleRegionViceMillageSRChartRealValue / this.state.vehicleRegionViceMillageSRChartTarget) * 100).toFixed(2)}
                                            setTitel="Southern"
                                            realValue={this.state.vehicleRegionViceMillageSRChartRealValue}
                                            targetValue={this.state.vehicleRegionViceMillageSRChartTarget}
                                        />
                                    ) : (
                                        console.log(((this.state.vehicleRegionViceMillageCRChartRealValue / this.state.vehicleRegionViceMillageCRChartTarget) * 100).toFixed(2))
                                    )}
                                </div>

                                {/* <div class="col-md-6 col-lg-2" style={{ textAlign: "center", width: "20%" }} >
                                    {this.state.vehicleRegionViceMillageCRChart ? (<SpeedMeterChat setValues={this.state.vehicleRegionViceMillageCRChartValues} realValue={this.state.vehicleRegionViceMillageCRChartRealValue} targetValue={this.state.vehicleRegionViceMillageCRChartTarget} setTitel="CENTRAL" />) : ('')}
                                </div> */}

                                <div class="col-md-6 col-lg-3" style={{ textAlign: "center", width: "20%" }} >
                                    {this.state.vehicleRegionViceMillageCRChartTarget ? (
                                        <SemiCircleGaugeChat
                                            setcolor="#2D5FF4"
                                            setvalues={((this.state.vehicleRegionViceMillageCRChartRealValue / this.state.vehicleRegionViceMillageCRChartTarget) * 100).toFixed(2)}
                                            setTitel="Central"
                                            realValue={this.state.vehicleRegionViceMillageCRChartRealValue}
                                            targetValue={this.state.vehicleRegionViceMillageCRChartTarget}
                                        />
                                    ) : (
                                        console.log(((this.state.vehicleRegionViceMillageCRChartRealValue / this.state.vehicleRegionViceMillageCRChartTarget) * 100).toFixed(2))
                                    )}
                                </div>

                                {/* <div class="col-md-6 col-lg-2" style={{ textAlign: "center", width: "20%" }} >
                                    {this.state.vehicleRegionViceMillageNRChart ? (<SpeedMeterChat setValues={this.state.vehicleRegionViceMillageNRChartValues} realValue={this.state.vehicleRegionViceMillageNRChartRealValue} targetValue={this.state.vehicleRegionViceMillageNRChartTarget} setTitel="NORTHERN" />) : ('')}
                                </div> */}

                                <div class="col-md-6 col-lg-3" style={{ textAlign: "center", width: "20%" }} >
                                    {this.state.vehicleRegionViceMillageNRChartTarget ? (
                                        <SemiCircleGaugeChat
                                            setcolor="#2D5FF4"
                                            setvalues={((this.state.vehicleRegionViceMillageNRChartRealValue / this.state.vehicleRegionViceMillageNRChartTarget) * 100).toFixed(2)}
                                            setTitel="Northern"
                                            realValue={this.state.vehicleRegionViceMillageNRChartRealValue}
                                            targetValue={this.state.vehicleRegionViceMillageNRChartTarget}
                                        />
                                    ) : (
                                        console.log(((this.state.vehicleRegionViceMillageCRChartRealValue / this.state.vehicleRegionViceMillageCRChartTarget) * 100).toFixed(2))
                                    )}
                                </div>

                                {/* <div class="col-md-6 col-lg-2" style={{ textAlign: "center", width: "20%" }} >
                                    {this.state.vehicleRegionViceMillageERChart ? (<SpeedMeterChat setValues={this.state.vehicleRegionViceMillageERChartValues} realValue={this.state.vehicleRegionViceMillageERChartRealValue} targetValue={this.state.vehicleRegionViceMillageERChartTarget} setTitel="EASTERN" />) : ('')}
                                </div> */}

                                <div class="col-md-6 col-lg-3" style={{ textAlign: "center", width: "20%" }} >
                                    {this.state.vehicleRegionViceMillageERChartTarget ? (
                                        <SemiCircleGaugeChat
                                            setcolor="#2D5FF4"
                                            setvalues={((this.state.vehicleRegionViceMillageERChartRealValue / this.state.vehicleRegionViceMillageERChartTarget) * 100).toFixed(2)}
                                            setTitel="Eastern"
                                            realValue={this.state.vehicleRegionViceMillageERChartRealValue}
                                            targetValue={this.state.vehicleRegionViceMillageERChartTarget}
                                        />
                                    ) : (
                                        console.log(((this.state.vehicleRegionViceMillageCRChartRealValue / this.state.vehicleRegionViceMillageCRChartTarget) * 100).toFixed(2))
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

                <br></br>

                <div className="row">
                    <div className="col-md-12 col-lg-6">
                        <div className="card">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-md-3">
                                        <h4 className="card-title" style={{
                                            fontWeight: "bold", fontFamily: 'Roboto', textTransform: 'capitalize'
                                        }}>Running KM's</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">

                                {this.state.runningKMChart1 ? (<StackedBarLine y1={this.state.runningKMChart1SetY1} x1={this.state.runningKMChart1SetX1} y1Lable={"Daily KM's"} y2Lable={'MTD'} />) : ('')}

                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-6">
                        <div className="card">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-md-3">
                                        <h4 className="card-title" style={{
                                            fontWeight: "bold", fontFamily: 'Roboto', textTransform: 'capitalize'
                                        }}>Request Trend</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                {this.state.requesteTrendChart ? (
                                <LineCharts y1={this.state.requesteTrendChartSetY1} 
                                x1={this.stripYearFromDates(this.state.requesteTrendChartSetX1)}
                                yLabale={"Total"} />) : ('')}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-md-3">
                                        <h4 className="card-title" style={{
                                            fontWeight: "bold", fontFamily: 'Roboto', textTransform: 'capitalize'
                                        }}>Running KM's</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                {this.state.runningKMChart2 ? (
                                <ColumnChartsDataLablesChart 
                                y1={this.state.runningKMChart2SetY1} 
                                x1={this.state.runningKMChart2SetX1} 
                                barColors={['#42B02A']}
                                yLabale={"Daily KM's"} />) : ('')}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-6">
                        <div className="card">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-md-8">
                                        <h4 className="card-title" style={{
                                            fontWeight: "bold", fontFamily: 'Roboto', textTransform: 'capitalize'
                                        }}>Journey Status</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                {this.state.journeyStatus ? (
                                <StackedBarChat y1={this.state.journeyStatusSetY1} 
                                x1={this.state.journeyStatusSetX1} 
                                yLabale={'Regions'}
                                barColors={['#FF3A29', '#42B02A', '#FFB200']}
                                horizontalStatus={true}
                                />) : ('')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
function mapStateToProps(state) {
    //console.log("state", state);
    return {
        organization_count: state.organization.length,
        regions: state.regions,
        depots: state.depots,
        sites_count: state.sites.length,
        vendor_count: state.vendor.length,
        roles_count: state.roles.length,
    };
}

export default connect(mapStateToProps, { getDonationCategories, getData })(
    Dashboard
);

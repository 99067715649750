import React, { Component } from 'react';
import { connect } from "react-redux";
import { getSiteListByPage, filterSiteData } from '../../api/Repos/SiteRepo';
import { Link } from "react-router-dom";
import Select from 'react-select'
import { Collapse } from 'reactstrap';
import { DatePicker, Table, List, Skeleton, Avatar, Tag } from 'antd';
import { getDonationCategories, getData } from "../../actions/index";
import { getAllDepotList } from "../../api/index";

const options = [
	{ value: 'chocolate', label: 'Chocolate' },
	{ value: 'strawberry', label: 'Strawberry' },
	{ value: 'vanilla', label: 'Vanilla' }
]

class SiteIndex extends Component {

	constructor(props) {
		super(props)
		this.state = {
			pageNo: 1,
			editVisibilty: false,
			site_list: [],
			site_name: "",
			site_id: "",
			depotList: [],
			isOpen: false,
			loading: true,
			depotId: '',
			setLogin: false
		}
	}

	componentDidMount() {

		this.props.getData()
		this.getAllDepotList();
	

		this.getAllSites()
		var _depotList = [];
		this.props.depots.map((depot) => {
			_depotList.push({ value: depot.id, label: depot.name })
		})

		this.setState({
			depotList: _depotList
		})
	}
	getAllDepotList = async () => {
		var res = await getAllDepotList();
		this.setState({
			loadingDepot: true,
			depot_list_new: [],
		})
		console.log(res)
		if (res.success) {
			this.setState({
				depot_list_new: res.data,
				loadingDepot: false
			}, () => {

				var _depotList = [];
			
				this.state.depot_list_new.map((depot) => {
					_depotList.push({ value: depot.id, label: depot.name })
				})

				this.setState({
					depotList: _depotList
				})
			})
		}
	}
	getSetPageData = (serviceTypeIs, Pageid) => {
		//console.log("this.props");
		//console.log(serviceTypeIs.serviceType);
		if (serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == Pageid)[0] == null) {

			this.setState({
				editVisibilty: false
			})
		} else {
			this.setState({
				editVisibilty: true
			})
		}

	};

	getAllSites = async () => {
		var res = await getSiteListByPage(this.state.pageNo);
		this.setState({
			loading: true,
			site_list: [],
		})
		if (res.success) {
			this.setState({
				site_list: res.data,
				loading: false
			})
		}
	}


	previousBtnClick = () => {
		if (this.state.pageNo > 1) {
			this.setState({
				loading: true,
				site_list: [],
			})
			var aa = this.state.pageNo - 1
			this.setState({
				pageNo: aa
			}, () => {
				this.getAllSites();
			})
		}
	}

	nextBtnClick = () => {
		this.setState({
			loading: true,
			site_list: [],
		})
		var aa = this.state.pageNo + 1
		this.setState({
			pageNo: aa
		}, () => {
			this.getAllSites();
		})

	}

	searchFormItemChange = (event) => {
		var obj = {};
		obj[event.target.name] = event.target.value;
		//console.log(obj)
		this.setState(obj);
	}

	searchDepottemChange = (event) => {

		this.setState({
			depotId: event.value
		});
	}

	viewFilterClick = () => {
		this.setState({
			isOpen: !this.state.isOpen
		})
	}

	filterBtnClick = async () => {
		this.setState({
			loading: true,
			site_list: [],
		})
		let formData = new FormData();    //formdata object

		formData.append('depotId', this.state.depotId);   //append the values with key, value pair
		formData.append('siteId', this.state.site_id);
		formData.append('siteName', this.state.site_name);

		var res = await filterSiteData(formData);
		if (res.success) {
			this.setState({
				site_list: res.data,
				loading: false,
			})
		}
	}

	clearBtnClick = () => {
		window.location.reload()
	}

	render() {
		const columns123 = [
			{
				title: "# Site Id",
				// dataIndex: "siteId",
				//	defaultSortOrder: "descend",


				render: (text, record, index) => <span> <u><Link to={'/site-summary/' + text.id}>{text.siteId}</Link></u></span>,
			},
			{
				title: "Site",

				key: "siteName",

				render: (item) => <span  ><u><Link to={'/site-summary/' + item.id}>{item.siteName}</Link></u></span>,

			},
			{
				title: "Region",
				//   dataIndex: "accessPermissionCode",
				key: "accessPermissionCode",
				render: (item) => <span>{item.depot.region.name}</span>,
			},
			{
				title: "Depot",
				//   dataIndex: "accessPermissionCode",
				key: "accessPermissionCode",
				render: (item) => <span>{item.depot.name}</span>,
			},

			{
				title: "Status",
				dataIndex: "status",
				key: "operationTypeName",
				render: (item) => <>
					<Tag color="#03d87f" key={item}>
						<span>Active</span>
					</Tag>
				</>,

			},
			{
				title: "Site Category",
				dataIndex: "siteCategory",
				align: 'center',
				key: "siteCategory",
				render: (item) => <span class="badge badge-outline-primary badge-pill">{item.name}</span>,


			},
			{
				title: "Tower Height(m/ft)",
				dataIndex: "towerHeight",
				align: 'right',
				key: "towerHeight",
				render: (item) => <span>{item}</span>,
			},

			{
				title: "Depot Officer ",
				key: "DepotOfficer ",



				render: (item) => <span>{item.depot.depotOfficers[0].name}</span>,


			},
			{
				title: "Contact No",
				key: "ContactNo",
				align: 'right',
				width: '9%',



				render: (item) => <span>{item.depot.depotOfficers[0].mobileNumber.slice(0, -7) + ' ' + item.depot.depotOfficers[0].mobileNumber.slice(3, -4) + ' ' + item.depot.depotOfficers[0].mobileNumber.slice(-4)}</span>,


			},
			// {
			// 	title: "Action",
			// 	key: "ContactNo",
			// 	align: 'center',



			// 	render: (item) => <span><a href={'/sites/edit/' + item.id} key="list-loadmore-edit"><i class="fa fa-pen"></i></a></span>,


			// },

			// {
			// 	title: 'Action',

			// 	key: 'x',
			// 	render: (item) => <a href='#' onClick={() => this.getSiteAccessDetails(item.id)}><i data-feather="home" class="fa fa-eye"></i></a>,
			//   },

		];
		return (
			<>
			<div className="container-fluid">
					<div class="row">
						<div class="col-sm-12">
							<div class="page-title-box">
								<div class="row">
									<div class="col">
										<h4 class="page-title">Site List </h4>
										<ol class="breadcrumb">
											<li class="breadcrumb-item"><a href="javascript:void(0);">Organization</a></li>
											<li class="breadcrumb-item"><a href="javascript:void(0);">Site </a></li>

										</ol>
									</div>
									<div class="col-auto align-self-center">
										<div className='col-md-1'>



											<button type="button" style={{ marginLeft: 10 }} class="btn btn-danger" onClick={() => this.viewFilterClick()}><i class="fas fa-filter"></i></button>




										</div>


									</div>
								</div>
							</div>
						</div>
					</div>

					<Collapse isOpen={this.state.isOpen}>
						<div className="row">
							<div class="card">

								<div class="card-body bootstrap-select-1">
									<div class="row">
										<div class="col-md-3">
											<label class="mb-3">Depot</label>
											<Select options={this.state.depotList} name="depotId" onChange={(e) => this.searchDepottemChange(e)} />
										</div>
										<div class="col-md-3">
											<label class="mb-3">Site Name</label>
											<input autoComplete="off" style={{ height: 40 }} type="text" value={this.state.site_name} className="form-control" name="site_name" id="site_name" placeholder="Site Name" onChange={(e) => this.searchFormItemChange(e)} />
										</div>
										<div class="col-md-3">
											<label class="mb-3">Site ID</label>
											<input autoComplete="off" style={{ height: 40 }} type="text" value={this.state.description} className="form-control" name="site_id" id="site_id" placeholder="Site ID" onChange={(e) => this.searchFormItemChange(e)} />
										</div>

									</div>
									<div class="col-md-3" style={{ marginTop: 10 }}>
										<button type="button" class="btn btn-primary" onClick={() => this.filterBtnClick()}> Filter</button>
										<button type="button" style={{ marginLeft: 5 }} class="btn btn-dark" onClick={() => this.clearBtnClick()}>Clear Filter</button>
									</div>
								</div>
							</div>
						</div>
					</Collapse>
					<div className="row">
						{/* <div className="col-lg-6">
                            <ul className="list-inline">
                                <li className="list-inline-item">
                                    <h5 className="mt-0">Here the list of all continu projects. <span className="badge badge-pink">6</span></h5>
                                </li>
                            </ul>
                        </div>  */}

						<div className="col-lg-6 text-end">
							<div className="text-end">
								{/* <ul className="list-inline">
                                    <li className="list-inline-item">
                                        <div className="input-group">                               
                                            <input autoComplete="off"  type="text" id="example-input1-group2" name="example-input1-group2" className="form-control form-control-sm" placeholder="Search" />
                                            <button type="button" className="btn btn-soft-primary btn-sm"><i className="fas fa-search"></i></button>
                                        </div>
                                    </li>
                                    <li className="list-inline-item">
                                        <button type="button" className="btn btn-soft-primary btn-sm"><i className="fas fa-filter"></i></button>
                                    </li> 
                                </ul> */}
							</div>
						</div>
					</div>

					<div className="row">
						{this.state.site_list.map((site, index) => {
							// return <div className="col-lg-4" >

							// 	<div className="card">
							// 		<div className="card-body">
							// 			<div className="media mb-3">
							// 				<img src="assets/images/widgets/project2.jpg" alt="" className="thumb-md rounded-circle" />
							// 				<div className="media-body align-self-center text-truncate ms-3">
							// 					<h4 className="m-0 fw-semibold text-dark font-15">{site.siteName}</h4>
							// 					<p className="text-muted  mb-0 font-13"><span className="text-dark">Site ID : </span>{site.siteId}</p>
							// 				</div>


							// 			</div>
							// 			<Link to={'/site-summary/' + site.id}>
							// 				<hr className="hr-dashed" style={{ background: "#adadad" }} />
							// 				<div className="d-flex justify-content-between mb-3">
							// 					<h6 className="fw-semibold m-0">Region : <span className="text-muted fw-normal"> {site.depot.region.name}</span></h6>
							// 					<h6 className="fw-semibold m-0">Status : <span className="text-muted fw-normal"><span class="badge bg-success">ACTIVE</span></span></h6>
							// 				</div>
							// 				<div className="d-flex justify-content-between mb-3">
							// 					<h6 className="fw-semibold m-0">Depot : <span className="text-muted fw-normal"> {site.depot.name}</span></h6>
							// 					<h6 className="fw-semibold m-0"> Site Category: <span className="text-muted fw-normal"> {site.siteCategory.name}</span></h6>

							// 				</div>
							// 				<div className="d-flex justify-content-between mb-3">
							// 					<h6 className="fw-semibold m-0">Tower Legs Count : <span className="text-muted fw-normal"> {site.towerLegsCount == null ? "N/A" : site.towerLegsCount}</span></h6>
							// 					<h6 className="fw-semibold m-0">Tower Height : <span className="text-muted fw-normal"> {site.towerHeight}</span></h6>
							// 				</div>
							// 				<hr className="hr-dashed" style={{ background: "#adadad" }} />
							// 				{site.depot.depotOfficers != null && site.depot.depotOfficers.length != 0 && <div className="d-flex justify-content-between mb-3">
							// 					<h6 className="fw-semibold m-0">Depot Officer : <span className="text-muted fw-normal"> {site.depot.depotOfficers[0].name}</span></h6>

							// 				</div>}
							// 				{site.depot.depotOfficers != null && site.depot.depotOfficers.length != 0 && <div className="d-flex justify-content-between mb-3">
							// 					<h6 className="fw-semibold m-0"> Contact No : <span className="text-muted fw-normal"> {site.depot.depotOfficers[0].mobileNumber}</span></h6>

							// 				</div>}
							// 			</Link>



							// 		</div>
							// 	</div>
							// </div>
						})



						}
						<Table
							size='small'
							fontSize={9}
							columns={columns123}
							bordered
							pagination={false}
							loading={this.state.loading}
							dataSource={this.state.site_list}
						/>
						<div
							style={{
								textAlign: 'center',
								marginTop: 12,
								height: 32,
								lineHeight: '32px',
							}}
						>
							{/* <Button onClick={this.onLoadMore}>loading more</Button> */}
							<div class="row">

								<div class=" col-md-12">
									<div class="dataTables_paginate paging_simple_numbers" id="row_callback_paginate" style={{ display: "flex", textAlign: "end", justifyContent: "end" }}>
										<ul class="pagination">
											<li class="paginate_button page-item previous" id="row_callback_previous">
												<a onClick={() => this.previousBtnClick()} aria-controls="row_callback" data-dt-idx="0" tabindex="0" class="page-link" style={{ fontSize: 15 }}>Previous</a>
											</li>
											<li class="paginate_button page-item ">
												<a aria-controls="row_callback" data-dt-idx="1" tabindex="0" class="page-link" style={{ fontSize: 15 }}>{this.state.pageNo}/250
												</a>
											</li>

											<li class="paginate_button page-item next" id="row_callback_next">
												<a onClick={() => this.nextBtnClick()} aria-controls="row_callback" data-dt-idx="7" tabindex="0" class="page-link" style={{ fontSize: 15 }}>Next
												</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>

				
			</>
		);
	}
}

function mapStateToProps(state) {
	//console.log("state", state);
	return {
		organization_count: state.organization.length,
		regions: state.regions,
		depots: state.depots,
		sites_count: state.sites.length,
		vendor_count: state.vendor.length,
		roles_count: state.roles.length,
	};
}
export default connect(mapStateToProps, { getDonationCategories, getData })(
	SiteIndex
);

// export default connect(
// 	mapStateToProps,
// 	{}
// )(SiteIndex);



import React, { useState, useEffect } from 'react';
import {
    ClockCircleFilled,
    HistoryOutlined
} from '@ant-design/icons';
import { Row, Col, Card, Button, Empty, Modal, Steps, Spin, Tag } from 'antd';
import { getEditHistorySiteAccessRequestREWAMP, getEditHistorySiteAccessRequestByIDREWAMP } from "../../../api/index";

const { Step } = Steps;

const EditHistory = ({ siteAccessRequestID }) => {
    const [editHistory, setEditHistory] = useState([]);
    const [currentStep, setCurrentStep] = useState(0);
    const [editDetails, setEditDetails] = useState([]);
    const [entityType, setEntityType] = useState('SITE_ACCESS_REQUEST');
    const [openModal, setOpenModal] = useState(false);
    const [selectedSessionID, setSelectedSessionID] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchEditHistory(siteAccessRequestID);
    }, [siteAccessRequestID]);

    const fetchEditHistory = async (siteAccessRequestID) => {
        setIsLoading(true);
        const response = await getEditHistorySiteAccessRequestREWAMP(siteAccessRequestID);
        if (response.success) {
            setEditHistory(response.data.results);
        } else {
            setEditHistory([]);
        }
        setIsLoading(false);
    }

    const fetchEditDetails = async (sessionID) => {
        setIsLoading(true);
        const response = await getEditHistorySiteAccessRequestByIDREWAMP(sessionID);
        if (response.success) {
            setEditDetails(response.data.results);
        } else {
            setEditDetails([]);
        }
        setIsLoading(false);
    }

    const formatWord = (word) => {
        return word.split(/(?=[A-Z])/).map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(' ');
    }

    const renderSteps = (message, time, type) => (
        <Step
            icon={<ClockCircleFilled style={{ color: type === 'DELETE' ? 'red' : type === 'INSERT' ? 'orange' : 'green' }} />}
            title={
                <div style={{ backgroundColor: "#F5F5F5", padding: "10px", borderRadius: "10px", width: "500px", marginTop: "5px" }}>
                    <Row>
                        <Col span={21}>
                            <span style={{ color: "black", fontSize: "12px" }}>{message}</span><br />
                            <span style={{ color: "gray", fontSize: "10px" }}>{time}</span>
                        </Col>
                        <Col span={3} style={{ textAlign: "right" }}>
                            <Tag color={type === 'DELETE' ? 'red' : type === 'INSERT' ? 'orange' : 'green'}>
                                <span style={{ fontWeight: 'bold', fontSize: "10px", color: type === 'DELETE' ? 'red' : type === 'INSERT' ? 'orange' : 'green' }}>
                                    {type}
                                </span>
                            </Tag>
                        </Col>
                    </Row>
                </div>
            }
        />
    );

    const handleCardClick = (id) => {
        fetchEditDetails(id);
        setSelectedSessionID(id);
    }

    const openEditModal = (entityType) => {
        setOpenModal(true);
        setEntityType(entityType);
    }

    const closeEditModal = () => {
        setOpenModal(false);
    }

    const formatHistoryTeamMembers = (history) => {
        console.log(history);
        return history.map(record => {
          const {
            entityType,
            operationType,
            fieldName,
            identification,
            oldValue,
            newValue,
            editTimestamp
          } = record;
          const formattedTimestamp = new Date(editTimestamp).toLocaleString();
          let newValueStr = '';
          if (typeof newValue === 'object' && newValue !== null) {
            newValueStr = newValue.name ? `name: ${newValue.name}` : '';
          } else {
            newValueStr = newValue;
          }
          console.log(newValueStr);

          let entityTypeReformatted = entityType.toLowerCase().split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
          let formattedText = '';
          if (operationType === 'UPDATE') {
            formattedText = `The ${fieldNameFormatter(fieldName)} of ${entityTypeReformatted} with identification ${identification?.toUpperCase()} was changed from ${oldValue} to ${newValueStr}.`;
          } else if (operationType === 'DELETE') {
            formattedText = `the ${entityTypeReformatted} with identification ${identification.toUpperCase()} was deleted.`;
          } else if (operationType === 'CREATE') {
            formattedText = `A new ${entityTypeReformatted} with identification ${identification?.toUpperCase()} was created with ${newValueStr}.`;
          }
      
          return formattedText;
        }).join('\n');
      }

      const formatHistoryEquipment = (history) => {
        return history.map(record => {
          const {
            entityType,
            operationType,
            fieldName,
            identification,
            oldValue,
            newValue,
            editTimestamp
          } = record;          
          let newValueStr = '';
          if (typeof newValue === 'object' && newValue !== null) {
            newValueStr = `Item Name: ${newValue.itemName}`;
          } else {
            newValueStr = newValue;
          }      
          let entityTypeReformatted = entityType.toLowerCase().split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
          let formattedText = '';
          if (operationType === 'UPDATE') {
            formattedText = `The ${fieldNameFormatter(fieldName)} of ${entityTypeReformatted} with identification ${identification?.toUpperCase()} was changed from ${oldValue} to ${newValueStr}.`;
          } else if (operationType === 'DELETE') {
            formattedText = `The ${entityTypeReformatted} with identification ${identification?.toUpperCase()} was deleted.`;
          } else if (operationType === 'CREATE') {
            formattedText = `A new ${entityTypeReformatted} with identification ${identification?.toUpperCase()} was created with ${newValueStr}.`;
          }
      
          return formattedText;
        }).join('\n');
      }

      const formatHistoryInstruments = (history) => {
        console.log(history);
        return history.map(record => {
          const {
            entityType,
            operationType,
            fieldName,
            identification,
            oldValue,
            newValue,
            editTimestamp
          } = record;          
          let newValueStr = '';
          if (typeof newValue === 'object' && newValue !== null) {
            newValueStr = `Item Name: ${newValue.itemName}`;
          } else {
            newValueStr = newValue;
          }
          console.log(newValueStr);
      
          let entityTypeReformatted = entityType.toLowerCase().split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
          let formattedText = '';
          if (operationType === 'UPDATE') {
            formattedText = `The ${fieldNameFormatter(fieldName)} of ${entityTypeReformatted} with identification ${identification?.toUpperCase()} was changed from ${oldValue} to ${newValueStr}.`;
          } else if (operationType === 'DELETE') {
            formattedText = `The ${entityTypeReformatted} with identification ${identification.toUpperCase()} was deleted.`;
          } else if (operationType === 'CREATE') {
            formattedText = `A new ${entityTypeReformatted} with identification ${identification?.toUpperCase()} was created with ${newValueStr}.`;
          }
      
          return formattedText;
        }).join('\n');
      }

      const formatHistoryVehicles = (history) => {
        console.log(history);
        return history.map(record => {
          const {
            entityType,
            operationType,
            fieldName,
            identification,
            oldValue,
            newValue,
            editTimestamp
          } = record;         
          let newValueStr = '';
          if (typeof newValue === 'object' && newValue !== null) {
            newValueStr = `Vehicle Type: ${newValue.vehicleType}`;
          } else {
            newValueStr = newValue;
          }      
          let entityTypeReformatted = entityType.toLowerCase().split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
          let formattedText = '';
          if (operationType === 'UPDATE') {
            formattedText = `The ${fieldNameFormatter(fieldName)} of ${entityTypeReformatted} with identification ${identification?.toUpperCase()} was changed from ${oldValue} to ${newValueStr}.`;
          } else if (operationType === 'DELETE') {
            formattedText = `The ${entityTypeReformatted} with identification ${identification.toUpperCase()} was deleted.`;
          } else if (operationType === 'CREATE') {
            formattedText = `A new ${entityTypeReformatted} with identification ${identification?.toUpperCase()} was created with ${newValueStr}.`;
          }
      
          return formattedText;
        }).join('\n');
      }
      
      const formatHistoryFiles = (history) => {
        console.log(history);
        return history.map(record => {
          const {
            entityType,
            operationType,
            fieldName,
            identification,
            oldValue,
            newValue,
            editTimestamp
          } = record;
          const formattedTimestamp = new Date(editTimestamp).toLocaleString();
          
          let formattedText = '';
          let entityTypeReformatted = entityType.toLowerCase().split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
      
          if (operationType === 'UPDATE') {
            if (oldValue === null && newValue !== null) {
              formattedText = `A new ${fieldNameFormatter(fieldName)} (${newValue}) was added for ${entityTypeReformatted}s.`;
            } else if (oldValue !== null && newValue === null) {
              formattedText = `The ${fieldNameFormatter(fieldName)} (${oldValue}) was removed from ${entityTypeReformatted}s.`;
            } else {
              formattedText = `The ${fieldNameFormatter(fieldName)} for ${entityTypeReformatted} was changed from ${oldValue} to ${newValue}.`;
            }
          } else if (operationType === 'DELETE') {
            formattedText = `The ${fieldNameFormatter(fieldName)} (${oldValue}) was deleted from ${entityTypeReformatted}s.`;
          }
      
          return formattedText;
        }).join('\n');
      }
      
    const fieldNameFormatter = (fieldName) => {
        return fieldName.split(/(?=[A-Z])/).map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(' ');
    }
      
    const renderEditDetails = () => {
        return (
            <Steps current={currentStep} onChange={setCurrentStep} direction='vertical' size='small'>
                {editDetails?.editHistories?.map((item, index) => {
                    const time = item?.editTimestamp ? new Date(item?.editTimestamp).toLocaleString('en-IN', {
                        year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true
                    }) : "Empty";

                    console.log(formatHistoryTeamMembers([item]));
                    if (item?.operationType === 'DELETE' && item?.entityType === entityType) {
                       if (item?.entityType === 'TEAM_MEMBER') {
                        return renderSteps(formatHistoryTeamMembers([item]), time, 'DELETE');
                        } else if (item?.entityType === 'EQUIPMENT') {
                            return renderSteps(formatHistoryEquipment([item]), time, 'DELETE');
                        } else if (item?.entityType === 'INSTRUMENT') {
                            return renderSteps(formatHistoryInstruments([item]), time, 'DELETE');
                        } else if (item?.entityType === 'VEHICLE') {
                            return renderSteps(formatHistoryVehicles([item]), time, 'DELETE');
                        } 
                        else if (item?.entityType === 'FILE') {
                            return renderSteps(formatHistoryFiles([item]), time, 'DELETE');
                        }
                        else {
                        return renderSteps(`Delete ${formatWord(item?.fieldName)} with ${item?.identification}`, time, 'DELETE');
                        }
                    } else if (item?.operationType === 'UPDATE' && item?.entityType === entityType) {
                          if (item?.entityType === 'TEAM_MEMBER') {
                            return renderSteps(formatHistoryTeamMembers([item]), time, 'UPDATE');
                            } else if (item?.entityType === 'EQUIPMENT') {
                                return renderSteps(formatHistoryEquipment([item]), time, 'UPDATE');
                            } else if (item?.entityType === 'INSTRUMENT') {
                                return renderSteps(formatHistoryInstruments([item]), time, 'UPDATE');
                            } else if (item?.entityType === 'VEHICLE') {
                                return renderSteps(formatHistoryVehicles([item]), time, 'UPDATE');
                            } 
                            else if (item?.entityType === 'FILE') {
                                return renderSteps(formatHistoryFiles([item]), time, 'UPDATE');
                            }
                            else {
                            return renderSteps(`${formatWord(item?.fieldName)} is changed from ${item?.oldValue || "Empty"} to ${item?.newValue || "Empty"}`, time, 'UPDATE');
                            }
                    } else if (item?.operationType === 'CREATE' && item?.entityType === entityType) {
                       if (item?.entityType === 'TEAM_MEMBER') {
                        return renderSteps(formatHistoryTeamMembers([item]), time, 'INSERT');
                        } else if (item?.entityType === 'EQUIPMENT') {
                            return renderSteps(formatHistoryEquipment([item]), time, 'INSERT');
                        } else if (item?.entityType === 'INSTRUMENT') {
                            return renderSteps(formatHistoryInstruments([item]), time, 'INSERT');
                        } else if (item?.entityType === 'VEHICLE') {
                            return renderSteps(formatHistoryVehicles([item]), time, 'INSERT');
                        } 
                        else if (item?.entityType === 'FILE') {
                            return renderSteps(formatHistoryFiles([item]), time, 'INSERT');
                        }
                        else {
                         return renderSteps(`Add New ${formatWord(item?.fieldName)} with ${item?.identification || "Empty"}`, time, 'INSERT');
                         }
                    }
                    return null;
                })}
            </Steps>
        );
    }

    return (
        <div style={{ margin: '0', padding: '0', backgroundColor: '#f0f2f5', width: '100%', height: '400px', overflow: 'auto' }}>
            <Spin spinning={isLoading} tip="Loading...">
                <div style={{ margin: '5px', padding: '0', backgroundColor: '#f0f2f5' }}>
                    {selectedSessionID !== null && (
                        <Button type="primary" style={{ marginBottom: '20px', borderRadius: '5px', width: '120px' }} onClick={() => setSelectedSessionID(null)}>
                            Back
                        </Button>
                    )}
                    {selectedSessionID === null ? (
                        <Row>
                            {editHistory.length > 0 ? (
                                editHistory.map((item, index) => (
                                    <Col span={8} key={index}>
                                        <Card
                                            style={{ width: '99%', borderRadius: '10px', marginTop: '20px', marginBottom: '20px', cursor: "pointer", backgroundColor: selectedSessionID === item.id ? "#eeeee4" : "white" }}
                                            onClick={() => handleCardClick(item.id)}
                                        >
                                            <Row>
                                                <Col span={18}>
                                                    <span style={{ color: "black", fontSize: "14px", fontWeight: "bold" }}>{item.userEmail || "Not Found"}</span><br />
                                                    <span style={{ color: "gray", fontSize: "12px" }}>{new Date(item.operationTimestamp).toLocaleString('en-IN', { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true })}</span>
                                                </Col>
                                                <Col span={6} style={{ textAlign: 'center' }}>
                                                    <div style={{ background: "#2d5ff4", borderRadius: "50%", width: "30px", height: "30px", display: "flex", alignItems: "center", justifyContent: "center", marginRight: "8px" }}>
                                                        {/* <span style={{ color: "white", fontSize: '20px' }}>{index + 1}</span> */}
                                                        <HistoryOutlined style={{ color: "white", fontSize: '20px' }} />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                ))
                            ) : (
                                <div style={{ marginTop: '15%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Empty description={<span style={{ color: "gray" }}>No Edit History Found</span>} />
                                </div>
                            )}
                        </Row>
                    ) : (
                        <Row>
                            {['SITE_ACCESS_REQUEST', 'TEAM_MEMBER', 'EQUIPMENT', 'INSTRUMENT', 'VEHICLE', 'FILE'].map((type, index) => {
                                // Function to convert type to camelCase and append 'Count'
                                const getEditDetailKey = (type) => {
                                    const camelCaseType = type.toLowerCase().split('_').map((word, index) => index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)).join('');
                                    return `${camelCaseType}Count`;
                                };

                                return (
                                    <Col span={6} key={index}>
                                        <Card
                                            style={{ width: '99%', borderRadius: '10px', marginBottom: '20px', cursor: "pointer" }}
                                            onClick={() => {
                                                const key = getEditDetailKey(type);
                                                if (editDetails?.[key] || 0) {
                                                    openEditModal(type);
                                                }
                                            }}
                                        >
                                            <Row>
                                                <Col span={18}>
                                                    <span style={{ color: "black", fontSize: "14px", fontWeight: "bold" }}>{type.replace('_REQUEST', '').replace('_', ' ').toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</span><br />
                                                    <span style={{ color: "gray", fontSize: "12px" }}>Related Edits</span>
                                                </Col>
                                                <Col span={6} style={{ textAlign: 'center' }}>
                                                    <div style={{ background: "#2d5ff4", borderRadius: "50%", width: "30px", height: "30px", display: "flex", alignItems: "center", justifyContent: "center", marginRight: "8px" }}>
                                                        <span style={{ color: "white", fontSize: '20px' }}>{editDetails?.[getEditDetailKey(type)] || 0}</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                );
                            })}
                        </Row>
                    )}
                    <Modal
                        title={<span style={{ color: 'black', fontSize: '14px' }} className='textStyles-small'>Edit Timeline</span>}
                        visible={openModal}
                        onOk={closeEditModal}
                        footer={null}
                        onCancel={closeEditModal} width={700}>
                        <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
                            {renderEditDetails()}
                        </div>
                    </Modal>
                </div>
            </Spin>
        </div>
    );
};

export default EditHistory;

import React, { Component } from 'react';

import { Collapse } from 'reactstrap';

import { Select, DatePicker, Table, List, Skeleton, Avatar, Tag } from 'antd';
import { viewerAllRequests, getUserSites, getAccessPermitionRequestById, getlogByStatusId, getStatusSummary } from '../../api/index';
import moment from 'moment';

const { Option } = Select;


class AllRequests extends Component {
    constructor() {
        super();
        this.state = {
            isOpenFillter: true,
            all_request: [],
            loading:true
        }
    }
    componentDidMount() {
        this.getViewerAllRequests();
    }
    getViewerAllRequests = async () => {
		this.setState({
			loading:true
		})
		var form = new FormData();
		//console.log('this.state.pageNo');
		//console.log(this.state.pageNo);
		//console.log(this.state.recodesPerPage_1);
		//console.log(this.state.regionIds);
		//console.log(this.state.depotIds);
		//console.log(this.state.siteIds);
		

		form.append("pageNo",0);
		form.append("recodesPerPage",20);


		var res = await viewerAllRequests(form);
		//console.log("viewerAllRequests");
		console.log(res.data.data);
		if (res.success) {
			this.setState({
				loading:false,

				all_request: res.data.data,
			
			});
		}
	};
    viewFilterClick = () => {
        this.setState({
            isOpenFillter: !this.state.isOpenFillter
        })
    }
    render() {
        const columns123 = [
			{
				title: "#",
				dataIndex: "id",
				defaultSortOrder: "descend",

				sorter: {
					compare: (a, b) => a.id - b.id,
					multiple: 2,
				},

				render: (text, record, index) => <span>{++index}</span>,
			},
		
			{
				title: "Request Code",
				//   dataIndex: "accessPermissionCode",
				key: "accessPermissionCode",
				render: (item) => <span> <a href='#' style={{fontSize:14}} onClick={() => this.getSiteAccessDetails(item.id)}><u>{item.accessPermissionCode}</u></a></span>,
			}, 
			{
				title: "Created On",
				//   dataIndex: "accessPermissionCode",
				dataIndex: "createdOn",
				sorter: (a, b) => moment(a.createdOn).unix() - moment(b.createdOn).unix(),
			
				
				render: (item) => <span>{moment(item).format('YYYY-MM-DD HH:mm:ss')}</span>,
			},	
			{
				title: "Type",
				dataIndex: "operationTypeName",
				key: "operationTypeName",
				render: (item) => <span>{item}</span>,
			},
			{
				title: "Group",
				dataIndex: "groupName",
				key: "groupName",

				//render: (item) => <span>{item}</span>,
				render: (item) => <>
					<Tag color="gray" key={item}>
						{item}
					</Tag>
				</>,
			},
			{
				title: "Requester",
				dataIndex: "userName",
				key: "userName",
				render: (item) => <span>{item}</span>,
			},

			{
				title: "Status",
				key: "statusName",
				//width: "20%",


				//render: (item) => <span>{item}</span>,
				render: (item) => <>
					<Tag color={item.statusColor} key={item}>
						<span style={{ color: 'black' }}><b>{item.statusName}</b></span>
					</Tag>
				</>,

			},

			// {
			// 	title: 'Action',

			// 	key: 'x',
			// 	render: (item) => <a href='#' onClick={() => this.getSiteAccessDetails(item.id)}><i data-feather="home" class="fa fa-eye"></i></a>,
			//   },

		];
        return (
            <div className="row">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="page-title-box" style={{ padding: 0 }}>
                            <div className="row">
                                <div style={{ flex: "0.7 0 0%" }} style={{ width: "93%" }} >
                                    <h4>All Requests</h4>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>


                <div class="col-lg-12 col-xl-12">
                    <div class="card">
                        <div class="card-header">
                            <div className='row'>
                                <div className='col-md-12'>
                                    <Collapse isOpen={this.state.isOpenFillter}>
                                        <div className="row">
                                            <div class="card" style={{ padding: 0, border: 0,marginBottom:5 }}>

                                                <div class="card-body bootstrap-select-1" style={{ padding: 5 }}>
                                                    <div class="row">
                                                        <div class="col-md-3">


                                                            <Select
                                                                mode="multiple"
                                                                showSearch
                                                                name="regionId"
                                                                onChange={(e) => this.searchRegionmChange(e)}
                                                                placeholder="Region"
                                                                style={{ width: "100%" }}

                                                                optionFilterProp="children"
                                                                filterOption={(input, option) =>
                                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                filterSort={(optionA, optionB) =>
                                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                                }
                                                            // options={this.state.regionsList}

                                                            >
                                                                {/* {this.state.regionsList.map((item, index) => {
                                                return <Option value={item.value}>{item.label}</Option>;
                                            })} */}
                                                            </Select>
                                                        </div>
                                                        <div class="col-md-3">

                                                            <Select
                                                                mode="multiple"
                                                                showSearch
                                                                name="depotId"
                                                                onChange={(e) => this.searchDepottemChange(e)}
                                                                placeholder="Depot"
                                                                style={{ width: "100%" }}
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) =>
                                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                filterSort={(optionA, optionB) =>
                                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                                }
                                                            // options={this.state.depotList}

                                                            >
                                                                {/* {this.state.depotList.map((item, index) => {
                                                return <Option value={item.value}>{item.label}</Option>;
                                            })} */}
                                                            </Select>
                                                        </div>
                                                        <div class="col-md-4">

                                                            <Select
                                                                mode="multiple"
                                                                placeholder="Site"
                                                                style={{ width: "100%" }}
                                                                // options={this.state.allSite} 
                                                                loading={this.state.allSite_loading}
                                                                name="siteId"
                                                                onChange={(e) => this.searchSiteemChange(e)}
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) =>
                                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                filterSort={(optionA, optionB) =>
                                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                                }
                                                            >
                                                                {/* {this.state.allSite.map((item, index) => {
                                                return <Option value={item.value}>{item.label}</Option>;
                                            })} */}
                                                            </Select>
                                                        </div>
                                                        <div class="col-md-2">
									<button type="button" class="btn btn-primary" onClick={() => this.filterBtnClick()}> Filter</button>
									<button type="button" style={{ marginLeft: 5 }} class="btn btn-dark" onClick={() => this.clearBtnClick()}>Clear Filter</button>
								</div>
                                                       

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>


                            </div>
                        </div>
                        <div class="card-body">
                        <Table
					size='small'
					fontSize={9}
					columns={columns123}
					bordered
					pagination={false}
					loading={this.state.loading}
					dataSource={this.state.all_request}
				/>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
export default AllRequests;

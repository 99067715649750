import React from "react";

function Box({style}){
    return(
        <div style={{
            height: 20,
            width: 200,
            background: 'purple',
            margin: 10,
            ...style
        }}></div>
    )
}

// export default class SmartContainer extends React.Component{
//     constructor(props){
//         super(props);
//         this.ref = React.createRef();
//         this.state = {
//             cotainerHeight: 0,
//             firstArray : [0],
//             isFirstArrayFull: false,
//             secondArray : [0],
//             isSecondArrayFull: false
//         }
//     }

//     componentDidMount(){

//     }

//     click(){
//         this.state.firstArray.push(Math.random())
//         this.setState({
//             firstArray: this.state.firstArray
//         })
//     }

//     render(){
//         // const { children } = this.props
        
//         return (
//             <React.Fragment>
//                 <div style={{position: 'absolute', right: 0}}>{this.state.cotainerHeight}</div>
//                 <div ref={this.ref} onClick={this.click.bind(this)}>
//                     <div style={{float: 'left'}}>
//                     {
//                         this.state.firstArray.map((e) => <Box/>)
//                     }
//                     </div>
//                     <div style={{float: 'right'}}>
//                     {
//                         this.state.secondArray.map((e) => <Box/>)
//                     }
//                     </div>
//                 </div>
//             </React.Fragment>
//         )
//     }
// }

class Smart extends React.Component{
    constructor(props){
        super(props);
        this.ref = React.createRef();
    }
    componentDidMount(){
        //console.log(this.ref.current)
    }
    render(){
        return(
            <div ref={this.ref}>ff</div>
        )
    }
}

export default class Cont extends React.Component{
    constructor(props){
        super(props);
        this.ref = React.createRef();
        this.state = {
            ar: [1,2,3,4],
            arr: []
        }
    }

    click(){
        this.state.ar.push(Math.random())
        this.setState({
            ar: this.state.ar
        })
    }

    componentDidUpdate(){
        this.ref.current && console.log({now: this.ref.current.clientHeight, parent: this.ref.current.parentNode.clientHeight})
    }

    render(){
        return(
            <div ref={this.ref} onClick={this.click.bind(this)}>
                {
                    this.state.ar.map(e => <Box/>)
                }
            </div>
        )
    }
}
import React, { Component } from 'react'
import { Col, Row } from 'antd';


const image_url = "https://storage.googleapis.com/dns_storage_v2/requestimages/";

export default class PisChecklistItem extends React.Component {

    render() {
        return (
            <>{
                this.props.item.type != "image" ? <div className="list-item"  >
                    
                    <Row>
                        <Col span={6}><div className='icon-div'  ><i class="dripicons-clipboard font-12" style={{ color: "#ffffff" }} /></div></Col>
                        <Col span={18} style={{ display: "flex", alignItems: "flex-end", justifyContent: 'flex-end' }} ><span class="badge badge-outline-primary badge-pill">{this.props.item.value}</span></Col>
                    </Row>
                    <p className='card-title-pis' >{this.props.item.name.replaceAll("_", " ").charAt(0).toUpperCase() + this.props.item.name.replaceAll("_", " ").slice(1)} </p>
                </div> : <div className="list-item" onClick={() => this.props.click(this.props.item)} style={{
                    cursor: "pointer",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundImage: `url( ${image_url + this.props.item.value})`,
                    backgroundColor: "rgba(0, 0, 0, 0.22)",
                    backgroundBlendMode: "multiply"
                }}>
                     
                   
                    <Row>
                        <Col span={18}><div className='icon-div'  ><i class="fas fa-image font-12" style={{ color: "#ffffff" }} /></div></Col>

                    </Row>
                    <p className='card-title-pis' style={{ color: "#ffffff" }} >{this.props.item.name.replaceAll("_", " ").charAt(0).toUpperCase() + this.props.item.name.replaceAll("_", " ").slice(1)} </p>
                </div>
            }</>
        )
    }
}

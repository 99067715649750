import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
    ClockCircleOutlined,
    ClockCircleFilled,
    SmileOutlined,
    FileProtectOutlined,
    FileImageOutlined,
    BarsOutlined,
    RollbackOutlined
} from '@ant-design/icons';
import { Empty, Divider, Space, Avatar, Card, Skeleton, Switch, Table, Select, Badge, Input, Button, Row, Upload, Col, Dropdown, Tag, Modal, Steps, message, Form, DatePicker, TimePicker, Descriptions, Image, Radio, Checkbox, Collapse } from 'antd';
import { getCrChecklistComponentsREWAMP, getKeyValueDetailsByIdAndComponentIdREWAMP, getPisHistoryGenaralDetailsById } from "../../../api/index";
import moment from 'moment';
const { Step } = Steps;
const image_url = "https://storage.googleapis.com/dns_storage_v2/requestimages/";
const baseAPI = "/civilRoutineService/getRequestDetailsByIdLite";

const CRDetailsView = () => {
    const { id, type } = useParams();
    const [sections, setSections] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedCard, setSelectedCard] = useState({
        id: null,
        name: null,

    });
    const [selectedSection, setSelectedSection] = useState(null);
    const [generalDetails, setGeneralDetails] = useState(null);

useEffect(() => {
    getCrChecklistComponents();
    getPISGenaralDeatils(id);
}, [id]);

    const getCrChecklistComponents = async () => {
        const response = await getCrChecklistComponentsREWAMP();
        if (response) {
            setSections(response?.data);
            setLoading(false);
        }
    }

    const getPISGenaralDeatils = async (id) => {
        const formData = new FormData();
        formData.append('requestId', id);
        const response = await getPisHistoryGenaralDetailsById(baseAPI, formData);
        if (response) {
            setGeneralDetails(response?.data);
        } else {
            message.error('Failed to fetch data');
            setGeneralDetails(null);
        }
    }

    const getKeyValueDetailsByIdAndComponentId = async (componentId) => {
        setSelectedSection(null);
        const formData = new FormData();
        const requestJson = JSON.stringify({
            "id": id,
            "compId": componentId
        });
        formData.append('Request', requestJson);
        const response = await getKeyValueDetailsByIdAndComponentIdREWAMP(formData);
        if (response) {
            setSelectedSection(response?.data);
        } else {
            message.error('Failed to fetch data');
            setSelectedSection(null);
        }
    }
    
    const allCheckLists = selectedSection?.length || 0;
    const allCheckListData = [];
    const imageCheckListData = [];

    for (let i = 0; i < allCheckLists; i++) {
        for (let j = 0; j < selectedSection[i]?.data.length; j++) {
            const type = selectedSection[i]?.data[j]?.type;
            if (type === 'image') {
                imageCheckListData.push({
                    key: selectedSection[i]?.data[j]?.id,
                    mainSectionName: selectedSection[i]?.section?.name ? selectedSection[i]?.section?.name : 'N/A',
                    sectionName: selectedSection[i]?.data[j]?.name ? selectedSection[i]?.data[j]?.name : 'N/A',
                    itemName: selectedSection[i]?.data[j]?.name ? selectedSection[i]?.data[j]?.name : 'N/A',
                    answer: selectedSection[i]?.data[j]?.value ? selectedSection[i]?.data[j]?.value : 'N/A',
                    type: type ? type : 'N/A',
                    conditions: selectedSection[i]?.data[j]?.conditions ? selectedSection[i]?.data[j]?.conditions : 'N/A',
                    actionType: selectedSection[i]?.data[j]?.actionType ? selectedSection[i]?.data[j]?.actionType : 'N/A',
                    remark: selectedSection[i]?.data[j]?.remark ? selectedSection[i]?.data[j]?.remark : 'N/A',
                });
            } else {
                allCheckListData.push({
                    key: selectedSection[i]?.data[j]?.id,
                    mainSectionName: selectedSection[i]?.section?.name ? selectedSection[i]?.section?.name : 'N/A',
                    sectionName: selectedSection[i]?.data[j]?.name ? selectedSection[i]?.data[j]?.name : 'N/A',
                    itemName: selectedSection[i]?.data[j]?.name ? selectedSection[i]?.data[j]?.name : 'N/A',
                    answer: selectedSection[i]?.data[j]?.value ? selectedSection[i]?.data[j]?.value : 'N/A',
                    type: type ? type : 'N/A',
                    conditions: selectedSection[i]?.data[j]?.conditions ? selectedSection[i]?.data[j]?.conditions : 'N/A',
                    actionType: selectedSection[i]?.data[j]?.actionType ? selectedSection[i]?.data[j]?.actionType : 'N/A',
                    remark: selectedSection[i]?.data[j]?.remark ? selectedSection[i]?.data[j]?.remark : 'N/A',
                });
            }
        }
    }

    const sectionDataMap = {};
    allCheckListData.forEach((item) => {
        const mainSectionName = item.mainSectionName;

        if (!sectionDataMap[mainSectionName]) {
            sectionDataMap[mainSectionName] = [];
        }

        sectionDataMap[mainSectionName].push(item);
    });

    const imageSectionDataMap = {};
    imageCheckListData.forEach((item) => {
        const mainSectionName = item.mainSectionName;

        if (!imageSectionDataMap[mainSectionName]) {
            imageSectionDataMap[mainSectionName] = [];
        }

        imageSectionDataMap[mainSectionName].push(item);
    });

    const columns = [
        {
            title: 'Section Name',
            dataIndex: 'sectionName',
            key: 'sectionName',
            render: (text, record) => (
                <div>
                    <b className='textStyle-small' style={{ color: 'gray', textTransform: 'capitalize', fontSize: 12 }}>
                        {text.replace(/_/g, ' ')}
                    </b>
                </div>
            ),
            width: 150,
        },
        {
            title: 'Item Name',
            dataIndex: 'answer',
            key: 'answer',
            render: (text, record) => (
                <div>
                    {text === 'N/A' && record.type !== 'Image' ? (
                        <span className="mt-1 textStyle-small">
                            <span className='textStyle-small'>
                                <b>Action Type :</b> <Tag color="red">{record.actionType}</Tag>
                            </span> <br /> <br />
                            <span className='textStyle-small'>
                                <b>Conditions :</b> <Tag color="blue">{record.conditions}</Tag>
                            </span> <br /> <br />
                            <span className='mt-2' style={{ marginTop: 10 }}>
                                <b className='textStyle-small'>Remark :</b> <Tag color="green">{record.remark}</Tag>
                            </span>
                        </span>
                    ) : (
                        <Tag color="green" className="mt-1">{text}</Tag>
                    )}
                </div>
            ),
            width: 200,
        },
    ];


    const combineFix = Object.keys(sectionDataMap).map((mainSectionName) => {
        const dataSource = sectionDataMap[mainSectionName];
        const imageDataSource = imageSectionDataMap[mainSectionName] || [];

        return (
            <div key={mainSectionName} style={{
                padding: 10,
                backgroundColor: 'white',
                borderRadius: 5,
                marginTop: 20,
                width: '100%',
            }}>
                <h5 className='textStyle-small'
                    style={{ fontSize: 14 }}
                ><FileProtectOutlined /> {mainSectionName}</h5>
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    pagination={false}
                    size='small'
                    locale={{ emptyText: 'No data' }}
                /> 
                <br></br>
                <br></br>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {imageDataSource.map((item) => (
                        <div key={item.key} style={{ margin: '10px' }}>
                            <h6 style={{
                                textTransform: 'capitalize', fontSize: 12, fontWeight: 'bold'
                            }}> <FileImageOutlined /> {item.itemName.replace(/_/g, ' ')}</h6>
                            <Image
                                width={200}
                                height={200}
                                src={image_url + (item.answer ? item.answer : "error")}
                                style={{ cursor: 'pointer', borderRadius: 10 }}
                            />
                        </div>
                    ))}
                </div>
            </div>
        );
    });

    const dataWithSectionNameImages = selectedSection?.filter(item => item?.section?.name === 'IMAGES');

    if (dataWithSectionNameImages?.length > 0) {
        const imagesData = dataWithSectionNameImages[0]?.data;
        const imageValues = imagesData?.map(image => image?.value);
        console.log("Image Values:", imageValues);
    } else {
        console.log("No data found with section name 'IMAGES'");
    }

    const renderImagesSection = dataWithSectionNameImages?.map((section) => {
        const imagesData = section?.data;
        return (
            <div style={{ padding: 10, backgroundColor: 'white', borderRadius: 5, marginTop: 20, width: '100%' }}>
                <h5 className='textStyle-small' style={{ fontSize: 14 }}><FileImageOutlined /> {section?.section?.name}</h5>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {imagesData?.map((item) => (
                        <div key={item?.id} style={{ margin: '10px' }}>
                            <h6 style={{ textTransform: 'capitalize', fontSize: 12, fontWeight: 'bold' }}> 
                            <FileImageOutlined /> {item?.name.replace(/_/g, ' ')}</h6>
                            <Image
                                width={200}
                                height={200}
                                src={item?.value ? image_url + item?.value : "https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg"}
                                style={{ cursor: 'pointer', borderRadius: 10 }}
                            />
                        </div>
                    ))}
                </div>
            </div>
        );
    });
    
    const combinedRender = (
        <div>
            {combineFix}
            {renderImagesSection}
        </div>
    );

    return (
        <>
        {
            generalDetails ? (
                <div className="col-12">
                <div className="card" style={{ margin: 20, borderRadius: 10, padding: 10, backgroundColor: 'white', boxShadow: '0px 0px 10px 0px #0000001a' }}>
                    <div class="card-body">
                        <div class="dastone-profile">
                            <div class="row">
                                <div class="col-lg-4 align-self-center mb-3 mb-lg-0">
                                    <div class="dastone-profile-main">
                                        <div class="dastone-profile-main-pic">
                                            <img src={'https://static.vecteezy.com/system/resources/previews/001/902/502/large_2x/telecommunication-radio-antenna-and-satelite-tower-with-blue-sky-free-photo.JPG'} alt="" height="110" class="rounded-circle"></img>
                                        </div>
                                        <div class="dastone-profile_user-detail">
                                            <h5 class="dastone-user-name textStyle-small">{generalDetails?.siteName}</h5>
                                            <p className="mb-0 text-secondary font-16 align-middle me-2 textStyle-small"><span class="ant-tag ant-tag-has-color" style={{ backgroundColor: 'gray', fontSize: 14 }}>{generalDetails?.siteId}</span></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-5 ms-auto align-self-center">
                                    <ul class="list-unstyled personal-detail mb-0">
                                        <li className="mt-2 textStyle-small"><i className="ti ti-map-alt text-secondary font-16 align-middle me-2"></i> <b> Code </b> : {generalDetails?.requestCode}</li>
                                        <li className="mt-2 textStyle-small"><i className="ti ti-home text-secondary font-16 align-middle me-2"></i> <b> Status </b> : <Tag color={generalDetails?.colorCode?.replace("0xFF", "#")} >{generalDetails?.status}</Tag></li>
                                        <li className="mt-2 textStyle-small"><i className="ti ti-email text-secondary font-16 align-middle me-2"></i> <b> Request Type:  </b> : {generalDetails?.requestType}</li>
                                    </ul>
                                </div>
                                <div class="col-lg-3 align-self-center">
                                    <div className='row'>
                                        <ul class="list-unstyled personal-detail mb-0 textStyle-small">
                                            <li >Created Date : <span class=" textStyle-small text-muted font-12 fw-normal"> 
                                            {moment(generalDetails?.createdDate).format('YYYY-MM-DD HH:mm:ss')}</span></li>
                                        </ul>
                                    </div>
                                    <div class="row">
                                        <div class="col-auto text-end border-end">
                                            <p class="mb-0 fw-semibold textStyle-small">Request Date</p>
                                            <h4 class="m-0 fw-bold"> 
                                            <span class="text-muted font-12 fw-normal textStyle-small"> {generalDetails?.requestDate}</span></h4>
                                        </div>
                                        <div class="col-auto">
                                            <p class="mb-0 fw-semibold textStyle-small">Service Date</p>
                                            <h4 class="m-0 fw-bold"> <span class="text-muted font-12 fw-normal textStyle-small">{generalDetails?.serviceDate}</span></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
            ) : (
                <div style={{ margin: 20, borderRadius: 10, padding: 10, backgroundColor: 'white', boxShadow: '0px 0px 10px 0px #0000001a' }}>
                <span className='textStyle-small' style={{ fontSize: 16 }}>{selectedCard?.name}</span>
                {combinedRender ? combinedRender : <Empty />}
            </div>
            )
        }


                    
            <div style={{ margin: 20, borderRadius: 10, padding: 10, backgroundColor: 'white', boxShadow: '0px 0px 10px 0px #0000001a' }}>
                <Row>
                    {
                        sections?.map((section, index) => {
                            return (
                                <Card key={index}
                                    style={{
                                        width: 200, margin: 2, cursor: 'pointer',
                                        backgroundColor: selectedCard?.id === section?.id ? '#f0f0f0' : 'white', borderRadius: 10
                                    }}
                                    onClick={() => {
                                        setSelectedCard({
                                            id: section?.id,
                                            name: section?.name,
                                        });
                                        getKeyValueDetailsByIdAndComponentId(section?.id);
                                    }}
                                >
                                    <BarsOutlined />
                                    <span className='textStyle-small' style={{ marginLeft: 10, fontSize: 12 }}>
                                        {section?.name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                                    </span>
                                </Card>
                            )
                        })
                    }
                </Row>
            </div>

            {
                selectedSection === null ? (
                    <div style={{ margin: 20, borderRadius: 10, padding: 10, backgroundColor: 'white', boxShadow: '0px 0px 10px 0px #0000001a' }}>
                        <Skeleton active />
                    </div>
                ) : (
                    <div style={{ margin: 20, borderRadius: 10, padding: 10, backgroundColor: 'white', boxShadow: '0px 0px 10px 0px #0000001a' }}>
                        <span className='textStyle-small' style={{ fontSize: 16 }}>{selectedCard?.name}</span>
                        {combinedRender ? combinedRender : <Empty />}
                    </div>
                )
            }
        </>
    )
}

export default CRDetailsView;

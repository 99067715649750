import React from "react";
import  { FiCalendar, FiClock } from 'react-icons/fi';

class TickClock extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            curTime : new Date().toLocaleTimeString()
        }
    }
    componentDidMount(){
        setInterval(function(){
            this.setState({
                curTime: new Date().toLocaleTimeString()
            })
        }.bind(this), 1000);
    }
    render(){
        return(
            <div className="center-content"><FiClock style={{margin: 5}}/>{this.state.curTime}</div>
        )
    }
}


class StillClock extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            curTime : new Date().toDateString()
        }
        
    }
    
    render(){
        return(
            <div className="center-content"><FiCalendar style={{margin: 5}}/>{this.state.curTime}</div>
        )
    }
}

export {
    TickClock,
    StillClock
}
import React, { Component } from 'react'
import { Select, DatePicker, Table, Image, Avatar, Input, Tag, Badge, Descriptions, Space } from 'antd';

import { Link } from "react-router-dom";


import { getLastThreeRequestDetails, getUserSites, getPisHistoryData, getSitesWithFilter, getPisHistoryDetailsById, getAllPisStatus } from "../../api/index";
import Modal from 'react-modal';

import { THE_FAMILY_ID } from '@azure/msal-common/dist/utils/Constants';
import Item from 'antd/lib/list/Item';
import ReactLoading from 'react-loading';
import { tupleNum } from 'antd/lib/_util/type';
import { Collapse } from 'reactstrap';
import { withRouter, Redirect } from 'react-router-dom'
import { connect } from "react-redux"; 

import moment from 'moment';


class PgHistoryView extends Component {

    constructor(props) {
        super(props);
        this.state = {

            loading: false,
            showModal: false,
            isOpenFillter: true,
            allList: null,
            allSite_loading: true,
            allSite: [],
            depotList: [],
            statusList: [],
            regionsList: [],
            allDataList: [],
            startDate: "",
            endDate: "",
            selectSiteId: null,
            selectSiteName: null,
            selectComptName: null,
            comptId: null,
            selectImagesDetails: null,
            regionIds: '',
            depotIds: '',
            siteIds: '',
            statusId: '',
            enterReqCode: '',
            fullPageCount: 0,
            loopPageCount: 0,
            recodesPerPage: 50,
            itemDetails: [],
            selectedServiceTypeId: 1,
            selectedDataUrl: "/genServiceRequest/getAllPisGenServiceRequests",
            selectedDetailUrl: "",
            recodesPerPage2: 1,
            pageNo2: 0,
            filterVisibility: false
        };

    }
    viewFilterClick = () => {
        this.setState({
            isOpenFillter: !this.state.isOpenFillter
        })
    }

    previousBtnClick2 = () => {
        if (this.state.pageNo2 > 0) {
            var aa = this.state.pageNo2 - 1
            this.setState({
                pageNo2: aa
            }, () => {
                this.getPisDataList();
            })
        }
    }
    nextBtnClick2 = () => {
        if (this.state.recodesPerPage2 > this.state.pageNo2 + 1) {
            var aa = this.state.pageNo2 + 1
            this.setState({
                pageNo2: aa
            }, () => {
                this.getPisDataList();
            })

        }

    }
    componentDidMount() {
        this.getAllDataPisStatus();
        this.getDataSitesByuser()


        var _depotList = [];
        var _regionsList = [];

        this.props.depots.map((depot) => {
            _depotList.push({ value: depot.id, label: depot.name })
        })
        this.props.regions.filter(allret => "0".includes(allret.isSubRegion)).map((regions) => {
            _regionsList.push({ value: regions.id, label: regions.name })
        })
        this.setState({
            depotList: _depotList,
            regionsList: _regionsList
        })
    }

    getDataSitesByuser = async () => {
        var res = await getUserSites();
        var _allSite = [];
        console.log("getUserSites");
        //console.log(res.data);
        if (res.success) {
            res.data.map((sites) => {
                _allSite.push({ value: sites.id, label: sites.siteName })
            })
            this.setState({
                allSite_loading: false,
                allSite: _allSite,
            });



        }
    };
    getAllDataPisStatus = async () => {
        var res = await getAllPisStatus();
        console.log('getAllPisStatus')
        console.log(res);
        if (res.success) {
            var _statudList = [];

            res.data.map((depot) => {
                _statudList.push({ value: depot.id, label: depot.name })
            })

            this.setState({
                statusList: _statudList,
            })

        }

    }
    getPisDataList = async () => {
        this.setState({
            loading: true
        })
        var data = new FormData();
        data.append("pageNo", this.state.pageNo2);
        data.append("rowCountPerPage", this.state.recodesPerPage);
        data.append('fromDate', this.state.startDate);
        data.append('toDate', this.state.endDate);
        data.append('regionIds', this.state.regionIds);
        data.append('depotIds', this.state.depotIds);
        data.append('siteIds', this.state.siteIds);
        data.append('status', this.state.statusId);
        data.append('serviceRequestIds', this.state.enterReqCode);


        var pageCount = 0;


        var res = await getPisHistoryData(this.state.selectedDataUrl, data);
        console.log(res)
        if (res.success) {

            this.setState({
                loading: false,
                tabaleLoading: false,
                allDataList: res.data.content.reverse(),
                recodesPerPage2: res.data?.totalPages
            })

        }



    }



    clearBtnClick = () => {
        window.location.reload()
    }
    searchRegionmChange = (event) => {
        var dd = event
        //console.log(dd)
        var subRegion = [];
        var _depotList1 = [];
        var subRegionIdArray = [];

        this.props.regions.filter(allret => dd.map((n) => n).includes(allret.parentRegion?.id)).map((subR) => {
            subRegionIdArray.push(subR.id)
        })

        this.setState({
            regionIds: subRegionIdArray
        }, () => { this.getDataSitesWithFilter() });

        //console.log(this.props.regions.filter(allret => dd.map((n) => n).includes(allret.parentRegion?.id)));
        subRegion = this.props.regions.filter(allret => dd.map((n) => n).includes(allret.parentRegion?.id));
        //console.log(this.props.depots.filter(alldate => subRegion.map((n) => n.id).includes(alldate.region?.id)));
        this.props.depots.filter(alldate => subRegion.map((n) => n.id).includes(alldate.region?.id)).map((depot) => {
            _depotList1.push({ value: depot.id, label: depot.name })
        })

        this.setState({
            depotList: _depotList1

        })

    }
    searchDepottemChange = (event) => {

        this.setState({
            depotIds: event
        }, () => this.getDataSitesWithFilter());
    }
    getDataSitesWithFilter = async () => {
        this.setState({
            allSite_loading: true,
        })

        var _regionList = [];
        var _depotList = [];
        var _allSite = [];
        if (this.state.regionIds !== '') {
            this.state.regionIds?.map((x, y) => {
                _regionList.push({ "id": x })
            }, () => {

            })
        }
        if (this.state.depotIds !== '') {
            this.state.depotIds?.map((a, b) => {
                _depotList.push({ "id": a })
            }, () => {

            })
        }


        var data = new FormData();
        // data.append('regions','[{"id":6},{"id":7}]');
        data.append('regions', JSON.stringify(_regionList));
        data.append('depots', JSON.stringify(_depotList));
        var res = await getSitesWithFilter(data);
        if (res.success) {
            res.data.data.map((sites) => {
                _allSite.push({ value: sites.id, label: sites.siteName })
            })
            this.setState({
                allSite_loading: false,
                allSite: _allSite,

            })

        }

    }
    checkFilterDisplay = () => {
        if (this.state.startDate != "" && this.state.endDate != "") {

            this.setState({
                filterVisibility: true
            })

        } else {
            this.setState({
                filterVisibility: false,
                regionIds: '',
                depotIds: '',
                siteIds: '',
                statusId: '',
            })

        }

    }
    onChangeDate = (date, dateString) => {
        //console.log(date);
        //console.log(dateString);
        this.setState({
            startDate: dateString,
        }, () => {
            this.checkFilterDisplay();
        });
    };
    onChangeDate2 = (date, dateString) => {
        //console.log(date);
        //console.log(dateString);
        this.setState({
            endDate: dateString,
        }, () => {
            this.checkFilterDisplay();
        });
    };
    searchStatusChange = (event) => {

        this.setState({
            statusId: event
        });
    }

    searchSiteemChange = (event) => {

        this.setState({
            siteIds: event
        });
    }
    filterBtnClick = async () => {
        if (this.state.startDate != "" && this.state.endDate != "") {
            this.getPisDataList();
        } else {
            if (this.state.enterReqCode != '') {
                if (this.state.selectedDataUrl == '/genServiceRequest/getAllPisGenServiceRequests') {
                    let reqCodeDataUrl = '/genServiceRequest/getAllPisGenServiceRequestsByRequestCode'
                    this.getPisDataListByRequestCode(this.state.enterReqCode, reqCodeDataUrl)
                }
                if (this.state.selectedDataUrl == '/acServiceRequest/getAllPisAcServiceRequests') {
                    let reqCodeDataUrl = '/acServiceRequest/getAllPisAcServiceRequestsByRequestCode'
                }
                if (this.state.selectedDataUrl == '/siteCleaningService/getAllPisScServiceRequests') {
                    let reqCodeDataUrl = '/siteCleaningService/getAllPisScServiceRequestsByRequestCode'
                }

                if (this.state.selectedDataUrl == '/civilRoutineService/getAllPisCrServiceRequests') {
                    let reqCodeDataUrl = '/civilRoutineService/getAllPisCrServiceRequestsByRequestCode'
                }
            }


        }

    }

    getPisDataListByRequestCode = async (code, url) => {
        this.setState({
            loading: true
        })
        var data = new FormData();
        data.append("serviceRequestIds", code);
        var res = await getPisHistoryData(url, data);
        console.log('getPisDataListByRequestCode')
        console.log(res)
        if (res.success) {
            this.setState({
                loading: false,
                tabaleLoading: false,
                allDataList: res.data.content.reverse(),
                recodesPerPage2: res.data?.totalPages
            })
        }



    }
    serviceTypesChange = (e, serviceTypes) => {
        this.setState({
            selectedServiceTypeId: e,
            selectedDataUrl: serviceTypes[e - 1].data_url
        })
        console.log(serviceTypes[e - 1].data_url);
        console.log(e);
    }

    searchFormReqCode = (event) => {
        this.setState({
            enterReqCode: event.target.value
        })


    }



    render() {
        const { Option } = Select;

        const columns123 = [
            {
                title: "Id",
                // dataIndex: "siteId",
                //	defaultSortOrder: "descend",


                render: (item) => <>
                    <span style={{ padding: 1 }} key={item?.id}>
                        {item?.id}
                    </span>
                </>,
            },
            {
                title: "Request Code",
                render: (item) => <>

                    {item?.requestCode}
                </>,
            },

            {
                title: "Site Id",
                render: (item) => <>

                    {item?.siteId}
                </>,
            },
            {
                title: "Site Name",
                render: (item) => <>

                    {item?.siteName}
                </>,
            },
            {
                title: "Created Date",
                render: (item) => <>
                    < >
                        {item?.createdDate.split("T")[0]}
                    </>
                </>,
            },
            {
                title: "Status",
                render: (item) => <>
                    <Tag color={item?.colorCode.replace("0xFF", "#")} style={{ padding: 3 }} >
                        {item.status}
                    </Tag>
                </>,
            },
            {
                title: "Service Date",
                render: (item) => <>
                    < >
                        {item?.serviceDate == null ? "PENDING" : item?.serviceDate}
                    </>
                </>,
            },
            // http://localhost:3000/pis/detailView/civil
            {
                title: 'Action',
                key: 'action',
render: (item) => (
  <>
    {
      <a href={this.state.selectedServiceTypeId === 4 ? "/pis/detailView/civil/" + item.id+ "/" + this.state.selectedServiceTypeId:
       "/pis-detail-view/" + item.id+ "/" + this.state.selectedServiceTypeId}>
        View
      </a>
    }
  </>
)
            },


        ];
        var serviceTypes = [{ "id": 1, "name": "Gen Service - PIS", "data_url": "/genServiceRequest/getAllPisGenServiceRequests" }, { "id": 2, "name": "AC Service - PIS", "data_url": "/acServiceRequest/getAllPisAcServiceRequests" }, { "id": 3, "name": "Site Cleaning Service - PIS", "data_url": "/siteCleaningService/getAllPisScServiceRequests" }, { "id": 4, "name": "Civil Routine - PIS", "data_url": "/civilRoutineService/getAllPisCrServiceRequests" }];

        return (

            <div class="container-fluid" >
                <div class="row">
                    <div class="col-sm-12">
                        <div class="page-title-box">
                            <div class="row">
                                <div class="col">
                                    <h4 class="page-title">PIS Data View</h4>

                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="javascript:void(0);">PIS </a></li>
                                        <li class="breadcrumb-item"><a href="javascript:void(0);">Data View</a></li>

                                    </ol>
                                </div>
                                <div class="col-auto align-self-center">
                                    <div class="row">

                                        <div class="col-md-1">
                                            <button onClick={() => this.viewFilterClick()} type="button" style={{ marginLeft: 10 }} class="btn btn-danger" ><i class="fas fa-filter"></i></button>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <Collapse isOpen={this.state.isOpenFillter}>
                        <div className="row">
                            <div class="card">

                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-2 filter-form">
                                            <Select
                                                // mode="multiple"
                                                showSearch
                                                name="regionId"
                                                onChange={(e) => this.serviceTypesChange(e, serviceTypes)}
                                                placeholder="Organization"
                                                style={{ width: "100%" }}
                                                value={this.state.selectedServiceTypeId}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                filterSort={(optionA, optionB) =>
                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                }
                                            >
                                                {serviceTypes.map((item, index) => {
                                                    return <Option key={index} value={item.id}>{item.name}</Option>;
                                                })}
                                            </Select>
                                            {/* {this.state.organizationError && <p style={{ color: "red", fontSize: '10px' }}>The organization field is required.</p>} */}
                                        </div>
                                        <div class="col-md-2 filter-form">
                                            <DatePicker
                                                placeholder="Start Date *"
                                                style={{ width: "100%" }}
                                                onChange={this.onChangeDate}
                                            />

                                        </div>
                                        <div className='col-md-2 filter-form'>
                                            <DatePicker
                                                placeholder="End Date *"
                                                style={{ width: "100%" }}
                                                onChange={this.onChangeDate2}
                                            />
                                        </div>
                                        {this.state.filterVisibility ? (<>
                                            <div class="col-md-2 filter-form">


                                                <Select
                                                    mode="multiple"
                                                    showSearch
                                                    name="regionId"
                                                    onChange={(e) => this.searchRegionmChange(e)}
                                                    placeholder="Region"
                                                    style={{ width: "100%" }}

                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    filterSort={(optionA, optionB) =>
                                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                    }
                                                // options={this.state.regionsList}

                                                >
                                                    {this.state.regionsList?.map((item, index) => {
                                                        return <Option value={item.value}>{item.label}</Option>;
                                                    })}
                                                </Select>
                                            </div>
                                            <div class="col-md-2 filter-form">

                                                <Select
                                                    mode="multiple"
                                                    showSearch
                                                    name="depotId"
                                                    onChange={(e) => this.searchDepottemChange(e)}
                                                    placeholder="Depot"
                                                    style={{ width: "100%" }}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    filterSort={(optionA, optionB) =>
                                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                    }
                                                // options={this.state.depotList}

                                                >
                                                    {this.state.depotList?.map((item, index) => {
                                                        return <Option value={item.value}>{item.label}</Option>;
                                                    })}
                                                </Select>
                                            </div>
                                            <div class="col-md-2 filter-form">

                                                <Select
                                                    mode="multiple"
                                                    placeholder="Site"
                                                    style={{ width: "100%" }}
                                                    // options={this.state.allSite} 
                                                    loading={this.state.allSite_loading}
                                                    name="siteId"
                                                    onChange={(e) => this.searchSiteemChange(e)}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    filterSort={(optionA, optionB) =>
                                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                    }
                                                >
                                                    {this.state.allSite.map((item, index) => {
                                                        return <Option value={item.value}>{item.label}</Option>;
                                                    })}
                                                </Select>
                                            </div>
                                            <div class="col-md-2 filter-form">

                                                <Select
                                                    mode="multiple"
                                                    placeholder="Status"
                                                    style={{ width: "100%" }}
                                                    // options={this.state.allSite} 
                                                    // loading={this.state.allSite_loading}
                                                    name="statusId"
                                                    onChange={(e) => this.searchStatusChange(e)}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    filterSort={(optionA, optionB) =>
                                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                    }
                                                >
                                                    {this.state.statusList.map((item, index) => {
                                                        return <Option value={item.value}>{item.label}</Option>;
                                                    })}
                                                </Select>
                                            </div>
                                        </>) : ('')}


                                        <div class="col-md-4 filter-form">
                                            <Input placeholder="Request Code" onChange={(e) => this.searchFormReqCode(e)} />


                                        </div>


                                    </div>
                                    <br></br>
                                    <div className='row'>


                                    </div>
                                    <div class="col-md-3" style={{ marginTop: 10 }}>
                                        <button type="button" class="btn btn-primary" onClick={() => this.filterBtnClick()}> Filter</button>
                                        <button type="button" style={{ marginLeft: 5 }} class="btn btn-dark" onClick={() => this.clearBtnClick()}>Clear Filter</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Collapse>
                    <Table
                        size='small'
                        fontSize={9}
                        columns={columns123}
                        bordered
                        pagination={false}
                        loading={this.state.loading}
                        dataSource={this.state.allDataList}
                    />
                </div>
                <br></br>
                <div class="row">

                    <div class=" col-md-12">
                        <div class="dataTables_paginate paging_simple_numbers" id="row_callback_paginate" style={{ display: "flex", textAlign: "end", justifyContent: "end" }}>
                            <ul class="pagination">
                                <li class="paginate_button page-item previous" id="row_callback_previous">
                                    <a onClick={() => this.previousBtnClick2()} aria-controls="row_callback" data-dt-idx="0" tabindex="0" class="page-link">Previous</a>
                                </li>
                                <li class="paginate_button page-item ">
                                    <a aria-controls="row_callback" data-dt-idx="1" tabindex="0" class="page-link">{this.state.pageNo2 + 1}/{this.state.recodesPerPage2}
                                    </a>
                                </li>
                                <li class="paginate_button page-item next" id="row_callback_next">
                                    <a onClick={() => this.nextBtnClick2()} aria-controls="row_callback" data-dt-idx="7" tabindex="0" class="page-link">Next
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div >
        )
    }

}
function mapStateToProps(state) {
    return {
        depots: state.depots,
        regions: state.regions
    };
}
export default withRouter(connect(mapStateToProps, {})(PgHistoryView));



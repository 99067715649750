import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { getDonationCategories, getData } from "../../actions/index";


class GeneratorDistribution extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allDataset: null,
            tableView: false,
            regionSet: null,
            setX:['<=7.5kVA','7.5-11.5kVA','11.5-18kVA','18-25kVA','25-40kVA','>=40kVA'],
        }

    }

    componentWillMount() {

    }

    componentDidMount() {
        console.log('genDis')
        console.log(this.props.regions)

        this.setState({
            allDataset: Object.values(this.props.dataSet),
            regionSet: this.props.regions.filter(re => re.isSubRegion == 0)
        }, () => {
            console.log(this.state.allDataset[0][0])
            if (this.state.allDataset != null && this.state.allDataset.length > 0) {
            }
            this.setState({
                tableView: true
            })
        })
    }

    // componentWillReceiveProps(nextProps) {

    // }

    // shouldComponentUpdate(nextProps, nextState) { 
    //     console.log(nextProps);
    //     console.log(nextState);
    // }

    // componentWillUpdate(nextProps, nextState) {

    // }

    // componentDidUpdate(prevProps, prevState) {

    // }

    componentWillUnmount() {

    }

    keysToRemove = ["Test Region", "N/A", "ND Super", "Central office"];

    render() {
        let fullTotal = 0;
        return (
            <div>
                {
                this.state.tableView ? (<table class="table table-Rectifier mb-0" style={{ fontSize: 'x-small' }} >
                        <thead style={{ borderWidth: 1 }}>
                            <tr>
                                <th className='table-th-color'><b> Gen Capacity (kVA) </b> </th>
                                {this.state.regionSet?.map((region) => {
                                    if (!this.keysToRemove.includes(region.name)) {
                                        return <th className='table-th-color' style={{ padding: 3 }} key={region.id}><b>{region.name}</b></th>;
                                    }
                                    return null; // Return null for excluded regions
                                })}
                                <th className='table-th-color' style={{ padding: 3 }}><b>Total</b></th>
                            </tr>
                        </thead>
                    <tbody style={{ borderWidth: 1 }}>

                        {this.state.setX?.map((x) => {
                            let setlater = ''
                            if (x.charAt(0) == "B") {
                                setlater = x.substring(4);
                            } else {
                                setlater = x;
                            }
                            const arr = [];
                            // return  <tr>
                            //     <td className='table-th-color' style={{padding:3}} >{setlater != null ? (setlater) : ('Null')}</td>
                            //     {(() => {
                            //         const arr = [];
                            //         let totalValue = 0;

                            //         this.state.regionSet.map((a) => {
                            //             this.state.allDataset.map((x1) => {
                            //                 console.log('x1')
                            //                 let setofarray = x1.filter(re => re.region == a.name)
                            //                 if (setofarray.length > 0) {
                            //                     totalValue = totalValue + setofarray[0][x]
                            //                     arr.push(
                            //                         <td className='power-data-dashboard-table-tb' style={{padding:3,textAlign:"end"}}>{setofarray[0][x]}</td>
                            //                     );
                            //                 }
                            //             })
                            //         })
                            //         arr.push(
                            //             <td className='power-data-dashboard-table-tb' style={{padding:3,textAlign:"end"}}>{totalValue.toLocaleString()}</td>
                            //         );
                            //         return arr;
                            //     })()}
                            // </tr>

                            return (
                                <tr>
                                    <td className='table-th-color' style={{ padding: 3 }} >{setlater != null ? (setlater) : ('Null')}</td>
                                    {(() => {
                                        const arr = [];
                                        let totalValue = 0;
                                        this.state.regionSet.map((a) => {
                                            this.state.allDataset.map((x1) => {
                                                console.log('x1')
                                                let setofarray = x1.filter(re => re.region == a.name)
                                                if (setofarray.length > 0) {
                                                    totalValue = totalValue + setofarray[0][x]
                                                    arr.push(
                                                        <td className='power-data-dashboard-table-tb' style={{ padding: 3, textAlign: "end" }}>{setofarray[0][x]}</td>
                                                    );
                                                }
                                            })
                                        });
                                        arr.push(
                                            <td className='power-data-dashboard-table-tb' style={{ padding: 3, textAlign: "end" }} key="total">
                                                {totalValue.toLocaleString()}
                                            </td>
                                        );
                            
                                        return arr;
                                    })()}
                                </tr>
                            );
                            

                        })}

                        <tr>
                            <td className='table-th-color' style={{padding:3,textAlign:"end"}} ><b>Total</b></td>
                            {this.state.regionSet?.map((x) => {
                                if (!this.keysToRemove.includes(x.name)) {
                                const arr = [];
                                return <>
                                    {(() => {
                                        const arr = [];
                                        let totalValue = 0;
                                        this.state.setX.map((a) => {
                                            this.state.allDataset.map((x1) => {
                                                console.log('x1')
                                                let setofarray = x1.filter(re => re.region == x.name)
                                                if (setofarray.length > 0) {
                                                    totalValue = totalValue + setofarray[0][a]
                                                }
                                            })
                                        })
                                        arr.push(
                                            <td style={{padding:3,textAlign:"end"}} className='power-data-dashboard-table-tb'>{totalValue.toLocaleString()}</td>
                                        );
                                        fullTotal = fullTotal + totalValue;
                                        return arr;
                                    })()}
                                </>
                                }
                            })}
                            <td style={{padding:3,textAlign:"end"}} className='power-data-dashboard-table-tb'><b>{fullTotal.toLocaleString()}</b></td>
                        </tr>
                    </tbody>
                </table>) : ('')}

            </div>
        );
    }
}

GeneratorDistribution.propTypes = {

};


function mapStateToProps(state) {
    //console.log("state", state);
    return {
        organization_count: state.organization.length,
        regions: state.regions,
        depots: state.depots,
        sites_count: state.sites.length,
        vendor_count: state.vendor.length,
        roles_count: state.roles.length,
    };
}

export default connect(mapStateToProps, { getDonationCategories, getData })(
    GeneratorDistribution
);
import React, { Component } from 'react'
import { Table, Input, InputNumber, Tag, Space, Button, Row, Col, Drawer, Select, Spin, Switch, message, Modal } from "antd";
import {updateSbPower, getAllDropdowns} from "../../api/index";
const { Option } = Select;

class SharedBackupPower extends Component {
    constructor(props) {
        super(props);
        this.state = {
            switchStatus: true,

            objectSet: {
                "sbpSharedFrom": "",
                "sbpNoOfPhases": "",
                "sbpCapacityPerPhase": 0,
                "sbpBackupPowerOperatingStatus": false,
            },
            dropDownObjectSet: {
                "sbpSharedFrom": [],
                "sbpNoOfPhases": [],
                "sbpCapacityPerPhase": [],
                "sbpBackupPowerOperatingStatus": []
            }
        }
        this.handlesbpSharedFrom = this.handlesbpSharedFrom.bind(this);
        this.handlsbpCapacityPerPhase = this.handlsbpCapacityPerPhase.bind(this);
        this.handlsbpNoOfPhases = this.handlsbpNoOfPhases.bind(this);
    }

    getSharedBackupPowerDropDownData = async () => {
        var res = await getAllDropdowns();
        if (res.success) {
            this.setState({
                dropDownObjectSet: {
                    "sbpSharedFrom": res.data["Shared Backup Power"]["Shared From"],
                    "sbpNoOfPhases": res.data["Shared Backup Power"]["No of Phases"],
                    "sbpCapacityPerPhase": res.data["Shared Backup Power"]["Capacity per phase (A)"],
                    "sbpBackupPowerOperatingStatus": res.data["Shared Backup Power"]["Backup Power Operating status"],
                }
            })
        } else {
            message.warning('Issue With Dropdown Data Fetching! : ' + res.data.message);
        }
    }

    handlesbpSharedFrom(e) {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                sbpSharedFrom: e.target.value
            }

        }, () => {
            console.log(this.state.objectSet)
        })
    }
    
    handlsbpCapacityPerPhase(e) {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                sbpCapacityPerPhase: e
            }

        }, () => {
            console.log(this.state.objectSet)
        })
    }
    handlsbpNoOfPhases(e) {
        console.log(e)


        this.setState({
            objectSet: {
                ...this.state.objectSet,
                sbpNoOfPhases: e.target.value
            }

        }, () => {
            console.log(this.state.objectSet)
        })
    }
    switchTo() {
        this.state.objectSet['sbpBackupPowerOperatingStatus'] = !this.state.objectSet['sbpBackupPowerOperatingStatus'];
    }
    
    
    componentDidMount() {
        console.log(this.props.details)
        this.setState({
            objectSet: {
                "sbpSharedFrom": this.props.details.access_technologies_data?.sbpSharedFrom,
                "sbpNoOfPhases": this.props.details.access_technologies_data?.sbpNoOfPhases,
                "sbpCapacityPerPhase": this.props.details.access_technologies_data?.sbpCapacityPerPhase,
                "sbpBackupPowerOperatingStatus": this.props.details.access_technologies_data?.sbpBackupPowerOperatingStatus,
            }
        })
        this.getSharedBackupPowerDropDownData();
    }

        
	showUpdateConfirm = () => {
		const { confirm } = Modal;
		confirm({
			title: 'Are You Want To Update These Data, Related To Shared Backup Power?',
			onOk: async () => {
                this.dataSetToUpdate();
			},
			onCancel() { },
		});
	};
    
    dataSetToUpdate = async () => {
           var res = await updateSbPower(this.props.details2.site_details.id, this.state.objectSet);
           if(res.success){
              message.success('Successfully Updated!');
              this.props.parentMethod();
              this.setState({
                switchStatus: true
            })
           }else{
            message.warning(' Something went wrong!')
           }
    }

    dataUpdate() {
        console.log("ssss")
        this.setState({
            switchStatus: false
        })
    }
    close = async () =>{
        this.props.parentMethod();
        this.setState({
            objectSet: {
                "sbpSharedFrom": this.props.details.access_technologies_data?.sbpSharedFrom,
                "sbpNoOfPhases": this.props.details.access_technologies_data?.sbpNoOfPhases,
                "sbpCapacityPerPhase": this.props.details.access_technologies_data?.sbpCapacityPerPhase,
                "sbpBackupPowerOperatingStatus": this.props.details.access_technologies_data?.sbpBackupPowerOperatingStatus,
            }
        });
        this.setState({
            switchStatus: true
        });
    }
    render() {
        const { details } = this.props.details
        return (
            <>
                {this.props.details.access_technologies_data !=null ? (
                    <div className='row'>
                        <div className='col-md-6'>
                            <div class="card">
                                <div class="card-header">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <h4 class="card-title">Information</h4>
                                        {this.state.switchStatus
                                            ?   <button type="button" class="btn btn-soft-primary btn-icon-square-sm" onClick={() => this.dataUpdate()}> <i class="mdi mdi-pencil-outline"></i></button>
                                            :   <div class="d-flex justify-content-end align-items-center">
                                                    <button type="button" class="btn btn-soft-secondary " onClick={() => this.close()} style={{marginRight:"10px"}}> Cancel </button>    
                                                    <button type="button" class="btn btn-soft-primary " onClick={() => this.showUpdateConfirm()}> Submit to Update </button>
                                                </div>
                                        }
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        
                                            <ul class="list-group">
                                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                                    <div>
                                                    <i class="la la-hand-o-right text-primary me-2"></i>  Shared From
                                                    </div>
                                                <div>
                                                    {this.state.switchStatus.toString() == "true" ? (                                                      
                                                            this.props.details.access_technologies_data?.sbpSharedFrom != null || 
                                                            this.props.details.access_technologies_data?.sbpSharedFrom != undefined ? ( 
                                                                <span className="badge badge-outline-success">{this.props.details.access_technologies_data.sbpSharedFrom}</span>
                                                            ) : (
                                                                <span className="badge badge-outline-danger">Not Defined Yet</span>
                                                            )    
                                                    ) : (
                                                        <Select
                                                            defaultValue={this.props.details.access_technologies_data?.sbpSharedFrom}
                                                            style={{ width: 120 }} 
                                                            onChange={(e) => {
                                                                this.state.objectSet.sbpSharedFrom = e;
                                                            }}>
                                                            {this.state.dropDownObjectSet.sbpSharedFrom.map((x, y) => {
                                                                return <Option value={x}>{x}</Option>
                                                            })}
                                                        </Select>
                                                    )}
                                                </div>
                                                </li>
                                                
                                                
                                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                                    <div>
                                                    <i class="la la-hand-o-right text-primary me-2"></i> No of Phases
                                                    </div>
                                                    {/* {this.state.switchStatus ? this.props.details.access_technologies_data?.sbpNoOfPhases != null ?( <span class="badge badge-outline-success">{this.props.details.access_technologies_data.sbpNoOfPhases}</span>):( <span class="badge badge-outline-warning">Currently Not Set</span>) : <Input style={{ width: '20%' }} value={this.state.objectSet?.sbpNoOfPhases} id="mpcNoOfPhases" onChange={this.handlsbpNoOfPhases} />} */}
                                                    <div>
                                                    {this.state.switchStatus.toString() == "true" ? (                                                      
                                                            this.props.details.access_technologies_data?.sbpNoOfPhases != null || 
                                                            this.props.details.access_technologies_data?.sbpNoOfPhases != undefined ? ( 
                                                                <span className="badge badge-outline-success">{this.props.details.access_technologies_data.sbpNoOfPhases}</span>
                                                            ) : (
                                                                <span className="badge badge-outline-danger">Not Defined Yet</span>
                                                            )    
                                                    ) : (
                                                        <Select
                                                            defaultValue={this.props.details.access_technologies_data?.sbpNoOfPhases}
                                                            style={{ width: 120 }} 
                                                            onChange={(e) => {
                                                                this.state.objectSet.sbpNoOfPhases = e;
                                                            }}>
                                                            {this.state.dropDownObjectSet.sbpNoOfPhases.map((x, y) => {
                                                                return <Option value={x}>{x}</Option>
                                                            })}
                                                        </Select>
                                                    )}
                                                    </div>
                                                </li>
                                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                                    <div>
                                                    <i class="la la-hand-o-right text-primary me-2"></i>  Capacity per phase (A)
                                                    </div>
                                                    {/* {this.state.switchStatus ? this.props.details.access_technologies_data?.sbpCapacityPerPhase ?( <span class="badge badge-outline-success">{this.props.details.access_technologies_data.sbpCapacityPerPhase}</span>):( <span class="badge badge-outline-warning">{this.props.details.access_technologies_data.sbpCapacityPerPhase}</span>) : <InputNumber style={{ width: '20%' }} value={this.state.objectSet?.sbpCapacityPerPhase} id="mpcNoOfPhases" onChange={this.handlsbpCapacityPerPhase} />} */}
                                                    <div>
                                                    {this.state.switchStatus.toString() == "true" ? (                                                      
                                                            this.props.details.access_technologies_data?.sbpCapacityPerPhase != null || 
                                                            this.props.details.access_technologies_data?.sbpCapacityPerPhase != undefined ? ( 
                                                                <span className="badge badge-outline-success">{this.props.details.access_technologies_data.sbpCapacityPerPhase}</span>
                                                            ) : (
                                                                <span className="badge badge-outline-danger">Not Defined Yet</span>
                                                            )    
                                                    ) : (
                                                        <Select
                                                            defaultValue={this.props.details.access_technologies_data?.sbpCapacityPerPhase}
                                                            style={{ width: 120 }} 
                                                            onChange={(e) => {
                                                                this.state.objectSet.sbpCapacityPerPhase = e;
                                                            }}>
                                                            {this.state.dropDownObjectSet.sbpCapacityPerPhase.map((x, y) => {
                                                                return <Option value={x}>{x}</Option>
                                                            })}
                                                        </Select>
                                                    )}
                                                    </div>
                                                </li>
                                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                                    <div>
                                                    <i class="la la-hand-o-right text-primary me-2"></i>  Backup Power Operating status
                                                    </div>
                                                    {/* {this.props.details.access_technologies_data?.sbpBackupPowerOperatingStatus ? (<> <Switch disabled={this.state.switchStatus} defaultChecked onChange={() => this.switchTo()} checkedChildren="true" unCheckedChildren="false" /></>) : (<Switch disabled={this.state.switchStatus} onChange={() => this.switchTo()} checkedChildren="true" unCheckedChildren="false" />)} */}
                                                    <div>
                                                    {this.state.switchStatus.toString() == "true" ? (                                                      
                                                            this.props.details.access_technologies_data?.sbpBackupPowerOperatingStatus != null || 
                                                            this.props.details.access_technologies_data?.sbpBackupPowerOperatingStatus != undefined ? ( 
                                                                <span className="badge badge-outline-success">{this.props.details.access_technologies_data.sbpBackupPowerOperatingStatus.toString()}</span>
                                                            ) : (
                                                                <span className="badge badge-outline-danger">Not Defined Yet</span>
                                                            )    
                                                    ) : (
                                                        <Select
                                                            defaultValue={this.props.details.access_technologies_data?.sbpBackupPowerOperatingStatus}
                                                            style={{ width: 120 }} 
                                                            onChange={(e) => {
                                                                this.state.objectSet.sbpBackupPowerOperatingStatus = e;
                                                            }}>
                                                            {this.state.dropDownObjectSet.sbpBackupPowerOperatingStatus.map((x, y) => {
                                                                return <Option value={x}>{x}</Option>
                                                            })}
                                                        </Select>
                                                    )}
                                                    </div>
                                                </li>
                                                {/* <li class="list-group-item d-flex justify-content-between align-items-center">
                                                    <div>
                                                    <i class="la la-hand-o-right text-primary me-2"></i>  DC Power Shared From
                                                    </div>
                                                    {this.state.switchStatus ? this.props.details.access_technologies_data?.dcpsSharedFrom ? ( <span class="badge badge-outline-success">{this.props.details.access_technologies_data.dcpsSharedFrom}</span>):( <span class="badge badge-outline-warning">{this.props.details.access_technologies_data.dcpsSharedFrom}</span>) : <InputNumber style={{ width: '20%' }} value={this.state.objectSet?.dcpsSharedFrom} id="mpcNoOfPhases" onChange={this.handledcpsSharedFrom} />}

                                                </li>
                                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                                    <div>
                                                    <i class="la la-hand-o-right text-primary me-2"></i>  DC Power Shared Capacity
                                                    </div>
                                                    {this.state.switchStatus ? this.props.details.access_technologies_data?.dcpsSharedCapacity ? ( <span class="badge badge-outline-success">{this.props.details.access_technologies_data.dcpsSharedCapacity}</span>):( <span class="badge badge-outline-warning">{this.props.details.access_technologies_data.dcpsSharedCapacity}</span>) : <InputNumber style={{ width: '20%' }} value={this.state.objectSet?.dcpsSharedCapacity} id="mpcNoOfPhases" onChange={this.handledcpsSharedCapacity} />}
                                                </li>
                                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                                    <div>
                                                    <i class="la la-hand-o-right text-primary me-2"></i>  DC Power Average Load
                                                    </div>
                                                    {this.state.switchStatus ? this.props.details.access_technologies_data?.dcpsAverageLoad ? ( <span class="badge badge-outline-success">{this.props.details.access_technologies_data.dcpsAverageLoad}</span>):( <span class="badge badge-outline-warning">{this.props.details.access_technologies_data.dcpsAverageLoad}</span>) : <InputNumber style={{ width: '20%' }} value={this.state.objectSet?.dcpsAverageLoad} id="mpcNoOfPhases" onChange={this.handledcpsAverageLoad} />}

                                                </li> */}
                                              
                                            </ul>
                                     
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : ( <div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <h4 style={{ color: "#c5c5c5" }}>No Dat Found Shared Backup Power</h4></div>)}

            </>
        )
    }
}
export default SharedBackupPower;

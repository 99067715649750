import React, { Component } from "react";
import { Pie, Bar } from "@ant-design/plots";
import ReactApexChart from "react-apexcharts";
import { networkDashboard, getMasterDataSiteInfo } from "../../api/index";
import PowerShareOut from "../../components/power-data-dashbord/power -share-out";
import OutdoorCabinetOverview from "../../components/power-data-dashbord/outdoor-cabinet-overview";
import BatteryOverview from "../../components/power-data-dashbord/battery-overview";
import RectifierOverview from "../../components/power-data-dashbord/rectifier-overview";
import GeneratorDistribution from "../../components/power-data-dashbord/generator-distribution";
import IndoorOutdoor from "../../components/power-data-dashbord/indoor-outdoor";
import {
  Select,
  Spin,
  Table,
  Image,
  Avatar,
  Input,
  Tag,
  Badge,
  Descriptions,
  Space,
} from "antd";

import { Collapse } from "reactstrap";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import { center } from "@antv/g2plot/lib/plots/sankey/sankey";
class PowerDataDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bbModelWiseDistribution: [],
      bbSiteErValue: [],
      finleSiteErValue: [],
      recModelWiseDistribution: [],
      outdoorCabinetMakewiseDistribution: [],
      outdoorCabinetTypewiseDistribution: [],

      powerSharedOutDataSet: null,
      outdoorCabinetOverviewDataSet: null,
      batteryOverviewDataSet: null,
      generatorDistributionDataSet: null,
      rectifierOverviewDataSet: null,
      indoorOutdoorDataSet: null,
      optionsSiteDSL: [],
      seriesSiteDSL: [],
      optionsSolaOverview: [],
      seriesSolaOverview: [],
      isSetSiteDistributionwithSiteLoad: false,
      isSetSolaOverviewLoad: false,
      powerCnType: false,
      isOpenFillter: true,
      regionsList: [],
      powerConnectionTypeId: "",
      regionId: "",
      indoorOutdoorTypeId: "",
      powerConnectionTypeSet: [],
      isLoading: false,
      setSiteDistributionWithSiteLoadX: [
        "< 1KW",
        "1kw-2kw",
        "2kw-3kw",
        "4kw-6kw",
        "6kw-8kw",
        "> 8kw",
      ],
    };
  }

  viewFilterClick = () => {
    this.setState({
      isOpenFillter: !this.state.isOpenFillter,
    });
  };
  componentDidMount() {
    this.getNetworkDashboardData();
    this.getMasterData();
    console.log(this.props.details);

    var _depotList = [];
    var _regionsList = [];

    this.props.depots.map((depot) => {
      _depotList.push({ value: depot.id, label: depot.name });
    });
    this.props.regions
      .filter((allret) => "0".includes(allret.isSubRegion))
      .map((regions) => {
        _regionsList.push({ value: regions.id, label: regions.name });
      });
    this.setState({
      depotList: _depotList,
      regionsList: _regionsList,
    });
  }

  getMasterData = async () => {
    var res = await getMasterDataSiteInfo(1);
    console.log("ssssddddd");
    console.log(res);
    if (res.success) {
      this.setState({
        powerConnectionTypeSet: res.data?.powerConnectionType,
      });
    }
  };
  getNetworkDashboardData = async () => {
    this.setState({
      indoorOutdoorDataSet: null,
      powerSharedOutDataSet: null,
      outdoorCabinetOverviewDataSet: null,
      batteryOverviewDataSet: null,
      generatorDistributionDataSet: null,
      rectifierOverviewDataSet: null,
      indoorOutdoorDataSet: null,
      powerCnType: false,
      isLoading: true,
    });

    var data = new FormData();
    data.append("region", this.state.regionId);
    data.append("powerConnectionType", this.state.powerConnectionTypeId);
    data.append("indoorOutdoorType", this.state.indoorOutdoorTypeId);

    var res = await networkDashboard(data);

    console.log("newqork");
    console.log(res);
    if (res.success) {
      const keysToRemove = ["Test Region", "N/A", "ND Super", "Central office"];
      const GenDistributionData = res.data.GenDistribution;
      keysToRemove.forEach((key) => {
        delete GenDistributionData[key];
      });
      // delete GenDistributionData["Test Region"];
      // delete GenDistributionData["N/A"];
      // delete GenDistributionData["ND Super"];
      // delete GenDistributionData["Central office"];
      this.setState(
        {
          bbModelWiseDistribution: res.data.bbModelWiseDistribution,
          recModelWiseDistribution: res.data.recModelWiseDistribution,
          outdoorCabinetMakewiseDistribution:
            res.data.outdoorCabinetMakewiseDistribution,
          outdoorCabinetTypewiseDistribution:
            res.data.outdoorCabinetTypewiseDistribution,
          SiteDistributionWithSiteLoad: res.data.SiteDistributionWithSiteLoad,
          powerSharedOutDataSet: res.data.powerSharedOut,
          outdoorCabinetOverviewDataSet: res.data.outdoorCabinetOverview,
          batteryOverviewDataSet: res.data.bbOverview,
          rectifierOverviewDataSet: res.data.recDistribution,
          powerConnectionTypeCountDataSet: res.data.PowerConnectionTypeCount,
          indoorOutdoorDataSet: res.data.IndoorOutdoorCount,
          generatorDistributionDataSet: GenDistributionData,
          solaOverview: res.data.SolaOverview,
          bbSiteErValue: res.data.ERValue,
        },
        () => {
          this.siteDistributionwithSiteLoadFinction(
            this.state.SiteDistributionWithSiteLoad
          );
          if (this.state.solaOverview?.length > 0) {
            this.solaOverviewFuntion(this.state.solaOverview);
          }

          this.setPowerConnectionTypeCountDataSetArray(
            this.state.powerConnectionTypeCountDataSet
          );
          this.setSiteErValueArray(this.state.bbSiteErValue);
          this.setState({
            isLoading: false,
          });
        }
      );
    } else {
    }
  };

  setSiteErValueArray(data) {
    const wee = Object.entries(data);
    const newArray = wee.map((x, y) => {
      if (x[0] == "greaterThan10omh") {
        return { lable: "> 10 ohm", count: x[1] };
      } else {
        return { lable: "≤ 10 ohm", count: x[1] };
      }
    });
    this.setState({
      finleSiteErValue: newArray,
    });
  }

  setPowerConnectionTypeCountDataSetArray(data) {
    let typeSet = data.map((a) => {
      if (a.Power_Connection_Type == null) {
        return "Null";
      } else {
        return a.Power_Connection_Type;
      }
    });
    let countSet = data.map((a) => a.Site_Count);
    console.log(typeSet);
    this.setState(
      {
        series: [
          {
            data: countSet,
          },
        ],
        options: {
          chart: {
            type: "bar",
            height: 350,
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            title: {
              text: "Site Count",
            },
            categories: typeSet,
          },
          yaxis: {
            title: {
              text: "Power Connection type",
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: true,
            },
          },
        },
      },
      () => {
        this.setState({
          powerCnType: true,
        });
      }
    );
  }
  setsolaOverviewArray(data) {
    console.log("setsolaOverviewArraySort");
    console.log(data);
    // const sortedData = Object.entries(data).sort((a, b) => a[0]['<=9kW'] - b[0]['<=9kW']);

    // console.log(sortedData)
    let customSort = [
      "<=9kW",
      "9kW-12kW",
      "12kW-15kW",
      "15kW-24kW",
      "24kW-32kW",
      ">32kW",
    ];
    const result = Object.fromEntries(
      Object.entries(data).sort((x, y) => {
        // Compare the keys of each pair
        console.log("setKeyyyy");
        console.log(x);

        if (x[0] == "<=9kW") {
          return -1;
        } else if (y[0] == ">32kW ") {
          return -1;
        } else {
          return x[0].substring(0, 2) - y[0].substring(0, 2);
        }

        // if (x[0]=='<=9kW') {
        //     return -1;
        //   }

        // if(y[0].charAt(0)<x[0].charAt(0)){
        //     return 0;
        // }

        //   if (y[0]=='>32kW ') {
        //     return -1;
        //   }
      })
    );

    // let result=JSON.stringify(Object.entries(data).sort(customSort), 0, 4)
    console.log("oppp");
    console.log(result);

    const { Power_Connection_Type, ...rest } = result;

    return rest;
  }
  setSiteDistributionwithSiteLoadArray(data) {
    // delete data["region"];

    const result = Object.fromEntries(
      Object.entries(data).sort((x, y) => {
        // Compare the keys of each pair
        // if (a[0] < b[0]) {
        //     return -1;
        // } else if (a[0] > b[0]) {
        //     return 1;
        // } else {
        //     return 0;
        // }

        if (x[0] == "< 1KW") {
          return -1;
        } else if (y[0] == "> 8kw") {
          return -1;
        } else {
          return x[0].substring(0, 2) - y[0].substring(0, 2);
        }
      })
    );

    const { region, ...rest } = result;

    return rest;
  }
  indoorOutdoorTypeIdChange = (event) => {
    if (event != undefined) {
      this.setState({
        indoorOutdoorTypeId: event,
      });
    } else {
      this.setState({
        indoorOutdoorTypeId: "",
      });
    }
  };

  searchPowerConnectionChange = (event) => {
    console.log(event);
    if (event != undefined) {
      this.setState({
        powerConnectionTypeId: event,
      });
    } else {
      this.setState({
        powerConnectionTypeId: "",
      });
    }
  };

  searchRegionmChange = (event) => {
    var dd = event;
    if (event != undefined) {
      this.setState({
        regionId: event,
      });
    } else {
      this.setState({
        regionId: "",
      });
    }
  };
  filterBtnClick = async () => {
    this.getNetworkDashboardData();
  };
  clearBtnClick = () => {
    window.location.reload();
  };
  solaOverviewFuntion(dataset) {
    const resultObject = dataset;
    var xaxisSet = null;
    console.log("solaOverviewFuntion");
    console.log(resultObject);

    var newaary = resultObject.map((x, y) => {
      xaxisSet = Object.keys(this.setsolaOverviewArray(x));
      return {
        name:
          x.Power_Connection_Type != null ? x.Power_Connection_Type : "Null",
        data: Object.values(this.setsolaOverviewArray(x)),
      };
    });
    this.setState(
      {
        seriesSolaOverview: newaary,
        optionsSolaOverview: {
          chart: {
            type: "bar",
            height: 350,
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: true,
            background: {
              enabled: true,
              foreColor: "#fff",
              padding: 2,
              borderRadius: 2,
              borderWidth: 1,
              borderColor: "#fff",
            },
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                legend: {
                  position: "bottom",
                  offsetX: -10,
                  offsetY: 0,
                },
              },
            },
          ],
          xaxis: {
            title: {
              text: "Installed Solar Capacity (kW) ",
            },
            categories: xaxisSet,
          },
          yaxis: {
            title: {
              text: "Site Count",
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: true,
            },
          },
          fill: {
            opacity: 1,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      () => {
        this.setState({
          isSetSolaOverviewLoad: true,
        });
      }
    );
  }

  siteDistributionwithSiteLoadFinction(dataSet) {
    console.log("dataSet23");

    const resultObject = Object.values(this.state.SiteDistributionWithSiteLoad);
    console.log(resultObject);
    var xaxisSet = null;
    var newaary = resultObject.map((x, y) => {
      const result = Object.keys(x[0]);
      const result2 = Object.values(x[0]);

      xaxisSet = Object.keys(this.setSiteDistributionwithSiteLoadArray(x[0]));

      console.log(this.setSiteDistributionwithSiteLoadArray(x[0]));

      return {
        name: x[0].region != null ? x[0].region : "Null",
        data: Object.values(this.setSiteDistributionwithSiteLoadArray(x[0])),
      };
    });

    console.log("setOpctionnn");
    console.log(newaary);

    this.setState(
      {
        seriesSiteDSL: newaary,
        optionsSiteDSL: {
          chart: {
            type: "bar",
            height: 350,
            stacked: true,
            toolbar: {
              show: false,
            },
          },

          dataLabels: {
            enabled: true,
            background: {
              enabled: true,
              foreColor: "#fff",
              padding: 2,
              borderRadius: 2,
              borderWidth: 1,
              borderColor: "#fff",
            },
          },

          stroke: {
            width: 1,
            colors: ["#fff"],
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                legend: {
                  position: "bottom",
                  offsetX: -10,
                  offsetY: 0,
                },
              },
            },
          ],
          xaxis: {
            title: {
              text: "DC Load Range",
            },
            categories: xaxisSet,
          },
          yaxis: {
            title: {
              text: "Site Count",
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: true,
            },
          },

          fill: {
            opacity: 1,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      () => {
        this.setState({
          isSetSiteDistributionwithSiteLoad: true,
        });
      }
    );
  }

  render() {
    const { Option } = Select;

    const data2 = [
      {
        type: "aa",
        sales: 38,
      },
      {
        type: "dd",
        sales: 52,
      },
      {
        type: "cc",
        sales: 61,
      },
      {
        type: "bb",
        sales: 145,
      },
      {
        type: "ee",
        sales: 48,
      },
      {
        type: "ff",
        sales: 38,
      },
    ];
    const config3 = {
      data: data2,
      xField: "sales",
      yField: "type",
      barWidthRatio: 0.5,
      style: { height: "250px" },
      position: "left",
      meta: {
        type: {
          alias: "oppp",
        },
        sales: {
          alias: "ppoo",
        },
      },
    };
    const data = [
      {
        type: "sd",
        value: 27,
      },
      {
        type: "sdf",
        value: 25,
      },
      {
        type: "sdskdls skds",
        value: 18,
      },
      {
        type: "sdkkj lksd ",
        value: 15,
      },
      {
        type: "dfd",
        value: 10,
      },
      {
        type: "sdskdksljj dd",
        value: 5,
      },
    ];
    const dataBatteryModelWiseDistribution = this.state.bbModelWiseDistribution;
    const databbSiteErValue = this.state.finleSiteErValue;

    const configSiteErValue = {
      appendPadding: 0,
      data: databbSiteErValue,
      angleField: "count",
      colorField: "lable",
      radius: 0.8,
      height: 150,
      legend: {
        title: {
          text: "Latest ER Value(Ohms)",

          styles: {
            itemMarginTop: 1,
          },
        },
        titleStyle: { fontSize: 9 },
      },
      label: {
        type: "outer",
        content: "{percentage}",
      },
      interactions: [
        {
          type: "element-active",
        },
      ],
    };
    const configBatteryModelWiseDistribution = {
      appendPadding: 10,
      data: dataBatteryModelWiseDistribution,
      angleField: "battery_count",
      colorField: "model",
      radius: 0.8,
      legend: {
        title: {
          text: "BB Distribution Summary",

          styles: {
            itemMarginTop: 25,
          },
        },
        titleStyle: { fontSize: 9 },
      },
      label: {
        type: "outer",
        content: "{percentage}",
      },
      interactions: [
        {
          type: "element-active",
        },
      ],
    };
    const dataRecModelWiseDistribution = this.state.recModelWiseDistribution;

    const configRecModelWiseDistribution = {
      appendPadding: 10,
      data: dataRecModelWiseDistribution,
      angleField: "model_count",
      colorField: "model",
      radius: 0.8,
      legend: {
        title: {
          text: "Rectifier Distribution Summary",

          styles: {
            itemMarginTop: 25,
          },
        },
        titleStyle: { fontSize: 9 },
      },
      label: {
        type: "outer",
        content: "{percentage}",
      },
      interactions: [
        {
          type: "element-active",
        },
      ],
    };
    const dataOutdoorCabinetMakewiseDistribution =
      this.state.outdoorCabinetMakewiseDistribution;

    const configOutdoorCabinetMakewiseDistribution = {
      appendPadding: 10,
      data: dataOutdoorCabinetMakewiseDistribution,
      angleField: "count",
      colorField: "make",
      radius: 0.8,
      legend: {
        position: "bottom",
        flipPage: false,
        title: {
          text: "Cabinet Type",

          styles: {
            itemMarginTop: 25,
          },
        },
        titleStyle: { fontSize: 9 },
      },
      label: {
        type: "outer",
        content: "{percentage}",
      },
      interactions: [
        {
          type: "element-active",
        },
      ],
    };

    const dataOutdoorCabinetTypewiseDistribution =
      this.state.outdoorCabinetTypewiseDistribution;

    const configOutdoorCabinetTypewiseDistribution = {
      appendPadding: 10,
      data: dataOutdoorCabinetTypewiseDistribution,
      angleField: "count",
      colorField: "type",
      radius: 0.8,
      legend: {
        position: "bottom",
        align: "left",
        flipPage: false,
        title: {
          text: "Cabinet Type",

          styles: {
            itemMarginTop: 25,
          },
        },
        titleStyle: { fontSize: 9 },
      },
      label: {
        type: "outer",
        content: "{percentage}",
      },
      interactions: [
        {
          type: "element-active",
        },
      ],
    };

    const config2 = {
      appendPadding: 10,
      data,
      angleField: "value",
      colorField: "type",
      radius: 0.8,
      legend: {
        position: "bottom",
        flipPage: false,
        title: {
          text: "Cabinet Type",

          styles: {
            itemMarginTop: 25,
          },
        },
        titleStyle: { fontSize: 9 },
      },
      label: {
        type: "outer",
        content: "{percentage}",
      },
      interactions: [
        {
          type: "element-active",
        },
      ],
    };
    return (
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <div class="page-title-box">
              <div class="row">
                <div class="col">
                  <h4 class="page-title">Power Data Dashboard</h4>
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <a href="javascript:void(0);">Power Data </a>
                    </li>
                    <li class="breadcrumb-item">
                      <a href="javascript:void(0);"> Dashboard</a>
                    </li>
                  </ol>
                </div>
                <div class="col-auto align-self-center">
                  <div class="row">
                    <div class="col-md-1">
                      <button
                        onClick={() => this.viewFilterClick()}
                        type="button"
                        style={{ marginLeft: 10 }}
                        class="btn btn-danger"
                      >
                        <i class="fas fa-filter"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <Collapse isOpen={this.state.isOpenFillter}>
            <div className="row">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-3 filter-form">
                      <Select
                        showSearch
                        name="regionId"
                        onChange={(e) => this.searchRegionmChange(e)}
                        placeholder="Region"
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        allowClear
                        // options={this.state.regionsList}
                      >
                        {this.state.regionsList?.map((item, index) => {
                          return (
                            <Option value={item.value}>{item.label}</Option>
                          );
                        })}
                      </Select>
                    </div>
                    <div class="col-md-3 filter-form">
                      <Select
                        showSearch
                        name="PowerConnectionType"
                        onChange={(e) => this.searchPowerConnectionChange(e)}
                        placeholder="Power Connection Type"
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        allowClear

                        // options={this.state.depotList}
                      >
                        {this.state.powerConnectionTypeSet?.map(
                          (item, index) => {
                            return (
                              <Option value={item?.id}>{item?.name}</Option>
                            );
                          }
                        )}
                      </Select>
                    </div>

                    <div class="col-md-3 filter-form">
                      <Select
                        placeholder="Indoor Outdoor Type"
                        style={{ width: "100%" }}
                        // options={this.state.allSite}
                        // loading={this.state.allSite_loading}
                        name="indoorOutdoorTypeId"
                        onChange={(e) => this.indoorOutdoorTypeIdChange(e)}
                        optionFilterProp="children"
                        allowClear
                      >
                        <Option value={1}>Indoor </Option>
                        <Option value={2}>Outdoor</Option>
                      </Select>
                    </div>

                    <div class="col-md-3">
                      <button
                        type="button"
                        class="btn btn-primary"
                        onClick={() => this.filterBtnClick()}
                      >
                        {" "}
                        Filter
                      </button>
                      <button
                        type="button"
                        style={{ marginLeft: 5 }}
                        class="btn btn-dark"
                        onClick={() => this.clearBtnClick()}
                      >
                        Clear Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Collapse>
        </div>
        <Spin spinning={this.state.isLoading} delay={200}>
          <div className="row">
            <div class="col-lg-4">
              <div class="card">
                <div class="card-body">
                  <div class="table-responsive">
                    {this.state.indoorOutdoorDataSet != null ? (
                      <IndoorOutdoor
                        dataSet={this.state.indoorOutdoorDataSet}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  {/*end /tableresponsive */}
                </div>
                {/*end card-body */}
              </div>
              {/*end card */}
            </div>{" "}
            {/* end col  */}
            <div class="col-lg-8">
              <div class="card">
                <div class="card-body">
                  {this.state.powerCnType ? (
                    <ReactApexChart
                      options={this.state.options}
                      series={this.state.series}
                      type="bar"
                      height={250}
                    />
                  ) : (
                    ""
                  )}
                </div>
                {/*end card-body */}
              </div>
              {/*end card */}
            </div>{" "}
            {/* end col  */}
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-6">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">
                    <b> Site Distribution with Site Load </b>{" "}
                  </h4>
                </div>
                <div className="card-body">
                  <div className="row">
                    {this.state.isSetSiteDistributionwithSiteLoad ? (
                      <ReactApexChart
                        options={this.state.optionsSiteDSL}
                        series={this.state.seriesSiteDSL}
                        type="bar"
                        height={350}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="row">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">
                      <b> Generator Distribution </b>
                    </h4>
                  </div>
                  <div className="card-body">
                    {this.state.generatorDistributionDataSet != null ? (
                      <GeneratorDistribution
                        dataSet={this.state.generatorDistributionDataSet}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">
                      <b> Site ER Value </b>
                    </h4>
                  </div>
                  <div className="card-body">
                    <Pie {...configSiteErValue} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">
                    <b>Solar Overview </b>{" "}
                  </h4>
                </div>
                <div className="card-body">
                  <div className="row">
                    {this.state.isSetSolaOverviewLoad ? (
                      <ReactApexChart
                        options={this.state.optionsSolaOverview}
                        series={this.state.seriesSolaOverview}
                        type="bar"
                        height={350}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">
                    <b>Power Share-Out</b>
                  </h4>
                </div>
                <div className="card-body">
                  {this.state.powerSharedOutDataSet != null ? (
                    <PowerShareOut dataSet={this.state.powerSharedOutDataSet} />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-6">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">
                    <b> Battery Overview </b>
                  </h4>
                </div>
                <div className="card-body">
                  {this.state.batteryOverviewDataSet != null ? (
                    <BatteryOverview
                      dataSet={this.state.batteryOverviewDataSet}
                    />
                  ) : (
                    ""
                  )}

                  <br></br>
                  <div className="row" style={{ textAlign: "center" }}>
                    <h6>
                      <b> Battery Model wise Distribution</b>{" "}
                    </h6>
                  </div>

                  <div className="row">
                    <Pie {...configBatteryModelWiseDistribution} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">
                    <b>Rectifier Overview</b>
                  </h4>
                </div>
                <div className="card-body">
                  <div class="table-responsive">
                    {this.state.rectifierOverviewDataSet != null ? (
                      <RectifierOverview
                        dataSet={this.state.rectifierOverviewDataSet}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <br></br>

                  <div className="row" style={{ textAlign: "center" }}>
                    <h6>
                      <b> Rectifier Model wise Distribution </b>
                    </h6>
                  </div>

                  <div className="row">
                    <Pie {...configRecModelWiseDistribution} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <div className="row"></div>
          </div>

          <div className="row">
            <div className="col-md-12 col-lg-7">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">
                    <b> Outdoor Cabinet Overview</b>
                  </h4>
                </div>
                <div className="card-body">
                  <div className="row">
                    {this.state.outdoorCabinetOverviewDataSet != null ? (
                      <OutdoorCabinetOverview
                        dataSet={this.state.outdoorCabinetOverviewDataSet}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <br></br>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="row" style={{ textAlign: "center" }}>
                        <h6>
                          <b> Outdoor Cabinet Type wise Distribution </b>
                        </h6>
                      </div>

                      <div className="row">
                        <Pie {...configOutdoorCabinetTypewiseDistribution} />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row" style={{ textAlign: "center" }}>
                        <h6>
                          {" "}
                          <b>Outdoor Cabinet Make wise Distribution </b>
                        </h6>
                      </div>

                      <div className="row">
                        <Pie {...configOutdoorCabinetMakewiseDistribution} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row"></div>
        </Spin>
      </div>
    );
  }
}

function mapStateToProps(state) {
  //console.log("state")
  //console.log(state)
  return {
    depots: state.depots,
    regions: state.regions,
  };
}
export default withRouter(connect(mapStateToProps, {})(PowerDataDashboard));
// export default PowerDataDashboard;

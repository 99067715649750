import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { getAllVendorTeam, updateVendorTeam, createNewVendorTeam, getAllTeamMembers, removeVendorTeamDataAccessFunction } from '../../api/index';
import { connect } from "react-redux";
import { ToastContainer, toast } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';
import { Collapse, Select, Button, Tag, message, Row, Col, Form, Modal, Input, Result, Table, Drawer, Menu, Dropdown, Space, Spin } from 'antd';
import { ExclamationCircleOutlined, DeleteOutlined, EditOutlined, PlusCircleFilled, MoreOutlined, EyeOutlined, MinusCircleOutlined } from '@ant-design/icons';
import './../master-data/style.css';
import { FiTrash2 } from 'react-icons/fi';

const { Option } = Select;

class VendorTeam extends Component {

	constructor(props) {
		super(props)
		this.validator = new SimpleReactValidator();
		this.state = {
			filterTable: null,
			name: "",
			code: "",
			vendor: 1,
			region: 1,
			selectedRegionList: [],
			vendorTeams: [],
			teamMambers: [],
			open: false,
			isLoading: false,
			selectedUser: null,
			isEdit: false,
			selectedUserList: [],
			showMobile: false,
			isEdit: false,
			isOpenModal: false,
			isView: false,
		}
	}

	componentDidMount() {
		this.getAllVendorTeams()
	}

	getAllVendorTeams = async () => {
		this.setState({
			isLoading: true
		})
		var res = await getAllVendorTeam();
		var team_mambers = await getAllTeamMembers();
		console.log(team_mambers);
		if (res.success) {
			this.setState({
				isLoading: false,
				vendorTeams: res.data,
				teamMambers: team_mambers.data
			})
		}
	}

	// window soft reaload function for realod current frame
	reloadWindow = () => {
		window.location.reload(true);
	}

	createVendorTeam = async () => {
		if (this.validator.allValid()) {
			var res;
			var data = {
				"name": this.state.name,
				"code": this.state.code,
				"vendor": { id: this.state.vendor },
				"region": { id: this.state.region },
				"users": this.state.selectedUserList
			}

			console.log(this.state.isEdit);

			if (this.state.isEdit) {
				data.id = this.state.id;
				console.log(data);
				res = await updateVendorTeam(data);
			}
			else {
				res = await createNewVendorTeam(data);
			}

			if (res.success) {
				toast.success(this.state.isEdit ? "Successfully Updated Vendor Team" : "Successfully Created Vendor Team");
				this.getAllVendorTeams();
				this.reloadWindow();
			}
		} else {
			this.validator.showMessages();
			// rerender to show messages for the first time
			// you can use the autoForceUpdate option to do this automatically`
			this.forceUpdate();
		}
	}

	formItemChange = (event) => {
		var obj = {};
		obj[event.target.name] = event.target.value;
		this.setState(obj);
	}


	vendorChange = (event) => {
		var obj = {};
		obj[event.target.name] = event.target.value;
		this.setState(obj);
	}

	search = (value) => {
		const { vendorTeams } = this.state;
		const filterTable = vendorTeams.filter((o) =>
			Object.keys(o).some((k) =>
				String(o[k]).toLowerCase().includes(value.toLowerCase())
			)
		);

		this.setState({ filterTable });
	};

	onDrawerClose = () => {
		this.setState({
			open: false
		})
	}

	openRegisterDrawer = (e) => {
		this.setState({
			open: true,
			selectedUser: e
		})
	}

	editVendorTeam = (e) => {
		this.setState({
			"id": e.id,
			"name": e.name,
			"code": e.code,
			"vendor": e.vendor.id,
			"region": e.region.id,
			"selectedUserList": e.users,
			"isEdit": true
		})


	}

	clearData() {
		this.setState({
			"id": "",
			"name": "",
			"code": "",
			"vendor": "",
			"region": "",
			"selectedUserList": [],
			"isEdit": true
		})
	}

	teamMemberChange = (team) => {
		var _team = [];
		team.map((item: any) => {
			_team.push({ "id": item })

		})
		this.setState({
			selectedUserList: _team
		})
	}

	regionChange = (team) => {
		var _rerion = [];
		team.map((item: any) => {
			_rerion.push({ "id": item })

		})
		this.setState({
			selectedRegionList: _rerion
		})
	}

	showPhoneNumberToast = (status) => {
		this.setState({
			showMobile: status
		})
	}

	showDeleteConfirm = async (record) => {
		const { confirm } = Modal;
		confirm({
			title: 'Do you want to deactivate this Vendor Team?',
			onOk: async () => {
				await this.deleteVendorTeamFromId(record.id);
			},
			onCancel() { },
		});
	};

	// removeVendorTeamDataAccessFunction
	deleteVendorTeamFromId = async (id) => {
		const formData = new FormData();
		formData.append('vendorTeamId', id);
		var res = await removeVendorTeamDataAccessFunction(formData);
		if (res.success) {
			toast.success('Vendor deactivated successfully');
			this.getAllVendorTeams();
		} else {
			toast.error(res?.data);
		}
	}

	showCreateConfirmationModal = (message) => {
		Modal.confirm({
			title: 'Confirm',
			icon: <ExclamationCircleOutlined />,
			content: message,
			okText: 'Yes',
			cancelText: 'No',
			onOk: () => this.createVendorTeam(),
		});
	};

	render() {
		const columns = [
			{
				title: <span className="textStyles-small">Team Name</span>,
				dataIndex: "name",
				key: "name",
				render: (text) => <span className="textStyles-small">{text == " " ? <b>Not Set</b> : text}</span>,
			},
			{
				title: <span className="textStyles-small">Team Code</span>,
				dataIndex: "code",
				key: "code",
				render: (text) => <span className="textStyles-small">{text}</span>,
			},
			{
				title: <span className="textStyles-small">Vendor</span>,
				dataIndex: "vendor",
				key: "vendor",
				render: (vendor) => <span className="textStyles-small">{vendor.name}</span>,
			},
			{
				title: <span className="textStyles-small">Region</span>,
				dataIndex: "region",
				key: "region",
				render: (region) => <span className="textStyles-small">{region.name}</span>,
			}, {
				title: "Action",
				key: "action",
				render: (text, record) => (
					// <Space size="middle">
					// 	<EyeOutlined twoToneColor="#52c41a" 
					// 	onClick={() => {
					// 		this.openRegisterDrawer(record);
					// 		this.setState({ isView: true, isOpenModal: true });
					// 	}} />
					// 	<EditOutlined onClick={() => this.editVendorTeam(record)} />
					// 	{/*<FiTrash2 onClick={() => this.showDeleteConfirm(record)} /> */}
					// </Space>
					<span className="text-end">

						<Button
						shape='circle'
						size='small'
						type='primary'
						icon={<EyeOutlined style={{ fontSize: '12px' }} />}
						style={{ marginLeft: '10px', backgroundColor: "#38B36E", borderColor: "#38B36E" }}
						onClick={() => {
							this.openRegisterDrawer(record);
							this.setState({
								isView: true,
								isOpenModal: true,
								isEdit: false
							});
						}}
					/>

					<Button
						shape='circle'
						size='small'
						type='primary'
						icon={<EditOutlined style={{ fontSize: '12px' }} />}
						style={{ marginLeft: '10px' }}
						onClick={() => {
							this.editVendorTeam(record);
							this.setState({
								isView: false,
								isEdit: true,
								isOpenModal: true
							});
						}}
					/>
					<Button
						shape='circle'
						size='small'
						type='primary'
						danger
						icon={<MinusCircleOutlined style={{ fontSize: '12px' }} />}
						style={{ marginLeft: '10px' }}
						onClick={() => this.showDeleteConfirm(record)}
					/>
				</span>
				),
			}
		];
		return (
			<div className="container-fluid">
				<div class="row">
					<div class="col-sm-12">
						<div class="page-title-box">
							<div class="row">
								<div class="col">
									<b>Vendor Team</b>
									<ol class="breadcrumb">
										<li class="breadcrumb-item"><a href="javascript:void(0);">Admin</a></li>
										<li class="breadcrumb-item"><a href="javascript:void(0);">Vendor Team</a></li>
									</ol>
								</div>
								<div class="col-auto align-self-center">
									<a href="#" class="btn btn-sm btn-outline-primary" id="Dash_Date">
										<span class="day-name" id="Day_Name">Today:</span>&nbsp;
										<span class="" id="Select_date">{new Date().toUTCString().slice(5, 16)}</span>
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar align-self-center icon-xs ms-1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div style={{ margin: '0', padding: '10px', width: '100%', backgroundColor: 'white', borderRadius: '10px', marginTop: '20px' }}>
					<Row>
						<Col span={12}>
							<Button type="primary" onClick={() => this.setState({ isOpenModal: true, isEdit: false })}
								style={{ background: "#38B36E", borderColor: "#38B36E", borderRadius: '5px', margin: 10 }}><PlusCircleFilled />
								<span className="textStyles-small">New Vendor Team</span></Button>
						</Col>
						<Col span={12} style={{ textAlign: 'right' }}>
							<Input
								enterButton
								className='borderedSelect' style={{ height: 33, margin: 10, marginRight: 10, width: 350 }}
								placeholder="Search Vendor Teams ..."
								onChange={(e) => {
									const currValue = e.target.value;
									this.search(currValue);
								}}
							/>
						</Col>
					</Row>
					<Spin spinning={this.state.isLoading} delay={200}>
						<Table
							className="table-striped-rows"
							size="small"
							columns={columns}
							style={{ marginTop: 10 }}
							dataSource={this.state.filterTable == null ?
								this.state.vendorTeams.sort((a, b) => b.id - a.id)
								: this.state.filterTable.sort((a, b) => b.id - a.id)
							}
							rowKey="id"
						/>
					</Spin>
				</div>
				<Modal 
					title={<span className='textStyles-small' style={{ fontSize: 14 }}>{this.state.isView ? "Vendor Team Details" : this.state.isEdit ? "Edit Vendor Team" : "Register New Vendor Team"}</span>}
					visible={this.state.isOpenModal} 
					// visible={true}
					onOk={() => {
						this.setState({ isOpenModal: false, isEdit: false, isView: false });
						this.clearData();
					}} 
					onCancel={() => {
						this.setState({ isOpenModal: false, isEdit: false, isView: false });
						this.clearData();
					}}
					footer={null}
					width={550}
				>
					{
						this.state.isView ? (
							<>
							{this.state.selectedUser !== null && <div class="row">
								<div class="col-12">
									<div class="card">
										<div class="card-body p-0">
											<div id="user_map" class="pro-map"  ></div>
										</div>
										<div class="card-body">
											<div class="dastone-profile">
												<div class="row">
		
													<div class="col-lg-8">
														<ul class="list-unstyled personal-detail mb-0">
															<li class="mt-1 textStyles-small"><b> Name </b> : {
																this.state.selectedUser && this.state.selectedUser.name
																	? this.state.selectedUser.name
																	: "Not set"
															}</li>
															<li class="mt-1 textStyles-small"><b> Code </b> : {this.state.selectedUser.code}</li>
														</ul>
													</div>
												</div>
											</div>
										</div></div>
									<div class="card">
										<div class="card-body">
											<div class="dastone-profile">
												<div class="row">
													<div class="col-lg-8">
														<ul class="list-unstyled personal-detail mb-0">
															<li class="mt-1 textStyles-small"><b> Vendor Name </b> : {this.state.selectedUser.vendor.name}</li>
															<li class="mt-1 textStyles-small"><b> Vendor Contact Number </b> : {this.state.selectedUser.vendor.contactNumber}</li>
															<li class="mt-1 textStyles-small"><b> Vendor Contact Person </b> : {this.state.selectedUser.vendor.contactPerson}</li>
														</ul>
													</div>
												</div>
											</div>
										</div></div>
									<div class="card">
										<div class="card-body">
											<div class="dastone-profile">
												<div class="row">
													<div class="col-lg-8">
														<ul class="list-unstyled personal-detail mb-0">
															<li class="mt-1 textStyles-small"><b> Region </b> : {this.state.selectedUser.region.name}</li>
															<li class="mt-1 textStyles-small"><b> Organization </b> : {this.state.selectedUser.region.organization.name}</li>
														</ul>
													</div>
		
												</div>
											</div>
										</div></div>
									
									<span className="textStyles-small"
									style={{ fontSize: 13 }}>{this.state.selectedUser && this.state.selectedUser.users.length == 0 ? "No team members" : "Team Members"}</span>
									<div class="row">
		
										{this.state.selectedUser !== null && this.state.selectedUser.users.map((user) => {
											return <div class="col-lg-11">
												<div class="card">
													<div class="card-body">
														<div class="row">
															<div class="col">
																<div class="media">
																	<img src="assets/images/users/user-2.jpg" alt="user" class="rounded-circle thumb-lg align-self-center" />
																	<div class="media-body ms-3 align-self-center">
																		<h5 class="mt-0 mb-1 textStyles-small">{user.name}</h5>
																		<p class="mb-0 text-muted textStyles-small">{user.email}</p>
																		{this.state.showMobile && <p class="mb-0 text-muted textStyles-small">{user.mobileNumber}</p>}
																	</div>
																</div>
															</div>
															<div class="col-auto align-self-center">
																<ul class="list-inline mb-0">
																	<li class="list-inline-item">
																		<a href="#" onMouseEnter={() => this.showPhoneNumberToast(true)} onMouseLeave={() => this.showPhoneNumberToast(false)} class="me-1 contact-icon"><i class="fas fa-phone"></i></a>
																	</li>
																</ul>
															</div>
														</div>
													</div>
												</div>
											</div>
										})}
									</div>
								</div>
							</div>
							} </>
						) : (
							<div>
							<form>
								<Row>
									<Col span={12}>
									<div>
									<label className="form-label" for="name"><span className='textStyles-small' style={{ fontSize: 12 }}>Vendor Team Name</span></label>
									<input autoComplete="off" 
									type="text" 												
									className="borderedSelect"
												style={{ height: 34, width: '98%' }}
												name="name" value={this.state.name} 
												aria-describedby="emailHelp" 
												onChange={(e) => this.formItemChange(e)} />
									<p style={{ color: "#f5325c" }}>{this.validator.message('name', this.state.name, 'required')}</p>
								</div>
									</Col>

									<Col span={12}>
									<div>
									<label className="form-label" for="description"><span className='textStyles-small' style={{ fontSize: 12 }}>Vendor Team Code</span></label>
									<input autoComplete="off" type="text" value={this.state.code}
																					className="borderedSelect"
																					style={{ height: 34, width: '98%' }}
									name="code" onChange={(e) => this.formItemChange(e)} />
									<p style={{ color: "#f5325c" }}>{this.validator.message('code', this.state.code, 'required')}</p>
								</div>
									</Col>

									<Col span={12}>
									<div>
									<label className="form-label" for="exampleInputPassword1"><span className='textStyles-small' style={{ fontSize: 12 }}>Region</span></label>
									<div className="col-sm-12">
										<select 
																						className='borderedSelect'
																						style={{ height: 34, width: '98%' }}
																						bordered={false}
										name="region" value={this.state.region} onChange={(e) => this.formItemChange(e)}>
											{this.props.regions.map((region, index) => {
												return <option value={region.id}>{region.name}</option>
											})}
										</select>
										{/* <p style={{color:"#f5325c"}}>{this.validator.message('vendor', this.state.vendor, 'required')}</p> */}
									</div>
								</div>
									</Col>

									<Col span={12}>
									<div>
									<label className="form-label" for="exampleInputPassword1"><span className='textStyles-small' style={{ fontSize: 12 }}>Vendor</span></label>
									<div className="col-sm-12">
										<select 
																						className='borderedSelect'
																						style={{ height: 34, width: '98%' }}
																						bordered={false}
										name="vendor" value={this.state.vendor} onChange={(e) => this.vendorChange(e)}>
											{this.props.vendors.map((vendor, index) => {
												return <option value={vendor.id}>{vendor.name}</option>
											})}
										</select>
										{/* <p style={{color:"#f5325c"}}>{this.validator.message('vendor', this.state.vendor, 'required')}</p> */}
									</div>
								</div>
									</Col>

									<Col span={24}>
												
								<div>
									<label class="form-label" for="exampleInputEmail1"><span className='textStyles-small' style={{ fontSize: 12 }}>Team Members</span></label>
									<Select
										mode="multiple"
										showSearch
										name="regionId"
										onChange={(e) => this.teamMemberChange(e)}
										className='borderedSelect'
										style={{ width: '100%' }}
										bordered={false}
										value={this.state.selectedUserList.map((item) => item.id)}
										filterOption={(input, option) =>
											option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
										}
										filterSort={(optionA, optionB) =>
											optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
										}
									>
										{this.state.teamMambers.map((item, index) => {
											return <Option key={index} value={item.id}>{item.name + "-" + item.email}</Option>;
										})}
									</Select>
								</div>
									</Col>
								</Row>


								{/* <div class="mb-3">
									<label class="form-label" for="exampleInputEmail1">Region</label>
									<Select
										mode="multiple"
										showSearch
										name="region"
										onChange={(e) => this.regionChange(e)}
										placeholder="Region"
										style={{ width: "100%" }}
										value={this.state.selectedRegionList.map((item) => item.id)}
										filterOption={(input, option) =>
											option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
										}
										filterSort={(optionA, optionB) =>
											optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
										}
									>
										{this.props.regions.map((item, index) => {
											return <Option key={index} value={item.id}>{item.name}</Option>;
										})}
									</Select>
								</div> */}



							</form>
							{/* <button className="btn btn-primary"
								onClick={() => this.showCreateConfirmationModal(this.state.isEdit ?
									"Are you sure you want to update this Vendor Team?" : "Are you sure you want to create this Vendor Team?")} >
								{this.state.isEdit ? "Update Vendor Team" : "Register New Vendor Team"}
							</button> */}
							<Row style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
								<button type="submit" className="btn btn-primary"
									style={{ background: "#38B36E", borderColor: "#38B36E", borderRadius: '5px' }}
									onClick={() => this.showCreateConfirmationModal(this.state.isEdit ?
										"Do you want to update this Vendor Team?" : "Do you want to create this Vendor Team?")} >
										<span className="textStyles-small">{this.state.isEdit ? "Update Vendor Team" : "Register New Vendor Team"}</span>
								</button>
							</Row>
						</div>
						)
					}


				</Modal>
				<ToastContainer position="top-right" autoClose={5000} />
			</div >
		);
	}
}


function mapStateToProps(state) {
	console.log(state)
	return {
		vendors: state.vendor,
		regions: state.regions,

	};
}

function mapDispatchToProps(dispatch) {
	return {
		// addVendor: data => dispatch(addVendor(data))
	};
}

export default connect(
	mapStateToProps, mapDispatchToProps
)(VendorTeam);


import React, { Component } from 'react'
import { connect } from "react-redux";
import { getDonationCategories, getData } from "../../actions/index";
import { Upload, Select, Spin, Table, Tag, Popconfirm, message, Drawer, DatePicker } from "antd";
const { Column } = Table;
const moment = require('moment');
const { RangePicker } = DatePicker;

class PowerDataView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            SubRegion_list: [],
            depot_list: [],
            filter_date: moment(moment().format("YYYY-MM-DD").slice(0, -2) + '01')._i,
            filter_date_to: moment().format("YYYY-MM-DD"),
            filter_depot: [],
            filter_region: [],
            filter_sub_region: [],
            selectedServiceTypeIds: [2],
            serviceTypes: [{ "id": 2, "name": "Generator" }, { "id": 3, "name": "Air Conditioner" }, { "id": 4, "name": "SPD" }, { "id": 5, "name": "ATS" }, { "id": 6, "name": "DCDU" }, { "id": 7, "name": "FCB" }, { "id": 8, "name": "Battery Rack" }, { "id": 9, "name": "Renewable Energy Solar" }, { "id": 10, "name": "Renewable Energy Wind" }, { "id": 11, "name": "Shared Out Operator" }, { "id": 12, "name": "Rectifier" }, { "id": 13, "name": "Battery Bank" }, { "id": 14, "name": "Cabinet" }],
        }
    }

    componentDidMount() {
        this.props.getData();
        var data = new FormData();
        console.log(this.state.filter_date);
        console.log(this.state.filter_date_to);
        data.append('scheduleDateFrom', this.state.filter_date);
        data.append('scheduleDateTo', this.state.filter_date_to);
        data.append('regions', this.state.filter_region);
        data.append('subregions', this.state.filter_sub_region);

        this.setState({
            depot_list: this.props.depots,
            SubRegion_list: this.props.regions.filter((item) => {
                return item.isSubRegion == 1;
            }),
        });
    }

    serviceTypesChange = (selectedIds) => {
        if(selectedIds.length != 0){
            this.setState({
                selectedServiceTypeIds: selectedIds,
            })
        }
    }

    searchClick = () => {

    }

    clearBtnClick = () => {

    }

    onChangeRegion = (value) => {
        this.setState({
            depot_list: [],
            filter_depot: [],
            filter_sub_region: [],

        });
        console.log(value);
        this.setState({
            filter_region: value,
            selectedDepot: "all_depots",
            selected_region_id: value,
            SubRegion_list: this.props.regions.filter(alldate => value.map((n) => n).includes(alldate.parentRegion?.id))

        }, () => {
            if (this.state.filter_sub_region == "") {
                var getall = this.props.regions.filter(alldate => value.includes(alldate.parentRegion?.id))
                var output = [];
                for (var i = 0; i < getall.length; ++i)
                    output.push(getall[i]['id']);

                var data1 = new FormData();

                console.log(this.state.filter_region);
                data1.append('scheduleDateFrom', this.state.filter_date);
                data1.append('scheduleDateTo', this.state.filter_date_to);
                data1.append('regions', this.state.filter_region);
                data1.append('subregions', this.state.filter_sub_region);

            }
        });
    };

    dataonChange = (date, dateString) => {
        this.setState({
            filter_date: dateString[0],
            filter_date_to: dateString[1],
        });
    };

    onsubChange = (value) => {
        this.setState({
            depot_list: [],
            filter_depot: [],
        });
        this.setState({
            selectedDepot: "all_depots",
            selected_region_id: value,
            filter_sub_region: value,
            depot_list: this.props.depots.filter(alldate => value.map((n) => n).includes(alldate.region?.id))
        }, () => {
            var data1 = new FormData();

            console.log(this.state.filter_region);
            data1.append('scheduleDateFrom', this.state.filter_date);
            data1.append('scheduleDateTo', this.state.filter_date_to);
            data1.append('regions', this.state.filter_region);
            data1.append('subregions', this.state.filter_sub_region);
        });
    };

    showLoading() {
        this.setState({
            isLoading: true
        })
    }


    hideLoading() {
        this.setState({
            isLoading: false
        })
    }
    
    render() {
        const { Option } = Select;
        const columns = [

            {
                title: "Site ID",

                dataIndex: "siteId",
                key: "site",
                fixed: 'left',
                render: (item) => <span>{item}</span>,
            },
            {
                title: "Site Name",

                dataIndex: "siteName",
                key: "siteName",
                fixed: 'left',
                render: (item) => <span>{item}</span>,
            },
            {
                title: "Region Name",
                dataIndex: "regionName",
                key: "regionName",
                render: (item) => <span>{item}</span>,
            },
            {
                title: "Depot Name",
                dataIndex: "depotName",
                key: "depotName",
                render: (item) => <span>{item}</span>,
            },
            {
                title: "Site Address",
                // dataIndex: "depotName",
                key: "siteAddressList",
                render: (item) => <span>{item?.siteAddressList[0]?.address}</span>,
            },
            {
                title: "Longitude",
                // dataIndex: "depotName",
                key: "Longitude",
                render: (item) => <span>{item?.siteAddressList[0]?.longitude}</span>,
            },
            {
                title: "Latitude",
                // dataIndex: "depotName",
                key: "Latitude",
                render: (item) => <span>{item?.siteAddressList[0]?.latitude}</span>,
            },
            {
                title: "Site Type",
                // dataIndex: "depotName",
                key: "siteType",
                render: (item) => <span>{item?.siteType?.name}</span>,
            },
            {
                title: "Site Status",
                // dataIndex: "depotName",
                key: "status",
                render: (item) => <span>{item?.status}</span>,
            },
            {
                title: "Site Elevation",
                dataIndex: "siteElevation",
                key: "siteElevation",
                render: (item) => <span>{item}</span>,
            },
            {
                title: "Site Key Required",
                dataIndex: "sitsiteKeyRequirede",
                key: "siteKeyRequired",
                render: (item) => <span>{item}</span>,
            },
            {
                title: "Site On Air Date",
                dataIndex: "siteOnAirDate",
                key: "siteOnAirDate",
                render: (item) => <span>{item}</span>,
            },
            // {
            //     title: "Site PowerDb",
            //     dataIndex: "sitePowerDb",
            //     key: "sitePowerDb",
            //     render: (item) => <span>{item}</span>,
            // },
            {
                title: "Site Topology Terrain Mapping",
                dataIndex: "siteTopologyTerrainMapping",
                key: "siteTopologyTerrainMapping",
                render: (item) => <span>{item}</span>,
            },
            {
                title: "Shelter Size",
                dataIndex: "shelterSize",
                key: "shelterSize",
                render: (item) => <span>{item}</span>,
            },
            {
                title: "Tower Height",
                dataIndex: "towerHeight",
                key: "towerHeight",
                render: (item) => <span>{item}</span>,
            },
            {
                title: "Tower Legs Count",
                dataIndex: "towerLegsCount",
                key: "towerLegsCount",
                render: (item) => <span>{item}</span>,
            },
            {
                title: "Tower Owner Category",
                dataIndex: "towerOwnerCategory",
                key: "towerOwnerCategory",
                render: (item) => <span>{item}</span>,
            },
            {
                title: "Vehicle Type",
                dataIndex: "vehicleType",
                key: "vehicleType",
                render: (item) => <span>{item}</span>,
            },
        ];

        function onBlur() {
            //console.log("blur");
        }

        function onFocus() {
            //console.log("focus");
        }

        function onSearch(val) {
            //console.log("search:", val);
        }
        return (
            <Spin spinning={this.state.isLoading} delay={200}>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="page-title-box">
                                <div class="row">
                                    <div class="col">
                                        <h4 class="page-title">Power Data</h4>
                                        <ol class="breadcrumb">
                                            <li class="breadcrumb-item"><a href="javascript:void(0);">Powerdb </a></li>
                                            <li class="breadcrumb-item"><a href="javascript:void(0);">Data View</a></li>

                                        </ol>
                                    </div>
                                    <div class="col-auto align-self-center">
                                        <div class="btn btn-sm btn-outline-primary" id="Dash_Date">
                                            <span class="day-name" id="Day_Name">Today:</span>&nbsp;
                                            <span class="" id="Select_date">{new Date().toUTCString().slice(5, 16)}</span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar align-self-center icon-xs ms-1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ marginRight: 15 }}>
                        <div class="col-3">
                            <div className="row">
                                <div class="col-sm">
                                    <Select
                                        mode= "multiple"
                                        onChange={(e) => this.serviceTypesChange(e)}
                                        style={{ width: "90%", borderRadius: 10 }}
                                        value={this.state.selectedServiceTypeIds}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA, optionB) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }
                                    >
                                        {this.state.serviceTypes.map((item, index) => {
                                            return <Option key={index} value={item.id}>{item.name}</Option>;
                                        })}
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <div class="col-9">
                            <div className="row justify-content-start">
                                <div className="col-md-2 col-lg-4" style={{ paddingBottom: 17 }}>
                                    <RangePicker
                                        style={{ width: "100%" }}
                                        defaultValue={[moment(moment().format("YYYY-MM-DD").slice(0, -2) + '01'), moment()]}
                                        onChange={this.dataonChange}
                                    />
                                </div>
                                <div className="col-md-2 col-lg-4" style={{ paddingBottom: 17 }}>
                                    <Select
                                        mode="multiple"
                                        showSearch
                                        style={{ width: "100%" }}
                                        placeholder="Select a region"
                                        optionFilterProp="children"
                                        onChange={this.onChangeRegion}
                                        onFocus={onFocus}
                                        onBlur={onBlur}
                                        onSearch={onSearch}
                                        filterOption={(input, option) =>
                                            option.props.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {this.props.regions.filter(allret => "0".includes(allret.isSubRegion)).map((item, index) => {
                                            return <Option value={item.id}>{item.name}</Option>;
                                        })}
                                    </Select>
                                </div>
                                <div className="col-md-3 col-lg" >
                                    <Select
                                        mode="multiple"
                                        showSearch
                                        style={{ width: "100%" }}
                                        placeholder="Select a Subregion"
                                        optionFilterProp="children"
                                        onChange={this.onsubChange}
                                        onFocus={onFocus}
                                        onBlur={onBlur}
                                        onSearch={onSearch}
                                        filterOption={(input, option) =>
                                            option.props.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {this.state.SubRegion_list.map((item, index) => {
                                            return <Option value={item.id}>{item.name}</Option>;
                                        })}
                                    </Select>
                                </div>
                                <div className="col-md-2 col-lg-4" style={{ paddingBottom: 17 }}>
                                    <Select
                                        mode="multiple"
                                        showSearch
                                        style={{ width: "100%" }}
                                        placeholder="Select a depot"
                                        optionFilterProp="children"
                                        onChange={this.onChange2}
                                        defaultActiveFirstOption={true}
                                        allowClear={true}
                                        filterOption={(input, option) =>
                                            option.props.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {this.state.depot_list.map((item, index) => {
                                            return <Option value={item.id}>{item.name}</Option>;
                                        })}
                                    </Select>
                                </div> 
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ display: "flex", justifyContent: 'end' }}>
                        <div className='col-md-2' style={{ textAlign: 'end', padding: 0 }}>
                            <button
                                onClick={this.searchClick}
                                class="btn btn-primary"
                                style={{ marginRight: -16 }}
                            >
                                <i class="fas fa-search"> </i> Search
                            </button>
                        </div>
                        <div className='col-md-1' style={{ textAlign: 'end' }}>
                            <button type="button" style={{ marginLeft: 5 }} class="btn btn-dark" onClick={() => this.clearBtnClick()}>Clear</button>
                        </div>
                    </div>
                    <br></br>
                    <div className="col-lg-12 col-xl-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="row ">
                                    <div className="col">
                                        <h4 className="card-title">
                                            {this.state.serviceTypes.map((item) => {
                                                return(this.state.selectedServiceTypeIds.includes(item.id)) 
                                                    ? <a style={{ paddingRight: 20 }}>{item.name}</a>
                                                    : ""
                                            })}
                                        </h4>
                                        <div></div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <Table
                                    size="small"
                                    columns={columns}
                                    // loading={this.state.loading && this.state.rectifierloading && this.state.batteryBanksloading && this.state.airConditionersloading && this.state.spdsloading && this.state.cabinetsloading && this.state.generatorsloading && this.state.atsesloading && this.state.dcdusloading && this.state.fcbsloading && this.state.sharedOutOperatorsesloading}
                                    loading={this.state.tabaleLoading}
                                    dataSource={this.state.data_list}
                                    scroll={{
                                        x: 1300,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                    </div>
                </div>
            </Spin>

        )
    }
}

function mapStateToProps(state) {
    //console.log("state", state);
    return {
        organization_count: state.organization.length,
        regions: state.regions,
        depots: state.depots,
        sites_count: state.sites.length,
        vendor_count: state.vendor.length,
        roles_count: state.roles.length,
    };
}

export default connect(mapStateToProps, { getDonationCategories, getData })(
    PowerDataView
);

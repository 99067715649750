import React, { Component } from 'react'
import { InputNumber, message, Input, DatePicker, Select } from 'antd';
import {updateEarthing} from "../../api/index";
import moment from 'moment';
const { Option } = Select;

class Earthing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            switchStatus: true,
            objectSet: {
                "earERvalue": 0,
                "earSRValueMeasureDate": "",
                "earSR1m": 0,
                "earSR3m": 0,
                "earAverageSRValue": 0,
                "earERImprovementStatus": "",
                "latestERMeasuredDate": ""
            }
        }
        this.handlearERvalue = this.handlearERvalue.bind(this);
        this.handlearSRValueMeasureDate = this.handlearSRValueMeasureDate.bind(this);
        this.handlearSR1m = this.handlearSR1m.bind(this);
        this.handlearSR3m = this.handlearSR3m.bind(this);
        this.handlearAverageSRValue = this.handlearAverageSRValue.bind(this);
        this.handlearERImprovementStatus = this.handlearERImprovementStatus.bind(this);
        this.handlelatestERMeasuredDate = this.handlelatestERMeasuredDate.bind(this);
        
    }
    handlearERvalue(e) {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                earERvalue: e
            }

        })
    }
    handlearSRValueMeasureDate(date, dateString) {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                earSRValueMeasureDate: dateString
            }

        })
    }
    handlearSR1m(e) {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                earSR1m: e
            }

        })
    }
    handlearSR3m(e) {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                earSR3m: e
            }

        })
    }
    handlearAverageSRValue(e) {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                earAverageSRValue: e
            }

        })
    }
    handlearERImprovementStatus(e) {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                earERImprovementStatus: e.target.value
            }

        })
    }
    handlelatestERMeasuredDate(date, dateString) {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                latestERMeasuredDate: dateString
            }

        })
    }
    componentDidMount() {
        console.log(this.props.details)
        console.log('powerCon')
        this.setState({
            objectSet: {
                "earERvalue": this.props.details.access_technologies_data?.earERvalue,
                "earSRValueMeasureDate": this.props.details.access_technologies_data?.earSRValueMeasureDate,
                "earSR1m": this.props.details.access_technologies_data?.earSR1m,
                "earSR3m": this.props.details.access_technologies_data?.earSR3m,
                "earAverageSRValue": this.props.details.access_technologies_data?.earAverageSRValue,
                "earERImprovementStatus": this.props.details.access_technologies_data?.earERImprovementStatus,
                "latestERMeasuredDate": this.props.details.access_technologies_data?.latestERMeasuredDate
            }

        }, () => {
            console.log("opopop")
            console.log(this.state.objectSet)
        })
    }
    dataSetToUpdate = async () => {

        var res = await updateEarthing(this.props.details2.site_details.id, this.state.objectSet);

        if(res.success){
           message.success('Successfully Update!')
           this.props.parentMethod();
           this.setState({
             switchStatus: true
         })
        }else{
         message.warning(' Something went wrong!')
        }

    }
    dataUpdate() {
        console.log("ssss")
        this.setState({
            switchStatus: false
        })
    }
    close = async () =>{
        this.props.parentMethod();
        this.setState({
            objectSet: {
                "earERvalue": this.props.details.access_technologies_data?.earERvalue,
                "earSRValueMeasureDate": this.props.details.access_technologies_data?.earSRValueMeasureDate,
                "earSR1m": this.props.details.access_technologies_data?.earSR1m,
                "earSR3m": this.props.details.access_technologies_data?.earSR3m,
                "earAverageSRValue": this.props.details.access_technologies_data?.earAverageSRValue,
                "earERImprovementStatus": this.props.details.access_technologies_data?.earERImprovementStatus,
                "latestERMeasuredDate": this.props.details.access_technologies_data?.latestERMeasuredDate
            }
        });
        this.setState({
            switchStatus: true
        });
    }
    render() {
        return (
            <>
                {this.props?.details?.access_technologies_data != null ? (
                    <div className='row'>
                        <div className='col-md-6'>
                            <div class="card">
                                <div class="card-header">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <h4 class="card-title">Information</h4>
                                        {this.state.switchStatus
                                            ?   <button type="button" class="btn btn-soft-primary btn-icon-square-sm" onClick={() => this.dataUpdate()}> <i class="mdi mdi-pencil-outline"></i></button>
                                            :   <div class="d-flex justify-content-end align-items-center">
                                                    <button type="button" class="btn btn-soft-secondary " onClick={() => this.close()} style={{marginRight:"10px"}}> Cancel </button>    
                                                    <button type="button" class="btn btn-soft-primary " onClick={() => this.dataSetToUpdate()}> Submit to Update </button>
                                                </div>
                                        }
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="row">

                                        <ul class="list-group">
                                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                                <div>
                                                    <i class="la la-hand-o-right text-primary me-2"></i>Latest  ER Value (Ohms)
                                                </div>
                                                {this.state.switchStatus ? this.props.details.access_technologies_data?.earERvalue ? ( <span class="badge badge-outline-success">{this.props.details.access_technologies_data.earERvalue}</span>):( <span class="badge badge-outline-warning">{this.props.details.access_technologies_data.earERvalue}</span>) : <InputNumber style={{ width: '20%' }} value={this.state.objectSet?.earERvalue} id="mpcNoOfPhases" onChange={this.handlearERvalue} />}

                                            </li>
                                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                                <div>
                                                    <i class="la la-hand-o-right text-primary me-2"></i>Latest ER Measured Date
                                                </div>
                                                {this.state.switchStatus ? this.props.details.access_technologies_data?.latestERMeasuredDate != null ? ( <span class="badge badge-outline-success">{this.props.details.access_technologies_data.latestERMeasuredDate}</span>):( <span class="badge badge-outline-warning">Currently Not Set</span>) : <DatePicker style={{ width: '30%' }} defaultValue={(this.state.objectSet?.latestERMeasuredDate != '' && this.state.objectSet?.latestERMeasuredDate != null) ? (moment(this.state.objectSet?.latestERMeasuredDate)) : ("")} id="mpcNoOfPhases" onChange={this.handlelatestERMeasuredDate} />}

                                            </li>
                                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                                <div>
                                                    <i class="la la-hand-o-right text-primary me-2"></i>SR Value Measure Date
                                                </div>
                                                {this.state.switchStatus ? this.props.details.access_technologies_data?.earSRValueMeasureDate != null ? ( <span class="badge badge-outline-success">{this.props.details.access_technologies_data.earSRValueMeasureDate}</span>):( <span class="badge badge-outline-warning">Currently Not Set</span>) : <DatePicker style={{ width: '30%' }} defaultValue={(this.state.objectSet?.earSRValueMeasureDate != '' && this.state.objectSet?.earSRValueMeasureDate != null) ? (moment(this.state.objectSet?.earSRValueMeasureDate)) : ("")} id="mpcNoOfPhases" onChange={this.handlearSRValueMeasureDate} />}

                                            </li>
                                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                                <div>
                                                    <i class="la la-hand-o-right text-primary me-2"></i>SR_1m
                                                </div>
                                                {this.state.switchStatus ? this.props.details.access_technologies_data?.earSR1m ? ( <span class="badge badge-outline-success">{this.props.details.access_technologies_data.earSR1m}</span>):( <span class="badge badge-outline-warning">{this.props.details.access_technologies_data.earSR1m}</span>) : <InputNumber style={{ width: '20%' }} value={this.state.objectSet?.earSR1m} id="mpcNoOfPhases" onChange={this.handlearSR1m} />}

                                            </li>
                                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                                <div>
                                                    <i class="la la-hand-o-right text-primary me-2"></i>SR_3m
                                                </div>
                                                {this.state.switchStatus ? this.props.details.access_technologies_data?.earSR3m ? ( <span class="badge badge-outline-success">{this.props.details.access_technologies_data.earSR3m}</span>):( <span class="badge badge-outline-warning">{this.props.details.access_technologies_data.earSR3m}</span>) : <InputNumber style={{ width: '20%' }} value={this.state.objectSet?.earSR3m} id="mpcNoOfPhases" onChange={this.handlearSR3m} />}

                                            </li>
                                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                                <div>
                                                    <i class="la la-hand-o-right text-primary me-2"></i>Average SR Value
                                                </div>
                                                {this.state.switchStatus ? this.props.details.access_technologies_data?.earAverageSRValue ? ( <span class="badge badge-outline-success">{this.props.details.access_technologies_data.earAverageSRValue}</span>):( <span class="badge badge-outline-warning">{this.props.details.access_technologies_data.earAverageSRValue}</span>) : <InputNumber style={{ width: '20%' }} value={this.state.objectSet?.earAverageSRValue} id="mpcNoOfPhases" onChange={this.handlearAverageSRValue} />}

                                            </li>

                                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                                <div>
                                                    <i class="la la-hand-o-right text-primary me-2"></i>ER Improvement Status (WIP/Completed)
                                                </div>
                                                {/* {this.state.switchStatus ? this.props.details.access_technologies_data?.earERImprovementStatus !=null ? (<span class="badge badge-outline-success">{this.props.details.access_technologies_data.earERImprovementStatus}</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>) : <Input style={{ width: '20%' }} value={this.state.objectSet?.earERImprovementStatus} id="mpcNoOfPhases" onChange={this.handlearERImprovementStatus} />} */}
                                                <div>
                                                        
                                                        {this.state.switchStatus.toString() == "true" ? (
                                                            this.props.details.access_technologies_data?.earERImprovementStatus ?(
                                                                <span class="badge badge-outline-success">{this.props.details.access_technologies_data?.earERImprovementStatus}</span>
                                                            ) : (
                                                                <span class="badge badge-outline-danger">Currently Not Set</span>
                                                            )
                                                        ) : (
                                                            <Select
                                                                defaultValue={this.props.details.access_technologies_data?.earERImprovementStatus}
                                                                style={{ width: 120 }}
                                                                onChange={(e) => {
                                                                    this.state.objectSet.earERImprovementStatus = e;
                                                                }}>
                                                                {this.props.dropdoenEAR["dropdownObjEarthing"]["earERImprovementStatus"]?.map((x) => {
                                                                    return <Option value={x}>{x}</Option>
                                                                })}
                                                            </Select>
                                                        )}
                                                    </div>
                                            </li>
                                            {/* <li class="list-group-item d-flex justify-content-between align-items-center">
                                                <div>
                                                    <i class="la la-hand-o-right text-primary me-2"></i>Average Energy
                                                </div>

                                                {this.props.details.access_technologies_data?.earAverageEnergy ? (<span class="badge badge-outline-success">{JSON.stringify(this.props.details.access_technologies_data.earAverageEnergy)}</span>) : (<span class="badge badge-outline-warning">{JSON.stringify(this.props.details.access_technologies_data?.earAverageEnergy)}</span>)}

                                            </li>
                                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                                <div>
                                                    <i class="la la-hand-o-right text-primary me-2"></i>Average Energy
                                                </div>

                                                {this.props.details.access_technologies_data?.earAverageEnergy ? (<span class="badge badge-outline-success">{JSON.stringify(this.props.details.access_technologies_data.earAverageEnergy)}</span>) : (<span class="badge badge-outline-warning">{JSON.stringify(this.props.details.access_technologies_data?.earAverageEnergy)}</span>)}

                                            </li> */}
                                           
                                            

                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <h4 style={{ color: "#c5c5c5" }}>No Dat Found Earthing</h4></div>)}

            </>
        )
    }
}
export default Earthing;

import React, { Component } from 'react';
import { Select,DatePicker } from 'antd';
import { connect } from "react-redux";
import { getRegionWiseDepots } from "../api/index";
import { getDonationCategories, getData } from "../actions/index";

//live dashbord 
import { DataContext, withContext } from "../context"
import { Loading } from "../components/essentials/alerts"
import { LoginModal } from "../components/essentials/modals"
import { FiToggleLeft, FiToggleRight, FiMapPin, FiNavigation,FiCalendar } from 'react-icons/fi';
import FullMap from "../components/vector-map";
import { Card,Card2, VehicleCard, VehicaleCell, LocationContainer } from "../components/cards";
import { CardContainer } from "../components/essentials/containers";
import { Flex, Box } from "rebass"
import { TickClock, StillClock }from "../components/clocks";
import '../../LivePg.css';
import './react-tag.css';
import _ from "lodash";
import { getPgByDepots } from "../api/index";
import Marker from "../components/vector-map/marker";

import markers from "../../markers.config"
import {
  DEFAULT_HEIGHT,
  DEFAULT_WIDTH
} from "../components/defaults"

import "../components/vector-map/map.css"
import { Collapse  } from 'reactstrap';
// import { WithContext as ReactTags } from 'react-tag-input';


const { RangePicker } = DatePicker;




const { Option } = Select;

function VehicleStatCard ({stats}){
  let {
    total_pg,
    delayed_pg
  } = stats;

    return(
      <Card 
        value={delayed_pg}
        from={total_pg}
        header={<React.Fragment><FiNavigation style={{margin: 5}}/>DELAYED</React.Fragment>}
        className="app-dashboard app-dashboard-card-color-lable red"
        style={{paddingLeft: 10}}/>
    )
}
function LocationStatCard ({stats}){

    let {
      total_pg,
      delayed_pg
    } = stats;
    //console.log("total_pg");
    //console.log(stats);
    return(
      <Card2 
        value={total_pg}
      
        header={<React.Fragment><FiMapPin style={{margin: 5}}/>TOTAL </React.Fragment>}
        className="app-dashboard app-dashboard-card-color-lable green"
        style={{paddingLeft: 10}}/>
    )
  }

  function VehicaleCellContext(_data, i,tags){
    const {data, color, name} = _data;

    
    //console.log("ssdppppppp");
    //console.log(data);
    if(tags==''){
      return(
        data.length > 0 && <VehicleCard
          index={i+1}
          data={data.map(__data => _.merge(__data, {name: name}))}
          name={name}
          primaryColor={color}
          childComponent={VehicaleCell}/>
      )
    }else{
      return(
        data.length > 0 && <VehicleCard
          index={i+1}
          data={data.filter(alldate=>tags.map((n)=>Number(n.id)).includes(alldate.statusId)).map(__data => _.merge(__data, {name: name}))}
          name={name}
          primaryColor={color}
          childComponent={VehicaleCell}/>
      )
    }
   
  }
  const KeyCodes = {
    comma: 188,
    enter: [10, 13],
  };
  const delimiters = [...KeyCodes.enter, KeyCodes.comma];
class DashboardPgLive extends Component {
    constructor(props){
        super(props);
        this.state = {
            
          curTime:"",
          data: [],
          isLight: true,
          depot_list: [],
          SubRegion_list: [],
          full_data_set:[],

        datahhh:[],
        isLight: true,
        isLoading: false,
        isOpen : false,
        filterDate:"",
        filterEndDate:"",
        filterToday:"",
        filterRegion:[],
        filterSubRegion:[],
        filterDepot:[],
        regionName:"",
        DepotName:"",
        headerName:"",

      
        stats: {
          total_pg:0,
          delayed_pg:0,
        },
        pins:[],
        fPin: {}, 
        data: [],
        tags: [],

        suggestions: [
          { id:"8", text: 'Installed', color:'#00ffbf'},
          { id:"14", text: 'DO Verified' },
          { id:"20", text: 'Cancellation Request By Vendor' },
          { id:"6", text: 'Dispatch',color:'#00ffbf' },
          { id:"3", text: 'Initiated' },
          { id:"9", text: 'Installation Confirmed' },
          { id:"17", text: 'DO Request Cancellation' },
          { id:"13", text: 'Removed' },
          { id:"7", text: 'Arrival',color:'#00ffbf' },
          { id:"5", text: 'Team Assign' },
          { id:"21", text: 'DO Approved Cancellation' },
          { id:"16", text: 'Completed' },
          { id:"4", text: 'Approved' },
          { id:"18", text: 'Penalty Exclusion' },
          { id:"22", text: 'OM Rejected' },
          { id:"15", text: 'OM Verified' },
          { id:"11", text: 'Removal Requested By DO' },
          { id:"10", text: 'Removal Requested By Vendor'},
          { id:"12", text: 'Remove Request Confirm By DO'},
          { id:"19", text: 'Completed'},
          { id:"23", text: 'DO Reject Cancellation'},
          { id:"24", text: 'Sync Installation Details'},
          { id:"25", text: 'Sync Remove Details'},
          { id:"26", text: 'PG_SYNC_ARRIVED_DETAILS'},
          { id:"27", text: 'Installation Rejected by DO'},
          { id:"28", text: 'Requested to Reinstall PG'},
          { id:"29", text: 'Requested to Remove Correctly'},
          { id:"30", text: 'Removal Rejected by OM'},
          { id:"31", text: 'Rejected'},
          
       ]
          
        }
        this.handleDelete = this.handleDelete.bind(this);
        this.handleAddition = this.handleAddition.bind(this);
        this.handleDrag = this.handleDrag.bind(this);
      }

      counter({data}){
        let total_pg = 0;
        let delayed_pg = 0;
        let countpg = 0;
        let delayed_count_pg = 0;
        const arrayResult = Object.keys(data).map(room => {
          return {name: room, data: data[room]} 
      });
     
        //console.log(arrayResult);
        arrayResult.map((data)=>{
          countpg=countpg+data.data.length;
         
          data.data.map((data2)=>{
           

            if(data2.isSLABreach==true){
            ++ delayed_count_pg
            }

          
          })
        });
        
        
        
        total_pg = countpg;
        delayed_pg = delayed_count_pg
        //console.log("delayed_pg");
        //console.log(delayed_pg);
    
        return { 
          total_pg,
          delayed_pg
        }
      }

      createDataWithPins({data}){
        //console.log("tyuiioiuytf");
       // const setarray =Object.values(data);
        //console.log();
       // //console.log(Object.values(data).filter(allret=>[14].includes(allret.statusId)));
      // .filter(allret=>this.state.tags.map((n)=>n.id).includes(allret.statusId))  data={data.filter(alldate=>brr.includes(alldate.statusId)).map(__data => _.merge(__data, {name: name}))}
      
        const arrayResult = Object.keys(data).map(room => {
          return {name: room, data: data[room]} 
      });

      //console.log("sdtyty");
      //console.log(arrayResult);
      

     
        // data.data.final_card_data,
        return arrayResult.map(e => ({
          ...e,
          ..._.find(markers, { name: e.name })
        }));
      }
      getPins({data}){
        return data.final_card_data.map(({name}) => _.find(markers, { name }))
      }
      componentDidMount() {

        //console.log(this.props);
        //allret=>"1".includes(allret.isSubRegion)
     
        this.setState({
          depot_list: this.props.depots,
          SubRegion_list: this.props.regions.filter((item)=>{
            return item.isSubRegion==1;
          }),

       
        });
        this.filterBtnClick();

        this.interval = setInterval(() => this.filterBtnClick(), 30000) //30000
       // this.getData();

      }
      componentWillUnmount(){
        clearInterval(this.interval)
      }
      getDataPgByDepots= async (value) => {
        var form = new FormData();
        form.append("depotIds", "2");
        form.append("RequestDate", "2021-12-13");
              
        var res = await getPgByDepots(form);
      
     
         this.setState({
                 stats: this.counter(res),
                  data: this.createDataWithPins(res),
                 // pins: this.getPins(this.state.full_data_set),
                  isLoading: false
                })
       
      };
      filterBtnClick = async() =>{
        var form = new FormData();

        var myCurrentDate = new Date();
        var date = myCurrentDate.getFullYear() + '-' + (myCurrentDate.getMonth()+1) + '-' + myCurrentDate.getDate();
      
      
     //console.log(date)
     if(this.state.filterDate=="" && this.state.filterEndDate==""){
      form.append("depotIds",this.state.filterDepot);
      form.append("RegionIds",this.state.filterRegion);
      form.append("RequestDateTo",date);
      form.append("RequestDateFrom",date);
      this.setState({
        filterDate:date,
        filterEndDate:date
      })

     }else{
      if(this.state.filterDepot.length>0){
        //console.log("filterDepot")
        //console.log(this.state.filterRegion)
        //console.log(this.state.filterDepot)
        
        form.append("depotIds",this.state.filterDepot);
       
        form.append("RequestDateTo",this.state.filterEndDate);
        form.append("RequestDateFrom",this.state.filterDate);
      }else{
        //console.log("filterDepotNo")
        //console.log(this.state.filterRegion)
        //console.log(this.state.filterDepot)
        if(this.state.filterSubRegion==""){
          var getall=this.props.regions.filter(alldate=>this.state.filterRegion.includes(alldate.parentRegion?.id))
          var output = [];
          for (var i=0; i < getall.length ; ++i)
              output.push(getall[i]['id']);

              //sthis.state.filterSubRegion=output;
          
              form.append("RequestDateTo",this.state.filterEndDate);
              form.append("RequestDateFrom",this.state.filterDate);
              form.append("RegionIds",output);
      
        }else{
          form.append("RequestDateTo",this.state.filterEndDate);
          form.append("RequestDateFrom",this.state.filterDate);
          form.append("RegionIds",this.state.filterSubRegion);

        }
       

      }

     }

       
        
        
        

        //console.log(form);
    
        var res = await getPgByDepots(form);  
      

        if(res.success){
        //  //console.log(this.createDataWithPins(res));
     
          this.setState({
         
            curTime : new Date(this.state.filterDate).toDateString(),
            headerName:this.state.regionName || this.state.DepotName?(this.state.regionName+' / '+this.state.DepotName):("All Sites"),
                   stats: this.counter(res),
                   data: this.createDataWithPins(res),
                   //pins: this.getPins(this.state.full_data_set),
                   isLoading: false
                 })
        } 
      }
      onChange = (value) => {
        this.getDataPgByDepots(value)
      
      };
      viewFilterClick = () =>{
        this.setState({
          isOpen : !this.state.isOpen
        })
      }
      onChangeDate = (date, dateString) => {
        //console.log(date);
        //console.log(dateString);
        this.setState({
          filterDate: dateString,
        });
      };
      onChangeEndDate = (date, dateString) => {
        //console.log(date);
        //console.log(dateString);
        this.setState({
          filterEndDate: dateString,
        });
      };
      onChangeSubRegion= (value) => {
        //console.log("onChangeSubRegion");
        //console.log(value);
         const dataa= this.props.regions.filter((item) => {
          return item;
        });
        this.setState({
          filterDepot:[],
        })
    
        this.setState({
          regionName:dataa[value-1]?.name,
         // filterDepot:"",
          DepotName:"",
          filterSubRegion: value,
        
          depot_list: this.props.depots.filter(alldate=>value.map((n)=>n).includes(alldate.region?.id))
         
        });
      };
      onChangeRegion= (value) => {
        //console.log("onChangeRegion");
        //console.log(value);
        this.setState({
          filterSubRegion:[],
          filterDepot:[],

        })
         const dataa= this.props.regions.filter((item) => {
          return item;
        });
        
    
        this.setState({
          regionName:dataa[value-1]?.name,
         // filterDepot:"",
          DepotName:"",
          filterRegion: value,

          SubRegion_list: this.props.regions.filter(alldate=>value.map((n)=>n).includes(alldate.parentRegion?.id))
        
          // SubRegion_list: this.props.regions.filter((item) => {
          //   return item.parentRegion?.id == value.map((item2)=>{return item2});
          // }),
         
        });
      };
      onChangeDepot= (value) => {
        //console.log("onChangeDepot");
        //console.log( );
        const dataa= this.props.depots.filter((item) => {
          return item;
        });
    //    //console.log(dataa[value-1].name);

        this.setState({
         
          DepotName:dataa[value-1]?.name,
          filterDepot: value,
        });
      };
      clearBtnClick = () =>{
        window.location.reload()
      }

      handleDelete(i) {
        const { tags } = this.state;
        this.setState({
         tags: tags.filter((tag, index) => index !== i),
        });
    }

    handleAddition= async(tag)=>{
        
      await this.setState(state => ({ tags: [...state.tags, tag] }));

      //console.log(this.state.tags);
       
    }

    handleDrag(tag, currPos, newPos) {
        const tags = [...this.state.tags];
        const newTags = tags.slice();

        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);

        // re-render
        this.setState({ tags: newTags });
    }

    render() {
      let height = DEFAULT_HEIGHT
        let width = DEFAULT_WIDTH
        const { tags, suggestions } = this.state;
        return (
            <DataContext>
                 {withContext(Loading)}
        {/* {withContext(LoginModal)} */}
               <div className={`App ${this.state.isLight ? 'light': 'dark'}`}>
          <span onClick={() => this.setState({ isLight: !this.state.isLight })} style={{position: 'absolute', bottom: 0, right: 10}}>{
            this.state.isLight 
            ? <FiToggleLeft style={{fontSize: 20}}/>
            : <FiToggleRight style={{fontSize: 20}}/>
          }</span>
          <div className="app-dashboard-sidebar">
             <CardContainer style={{cursor: 'pointer'}}>
                {/* <MapCitation /> */}
                <div  className="svg-map-container" style={{height, width}}>
                    {
                     this.state.pins?.map(props => <Marker key={props.name} {...props}/>)
                    }
                </div>
            </CardContainer>
            <div>
              {/* {withContext(VehicleStatCard)}
              {withContext(LocationStatCard)} */}
              <Card 
                value={this.state.stats.delayed_pg} 
                from={this.state.stats.total_pg} 
                header={<React.Fragment><FiNavigation style={{margin: 5}}/>DELAYED</React.Fragment>}
                className="app-dashboard app-dashboard-card-color-lable red"
                style={{paddingLeft: 10}}/>
              <Card2 
                value={this.state.stats.total_pg} 
               
                header={<React.Fragment><FiMapPin style={{margin: 5}}/>Pg Count</React.Fragment>}
                className="app-dashboard app-dashboard-card-color-lable green"
                style={{paddingLeft: 10}}/>
            </div>
          </div>

          <div className="app-dashboard-body">
            <CardContainer style={{borderRadius: '5px 5px 30px 30px'}}>
              <Flex className="app-dashboard-body-topbar">
                  <Flex width={1} justifyContent="flex-start">{this.state.curTime?( <div className="center-content"><FiCalendar style={{margin: 5}}/>{this.state.curTime}</div>):(<StillClock />)}</Flex>
                  <Flex width={1} justifyContent="center"><div className="departures">{this.state.headerName}</div></Flex>
                  <Flex width={1} justifyContent="flex-end"><TickClock /></Flex>
                  <div class="col-md-1">
										 <button type="button" style={{marginLeft:32}} class="btn btn-danger" onClick={()=> this.viewFilterClick()}><i class="fas fa-filter"></i></button>
                  </div> 
              </Flex>
            </CardContainer>
            <div className="app-dashboard-body-display">

            <Collapse isOpen={this.state.isOpen}>
                <div className="row" style={{paddingLeft:20,paddingRight:20}}>
                <div class="card">
                                
                                <div class="card-body bootstrap-select-1">
                                    <div class="row">
                                    <div class="col-md-2">
                                            <label class="mb-3">Start Date</label>
                                            <div className="row">
                                              <div className="col-md-12">
                                                <DatePicker
                                                  placeholder="Start Date"
                                                  style={{ width: "100%" }}
                                                  onChange={this.onChangeDate}
                                                />
                                              </div>
                                              </div>
										
                                         </div>  
                                         <div class="col-md-2">
                                            <label class="mb-3"> End Date</label>
                                            <div className="row">
                                              <div className="col-md-12">
                                                <DatePicker
                                                  placeholder="Start Date"
                                                  style={{ width: "100%" }}
                                                  onChange={this.onChangeEndDate}
                                                />
                                              </div>
                                              </div>
										
                                         </div>  
                                        <div class="col-md-3">
                                            <label class="mb-3">Region</label>
                                            
                                            <Select
                                                mode="multiple"
                                                showSearch
                                                onChange={this.onChangeRegion} 
                                                name="regionId"
                                                value={this.state.filterRegion}

                                                style={{ width: "100%" }}
                                                placeholder="Select Regions"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                }
                                              >
                                                
                                                {this.props.regions.filter(allret=>"0".includes(allret.isSubRegion)).map((item, index) => {
                                                                return <Option value={item.id}>{item.name}</Option>;
                                                            })}
                                            </Select>
                                        </div> 
                                        <div class="col-md-3">
                                            <label class="mb-3">Sub Region</label>
                                            
                                            <Select
                                                mode="multiple"
                                                showSearch
                                                onChange={this.onChangeSubRegion} 
                                                name="sub_regionId"
                                                value={this.state.filterSubRegion}

                                                style={{ width: "100%" }}
                                                placeholder="Select Sub Regions"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                }
                                              >
                                              
                                                {this.state.SubRegion_list.map((item, index) => {
                                                                return <Option value={item.id}>{item.name}</Option>;
                                                            })}
                                            </Select>
                                        </div>   
                                        <div class="col-md-2">
                                            <label class="mb-3">Depot</label>
                                            <Select
                                                mode="multiple"
                                                showSearch
                                                onChange={this.onChangeDepot} 
                                                value={this.state.filterDepot}
                                                name="depotsId"
                                                style={{ width: "100%" }}
                                                placeholder="Select Depot"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                }
                                              >
                                              
                                                {this.state.depot_list.map((item, index) => {
                                                                return <Option value={item.id}>{item.name}</Option>;
                                                            })}
                                            </Select>
                                        </div>                               
                                      
										                
										                                            
                                    </div>
                                      <div class="row">
                                          <div class="col-md-2" style={{marginTop:10}}> 
                                            <button type="button" class="btn btn-primary" onClick={()=> this.filterBtnClick()}> Filter</button>
                                            <button type="button" style={{marginLeft:5}} class="btn btn-dark" onClick={()=> this.clearBtnClick()}>Clear Filter</button>
                                              </div>  
                                              <div class="col-md-10" style={{marginTop:10}}>
                                                    <div>
                                                        {/* <ReactTags tags={tags}
                                                            suggestions={suggestions}
                                                            handleDelete={this.handleDelete}
                                                            handleAddition={this.handleAddition}
                                                            handleDrag={this.handleDrag}
                                                            placeholder="Add Status.."
                                                            delimiters={delimiters} /> */}
                                                    </div>
                                              </div>
                                      </div>
                                </div>  
                            </div>
			</div>
			</Collapse>

              {/* <Main /> */}
                <Flex>
                  {/* LEFT SIDE */}
                  <Box width={1}>
                    <LocationContainer>
                    {this.state.data.map((_data, i) => i%2==0 && VehicaleCellContext(_data, i,this.state.tags))}
                    </LocationContainer>
                  </Box>
                  {/* RIGHT SIDE */}
                  <Box width={1}>
                    <LocationContainer>
                    {this.state.data.map((_data, i) => i%2==1 && VehicaleCellContext(_data, i,this.state.tags))}
                  
                    </LocationContainer>
                  </Box>
                </Flex>
            </div>
          </div>
        </div>  

              </DataContext>
        );
    }
}
function mapStateToProps(state) {
    //console.log("state", state);
    return {
      organization_count: state.organization.length,
      regions: state.regions,
      depots: state.depots,
      sites_count: state.sites.length,
      vendor_count: state.vendor.length,
      roles_count: state.roles.length,
      pins:state.pins
    };
  }
  
export default connect(mapStateToProps, { getDonationCategories, getData })(DashboardPgLive);
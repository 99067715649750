import React, { Component } from 'react';
import { connect } from "react-redux";
import { Column } from '@ant-design/plots';
import { Select, DatePicker, Space ,message} from "antd";
import { getDonationCategories, getData } from "../actions/index";
import {
  GetCostByDepots
} from "../api/index";
import moment from 'moment';

const { RangePicker } = DatePicker;

class DashboardPgCostDepot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pending_pg: [],
      regoin_cost: [],
      pg_count: '',
      monthly_status: false,
      daily_status: false,
      weekly_status: false,
      get_cost_by_parent_region_loading: false,
      daily_regoin_cost: [],
      weekly_regoin_cost: [],
      monthly_regoin_cost: [],
      all_cost_data:[],
      select_filer_type: '',
      filter_date_to:'',
      selected_region_id:1,
      filter_date:'',

    };
  }
  createDataWithPins(data) {
    const arrayResult = Object.keys(data.daily).map(room => {
      return {
        data: data.daily[room].objects.map(e => ({
          ...e, time: data.daily[room].time
        }))
      }
    });
    var alldata = arrayResult.reduce((perv, cur) => [...perv, ...cur.data], []).sort((a, b) => (a > b) ? 1 : -1)
    //console.log("alldata");
    //console.log(alldata);
    this.setState({
      daily_regoin_cost: alldata,
      regoin_cost: alldata
    })

  }

  createDataWithPinsWeeklyCost(data) {
    if(!data.weekly.length==0){
      const arrayResult = Object.keys(data.weekly).map(room => {
        return {
          data: data.weekly[room].objects.map(e => ({
            ...e, time: data.weekly[room].time
          }))
        }
      });
      var alldata = arrayResult.reduce((perv, cur) => [...perv, ...cur.data], []).sort((a, b) => (a > b) ? 1 : -1)
      //console.log("alldataweekly");
      //console.log(alldata);
      this.setState({
        weekly_regoin_cost: alldata,
        regoin_cost: alldata
  
      })
    }

   

  }

  createDataWithPinsMonthlyCost(data) {

    if(!data.monthly.length==0){
    const arrayResult = Object.keys(data.monthly).map(room => {
      return {
        data: data.monthly[room].objects.map(e => ({
          ...e, time: data.monthly[room].time
        }))
      }
    });
    var alldata = arrayResult.reduce((perv, cur) => [...perv, ...cur.data], []).sort((a, b) => (a > b) ? 1 : -1)
    //console.log("alldatamonthly");
    //console.log(alldata);
    this.setState({
      monthly_regoin_cost: alldata,
      regoin_cost: alldata
      
    })
  }
  }

  componentDidMount() {

    var form = new FormData();

    form.append("depotId", '');
    form.append("regionId", '');
    form.append("parentRegionId", '1');
    form.append("RequestDateFrom",'');
    form.append("RequestDateTO", '');

    this.getGetCostByDepots(form);
  }

  getGetCostByDepots = async (data2) => {

    this.setState({
      get_cost_by_parent_region_loading: true,
      regoin_cost:[]
      
    });
    var res = await GetCostByDepots(data2);
    //console.log("GetCostByDepots");
    //console.log(res);

    if (res.success) {
      if(!res.data.daily.length==0){
      this.createDataWithPins(res?.data)
      this.createDataWithPinsWeeklyCost(res?.data)
      this.createDataWithPinsMonthlyCost(res?.data)
      this.setState({
        daily_status: true,
        weekly_status: false,
        monthly_status: false,
        get_cost_by_parent_region_loading: false,
        all_cost_data:res?.data,




      });

    }
    if(!res.data.weekly.length==0){
     // this.createDataWithPins(res?.data)
      this.createDataWithPinsWeeklyCost(res?.data)
      this.createDataWithPinsMonthlyCost(res?.data)
      this.setState({
        daily_status: false,
        weekly_status: true,
        monthly_status: false,
        get_cost_by_parent_region_loading: false,
        all_cost_data:res?.data,




      });

    }
    if(!res.data.monthly.length==0){
      // this.createDataWithPins(res?.data)
     //  this.createDataWithPinsWeeklyCost(res?.data)
       this.createDataWithPinsMonthlyCost(res?.data)
       this.setState({
         daily_status: false,
         weekly_status: false,
         monthly_status: true,
         get_cost_by_parent_region_loading: false,
         all_cost_data:res?.data,
 
 
 
 
       });
 
     }
    }else{
      message.warning(res?.data?.message)


    }
  };
  dataonChange = (date, dateString) => {
    //  //console.log(date);
    //console.log(dateString);
    this.setState({
      filter_date: dateString[0],
      filter_date_to: dateString[1],
    });
    var form = new FormData();

    form.append("depotId", '');
    form.append("regionId", '');
    form.append("parentRegionId",this.state.selected_region_id);
    form.append("RequestDateFrom", dateString[0]);
    form.append("RequestDateTo", dateString[1]);


    this.getGetCostByDepots(form);;



  };
  onChange = (value) => {
    this.setState({
      depot_list: [],
      filter_depot: [],
      filter_sub_region: [],

    });
    //console.log(`selected rr${value}`);
    this.setState({
      filter_region: value,
      selectedDepot: "all_depots",
      selected_region_id: value,

    });
  
    //this.getPgCountData(data);
    //this.getPgStatusData(data);
    //this.getPgCostData(data);
    //this.getPgSLAData(data);
   
  
      var form = new FormData();
      //console.log("sdsrrrrrr")
     
      form.append("depotId",'');
      form.append("regionId",'')
      form.append("parentRegionId", value);
      form.append("RequestDateFrom", this.state.filter_date);
      form.append("RequestDateTo", this.state.filter_date_to);

      this.getGetCostByDepots(form);
     





    


  };
  
  handleClickDaily = () => {
    //console.log(this.state);
    if(!this.state.all_cost_data.daily.length==0){
    this.setState({
      regoin_cost: this.state?.daily_regoin_cost,
      daily_status: true,
      weekly_status: false,
      monthly_status: false,
      select_filer_type: 'Last 7 Days'
    });
  }
  }
  handleClickWeekly = () => {
    if(!this.state.all_cost_data.weekly.length==0){
      //console.log("weekly");
      //console.log("weekly");
    this.setState({
      regoin_cost: this.state?.weekly_regoin_cost,
      daily_status: false,
      weekly_status: true,
      monthly_status: false,
      select_filer_type: 'Last 7 weeks'

    });
  }
  }
  handleClickMonthly = () => {
    if(!this.state.all_cost_data.monthly.length==0){
    this.setState({
      regoin_cost: this.state?.monthly_regoin_cost,
      monthly_status: true,
      daily_status: false,
      weekly_status: false,
      select_filer_type: 'Last 7 Month'
    });
  }
  }
  render() {

    const { Option } = Select;
    function onBlur() {
      //console.log("blur");
    }

    function onFocus() {
      //console.log("focus");
    }

    function onSearch(val) {
      //console.log("search:", val);
    }
    const configRegoinCost = {
      data: this.state.regoin_cost,
      isStack: true,
      xField: 'time',
      yField: 'cost',
      seriesField: 'type',
      label: {
        // 可手动配置 label 数据标签位置
        position: 'middle',
        // 'top', 'bottom', 'middle'
        // 可配置附加的布局方法
        layout: [
          // 柱形图数据标签位置自动调整
          {
            type: 'interval-adjust-position',
          }, // 数据标签防遮挡
          {
            type: 'interval-hide-overlap',
          }, // 数据标签文颜色自动调整
          {
            type: 'adjust-color',
          },
        ],
      },
    };
    return (
      <div class="container-fluid">
        <div class="row">
              <div class="col-sm-12">
                <div class="page-title-box">
                  <div class="row">
                    <div class="col">
                      <h4 class="page-title">PG Cost Of Depot</h4>
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Dashboard</a></li>
                        <li class="breadcrumb-item"><a href="javascript:void(0);">PG</a></li>

                      </ol>
                    </div>
                    <div class="col-auto align-self-center">
                      <a href="#" class="btn btn-sm btn-outline-primary" id="Dash_Date">
                        <span class="day-name" id="Day_Name">Today:</span>&nbsp;
                        <span class="" id="Select_date">{new Date().toUTCString().slice(5, 16)}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar align-self-center icon-xs ms-1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                      </a>

                    </div>
                  </div>
                </div>
              </div>
            </div>
        <div class="row">
        <div class="col-md-2" style={{ alignItems: "center", display: "flex" }}>

<li class="list-inline-item">
  {this.state.daily_status ? (<button type="button" onClick={this.handleClickDaily} class="btn btn-soft-primary btn-sm" style={{ marginRight: 5, background: "#1761fd", color: "white" }}>Daily</button>) : (<button type="button" onClick={this.handleClickDaily} class="btn btn-soft-primary btn-sm" style={{ marginRight: 5 }}>Daily</button>)}
  {this.state.weekly_status ? (<button type="button" onClick={this.handleClickWeekly} class="btn btn-soft-primary btn-sm" style={{ marginRight: 5, background: "#1761fd", color: "white" }}>Weekly</button>) : (<button type="button" onClick={this.handleClickWeekly} class="btn btn-soft-primary btn-sm" style={{ marginRight: 5 }}>Weekly</button>)}
  {this.state.monthly_status ? (<button type="button" onClick={this.handleClickMonthly} class="btn btn-soft-primary btn-sm" style={{ background: "#1761fd", color: "white",padding: "revert" }} >Monthly</button>) : (<button type="button" onClick={this.handleClickMonthly} class="btn btn-soft-primary btn-sm" style={{padding: "revert" }}>Monthly</button>)}
</li>


</div>
          <div className="col-md-2 col-lg-3">

            <RangePicker

              style={{ width: "100%" }}
              defaultValue={[moment(moment().format("YYYY-MM-DD").slice(0, -2) + '01'), moment()]}
              onChange={this.dataonChange}
            />
          </div>
          <div class="col-md-3">

            <Select
              // mode="multiple"
              showSearch
              style={{ width: "100%" }}
               value={this.state.selected_region_id}
              placeholder="Select a region"
              optionFilterProp="children"
              onChange={this.onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              onSearch={onSearch}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >

              {this.props.regions.filter(allret => "0".includes(allret.isSubRegion)).map((item, index) => {
                return <Option value={item.id}>{item.name}</Option>;
              })}
            </Select>


          </div>

          {/* <div className="col-md-12 col-lg-6">
        
          </div> */}



        </div>
        <br></br>
          <div class="row">
            <div className="col-md-12 col-lg-12">
              <div className="card">
               
                <div className="card-body">
                  <Column {...configRegoinCost}
                    loading={this.state.get_cost_by_parent_region_loading}
                  />
                </div>
              </div>
            </div>
          </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  //console.log("state", state);
  return {
    organization_count: state.organization.length,
    regions: state.regions,
    depots: state.depots,
    sites_count: state.sites.length,
    vendor_count: state.vendor.length,
    roles_count: state.roles.length,
  };
}

export default connect(mapStateToProps, { getDonationCategories, getData })(
  DashboardPgCostDepot
);
import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import { connect } from "react-redux";
import { createNewRegion } from "../../app/api/index";
import { ToastContainer, toast } from "material-react-toastify";
import { getAllDepotUsers, getUsers, assignToDepot } from "../api/index";
import { Table, Input, Tag, Space, Select } from "antd";

import users from "./users";

// const children = [];
const { Option } = Select;
// for (let i = 10; i < 36; i++) {
//   children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
// }
class DepotUsers extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      filterTable: null,
      baseData: "",
      all_users: "",
      // children: [],
      selected_users: [],
      selected_depot: [],
    };
  }
  organizationList() {
    //console.log("kk");
  }
  componentDidMount() {
    this.getUsers();
    this.getUsersAll();
  }

  getUsers = async () => {
    var res = await getAllDepotUsers();
    //console.log(res);
    if (res.success) {
      this.setState({
        baseData: res.data,
      });
    }
  };
  getUsersAll = async () => {
    var res = await getUsers();
    //console.log(res);
    if (res.success) {
      this.setState({
        all_users: res.data,
      });
    }
  };
  formItemChange = (event) => {
    var obj = {};
    obj[event.target.name] = event.target.value;
    this.setState(obj);
    //console.log(obj);
  };

  formItemChecked = (event) => {
    var obj = {};
    obj[event.target.name] = event.target.checked;
    this.setState(obj);
    //console.log(obj);
  };
  search = (value) => {
    const { baseData } = this.state;
    //console.log("PASS", { value });

    const filterTable = baseData.filter(
      (o) =>
        // Object.keys(o).some((k) =>
        o.user.email.includes(value.toLowerCase())
      // )
    );

    this.setState({ filterTable });
  };
  filterRegions = (value, region) => {
    var rr = region.filter((d) => d.name.includes(value));
    //console.log(rr);
    return rr.length > 0;
  };
  handleChange = (e, value) => {
    // //console.log(value);
    // //console.log(`selected ${value.toString()}`);
    this.setState({
      selected_users: value,
    });
  };
  handleChange2 = (e, value) => {
    // //console.log(`selected ${value}`);
    this.setState({
      selected_depot: value,
    });
  };
  assignToDepot = async () => {
    if (this.validator.allValid()) {
      var formdata = new FormData();

      formdata.append(
        "deport",
        '{"id":"' + this.state.selected_depot.value + '"}'
      );
      var users = this.state.selected_users.map((item) => {
        return { id: item.value };
      });

      formdata.append("users", JSON.stringify(users));

      //console.log(formdata);
      var res = await assignToDepot(formdata);

      if (res.success) {
        //  this.getUsers();
        toast.success("Done");
        // this.setState({
        //   selected_users: [],
        //   selected_depot: [],
        // });
        window.location.reload()
        // this.handleChange('',[])
        // this.handleChange2('',[])
      }
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  };

  render() {
    const { filterTable, baseData, all_users } = this.state;

    const columns = [
      {
        title: "#",
        dataIndex: "id",

        defaultSortOrder: "ascend",
        sorter: (a, b) => a.id - b.id,
        sortDirections: ["descend"],
        render: (text, record, index) => <span>{text}</span>,
      },
      {
        title: "Email",
        dataIndex: "user",
        key: "user",
        //  sorter: (a, b) => a.name.localeCompare(b.name),
        render: (users) => <span>{users.email.toLowerCase()}</span>,
      },

      {
        title: "Depot",
        key: "depot",
        dataIndex: "depot",
        filters: this.props.depots.map((item) => ({
          text: item.name.toUpperCase(),
          value: item.name,
        })),

        onFilter: (value, record) => record.depot.name.indexOf(value) === 0,

        render: (depot) => (
          <>
            <Tag style={{ fontSize: 10 }} color="volcano" key={depot}>
              {depot.name.toUpperCase()}
            </Tag>
          </>
        ),
      },

      // {
      //   title: "Action",
      //   key: "action",
      //   render: (text, record) => (
      //     <Space size="middle">
      //       <a>View</a>
      //     </Space>
      //   ),
      // },
    ];

    const { Option } = Select;
    return (
      <div class="container-fluid">
        <div class="row">
              <div class="col-sm-12">
                <div class="page-title-box">
                  <div class="row">
                    <div class="col">
                      <h4 class="page-title">Depot Users</h4>
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Admin</a></li>
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Depot Users</a></li>

                      </ol>
                    </div>
                    <div class="col-auto align-self-center">
                      <a href="#" class="btn btn-sm btn-outline-primary" id="Dash_Date">
                        <span class="day-name" id="Day_Name">Today:</span>&nbsp;
                        <span class="" id="Select_date">{new Date().toUTCString().slice(5, 16)}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar align-self-center icon-xs ms-1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                      </a>

                    </div>
                  </div>
                </div>
              </div>
            </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Add Depot Users</h4>
              </div>
              <div className="card-body">
                <div className="mb-3">
                  <label className="form-label" for="users">
                    Users
                  </label>
                  <Select
                    mode="multiple"
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="Please select users"
                    defaultValue={[]}
                    onChange={(e, value) => {
                      this.handleChange(e, value);
                    }}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {this.state.all_users &&
                      this.state.all_users.map((item) => (
                        <Option key={item.id} value={item.id}>
                          {item.email}
                        </Option>
                      ))}
                  </Select>
                  <p style={{ color: "#f5325c" }}>
                    {this.validator.message(
                      "users",
                      this.state.selected_users,
                      "required"
                    )}
                  </p>
                </div>
                <div className="mb-3">
                  <label className="form-label" for="depot">
                    Depots
                  </label>
                  <Select
                    // mode="multiple"
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="Please select depot"
                    defaultValue={[]}
                    // value={this.state.selected_depot}
                    // onChange={this.handleChange2}
                    onChange={(e, value) => {
                      this.handleChange2(e, value);
                    }}
                  >
                    {this.props.depots &&
                      this.props.depots.map((item) => (
                        <Option key={item.id}>{item.name}</Option>
                      ))}
                  </Select>
                  <p style={{ color: "#f5325c" }}>
                    {this.validator.message(
                      "depot",
                      this.state.selected_depot,
                      "required"
                    )}
                  </p>
                </div>
                <button
                  className="btn btn-primary"
                  onClick={() => this.assignToDepot()}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Depot Users</h4>
              </div>
              <div className="card-body">
                <Input
                  enterButton
                  style={{ marginBottom: 5 }}
                  placeholder="Search ..."
                  onChange={(e) => {
                    const currValue = e.target.value;
                    this.search(currValue);
                  }}
                />

                <Table
                  size="small"
                  columns={columns}
                  dataSource={filterTable == null ? baseData : filterTable}
                />
              </div>
            </div>
          </div>
        </div>
        <ToastContainer position="top-right" autoClose={5000} />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    // organization: state.organization,
    // regions: state.regions,
    // roles: state.roles,
    depots: state.depots,
  };
}
export default connect(mapStateToProps)(DepotUsers);

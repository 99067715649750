import React, { Component } from "react";
import { connect } from "react-redux";
import moment from 'moment';
import { transitions, positions, useAlert, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import { getDonationListData } from "../actions/index";
import { getDonationCategories, getData } from "../actions/index";
import SemiCircleGaugeChat from '../components/charts/semi-circle-gauge'
import {
  getPgData,
  getPgCountData,
  getPgStatusData,
  getPgCostData,
  getPgSLAData,
  StatusTypesCount, DayWeekMonthFilter, GetCostByDate, PgSLABreachPercentage, GetCostByParentRegion, PgFullCountByTime, PgChartRequest, getMonthlyRegionViceCost, getDashboardChartDatas
} from "../api/index";
import { Select, DatePicker, Space, Row, Col, Spin, Empty } from "antd";
import { Line, Pie, Column } from '@ant-design/plots';
import CurrencyFormat from 'react-currency-format';
import SpeedMeterChat from '../components/charts/speed-meter'
import StackedBarChart from '../components/charts/stacked-bar'
import StackedBarLineChart from '../components/charts/stacked-bar-line'
import ColumnChartsDataLablesChart from '../components/charts/column-charts-data-lables'
import LineCharts from '../components/charts/line-charts';
import BarChartChartjs from '../components/charts/bar-chart-chartjs'
import Pg from "../components/pg/pg";
import { PullRequestOutlined, SettingOutlined, DeleteOutlined, CloseOutlined, DollarOutlined, RiseOutlined } from "@ant-design/icons";
import { Badge } from 'antd';
import './basic.css';
import LineChart from "./chart/base-line";
import LineChartSla from "./chart/base-line-sla";
import ReactLoading from 'react-loading';

import StackedBar from "./chart/StackedBar";
import GroupedBar from "./chart/GroupedBar";
import {
  RightOutlined,
  DownOutlined,
  BorderOuterOutlined,
  TeamOutlined,
  AppstoreOutlined,
  DeploymentUnitOutlined,
  ClusterOutlined,
} from "@ant-design/icons";
import DoughnutChart from "./chart/Doughnut";
import { globaAbortController } from "../api/api.client";

//import { Line } from "react-chartjs-2";


const { RangePicker } = DatePicker;
var noData = (
  <svg
    class="ant-empty-img-simple"
    width="64"
    height="41"
    viewBox="0 0 64 41"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(0 1)" fill="none" fill-rule="evenodd">
      <ellipse
        class="ant-empty-img-simple-ellipse"
        cx="32"
        cy="33"
        rx="32"
        ry="7"
      ></ellipse>
      <g class="ant-empty-img-simple-g" fill-rule="nonzero">
        <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
        <path
          d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
          class="ant-empty-img-simple-path"
        ></path>
      </g>
    </g>
  </svg>
);
class DashboardPg extends Component {
  constructor(props) {
    super(props);
    const currentDate_landing = new Date();
    this.state = {
      requested_count: 0,
      installed_count: 0,
      removal_requested_count: 0,
      removed_count: 0,
      cancelled_count: 0,
      cancelled_count_payable: 0,
      pg_by_count_chart_data: [],
      day_week_month_filter_data_set: [],
      day_week_month_filter_data_set_weekly: [],
      day_week_month_filter_data_set_monthly: [],
      daily_status: false,
      daily_status_chart_req: false,
      monthly_status: false,
      monthly_status_chart_req: false,
      weekly_status: false,
      weekly_status_chart_req: false,
      pg_cost_count: 18756,
      pg_cost_daily_weekly_monthly_count: "",
      sla_status_daily_weekly_monthly: "",
      sla_status: "",
      pg_count_daily_weekly_monthly: "",
      pg_count: "",
      pgData: "",
      pgPendingCount: 0,
      filterTable: null,
      isPgSelected: false,
      selectedPgData: null,
      chartLabel: [],
      chartData: [],
      chart_data_2: [],
      chart_data_3: [],
      chart_data_4: [],
      selected_region_id: null,
      depot_list: [],
      filter_date: currentDate_landing.getFullYear(),
      //filter_date_to: moment().format("YYYY-MM-DD"),
      // filter date to end of month
      filter_date_to: currentDate_landing.getMonth() + 1,
      filter_depot: [],
      filter_region: [],
      filter_sub_region: [],
      SubRegion_list: [],
      selectedDepot: "all_depots",
      loading: false,
      pg_by_count_loading: false,
      sla_status_loading: false,
      pg_cost_loading: false,
      pg_chart_req_loading: false,
      pg_cost_type: false,
      pg_count_loading: false,
      daily_regoin_cost: [],
      weekly_regoin_cost: [],
      monthly_regoin_cost: [],
      regoin_cost: [],
      select_filer_type: '',
      fist_of_this_month: '',
      all_chart_req_data: [],
      daily_chart_req_data: [],
      setY1: null,
      setX1: null,
      installationStatusChart: false,
      regionBreakdownChart: false,
      regionBreakdownChartSetY1: null,
      regionBreakdownChartSetX1: null,
      runningHoursBandChart: false,
      runningHoursBandChartSetY1: null,
      runningHoursBandChartSetX1: null,
      runningHoursRegionallyChart: false,
      runningHoursRegionallyChartSetY1: null,
      runningHoursRegionallyChartSetX1: null,
      runningCostChart: false,
      runningCostChartSetY1: null,
      runningCostChartSetX1: null,

      pgCostWRChartValue: null,
      pgCostWRChart: false,
      pgCostWRChartTarget: null,
      pgCostWRChartRealValue: null,
      pgCostCRChartValue: null,
      pgCostCRChart: false,
      pgCostCRChartTarget: null,
      pgCostCRChartRealValue: null,

      pgCostERChartValue: null,
      pgCostERChart: false,
      pgCostERChartTarget: null,
      pgCostERChartRealValue: null,

      pgCostSRChartValue: null,
      pgCostSRChart: false,
      pgCostSRChartTarget: null,
      pgCostSRChartRealValue: null,

      pgCostNRChartValue: null,
      pgCostNRChart: false,
      pgCostNRChartTarget: null,
      pgCostNRChartRealValue: null,

      regionalBreakdownChartData: null,
      installationStatusChartData: null,
      requestTrendChartData: null,
      runningCostChartData: null,
      runningHoursRegionalChartData: null,

      requesteTrendChart: false,
      requesteTrendChartSetY1: null,
      requesteTrendChartSetX1: null,
    };
  }

  createDataWithPins(data) {
    const arrayResult = Object.keys(data.daily).map(room => {
      return {
        data: data.daily[room].objects.map(e => ({
          ...e, time: data.daily[room].time
        }))
      }
    });
    var alldata = arrayResult.reduce((perv, cur) => [...perv, ...cur.data], [])
    //console.log("alldata");
    //console.log(alldata);
    this.setState({
      daily_regoin_cost: alldata,
      regoin_cost: alldata
    })

  }

  createDataWithPinsWeeklyCost(data) {
    const arrayResult = Object.keys(data.weekly).map(room => {
      return {
        data: data.weekly[room].objects.map(e => ({
          ...e, time: data.weekly[room].time
        }))
      }
    });
    var alldata = arrayResult.reduce((perv, cur) => [...perv, ...cur.data], [])
    //console.log("alldataweekly");
    //console.log(alldata);
    this.setState({
      weekly_regoin_cost: alldata
    })

  }

  createDataWithPinsMonthlyCost(data) {
    const arrayResult = Object.keys(data.monthly).map(room => {
      return {
        data: data.monthly[room].objects.map(e => ({
          ...e, time: data.monthly[room].time
        }))
      }
    });
    var alldata = arrayResult.reduce((perv, cur) => [...perv, ...cur.data], [])
    this.setState({
      monthly_regoin_cost: alldata
    })

  }

  componentDidMount() {

    // console.log(y1);
    this.props.getData();
    var Tody = moment().format("YYYY-MM-DD");
    //console.log("Tody");
    //console.log(Tody.slice(0, -2) + '01');
    this.setState({
      fist_of_this_month: Tody.slice(0, -2) + '01'
    })
    //console.log(this.state.fist_of_this_month);

    var data = {
      // "id":2,
      type: "all",
    };
    var data2 = {
      depotId: '',
      regionId: '',
      parentRegionId: '',
      RequestDateFrom: '',
      RequestDateTO: '',
    };
    var form = new FormData();

    form.append("depotId", '');
    form.append("regionId", '');
    form.append("parentRegionId", '');
    form.append("year", this.state.filter_date);
    form.append("month", this.state.filter_date_to);
    form.append("RequestDateTO", '');

    this.getPgCountData(data);
    this.getPgStatusData(data);
    this.getPgCostData(data);
    this.getPgSLAData(data);
    this.getStatusTypesCount(form);
    this.getdayWeekMonthFilter(form);
    this.getPgSLABreachPercentage(form);
    this.getPgChartRequest(form);
    //this.getGetCostByParentRegion(form);
    this.getDashboardChartData(form)
    this.getGetCostByDate(form);
    this.getPgFullCountByTime(form);
    this.getDataMonthlyRegionViceCost(form)
    this.setState({
      depot_list: this.props.depots,
      SubRegion_list: this.props.regions.filter((item) => {
        return item.isSubRegion == 1;
      }),
    });

    // if(localStorage.getItem("userId")==null){
    //   this.interval = setInterval(() => window.location.reload) //30000

    // }

  }
  setRegionalBreakdownChartDataSet(data) {
    if (data != null) {
      this.setState({
        regionBreakdownChart: false
      })

      let canceledSet = data.map(a => a.canceled);
      let installedSet = data.map(a => a.installed);
      let requestedSet = data.map(a => a.requested);
      let removedSet = data.map(a => a.removed);
      let regionSet = data.map(a => a.region);

      this.setState({
        regionBreakdownChartSetY1: [{
          name: 'Cancelled',
          type: 'column',
          data: canceledSet
        },
        {
          name: 'Removed',
          type: 'column',
          data: removedSet
        },
        {
          name: 'Installed',
          type: 'column',
          data: installedSet
        },
        {
          name: 'Requested',
          type: 'column',
          data: requestedSet
        },
        ],
        regionBreakdownChartSetX1: regionSet
      }, () => {
        this.setState({
          regionBreakdownChart: true
        })
      })

    }
  }

  getLastDayOfMonth(inputDate) {
    const [year, month] = inputDate.split('-');
    const nextMonth = new Date(year, month);
    nextMonth.setDate(0);
    const lastDay = nextMonth.getDate();
    return `${year}-${month}-${lastDay.toString().padStart(2, '0')}`;
  }

  setInstallationStatusChartDataSet(data) {
    if (data != null) {
      this.setState({
        installationStatusChart: false
      })
      let fff = data.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
      let set_year = this.state.filter_date;
      let set_month = this.state.filter_date_to;
      let set_data = set_year + '-' + set_month
      const inputDate = set_data;
      const lastDayOfMonth = this.getLastDayOfMonth(inputDate);
      const today = new Date(lastDayOfMonth);
      const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
      const formattedFirstDay = firstDayOfMonth.toISOString().split('T')[0];

      fff = fff.filter(data => {
        return moment(data.date).isBetween(formattedFirstDay, today, null, '[]')
      });

      //   {
      //     "installed": 0,
      //     "requested": 3,
      //     "canceled": 7,
      //     "removed": 8,
      //     "date": "2024-04-01"
      // },

      let canceledSet = fff?.map(a => a.canceled);
      let requestedSet = fff?.map(a => a.requested);
      let dateSet = fff?.map(a => a.date);
      let installedSet = fff?.map(a => a.installed);
      let removedSet = fff?.map(a => a.removed);

      this.setState({
        setY1: [{
          name: 'Cancelled',
          type: 'column',
          data: canceledSet
        }, {
          name: 'Removed',
          type: 'column',
          data: removedSet
        }, {
          name: 'Installed',
          type: 'column',
          data: installedSet
        },
        {
          name: 'Requested',
          type: 'column',
          data: requestedSet
        }
        ],
        setX1: dateSet
      }, () => {
        this.setState({
          installationStatusChart: true
        })
      })

    }
  }


  setRequestTrendChartDataSet(data) {
    if (data != null) {
      this.setState({
        requesteTrendChart: false
      })

      let fff = data.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
      let set_year = this.state.filter_date;
      let set_month = this.state.filter_date_to;
      let set_data = set_year + '-' + set_month
      const inputDate = set_data;
      const lastDayOfMonth = this.getLastDayOfMonth(inputDate);
      const today = new Date(lastDayOfMonth);
      const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
      const formattedFirstDay = firstDayOfMonth.toISOString().split('T')[0];

      fff = fff.filter(data => {
        return moment(data.date).isBetween(formattedFirstDay, today, null, '[]')
      });

      let countSet = fff.map(a => a.count);
      let dateSet = fff.map(a => a.date.split("-")[2]);
      this.setState({
        requesteTrendChartSetY1: [{
          name: "Count",
          data: countSet
        }],
        requesteTrendChartSetX1: dateSet,
      }, () => {
        this.setState({
          requesteTrendChart: true
        })
      })
    }
  }

  checkRegion(filtered_0_3HR, typeName) {
    let setfilterRegionNR = filtered_0_3HR.filter(data3 => {
      return data3.perent_region === 'Northern (NR)';
    })

    if (setfilterRegionNR.length == 0) {
      let newoneNR = {
        "hour_band": typeName,
        "perent_region": "Northern(NR)",
        "count": 0
      }
      filtered_0_3HR.push(newoneNR);
    }

    let setfilterRegionSR = filtered_0_3HR.filter(data3 => {
      return data3.perent_region === 'Southern (SR)';
    })

    if (setfilterRegionSR.length == 0) {
      let newoneSR = {
        "hour_band": typeName,
        "perent_region": "Southern(SR)",
        "count": 0
      }
      filtered_0_3HR.push(newoneSR);
    }

    let setfilterRegionCR = filtered_0_3HR.filter(data3 => {
      return data3.perent_region === 'Central (CR)';
    })

    if (setfilterRegionCR.length == 0) {
      let newoneCR = {
        "hour_band": typeName,
        "perent_region": "Central(CR)",
        "count": 0
      }
      filtered_0_3HR.push(newoneCR);
    }

    let setfilterRegionWR = filtered_0_3HR.filter(data3 => {
      return data3.perent_region === 'Western (WR)';
    })

    if (setfilterRegionWR.length == 0) {
      let newoneWR = {
        "hour_band": typeName,
        "perent_region": "Western(WR)",
        "count": 0
      }
      filtered_0_3HR.push(newoneWR);
    }

    let setfilterRegionER = filtered_0_3HR.filter(data3 => {

      return data3.perent_region === 'Eastern (ER)';
    })

    if (setfilterRegionER.length == 0) {
      let newoneER = {
        "hour_band": typeName,
        "perent_region": "Eastern(ER)",
        "count": 0
      }
      filtered_0_3HR.push(newoneER);

    }

    return filtered_0_3HR

  }
  setFullObjectWithRenge(filtered_0_3HR, typeName) {
    filtered_0_3HR = this.checkRegion(filtered_0_3HR, typeName)

    const result_0_3HR = filtered_0_3HR.map(item => ({
      hour_band: item.hour_band,
      [item.perent_region.replace(" ", "")]: item.count
    }));



    const result123 = result_0_3HR.reduce((acc, item) => {
      // copy the hour_band property if it's not already in the accumulator
      if (!acc.hour_band) {
        acc.hour_band = item.hour_band;
      }
      // loop over the other properties
      for (const key in item) {
        // skip the hour_band property
        if (key !== "hour_band") {
          // replace the parentheses with underscores and assign the value
          acc[key] = item[key];
        }
      }
      // return the accumulator
      return acc;
    }, {}); // initialize the accumulator as an empty object

    return result123
  }

  setRunningHoursBandChartDataSet(data) {
    if (data != null) {
      this.setState({
        runningHoursBandChart: false,
        runningHoursBandChartSetY1: null,
        runningHoursBandChartSetX1: null
      })

      let filtered_0_3HR = data.filter(data => {
        return data.hour_band === '0-3Hr';
      });
      let filtered_3_5Hr = data.filter(data => {
        return data.hour_band === '3-5Hr';
      });
      let filtered_5_8Hr = data.filter(data => {
        return data.hour_band === '5-8Hr';
      });
      let filtered_8_24Hr = data.filter(data => {
        return data.hour_band === '8-24Hr';
      });
      let filtered_24Hr = data.filter(data => {
        return data.hour_band === '>24Hr';
      });


      let finalAarry = [this.setFullObjectWithRenge(filtered_0_3HR, '0-3Hr'), this.setFullObjectWithRenge(filtered_3_5Hr, '3-5Hr'), this.setFullObjectWithRenge(filtered_5_8Hr, '5-8Hr'), this.setFullObjectWithRenge(filtered_8_24Hr, '8-24Hr'), this.setFullObjectWithRenge(filtered_24Hr, '>24Hr')]

      finalAarry.sort((a, b) => (a.hour_band > b.hour_band) ? 1 : -1)
      console.log("finalAarry")
      console.log(finalAarry)

      let hour_bandSet = finalAarry.map(a => a.hour_band);
      let CRSet = finalAarry.map(a => a['Central(CR)']);
      let ERSet = finalAarry.map(a => a['Eastern(ER)']);
      let NRSet = finalAarry.map(a => a['Northern(NR)']);
      let SRSet = finalAarry.map(a => a['Southern(SR)']);
      let WRSet = finalAarry.map(a => a['Western(WR)']);

      this.setState({
        runningHoursBandChartSetY1: [{
          name: 'CR',
          type: 'column',
          data: CRSet
        }, {
          name: 'ER',
          type: 'column',
          data: ERSet
        }, {
          name: 'NR',
          type: 'column',
          data: NRSet
        }, {
          name: 'SR',
          type: 'column',
          data: SRSet
        }, {
          name: 'WR',
          type: 'column',
          data: WRSet
        }],
        runningHoursBandChartSetX1: hour_bandSet
      }, () => {
        this.setState({
          runningHoursBandChart: true
        })
      })

    }

  }


  setRunningHoursRegionalChartDataSet(data) {
    this.setState({
      runningHoursRegionallyChart: false,
      runningHoursRegionallyChartSetY1: null,
      runningHoursRegionallyChartSetX1: null
    })
    if (data != null) {
      let regionSet = data.map(a => a.region);
      let totalSet = data.map(a => a.total);
      this.setState({
        runningHoursRegionallyChartSetY1: [{
          name: 'Inflation',
          data: totalSet
        }],
        runningHoursRegionallyChartSetX1: regionSet
      }, () => {
        this.setState({
          runningHoursRegionallyChart: true
        })
      })
    }
  }

  setRunningCostChartDataDataSet(data) {
    console.log('setRunningCostChartDataDataSet');
    console.log(data);
    if (data != null) {
      this.setState({
        runningCostChart: false
      })
      let fff = data.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
      let dateSet = fff.map(a => a.date);
      let cumulative_sumSet = fff.map(a => a.cumulative_sum);
      let WRSet = fff.map(a => a.WR);
      let CRSet = fff.map(a => a.CR);
      let ERSet = fff.map(a => a.ER);
      let NRSet = fff.map(a => a.NR);
      let SRSet = fff.map(a => a.SR);

      console.log(NRSet)
      this.setState({
        runningCostChartSetY1: [
          {
            label: 'Cumulative Cost',
            yAxisID: 'y2',
            type: 'line',
            data: cumulative_sumSet,
            borderWidth: 2,
            backgroundColor: 'rgb(53, 162, 235)',
            borderColor: 'rgb(53, 162, 200)',
          },
          {
            label: 'CR',
            yAxisID: 'y',
            data: CRSet,
            backgroundColor: 'rgb(255, 99, 132)',
          },
          {
            label: 'ER',
            yAxisID: 'y',
            data: ERSet,
            backgroundColor: 'rgb(255,165,0)',
          },
          {
            label: 'NR',
            yAxisID: 'y',
            data: NRSet,
            backgroundColor: 'rgb(0,250,154)',
          },
          {
            label: 'SR',
            yAxisID: 'y',
            data: SRSet,
            backgroundColor: 'rgb(0,191,255)',
          },
          {
            label: 'WR',
            yAxisID: 'y',
            data: WRSet,
            backgroundColor: 'rgb(139,0,139)',
          },

        ],
        runningCostChartSetX1: dateSet,
      }, () => {
        this.setState({
          runningCostChart: true
        })
      })




    }

  }

  getDashboardChartData = async (data) => {
    var res = await getDashboardChartDatas(data);
    console.log('getDashboardChartDatas')
    console.log(res)
    if (res.success) {
      this.setState({
        regionalBreakdownChartData: res.data?.regionalBreakdown,
        installationStatusChartData: res.data?.installationStatus,
        requestTrendChartData: res.data?.requestTrend,
        runningCostChartData: res.data?.runningCost,
        runningHoursRegionalChartData: res?.data?.RunningHoursRegional,
        runningHoursBandChartData: res.data?.runningHoursBand
      }, () => {
        this.setRegionalBreakdownChartDataSet(this.state.regionalBreakdownChartData)
        this.setInstallationStatusChartDataSet(this.state.installationStatusChartData)
        this.setRequestTrendChartDataSet(this.state.requestTrendChartData)
        this.setRunningCostChartDataDataSet(this.state.runningCostChartData)
        this.setRunningHoursRegionalChartDataSet(this.state.runningHoursRegionalChartData)
        this.setRunningHoursBandChartDataSet(this.state.runningHoursBandChartData)
      })
    }
  }

  getDataMonthlyRegionViceCost = async (data) => {
    var res = await getMonthlyRegionViceCost(data);
    console.log('getMonthlyRegionViceCost')
    console.log(res)
    this.setState({
      pgCostWRChart: false,
      pgCostERChartValue: null,
      pgCostERChartTarget: null,
      pgCostERChartRealValue: null,
      pgCostERChart: false,
      pgCostWRChartValue: null,
      pgCostWRChartTarget: null,
      pgCostWRChartRealValue: null,
      pgCostCRChartValue: null,
      pgCostCRChartTarget: null,
      pgCostCRChartRealValue: null,
      pgCostCRChart: false,
      pgCostSRChartValue: null,
      pgCostSRChartTarget: null,
      pgCostSRChartRealValue: null,
      pgCostSRChart: false,
      pgCostNRChartValue: null,
      pgCostNRChartTarget: null,
      pgCostNRChartRealValue: null,
      pgCostNRChart: false,
  });
    if (res.success) {

      if (res?.data != null) {
        res?.data.map((x, y) => {
          if (x.region == 'Eastern (ER)') {
            let setvaluesscr = (x?.value / x?.target)
            this.setState({
              pgCostERChartValue: setvaluesscr,
              pgCostERChartTarget: x?.target,
              pgCostERChartRealValue: x?.value,
            }, () => {
              this.setState({
                pgCostERChart: true
              })
            })
          }
          if (x.region == 'Western (WR)') {
            let setvaluesscr = (x?.value / x?.target)
            this.setState({
              pgCostWRChartValue: setvaluesscr,
              pgCostWRChartTarget: x?.target,
              pgCostWRChartRealValue: x?.value,
            }, () => {
              this.setState({
                pgCostWRChart: true
              })
            })
          }
          if (x.region == 'Central (CR)') {
            let setvaluesscr = (x?.value / x?.target)
            this.setState({
              pgCostCRChartValue: setvaluesscr,
              pgCostCRChartTarget: x?.target,
              pgCostCRChartRealValue: x?.value,
            }, () => {
              this.setState({
                pgCostCRChart: true
              })
            })
          }
          if (x.region == 'Southern (SR)') {
            let setvaluesscr = (x?.value / x?.target)

            console.log('setvaluesscr' + setvaluesscr)
            this.setState({
              pgCostSRChartValue: setvaluesscr,
              pgCostSRChartTarget: x?.target,
              pgCostSRChartRealValue: x?.value,
            }, () => {
              this.setState({
                pgCostSRChart: true
              })
            })
          }
          if (x.region == 'Northern (NR)') {
            let setvaluesscr = (x?.value / x?.target)

            console.log('setvaluesscr' + setvaluesscr)
            this.setState({
              pgCostNRChartValue: setvaluesscr,
              pgCostNRChartTarget: x?.target,
              pgCostNRChartRealValue: x?.value,
            }, () => {
              this.setState({
                pgCostNRChart: true
              })
            })
          }
        })
      }
    }
  }

  getPgChartRequest = async (data2) => {
    this.setState({
      pg_chart_req_loading: true,
    });
    var res = await PgChartRequest(data2);
    if (res.success) {
      if (!res.data?.daily?.length == 0) {
        this.setState({
          daily_status_chart_req: true,
          weekly_status_chart_req: false,
          monthly_status_chart_req: false,
          pg_chart_req_loading: false,
          all_chart_req_data: res?.data,
          daily_chart_req_data: res?.data?.daily,
        });
      }
      if (!res.data?.weekly?.length == 0) {
        this.setState({
          daily_status_chart_req: false,
          weekly_status_chart_req: true,
          monthly_status_chart_req: false,
          pg_chart_req_loading: false,
          all_chart_req_data: res?.data,
          daily_chart_req_data: res?.data?.weekly,
        });
      }
      if (!res.data?.monthly?.length == 0) {
        this.setState({
          daily_status_chart_req: false,
          weekly_status_chart_req: false,
          monthly_status_chart_req: true,
          pg_chart_req_loading: false,
          all_chart_req_data: res?.data,
          daily_chart_req_data: res?.data?.monthly,
        });
      }
    }
  };

  getPgFullCountByTime = async (data2) => {
    this.setState({
      pg_count_loading: true,
    });
    var res = await PgFullCountByTime(data2);
    if (res.success) {
      this.setState({
        pg_count_loading: false,
        pg_count_daily_weekly_monthly: res.data,
        pg_count: res.data.count,
      })
    }
  };

  getdayWeekMonthFilter = async (data2) => {
    this.setState({
      pg_by_count_loading: true
    });


    var res = await DayWeekMonthFilter(data2);
    console.log("DayWeekMonthFilter");
    console.log(res);

    if (res.success) {
      if (!res.data.daysWithCount.length == 0) {
        this.setState({
          pg_by_count_loading: false,
          day_week_month_filter_data_set: res.data,
          day_week_month_filter_data_set_weekly: res.data.weeksWithCount.sort((a, b) => (a > b) ? 1 : -1),
          day_week_month_filter_data_set_monthly: res.data.monthsWithCount.sort((a, b) => (a > b) ? 1 : -1),
          pg_by_count_chart_data: res.data.daysWithCount.sort((a, b) => (a > b) ? 1 : -1),
          daily_status: true,
          weekly_status: false,
          monthly_status: false,
          select_filer_type: 'Last 7 Days'

        });

      } else if (!res.data.weeksWithCount.length == 0) {
        this.setState({
          pg_by_count_loading: false,
          day_week_month_filter_data_set: res.data,

          day_week_month_filter_data_set_monthly: res.data.monthsWithCount.sort((a, b) => (a > b) ? 1 : -1),
          pg_by_count_chart_data: res.data.weeksWithCount.sort((a, b) => (a > b) ? 1 : -1),
          day_week_month_filter_data_set_weekly: res.data.weeksWithCount,
          daily_status: false,
          weekly_status: true,
          monthly_status: false,
          select_filer_type: 'Last 7 Days'

        });

      } else if (!res.data.monthsWithCount.length == 0) {
        this.setState({
          pg_by_count_loading: false,
          day_week_month_filter_data_set: res.data,
          day_week_month_filter_data_set_weekly: res.data.weeksWithCount.sort((a, b) => (a > b) ? 1 : -1),
          pg_by_count_chart_data: res.data.monthsWithCount.sort((a, b) => (a > b) ? 1 : -1),
          day_week_month_filter_data_set_monthly: res.data.monthsWithCount,
          daily_status: false,
          weekly_status: false,
          monthly_status: true,
          select_filer_type: 'Last 7 Days'

        });

      }


    }
  };
  getPgSLABreachPercentage = async (data2) => {

    this.setState({
      sla_status_loading: true
    });
    var res = await PgSLABreachPercentage(data2);
    //console.log("PgSLABreachPercentage");
    //console.log(res);

    if (res.success) {
      this.setState({
        sla_status_loading: false,
        sla_status_daily_weekly_monthly: res?.data,
        sla_status: res?.data?.daily,


      });


    }
  };
  getGetCostByDate = async (data2) => {
    // globalCancelToken.cancel()


    this.setState({
      pg_cost_loading: true
    });
    var res = await GetCostByDate(data2);

    if (res.success) {

      this.setState({
        pg_cost_loading: false,
        pg_cost_daily_weekly_monthly_count: res?.data,
        pg_cost_count: res?.data?.cost,
        pg_cost_type: res?.data?.type,
      });
    }
  };
  getStatusTypesCount = async (data) => {
    var res = await StatusTypesCount(data);
    if (res.success) {
      this.setState({
        requested_count: res.data[0]["REQUESTED"] || 0,
        installed_count: res.data[0]["INSTALLED"] || 0,
        removed_count: res.data[0]["REMOVED"] || 0,
        cancelled_count: res.data[0]["NON_PAYALBE_CANCELLED"] || 0,
        cancelled_count_payable: res.data[0]["PAYABLE_CANCELLED"] || 0,
      });
    }
  };

  getPgCountData = async (data) => {
    var data = data;
    var res = await getPgCountData(data);
    if (res.success) {
      this.setState({
        chartLabel: res.data.lable,
        chartData: res.data.data,
      });
    }
  };
  getPgSLAData = async (data) => {
    var data = data;
    var res = await getPgSLAData(data);
    //console.log(res);

    if (res.success) {
      this.setState({
        chart_data_4: res.data,
      });
    }
  };
  getPgStatusData = async (data) => {
    var data = data;
    var res = await getPgStatusData(data);
    //console.log(res);

    if (res.success) {
      this.setState({
        chart_data_2: res.data,
      });
    }
  };
  getPgCostData = async (data) => {
    var data = data;
    var res = await getPgCostData(data);
    //console.log(res);

    if (res.success) {
      this.setState({
        chart_data_3: res.data,
      });
    }
  };
  getPgData = async () => {
    var res = await getPgData();
    // //console.log(res.data.requests);

    if (res.success) {
      this.setState({
        pgData: res.data.requests,
        // pgPendingCount:res.data.counts.pendingCount
      });
    }
  };

  onChange = (value) => {
    console.log(this.props.depots)
    this.setState({
      depot_list: [],
      filter_depot: [],
      filter_sub_region: [],

    });
    //console.log(`selected rr${value}`);
    this.setState({
      filter_region: value,
      selectedDepot: "all_depots",
      selected_region_id: value,
      SubRegion_list: this.props.regions.filter(alldate => value.map((n) => n).includes(alldate.parentRegion?.id)),
      depot_list: this.props.depots.filter(alldate => value.map((n) => n).includes(alldate.region?.parentRegion?.id))

    });

    var data = {
      id: value,
      type: "region",
    };
    if (this.state.filter_sub_region == "") {
      var getall = this.props.regions.filter(alldate => value.includes(alldate.parentRegion?.id))
      var output = [];
      for (var i = 0; i < getall.length; ++i)
        output.push(getall[i]['id']);
      var form = new FormData();
      form.append("depotId", this.state.filter_depot);
      form.append("regionId", this.state.filter_sub_region)
      form.append("parentRegionId", value);
      form.append("year", this.state.filter_date);
      form.append("month", this.state.filter_date_to);
      var formWithOutFilters = new FormData();
      formWithOutFilters.append("year", this.state.filter_date);
      formWithOutFilters.append("month", this.state.filter_date_to);
      this.getStatusTypesCount(form);
      this.getdayWeekMonthFilter(form);
      this.getGetCostByDate(form);
      this.getPgFullCountByTime(form);
    }
  };

  dataonChange = (date, dateString) => {
    const [yearValue, monthValue] = dateString.split('-');
    this.setState({
      filter_date: yearValue,
      filter_date_to: monthValue,
    }, () => {
      var form = new FormData();

      form.append("depotId", this.state.filter_depot);
      form.append("regionId", this.state.filter_sub_region);
      form.append("parentRegionId", this.state.filter_region);
      form.append("year", this.state.filter_date);
      form.append("month", this.state.filter_date_to);

      this.getdayWeekMonthFilter(form);
      this.getStatusTypesCount(form);
      this.getPgSLABreachPercentage(form);
      this.getPgFullCountByTime(form);
      this.getGetCostByDate(form);
      this.getDashboardChartData(form)
      this.getPgChartRequest(form);
      this.getDataMonthlyRegionViceCost(form)

    });



  };
  onsubChange = (value) => {
    this.setState({
      depot_list: [],
      filter_depot: [],
    });
    //console.log(`selected rr${value}`);
    this.setState({
      selectedDepot: "all_depots",
      selected_region_id: value,
      filter_sub_region: value,
      depot_list: this.props.depots.filter(alldate => value.map((n) => n).includes(alldate.region?.id))
    });
    var data = {
      id: value,
      type: "region",
    };
    var form = new FormData();
    form.append("depotId", this.state.filter_depot);
    form.append("regionId", value)
    form.append("parentRegionId", this.state.filter_region);
    form.append("RequestDateFrom", this.state.filter_date);
    form.append("RequestDateTo", this.state.filter_date_to);
    form.append("RequestDateTO", this.state.filter_date_to);
    form.append("year", this.state.filter_date);
    form.append("month", this.state.filter_date_to);
    this.getdayWeekMonthFilter(form);
    this.getStatusTypesCount(form);
    //this.getPgSLABreachPercentage(form);
    this.getGetCostByDate(form);
    this.getPgFullCountByTime(form);
    //this.getPgChartRequest(form);

  };
  onChange2 = (value) => {
    //console.log(`selected depot ${value}`);
    this.setState({
      selectedDepot: value,
      filter_depot: value,
    });
    var data = {
      id: value,
      type: "depot",
    };
    var form = new FormData();
    //console.log("sdsrrrrrr")
    ////console.log(output)
    form.append("depotId", value);
    form.append("regionId", this.state.filter_sub_region)
    form.append("parentRegionId", this.state.filter_region);
    form.append("RequestDateFrom", this.state.filter_date);
    form.append("RequestDateTo", this.state.filter_date_to);
    form.append("RequestDateTO", this.state.filter_date_to);
    form.append("year", this.state.filter_date);
    form.append("month", this.state.filter_date_to);

    this.getdayWeekMonthFilter(form);
    this.getStatusTypesCount(form);
    //this.getPgSLABreachPercentage(form);
    this.getGetCostByDate(form);
    this.getPgFullCountByTime(form);
    //this.getPgChartRequest(form);
    // this.getPgCountData(data);
    // this.getPgStatusData(data);
    // this.getPgCostData(data);
    // this.getPgSLAData(data);
  }
  handleClickDaily = () => {
    //console.log(this.state);
    if (!this.state.day_week_month_filter_data_set.daysWithCount.length == 0) {
      this.setState({
        pg_by_count_chart_data: this.state.day_week_month_filter_data_set.daysWithCount,
        // pg_cost_count: this.state?.pg_cost_daily_weekly_monthly_count[0]?.cost,
        //pg_cost_count: 18756,
        //sla_status: this.state?.sla_status_daily_weekly_monthly?.daily,
        //regoin_cost: this.state?.daily_regoin_cost,
        //pg_count: this.state?.pg_count_daily_weekly_monthly.sevenDays,
        daily_status: true,
        weekly_status: false,
        monthly_status: false,
        // select_filer_type: 'Last 7 Days'
      });
    } else {
      alert("The time range is not applicable.")
    }
  }
  chartHandleClickDaily = () => {
    if (!this.state.all_chart_req_data?.daily?.length == 0) {
      this.setState({
        daily_req_data: this.state.all_chart_req_data?.daily,
        // pg_cost_count: this.state?.pg_cost_daily_weekly_monthly_count[0]?.cost,
        //pg_cost_count: 18756,
        //sla_status: this.state?.sla_status_daily_weekly_monthly?.daily,
        //regoin_cost: this.state?.daily_regoin_cost,
        //pg_count: this.state?.pg_count_daily_weekly_monthly.sevenDays,
        daily_status: true,
        weekly_status: false,
        monthly_status: false,
        // select_filer_type: 'Last 7 Days'
      });
    } else {
      alert("The time range is not applicable.")
    }
  }
  handleClickWeekly = () => {
    if (!this.state.day_week_month_filter_data_set_weekly.length == 0) {
      this.setState({
        pg_by_count_chart_data: this.state.day_week_month_filter_data_set_weekly,
        daily_status: false,
        weekly_status: true,
        monthly_status: false,
        select_filer_type: 'Last 7 weeks'

      });
    } else {
      alert("The time range is not applicable.")
    }
  }
  chartHandleClickWeekly = () => {
    if (!this.state.all_chart_req_data?.weekly?.length == 0) {
      this.setState({
        daily_req_data: this.state.all_chart_req_data?.weekly,
        daily_status: false,
        weekly_status: true,
        monthly_status: false,
        select_filer_type: 'Last 7 weeks'

      });
    } else {
      alert("The time range is not applicable.")
    }
  }
  handleClickWeekly = () => {
    //console.log(this.state.day_week_month_filter_data_set)
    if (!this.state.day_week_month_filter_data_set_weekly.length == 0) {
      this.setState({
        pg_by_count_chart_data: this.state.day_week_month_filter_data_set_weekly,
        daily_status: false,
        weekly_status: true,
        monthly_status: false,
        select_filer_type: 'Last 7 weeks'

      });
    } else {
      alert("The time range is not applicable.")
    }
  }
  chartHandleClickMonthly = () => {
    if (!this.state.all_chart_req_data?.monthly?.length == 0) {

      //console.log("gttttt");
      //console.log(this.state.all_chart_req_data);

      this.setState({
        daily_req_data: this.state.all_chart_req_data?.monthly,
        // pg_cost_count: this.state?.pg_cost_daily_weekly_monthly_count[2]?.cost,
        // pg_cost_count: 784512,
        // sla_status: this.state?.sla_status_daily_weekly_monthly?.monthly,
        // regoin_cost: this.state?.monthly_regoin_cost,
        // pg_count: this.state?.pg_count_daily_weekly_monthly.sevenMonths,

        monthly_status: true,
        daily_status: false,
        weekly_status: false,
        select_filer_type: 'Last 7 Month'


      });
    } else {
      alert("The time range is not applicable.")
      //console.log("sdsdsooooo")

    }

  }
  handleClickMonthly = () => {
    if (!this.state.day_week_month_filter_data_set_monthly.length == 0) {

      //console.log("gttttt");
      //console.log(this.state.day_week_month_filter_data_set_monthly);

      this.setState({
        pg_by_count_chart_data: this.state.day_week_month_filter_data_set_monthly,
        // pg_cost_count: this.state?.pg_cost_daily_weekly_monthly_count[2]?.cost,
        // pg_cost_count: 784512,
        // sla_status: this.state?.sla_status_daily_weekly_monthly?.monthly,
        // regoin_cost: this.state?.monthly_regoin_cost,
        // pg_count: this.state?.pg_count_daily_weekly_monthly.sevenMonths,

        monthly_status: true,
        daily_status: false,
        weekly_status: false,
        select_filer_type: 'Last 7 Month'


      });
    } else {
      alert("The time range is not applicable.")
      //console.log("sdsdsooooo")

    }
  }


  stripYearFromDates(dates) {
    return dates.map(date => date.slice(8));
  }

  stripYearFromMonDates(dates) {
    return dates.map(dateString => {
      const date = new Date(dateString);
      return date.toLocaleDateString('en-US', { month: 'long', day: '2-digit' });
    });
  }

  render() {
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long' });

    const options = {
      // you can also just use 'bottom center'
      position: positions.BOTTOM_CENTER,
      timeout: 5000,
      offset: '30px',
      // you can also just use 'scale'
      transition: transitions.SCALE
    }
    const { filterTable, pgData } = this.state;
    const { Option } = Select;

    function onBlur() {
      //console.log("blur");
    }

    function onFocus() {
      //console.log("focus");
    }

    function onSearch(val) {
      //console.log("search:", val);
    }
    // const configChart = {
    //   data: this.state.daily_chart_req_data,
    //   color: ['#007BFF', '#28A745'],
    //   isGroup: true,
    //   xField: 'key',
    //   yField: 'value',
    //   seriesField: 'type',
    //   height: 283,
    //   xAxis: {
    //     title: {
    //       text: 'Date',
    //       visible: true,
    //     },
    //     tickCount: 7, // Show more ticks for better readability
    //   },
    //   yAxis: {
    //     title: {
    //       text: 'Number of Requests',
    //       visible: true,
    //     },
    //     min: 0,
    //   },
    //   label: {
    //     position: 'middle',
    //     layout: [
    //       {
    //         type: 'interval-adjust-position',
    //       },
    //       {
    //         type: 'interval-hide-overlap',
    //       },
    //       {
    //         type: 'adjust-color',
    //       },
    //     ],
    //   },
    // };


    const config2 = {
      appendPadding: 2,
      data: this.state.sla_status,
      angleField: 'value',
      colorField: 'type',
      color: ['#FFB200', '#42B02A'],
      height: 283,
      radius: 1,
      innerRadius: 0.6,
      label: {
        type: 'inner',
        offset: '-50%',
        content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
        style: {
          textAlign: 'center',
          fontSize: 14,
        },
      },
      interactions: [
        {
          type: 'element-selected',
        },
        {
          type: 'element-active',
        },
      ],
      statistic: {
        title: false,
        content: {
          style: {
            whiteSpace: 'pre-wrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
          content: 'SLA',
        },
      },
    };


    const configChart = {
      data: this.state.daily_chart_req_data,
      color: ['#FFB200', '#42B02A'],
      isGroup: true,
      xField: 'key',
      yField: 'value',
      seriesField: 'type',
      height: 283,
      label: {
        position: 'middle',
        layout: [
          {
            type: 'interval-adjust-position',
          },
          {
            type: 'interval-hide-overlap',
          },
          {
            type: 'adjust-color',
          },
        ],
      },
    };


    const configRegoinCost = {
      data: this.state.regoin_cost,
      isStack: true,
      xField: 'time',
      yField: 'cost',
      seriesField: 'type',
      label: {
        // 可手动配置 label 数据标签位置
        position: 'middle',
        // 'top', 'bottom', 'middle'
        // 可配置附加的布局方法
        layout: [
          // 柱形图数据标签位置自动调整
          {
            type: 'interval-adjust-position',
          }, // 数据标签防遮挡
          {
            type: 'interval-hide-overlap',
          }, // 数据标签文颜色自动调整
          {
            type: 'adjust-color',
          },
        ],
      },
    };

    const formatNumberWithCommas = (number) => {
      if (typeof number !== 'number') {
        return 'Invalid input';
      }
      return number.toLocaleString();
    };

    const presentatageCount = (max, min) => {
      let result = ((min / max) * 100).toFixed(2)
      return result
    }

    //   stripYearFromDates(dates) {
    //     return dates.map(date => date.slice(8));
    // }

    return (
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <div class="page-title-box">
              <div class="row">
                <div class="col">
                  <h4 class="page-title">Dashboard PG</h4>
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0);">Dashboard</a></li>
                    <li class="breadcrumb-item"><a href="javascript:void(0);">PG</a></li>

                  </ol>
                </div>
                <div class="col-auto align-self-center">
                  <a href="#" class="btn btn-sm btn-outline-primary" id="Dash_Date">
                    <span class="day-name" id="Day_Name">Today:</span>&nbsp;
                    <span class="" id="Select_date">{new Date().toUTCString().slice(5, 16)}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar align-self-center icon-xs ms-1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                  </a>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12" style={{
            background: '#fafafa', padding: '20px', borderRadius: '10px'
          }}>
            <div className="row">
              <div className="col-md-2 col-lg-3">
                <DatePicker
                  picker="month"
                  className='customDropdown textStyles-small'
                  style={{ width: "100%", borderRadius: "5px" }}
                  allowClear={false}
                  defaultValue={moment(moment().format("YYYY-MM-DD").slice(0, -2) + '01')}
                  size='medium'
                  onChange={this.dataonChange}
                />
              </div>
            </div>
          </div>
        </div>

        <div style={{ background: 'white', borderRadius: '10px', padding: '20px' }}>
          <Row>
            <Col span={12}>
              <h5 style={{
                fontWeight: "bold", fontFamily: 'Roboto'
              }}>Dashboard Statistics </h5>
            </Col>

            <Col span={12}>
              <div style={{ float: 'right' }}>
                <Select
                  mode="multiple"
                  className="borderedSelect"
                  bordered={false}
                  showSearch
                  style={{ width: "250px", borderRadius: "5px" }}
                  placeholder="Select a region"
                  optionFilterProp="children"
                  onChange={this.onChange}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {this.props.regions.filter(allret => "0".includes(allret.isSubRegion)).map((item, index) => {
                    return <Option value={item.id}>{item.name}</Option>;
                  })}
                </Select>

                <Select
                  mode="multiple"
                  className="borderedSelect"
                  bordered={false}
                  showSearch
                  style={{ width: "250px", marginLeft: "5px" }}
                  placeholder="Select a Subregion"
                  optionFilterProp="children"
                  onChange={this.onsubChange}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {this.state.SubRegion_list.map((item, index) => {
                    return <Option value={item.id}>{item.name}</Option>;
                  })}
                </Select>
              </div>

            </Col>
          </Row>

          <Row style={{ marginTop: '10px' }}>
            <div className="col-md-12 col-lg-12">
              <div class="row justify-content-center">

                <div class="col-md-2 col-lg-3" style={{ width: '20%' }}>
                  <div class="card report-card  bg-soft-primary" style={{ borderRadius: '20px', height: '110px' }}>
                    <div class="card-body">
                      <div class="row d-flex justify-content-center">
                        <div class="col">
                          <p class="mb-0 fw-semibold" style={{
                            fontSize: "14px"
                          }}>
                            <b>Requested <br/>Count</b></p>
                          <h2 class="m-0" style={{ marginTop: '3px' }}>{this.state.requested_count}</h2>
                        </div>
                        <div class="col-auto align-self-center">
                          <div style={{
                            background: "white", borderRadius: "50%", width: "40px", height: "40px", display: "grid",
                            alignItems: "center", justifyContent: "center", margin: "auto"
                          }} float="left" className="mt-1">
                            <PullRequestOutlined style={{ color: "#1761fd", fontSize: '20px' }} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div class="col-md-2 col-lg-3" style={{ width: '20%' }}>
                  <div class="card report-card bg-soft-success" style={{ borderRadius: '20px', height: '110px' }}>
                    <div class="card-body" >
                      <div class="row d-flex justify-content-center">
                        <div class="col">
                          <p class="mb-0 fw-semibold" style={{
                            fontSize: "14px"
                          }}><b>Installed <br/> Count</b></p>
                          <h2 class="m-0" style={{ marginTop: '3px' }}>{this.state.installed_count}</h2>
                        </div>
                        <div class="col-auto align-self-center">
                          <div style={{
                            background: "white", borderRadius: "50%", width: "40px", height: "40px", display: "grid",
                            alignItems: "center", justifyContent: "center", margin: "auto"
                          }} float="left" className="mt-1">
                            <SettingOutlined style={{ color: "#99db7f", fontSize: '20px' }} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-lg-3" style={{ width: '20%' }}>
                  <div class="card report-card bg-soft-pink" style={{ borderRadius: '20px', height: '110px' }}>
                    <div class="card-body" >
                      <div class="row d-flex justify-content-center">
                        <div class="col">
                          <p class="mb-0 fw-semibold" style={{
                            fontSize: "14px"
                          }}><b>Removed <br/>Count</b></p>
                          <h2 class="m-0" style={{ marginTop: '3px' }}>{this.state.removed_count}</h2>
                        </div>
                        <div class="col-auto align-self-center">
                          <div style={{
                            background: "white", borderRadius: "50%", width: "40px", height: "40px", display: "grid",
                            alignItems: "center", justifyContent: "center", margin: "auto"
                          }} float="left" className="mt-1">
                            <DeleteOutlined style={{ color: "#fd3ca3", fontSize: '20px' }} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-lg-3" style={{ width: '20%' }} >
                  <div class="card report-card bg-soft-warning" style={{ borderRadius: '20px', height: '110px' }}>
                    <div class="card-body" >
                      <div class="row d-flex justify-content-center">
                        <div class="col">
                          <p class="mb-0 fw-semibold" style={{
                            fontSize: "14px"
                          }}><b>Non-Payable <br/>Cancelled</b></p>
                          <h2 class="m-0" style={{ marginTop: '3px' }}>{this.state.cancelled_count}</h2>
                        </div>
                        <div class="col-auto align-self-center">
                          <div style={{
                            background: "white", borderRadius: "50%", width: "40px", height: "40px", display: "grid",
                            alignItems: "center", justifyContent: "center", margin: "auto"
                          }} float="left" className="mt-1">
                            <CloseOutlined style={{ color: "#ffda7e", fontSize: '20px' }} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-lg-3" style={{ width: '20%'}}>
                  <div class="card report-card bg-soft-purple" style={{ borderRadius: '20px', height: '110px'}}>
                    <div class="card-body" >
                      <div class="row d-flex justify-content-center">
                        <div class="col">
                          <p class="mb-0 fw-semibold" style={{
                            fontSize: "14px"
                          }}><b>Payable <br/>Cancelled</b></p>
                          <h2 class="m-0" style={{ marginTop: '3px' }}>{this.state.cancelled_count_payable}</h2>
                        </div>
                        <div class="col-auto align-self-center">
                          <div style={{
                            background: "white", borderRadius: "50%", width: "40px", height: "40px", display: "grid",
                            alignItems: "center", justifyContent: "center", margin: "auto"
                          }} float="left" className="mt-1">
                            <CloseOutlined style={{ color: "#BDB5D5", fontSize: '20px' }} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </Row>
        </div>

        <div className='col-md-12' style={{ marginTop: '10px' }}>
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="col-md-4">
                  <h4 className="card-title" style={{
                    fontWeight: "bold", fontFamily: 'Roboto'
                  }}>Portable Generator - Monthly </h4>
                </div>
                <div className="container" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <div className="col">
                    <span><Badge color="#2D5FF4" text="Value" style={{ marginLeft: "10px", fontFamily: 'Roboto', fontSize: '9px', fontWeight: 'bold' }} /></span>
                    <span style={{ marginLeft: "10px", fontFamily: 'Roboto', fontSize: '9px', fontWeight: 'bold' }}><Badge color="#42B02A" text="Target" /></span>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="row">

                {this.state.pgCostWRChart ? (<>
                  <div class="col-md-6 col-lg-3" style={{ textAlign: "center", width: "20%" }} >
                    {this.state.pgCostWRChartTarget ? (
                      <SemiCircleGaugeChat
                        setcolor="#2D5FF4"
                        setvalues={((this.state.pgCostWRChartRealValue / this.state.pgCostWRChartTarget) * 100).toFixed(2)}
                        setTitel="Western"
                        realValue={this.state.pgCostWRChartRealValue}
                        targetValue={this.state.pgCostWRChartTarget}
                      />
                    ) : (
                      console.log(((this.state.pgCostCRChartValue / this.state.pgCostCRChartTarget) * 100).toFixed(2))
                    )}
                  </div>

                  <div class="col-md-6 col-lg-3" style={{ textAlign: "center", width: "20%" }} >
                    {this.state.pgCostCRChartTarget ? (
                      <SemiCircleGaugeChat
                        setcolor="#2D5FF4"
                        setvalues={((this.state.pgCostCRChartRealValue / this.state.pgCostCRChartTarget) * 100).toFixed(2)}
                        setTitel="Central"
                        realValue={this.state.pgCostCRChartRealValue}
                        targetValue={this.state.pgCostCRChartTarget}
                      />
                    ) : (
                      console.log(((this.state.pgCostCRChartValue / this.state.pgCostCRChartTarget) * 100).toFixed(2))
                    )}
                  </div>

                  <div class="col-md-6 col-lg-3" style={{ textAlign: "center", width: "20%" }} >
                    {this.state.pgCostSRChartTarget ? (
                      <SemiCircleGaugeChat
                        setcolor="#2D5FF4"
                        setvalues={((this.state.pgCostSRChartRealValue / this.state.pgCostSRChartTarget) * 100).toFixed(2)}
                        setTitel="Southern"
                        realValue={this.state.pgCostSRChartRealValue}
                        targetValue={this.state.pgCostSRChartTarget}
                      />
                    ) : (
                      console.log(((this.state.pgCostSRChartValue / this.state.pgCostSRChartTarget) * 100).toFixed(2))
                    )}
                  </div>

                  <div class="col-md-6 col-lg-3" style={{ textAlign: "center", width: "20%" }} >
                    {this.state.pgCostNRChartTarget ? (
                      <SemiCircleGaugeChat
                        setcolor="#2D5FF4"
                        setvalues={((this.state.pgCostNRChartRealValue / this.state.pgCostNRChartTarget) * 100).toFixed(2)}
                        setTitel="Northern"
                        realValue={this.state.pgCostNRChartRealValue}
                        targetValue={this.state.pgCostNRChartTarget}
                      />
                    ) : (
                      console.log(((this.state.pgCostNRChartValue / this.state.pgCostNRChartTarget) * 100).toFixed(2))
                    )}
                  </div>

                  <div class="col-md-6 col-lg-3" style={{ textAlign: "center", width: "20%" }} >
                    {this.state.pgCostERChartTarget ? (
                      <SemiCircleGaugeChat
                        setcolor="#2D5FF4"
                        setvalues={((this.state.pgCostERChartRealValue / this.state.pgCostERChartTarget) * 100).toFixed(2)}
                        setTitel="Eastern"
                        realValue={this.state.pgCostERChartRealValue}
                        targetValue={this.state.pgCostERChartTarget}
                      />
                    ) : (
                      console.log(((this.state.pgCostERChartValue / this.state.pgCostERChartTarget) * 100).toFixed(2))
                    )}
                  </div>
                </>) : ('')}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 col-lg-8">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-md-4">
                    <h4 className="card-title" style={{
                      fontWeight: "bold", fontFamily: 'Roboto'
                    }}>PG Tickets</h4>
                  </div>
                  <div className="col-md-8" style={{ fontSize: 11, textAlign: 'end', display: "grid", alignItems: "center" }}>
                    <li class="list-inline-item">
                      {this.state.daily_status_chart_req ? (<button type="button" onClick={this.chartHandleClickDaily} class="btn btn-soft-primary btn-sm" style={{ marginRight: 5, background: "#1761fd", color: "white", padding: "revert" }}>Daily</button>) : (<button type="button" onClick={this.chartHandleClickDaily} class="btn btn-soft-primary btn-sm" style={{ marginRight: 5, padding: "revert" }}>Daily</button>)}
                    </li>
                  </div>
                </div>

              </div>
              <div className="card-body">
                {<Column {...configChart}
                  loading={this.state.pg_chart_req_loading}
                />
                }
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-4">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-md-8">
                    <h4 className="card-title" style={{
                      fontWeight: "bold", fontFamily: 'Roboto'
                    }}>SLA Status Percentage</h4>
                  </div>
                  <div className="col-md-4" style={{ fontSize: 11, textAlign: 'end', display: "grid", alignItems: "center" }}>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <Pie {...config2}
                  loading={this.state.sla_status_loading}
                />
              </div>
            </div>
          </div>

          {/* <div className="col-md-12 col-lg-2">
            <div className="row">
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-md-6">
                      <h4 className="card-title" style={{
                        fontWeight: "bold", fontFamily: 'Roboto'
                      }}>PG Cost</h4>
                    </div>
                    <div className="col-md-6" style={{ fontSize: 9, textAlign: 'end', display: "grid", alignItems: "center" }}>
                    </div>
                  </div>
                </div>
                <div className="card-body" style={{ height: 140, textAlign: "center" }}>
                  <x style={{ fontSize: 24 }}>LKR</x>
                  {this.state.pg_cost_loading ? (
                    <div className="row">
                      <div className="col-md-4"></div>
                      <ReactLoading
                        type={"bars"}
                        color={"#03fc4e"}
                        height={86}
                        width={50}
                      /></div>
                  ) : (
                    <p style={{ fontSize: 24 }}>
                      {this.state.pg_cost_type == "Out of Range" ? (<p>Out of Range</p>) : (<b> <CurrencyFormat value={this.state.pg_cost_count} displayType={'text'} thousandSeparator={true} renderText={value => <div>{value}</div>} />
                      </b>)
                      }
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-md-8">
                      <h4 className="card-title" style={{
                        fontWeight: "bold", fontFamily: 'Roboto'
                      }}>PG Count</h4>
                    </div>
                    <div className="col-md-4" style={{ fontSize: 9, textAlign: 'end', display: "grid", alignItems: "center" }}>
                    </div>
                  </div>
                </div>
                <div className="card-body" style={{ height: 110, textAlign: "center" }}>
                  <p style={{ fontSize: 24 }}>{this.state.pg_count}</p>
                </div>
              </div>
            </div>
          </div> */}
        </div>

        <div className="row">
          {/* <div className="col-md-12 col-lg-10">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-md-3">
                    <h4 className="card-title" style={{
                      fontWeight: "bold", fontFamily: 'Roboto'
                    }}>PG by count</h4>
                  </div>
                  <div className="col-md-9" style={{ fontSize: 11, textAlign: 'end', display: "grid", alignItems: "center" }}>
                    <li class="list-inline-item">
                      {this.state.daily_status ? (<button type="button" onClick={this.handleClickDaily} class="btn btn-soft-primary btn-sm" style={{ marginRight: 5, background: "#1761fd", color: "white", padding: "revert" }}>Daily</button>) : (<button type="button" onClick={this.handleClickDaily} class="btn btn-soft-primary btn-sm" style={{ marginRight: 5, padding: "revert" }}>Daily</button>)}
                      {this.state.weekly_status ? (<button type="button" onClick={this.handleClickWeekly} class="btn btn-soft-primary btn-sm" style={{ marginRight: 5, background: "#1761fd", color: "white", padding: "revert" }}>Weekly</button>) : (<button type="button" onClick={this.handleClickWeekly} class="btn btn-soft-primary btn-sm" style={{ marginRight: 5, padding: "revert" }}>Weekly</button>)}
                      {this.state.monthly_status ? (<button type="button" onClick={this.handleClickMonthly} class="btn btn-soft-primary btn-sm" style={{ background: "#1761fd", color: "white", padding: "revert" }} >Monthly</button>) : (<button type="button" onClick={this.handleClickMonthly} class="btn btn-soft-primary btn-sm" style={{ padding: "revert" }}>Monthly</button>)}
                    </li>
                  </div>
                </div>
              </div>
              <div className="card-body">
                {<Line {...config}
                  loading={this.state.pg_by_count_loading}
                />
                }
              </div>
            </div>
          </div> */}

          <div className="col-md-12 col-lg-2">
            {/* <div className="row">
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-md-6">
                      <h4 className="card-title" style={{
                        fontWeight: "bold", fontFamily: 'Roboto'
                      }}>PG Cost</h4>
                    </div>
                    <div className="col-md-6" style={{ fontSize: 9, textAlign: 'end', display: "grid", alignItems: "center" }}>
                    </div>
                  </div>
                </div>
                <div className="card-body" style={{ height: 140, textAlign: "center" }}>
                  <x style={{ fontSize: 24 }}>LKR</x>
                  {this.state.pg_cost_loading ? (
                    <div className="row">
                      <div className="col-md-4"></div>
                      <ReactLoading
                        type={"bars"}
                        color={"#03fc4e"}
                        height={86}
                        width={50}
                      /></div>
                  ) : (
                    <p style={{ fontSize: 24 }}>
                      {this.state.pg_cost_type == "Out of Range" ? (<p>Out of Range</p>) : (<b> <CurrencyFormat value={this.state.pg_cost_count} displayType={'text'} thousandSeparator={true} renderText={value => <div>{value}</div>} />
                      </b>)
                      }
                    </p>
                  )}
                </div>
              </div>
            </div> */}
            {/* <div className="row">
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-md-8">
                      <h4 className="card-title" style={{
                        fontWeight: "bold", fontFamily: 'Roboto'
                      }}>PG Count</h4>
                    </div>
                    <div className="col-md-4" style={{ fontSize: 9, textAlign: 'end', display: "grid", alignItems: "center" }}>
                    </div>
                  </div>
                </div>
                <div className="card-body" style={{ height: 110, textAlign: "center" }}>
                  <p style={{ fontSize: 34 }}>
                    <div style={{
                      background: "#1761fd", borderRadius: "50%", width: "50px", height: "50px", display: "grid",
                      alignItems: "center", justifyContent: "center", margin: "auto", color: "white", fontSize: 30
                    }}>
                    {this.state.pg_count}
                    </div>
                  </p>
                </div>
              </div>
            </div> */}

          </div>
          <div className="col-md-12 col-lg-12">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-md-8">
                    <h4 className="card-title" style={{
                      fontWeight: "bold", fontFamily: 'Roboto'
                    }}>Installation status</h4>
                  </div>
                </div>
              </div>
              <div className="card-body">
                {this.state.installationStatusChart ? (
                  <StackedBarChart
                    y1={this?.state?.setY1}
                    x1={this.stripYearFromMonDates(this.state.setX1)}
                    barColors={['#EE4B2B', '#F68407', '#00AC4F', '#2D5FF4']}
                    yLabale={'No of Requests'} />) : ('No')}
              </div>
            </div>

          </div>
          <div className="col-md-12 col-lg-6">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-md-8">
                    <h4 className="card-title" style={{
                      fontWeight: "bold", fontFamily: 'Roboto'
                    }}>Region Breakdown</h4>
                  </div>
                </div>
              </div>
              <Spin spinning={this.state.regionBreakdownChart ? false : true} tip="Loading...">
                {
                  this.state.regionBreakdownChart ? (
                    <div className="card-body">
                      {this.state.regionBreakdownChart ? (
                        <StackedBarChart
                          y1={this.state.regionBreakdownChartSetY1}
                          x1={this.state.regionBreakdownChartSetX1}
                          barColors={['#EE4B2B', '#F68407', '#00AC4F', '#2D5FF4']}
                          yLabale={'No of Requests'} />) : ('')}
                    </div>
                  ) : (
                    <Empty description="No data for display" image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  )
                }
              </Spin>
            </div>
          </div>
          <div className="col-md-12 col-lg-6">
            <div className="card">
              <div className="card-header">

                <div className="row">
                  <div className="col-md-8">
                    <h4 className="card-title" style={{
                      fontWeight: "bold", fontFamily: 'Roboto'
                    }}>Running Cost</h4>
                  </div>
                </div>
              </div>
              <div className="card-body">
                {this.state.runningCostChart ? (
                  <StackedBarLineChart
                    y1={this.state.runningCostChartSetY1}
                    x1={this.state.runningCostChartSetX1}
                    y1Lable={'Daily Cost'}
                    y2Lable={'Cumulative Cost'} />) : ('')}
              </div>
            </div>
          </div>

          <div className="col-md-12 col-lg-6">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-md-8">
                    <h4 className="card-title" style={{
                      fontWeight: "bold", fontFamily: 'Roboto'
                    }}>Running Hours Band</h4>
                  </div>
                </div>
              </div>
              <div className="card-body">
                {this.state.runningHoursBandChart ? (
                  <StackedBarChart
                    y1={this.state.runningHoursBandChartSetY1}
                    x1={this.state.runningHoursBandChartSetX1}
                    barColors={['#CBC300', '#2D5FF4', '#42B02A', '#c624ff', '#FF3A29']}
                    yLabale={'No of Requests'} />) : ('')}
              </div>
            </div>
          </div>

          <div className="col-md-12 col-lg-6">
            <div className="card">
              <div className="card-header">

                <div className="row">
                  <div className="col-md-8">
                    <h4 className="card-title" style={{
                      fontWeight: "bold", fontFamily: 'Roboto'
                    }}>Running Hours - Regionally</h4>
                  </div>
                </div>
              </div>
              <div className="card-body">
                {this.state.runningHoursRegionallyChart ? (
                  <ColumnChartsDataLablesChart
                    y1={this.state.runningHoursRegionallyChartSetY1}
                    x1={this.state.runningHoursRegionallyChartSetX1}
                    prefixData={"Hours"}
                    barColors={['#c624ff']}
                    yLabale={'No of PG Hours'} />) : ('')}
              </div>
            </div>
          </div>

          <div className="col-md-12 col-lg-6">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-md-8">
                    <h4 className="card-title" style={{
                      fontWeight: "bold", fontFamily: 'Roboto'
                    }}>Request Trend</h4>
                  </div>
                </div>
              </div>
              <div className="card-body">
                {this.state.requesteTrendChart ? (
                  <LineCharts
                    y1={this.state.requesteTrendChartSetY1}
                    x1={this.state.requesteTrendChartSetX1}
                    yLabale={"Total"} />) : ('')}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    organization_count: state.organization.length,
    regions: state.regions,
    depots: state.depots,
    sites_count: state.sites.length,
    vendor_count: state.vendor.length,
    roles_count: state.roles.length,
  };
}

export default connect(mapStateToProps, { getDonationCategories, getData })(
  DashboardPg
);

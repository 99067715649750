import React, { Component } from 'react'
import ImageGallery from 'react-image-gallery';
import NotFoundImage from '../../icon/image-not-found1.png'


class ImageDetails extends Component {

    constructor(props) {
        super(props);


        this.state = {
            latestImagesList: null,
            latestSecondImagesList: null,
            latestThirdImagesList: null


        };



    }

    componentDidMount() {
        //console.log(this.props.details)
        if (this.props.details?.latestImages != null) {
            this.setLatestImages(this.props.details?.latestImages)

        }
        if (this.props.details?.latestSecondImages != null) {
            this.setlatestSecondImages(this.props.details?.latestSecondImages)

        }
        if (this.props.details?.latestThirdImages != null) {
            this.setlatestThirdImages(this.props.details?.latestThirdImages)

        }



    }
    setlatestThirdImages(array) {
        var current = [];

        this.props.details?.latestThirdImages.map((items, index) => {
            current = [...current, { original: 'https://storage.googleapis.com/dns_storage_v2/requestimages/'.items.imageName, thumbnail: 'https://storage.googleapis.com/dns_storage_v2/requestimages/'.items.imageName }];
            //current=arr2;
        })
        //console.log(current)
        this.setState({
            latestThirdImagesList: current
        })
    }
    setlatestSecondImages(array) {
        var current = [];

        this.props.details?.latestSecondImages.map((items, index) => {
            current = [...current, { original: 'https://storage.googleapis.com/dns_storage_v2/requestimages/'.items.imageName, thumbnail: 'https://storage.googleapis.com/dns_storage_v2/requestimages/'.items.imageName }];
            //current=arr2;
        })
        //console.log(current)
        this.setState({
            latestSecondImagesList: current
        })
    }
    setLatestImages(array) {
        var current = [];

        this.props.details?.latestImages.map((items, index) => {
            current = [...current, { original: 'https://storage.googleapis.com/dns_storage_v2/requestimages/'+items.imageName, thumbnail: 'https://storage.googleapis.com/dns_storage_v2/requestimages/'+items.imageName }];
            //current=arr2;
        })
        //console.log(current)
        this.setState({
            latestImagesList: current
        })
    }
    render() {
        const images = [
            {
                original: 'https://picsum.photos/id/1018/1000/600/',
                thumbnail: 'https://picsum.photos/id/1018/1000/600/',
            },

        ];
        //console.log(images);
        //console.log(this.state.latestImagesList);

        return (
            <div>

                <br></br>
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-6 align-self-center">
                                        {this.state.latestImagesList != null ? (<ImageGallery items={this.state.latestImagesList} />) : (<div className='row'>

                                            <img src={NotFoundImage}></img>
                                        </div>)}

                                    </div>
                                    <div class="col-lg-6 align-self">
                                        <div class="single-pro-detail">


                                            <h3 class="pro-title">{this.props.details?.name}</h3>
                                            <div class="row"><div class="col">
                                              
                                                <ol class="breadcrumb">
                                                    <li class="breadcrumb-item">
                                                        <a href="javascript:void(0);">{this.props.siteName1} </a>
                                                    </li>
                                                    <li class="breadcrumb-item">
                                                        <a href="javascript:void(0);">{this.props.comptName}</a>
                                                    </li>
                                                    <li class="breadcrumb-item">
                                                        <a href="javascript:void(0);">{this.props.details?.name} </a>
                                                    </li>
                                                    
                                                </ol>

                                            </div>
                                                <div class="col-auto align-self-center"></div>
                                            </div>


                                            <ul class="list-unstyled pro-features border-0">
                                                <li>images Count: {this.props.details?.imageCount}</li>
                                                {/* <li>Contrary to popular belief, Lorem Ipsum is not text. </li> */}
                                            </ul>
                                         

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <h4 class="page-title">History</h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="card">
                            <div class="card-body">

                                {this.state.latestSecondImagesList != null ? (<ImageGallery items={this.state.latestSecondImagesList} />) : (<div className='row'>

                                    <img src={NotFoundImage}></img>
                                </div>)}

                                <div class="row my-4">
                                    <div class="col">
                                        <a href="#" class="title-text d-block">Latest Second </a>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="card">
                            <div class="card-body">

                                {this.state.latestThirdImagesList != null ? (<ImageGallery items={this.state.latestThirdImagesList} />) : (<div className='row'>

                                    <img src={NotFoundImage}></img>
                                </div>)}
                                <div class="row my-4">
                                    <div class="col">
                                        <a href="#" class="title-text d-block">Latest Third </a>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>



                </div>
            </div>
        )
    }
}
export default ImageDetails;
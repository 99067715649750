import React from "react";
import Marker from "./marker";
import { withContext } from "../../context"
import { CardContainer } from "../essentials/containers"
import "./map.css"

import {
    DEFAULT_HEIGHT,
    DEFAULT_WIDTH
} from "../defaults"

function MapCitation(){
    return(
        <div style={{fontSize: 10}} className="vector-map-citation">Map of Sri Lanka</div>
    )
}

class Card extends React.Component{
    componentDidMount() {
        //console.log("map i,mag")
        //console.log(this.props);
       // this.getData();

      }
    render(){
        let height = DEFAULT_HEIGHT
        let width = DEFAULT_WIDTH
        return(
            <CardContainer style={{cursor: 'pointer'}}>
                {/* <MapCitation /> */}
                <div  className="svg-map-container" style={{height, width}}>
                    {
                        
                    }
                </div>
            </CardContainer>
        )
    }
}

export default withContext.bind(this, Card)

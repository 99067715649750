import React, { useState, useEffect } from 'react';
import {
    CarOutlined,
    NodeIndexOutlined,
    EditOutlined,
    DeleteOutlined,
    EyeOutlined,
    CheckOutlined,
    DashboardOutlined,
    SearchOutlined,
    PhoneOutlined,
    PaperClipOutlined,
    UploadOutlined,
    DownloadOutlined,
    CloudSyncOutlined
} from '@ant-design/icons';
import { Checkbox, Avatar, Card, Skeleton, Switch, Table, Select, Input, Button, Row, Upload, Col, Dropdown, Tag, Modal, Steps, message, Form, DatePicker, TimePicker, Descriptions, Image, Empty, Spin} from 'antd';
import {
    getAllActiveVehicleOwner,
    getAllMainRegions,
    getVehicleListFullExport,
    getSessionFullExport,
    getVehicleSummaryReport,
    getMonthlySummaryReport,
    getAccessTeamExportDataREWAMP,
    getAccessFullExportDataREWAMP,
    getSitesExportDataAccessFunction
} from "./../../api/index";
import './../master-data/style.css';
import xlsx from 'xlsx';
import jsonfile from 'jsonfile';
import fs from 'fs';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import { title } from 'process';

const { Option } = Select;
const { RangePicker } = DatePicker;

const SiteExport = () => {
    const [expoertCategory, setExportCategory] = useState('1');
    const [startAndEndDate, setStartAndEndDate] = useState([]);
    const [exportFormat, setExportFormat] = useState('1');
    const [regionList, setRegionList] = useState([]);
    const [vendorName, setVendorName] = useState([]);
    const [fetching, setFetching] = useState(false);
    const [searchData, setSearchData] = useState(null);
    const [selectedRegions, setSelectedRegions] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState([]);
    const [mappedData, setMappedData] = useState([]);
    const [buttonVisible, setButtonVisible] = useState(false);
    const [selectedSupplier, setSelectedSupplier] = useState([]);
    const [isStillLoading, setIsStillLoading] = useState(false);

    useEffect(() => {
        fetchAllSiteData();
    }, []);

    const fetchAllSiteData = async () => {
        setIsStillLoading(true);
        const response = await getSitesExportDataAccessFunction();
        if (response.success) {
            setSearchData(response?.data);
            const mapData = siteTopologyMap(response.data);
            setMappedData(mapData);
            setButtonVisible(true);
            setIsStillLoading(false);
        } else {
            message.error("Failed to fetch access data full export");
            setIsStillLoading(false);
        }
    }

    const prepareDownloadData = () => {
        const mapDataSet = mappedData;
        if (mapDataSet.length === 0) {
            message.error("No data to export");
            return;
        }
        if (expoertCategory === '1') {
            exportData(mapDataSet, "Site List_");
        } else if (expoertCategory === '2') {
            exportData(mapDataSet, "Site List_");
        } 
    }

    const  transformKey = (key) => {
        const words = key.split(/(?=[A-Z])/);
        const transformedKey = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        return transformedKey;
      }

    const allKeys = searchData?.reduce((keys, obj) => {
        Object.keys(obj).forEach(key => {
            if (!keys.includes(key)) {
                keys.push(key);
            }
        });
        return keys;
    }, []);


    const maxColumns = Math.min(7, allKeys?.length);
    const columns = allKeys?.slice(0, maxColumns).map(key => ({
        title: transformKey(key),
        dataIndex: key,
        key: key,
    }));

    const timeConversionToReadable = (inputTime) => {
        if (inputTime !== null || inputTime !== '') {
            return inputTime.replace("T", " ").slice(0, 19);
        } else {
            return "N/A";
        }
    }

    const siteTopologyMap = (apiData) => {
        return apiData.map(item => ({
            "Site Name": item?.site_name ? item.site_name : "N/A",
            "Site ID": item?.site_id ? item.site_id : "N/A",
            "Depot": item?.Depot ? item.Depot : "N/A",
            "Region": item?.Region ? item.Region : "N/A",
            "District": item?.District ? item.District : "N/A",
            "Energy Category": item?.Energy_Category ? item.Energy_Category : "N/A",
            "Site Category": item?.Site_Category ? item.Site_Category : "N/A",
            "Is Standby Generator Available": item?.is_standby_generator_available ? item.is_standby_generator_available : "N/A",
            "PG Possibility": item?.pg_possibility ? item.pg_possibility : "N/A",
            "Number of Phases": item?.no_of_phases ? item.no_of_phases : "N/A",
            "Shelter Type": item?.shelter_type ? item.shelter_type : "N/A",
            "Access Permission Group": item?.access_permissio_group ? item.access_permissio_group : "N/A",
            "Site Owner": item?.site_owner ? item.site_owner : "N/A",
            "Site Entity": item?.site_entity ? item.site_entity : "N/A",
            "Site Status": item?.site_status ? item.site_status : "N/A",
            "Site On Air Date": item?.site_on_air_date ? item.site_on_air_date : "N/A",
            "Site Elevation": item?.site_elevation ? item.site_elevation : "N/A",
            "Tower Category": item?.Tower_Category ? item.Tower_Category : "N/A",
            "Tower Type": item?.Tower_Type ? item.Tower_Type : "N/A",
            "Tower Owner Category": item?.tower_owner_category ? item.tower_owner_category : "N/A",
            "Tower Height": item?.tower_height ? item.tower_height : "N/A",
            "Tower Legs Count": item?.tower_legs_count ? item.tower_legs_count : "N/A",
            "PG Manual Distance": item?.pg_mannual_distance ? item.pg_mannual_distance : "N/A",
            "Refuel Manual Distance": item?.refuel_manual_distance ? item.refuel_manual_distance : "N/A",
            "Distance From Regional Office": item?.distance_from_regional_office ? item.distance_from_regional_office : "N/A",
            "Manual Transport": item?.manual_transport ? item.manual_transport : "N/A",
            "Longitude": item?.longitude ? item.longitude : "N/A",
            "Latitude": item?.latitude ? item.latitude : "N/A",
            "Address": item?.address ? item.address : "N/A",
            "Tenants": item?.tenants ? item.tenants : "N/A",
            "Power Shared Tenants": item?.power_shared_tenants ? item.power_shared_tenants : "N/A",
            "Non Shading Space Availability On Shelter": item?.non_shading_space_availability_onshelter ? item.non_shading_space_availability_onshelter : "N/A",
            "Non Shading Space Availability Ground": item?.non_shading_space_availability_ground ? item.non_shading_space_availability_ground : "N/A",
            "Dependent Cell Count": item?.dependent_cell_count ? item.dependent_cell_count : "N/A",
            "Is Idle Generator Hut Available": item?.is_idle_generator_hut_avaialable ? item.is_idle_generator_hut_avaialable : "N/A",
            "Is Space Available New Gen": item?.is_space_available_new_gen ? item.is_space_available_new_gen : "N/A",
            "Working Days Accessible Hours": item?.working_days_accessible_hours ? item.working_days_accessible_hours : "N/A",
            "Weekends or Holidays Accessible Hours": item?.weekends_or_holidays_accessible_hours ? item.weekends_or_holidays_accessible_hours : "N/A",
            "Access Permission Providing Party": item?.access_permission_providing_party ? item.access_permission_providing_party : "N/A",
            "Active Passive": item?.active_passive ? item.active_passive : "N/A",
            "Site Topology Terrain Mapping": item?.site_topology_terrain_mapping ? item.site_topology_terrain_mapping : "N/A",
            "Vehicle Type": item?.vehicle_type ? item.vehicle_type : "N/A",
            "Is Access Permission Required": item?.is_access_permission_required ? item.is_access_permission_required : "N/A",
            "Is Site Key Required": item?.is_site_key_required ? item.is_site_key_required : "N/A",
            "Gen Hut Type 1": item?.gen_hut_type1 ? item.gen_hut_type1 : "N/A",
            "Gen Hut Type 2": item?.gen_hut_type2 ? item.gen_hut_type2 : "N/A",
            "Cooling Methods": item?.cooling_methods ? item.cooling_methods : "N/A",
            "Indoor Outdoor Type": item?.indoor_outdoor_type ? item.indoor_outdoor_type : "N/A",
        }));
    }
    
    const column = [
        {
            title: <span className='textStyles-small'>Site ID</span>,
            dataIndex: 'site_id',
            key: 'site_id',
            render: (text) => (
                <span className='textStyles-small'>{text === ' ' ? <i>No Site ID</i> : text}</span>
            ),
        },
        {
            title: <span className='textStyles-small'>Site Name</span>,
            dataIndex: 'site_name',
            key: 'site_name',
            render: (text) => (
                <span className='textStyles-small'>{text === ' ' ? <i>No Site Name</i> : text}</span>
            ),
        },
        {
            title: <span className='textStyles-small'>Region</span>,
            dataIndex: 'Region',
            key: 'Region',
            render: (text) => (
                <span className='textStyles-small'>{text === ' ' ? <i>No Region</i> : text}</span>
            ),
        },
        {
            title: <span className='textStyles-small'>Depot</span>,
            dataIndex: 'Depot',
            key: 'Depot',
            render: (text) => (
                <span className='textStyles-small'>{text === ' ' ? <i>No Depot</i> : text}</span>
            ),
        },
        {
            title: <span className='textStyles-small'>District</span>,
            dataIndex: 'District',
            key: 'District',
            render: (text) => (
                <span className='textStyles-small'>{text === ' ' ? <i>No District</i> : text}</span>
            ),
        },
        {
            title: <span className='textStyles-small'>Site Category</span>,
            dataIndex: 'Site_Category',
            key: 'Site_Category',
            render: (text) => (
                <span className='textStyles-small'>{text === ' ' ? <i>No Site Category</i> : text}</span>
            ),
        },
        {
            title: <span className='textStyles-small'>Site Status</span>,
            dataIndex: 'site_status',
            key: 'site_status',
            render: (text) => (
                <span className='textStyles-small'>{text === ' ' ? <i>No Site Status</i> : text}</span>
            ),
        },
        {
            title: <span className='textStyles-small'>Tower Height</span>,
            dataIndex: 'tower_height',
            key: 'tower_height',
            render: (text) => (
                <span className='textStyles-small'>{text === ' ' ? <i>0</i> : text}</span>
            ),
        },
        {
            title: <span className='textStyles-small'>Address</span>,
            dataIndex: 'address',
            key: 'address',
            render: (text) => (
                <span className='textStyles-small'>{text === ' ' ? <i>No Address</i> : text}</span>
            ),
        }
    ]
    
    
    const exportData = (data, exportName) => {
        if (exportFormat === '1') {
            const worksheet = xlsx.utils.json_to_sheet(data);
            const workbook = xlsx.utils.book_new();
            xlsx.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            xlsx.writeFile(workbook, exportName+ new Date().toISOString().slice(0, 10) + '.xlsx');

        } else {
            const csv = Papa.unparse(data);
            const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
            saveAs(blob, exportName + new Date().toISOString().slice(0, 10) + '.csv');
        }
    }

    return (
        <body>
            <div style={{ margin: '0', padding: '10px', height: '100%' }}>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="page-title-box">
                            <div className="row">
                                <div className="col">
                                    <h4 className="page-title" style={{ fontWeight: "bold" }}>
                                        Site List Export
                                    </h4>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <a href="javascript:void(0);">Data Export </a>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <a href="javascript:void(0);">Site List Export</a>
                                        </li>
                                    </ol>
                                </div>
                                <div className="col-auto align-self-center">
                                    <a
                                        href="#"
                                        className="btn btn-sm btn-outline-primary"
                                        id="Dash_Date"
                                    >
                                        <span className="day-name" id="Day_Name">
                                            Today:
                                        </span>
                                        &nbsp;
                                        <span className="" id="Select_date">
                                            {new Date().toUTCString().slice(5, 16)}
                                        </span>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            className="feather feather-calendar align-self-center icon-xs ms-1"
                                        >
                                            <rect
                                                x="3"
                                                y="4"
                                                width="18"
                                                height="18"
                                                rx="2"
                                                ry="2"
                                            ></rect>
                                            <line x1="16" y1="2" x2="16" y2="6"></line>
                                            <line x1="8" y1="2" x2="8" y2="6"></line>
                                            <line x1="3" y1="10" x2="21" y2="10"></line>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{
                    backgroundColor: "#FFFFFF",
                    padding: "20px 20px 20px 20px",
                    borderRadius: "10px",
                    marginTop: "5px",
                }}>


                    <div style={{ padding: 10, borderRadius: 10 }}>
                        <Row>
                            <Col span={24}>
                                <Select placeholder="Select Export Format"
                                    className='borderedSelect'
                                    style={{ width: '220px', marginLeft: "10px" }}
                                    onChange={(value) => setExportFormat(value)}
                                    defaultValue={exportFormat}
                                    bordered={false}
                                >
                                    <Option value="1" className="textStyle-small">EXCEL</Option>
                                    <Option value="2" className="textStyle-small">CSV</Option>
                                </Select>

                                {
                                    buttonVisible ? (
                                        <Button
                                            className='textStyles-small'
                                            type="primary"
                                            style={{ marginLeft: "10px", width: "150px", borderRadius: 5, backgroundColor: "#52c41a", borderColor: "#52c41a" }}
                                            icon={<DownloadOutlined />}
                                            onClick={prepareDownloadData}
                                        >
                                            Export Site List
                                        </Button>
                                    ) : <></>
                                }

                            </Col>
                            <Col span={12}>
                                <div style={{ float: "right" }}>
                                </div>
                            </Col>
                        </Row>
                    </div> <br />

                    <Spin tip="Fetching Data..." size="large" spinning={isStillLoading}>
                        <Table
                            className="table-striped-rows"
                            dataSource={searchData === null ? [] : searchData}
                            columns={column}
                            loading={searchData === null ? fetching : false}
                            rowKey="key"
                            size="small"
                        />
                    </Spin>
                </div>
            </div>

        </body>

    )
}

export default SiteExport;
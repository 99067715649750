import React, { useState, useEffect } from "react";
import { Table, Input, Select, Button, Modal, Space, Tag, Tooltip, Row, Col, Form, Upload, message, Image, Spin } from "antd";
import {
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    CheckOutlined,
    UploadOutlined,
    CarOutlined,
    SearchOutlined
} from "@ant-design/icons";
import axios from "axios";
import { FilePdfOutlined, FileWordOutlined } from "@ant-design/icons";
import './style.css';
// import { getAllVehicles } from "../../api/index";
import { saveAs } from 'file-saver';
import { Parser } from 'json2csv';
import * as XLSX from 'xlsx';
import xlsx from 'xlsx';
import Papa from 'papaparse';
import {
    getAllVehiclesList,
    getAllActiveVehicleMaster,
    getAllVehicleType,
    getAllActiveVehicleSubType,
    getAllActiveVehicleOwner,
    getAllActiveVehiclePaymentCategory,
    getAllVendorPool,
    getAllRegion,
    getAllDrivers,
    registerNewVehicles,
    getSingleVehicleByID,
    updateVehicleByID,
    deleteVehicleByID,
    getAllFilteredVehicleMaster,
    getAllFilteredDrivers,

    getAllUsersAuth,
    getAllOperatingModel,
    deleteVehicleMaster,
    updateVehicleMaster,
    registerNewVehicleMaster,
    getNewVehicleMaster,
    getVehicleMasterByID,
    getDriversWithVehicleMastersAPI,
    getVehicleWithOutMasterID
} from "../../api/index";

import { exportToCSV, exportToExcel } from './dataPreprocessing';

const { Option } = Select;

const VehicleMasters = () => {
    const [dataSource, setDataSource] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [selectedVehicleType, setSelectedVehicleType] = useState("");
    const [visible, setVisible] = useState(false);
    const [open, setOpen] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [registeredVehicle, setRegisteredVehicle] = useState([]);
    const [searchData, setSearchData] = useState(null);


    const [vehicleType, setVehicleType] = useState([]);
    const [vehicleSubType, setVehicleSubType] = useState([]);
    const [vehicleOwner, setVehicleOwner] = useState([]);
    const [vehiclePaymentCategory, setVehiclePaymentCategory] = useState([]);
    const [vehicleMaster, setVehicleMaster] = useState([]);
    const [vehicleDriver, setVehicleDriver] = useState([]);
    const [region, setRegion] = useState([]);
    const [vendorPool, setVendorPool] = useState([]);
    const [selectedVehicle, setSelectedVehicle] = useState({});
    const [spinning, setSpinning] = React.useState(false);
    const [vehicleRegisterNumbers, setVehicleRegisterNumbers] = useState([]);

    const [vehicleMasters, setVehicleMasters] = useState([]);
    const [operatingModel, setOperatingModel] = useState([]);
    const [allAuthUsers, setAllAuthUsers] = useState([]);
    const [tvlOfficer, setTvlOfficer] = useState(false);
    const [selectedVehicleMaster, setSelectedVehicleMaster] = useState({});

    const [getAllVehiclesListData, setAllVehiclesList] = useState([]);

    const baseImgURL = "https://storage.googleapis.com/dns_storage_v2/dnsrevampimages/";


    useEffect(() => {
        // fetchAllVehicles();
        // fetchVehicleType();
        // fetchVehicleSubType();
        // fetchVehicleOwner();
        // fetchVehiclePaymentCategory();
        // fetchVehicleMaster();
        // fetchAllVendorPool();


        fetchAllRegions();
        fetchAllDrivers();
        fetchGetNewVehicleMaster();
        fetchGetAllUsersAuth();
        fetchOperatingModel();
        fetchAllVehicles();
        fetchAllVehiclesList();
    }, []);

    // init export functions here


    const fetchAllVehiclesList = async () => {
        const response = await getAllVehiclesList();
        if (response.success) {
            setAllVehiclesList(response.data.results);
        } else {
            message.error("Failed to fetch vehicles");
        }
    };

    const fetchGetNewVehicleMaster = async () => {
        const response = await getNewVehicleMaster();
        if (response.success) {
            setVehicleMasters(response.data.results);
        } else {
            message.error("Failed to fetch vehicle masters");
        }
    };

    const fetchAllRegions = async () => {
        const response = await getAllRegion();
        if (response.success) {
            console.log(response.data);
            setRegion(response.data.results);
        } else {
            console.log(response.error);
            message.error("Failed to fetch regions");
        }
    };

    const fetchAllDrivers = async () => {
        const response = await getAllFilteredDrivers();
        if (response.success) {
            setVehicleDriver(response.data.results);
        } else {
            message.error("Failed to fetch drivers");
        }
    };

    const fetchAllVehicles = async () => {
        const response = await getVehicleWithOutMasterID();
        if (response.success) {
            setRegisteredVehicle(response.data.results);
        } else {
            console.log(response.error);
            message.error("Failed to fetch vehicles");
        }
    };


    const fetchOperatingModel = async () => {
        const response = await getAllOperatingModel();
        if (response.success) {
            setOperatingModel(response.data.results);
        } else {
            console.log(response.error);
            message.error("Failed to fetch operating models");
        }
    };

    const fetchGetAllUsersAuth = async () => {
        const response = await getAllUsersAuth();
        if (response.success) {
            setAllAuthUsers(response.data.results);
        } else {
            console.log(response.error);
            message.error("Failed to fetch users");
        }
    };

    const fetchGetVehicleMasterByID = async (id) => {
        const response = await getVehicleMasterByID(id);
        if (response.success) {
            setSelectedVehicleMaster({});
            setSelectedVehicleMaster(response.data.results);
            setIsUpdate(true);
            setVisible(true);

        } else {
            message.error("Failed to fetch vehicle master");
        }
    };

    const fetchVehicleSubType = async () => {
        const response = await getAllActiveVehicleSubType();
        if (response.success) {
            console.log(response.data);
            setVehicleSubType(response.data.results);
        } else {
            console.log(response.error);
            message.error("Failed to fetch vehicle sub types");
        }
    };

    const fetchVehicleOwner = async () => {
        const response = await getAllActiveVehicleOwner();
        if (response.success) {
            console.log(response.data);
            setVehicleOwner(response.data.results);
        } else {
            console.log(response.error);
            message.error("Failed to fetch vehicle owners");
        }
    };

    const fetchVehiclePaymentCategory = async () => {
        const response = await getAllActiveVehiclePaymentCategory();
        if (response.success) {
            console.log(response.data);
            setVehiclePaymentCategory(response.data.results);
        } else {
            console.log(response.error);
            message.error("Failed to fetch vehicle payment categories");
        }
    };

    const fetchVehicleMaster = async () => {
        const response = await getAllFilteredVehicleMaster();
        if (response.success) {
            console.log(response.data);
            setVehicleMaster(response.data.results);
        } else {
            console.log(response.error);
            message.error("Failed to fetch vehicle masters");
        }
    };

    const fetchSelectedVehicle = async (id) => {
        const response = await getSingleVehicleByID(id);
        if (response.success) {
            console.log(response.data);
            setSelectedVehicle({});
            setSelectedVehicle(response.data.results);
            setIsUpdate(true);
            setVisible(true);
        } else {
            console.log(response.error);
            message.error("Failed to fetch vehicle");
        }
    };

    const openModel = (editStatus) => {
        setVisible(true);
        setIsUpdate(editStatus);
    };

    const handleCancel = (editStatus) => {
        setVisible(false);
        // setSelectedVehicle({});
        setSelectedVehicle({});
        setSelectedVehicleMaster({});
        setIsUpdate(editStatus);
    };

    const columns = [
        {
            title: <span className="textStyle">Vehicle Master Code</span>,
            dataIndex: "code",
            key: "code",
            render: (text) => {
                return <span className="textStyleChild">
                    {text ? text : "N/A"}
                </span>;
            }
        },
        {
            title: <span className="textStyle">Travelling Officer</span>,
            dataIndex: "travellingOfficer",
            key: "travellingOfficer",
            render: (text) => {
                return <span className="textStyleChild">
                    {text ? text : "N/A"}
                </span>;
            }
        },
        {
            title: <span className="textStyle">Vehicle Category</span>,
            dataIndex: "vehicleCategory",
            key: "vehicleCategory",
            render: (text) => {
                return <span className="textStyleChild">
                    {text ? text : "N/A"}
                </span>;
            }
        },
        {
            title: <span className="textStyle">Region</span>,
            dataIndex: "regionId",
            key: "regionId",
            render: (text, record) => {
                return <span className="textStyleChild">
                    {record?.regionId ? region.find((item) => item?.id === record?.regionId)?.name : "N/A"}
                </span>;
            }
        },
        {
            title: <span className="textStyle">Vehicle Running Purpose</span>,
            dataIndex: "vehicleRunningPurpose",
            key: "vehicleRunningPurpose",
            render: (text) => {
                return <span className="textStyleChild">
                    {text ? text : "N/A"}
                </span>;
            }
        },
        {
            title: <span className="textStyle">Mileage</span>,
            dataIndex: "mileage",
            key: "mileage",
            render: (text) => {
                return <span className="textStyleChild">
                    {text ? text : "N/A"}
                </span>;
            }
        },
        {
            title: <span className="textStyle">Actions</span>,
            dataIndex: "id",
            key: "id",
            render: (text, record) => (
                <>
                    <Button
                        type="circle"
                        style={{ backgroundColor: "#EBEFFF", width: "25px", height: "25px" }}
                        icon={<EditOutlined style={{ color: "#2C5EF4", fontWeight: "bold", fontSize: "10px" }} />}
                        onClick={() => fetchGetVehicleMasterByID(text)}
                        size="small"
                    />
                    <Button
                        type="circle"
                        style={{ backgroundColor: "#FEF2F3", marginLeft: 10, width: "25px", height: "25px" }}
                        icon={<DeleteOutlined style={{ color: "#FF0000", fontWeight: "bold", fontSize: "10px" }} />}
                        onClick={() => handleDelete(text)}
                        size="small"
                    />
                </>
            ),
        },
    ]

    const handleSearch = (selectedKeys, confirm) => {
        confirm();
        setSearchText(selectedKeys[0]);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };

    const handleEdit = (record) => {
        console.log("Edit", record);

        fetchSelectedVehicle(record);
        // Implement the logic to show a modal for editing
        // setVisible(true);
    };

    const deleteByID = async (id) => {
        const response = await deleteVehicleMaster(id);
        if (response.success) {
            message.success("Vehicle Master Deleted Successfully");
            fetchGetNewVehicleMaster();
        } else {
            message.error("Failed To Delete Vehicle Master");
        }
    }

    const handleDelete = (id) => {
        Modal.confirm({
            title: "Confirm Delete",
            content: `Are you sure you want to delete this vehicle master?`,
            onOk: () => {
                deleteByID(id);
            },
            onCancel: () => { },
        });
    };

    const handleNewRegisterVehicleMaster = (record) => {
        Modal.confirm({
            title: "Confirm Register",
            content: `Are you Sure You Want To register This Vehicle Master?`,
            onOk: () => {
                registerNewVehicleMasterData(record);
            },
            onCancel: () => { },
        });
    };

    const handleVehicleMasterUpdate = (vehicleID, record) => {
        Modal.confirm({
            title: "Confirm Update",
            content: `Are you Sure You Want To Update This Vehicle Master?`,
            onOk: () => {
                updateVehicleDetailsMasterByGivenID(vehicleID, record);
            },
            onCancel: () => { },
        });
    };

    const handleFormSubmit = (values) => {
        console.log("Received values of form: ", values);

        const travellingOfficer = values?.travellingOfficer ? values?.travellingOfficer : null;
        const tvlCat = travellingOfficer === null || travellingOfficer === undefined || travellingOfficer === "" ? "POOL" : "FIXED";

        if (isUpdate) {
            const vehicleRequest = {
                code: values.code,
                description: values.description,
                travellingOfficerId: values.travellingOfficer ? values.travellingOfficer : null,
                travellingOfficer: allAuthUsers?.filter((item) => item.id === values.travellingOfficer)[0]?.email,
                vehicleCategory: tvlCat,
                regionId: values.regionId,
                driverId: values.driverId,
                vehicleId: values.vehicleId,
                operatingModelId: values.operatingModelId ? values.operatingModelId : null,
                vehicleRunningPurpose: values.vehicleRunningPurpose ? values.vehicleRunningPurpose : 'N/A',
                mileage: values.mileage,
            }
            handleVehicleMasterUpdate(selectedVehicleMaster.id, vehicleRequest);

        } else {
            const vehicleRequest = {
                code: values.code,
                description: values.description,
                travellingOfficerId: values.travellingOfficer ? values.travellingOfficer : null,
                travellingOfficer: tvlOfficer ? tvlOfficer : null,
                vehicleCategory: tvlCat,
                regionId: values.regionId,
                driverId: values.driverId,
                vehicleId: values.vehicleId,
                operatingModelId: values.operatingModelId ? values.operatingModelId : null,
                vehicleRunningPurpose: values.vehicleRunningPurpose ? values.vehicleRunningPurpose : 'N/A',
                mileage: values.mileage,
            }

            handleNewRegisterVehicleMaster(vehicleRequest);
        }
    };

    const registerNewVehicleMasterData = async (values) => {
        const response = await registerNewVehicleMaster(values);
        if (response.success) {
            message.success("New vehicle registered successfully");
            fetchGetNewVehicleMaster();
            handleCancel(false);
        } else {
            const errorMessage = response.data.errors[0].errorMessage;
            message.error(errorMessage);
        }
    }

    const updateVehicleDetailsMasterByGivenID = async (registerID, values) => {
        const response = await updateVehicleMaster(registerID, values);
        if (response.success) {
            message.success("Vehicle Master Updated Successfully");
            fetchAllVehicles();
            handleCancel(false);
            fetchGetNewVehicleMaster();
        } else {
            const errorMessage = response.data.errors[0].errorMessage;
            message.error(errorMessage);
        }
    }

    const searchDetailsByID = (value) => {
        if (value === "") {
            setSearchData(vehicleMasters);
        } else {
            const filteredData = vehicleMasters?.filter((session) => {
                return session?.code?.toLowerCase().includes(value?.toLowerCase());
            });
            setSearchData(filteredData);
        }
    };

    // miles to km convert function
    const milesToKm = (miles) => {
        return miles * 1.60934;
    }

    const addThousandSeparators = (number) => {
        if (typeof number !== 'number') {
            return number;
        }
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };
    
    const vehicleMasterExportDataMap = (apiData) => {
        const exportData = apiData.map(item => ({
            "Vehicle Master Code": item?.code ? item.code : "N/A",
            "Is Active": item?.active ? item.active ? "Active" : "Inactive" : "N/A",
            "Travelling Officer": item?.travellingOfficer ? item.travellingOfficer : "N/A",
            "Vehicle Category": item?.vehicleCategory ? item.vehicleCategory : "N/A",
            "Region": item?.regionId ? region.find((region) => region.id === item.regionId)?.name : "N/A",
            "Driver Name": item?.driverName ? item.driverName : "N/A",
            "Driver Contact": item?.driverContact ? item.driverContact : "N/A",
            "Vehicle Model": item?.vehicleId ? getAllVehiclesListData?.find((vehicle) => vehicle.id === item.vehicleId)?.model : "N/A",
            "Vehicle Brand": item?.vehicleId ? getAllVehiclesListData?.find((vehicle) => vehicle.id === item.vehicleId)?.brand : "N/A",
            "Vehicle Type": item?.vehicleId ? getAllVehiclesListData?.find((vehicle) => vehicle.id === item.vehicleId)?.vehicleType?.name : "N/A",
            "Vehicle Registration Number": item?.vehicleId ? getAllVehiclesListData?.find((vehicle) => vehicle.id === item.vehicleId)?.registrationNumber : "N/A",
            "Operating Model": item?.operatingModelId ? item.operatingModelId : "N/A",
            "Vehicle Running Purpose": item?.vehicleRunningPurpose ? item.vehicleRunningPurpose : "N/A",
            "Mileage": item?.mileage ? addThousandSeparators(item.mileage) : "0",
        }));
        return exportData;
    };

    const exportData = (data, exportName, exportFormat) => {
        if (exportFormat === 1) {
            const worksheet = xlsx.utils.json_to_sheet(data);
            const workbook = xlsx.utils.book_new();
            xlsx.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            xlsx.writeFile(workbook, exportName+ new Date().toISOString().slice(0, 10) + '.xlsx');

        } else {
            const csv = Papa.unparse(data);
            const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
            saveAs(blob, exportName + new Date().toISOString().slice(0, 10) + '.csv');
        }
    }

    // const prepareDownloadData = (expoertCategory) => {
    //     const mapDataSet = mappedData;
    //     if (mapDataSet.length === 0) {
    //         message.error("No data to export");
    //         return;
    //     }
    //     if (expoertCategory === '1') {
    //         exportData(mapDataSet, "Session Full Export_");
    //     } else if (expoertCategory === '2') {
    //         exportData(mapDataSet, "Vehicle Full Export_");
    //     } else if (expoertCategory === '3') {
    //         exportData(mapDataSet, "Monthly Payment Summary_");
    //     } else {
    //         exportData(mapDataSet, "Monthly Vehicle Summary_");
    //     }
    // }

    const handleExportCSV = () => {
        exportToCSV(vehicleMasters);
      };
    
      const handleExportExcel = () => {
        exportToExcel(vehicleMasters);
      };

    return (
        <body>
            <div style={{ margin: '0', padding: '10px', height: '100%' }}>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="page-title-box">
                            <div className="row">
                                <div className="col">
                                    <h4 className="page-title" style={{ fontWeight: "bold" }}>
                                        Vehicle Master
                                    </h4>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <a href="javascript:void(0);">Vehicle </a>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <a href="javascript:void(0);">Vehicle Master</a>
                                        </li>
                                    </ol>
                                </div>
                                <div className="col-auto align-self-center">
                                    <a
                                        href="#"
                                        className="btn btn-sm btn-outline-primary"
                                        id="Dash_Date"
                                    >
                                        <span className="day-name" id="Day_Name">
                                            Today:
                                        </span>
                                        &nbsp;
                                        <span className="" id="Select_date">
                                            {new Date().toUTCString().slice(5, 16)}
                                        </span>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            className="feather feather-calendar align-self-center icon-xs ms-1"
                                        >
                                            <rect
                                                x="3"
                                                y="4"
                                                width="18"
                                                height="18"
                                                rx="2"
                                                ry="2"
                                            ></rect>
                                            <line x1="16" y1="2" x2="16" y2="6"></line>
                                            <line x1="8" y1="2" x2="8" y2="6"></line>
                                            <line x1="3" y1="10" x2="21" y2="10"></line>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal
                    title={<div>
                        <div style={{ backgroundColor: "#FFFFFF" }}>
                            <b style={{ color: "black" }}>Vehicle Master Form</b>
                            <br />
                            <span style={{ fontSize: "12px", color: "##9D9B9B" }}>
                                475, Union Place, Colombo 02
                            </span>
                        </div>
                    </div>}
                    visible={visible}
                    onCancel={() => handleCancel(false)}
                    closable={false}
                    footer={null}
                    width={850}
                    destroyOnClose={true}
                    icon={null}
                >
                    <div>
                        <Spin spinning={spinning} fullscreen>
                            <Form
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                layout="vertical"
                                onFinish={handleFormSubmit}
                            >
                                <div style={{ marginLeft: "10px", overflow: 'auto', height: '500px' }}>
                                    <Row>
                                        <Col span={12}>
                                            <Form.Item
                                                label={<span style={{ fontWeight: "bold" }}>Vehicle Master Code</span>}
                                                name="code"
                                                rules={[{ required: true, message: 'Please input vehicle master code!' }]}
                                                initialValue={selectedVehicleMaster.code ? selectedVehicleMaster.code : null}
                                            >
                                                <Input
                                                    placeholder="Type Vehicle Master Code" style={{ width: "90%" }}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col span={12}>
                                            <Form.Item
                                                label={<span style={{ fontWeight: "bold" }}>Fixed User</span>}
                                                name="travellingOfficer"
                                                initialValue={selectedVehicleMaster.travellingOfficerId ? selectedVehicleMaster.travellingOfficerId : null}
                                            >
                                                <Select
                                                    style={{ width: "90%" }}
                                                    showSearch
                                                    placeholder="Select Fixed User"
                                                    onChange={(value) => setTvlOfficer(value)}
                                                >
                                                    <Option value={null} key={null}>Unassign</Option>
                                                    {allAuthUsers.map((item) => (
                                                        <Option value={item.id} key={item.id}>
                                                            {item.email}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col span={12}>
                                            <Form.Item
                                                label={<span style={{ fontWeight: "bold" }}>Vehicle Driver</span>}
                                                name="driverId"
                                                initialValue={selectedVehicleMaster.driverId ? selectedVehicleMaster.driverId : ''}
                                            >
                                                <Select placeholder="Select Vehicle Driver" style={{ width: "90%" }}>
                                                <Option value={null} key={null}>Unassign</Option>
                                                {
                                                    isUpdate == true ?(
                                                        <Option value={selectedVehicleMaster?.driverId} key={selectedVehicleMaster?.driverId}> {selectedVehicleMaster?.driverName} </Option>
                                                    ) : (<></>)
                                                }
                                                    {
                                                        vehicleDriver.map((item) => (
                                                            <Option value={item.id} key={item.id}>{item.firstName + " " + item.lastName}</Option>
                                                        ))
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col span={12}>
                                            <Form.Item
                                                label={<span style={{ fontWeight: "bold" }}>Region Name</span>}
                                                name="regionId"
                                                rules={[{ required: true, message: 'Please Select Region Name!' }]}
                                                initialValue={selectedVehicleMaster.regionId ? selectedVehicleMaster.regionId : null}
                                            >
                                                <Select placeholder="Select Region Name" style={{ width: "90%" }}>
                                                    {
                                                        region?.map((item) => (
                                                            <Option value={item.id} key={item.id}>{item.name}</Option>
                                                        ))
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}>
                                            <Form.Item
                                                label={<span style={{ fontWeight: "bold" }}>Vehicle ID</span>}
                                                name="vehicleId"
                                                initialValue={selectedVehicleMaster.vehicleId ? selectedVehicleMaster.vehicleId : ''}
                                            >
                                                <Select
                                                    showSearch
                                                    placeholder="Select Vehicle ID"
                                                    style={{ width: "90%" }} 
                                                >
                                                    <Option value={null} key={null}>Unassign</Option>
                                                    {
                                                        isUpdate == true ?(
                                                            <Option value={selectedVehicleMaster?.vehicleId} key={selectedVehicleMaster?.vehicleId}> 
                                                            {selectedVehicleMaster?.regNumber} 
                                                            </Option>
                                                        ) : (<></>)
                                                    }
                                                    {
                                                        registeredVehicle?.map((item) => (
                                                            <Option value={item?.id} key={item?.id}>{item?.registrationNumber}</Option>
                                                        ))
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label={<span style={{ fontWeight: "bold" }}>Operating Model</span>}
                                                name="operatingModelId"
                                                initialValue={selectedVehicleMaster.operatingModelId ? selectedVehicleMaster.operatingModelId : null}
                                            >
                                                <Select
                                                    showSearch
                                                    placeholder="Select Operating Model"
                                                    style={{ width: "90%" }}
                                                >
                                                    {
                                                        operatingModel?.map((item) => (
                                                            <Option value={item.id} key={item.id}>{item.name}</Option>
                                                        ))
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col span={12}>
                                            <Form.Item
                                                label={<span style={{ fontWeight: "bold" }}>Vehicle Running Purpose</span>}
                                                name="vehicleRunningPurpose"
                                                initialValue={selectedVehicleMaster.vehicleRunningPurpose ? selectedVehicleMaster.vehicleRunningPurpose : null}
                                            >
                                                <Input placeholder="Type Vehicle Running Purpose" style={{ width: "90%" }} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label={<span style={{ fontWeight: "bold" }}>Description</span>}
                                                name="description"
                                                initialValue={selectedVehicleMaster?.description ? selectedVehicleMaster?.description : null}
                                            >
                                                <Input placeholder="Type Description" style={{ width: "90%" }} />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col span={12}>
                                        <Form.Item
                                            label={<span style={{ fontWeight: "bold" }}>Commited Mileage</span>}
                                            name="mileage"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input commited mileage!'
                                                },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (value >= 0) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error('Please input a non-negative value for commited mileage!'));
                                                    },
                                                }),
                                            ]}
                                            initialValue={selectedVehicleMaster.mileage ? selectedVehicleMaster.mileage : 0}
                                        >
                                            <Input placeholder="Type Commited Mileage" 
                                            style={{ width: "90%" }} 
                                            type="number" />
                                        </Form.Item>
                                        </Col>
                                    </Row>

                                </div>

                                <Row>
                                    <Col span={24} style={{ textAlign: 'right', marginRight: '100px', marginTop: '40px' }}>
                                        <Form.Item>
                                            {
                                                isUpdate == false ?
                                                    <Button type="primary" htmlType="submit"
                                                        style={{ marginLeft: '10px', borderRadius: '5px', width: '180px', backgroundColor: '#2D5FF4', borderColor: '#2D5FF4' }}>
                                                        Register Vehicle Master
                                                    </Button> :
                                                    <Button type="primary" htmlType="submit"
                                                        style={{ marginLeft: '10px', borderRadius: '5px', width: '180px', backgroundColor: '#2D5FF4', borderColor: '#2D5FF4' }}>
                                                        Update Vehicle Master
                                                    </Button>
                                            }
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Spin>
                    </div>
                </Modal>

                <div style={{
                    backgroundColor: "#FFFFFF",
                    padding: "20px 20px 20px 20px",
                    borderRadius: "10px",
                    marginTop: "5px",
                }}>

                    <div style={{ padding: 10, borderRadius: 10 }}>
                        <Row>
                            <Col span={12}>
                                <Input
                                    placeholder="Search By Master Code"
                                    prefix={<SearchOutlined />}
                                    className="textStyle-small"
                                    style={{ width: 200, marginRight: 10, borderRadius: 5, borderColor: '#A9A9A9', height: 35 }}
                                    onChange={(e) => { searchDetailsByID(e.target.value) }}
                                />
                            </Col>
                            <Col span={12}>
                                <div className="alignRight">
                                    <Select
                                        className='borderedSelect'
                                        bordered={false}
                                        allowClear
                                        style={{ width: '220px', height: '35px'}}
                                        placeholder="Export As"
                                        onChange={(value) => {
                                            if (value === 1) {
                                                exportData(vehicleMasterExportDataMap(vehicleMasters), "Vehicle Master Export_", 1);
                                            } else {
                                                exportData(vehicleMasterExportDataMap(vehicleMasters), "Vehicle Master Export_", 2);
                                            }
                                        }}
                                    >
                                        <Select.Option value={2}><span className="textStyles-small">CSV Export</span></Select.Option>
                                        <Select.Option value={1}><span className="textStyles-small">Excel Export</span></Select.Option>
                                    </Select>
                                    <Button
                                        style={{ marginLeft: 5, borderRadius: "5px", backgroundColor: "#00AC4F", width: "180px", height: "35px" }}
                                        onClick={() => openModel(false)}>
                                        <span className="textStyles-small"
                                            style={{ color: "white", marginLeft: 2 }}>
                                            New Registration
                                        </span>
                                    </Button>
                                </div>
                            </Col>

                        </Row>
                    </div> <br />

                    <Table
                        className="table-striped-rows"
                        dataSource={searchData === null ? vehicleMasters : searchData}
                        columns={columns}
                        loading={vehicleMasters.length === 0 ? true : false}
                        rowKey="key"
                        size="small"
                    />
                </div>
            </div>
        </body>
    );
};

export default VehicleMasters;




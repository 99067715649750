import { useCallback } from 'react';
import { Handle, Position } from 'react-flow-renderer';

const handleStyle = { top: 10 };
const handleStyle2 = { top: 20 };

function TextUpdaterNode({ data, isConnectable }) {
  const onChange = useCallback((evt) => {
    console.log(evt.target.value);
  }, []);

  return (
    <div className="text-updater-node">
      <Handle type="target" position={Position.Left} isConnectable={isConnectable} />
     
   
       <Handle
        type="source"
        position={Position.Right}
        id="c"
        style={handleStyle2}
        isConnectable={isConnectable}
      />
     
    </div>
  );
}

export default TextUpdaterNode;

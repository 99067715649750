import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
//import { createNewOrganization, deleteOrganization, updateOrganizationDetails } from '../../app/api/index';
//import { addOrganization, removeOrganization, updateOrganization } from '../../app/actions/index';
import { createNewOrganization, UpdateOrganizationByGivenID } from '../../app/api/index';
import { addOrganization, getDonationCategories, getData } from '../../app/actions/index';
import { connect } from 'react-redux';
import { ClearOutlined } from '@ant-design/icons';
import { Collapse, Select, Button, Tag, message, Row, Col, Form, Modal, Input, Result, Table, Drawer, Menu, Dropdown } from 'antd';
import { ExclamationCircleOutlined, DeleteOutlined, EditOutlined, PlusCircleFilled, MoreOutlined } from '@ant-design/icons';
import './master-data/style.css';

class Organization extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      name: '',
      description: '',
      pageSize: 10,
      current: 1,
      searchText: '',
      visible: false,
      editDrawerVisible: false,
      filterTable: null,
      organizationList: [],
      editOrganizationDetails: {
        id: '',
        name: '',
        description: '',
      },
      isEdit: true,
      isOpenModal: false,
    };
  }



  sucessMessage = (messageInfo) => {
    message.success('System Message : ' + messageInfo);
  };

  errorMessage = (messageInfo) => {
    message.error('System Message : ' + messageInfo);
  };

  warningMessage = (messageInfo) => {
    message.warning('System Message : ' + messageInfo);
  };

  formItemChange = (event) => {
    var obj = {};
    obj[event.target.name] = event.target.value;
    this.setState(obj);
  };

  showConfirmModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  createOrganization = () => {
    this.showConfirmModal();
  };

  createOrganizationLogic = async () => {
    console.log(this.state);
    if (this.validator.allValid()) {
      var data = {
        name: this.state.name,
        description: this.state.description,
      };
      var res = await createNewOrganization(data);
      if (res.success) {
        this.props.getData();
        //this.props.addOrganization(res.data.data);
        this.setState({
          name: '',
          description: '',
          isOpenModal: false,
          isEdit: false,
        }, () => {

        });
        this.sucessMessage('Organization created successfully');
      }
    } else {
      this.validator.showMessages();
      this.errorMessage("Please fill all the required fields");
      this.forceUpdate();
    }
  };

  handleOk = () => {
    this.createOrganizationLogic();
    this.handleCancel();
  };

  handleTableChange = (pagination) => {
    this.setState({
      current: pagination.current,
    });
  };


  search = (value) => {
    const filterTable = this.state.organizationList.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(value.toLowerCase())
      )
    );
    this.setState({ filterTable });
  };

  showUpdateConfirm = () => {
    const { confirm } = Modal;
    confirm({
      title: 'Do you want to Update this Organization? ',
      onOk: () => {
        this.updateOrganization();
      },
      onCancel() { },
    });
  };

  showEditDrawer = (record) => {
    this.setState({
      editDrawerVisible: true,
      name: record.name,
      description: record.description,
      editOrganizationDetails: {
        id: record.id,
        name: record.name,
        description: record.description,
      },
    });
  };

  closeEditDrawer = () => {
    this.setState({
      editDrawerVisible: false,
      name: '',
      description: '',
      editOrganizationDetails: {
        id: '',
        name: '',
        description: '',
      },
    });
  };

  handleEditFormItemChange = (event, fieldName) => {
    const { value } = event.target;
    this.setState((prevState) => ({
      editOrganizationDetails: {
        ...prevState.editOrganizationDetails,
        [fieldName]: value,
      },
    }));

    var obj = {};
    obj[event.target.name] = event.target.value;
    this.setState(obj);
    console.log(this.state.obj)
  };

  updateOrganization = async () => {
    if (this.validator.allValid()) {
      const res = await UpdateOrganizationByGivenID(this.state.editOrganizationDetails, this.state.editOrganizationDetails.id);
      if (res.success) {
        this.props.getData();
        this.setState({
          editDrawerVisible: false,
        });
        this.setState({
          name: '',
          description: '',
          isOpenModal: false,
          isEdit: false,
        }, () => {

        });
        this.closeEditDrawer();
        this.sucessMessage('Organization updated successfully');

      } else {
        this.errorMessage('Failed to update organization');
      }
    } else {
      this.validator.showMessages();
      this.errorMessage(this.validator.getErrorMessages());
      this.forceUpdate();
    }
  }

  componentDidMount() {
    this.setState({
      organizationList: this.props.organization
    })
  }

  render() {
    const { organization } = this.props;
    const { pageSize, current, searchText, editDrawerVisible } = this.state;

    const escapeRegExp = (text) => {
      return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    };

    const wildcardSearch = (input, searchText) => {
      const escapedSearchText = escapeRegExp(searchText);
      const regex = new RegExp(escapedSearchText.replace(/%/g, '.*'), 'i');

      return regex.test(input);
    };

    const filteredData = organization.filter(
      (item) =>
        wildcardSearch(item.name, searchText) ||
        wildcardSearch(item.description, searchText)
    );

    const columns = [
      {
        title: <span className="textStyles-small">Organization Name</span>,
        dataIndex: 'name',
        key: 'name',
        render: (text, record) => <span className="textStyles-small">{record.name}</span>,
      },
      {
        title: <span className="textStyles-small">Description</span>,
        dataIndex: 'description',
        key: 'description',
        render: (text, record) => <span className="textStyles-small">{record.description}</span>,
      },
      {
        title: <span className="textStyles-small">Action</span>,
        key: 'action',
        render: (text, record, index) => (
          <span className="text-end">
            {/* <a href="#" onClick={() => this.showEditDrawer(record)}>
              <i className="las la-pen text-secondary font-16"></i>
            </a> */}
            {/*<a href="#" onClick={() => this.showDeleteConfirm(record)}>
              <i className="las la-trash-alt text-secondary font-16"></i>
        </a>*/}
            <Button
              shape='circle'
              size='small'
              type='primary'
              icon={<EditOutlined style={{ fontSize: '14px' }} />}
              style={{ marginLeft: '10px' }}
              onClick={() => {
                this.showEditDrawer(record);
                this.setState({
                  isEdit: true,
                  isOpenModal: true,
                });
              }}
            />

            {/*<Button
              shape='circle'
              size='small'
              type='primary'
              danger
              icon={<DeleteOutlined style={{ fontSize: '14px' }} />}
              style={{ marginLeft: '10px' }}
            />*/}
          </span>
        ),
      },
    ];

    return (
      <div className="container-fluid">
        <div className="row">

          <div className="row">
            <div className="col-sm-12">
              <div className="page-title-box">
                <div className="row">
                  <div className="col">
                    <h4 className="page-title">Organization</h4>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="javascript:void(0);">Admin</a>
                      </li>
                      <li className="breadcrumb-item">
                        <a href="javascript:void(0);">General</a>
                      </li>
                    </ol>
                  </div>
                  <div className="col-auto align-self-center">
                    <a
                      href="#"
                      className="btn btn-sm btn-outline-primary"
                      id="Dash_Date"
                    >
                      <span className="day-name" id="Day_Name">
                        Today:
                      </span>
                      &nbsp;
                      <span
                        className=""
                        id="Select_date"
                      >
                        {new Date().toUTCString().slice(5, 16)}
                      </span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-calendar align-self-center icon-xs ms-1"
                      >
                        <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                        <line x1="16" y1="2" x2="16" y2="6"></line>
                        <line x1="8" y1="2" x2="8" y2="6"></line>
                        <line x1="3" y1="10" x2="21" y2="10"></line>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">

            <div style={{ margin: '0', padding: '10px', width: '100%', backgroundColor: 'white', borderRadius: '10px', marginTop: '20px' }}>
              <Row>
                <Col span={12}>
                  <Button type="primary" onClick={() => this.setState({ isOpenModal: true, isEdit: false })}
                    style={{ background: "#38B36E", borderColor: "#38B36E", borderRadius: '5px', margin: 10 }}><PlusCircleFilled />
                    <span className="textStyles-small">New Organization</span></Button>
                </Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                  <Input
                    className='borderedSelect' style={{ height: 33, margin: 10, marginRight: 10, width: 350 }}
                    placeholder="Search organization"
                    onChange={(e) => {
                      const currValue = e.target.value;
                      this.search(currValue);
                    }}
                  />
                </Col>
              </Row>
              <Table
                size="small"
                className="table-striped-rows"
                columns={columns}
                dataSource={this.state.filterTable != null ? [...this.state.filterTable].sort((a, b) => b.id - a.id) :
                  [...this.props.organization].sort((a, b) => b.id - a.id)}
                pagination={{
                  pageSize,
                  current,
                  total: filteredData.length,
                }}
                onChange={this.handleTableChange}
              />
            </div>

            <Modal
              title="Confirmation"
              visible={this.state.visible}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
            > <p>Do you want to create a new organization?</p> </Modal>

            <Modal
              title={this.state.isEdit ? <span style={{ fontSize: 14 }} className='textStyles-small'>Edit Organization</span> : <span style={{ fontSize: 14 }} className='textStyles-small'>Create New Organization</span>}
              visible={this.state.isOpenModal}
              // visible={true}
              onOk={() => {this.setState({ isOpenModal: false, isEdit: false });
            this.closeEditDrawer();
            }}
              onCancel={() => {this.setState({ isOpenModal: false, isEdit: false });
            this.closeEditDrawer();
            }}
              footer={null}
            >
              {
                this.state.isEdit ? (
                  <>
                    <form>
                      <div className="mb-3">
                        <label className="form-label" htmlFor="name">
                          <span className="textStyles-small" style={{ fontSize: 12 }}>Organization Name</span>
                        </label>
                        <input
                          autoComplete="off"
                          type="text"
                          className="borderedSelect"
                          style={{ height: 34, width: '98%' }}
                          name="name"
                          value={this.state.name}
                          aria-describedby="emailHelp"
                          onChange={(e) => this.handleEditFormItemChange(e, 'name')}
                        />
                        <p style={{ color: '#f5325c' }}>
                          {this.validator.message(
                            'name',
                            this.state.name,
                            'required'
                          )}
                        </p>
                      </div>
                      <div className="mb-3">
                        <label className="form-label" htmlFor="description">
                          <span className="textStyles-small" style={{ fontSize: 12 }}>Organization Description</span>
                        </label>
                        <input
                          autoComplete="off"
                          type="text"
                          value={this.state.description}
                          className="borderedSelect"
                          style={{ height: 34, width: '98%' }}
                          name="description"
                          onChange={(e) => this.handleEditFormItemChange(e, 'description')}
                        />
                        <p style={{ color: '#f5325c' }}>
                          {this.validator.message(
                            'description',
                            this.state.description,
                            'required'
                          )}
                        </p>
                      </div>
                    </form>
                    {/* <button className="btn btn-primary" onClick={() => this.showUpdateConfirm()}>
            <span className="textStyles-small" style={{ fontSize: 12 }}>Update Organization</span>
            </button> */}
                    <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <button type="submit" className="btn btn-primary"
                        style={{ background: "#38B36E", borderColor: "#38B36E", borderRadius: '5px', marginTop: 10 }}
                        onClick={() => this.showUpdateConfirm()}>
                        <span className="textStyles-small">Update Organization</span>
                      </button>
                    </Row>
                  </>
                ) : (
                  <div>
                    <form>
                      <div className="mb-3">
                        <label className="form-label" htmlFor="name">
                          <span className="textStyles-small" style={{ fontSize: 12 }}>Organization Name</span>
                        </label>
                        <input
                          autoComplete="off"
                          type="text"
                          className="borderedSelect"
                          style={{ height: 34, width: '98%' }}
                          name="name"
                          value={this.state.name}
                          aria-describedby="emailHelp"
                          onChange={(e) => this.formItemChange(e)}
                        />
                        <p style={{ color: '#f5325c' }}>
                          {this.validator.message('name', this.state.name, 'required')}
                        </p>
                      </div>
                      <div className="mb-3">
                        <label className="form-label" htmlFor="description">
                          <span className="textStyles-small" style={{ fontSize: 12 }}>Organization Description</span>
                        </label>
                        <input
                          autoComplete="off"
                          type="text"
                          value={this.state.description}
                          className="borderedSelect"
                          style={{ height: 34, width: '98%' }}
                          name="description"
                          onChange={(e) => this.formItemChange(e)}
                        />
                        <p style={{ color: '#f5325c' }}>
                          {this.validator.message(
                            'description',
                            this.state.description,
                            'required'
                          )}
                        </p>
                      </div>
                    </form>
                    <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <button type="submit" className="btn btn-primary"
                        style={{ background: "#38B36E", borderColor: "#38B36E", borderRadius: '5px', marginTop: 10 }}
                        onClick={() => this.createOrganization()}>
                        <span className="textStyles-small">Create Organization</span>
                      </button>
                    </Row>
                  </div>
                )
              }
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    organization: state.organization,
  };
}

/*function mapDispatchToProps(dispatch) {
  return {
    addOrganization: (data) => dispatch(addOrganization(data)),
    //removeOrganization: (id) => dispatch(removeOrganization(id)),
    //updateOrganization: (data) => dispatch(updateOrganization(data)),
  };
}*/

export default connect(mapStateToProps, { getDonationCategories, getData })(
  Organization
);
import React, { Component } from 'react';
import { PgCountByStatusAndRegion } from "../api/index";
import { Column } from '@ant-design/plots';


class DashboardPgPendingStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pending_pg:[],
         
        };
      }
      getPgCountByStatusAndRegion = async () => {

        var res = await PgCountByStatusAndRegion();

    if (res.success) {
        //console.log(res.data);
        this.createDataWithPins(res?.data)
    }


      }

      createDataWithPins(data){
          //console.log("edit")
        
        const arrayResult = Object.keys(data).map(room => {
          return {data: data[room].countByRegionDto.map(e=>({
            ...e, status:data[room].status
          })) } 
      });  
      //console.log(arrayResult)
      var alldata=arrayResult.reduce((perv,cur)=>[...perv,...cur.data],[])
    //console.log("alldata");
    //console.log(alldata);
      this.setState({
       // daily_regoin_cost:alldata,
       pending_pg:alldata
      })
     
      }
      componentDidMount() {
        //this.getMapPgRequest();
        this.getPgCountByStatusAndRegion();
    
      }
    render() {
        const configRegoinCost = {
            data:this.state.pending_pg,
            isStack: true,
            xField: 'status',
            yField: 'count',
            seriesField: 'parentRegion',
            xAxis: {
                label: {
                  autoRotate: true,
                },
              },
            label: {
              // 可手动配置 label 数据标签位置
              position: 'middle',
              // 'top', 'bottom', 'middle'
              // 可配置附加的布局方法
              layout: [
                // 柱形图数据标签位置自动调整
                {
                  type: 'interval-adjust-position',
                }, // 数据标签防遮挡
                {
                  type: 'interval-hide-overlap',
                }, // 数据标签文颜色自动调整
                {
                  type: 'adjust-color',
                },
              ],
            },
          };
        return (
            <div>
            <div class="container-fluid">
            <div class="row">
              <div class="col-sm-12">
                <div class="page-title-box">
                  <div class="row">
                    <div class="col">
                      <h4 class="page-title">PG Status</h4>
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Dashboard</a></li>
                        <li class="breadcrumb-item"><a href="javascript:void(0);">PG</a></li>

                      </ol>
                    </div>
                    <div class="col-auto align-self-center">
                      <a href="#" class="btn btn-sm btn-outline-primary" id="Dash_Date">
                        <span class="day-name" id="Day_Name">Today:</span>&nbsp;
                        <span class="" id="Select_date">{new Date().toUTCString().slice(5, 16)}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar align-self-center icon-xs ms-1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                      </a>

                    </div>
                  </div>
                </div>
              </div>
            </div>
                <div class="row">
                        <div className="col-md-12 col-lg-12">
                            <div className="card">
                                
                                <div className="card-body">
                                    <Column {...configRegoinCost}
                                    loading={this.state.get_cost_by_parent_region_loading}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            
            </div>
        );
    }
}

export default DashboardPgPendingStatus;

import React, { Component } from "react";
import { Table, Input, Tag, Space, DatePicker, Select, Col, Row, Button } from "antd";
import { SearchOutlined, DownCircleOutlined } from '@ant-design/icons';
import ReactExport from "react-data-export";
import { getPisScServiceFullExport, getUserSites } from "../../api/index";
import { getDonationCategories, getData } from "../../actions/index";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'
import './../master-data/style.css';

import ReactLoading from 'react-loading';
import { validateBoolValue, validateDateTimeFormat, validateIntValue, validateModle, validateStringDateTimeFormat, validateStringValue } from "../../actions/validators";
import { DATE, DATE_TIME, TIME } from "../../constants/action-types";
const moment = require('moment');


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const { RangePicker } = DatePicker;
const { Option } = Select;

class PisScServiceFullExport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      filterTable: null,
      data_list: [],
      startDate: "",
      endDate: "",
      loading: false,
      allSite: [],
      depotList: [],
      regionsList: [],
      regionIds: '',
      depotIds: '',
      siteIds: '',
      allSite_loading: false,
      buttonLodading: false,
      fullArray: [],
      recodesPerPage1: 1000,

    };
  }

  componentDidMount() {
    const serviceTypeSet = JSON.parse(localStorage.getItem('serviceTypeSet'));
    if (serviceTypeSet === null) {
        const { history } = this.props;
        history.push("/");
    } else {
        // Ignore validation if serviceTypeSet contains id 44
        if (!serviceTypeSet.serviceType.some(checkuser => checkuser?.id === 44)) {
            this.getSetPageData(serviceTypeSet, 16);
        }
    }


    this.props.getData();
    this.getDataSitesByuser();

    var _depotList = [];
    var _regionsList = [];
    this.props.depots.map((depot) => {
      _depotList.push({ value: depot.id, label: depot.name })
    })
    this.props.regions.filter(allret => "0".includes(allret.isSubRegion)).map((regions) => {
      _regionsList.push({ value: regions.id, label: regions.name })
    })
    this.setState({
      depotList: _depotList,
      regionsList: _regionsList
    })

    //console.log(this.props.details);
  }
  getSetPageData = (serviceTypeIs, Pageid) => {
    //console.log("this.props");
    //console.log(serviceTypeIs.serviceType);
    if (serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == Pageid)[0] == null) {
      const { history } = this.props;

      history.push("/not-allowed");
    }

  };
  getDataSitesByuser = async () => {
    var res = await getUserSites();
    var _allSite = [];
    //console.log("getUserSites");
    //console.log(res.data);
    if (res.success) {
      res.data.map((sites) => {
        _allSite.push({ value: sites.id, label: sites.siteName })
      })
      this.setState({
        allSite_loading: false,
        allSite: _allSite,
      });
    }
  };
  getDataexpot = async (data) => {
    //console.log(data);
    var pageCount = 0;
    var res = await getPisScServiceFullExport(data);
    console.log(res);
    if (res.success) {
      pageCount = res?.data?.pageCount;
      if (pageCount == 0) {

        this.setState({
          loading: false,
          buttonLodading: false,
          data_list: [],
        });
      }


      if (pageCount == 1) {
        this.setState({
          loading: false,
          buttonLodading: false,
          data_list: res.data.data,
        });
      }
      if (pageCount > 1) {
        for (let i = 0; i < pageCount; i++) {
          this.getDataexpot2(i, pageCount)
          if (i == pageCount - 1) {
            this.setState({
              loading: false,

            })
          }
        }
        console.log("=======================================================================================")
        console.log(this.state.data_list)
      }
    }



  };
  getDataexpot2 = async (data, pagecout) => {



    var form = new FormData();
    form.append("fromDate", this.state.startDate);
    form.append("toDate", this.state.endDate);
    form.append("siteIds", this.state.siteIds);
    form.append("depotIds", this.state.depotIds);
    form.append("regionIds", this.state.regionIds);
    form.append("pageNo", data);
    form.append("recodesPerPage", this.state.recodesPerPage1);

    var res = await getPisScServiceFullExport(form);

    console.log("res 1000")
    console.log(data)
    console.log(res)
    if (res.success) {
      this.setState({
        fullArray: [...this.state.fullArray, ...res.data.data]
      }, () => {
        if (data + 1 == pagecout) {

          this.setState({
            buttonLodading: false
          })



        }
        this.setState({

          data_list: this.state.fullArray,
        });
      })

    }

  }
  onChangeDate = (date, dateString) => {
    //console.log(date);
    //console.log(dateString);
    this.setState({
      startDate: dateString,
    });
  };
  onChangeDate2 = (date, dateString) => {
    //console.log(date);
    //console.log(dateString);
    this.setState({
      endDate: dateString,
    });
  };

  searchClick = () => {
    //  console.log(this.state.siteIds);
    this.setState({ data_list: [], fullArray: [], buttonLodading: true, loading: true, })

    var form = new FormData();
    form.append("fromDate", this.state.startDate);
    form.append("toDate", this.state.endDate);
    form.append("siteIds", this.state.siteIds);
    form.append("depotIds", this.state.depotIds);
    form.append("regionIds", this.state.regionIds);
    form.append("pageNo", 0);
    form.append("recodesPerPage", this.state.recodesPerPage1);

    this.getDataexpot(form)

  };

  searchRegionmChange = (event) => {
    var dd = event
    //console.log(dd)
    var subRegion = [];
    var _depotList1 = [];
    var subRegionIdArray = [];

    this.props.regions.filter(allret => dd.map((n) => n).includes(allret.parentRegion?.id)).map((subR) => {
      subRegionIdArray.push(subR.id)
    })

    this.setState({
      regionIds: subRegionIdArray
    });

    //console.log(this.props.regions.filter(allret => dd.map((n) => n).includes(allret.parentRegion?.id)));
    subRegion = this.props.regions.filter(allret => dd.map((n) => n).includes(allret.parentRegion?.id));
    //console.log(this.props.depots.filter(alldate => subRegion.map((n) => n.id).includes(alldate.region?.id)));
    this.props.depots.filter(alldate => subRegion.map((n) => n.id).includes(alldate.region?.id)).map((depot) => {
      _depotList1.push({ value: depot.id, label: depot.name })
    })

    this.setState({
      depotList: _depotList1

    })

  }
  searchDepottemChange = (event) => {

    this.setState({
      depotIds: event
    });
  }
  searchSiteemChange = (event) => {

    this.setState({
      siteIds: event
    });
  }
  handleClick = () => {
    console.log(this.state.data_list);
  };
  render() {
    const fontValue = { sz: "12", bold: true, fill: { patternType: "solid", fgColor: { rgb: "FF0B0713" } } };
    const { filterTable } = this.state;
    const dataSet1 = this.state.data_list;




    const StatisticDataSet = [
      {
        columns: [
          { title: "Request Code", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 210 } },//char width 
          { title: "Site ID", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
          { title: "Site Name", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 

          { title: "Region", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width
          { title: "Sub Region", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width
          { title: "Depot", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width


          { title: "Vendor", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
          { title: "Request Type", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
          { title: "Request Created On", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
          { title: "Job Requeted Date", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 

          { title: "Current Status", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cMan
          { title: "Completed", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount

          { title: "Job Status", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
          { title: "Service Completed On", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
          { title: "Request Created By", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount

          { title: "Request Approved On", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width cManual_transport_amount
          { title: "Request Approved By", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width cManual_transport_amount

          { title: "Vendor Team", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//pixels width 
          { title: "Vendor Team Assigned On", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width   
          { title: "Vendor Team Assigned By", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width   

          { title: "Vendor Coordinator Approved On", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width cManual_transport_amount
          { title: "Vendor Coordinator Approved By", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width cManual_transport_amount

          { title: "Vendor Coordinator Rejected On", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width cManual_transport_amount
          { title: "Vendor Coordinator Rejected By", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width cManual_transport_amount

          { title: "Cleaning Service Done On", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width cManual_transport_amount
          { title: "Cleaning Service Done By", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width cManual_transport_amount

          { title: "Completed On", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width cManual_transport_amount
          { title: "Completed By", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width cManual_transport_amount

          { title: "Do Approved On", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width cManual_transport_amount
          { title: "Do Approved By", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width cManual_transport_amount

          { title: "Do Rejected On", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width cManual_transport_amount
          { title: "Do Rejected By", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width cManual_transport_amount

          { title: "Team Rejected On", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width cManual_transport_amount
          { title: "Team Rejected By", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width cManual_transport_amount

          { title: "Team Rejected On", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width cManual_transport_amount
          { title: "Team Rejected By", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width cManual_transport_amount

        ],
        data: dataSet1?.map((data) => [
          { value: validateModle(data.serviceRequest) ? validateStringValue(data.serviceRequest.requestCode) : ("Null"), style: { font: { sz: "12" } } },
          { value: validateModle(data.serviceRequest) ? validateStringValue(data.serviceRequest.site.siteId) : ("Null"), style: { font: { sz: "12" } } },
          { value: validateModle(data.serviceRequest) ? validateStringValue(data.serviceRequest.site.siteName) : ("Null"), style: { font: { sz: "12" } } },
          { value: validateModle(data.serviceRequest) ? validateStringValue(data.region) : ("Null"), style: { font: { sz: "12" } } },
          { value: validateModle(data.serviceRequest) ? validateStringValue(data.subRegion) : ("Null"), style: { font: { sz: "12" } } },
          { value: validateModle(data.serviceRequest) ? validateStringValue(data.depotName) : ("Null"), style: { font: { sz: "12" } } },
          { value: validateModle(data.serviceRequest) ? validateStringValue(data.serviceRequest.vendor.name) : ("Null"), style: { font: { sz: "12" } } },
          { value: validateModle(data.serviceRequest) ? validateStringValue(data.serviceRequest.requestType.name) : ("Null"), style: { font: { sz: "12" } } },
          { value: validateModle(data.initiateLog) ? validateDateTimeFormat(data.initiateLog.createdTime, DATE_TIME) : ("Null"), style: { font: { sz: "12" } } },
          { value: validateModle(data.serviceRequest) ? validateStringValue(data.serviceRequest.requestDate) : ("Null"), style: { font: { sz: "12" } } },
          { value: validateModle(data.pendingStatus) ? validateStringValue(data.pendingStatus) : ("Null"), style: { font: { sz: "12" } } },
          { value: data?.completed ? data?.completed : ('Null'), style: { font: { sz: "12" } } },
          { value: validateModle(data.status) ? validateStringValue(data.status.name) : ("Null"), style: { font: { sz: "12" } } },
          { value: validateStringValue(data.serviceDate), style: { font: { sz: "12" } } },
          { value: validateModle(data.initiateLog) ? validateStringValue(data.initiateLog?.user?.name) : ("Null"), style: { font: { sz: "12" } } },

          { value: validateModle(data.approvedInitiateRequestLog) ? validateDateTimeFormat(data.approvedInitiateRequestLog.createdTime, DATE_TIME) : ("Null"), style: { font: { sz: "12" } } },
          { value: validateModle(data.approvedInitiateRequestLog) ? validateStringValue(data.approvedInitiateRequestLog?.user?.name) : ("Null"), style: { font: { sz: "12" } } },

          { value: validateModle(data.vendorTeam) ? validateStringValue(data.vendorTeam.name) : ("Null"), style: { font: { sz: "12" } } },
          { value: validateModle(data.vendorTeamAssignedLog) ? validateDateTimeFormat(data.vendorTeamAssignedLog.createdTime, DATE_TIME) : ("Null"), style: { font: { sz: "12" } } },
          { value: validateModle(data.vendorTeamAssignedLog) ? validateStringValue(data.vendorTeamAssignedLog?.user?.name) : ("Null"), style: { font: { sz: "12" } } },

          { value: validateModle(data.vcApprovedLog) ? validateDateTimeFormat(data.vcApprovedLog.createdTime, DATE_TIME) : ("Null"), style: { font: { sz: "12" } } },
          { value: validateModle(data.vcApprovedLog) ? validateStringValue(data.vcApprovedLog?.user?.name) : ("Null"), style: { font: { sz: "12" } } },

          { value: validateModle(data.vcRejectedLog) ? validateDateTimeFormat(data.vcRejectedLog.createdTime, DATE_TIME) : ("Null"), style: { font: { sz: "12" } } },
          { value: validateModle(data.vcRejectedLog) ? validateStringValue(data.vcRejectedLog?.user?.name) : ("Null"), style: { font: { sz: "12" } } },

          { value: validateModle(data.cleaningServiceLog) ? validateDateTimeFormat(data.cleaningServiceLog.createdTime, DATE_TIME) : ("Null"), style: { font: { sz: "12" } } },
          { value: validateModle(data.cleaningServiceLog) ? validateStringValue(data.cleaningServiceLog?.user?.name) : ("Null"), style: { font: { sz: "12" } } },

          { value: validateModle(data.completedLog) ? validateDateTimeFormat(data.completedLog.createdTime, DATE_TIME) : ("Null"), style: { font: { sz: "12" } } },
          { value: validateModle(data.completedLog) ? validateStringValue(data.completedLog?.user?.name) : ("Null"), style: { font: { sz: "12" } } },

          { value: validateModle(data.doApprovedLog) ? validateDateTimeFormat(data.doApprovedLog.createdTime, DATE_TIME) : ("Null"), style: { font: { sz: "12" } } },
          { value: validateModle(data.doApprovedLog) ? validateStringValue(data.doApprovedLog?.user?.name) : ("Null"), style: { font: { sz: "12" } } },

          { value: validateModle(data.doRejectedLog) ? validateDateTimeFormat(data.doRejectedLog.createdTime, DATE_TIME) : ("Null"), style: { font: { sz: "12" } } },
          { value: validateModle(data.doRejectedLog) ? validateStringValue(data.doRejectedLog?.user?.name) : ("Null"), style: { font: { sz: "12" } } },

          { value: validateModle(data.teamRejectedLog) ? validateDateTimeFormat(data.teamRejectedLog.createdTime, DATE_TIME) : ("Null"), style: { font: { sz: "12" } } },
          { value: validateModle(data.teamRejectedLog) ? validateStringValue(data.teamRejectedLog?.user?.name) : ("Null"), style: { font: { sz: "12" } } },

          { value: validateModle(data.teamRejectedLog) ? validateDateTimeFormat(data.teamRejectedLog.createdTime, DATE_TIME) : ("Null"), style: { font: { sz: "12" } } },
          { value: validateModle(data.teamRejectedLog) ? validateStringValue(data.teamRejectedLog?.user?.name) : ("Null"), style: { font: { sz: "12" } } },

        ]),
      }
    ];

    const columns = [
      {
        title: "Request Code",
        dataIndex: "serviceRequest",
        key: "serviceRequest",
        render: (item) => <span>{item.requestCode}</span>,
      },
      {
        title: "Site Id",
        dataIndex: "serviceRequest",
        key: "serviceRequest",
        render: (item) => <span>{item.site.siteId}</span>,
      },
      {
        title: "Site Name",
        dataIndex: "serviceRequest",
        key: "serviceRequest",
        render: (item) => <span>{item.site.siteName}</span>,
      },

      {
        title: "Request Type",
        dataIndex: "serviceRequest",
        key: "serviceRequest",
        render: (item) => <span>{item.requestType.name}</span>,

      },
      {
        title: "Request Date",
        dataIndex: "serviceRequest",
        key: "serviceRequest",
        render: (item) => <span>{item.requestDate}</span>,
      },
      {
        title: "Request Time",
        dataIndex: "serviceRequest",
        key: "serviceRequest",
        render: (item) => <span>{item.requestTime}</span>,
      },
    ];
    const timestamp = Date.now();
    return (
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <div class="page-title-box">
              <div class="row">
                <div class="col">
                  <h4 class="page-title">PIS Site Cleaning Data Export</h4>
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0);">Data Export </a></li>
                    <li class="breadcrumb-item"><a href="javascript:void(0);"> Site Cleaning Data Export</a></li>

                  </ol>
                </div>
                <div class="col-auto align-self-center">
                  <a href="#" class="btn btn-sm btn-outline-primary" id="Dash_Date">
                    <span class="day-name" id="Day_Name">Today:</span>&nbsp;
                    <span class="" id="Select_date">{new Date().toUTCString().slice(5, 16)}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar align-self-center icon-xs ms-1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                  </a>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">

          <div className="row">
            <div style={{ backgroundColor: 'white', padding: 20, borderRadius: 10, boxShadow: '0px 0px 10px 0px #00000010' }}>
              <Row>
                <Col span={6}>
                  <DatePicker
                    placeholder="Start Date"
                    style={{ width: "98%", height: "34px" }}
                    onChange={this.onChangeDate}
                    className="customDropdown"
                  />
                </Col>

                <Col span={6}>
                  <DatePicker
                    placeholder="End Date"
                    style={{ width: "98%", height: "34px" }}
                    onChange={this.onChangeDate2}
                    className="customDropdown"
                  />
                </Col>

                <Col span={6}>
                  <Select
                    mode="multiple"
                    showSearch
                    className="customDropdown"
                    bordered={false}
                    name="regionId"
                    onChange={(e) => this.searchRegionmChange(e)}
                    placeholder="Region"
                    style={{ width: "98%", height: "auto" }}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {this.state.regionsList.map((item, index) => {
                      return <Option value={item.value}>{item.label}</Option>;
                    })}
                  </Select>
                </Col>

                <Col span={6}>
                  <Select
                    mode="multiple"
                    className="customDropdown"
                    bordered={false}
                    showSearch
                    name="depotId"
                    onChange={(e) => this.searchDepottemChange(e)}
                    placeholder="Depot"
                    style={{ width: "98%", height: "auto" }}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {this.state.depotList.map((item, index) => {
                      return <Option value={item.value}>{item.label}</Option>;
                    })}
                  </Select>
                </Col>

                <Col span={6} style={{ marginTop: 5 }}>
                  <Select
                    mode="multiple"
                    className="customDropdown"
                    bordered={false}
                    placeholder="Site"
                    style={{ width: "98%", height: "auto" }}
                    // options={this.state.allSite} 
                    loading={this.state.allSite_loading}
                    name="siteId"
                    onChange={(e) => this.searchSiteemChange(e)}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {this.state.allSite.map((item, index) => {
                      return <Option value={item.value}>{item.label}</Option>;
                    })}
                  </Select>
                </Col>

                <Col span={18} style={{ marginTop: 5 }}>
                  <Button
                    onClick={this.searchClick}
                    type="primary"
                    style={{ background: "#38B36E", borderColor: "#38B36E", borderRadius: '5px' }}
                    disabled={
                      this.state.startDate === "" &&
                      this.state.endDate === "" &&
                      this.state.regionIds === "" &&
                      this.state.depotIds === "" &&
                      this.state.siteIds === ""
                    }
                  >
                    <span className='textStyles-small' style={{ color: 'white' }}><SearchOutlined /> Search</span>
                  </Button>

                  {
                    this.state.data_list.length > 0 ? (
                      <ExcelFile
                        filename={"PIS Site Cleaning Data export -" + timestamp}
                        style={{ marginLeft: 10 }}
                        element={
                          <Button
                            disabled={this.state.buttonLodading || dataSet1.length <= 0}
                            icon={<DownCircleOutlined style={{ color: 'white' }} />}
                            type="primary"
                            style={{ borderRadius: '5px', marginLeft: 5 }}
                          >
                            <span className='textStyles-small' style={{ color: 'white' }}>Data Export</span>
                          </Button>
                        }
                      >
                        <ExcelSheet dataSet={StatisticDataSet}
                          className='textStyles-small'
                          name="PIS AC Service  Export" />
                      </ExcelFile>
                    ) : null
                  }
                </Col>
              </Row>
              <Table
                style={{ marginTop: 20 }}
                size="small"
                columns={columns}
                className='textStyles-small'
                loading={this.state.loading}
                dataSource={this.state.data_list}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  //console.log("state", state);
  return {
    organization_count: state.organization.length,
    regions: state.regions,
    depots: state.depots,
    sites_count: state.sites.length,
    vendor_count: state.vendor.length,
    roles_count: state.roles.length,
  };
}
export default withRouter(connect(mapStateToProps, { getDonationCategories, getData })(
  PisScServiceFullExport
)); 
import React, { Component } from 'react'
import { Table, Tag, Button, Drawer, Form, Input, Select, InputNumber, DatePicker, Popconfirm, message } from 'antd';
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import SimpleReactValidator from "simple-react-validator";
import { createNewBatteryRack, getBatteryRack, UpdateBatteryRack } from "../../api/index";
import { checkServiceType } from "../../actions/service-type";
import AssetDelete from "../site_components/asset_delete";

class iDBatteryRacks extends Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            title: "",
            tabaleLoading: false,
            actionView: false,
            showModal: false,
            open: false,
            openEdit: false,
            selectedAssetId: null,
            AssetType: [],
            dcduType: '',
            dcduCapacity: '',
            iDRModelCount: '',
            ibridbatteryrackModel: '',
            dcduFreeBreakerSlots: '',
            dcduPowerFromLLVD: '',
            dcduNoOfBreakerSlots: '',
            rbcapacityToPowerDCDU: '',
            AssetManagementPermission: false,
            openViewMoreDetails: false,
            selectedElement: "",

            dropdownObjIDBR: {
                "ibridbatteryrackModel": "",
                "iDRModelCount": "",
            },
        };
        this.requestFromClose = this.requestFromClose.bind(this);
        this.handleDcduType = this.handleDcduType.bind(this);
        this.handleDcduCapacity = this.handleDcduCapacity.bind(this);
        this.handleIdbatteryRackModelCount = this.handleIdbatteryRackModelCount.bind(this);
        this.handleidbatteryrackModel = this.handleidbatteryrackModel.bind(this);
        this.handleFreeBreakerSlots = this.handleFreeBreakerSlots.bind(this);
        this.handlePowerFromLLVD = this.handlePowerFromLLVD.bind(this);
        this.handleNoOfBreakerSlots = this.handleNoOfBreakerSlots.bind(this);
        this.handleRbcapacityToPowerDCDU = this.handleRbcapacityToPowerDCDU.bind(this);

    }
    handleidbatteryrackModel(e) {
        //console.log(e.target.value)
        this.setState({ ibridbatteryrackModel: e });
    }
    dcduUpdata(id) {
        // console.log('dfdfd')
        console.log(id)
        this.getDataDcduDetails(id);
        this.setState({ openEdit: true });
    }
    getDataDcduDetails = async (id) => {
        var res = await getBatteryRack(id);
        console.log(res);
        if (res.success) {
            this.setState({
                dcduEdit: res.data,
                dcduId: res.data?.id,
                iDRModelCount: res.data?.idbatteryRackModelCount,
                ibridbatteryrackModel: res.data?.idbatteryrackModel,
            })

        }
    }
    handleRbcapacityToPowerDCDU(e) {
        console.log(e.target.value)
        // console.log(e)
        this.setState({ rbcapacityToPowerDCDU: e.target.value });
    }
    handleNoOfBreakerSlots(e) {
        // console.log('ddddddd')
        // console.log(e)
        this.setState({ dcduNoOfBreakerSlots: e });
    }
    handlePowerFromLLVD(e) {
        console.log(e.target.value)
        this.setState({ dcduPowerFromLLVD: e.target.value });
    }
    handleFreeBreakerSlots(e) {
        //console.log(e.target.value)
        this.setState({ dcduFreeBreakerSlots: e });
    }
    handleDcduType(e) {
        //console.log(e.target.value)
        this.setState({ dcduType: e.target.value });
    }
    handleIdbatteryRackModelCount(e) {
        this.setState({ iDRModelCount: e });
    }
    handleDcduCapacity(e) {
        console.log(e)
        this.setState({ dcduCapacity: e });
    }
    componentDidMount() {
        console.log('tytqwe')
        console.log(this.props.detailsSite)
        if (JSON.parse(localStorage.getItem('serviceTypeSet')) == null) {
            const { history } = this.props;

            history.push("/");

        } else {

            this.getSetPageData(JSON.parse(localStorage.getItem('serviceTypeSet')), 38)

        }
        checkServiceType(38).then((data) => this.setState({
            AssetManagementPermission: data
        }, () => {
        }),

        )
        // console.log(this.props.details2)

    }
    getSetPageData = (serviceTypeIs, Pageid) => {

        if (serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == Pageid)[0] != null) {
            console.log('fddf');
            this.setState({
                actionView: true,
            })
        }

    };
    requestFromClose() {
        console.log('dsdsd')
        this.setState({ showModal: true, open: true });
    }
    onDrawerClose = () => {
        console.log('sddsds')
        this.formRef.current.resetFields();
        this.validator.hideMessages();
        this.setState({
            open: false,
            openEdit: false,
            selectedAssetId: null,
            AssetType: [],
            dcduType: '',
            dcduCapacity: '',
            dcduFreeBreakerSlots: '',
            dcduPowerFromLLVD: '',
            dcduNoOfBreakerSlots: '',
            rbcapacityToPowerDCDU: '',
            iDRModelCount: '',
            ibridbatteryrackModel: '',

        })

    }
    onChangeAsset = (value) => {
        console.log("onChangeAsset");
        //console.log(value);
        this.setState({
            selectedAssetId: value,
            AssetType: {
                "id": value
            },

        })
    };
    AllDataSubmitUpdate = async () => {

        if (this.validator.allValid()) {
            var dataSet;
            console.log('ssss')
            dataSet = {
                "id": this.state.dcduId,
                "site": { "id": this.props?.detailsSite?.site_details.id },
                "asset": {
                    "id": 2
                },
                "idbatteryRackModelCount": this.state.iDRModelCount,
                "idbatteryrackModel": this.state.ibridbatteryrackModel,

            }
            console.log(dataSet)
            var res = await UpdateBatteryRack(dataSet);
            console.log(res)
            if (res.success) {
                message.success('Successfully Battery Rack Edited!')
                this.formRef.current.resetFields();

                this.setState({
                    showModal: false,
                    open: false,
                    openEdit: false,

                    selectedAssetId: null,
                    AssetType: [],
                    dcduType: '',
                    dcduCapacity: '',
                    dcduFreeBreakerSlots: '',
                    dcduPowerFromLLVD: '',
                    dcduNoOfBreakerSlots: '',
                    rbcapacityToPowerDCDU: '',
                    iDRModelCount: '',
                    ibridbatteryrackModel: '',



                })
                this.props.parentMethod();

            } else {
                message.warning(' Something went wrong!')
            }
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }

    }
    AllDataSubmit = async () => {
        if (this.validator.allValid()) {
            var dataSet;
            console.log(this.state.AssetType)
            dataSet = {

                "site": { "id": this.props?.detailsSite?.site_details.id },

                "idbatteryRackModelCount": this.state.iDRModelCount,
                "idbatteryrackModel": this.state.ibridbatteryrackModel,


            }
            console.log(dataSet)
            var res = await createNewBatteryRack(dataSet);
            if (res.success) {
                message.success('Successfully Battery Rack Added!')
                this.formRef.current.resetFields();

                this.setState({
                    showModal: false,
                    open: false,
                    selectedAssetId: null,
                    AssetType: [],
                    dcduType: '',
                    dcduCapacity: '',
                    dcduFreeBreakerSlots: '',
                    dcduPowerFromLLVD: '',
                    dcduNoOfBreakerSlots: '',
                    rbcapacityToPowerDCDU: '',
                    iDRModelCount: '',
                    ibridbatteryrackModel: '',
                })
                this.props.parentMethod();
                // const { history } = this.props;
                // history.push("/site");
            } else {
                message.warning(' Something went wrong!')
            }
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    }

    viewMoreDetails(element) {
        this.setState({
            showModal2: true,
            openViewMoreDetails: true,
            selectedElement: element
        });
    }

    onViewMoreDetailsDrawerClose = () => {
        this.setState({
            showModal2: false,
            openViewMoreDetails: false,
        })
    }

    validatedTextLable = (field) => {
        return (field !== null && field !== '')
            ? <span class="badge badge-outline-secondary text-muted">{(field)}</span>
            : <span class="badge text-danger">Currently Not Set</span>;
    }
    submitForm = async (item) => {
        this.getDataIdBatteryRaxkDetailsForDelete(item);

    }
    getDataIdBatteryRaxkDetailsForDelete = async (id) => {

        var res = await getBatteryRack(id);
        console.log(res);
        if (res.success) {
            var respons = AssetDelete.Action(res.data, this.props?.detailsSite?.site_details.id, UpdateBatteryRack)

            respons.then((value) => {
                if (value == 1) {
                    message.success('Successfully Delete!')
                    this.props.parentMethod();
                } else {
                    message.warning(' Something went wrong!')
                }
            });
        }
    }
    render() {
        const { Option } = Select;

        const _idBatteryRacks = this.state.selectedElement
        const columns2 = [
            {
                title: "ID Battery Rack Model 1",
                dataIndex: "idbatteryrackModel",
                key: "idbatteryrackModel",
                width: '100px',
                content: <span class="badge badge-outline-info badge-pill"><i class="fas fa-hdd" style={{ paddingRight: "5px" }} />{_idBatteryRacks.idbatteryrackModel}</span>,
                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,
            },
            {
                title: "ID Battery Rack Model 1 Count",
                dataIndex: "idbatteryRackModelCount",
                key: "idbatteryRackModelCount",
                fixed: 'left',
                width: '80px',
                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,
                content: <Tag color="gray"><span>{_idBatteryRacks.idbatteryRackModelCount}</span></Tag>,
            }
        ];
        return (
            <>
                <div className='row'>
                    <div class="col-lg-12">
                        <div class="text-end">
                            <ul class="list-inline">
                                <li class="list-inline-item">
                                    {this.state.actionView
                                        ? <button type="button" class="btn btn-soft-primary btn-sm" onClick={this.requestFromClose}><i class="fas fa-plus me-2"></i>Add Id Battery Racks</button>
                                        : ('')
                                    }
                                </li>
                            </ul>
                        </div>
                    </div>

                    {/* <pre>
                        {JSON.stringify(this.props.dropdown, null, 2)}
                    </pre> */}

                    {this.props.details.access_technologies_data != null ? (<div className='col-lg-3'>
                        <table class="table table-dark2 mb-0" style={{ fontSize: 12 }} ><tbody><tr><td scope="row"><b>No of Different Battery Rack Models:</b></td><td>
                            {this.props.details2.idBatteryRacks_data.length}
                        </td><td scope="row"></td></tr></tbody></table>

                    </div>) : ('')}

                    <br></br>
                    <br></br>
                    <div className='row'>
                        {this.props.details2.idBatteryRacks_data.map((element) => (
                            <div className='col-md-4'>
                                <div class="card">
                                    <div class="card-header">
                                        <div class="d-flex justify-content-start align-items-center">
                                            <img src={'https://static.vecteezy.com/system/resources/previews/001/902/502/large_2x/telecommunication-radio-antenna-and-satelite-tower-with-blue-sky-free-photo.JPG'} alt="" class="thumb-md rounded" />
                                            <div class="badge badge-soft-primary" style={{ padding: "5px", marginLeft: "15px", border: "1px solid rgba(0, 0, 0, 0.05)" }}>
                                                <i class="la la-home font-14 text-dark" style={{ paddingLeft: "10px" }} />
                                                <i class="la la-angle-right text-dark font-14" style={{ padding: "5px" }} />
                                                <span class="fw-semibold text-pink">{this.props?.detailsSite?.site_details?.siteName?.substring(0, 11)}</span>
                                                <i class="la la-angle-right text-dark font-14" style={{ padding: "5px" }} />
                                                <span class="fw-semibold text-primary" style={{ paddingRight: "10px" }}>{element?.idbatteryrackModel?.substring(0, 18)}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="col">
                                            <div class="d-flex justify-content-between align-items-center mb-2">
                                                <h6 class="fw-normal m-0">No of Battery Racks in Same Model</h6>
                                                <span class="badge bg-secondary">{element.idbatteryRackModelCount}</span>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center mb-2">
                                                <h6 class="fw-normal m-0">ID Battery Rack Model 1</h6>
                                                <span class="badge badge-outline-info badge-pill"><i class="fas fa-hdd" style={{ paddingRight: "5px" }} />{element.idbatteryrackModel}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <p class="card-footer m-0">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <a href="#custom-modal" class="btn btn-soft-dark btn-sm waves-effect btn-round" onClick={() => this.viewMoreDetails(element)}>
                                                <i class="mdi mdi mdi-unfold-more-horizontal"></i> More Details </a>
                                            <div class="text-end">
                                                {this.state.AssetManagementPermission
                                                    ? (<button type="button" class="btn btn-soft-primary btn-icon-square-sm" onClick={() => this.dcduUpdata(element.id)} style={{ marginRight: "8px" }}><i class="mdi mdi-pencil-outline"></i></button>)
                                                    : ("")}
                                                <Popconfirm
                                                    title="Are You Sure?"
                                                    description="Are you sure to delete this task?"
                                                    onConfirm={() => this.submitForm(element.id)}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    {this.state.AssetManagementPermission
                                                        ? (<button type="button" class="btn btn-soft-danger btn-icon-square-sm"><i class="mdi mdi-trash-can-outline"></i></button>)
                                                        : ('')}
                                                </Popconfirm>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                            </div>
                        ))}

                    </div>



                    <Drawer maskClosable={false} title={"Id Battery Rack Details"} width={"50%"} placement="right" onClose={() => this.onViewMoreDetailsDrawerClose()} visible={this.state.openViewMoreDetails} closeIcon={<button type="button" class="btn btn-soft-danger btn-icon-square-sm" style={{ marginRight: "8px" }}><i class="mdi dripicons-cross"></i></button>}>
                        <div class="row">
                            <div class="col-lg-12">
                                <ul class="list-group">
                                    {columns2.map((item) => (
                                        <li class="d-flex list-group-item justify-content-between align-items-center">
                                            <div>
                                                <i class="la la-hand-o-right text-primary me-2"></i> {item.title}
                                            </div>
                                            {item.content}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </Drawer>

                    <Drawer maskClosable={false} title={" New Id Battery Racks"} width={"70%"} placement="right" onClose={() => this.onDrawerClose()} visible={this.state.open} closeIcon={<button type="button" class="btn btn-soft-danger btn-icon-square-sm" style={{ marginRight: "8px" }}><i class="mdi dripicons-cross"></i></button>}>
                        <div className="row" >
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <Form
                                            ref={this.formRef}
                                        >
                                            <div className='row'>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="dcdu">
                                                        ID Battery Rack Model 1 <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Form.Item required name="idbatteryrackModel" >
                                                        {/* <Input style={{ width: '100%' }} id="idbatteryrackModel" onChange={this.handleidbatteryrackModel} /> */}
                                                        <div>
                                                            <Select
                                                                showSearch
                                                                style={{ width: "100%" }}
                                                                id='idbatteryrackModel'
                                                                onChange={(e) => {
                                                                    this.handleidbatteryrackModel(e);
                                                                }}>
                                                                {this.props.dropdown["dropdownObjIDBR"]["ibridbatteryrackModel"]?.map((x, y) => {
                                                                    return <Option value={x}>{x}</Option>
                                                                })}
                                                            </Select>
                                                        </div>
                                                    </Form.Item>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message(' Id batteryrack Model', this.state.ibridbatteryrackModel, 'required')}</p>

                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="depot">
                                                        ID Battery Rack Model 1 Count <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Form.Item required name="idbatteryRackModelCount" >
                                                        {/* <InputNumber style={{ width: '100%' }} id="idbatteryRackModelCount" onChange={this.handleIdbatteryRackModelCount} /> */}
                                                        <div>
                                                            <Select
                                                                style={{ width: "100%" }}
                                                                id='idbatteryRackModelCount'
                                                                onChange={(e) => {
                                                                    this.handleIdbatteryRackModelCount(e);
                                                                }}>
                                                                {this.props.dropdown["dropdownObjIDBR"]["iDRModelCount"]?.map((x, y) => {
                                                                    return <Option value={x}>{x}</Option>
                                                                })}
                                                            </Select>
                                                        </div>
                                                    </Form.Item>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Id battery Rack Model Count', this.state.iDRModelCount, 'required')}</p>
                                                </div>
                                            </div>
                                        </Form>
                                        <div className='row' style={{ justifyContent: "end" }}>
                                            <div className='col-md-2' >   <button className="btn btn-primary" onClick={() => this.AllDataSubmit()} >Add New Asset</button></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Drawer>
                    <Drawer maskClosable={false} title={" Edit Id Battery Racks"} width={"70%"} placement="right" onClose={() => this.onDrawerClose()} visible={this.state.openEdit} closeIcon={<button type="button" class="btn btn-soft-danger btn-icon-square-sm" style={{ marginRight: "8px" }}><i class="mdi dripicons-cross"></i></button>}>
                        <div className="row" >
                            <div className="col-lg-12">
                                <div className="card">

                                    <div className="card-body">
                                        <Form
                                            ref={this.formRef}
                                        >
                                            <div className='row'>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="dcdu">
                                                        ID Battery Rack Model 1<x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Form.Item >
                                                        {/* <Input style={{ width: '100%' }} value={this.state.ibridbatteryrackModel} id="idbatteryrackModel" onChange={this.handleidbatteryrackModel} /> */}
                                                        <div>
                                                            <Select
                                                                showSearch
                                                                style={{ width: "100%" }}
                                                                id='idbatteryrackModel'
                                                                value={this.state.ibridbatteryrackModel}
                                                                onChange={(e) => {
                                                                    this.handleidbatteryrackModel(e);
                                                                }}>
                                                                {this.props.dropdown["dropdownObjIDBR"]["ibridbatteryrackModel"]?.map((x, y) => {
                                                                    return <Option value={x}>{x}</Option>
                                                                })}
                                                            </Select>
                                                        </div>
                                                    </Form.Item>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message(' Id batteryrack Model', this.state.ibridbatteryrackModel, 'required')}</p>
                                                </div>

                                                <div className="col-md-6">
                                                    <label className="form-label" for="depot">
                                                        ID Battery Rack Model 1 Count  <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Form.Item >
                                                        <div>
                                                            <Select
                                                                style={{ width: "100%" }}
                                                                value={this.state.iDRModelCount}
                                                                id='idbatteryRackModelCount'
                                                                onChange={(e) => {
                                                                    this.handleIdbatteryRackModelCount(e);
                                                                }}>
                                                                {this.props.dropdown["dropdownObjIDBR"]["iDRModelCount"]?.map((x, y) => {
                                                                    return <Option value={x}>{x}</Option>
                                                                })}
                                                            </Select>
                                                        </div>
                                                    </Form.Item>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Id battery Rack Model Count', this.state.iDRModelCount, 'required')}</p>
                                                </div>
                                            </div>
                                        </Form>
                                        <div className='row' style={{ justifyContent: "end" }}>
                                            <div className='col-md-2' >   <button className="btn btn-primary" onClick={() => this.AllDataSubmitUpdate()} >Update This Asset</button></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Drawer>
                </div>
            </>
        )
    }
}
export default iDBatteryRacks;
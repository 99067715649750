import { imageCapturingService, serviceCommon, serviceOne, serviceUser, serviceFinance, refuelserviceOne, serviceVehicle, siteAccessService, serviceRouting, pisService, baseUrl, hierarchy, vehicleRewamp, accessrw } from "./api.config";
import axios from "axios";

const headers = {
    "Authorization": "Bearer " + localStorage.getItem("access_token"),
    "logged-user-email": localStorage.getItem("logged_user_email"),
    "userId": localStorage.getItem("userId")
};

export const createBulkSiteAccessRequestREWAMP = async (data) => {
    try {
        const axiosInstance = axios.create({
            baseURL: baseUrl+accessrw,
            headers,
            timeout: 60000,
        });
        const res = await axiosInstance.post("/api/v1/siteAccessRequest/bulk", data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        const response = res.status === 200
            ? { success: true, data: res.data }
            : { success: false, data: res };
        return response;
    } catch (err) {
        return { success: false, data: err.message };
    }
};

export const uploadAccessRequestFiles = async (data) => {
    try {
        const axiosInstance = axios.create({
            baseURL: baseUrl+accessrw,
            headers,
            timeout: 60000,
        });
        const res = await axiosInstance.post("/api/v1/image-upload", data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        const response = res.status === 200
            ? { success: true, data: res.data }
            : { success: false, data: res.data };
        return response;
    } catch (err) {
        return { success: false, data: err.message };
    }
};
import React, { Component } from 'react';
import { InputNumber, Form, Button, Drawer, message, Collapse, Select } from "antd";
import { PlusCircleOutlined } from '@ant-design/icons';
import { Createcreatebulcktank, getBulckTankBySiteId, getBulckTankById, updateBulckTank } from "../../api/index";
import SimpleReactValidator from "simple-react-validator";
import { checkServiceType } from "../../actions/service-type";
const { Option } = Select;

class BulckTankDetails extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();

        this.state = {
            open: false,
            title: "",
            showModal: false,
            maxFillingLimit: '',
            capacity: '',
            bulckTankEdit: null,

            bulckTankList: [],
            actionView: false,
            AssetManagementPermission: false,
            openViewMoreDetails: false, 
            selectedElement: "",

            objBulktankDropdown : {
                "capacity": "",
            }
        };
        this.requestFromClose = this.requestFromClose.bind(this);
        this.requestFromClose3 = this.requestFromClose3.bind(this);
        this.requestFromClose4 = this.requestFromClose4.bind(this);
        this.handleMaxFillingLimit = this.handleMaxFillingLimit.bind(this);
        this.handleCapacity = this.handleCapacity.bind(this);
    }
    componentDidMount() {
        //console.log(this.props.details.site_details.id)
        checkServiceType(38).then((data) => this.setState({
			AssetManagementPermission: data
		}, () => {
		}),

		)
        if (JSON.parse(localStorage.getItem('serviceTypeSet')) == null) {
            const { history } = this.props;

            history.push("/");

        } else {

            this.getSetPageData(JSON.parse(localStorage.getItem('serviceTypeSet')), 38)

        }
        this.getDataBulckTankBySiteId();

    }
    getSetPageData = (serviceTypeIs, Pageid) => {

        if (serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == Pageid)[0] != null) {
            console.log('fddf');
            this.setState({
                actionView: true,
            })
        } else {
            console.log('erereress')
        }

    };
    grnUpdata(id) {
        console.log(id)
        this.getDataBulckTankById(id);

        this.setState({ showModal1: true, });

    }
    getDataBulckTankById = async (id) => {

        var dataset = {
            'id': id
        }
        var res = await getBulckTankById(dataset);
        if (res.success) {
            this.setState({ bulckTankEdit: res.data }, () => {
                console.log(res.maxFillingLimit)
                this.setState({

                    maxFillingLimit: res.data?.maxFillingLimit,
                    capacity: res.data?.capacity,

                }, () => {
                    this.setState({ open: true, })
                })
            })
            console.log(res)
        }

    }
    getDataBulckTankBySiteId = async () => {
        var dataset = {
            'id': this.props.details.site_details.id
        }
        var res = await getBulckTankBySiteId(dataset);
        //console.log("getBulckTankBySiteId");
        //console.log(res);
        if (res.success) {
            this.setState({
                bulckTankList: res.data


            })
        }
    };
    requestFromClose() {
        this.setState({
            showModal: true,
            open: true,
            maxFillingLimit: '',
            capacity: '',
            bulckTankEdit: null,
        });
    }
    requestFromClose4() {
        this.setState({ showModal1: false, });
    }

    requestFromClose3() {
        this.setState({ showModal: false, });
    }
    handleMaxFillingLimit(e) {
        //console.log(e)
        this.setState({ maxFillingLimit: e });
    }
    handleCapacity(e) {
        //console.log(e)
        this.setState({ capacity: e });
    }
    AllDataSubmitToUpdate = async () => {
        console.log(this.state.bulckTankEdit);
        if (this.validator.allValid()) {
            if (this.state.bulckTankEdit == null) {

                var dataSet = {
                    "maxFillingLimit": this.state.maxFillingLimit,
                    "capacity": this.state.capacity,
                    "site": { "id": this.props.details.site_details.id },
                }
                //console.log(dataSet)
                var res = await Createcreatebulcktank(dataSet);
                if (res.success) {
                    message.success('Successfully Bulk Tank Added!')
                    this.getDataBulckTankBySiteId();
                    this.setState({
                        maxFillingLimit: '',
                        capacity: '',
                        bulckTankEdit: null,
                        showModal: false,
                        open: false
                    })
                    // const { history } = this.props;
                    // history.push("/site");
                }else{
                    message.warning(' Something went wrong!')
                }
            } else {
                var dataSet = {
                    "id": this.state?.bulckTankEdit?.id,
                    "maxFillingLimit": this.state.maxFillingLimit,
                    "capacity": this.state.capacity,
                    "site": { "id": this.props.details.site_details.id },
                }
                //console.log(dataSet)
                var res = await updateBulckTank(dataSet);
                if (res.success) {
                    message.success('Successfully Bulk Tank Edited!')
                    this.getDataBulckTankBySiteId();
                    this.setState({
                        maxFillingLimit: '',
                        capacity: '',
                        bulckTankEdit: null,
                        showModal: false,
                        open: false
                    })
                    // const { history } = this.props;
                    // history.push("/site");
                }else{
                    message.warning(' Something went wrong!')
                }
            }


        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    }
    onDrawerClose = () => {
        this.setState({
            open: false
        })

    }
    viewMoreDetails(element) {
        this.setState({
            openViewMoreDetails: true, 
            selectedElement: element
        });
    }
    onViewMoreDetailsDrawerClose = () => {
        this.setState({
            showModal2: false,
            openViewMoreDetails: false,
        })
    }
    render() {
        const { Panel } = Collapse;
        const _bulk  = this.state.selectedElement
        const columns2 = [
            {
                title: "Last Filling Quantity",
                details: [],
                content: _bulk !== "" ? <span class="badge bg-secondary">{_bulk.lastFillingQuantity}</span> : <></>,
            },
            {
                title: "Max Filling Limit",
                details: [],
                content: _bulk !== "" ? <span class="badge badge-soft-pink fw-semibold">{_bulk.maxFillingLimit}</span> : <></>,
            },
            {
                title: "Capacity",
                details: [],
                content: _bulk !== "" ? <span class="badge badge-soft-success fw-semibold">{_bulk.capacity}</span> : <></>,
            },
        ];
        
        return (
            <div className="row">

                <div class="col-lg-12">
                    <div class="text-end">
                        <ul class="list-inline">
                            <li class="list-inline-item">
                                {this.state.actionView
                                    ? <button type="button" class="btn btn-soft-primary btn-sm" onClick={this.requestFromClose}><i class="fas fa-plus me-2"></i> Add Bulk Tank</button>
                                    : ('')
                                }
                            </li>
                        </ul>
                    </div>                            
                </div>

                {this.state.bulckTankList.map((element) => (
                    <div className='col-md-4'>
                        <div class="card">
                            <div class="card-header">
                                <div class="d-flex justify-content-start align-items-center">
                                    <img src={'https://static.vecteezy.com/system/resources/previews/001/902/502/large_2x/telecommunication-radio-antenna-and-satelite-tower-with-blue-sky-free-photo.JPG'} alt="" class="thumb-md rounded"/> 
                                    <div class="badge badge-soft-primary" style={{padding: "5px", marginLeft:"15px", border: "1px solid rgba(0, 0, 0, 0.05)"}}>
                                        <i class="la la-home font-14 text-dark" style={{paddingLeft: "10px"}}/>
                                        <i class="la la-angle-right text-dark font-14" style={{padding: "5px"}}/>
                                        <span class="fw-semibold text-pink">{this.props.details.site_details.siteName}</span>
                                        <i class="la la-angle-right text-dark font-14" style={{padding: "5px"}}/>
                                        <span class="fw-semibold text-primary" style={{paddingRight: "10px"}}>Bulk Tank</span>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class = "col">
                                    <div class="d-flex justify-content-between align-items-center mb-2">  
                                        <h6 class="fw-normal m-0">Last Filling Quantity</h6>
                                        <span class="badge bg-secondary">{element.lastFillingQuantity}</span>
                                    </div> 
                                    <div class="d-flex justify-content-between align-items-center mb-2">  
                                        <h6 class="fw-normal m-0">Max Filling Limit</h6>
                                        <span class="badge badge-soft-pink fw-semibold">{element.maxFillingLimit}</span>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center mb-2">  
                                        <h6 class="fw-normal m-0">Capacity</h6>
                                        <span class="badge badge-soft-success fw-semibold">{element.capacity}</span>
                                    </div>
                                </div>
                            </div>
                            <p class="card-footer m-0">
                                <div class="d-flex justify-content-between align-items-center">
                                    <a href="#custom-modal" class="btn btn-soft-dark btn-sm waves-effect btn-round" onClick={() => this.viewMoreDetails(element)}>
                                        <i class="mdi mdi mdi-unfold-more-horizontal"></i> More Details </a>
                                    <div class="text-end">
                                        {this.state.AssetManagementPermission
                                            ? (<button type="button" class="btn btn-soft-primary btn-icon-square-sm" onClick={() => this.grnUpdata(element.id)} style={{marginRight: "0px"}}><i class="mdi mdi-pencil-outline"></i></button>) 
                                            : ("")}
                                        {/* <Popconfirm
                                            title="Are You Sure?"
                                            description="Are you sure to delete this task?"
                                            onConfirm={() => this.submitForm(element.id)}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            {this.state.AssetManagementPermission
                                                ?   (<button type="button" class="btn btn-soft-danger btn-icon-square-sm"><i class="mdi mdi-trash-can-outline"></i></button>) 
                                                :   ('')}
                                        </Popconfirm>                                                                                                                                                              */}
                                    </div>
                                </div>
                            </p>
                        </div>
                    </div>
                ))}

                <Drawer maskClosable={false} title={"Bulk Tanks Details"} width={"50%"} placement="right" onClose={() => this.onViewMoreDetailsDrawerClose()} visible={this.state.openViewMoreDetails} closeIcon={<button type="button" class="btn btn-soft-danger btn-icon-square-sm" style={{marginRight: "8px"}}><i class="mdi dripicons-cross"></i></button>}>
                    <Collapse style={{borderColor:"lavender", alignContent:"center"}}>
                        {columns2.map((item) => (
                            <Panel 
                                style={{borderColor:"lavender"}}
                                header={
                                    <li class="d-flex justify-content-between align-items-center">
                                        <div>
                                            {item.details.length == 0 ? <i class="la la-hand-o-right text-primary" style={{paddingLeft:"5px", paddingRight:"5px"}}/> : <></>}  
                                            <span class="fw-normal m-2">{item.title}</span>
                                        </div>
                                        {item.content}
                                    </li>
                                }
                                key= {item.title}
                                showArrow={item.details.length > 0}
                                collapsible={item.details.length > 0 ? "" : "icon"}
                            >
                                {item.details.map((detail) => (
                                    <li class="d-flex justify-content-between align-items-center me-2" style={{paddingBottom:"10px", paddingLeft:"10px"}}>
                                        <div>
                                            <i class="la la-circle text-primary me-2"></i> <span>{detail.title}</span>
                                        </div>
                                        {detail.content}
                                    </li>
                                ))}
                            </Panel>
                        ))}
                    </Collapse>
                </Drawer>

                <Drawer maskClosable={false} title={this.state.bulckTankEdit !== null ? "Bulk Tank Update" : "New Bulk Tank"}  placement="right" onClose={() => this.onDrawerClose()} visible={this.state.open} closeIcon={<button type="button" class="btn btn-soft-danger btn-icon-square-sm" style={{marginRight: "8px"}}><i class="mdi dripicons-cross"></i></button>}>
                    <div className='row'>
                        <div className="col-md-12">
                            <label className="form-label" for="depot">
                                Max Filling Limit <x style={{ color: "red" }}>*</x>
                            </label>
                            <Form.Item>
                                <InputNumber style={{ width: '100%' }} id="max_Filling_Limit" value={this.state.maxFillingLimit} onChange={this.handleMaxFillingLimit} />
                                <p style={{ color: "red" }}>{this.validator.message("Max Filling Limit", this.state.maxFillingLimit, "required")}</p>
                            </Form.Item>
                        </div>
                        <div className="col-md-12">
                            <label className="form-label" for="depot2">
                                Bulk Tank Capacity (l) <x style={{ color: "red" }}>*</x>
                            </label>
                            <Form.Item>
                                <Select
                                    value={this.state?.capacity}
                                    style={{ width: '100%' }}
                                    onChange={(e) => {
                                        this.handleCapacity(e);
                                    }}>
                                    {
                                        // (this.props?.dropdownObj["bulckTankDropdown"]["capacity"] ?? []).map((x) => {
                                        //     return <Option value={x}>{x}</Option>;
                                        // })
                                            this.props?.dropdownObj["bulckTankDropdown"] != null && (
                                                this.props?.dropdownObj["bulckTankDropdown"]["capacity"] ?? []
                                            ).map((x) => (
                                                <Option value={x}>{x}</Option>
                                            ))
                                    }
                                </Select>
                                <p style={{ color: "red" }}>{this.validator.message("Capacity", this.state.capacity, "required")}</p>
                            </Form.Item>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-2' >   <button className="btn btn-primary" onClick={() => this.AllDataSubmitToUpdate()} >Submit</button></div>
                    </div>
                </Drawer>
            </div>
        );
    }
}

export default BulckTankDetails;
import React, { Component } from "react";

class AssetDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: ""
    }; 
  }
 
  render() {
    
    return (
		<div className="row">
		   <div className="col-12">
			<div   id="pills-tabContent">
				<div   id="Profile_Project" role="tabpanel" aria-labelledby="Profile_Project_tab">
					<div className="row mb-4">
						<div className="col">
							{/* <form>
								<div className="input-group">                               
									<input autoComplete="off" type="text" id="example-input1-group2" name="example-input1-group2" className="form-control" placeholder="Search" />
									<button type="button" className="btn btn-primary"><i className="fas fa-search"></i></button>
								</div>                                                
							</form>                                             */}
						</div> 
						<div className="col-auto"> 
							<button type="button" className="btn btn-primary">Add Asset</button>                                                                                        
						</div>                                      
					</div>
					<div className="row">

					<div    style={{height: '120px' , display: 'flex',  justifyContent:'center', alignItems:'center' }}>
										<h4 style={{color:"#c5c5c5"}}>No Dat Found</h4></div>     
						{/* <div className="col-3">
							<div className="card">
								<div className="card-body ">
									<div className="text-center">
										<img src="assets/images/widgets/project2.jpg" alt="" className="rounded-circle d-block mx-auto mt-2" height="70"/>
										<h4 className="m-0 fw-semibold text-dark font-16 mt-3">Body Care</h4>   
										<p className="text-muted  mb-0 font-13"><span className="text-dark">Client : </span>Hyman M. Cross</p> 
									</div>                                                    
									<div className="row mt-4 d-flex align-items-center">
										<div className="col">
											<h5 className="font-22 m-0 fw-bold">11</h5> 
											<p className="mb-0 text-muted">Total Count</p>
										</div>
										<div className="col-auto">
											<a href="#" className="btn btn-sm btn-outline-secondary  px-4">More Details</a>
										</div>
									</div>                                                    
								</div>     
							</div>
						</div>
						<div className="col-3">
							<div className="card">
								<div className="card-body ">
									<div className="text-center">
										<img src="assets/images/widgets/project4.jpg" alt="" className="rounded-circle d-block mx-auto mt-2" height="70"/>
										<h4 className="m-0 fw-semibold text-dark font-16 mt-3">Book My World</h4>   
										<p className="text-muted  mb-0 font-13"><span className="text-dark">Client : </span>Johnson M. delly</p> 
									</div>                                                    
									<div className="row mt-4 d-flex align-items-center">
										<div className="col">
											<h5 className="font-22 m-0 fw-bold">14</h5> 
											<p className="mb-0 text-muted">Total Count</p>
										</div>
										<div className="col-auto">
											<a href="#" className="btn btn-sm btn-outline-secondary  px-4">More Details</a>
										</div>
									</div>                                                    
								</div>       
							</div> 
						</div>

						<div className="col-3">
							<div className="card">
								<div className="card-body ">
									<div className="text-center">
										<img src="assets/images/widgets/project3.jpg" alt="" className="rounded-circle d-block mx-auto mt-2" height="70"/>
										<h4 className="m-0 fw-semibold text-dark font-16 mt-3">Banking</h4>   
										<p className="text-muted  mb-0 font-13"><span className="text-dark">Client : </span>Hyman M. Cross</p> 
									</div>                                                    
									<div className="row mt-4 d-flex align-items-center">
										<div className="col">
											<h5 className="font-22 m-0 fw-bold">7</h5> 
											<p className="mb-0 text-muted">Total Count</p>
										</div>
										<div className="col-auto">
											<a href="#" className="btn btn-sm btn-outline-secondary  px-4">More Details</a>
										</div>
									</div>                                                    
								</div>       
							</div>   
						</div>
						<div className="col-3"> */}
							{/* <div className="card">
								<div className="card-body ">
									<div className="text-center">
										<img src="assets/images/widgets/project1.jpg" alt="" className="rounded-circle d-block mx-auto mt-2" height="70"/>
										<h4 className="m-0 fw-semibold text-dark font-16 mt-3">Transfer money</h4>   
										<p className="text-muted  mb-0 font-13"><span className="text-dark">Client : </span>Jack Z Jackson</p> 
									</div>                                                    
									<div className="row mt-4 d-flex align-items-center">
										<div className="col">
											<h5 className="font-22 m-0 fw-bold">12</h5> 
											<p className="mb-0 text-muted">Total Count</p>
										</div>
										<div className="col-auto">
											<a href="#" className="btn btn-sm btn-outline-secondary  px-4">More Details</a>
										</div>
									</div>                                                    
								</div>      
							</div>
						</div>                                     */}
					</div>
					</div>
					</div>
					</div>
					</div>

    );
  }
}
 

 

export default AssetDetails;

import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { Table, Input, Tag, Space } from "antd";
import {
  RightOutlined,
  DownOutlined,
  BorderOuterOutlined,
  TeamOutlined,
  AppstoreOutlined,
  DeploymentUnitOutlined,
  ClusterOutlined,
} from "@ant-design/icons";



class DepotGeneral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      filterTable: null,
    };
  }

  componentDidMount() {
    console.log("this.props.details");
    console.log(this.props.details);
  }

  render() {

    const { filterTable } = this.state;
    const columns = [
      // {
      //   title: "#",
      //   dataIndex: "id",
      //   defaultSortOrder: "ascend",
      //   sorter: (a, b) => a.id - b.id,
      //   sortDirections: ["descend"],
      //   render: (text, record, index) => <span>{text}</span>,
      // },
      {
				title: "# Site Id",
				// dataIndex: "siteId",
			//	defaultSortOrder: "descend",


				render: (text, record, index) => <span> <u><Link to={'/site-summary/' + text.id}>{text.siteId}</Link></u></span>,
			},
      {
				title: "Site",
			
				key: "siteName",

				render: (item) => <span  ><u><Link to={'/site-summary/' + item.id}>{item.siteName}</Link></u></span>,

			},
      {
				title: "Region",
				//   dataIndex: "accessPermissionCode",
				key: "accessPermissionCode",
				render: (item) => <span>{item.depot.region.name}</span>,
			},
			{
				title: "Depot",
				//   dataIndex: "accessPermissionCode",
				key: "accessPermissionCode",
				render: (item) => <span>{item.depot.name}</span>,
			},
      {
				title: "Status",
				dataIndex: "status",
				key: "operationTypeName",
        align: 'center',
				render: (item) => <>
					<Tag color="#03d87f" key={item}>
						<span style={{ color: 'black' }}><b>Active</b></span>
					</Tag>
				</>,

			},
			{
				title: "Site Category",
				dataIndex: "siteCategory",
        align: 'center',
				key: "siteCategory",
				render: (item) => <span>{item.name}</span>,
				//render: (item) => <span>{item}</span>,


			},
      {
				title: "siteOwner",
				align: 'center',
				key: "siteOwner",
				render: (item) => <>
					<Tag color="gray" key={item}>
						<span>{item.siteOwner.name}</span>
					</Tag>
				</>,

			},
   

			


    ];
    return (
      <div className="row">


        <Table
          size="small"
          columns={columns}
          rowKey="id"
						bordered

          // pagination={{ pageSize: 15 }}

          // expandable={{
          //   expandedRowRender:(record,key )=> <p key={key+1} style={{ margin: 0 }}>{record.id}</p>,
          // }}

          dataSource={
            filterTable == null ? this.props.details : filterTable
          }
        />


      </div>
    );
  }
}

export default DepotGeneral;
